window.domain = window.location.origin+'/';
window.site_url = domain; //"https://dr.cloudcounting.com/";
// window.api_url = "http://localhost/synergy/api/";
window.api_url = "https://devapi.rentsynergy.com/";
window.accessToken = '';
window.max_file_size = 8;
window.maxLength = 500;
window.dwolla_transaction_amount = 5000;
window.disable_confirmation_text = 'disable';
window.delete_confirmation_text = 'delete';
window.restore_confirmation_text = 'restore';
window.cancel_confirmation_text = 'cancel';
window.confirmation_text = 'confirm';
window.WEBSOCKET_URL = 'wss://hzwts9n9jd.execute-api.us-east-2.amazonaws.com/development';
window.HTML2PDF_URL = 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.0/html2pdf.bundle.min.js';
window.color_array = ['#e7f7f4','#ffe0d1','#97E0E1','#f5e4ef','#f4edfa'];
window.border_color_array = ['#4ba695','#d18f6f','#6FBABB','#b579a0','#b991db'];
window.canvas_width = 817;
window.canvas_height = '';
//window.canvas_height = 1057;
window.role_dd = ['Rent Synergy', 'Property Owner', 'Tenant'];
window.date_dd = ['Equal To', 'Greater Than', 'Less Than', 'Between'];
window.record_to_display = 5;
window.announcementStrWord = 80;
window.currency_sign = '$';
/*window.ckeditor_toolbar = [{"name": "basicstyles","groups": ["basicstyles"]},{"name": "links","groups": ["links"]},{"name": "paragraph","groups": ["list", "blocks"]},{"name": "document",
              "groups": ["mode"]},{"name": "insert","groups": ["insert"]},{"name": "styles","groups": ["styles"]}];
window.ckeditor_removeButtons = 'Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,PasteFromWord';*/

window.ckeditor_toolbar = [{ name: 'document', groups: [ 'mode' ] }, { name: 'clipboard', groups: [ 'undo', 'clipboard' ] },{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },{ name: 'links', groups: [ 'links' ] },{ name: 'insert', groups: [ 'insert' ] },{ name: 'styles', groups: [ 'styles' ] },{ name: 'tools', groups: [ 'tools' ] },'/',{ name: 'colors', groups: [ 'colors' ] },{ name: 'others', groups: [ 'others' ] },{ name: 'about', groups: [ 'about' ] }];

window.ckeditor_removeButtons = 'ImageButton,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,HiddenField,Subscript,Superscript,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Smiley,Iframe,ShowBlocks,About,TextColor,BGColor,Styles';

window.ckeditor_fontSize = '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt';

window.ckeditor_fontNames = 'Courier;Times New Roman;Helvetica';

window.authorize_text = 'I agree and authorize future payments to Rent Synergy to be processed by the Dwolla payment system from the selected account above. To cancel this authorization, I will change the payment settings within my account. I understand that it is my responsibility to ensure sufficient funds are available in my account to cover payments. Failure to do so may result in additional late fees and ACH return fees.';
window.session_idle_timer = 14; //(minutes) => idle time varialbe to show session about to expire modal box. (if val == 14, session will expire after 15 minutes of inavctivity)
window.countdown_timer = ''; //this variable is used to clear countdown interval when user is idle.
window.attempt_refresh_time = 300 //(seconds) => {(access_token expiry time) - (current time)) < 5 minutes (300 seconds)}
/* Setup timer for autosave after 5 second */
var typingTimer;                //timer identifier
var doneTypingInterval = 5000;  //time in ms, 5 second for example


if(localStorage.getItem("user") !== null) {
	window.current_page = localStorage.getItem('page_name');
	window.user = JSON.parse(localStorage.getItem('user'));
	window.status_code = user.user_details.status_code;
	window.skip_add_payment_details = user.user_details.skip_add_payment_details;
	window.is_bank_added = user.user_details.is_bank_added;
	window.user_service_name = $.trim(user.user_details.service_constant);
	window.first_property_id = user.user_details.first_property_id;
	window.allow_ai_access = user.user_details.allow_ai_access;
	window.user_id = user.user_details.user_id;
	window.username = user.user_details.given_name+' '+user.user_details.family_name;
	window.user_email = user.user_details.email;
	window.dwolla_customer_status = user.user_details.dwolla_customer_status;
	window.payment_type_transfer = user.user_details.payment_type_transfer;
	window.docusign_signature_enabled = user.docusign_signature_enabled;
	window.signature_enabled = user.signature_enabled;
	window.new_payment_schedule_enabled = user.new_payment_schedule_enabled;
	window.apply_default_coupon_code = user.apply_default_coupon_code;
	window.default_property_setup_coupon_code = user.user_details.default_property_setup_coupon_code;
	window.default_subscription_fee_coupon_code = user.user_details.default_subscription_fee_coupon_code;
	window.accessToken = user.authentication.AccessToken;
	window.expense_api_url = user.expensesInfo.expense_api_url;
	window.expense_x_api_key = user.expensesInfo.expense_x_api_key;
	window.expense_customer_id = user.expensesInfo.expense_customer_id;
	window.sa_role = user.serviceArray[1];
	window.rss_role = user.serviceArray[2];
	window.po_role = user.serviceArray[3];
	window.t_role = user.serviceArray[4];
	window.o_role = user.serviceArray[5];
	var tz = jstz.determine();
	window.timezone = tz.name();
} else {
	if(domain != window.location.href) {
		//window.location.href = window.location.protocol+'//'+window.location.hostname;
	}
}

/* When user access page in new tab check if user is already logged in or not */
window.checkIfSessionAvailable = function() {
	var page_name = localStorage.getItem('page_name');
	if(page_name == 'dashboard') {
		window.location.href = site_url+page_name+'.html';
	} else {
		window.location.href = site_url+'dashboard.html?action='+page_name;
	}
}

/* Below code is used to display modal in middle of screen */
/*window.centerModal = function() {
    $(this).css('display', 'block');
    var $dialog  = $(this).find(".modal-dialog"),
    offset       = ($(window).height() - $dialog.height()) / 2,
    bottomMargin = parseInt($dialog.css('marginBottom'), 10);

    // Make sure you don't hide the top part of the modal w/ a negative margin if it's longer than the screen height, and keep the margin equal to the bottom margin of the modal
    if(offset < bottomMargin) offset = bottomMargin;
    $dialog.css("margin-top", offset);
}
$(document).on('show.bs.modal', '.modal', centerModal);*/

/* Below function will used to display dropdown top when we click on notification ot setting icon */
window.call_mobile_query = function() {
	if($(window).width() < 767) {
		$('#notification_setting_header').find('.dropdown').addClass('dropup');
		$('#assumed_user_email').insertBefore('#main_container');
		announcementStrWord = 25;
	} else {
		$('#notification_setting_header').find('.dropdown').removeClass('dropup');
	}
}

call_mobile_query();
$(window).resize(function(){
	call_mobile_query();
	//$('.modal:visible').each(centerModal);
});

window.isTablet = function() {
    var userAgent = navigator.userAgent.toLowerCase();
    var screenWidth = window.innerWidth;
    var isTabletByAgent = /ipad|android(?!.*mobile)/i.test(userAgent);
    var isTabletByScreen = screenWidth >= 768 && screenWidth <= 1024;
    
    return isTabletByAgent || isTabletByScreen;
}

$(document).ajaxComplete(function( event, jqxhr, settings, thrownError ) {
	$('[data-toggle="tooltip"]').tooltip({
		trigger: 'hover'
	});

	$('[data-toggle="tooltip"]').on('click', function () {
    	$(this).tooltip('hide');
    	setTimeout(function(){
		    $('.tooltip').removeClass('show');
		}, 1000);
  	});
});

// Check if cogito incoming token expired
$(document).ajaxError(function( event, jqxhr, settings, thrownError ) {
	var obj = JSON.parse(jqxhr.responseText);
	var msg = obj.response_data.message;
	var status = obj.response_data.error_code;
	if(msg == 'The incoming token has expired' && status == 401) {
		if(domain != window.location.href) {
			var response = {
	            "response_data": {
	            	"success": false,
	              	"message": 'Session Expired'
	            }
	        };
	        display_error_message(response);
			setTimeout(function(){
			    localStorage.clear();
				window.location.href = window.location.protocol+'//'+window.location.hostname;
			}, 5000);
		} else {
			localStorage.clear();
		}
	}
});

var access_interval;
var idleTime = 0;
$(document).ready(function(){

	// START #94:
	// setting interval for getting new access token if the user is not idle.
	// attemptRefresh();
	access_interval = setInterval(attemptRefresh, 180000) // this checks for access time expiration date every 3 minutes (180000 ms)

	// setting idle time interval to get warning message to user about session timeout
	var idleInterval = setInterval(idleTimerIncrement, 60000) // 1 minute

	$(this).bind('mousemove keydown scroll click', () => { //if any of this event occurs idleTime resets.
		idleTime = 0
	})
	// END #94.

	// Jquery validate : override checkForm function to working with array field validation
	$.validator.prototype.checkForm = function () {
	    this.prepareForm();

	    for (var i = 0, elements = (this.currentElements = this.elements()) ; elements[i]; i++) {
	        if (this.findByName(elements[i].name).length != undefined && this.findByName(elements[i].name).length > 1) {
	            for (var cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
	                this.check(this.findByName(elements[i].name)[cnt]);
	            }
	        } else {
	            this.check(elements[i]);
	        }
	    }
	    return this.valid();
	}

	$('.phone_us').mask('(000) 000-0000');

	$(".settings-toggle").hide();
	$('#footer').load('footer.html');

	/* Disable right click context menu in browsers */
    /* Disable F12, Ctrl+Shift+i, Ctrl+Shift+j, Ctrl+Shift+c hot key combination in browser to prevent users from opening Fire bug / Developer tools. */

    /*if(user.user_details.user_service_name != sa_role) {

        $(document).bind("contextmenu",function(e) {
            e.preventDefault();
        });

        $(document).keydown(function(event){
            if(event.keyCode==123){
                return false;//Prevent from F12
            } else if(event.ctrlKey && event.shiftKey && event.keyCode==73){
                return false; //Prevent from ctrl+shift+i
            } else if(event.ctrlKey && event.shiftKey && event.keyCode==74){
                return false; //Prevent from ctrl+shift+j
            } else if(event.ctrlKey && event.shiftKey && event.keyCode==67){
                return false; //Prevent from ctrl+shift+c
            }
        });
    }*/

    /* End : Disabled Right CLick */

    $('.allow_amount').on('change, keyup', function() {
	    var currentInput = $(this).val();
	    var fixedInput = currentInput.replace(/[A-Za-z!@#$%^&*()]/g, '');
	    $(this).val(fixedInput);
	});

	$('#user_signin').on('click', function(){
		$('.navbar-toggler').trigger('click');
		// Do not allow to access landing page if user already logged
		if(localStorage.getItem("user") !== null) {
			checkIfSessionAvailable();
        } else {
        	window.location.href = site_url+'signin.html';
        }
	});

	$(document).off('click', '.open_signin_modal').on('click', '.open_signin_modal', function() {
		if(localStorage.getItem("user") !== null) {
			checkIfSessionAvailable();
        } else {
        	window.location.href = site_url+'signin.html';
		}
	});

	$(document).off('click', '.open_signup_modal').on('click', '.open_signup_modal', function() {
		if(localStorage.getItem("user") !== null) {
			checkIfSessionAvailable();
        } else {
        	window.location.href = site_url+'signup.html';
		}
	});

	// Login send passcode api
	$(document).on('click', '.send_passcode, .resend_send_passcode', function(e) {
		if(!$loginForm.valid()){
			return false;
		} else {
			$('.send_passcode').attr('disabled', true);
			// start #401 point : 6 from and to account cannot be same.
			var open_from = $(this).attr('data-open-from');
			if(open_from == 'link_account') {
				var email = $('#username').val();
				if($.trim(email) == $.trim(user_email)) {
					$('.send_passcode').attr('disabled', false);
					var response = {
			            "response_data": {
			                "success": false,
			                "message": 'From and to email address cannot be same.'
			            }
			        };
			        display_error_message(response);
			        return false;
				}
			}
			// end #401 point : 6 from and to account cannot be same.

			$('#login-phone-digit-1').val('');
	   		$('#login-phone-digit-2').val('');
	   		$('#login-phone-digit-3').val('');
	   		$('#login-phone-digit-4').val('');
	   		var username = $('#username').val();
	   		var data = 'username='+encodeURIComponent(username);
	   		$('.loader_container').css('display','inline-block');

	   		$.ajax({
		        method: 'POST',
		        url: api_url+"auth/login",
		        data: data,
		        async: false,
		    }).then(function(response) {
		    	$('.loader_container').css('display','none');
		    	if(response.response_data.success == false) {
		    		$('#username').attr('disabled', false);
		    		$('.send_passcode').attr('disabled', false);
		    		$('.send_passcode').removeClass('d-none');
		    		$('#otp_section').addClass('d-none');
		    	} else {

		    		$('#username').attr('disabled', true);
		    		$('.send_passcode').addClass('d-none');
		    		$('.login_button').removeClass('d-none');
		    		$('.otp_resend').removeClass('d-none');
		    		$('#otp_section').removeClass('d-none');

					$('.login_button').attr('disabled', false);
		    		$('#login_challenge').val(response.challenge);
					$('#login_email').val(response.challengeParameters.email);
					$('#login_session').val(response.session);
					$('#login_username').val(response.challengeParameters.USERNAME);
					$('#login_phone_number').val(response.challengeParameters.phone_number);
					$('#login-phone-digit-1').focus();

					if(response.userData.optinout == 1) {
						$('#login_otp_optin').removeClass('d-none');
						$('#login_otp_optout').addClass('d-none');
					} else {
						$('#login_otp_optout').removeClass('d-none');
						$('#login_otp_optin').addClass('d-none');
					}
		    	}
		    	display_error_message(response);
		    }, function() {
		        // hang on this step if the error occur
		    });
		    return false;
		}
	});

	// Submit passcode api / Login api
	$(document).on('click', '.login_button', function() {
		if(!$passcodeForm.valid()){
			return false;
		} else {
			$('.login_button').attr('disabled', true);
			var challenge = $('#login_challenge').val();
			var email = $('#login_email').val();
			var session = $('#login_session').val();
			var username = $('#login_username').val();
			var phone_number = $('#login_phone_number').val();

			var phone_digit_1 = $('#login-phone-digit-1').val();
	   		var phone_digit_2 = $('#login-phone-digit-2').val();
	   		var phone_digit_3 = $('#login-phone-digit-3').val();
	   		var phone_digit_4 = $('#login-phone-digit-4').val();
	   		var answer = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4;

	   		var data = 'challenge='+challenge+'&email='+email+'&session='+session+'&username='+username+'&phone_number='+phone_number+'&answer='+answer;

	   		var url = api_url+"auth/verify";
	   		var credential = true;

	   		$.ajax({
		        method: 'POST',
		        url: url,
		        data: data,
		        async: false,
		        xhrFields: {
			        withCredentials: credential
			    },
			    success: function(response) {
	            	if(response.response_data.success == false) {
			    		display_error_message(response);
			    		$('.login_button').attr('disabled', false);
			    		$('.digit-group').find('input').val('');
			    	} else {
			    		localStorage.setItem('user', JSON.stringify(response));
			    		localStorage.setItem('page_name', 'dashboard');

			    		window.user = JSON.parse(localStorage.getItem('user'));
						window.status_code = user.user_details.status_code;
						window.user_service_name = $.trim(user.user_details.service_constant);
						window.location.href = site_url+'dashboard.html';

						if(user_service_name == user.serviceArray[1]) {
							window.location.href = site_url+'dashboard.html?action=properties';
						} else {
							if(user_service_name == user.serviceArray[3] || user_service_name == user.serviceArray[4]) {
								var checklist_seq_no = user.user_details.cheklist_seq_no;
								var total_steps = user.user_details.total_steps;
								var steps_left = user.user_details.steps_left;
								if(steps_left != 0 || checklist_seq_no < total_steps) {
									window.location.href = site_url+'dashboard.html';
								} else {
									window.location.href = site_url+'dashboard.html?action=properties';
								}
							}
						}
			    	}
		        }
		    });
		    return false;
		}
	});

	// Signout api
	$(document).on('click', '#signout', function() {
		var user = JSON.parse(localStorage.getItem('user'));
		var accessToken = user.authentication.AccessToken;
		var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"auth/signout",
	        data:"user_id="+user_id,
	        xhrFields: {
		        withCredentials: true
		    },
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
            	display_error_message(response);
				localStorage.clear();
		    	if(response.response_data.success == false) {
		    		if(response.redirect_step == 'login') {
		    			window.location.href = site_url;
		    		}
		    	} else {
		    		window.location.href = site_url;
		    	}
	        }
	    });
	});

	$(document).off('click', '.stay_logged_in').on('click', '.stay_logged_in', function() {
		$('#SessionExpireModal').modal('hide');
		clearInterval(countdown_timer);
	});

	$(document).off('click', '.validation_step_1_save').on('click', '.validation_step_1_save', function() {
		if($userSignupForm.valid()){
			var recaptchaResponse = grecaptcha.getResponse();
			if (recaptchaResponse.length === 0) {
				var response = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please complete the CAPTCHA'
	                }
	            };
	            display_error_message(response);
	            return false;
			} else {
				var tenant_id = $('#tenant_id').val();
				if(tenant_id == null) {
					tenant_id = 0;
				}

				var first_name = $('#firstname').val();
				var last_name = $('#lastname').val();
				var email = $('#email_address').val();
				var country_code = $('#country_code').val();
				var phone = $.trim($('#phone_number').val());
				phone = phone.replace('-', '');
				phone = phone.replace('(', '');
				phone = phone.replace(')', '');
				phone = phone.replace(/\s/g, '');
				phone = country_code+phone;

				$('#user_phone_number').val(phone);

				if($('#verify_mobile_checkbox').is(":checked")) {
					var verify_mobile = 1;
				} else {
					var verify_mobile = 0;
				}

				if($('#optinout_checkbox').is(":checked")) {
					var optinout = 1;
				} else {
					var optinout = 0;
				}

				if($('#agree').is(":checked")) {
					var agree = 1;
				} else {
					var agree = 0;
				}

				var data = 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&phone='+encodeURIComponent(phone)+'&tenant_id='+tenant_id+'&optinout='+optinout+'&agree='+agree+'&verify_mobile='+verify_mobile+'&recaptchaResponse='+recaptchaResponse;

				$.ajax({
					method: 'POST',
					url: api_url+"auth/signup",
					data: data,
					success: function(response) {
						display_error_message(response);
						if(response.response_data.success == false) {
							return false;
						} else {
							$('#validation-step-1').html('');
							$('#validation-step-2').removeClass('d-none');

							// Set value for second step
							$('#usersub').val(response.user_details.usersub);

							// $('#destination_email').text(censorEmail(email));
							$('#destination_email').text(email);
							$('#email_verified').val(response.user_details.email_verified);
							$('#phone_verified').val(response.user_details.phone_verified);
							$('#optinout').val(response.user_details.optinout);

							$('#signup-email-digit-1').focus();

							if(response.user_details.email_verified == 1) {
								$('.enter_email_code_container').html('<img src="icon/email-verified.svg" height="200px" class="filter-13"><h1 class="green">Your email has been verified. Thank You!</h1>');
							}
						}
					}
				});
			}
		}
	});

	$(document).off('click', '.validation_step_2_save').on('click', '.validation_step_2_save', function() {
		if($userSignupForm.valid()){
			var email_digit_1 = $('#signup-email-digit-1').val();
	   		var email_digit_2 = $('#signup-email-digit-2').val();
	   		var email_digit_3 = $('#signup-email-digit-3').val();
	   		var email_digit_4 = $('#signup-email-digit-4').val();
	   		var email_digit_5 = $('#signup-email-digit-5').val();
	   		var email_digit_6 = $('#signup-email-digit-6').val();

	   		var usersub = $('#usersub').val();
	   		var email_verified = $('#email_verified').val();
	   		var phone_verified = $('#phone_verified').val();
	   		var confirmation_code = email_digit_1+email_digit_2+email_digit_3+email_digit_4+email_digit_5+email_digit_6;

	   		var data = 'usersub='+usersub+'&confirmation_code='+confirmation_code+'&email_verified='+email_verified+'&phone_verified='+phone_verified;

		    $.ajax({
		        method: 'POST',
		        url: api_url+"auth/confirm-signup",
		        data: data,
		        success: function(response) {
		        	display_error_message(response);
			    	if(response.response_data.success == false) {
			    		return false;
			    	} else {
			    		$('#validation-step-1, #validation-step-2').html('');
			    		$('#validation-step-3').removeClass('d-none');

			    		// Set value for third step
			    		$('#usersub').val(response.user_details.usersub);
			    		$('#phone_verified').val(response.user_details.phone_verified);
			    		$('#destination_phone').text(response.user_details.destination);
			    		$('#access_token').val(response.user_details.accessToken);

			    		if(response.user_details.phone_verified == 1) {
			    			$('.enter_phone_code_container').html('<img src="icon/phone-verified.svg" height="200px" class="filter-13"><h1 class="green">Your phone has been verified. Thank You!</h1>');
			    		}
			    	}
		        }
		    });
		}
	});

	$(document).off('click', '.validation_step_3_save').on('click', '.validation_step_3_save', function() {
		if($userSignupForm.valid()){
			var phone_digit_1 = $('#signup-phone-digit-1').val();
	   		var phone_digit_2 = $('#signup-phone-digit-2').val();
	   		var phone_digit_3 = $('#signup-phone-digit-3').val();
	   		var phone_digit_4 = $('#signup-phone-digit-4').val();
	   		var phone_digit_5 = $('#signup-phone-digit-5').val();
	   		var phone_digit_6 = $('#signup-phone-digit-6').val();

	   		var usersub = $('#usersub').val();
	   		var accessToken = $('#access_token').val();
	   		var confirmation_code = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4+phone_digit_5+phone_digit_6;

	   		var data = 'usersub='+usersub+'&confirmation_code='+confirmation_code+'&wizard_name=signup&seq_no=4';

	   		$.ajax({
		        method: 'POST',
		        url: api_url+"auth/verify-phone",
		        data: data,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	display_error_message(response);
			    	if(response.response_data.success == false) {
			    		return false;
			    	} else {
		    			$('#validation-step-1, #validation-step-2, #validation-step-3').html('');
			    		$('#validation-step-4').removeClass('d-none');
			    	}
			    }
		    });
		}
	});

	var $userSignupForm = $("#user-signup-form");
	$userSignupForm.validate({
		errorPlacement: function errorPlacement(error, element) {
			$(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
		},
		highlight: function(element) {
			$(element).addClass("is-invalid");
		},
		unhighlight: function(element) {
			$(element).removeClass("is-invalid");
		},
		rules: {
			"firstname": { required: true, lettersonly: true },
			"lastname": { required: true, lettersonly: true },
			"email_address": { required: true, email: true },
			"country_code": { required: true },
			"phone_number": { required: true },
			"verify_mobile_checkbox": { required: true },
			"agree": { required: true },
			"signup-email-digit-1": { required: true },
			"signup-email-digit-2": { required: true },
			"signup-email-digit-3": { required: true },
			"signup-email-digit-4": { required: true },
			"signup-email-digit-5": { required: true },
			"signup-email-digit-6": { required: true },
			"signup-phone-digit-1": { required: true },
			"signup-phone-digit-2": { required: true },
			"signup-phone-digit-3": { required: true },
			"signup-phone-digit-4": { required: true },
			"signup-phone-digit-5": { required: true },
			"signup-phone-digit-6": { required: true },
			"address_line1": { required: true },
			"city": { required: true },
			"state": { required: true },
			"zip_code": { required: true },
			"country": { required: true },
		},
		messages: {
			"firstname": { required: '', lettersonly: 'Please enter alphabets only' },
			"lastname": { required: '', lettersonly: 'Please enter alphabets only' },
			"email_address": { required: '', email: 'Please enter valid email address' },
			"country_code": { required: '' },
			"phone_number": { required: '' },
			"verify_mobile_checkbox": { required: '' },
			"agree": { required: '' },
			"signup-email-digit-1": { required: '' },
			"signup-email-digit-2": { required: '' },
			"signup-email-digit-3": { required: '' },
			"signup-email-digit-4": { required: '' },
			"signup-email-digit-5": { required: '' },
			"signup-email-digit-6": { required: '' },
			"signup-phone-digit-1": { required: '' },
			"signup-phone-digit-2": { required: '' },
			"signup-phone-digit-3": { required: '' },
			"signup-phone-digit-4": { required: '' },
			"signup-phone-digit-5": { required: '' },
			"signup-phone-digit-6": { required: '' },
			"address_line1": { required: '' },
			"city": { required: '' },
			"state": { required: '' },
			"zip_code": { required: '' },
			"country": { required: '' },
		}
	});

	// Start : Signup api

	jQuery.validator.addMethod("lettersonly", function(value, element)
	{
		return this.optional(element) || /^[a-z," "]+$/i.test(value);
	}, "Please enter alphabets only");

	$(document).off('click', '.resend_email_verification_code').on('click', '.resend_email_verification_code', function(e) {
		// Clear old verification code from input
		$('#signup-email-digit-1').val('');
   		$('#signup-email-digit-2').val('');
   		$('#signup-email-digit-3').val('');
   		$('#signup-email-digit-4').val('');
   		$('#signup-email-digit-5').val('');
   		$('#signup-email-digit-6').val('');

   		$('#signup-email-digit-1').focus();

		var email = $('#email_address').val();
		var data = 'email='+email;

	    $.ajax({
	        method: 'POST',
	        url: api_url+"auth/resend-verify-email",
	        data: data
	    }).then(function(response) {
	    	display_error_message(response);
	    	// if(response.response_data.success != false) {
	    	// 	if(response.user_details.email_verified == 1) {
	    	// 		$('.enter_email_code_container').html('<h1><i class="align-middle mr-2 fas fa-fw fa-check"></i> Email already verified</h1>');
	    	// 	}
	    	// }
	    }, function() {
	        // hang on this step if the error occur
	    });
	});

	$(document).off('click', '.resend_phone_verification_code').on('click', '.resend_phone_verification_code', function(e) {
		$('#signup-phone-digit-1').val('');
   		$('#signup-phone-digit-2').val('');
   		$('#signup-phone-digit-3').val('');
   		$('#signup-phone-digit-4').val('');
   		$('#signup-phone-digit-5').val('');
   		$('#signup-phone-digit-6').val('');

   		$('#signup-phone-digit-1').focus();

		var country_code = $('#country_code').val();
		// var phone = $.trim($('#user_phone_number').val());
		// phone = phone.replace('-', '');
		// phone = phone.replace('(', '');
		// phone = phone.replace(')', '');
		// phone = phone.replace(/\s/g, '');
		// phone = country_code+phone;
		var data = 'phone='+encodeURIComponent($('#user_phone_number').val());

	    $.ajax({
	        method: 'POST',
	        url: api_url+"auth/resend-verify-phone",
	        data: data,
	        beforeSend: function(xhr) {
	        	if(accessToken != '') {
	        		xhr.setRequestHeader('Authorization', accessToken);
	        	} else {
	        		xhr.setRequestHeader('Authorization', $('#access_token').val());
	        	}
		    },
	    }).then(function(response) {
	    	display_error_message(response);
	    	// if(response.response_data.success != false) {
	    	// 	if(response.user_details.email_verified == 1) {
	    	// 		$('.enter_email_code_container').html('<h1><i class="align-middle mr-2 fas fa-fw fa-check"></i> Email already verified</h1>');
	    	// 	}
	    	// }
	    }, function() {
	        // hang on this step if the error occur
	    });
	});

	$(document).on('click', '#agree, #verify_mobile_checkbox', function() {
		if($('#verify_mobile_checkbox').is(":checked") && $('#agree').is(":checked")) {
   			$(".sw-btn-next").attr('disabled',false);
   		} else {
   			$(".sw-btn-next").attr('disabled',true);
   		}
	});

	// End : Signup api

	// OTP input
	$('.digit-group').find('input').each(function() {
		$(this).attr('maxlength', 1);
		$(this).on('keyup', function(e) {
			var parent = $($(this).parent());

			if(e.keyCode === 8 || e.keyCode === 37) {
				var prev = parent.find('input#' + $(this).data('previous'));

				if(prev.length) {
					$(prev).select();
				}
			} else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
				var next = parent.find('input#' + $(this).data('next'));

				if(next.length) {
					$(next).select();
				} else {
					if(parent.data('autosubmit')) {
						parent.submit();
					}
				}
			}
		});
	});

	$(document).off('keypress', '.allow-number').on('keypress', '.allow-number', function(e) {
		//if the letter is not digit then display error and don't type anything
	    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
	        return false;
	    }
	});

	$(document).off('keypress', '.allow-number-dash').on('keypress', '.allow-number-dash', function(e) {
		var txt = String.fromCharCode(e.which)
	    var pattern = /^[0-9\-]+$/;
	    if (!(pattern.test(txt) || e.keyCode == 8)){
	    	return false;
	    }
	});

	// LEFT MENU CODE

	if(localStorage.getItem("user") !== null) {
		loadUserDetail(user.user_details.user_id);
		loadLeftMenu();
		// $('#search_form_container').load('components/search.html');
		loadDashboardPage();
		initialize_datepicker('vacant_date');
	}

	if(localStorage.getItem("primary") !== null) {
		$('#assumed_user_email').removeClass('d-none');
		$('#assumed_user_email').append('<div class="alert-message"><strong>'+username+' ('+user.user_details.email+')</strong></div>');
	}

	$(document).off('click', '.open_notification_section').on('click', '.open_notification_section', function(e) {
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Notifications');
		$('.back_button_right_sidebar').addClass('d-none').removeClass('open_notification_section');
		setTimeout(function(){
			// $('#sidebar_content_area').html('');
			getNotificationData();
		}, 500);
	});

	$(document).off('click', '#assume_identity_section').on('click', '#assume_identity_section', function(e) {
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Assume Identity');
		setTimeout(function(){
			loadUserLists();
		}, 500);
	});

	// HEADER SWITCH USER
	$(document).off('click', '.switch_user').on('click', '.switch_user', function(e) {
		e.stopPropagation();
		var linked_account_id = $(this).attr('data-id');
		var email = $(this).attr('data-email');
		var arrow = $(this).find('i').attr('data-type');
		if(arrow == 'down') {
			$(this).find('span').html('<i data-type="up" class="float-right fas fa-fw fa-angle-up"></i>');
			$(this).append('<div class="child-sec pt-3 pb-3"><button data-email="'+email+'" type="button" class="btn btn-primary mr-1 switch_btn">Switch</button></div>');
			$(this).addClass('bg-light');
		} else {
			$(this).find('span').html('<i data-type="down" class="float-right fas fa-fw fa-angle-down"></i>');
			$(this).find('.child-sec').remove();
			$(this).removeClass('bg-light');
		}
	});

	$(document).off('click', '.switch_btn').on('click', '.switch_btn', function(e) {
		e.stopPropagation();
		$(this).attr('disabled', true);
		var email = $(this).attr('data-email');
		var type = 'linked_service';
		getAssumedUserData('linked_service', email);
	});

	$(document).off('click', '.child-sec').on('click', '.child-sec', function(e) {
		e.stopPropagation();
	});

	// LEFT MENU SIDE BAR CLICK EVENT API
	$(document).off('click', '.sidebar-item').on('click', '.sidebar-item', function() {
		$('.sidebar-item').find('a.sidebar-link').removeClass('bg-light');
		$('.sidebar-item').find('a.sidebar-link').removeClass('orange');
		$(this).find('a.sidebar-link').addClass('bg-light');
		$(this).find('a.sidebar-link').addClass('orange');

		var page_name = $(this).attr('data-page-name');
		load_selected_page(page_name,'left_menu');

		if(document.getElementById("searchSidenav")){
			document.getElementById("searchSidenav").style.width = "0";
		}
        setTimeout(function() {
            document.getElementById("main_wrapper_container").style.marginRight = "0";
        }, 100);

		// When click on left side menu item, check if search unpin then clear search data and display selected page
		var search = localStorage.getItem('search');
		if(search == 'unpin') {
			if(localStorage.getItem("filter_data") !== null) {
				$('.clear_search_property_data').trigger('click');
				$('#global_search_form .close').trigger('click');
			}
		}
	});

	$(document).off('click', '#update_dwolla_kyc').on('click','#update_dwolla_kyc', function(e) {
		checkKycDetailStatus('profile');
	});

	$(document).off('click', '.kyc_status_icon').on('click','.kyc_status_icon', function(e) {
		e.preventDefault();
		e.stopPropagation();
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		setTimeout(function(){
			$('#sidebar_heading').html('KYC Status');
	        $('#sidebar_content_area').load('components/kyc_status_info.html');
	    }, 500);
		return false;
	});

	/* KYC field validation */
	$(document.body).off('change', '.user_elder, .user_us_citizen').on('change','.user_elder, .user_us_citizen', function() {
		if(!$('#unverified_kyc_checkbox_1').is(":checked") || !$('#unverified_kyc_checkbox_2').is(":checked")) {
			$('.submit_unverified_customer').attr('disabled', true);
		} else {
			if($('#tenant-kyc-detail-wizard #user_first_name').val() == '' || $('#tenant-kyc-detail-wizard #user_last_name').val() == '' || $('#tenant-kyc-detail-wizard #user_email_id').val() == '' || !$('#tenant-kyc-detail-wizard .user_us_citizen').prop('checked') || $('#tenant-kyc-detail-wizard .user_us_citizen:checked').val() != 'on' || !$('#tenant-kyc-detail-wizard .user_elder').prop('checked') || $('#tenant-kyc-detail-wizard .user_elder:checked').val() != 'on') {
				$('.submit_unverified_customer').attr('disabled', true);
			} else {
				$('.submit_unverified_customer').attr('disabled', false);
			}
		}

		if($('#tenant-kyc-detail-wizard .user_us_citizen:checked').val() == 'on' && $('#tenant-kyc-detail-wizard .user_elder:checked').val() == 'on'){
			// $('#unverified_kyc_checkbox_1').attr('disabled',false);
			// $('#unverified_kyc_checkbox_2').attr('disabled',false);
			$('#unverified_kyc_checkbox_1').closest('div').removeClass('add_opacity');
			$('#unverified_kyc_checkbox_2').closest('div').removeClass('add_opacity');
		} else {
			$('#unverified_kyc_checkbox_1').prop('checked',false);
			$('#unverified_kyc_checkbox_2').prop('checked',false);
			$('#unverified_kyc_checkbox_1').closest('div').addClass('add_opacity');
			$('#unverified_kyc_checkbox_2').closest('div').addClass('add_opacity');
			// $('#unverified_kyc_checkbox_1').attr('disabled',true);
			// $('#unverified_kyc_checkbox_2').attr('disabled',true);
		}
	});

	/* KYC field validation */

	$(document).off('click', '#unverified_kyc_checkbox_1, #unverified_kyc_checkbox_2').on('click','#unverified_kyc_checkbox_1, #unverified_kyc_checkbox_2', function() {
		if(!$('#unverified_kyc_checkbox_1').is(":checked") || !$('#unverified_kyc_checkbox_2').is(":checked")) {
			$('.submit_unverified_customer').attr('disabled', true);
		} else {
			if($('#tenant-kyc-detail-wizard #user_first_name').val() == '' || $('#tenant-kyc-detail-wizard #user_last_name').val() == '' || $('#tenant-kyc-detail-wizard #user_email_id').val() == '' || !$('#tenant-kyc-detail-wizard .user_us_citizen').prop('checked') || $('#tenant-kyc-detail-wizard .user_us_citizen:checked').val() != 'on' || !$('#tenant-kyc-detail-wizard .user_elder').prop('checked') || $('#tenant-kyc-detail-wizard .user_elder:checked').val() != 'on') {
				$('.submit_unverified_customer').attr('disabled', true);
			} else {
				$('.submit_unverified_customer').attr('disabled', false);
			}
		}
	});

	/* KYC field validation */

	$(document).on('click', '#kyc_checkbox_1, #kyc_checkbox_2', function() {
		if(!$('#kyc_checkbox_1').is(":checked") || !$('#kyc_checkbox_2').is(":checked")) {
			$('.submit_kyc_detail').attr('disabled', true);
		} else {
			if($('#kyc-detail-wizard #user_first_name').val() == '' || $('#kyc-detail-wizard #user_last_name').val() == '' || $('#kyc-detail-wizard #user_email_id').val() == '' || $('#kyc-detail-wizard #user_address_line1').val() == '' || $('#kyc-detail-wizard #user_city').val() == '' || $('#kyc-detail-wizard #user_state').val() == '' || $('#kyc-detail-wizard #user_zip_code').val() == '' || $('#kyc-detail-wizard #user_country').val() == '' || $('#kyc-detail-wizard #ssn').val() == '' || $('#kyc-detail-wizard input[name="date_of_birth"]').val() == '' ) {
				$('.submit_kyc_detail').attr('disabled', true);
			} else {
				$('.submit_kyc_detail').attr('disabled', false);
			}
		}
	});


	$(document).on('click', '#property_bank_authorize_checkbox', function() {
		if(!$('#property_bank_authorize_checkbox').is(":checked")) {
			$('.save_property_data').attr('disabled', true);
		} else {
			$('.save_property_data').attr('disabled', false);
		}
	});

	$(document).on('click', '#property_leased_checkbox', function() {
		if(!$('#property_leased_checkbox').is(":checked")) {
			$('.existing_lease_document').addClass('add_opacity');
			if($('#property_bank_authorize_checkbox').is(":checked")) {
				$('.save_property_data').attr('disabled', false);
			}
		} else {
			$('.existing_lease_document').removeClass('add_opacity');
			$('.save_property_data').attr('disabled', true);
		}
	});


	$(document).on('keyup change', '#kyc-detail-wizard #user_first_name, #kyc-detail-wizard #user_last_name, #kyc-detail-wizard #user_email_id, #kyc-detail-wizard #user_address_line1, kyc-detail-wizard #user_city, #kyc-detail-wizard #user_state, #kyc-detail-wizard #user_zip_code, #kyc-detail-wizard #user_country, #kyc-detail-wizard #ssn, #kyc-detail-wizard input[name="date_of_birth"]', function() {

		if($('#kyc-detail-wizard #user_first_name').val() == '' || $('#kyc-detail-wizard #user_last_name').val() == '' || $('#kyc-detail-wizard #user_email_id').val() == '' || $('#kyc-detail-wizard #user_address_line1').val() == '' || $('#kyc-detail-wizard #user_city').val() == '' || $('#kyc-detail-wizard #user_state').val() == '' || $('#kyc-detail-wizard #user_zip_code').val() == '' || $('#kyc-detail-wizard #user_country').val() == '' || $('#kyc-detail-wizard #ssn').val() == '' || $('#kyc-detail-wizard input[name="date_of_birth"]').val() == '' ) {
			$('.submit_kyc_detail').attr('disabled', true);
		} else {
			if(!$('#kyc_checkbox_1').is(":checked") || !$('#kyc_checkbox_2').is(":checked")) {
				$('.submit_kyc_detail').attr('disabled', true);
			} else {
				$('.submit_kyc_detail').attr('disabled', false);
			}
		}
	});

	// Submit KYC Detail
	$(document).off('click', '.submit_unverified_customer').on('click', '.submit_unverified_customer', function() {
		if($unverifiedKycForm.valid() && $('#tenant-kyc-detail-wizard .user_us_citizen:checked').val() == 'on' && $('#tenant-kyc-detail-wizard .user_elder:checked').val() == 'on'){
			$(this).attr('disabled', true);
			$(this).text('Processing...');
			submitUnverifiedKycDetail();
		} else {
			$(this).attr('disabled', true);
		}
	});

	// Submit KYC Detail
	$(document).off('click', '#submit_inquiry').on('click', '#submit_inquiry', function() {
		var query = $('#inquiry_textarea').val();
		if(query != '' && query != null){
			$(this).text('Processing...');
			$(this).attr('disabled', true);
			sendInquiryMail(query);
		}
	});

	// Submit KYC Detail
	$(document).off('click', '.submit_kyc_detail').on('click', '.submit_kyc_detail', function() {
		if($kycForm.valid()){
			var ssn = $('#ssn').val();
			var dwolla_customer_status = $('#dwolla_customer_status').val();
			if(dwolla_customer_status == 0 && ssn.length < 4) {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please enter last four digit of SSN'
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			if(dwolla_customer_status == 2 && ssn.length < 11) {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please enter full 9-digits SSN'
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			var type = 'kyc';
			var address1 = $('#user_address_line1').val();
			var address2 = $('#user_address_line2').val();
			var city = $('#user_city').val();
			var state = $('#user_state').val();
			var zipcode = $('#user_zip_code').val();
			var country = $('#user_country').val();

			verify_usps_address(type,address1,address2,city,state,zipcode);
	    }
	});

	$(document).off('click', '.upload_kyc_document').on('click', '.upload_kyc_document', function() {
		if($kycDocForm.valid()){
			if(kycDocumentDropzone.files.length == 0) {
				$('.submit_agreement_detail').attr('disabled', false);
				var response = {
					"response_data": {
						"success": false,
						"message": 'Please select a document'
					}
				};
				display_error_message(response);
				return false;
			} else {
				$(this).attr('disabled', true);
				$(this).text('Uploading...');
				var formData = new FormData();
				formData.append("user_id", user_id);
				formData.append("first_time_signup", $('#first_time_signup').val);
				formData.append("documentType", $('#kyc_doc_type').val());
				formData.append("file", kycDocumentDropzone.files[0]);

				$.ajax({
					method: 'POST',
					url: api_url+"payment-details/upload-kyc-document",
					data: formData,
					processData: false,
					contentType: false,
					cache: false,
					beforeSend: function(xhr) {
						xhr.setRequestHeader('Authorization', accessToken);
					},
					success: function(response) {
						display_error_message(response);
						if(response.response_data.success == true) {
							$('.upload_kyc_document').attr('disabled', true);
							$('.upload_kyc_document').text('Upload');
							var message_html = '<h4 class="text-center mt-6 green">Your document has been successfully uploaded and is now pending verification from Dwolla. We will notify you once the verification process is complete. Thank you for your patience.</h4>';
						$('.kyc_detail_media').html(message_html);
						} else {
							$('.upload_kyc_document').attr('disabled', false);
							$('.upload_kyc_document').text('Upload');
						}
					}
				});
			}
		}
	});

	// Submit KYC Detail
	$(document).off('click', '.update_dwolla_customer').on('click', '.update_dwolla_customer', function() {
		if($updateCustomerForm.valid()){
			var ssn = $('#customer_ssn').val();
			if(ssn.length < 4) {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please enter last four digit of SSN'
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			var type = 'update_dwolla_customer';
			var address1 = $('#customer_address_line1').val();
			var address2 = $('#customer_address_line2').val();
			var city = $('#customer_city').val();
			var state = $('#customer_state').val();
			var zipcode = $('#customer_zip_code').val();
			var country = $('#customer_country').val();

			verify_usps_address(type,address1,address2,city,state,zipcode);
	    }
	});

	/* Add Bank Field validation */

	$(document).on('click', '#electronic_checkbox', function() {
		if(!$('#electronic_checkbox').is(":checked")) {
			$('.save_payment_detail').attr('disabled', true);
		} else {
			if($('#payment-detail-wizard #bank_name').val() == '' || $('#payment-detail-wizard #account_type').val() == '' || $('#payment-detail-wizard #routing_number').val() == '' || $('#payment-detail-wizard #account_number').val() == '' || $('#payment-detail-wizard #confirm_account_number').val() == '') {
				$('.save_payment_detail').attr('disabled', true);
			} else {
				$('.save_payment_detail').attr('disabled', false);
			}
		}
	});

	$(document).on('keyup change', '#payment-detail-wizard #bank_name, #payment-detail-wizard #account_type, #payment-detail-wizard #routing_number, #payment-detail-wizard #account_number, #payment-detail-wizard #confirm_account_number', function() {

		if($('#payment-detail-wizard #bank_name').val() == '' || $('#payment-detail-wizard #account_type').val() == '' || $('#payment-detail-wizard #routing_number').val() == '' || $('#payment-detail-wizard #account_number').val() == '' || $('#payment-detail-wizard #confirm_account_number').val() == '') {
			$('.save_payment_detail').attr('disabled', true);
		} else {
			if(!$('#electronic_checkbox').is(":checked")) {
				$('.save_payment_detail').attr('disabled', true);
			} else {
				$('.save_payment_detail').attr('disabled', false);
			}
		}
	});

	$(document).off('mousedown', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon').on('mousedown', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon', function() {
		var element = $(this).attr('data-type');
		$('#'+element).attr('type','text');
	}).off('mouseup mouseleave', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon').on('mouseup mouseleave', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon', function() {
		var element = $(this).attr('data-type');
		$('#'+element).attr('type','password');
	});

	// Get zoho document status
	$(document).off('click', '.check_zoho_document_status').on('click', '.check_zoho_document_status', function(){
		var user_id = user.user_details.user_id;
	    checkZohoDocumentStatus(user_id);
	});

	// Search list
	$(document).off('keyup', '.search_input').on('keyup', '.search_input', function(){
	    var text = $(this).val().toLowerCase();
	    $('.list-group-item').hide();
	    $('.list-group-item .media').each(function(){
	        if($(this).text().toLowerCase().indexOf(""+text+"") != -1 ){
	            $(this).closest('.list-group-item').show();
	        }
	    });
	});

	// Applications left side user click event api
	$(document).off('click', '.pending_verification_user').on('click', '.pending_verification_user', function(e) {
		if(user.permissionData['USERS']["feature_items['detail']"]) {
			e.stopPropagation();
			$('#user_search_container').addClass('d-none');
			$('.card_list_heading').hide();
			$('#user_datatable_detail_info_container').addClass('d-none');
		    var user_id = $(this).attr('data-id');
		    var user_type = $(this).attr('data-type');
		    var user_name = $('#u_name_'+user_id).html();
		    openLoadingDialog($('#user_detail_info_container'));
		    setTimeout(function(){
		    	$('#user_detail_info_container').load('components/user/user_detail.html', user_id , function(){
		    		var layout = $('#user_list_layout').val();
			        getPendingUserData(user_id);
			        $('.user_name').html(user_name);
			        $('#navbar_role_name').html(user_name+' <small class="fs-14">('+user_type+')</small>');
			        $('.back_button').attr('data-layout',layout);
			    });
			}, 500);
		}
	});

	// Delete user
	$(document).off('click', '.delete_user').on('click', '.delete_user', function(e) {
		e.stopPropagation();
	    var user_id = $(this).attr('data-id');
	    ConfirmDelete('User', user_id);
	});

	// Applications left side user click event api
	/*$(document).off('click', '.approve_user, .reject_user').on('click', '.approve_user, .reject_user', function() {
	    var user_id = $('#selected_user_id').val();
	    var status = $(this).attr('data-status');
	    approveRejectUserApplication(user_id,status);
	});*/

	// Delete Background and Credit Report file
	$(document).off('click', '.delete_report_file').on('click', '.delete_report_file', function() {
	    var id = $(this).attr('data-id');
	    var module_name = $(this).attr('data-module');
	    var type = '';
	    if(module_name == 'BackgroundCheckReport') {
            type = 'Background Check Report';
        } else if(module_name == 'CreditReport') {
            type = 'Credit Report';
        }
	    ConfirmDelete(type, id);
	});

	// Skip Payment Detail
	$(document).off('click', '.skip_payment').on('click', '.skip_payment', function() {
		var user_id = user.user_details.user_id;
		skipPaymentDetail(user_id);
	});

	// Save Payment Detail
	$(document).off('click', '.save_payment_detail').on('click', '.save_payment_detail', function() {
		if($paymentForm.valid()){
	    	savePaymentDetail();
	    }
	});

	// Verify Bank Detail
	$(document).off('click', '.verify_bank_detail').on('click', '.verify_bank_detail', function() {
		var payment_detail_id = $(this).attr('data-id');
		$('#microDepositModal #micro_deposit_form_container').load('components/bank/verify_micro_deposit_form.html', payment_detail_id, function(){
			$('#payment_detail_id').val(payment_detail_id);
		});
	});

	// Verify Micro Deposit
	$(document).off('click', '.verify_micro_deposit').on('click', '.verify_micro_deposit', function() {
		var payment_detail_id = $('#payment_detail_id').val();
		var micro_deposit_1 = $('#micro_deposit_1').val();
		var micro_deposit_2 = $('#micro_deposit_2').val();
		if($microDepositAddForm.valid()){
			verify_micro_deposit(payment_detail_id,micro_deposit_1,micro_deposit_2);
		}
	});

	// Delete Bank Detail
	$(document).off('click', '.delete_bank_detail').on('click', '.delete_bank_detail', function() {
	    var id = $(this).attr('data-id');
	    var message = $(this).attr('data-message');
	    if(message.trim() != '') {
	    	var response = {
                "response_data": {
                	"success": false,
                  	"message": message
                }
            };
            display_error_message(response);
            return false;
	    } else {
	    	ConfirmDelete('Bank', id);
	    }
	});

	/*$(document).off('click', '.restore_bank_detail').on('click', '.restore_bank_detail', function() {
	    var id = $(this).attr('data-id');
	    ConfirmRestore('Bank', id);
	});*/

	$(document).off('click', '.initiate_micro_deposit').on('click', '.initiate_micro_deposit', function() {
	    var id = $(this).attr('data-id');
	    initiateMicroDeposit(id);
	});

	$(document).off('click', '.refresh_account_detail').on('click', '.refresh_account_detail', function() {
	    var id = $(this).attr('data-id');
		refreshAccountDetail(id);
	});

	// Lease Extension button
	$(document).off('click', '.lease_extension_btn').on('click', '.lease_extension_btn', function(){
		var key = $(this).attr('data-key');
		var type = $(this).attr('data-value');
		var tenant_lease_id = $(this).attr('data-tenant-lease-id');
		if(type == 'Yes') {
			var vacant_date = ''
			sendLeaseExtensionNotification(key, type, tenant_lease_id, vacant_date);
		} else if(type == 'No') {
			$('#vacantDateModal').modal('show');
			$('#e_key').val(key);
			$('#e_type').val(type);
			$('#e_tenant_lease_id').val(tenant_lease_id);
			addVacantDateValidation();
		}
	});

	// Add vacanting date
	$(document).off('click', '.add_vacant_date').on('click', '.add_vacant_date', function(){
		if($vacantForm.valid()){
			$(this).attr('disabled', true);
			var key = $('#e_key').val();
			var type = $('#e_type').val();
			var tenant_lease_id = $('#e_tenant_lease_id').val();
			var vacant_date = $('#vacant_date > input').val();
			sendLeaseExtensionNotification(key, type, tenant_lease_id, vacant_date);
		}
	});

	// Refund Security Deposit
	$(document).off('click', '.refund_security_deposit').on('click', '.refund_security_deposit', function(){
		$('#refundSecurityAmountModal').modal('show');
		var lease_id = $(this).attr('data-id');
		var sd_amount = $(this).attr('data-sd-amount');
		var tenants_table_id = $(this).attr('data-tenants-table-id');
		var tenants_user_id = $(this).attr('data-tenant-user-id');
		var payment_method_id = $(this).attr('data-payment-id');
		$('#refundSecurityAmountModal #refund_security_deposit_container').load('components/property/refund_security_deposit.html', function(){
			$('#selected_lease_id').val(lease_id);
			$('#tenants_table_id').val(tenants_table_id);
			$('#tenants_user_id').val(tenants_user_id);
			$('#security_refunded_amount').val(sd_amount);
			getUserPaymentMethod(tenants_user_id, payment_method_id);
		});
	});

	// Save Refund Security deposit data
	$(document).off('click', '.save_security_deposit_refund_data').on('click', '.save_security_deposit_refund_data', function(){
		if($refundSDForm.valid()){
			$(this).attr('disabled', true);
			saveSecurityDepositRefundData();
		}
	});

	// Authorize securituy deposit refund data
	$(document.body).off('change', '#authorize_security_deposit_refund').on('change', '#authorize_security_deposit_refund', function(){
		if($(this).is(":checked")) {
			$('.save_security_deposit_refund_data').attr('disabled', false);
		} else {
			$('.save_security_deposit_refund_data').attr('disabled', true);
		}
	});

	/* Start : Myaccount */

	// Load add New lease page
	$(document).off('click', '.edit_profile').on('click', '.edit_profile', function(){
		var field = $(this).attr('data-field');
		var open_from = 'edit_link';
		$('#editProfileModal').modal('show');
		getProfileEditForm(open_from, field, '');
	});

	// Load edit address
	$(document).off('click', '.edit_address').on('click', '.edit_address', function(){
		var user_service_id = $(this).attr('data-user-service-id');
		var user_service_address_id = $(this).attr('data-user-service-address-id');
		$('#editAddressModal').modal('show');
		getAddressEditForm(user_service_id, user_service_address_id);
	});

	// Load add New payment page
	$(document).off('click', '#open_bank_sidebar').on('click', '#open_bank_sidebar', function(){
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		if($(this).attr('data-type') == 'kyc'){
			$('#sidebar_heading').html('Add KYC');
			$('#sidebar_content_area').load('components/bank/add_kyc.html', function(){
				if(user_service_name == po_role){
					$('#kyc-detail-wizard').removeClass('d-none');
					$('#tenant-kyc-detail-wizard').remove();
				} else if(user_service_name == t_role){
					$('#kyc-detail-wizard').remove();
					$('#tenant-kyc-detail-wizard').removeClass('d-none');
				}
			});
		} else {
			$('#sidebar_heading').html('Add Bank');
			$('#sidebar_content_area').load('components/bank/description.html');
		}
	});

	// Load add New payment page
	$(document).off('click', '.add_new_payment').on('click', '.add_new_payment', function(){
		$('#paymentAddModal').modal('show');
		openLoadingDialog($('#payment_form_container'));
		$('#paymentAddModal #payment_form_container').load('components/bank/add_bank.html', function(){
			$('.card-actions').removeClass('d-none');
			$('.skip_payment').addClass('d-none');
			$('#ssn').mask('0000');
			$('#kyc-detail-wizard').addClass('d-none');
			$('#add_bank_methods').removeClass('d-none');
			//$('#link-account').trigger('click');
		});
	});

	// Authorize first month payment
	$(document).off('click', '.authorize_fm_rent_btn').on('click', '.authorize_fm_rent_btn', function(){
		var user_id = user.user_details.user_id;
		var property_id = $(this).attr('data-property-id');
		var lease_id = $(this).attr('data-lease-id');
		openLoadingDialog($('#review_first_month_payment_container'));
		setTimeout(function(){
			getFMPaymentDetail(user_id, property_id, lease_id);
		}, 500);
	});

	$(document).off('click', '#fm_payment_authorize_checkbox').on('click', '#fm_payment_authorize_checkbox', function(){
		if(!$('#fm_payment_authorize_checkbox').is(":checked")) {
			$('.authorize_first_month_payment').attr('disabled', true);
		} else {
			$('.authorize_first_month_payment').attr('disabled', false);
		}
	});

	$(document).off('click', '.authorize_first_month_payment').on('click', '.authorize_first_month_payment', function(){
		$('.authorize_first_month_payment').attr('disabled', true);
		var paymentArray = [];
	    $('.first_month_payment_hidden_input').each(function(key, value) {
		    var fmp_id = $('#first_month_payment_'+key).val();
		    var payment_id = $('#fm_payment_detail_'+key).val();
		    paymentArray.push({
				'sp_id' : fmp_id,
				'payment_id' : payment_id
			});
		});

		var is_payment_authorized = $('#is_payment_authorized').val();
		var is_allowed_to_authorized = $('#is_allowed_to_authorized').val();
		if(is_payment_authorized == 0 || is_allowed_to_authorized == 1) {
			if($fmPaymentForm.valid()){
				ConfirmPayment('fmp', JSON.stringify(paymentArray));
			} else {
				$('.authorize_first_month_payment').attr('disabled', false);
			}
		} else {
			// DO not need to confirm because payment already authorized
			authorizeFirstMonthPaymentData(JSON.stringify(paymentArray));
		}
	});

	// Load Rent collection page
	$(document).off('click', '.authorize_rent_btn').on('click', '.authorize_rent_btn', function(){
		var tenant_id = user.user_details.user_id;
		var property_id = $(this).attr('data-property-id');
		var scheduled_payment_id = $(this).attr('data-scheduled-payment-id');
		openLoadingDialog($('#rent_collection_container'));
		setTimeout(function(){
			//$('#payNowModal #rent_collection_container').load('components/myaccount/rent_collection.html');
			getSchedulePaymentDetail(tenant_id, property_id, scheduled_payment_id);
		}, 500);
	});

	$(document).off('click', '#payment_authorize_checkbox').on('click', '#payment_authorize_checkbox', function(){
		if(!$('#payment_authorize_checkbox').is(":checked")) {
			$('.save_rent_collection_data').attr('disabled', true);
		} else {
			$('.save_rent_collection_data').attr('disabled', false);
		}
	});

	$(document).off('click', '.enable_disable_autopay_btn').on('click', '.enable_disable_autopay_btn', function(){
		var user_id = user.user_details.user_id;
		var lease_id = $(this).attr('data-id');
		var property_id = $(this).attr('data-property-id');
		$('#enableAutopayModal').modal('show');
		// var type = $(this).attr('data-type');
		// if(type == 'enable') {
		// 	$('#autopay_enable_disable').val(1);
		// 	$('#autopay_title').html('Enable Autopay');
		// } else if(type == 'disable') {
		// 	$('#autopay_enable_disable').val(0);
		// 	$('#autopay_title').html('Disable Autopay');
		// }

		$('#autopay_title').html('Update Autopay Details');

		openLoadingDialog($('#autopay_container'));
		getLeaseDetailById('autopay', lease_id);
	});

	// $(document).off('click', '#autopay_authorize_checkbox').on('click', '#autopay_authorize_checkbox', function(){
	// 	if(!$('#autopay_authorize_checkbox').is(":checked")) {
	// 		$('.save_autopay_data').attr('disabled', true);
	// 	} else {
	// 		$('.save_autopay_data').attr('disabled', false);
	// 	}
	// });

	$(document.body).off('change', '#enable_autopay').on('change', '#enable_autopay', function(){
		if(this.checked){
			var terms = 'Note : By selecting Enable Autopay, I agree and authorize scheduled monthly rent payments to Rent Synergy on the scheduled autopay date for the amount mentioned above from the selected bank account. These payments will be processed by the Dwolla payment processing system. I understand that I can cancel this autopay option by selecting Disable Autopay option from my accounts page.';
		} else {
			var terms = 'Note : By selecting Disable Autopay, I am cancelling the automatic procesing of future scheduled rent payments. I understand that future monthly rent payments will be authorized manually and in the absence of doing so may incur additional late fees.';
		}

		$('.terms_of_autopay').html(terms);
	});

	$(document).off('click', '.save_autopay_data').on('click', '.save_autopay_data', function(){
		$(this).text('Processing...');
		$(this).attr('disabled', true);
		// var autopay = $('#autopay_enable_disable').val();

		if($('#enable_autopay:checked').val() == 'on'){
			var autopay = 1;
		} else {
			var autopay = 0;
		}

		var lease_id = $('#autopay_lease_id').val();
		var property_id = $('#autopay_property_id').val();
		var day = $('#autopay_date').val();
		var payment_detail_id = $('#autopay_payment_detail_id').val();
		//alert(autopay); alert(lease_id); alert(property_id); alert(day); alert(payment_detail_id); return false;
		// if(autopay == 1) {
			if($autopayForm.valid()){
				// $('.save_autopay_data').attr('disabled', true);
				saveAutopayData('autopay', lease_id, property_id, payment_detail_id, day, autopay);
			}
		// } else {
		// 	$('.save_autopay_data').attr('disabled', true);
		// 	saveAutopayData('autopay', lease_id, property_id, 0, 0, autopay);
		// }
	});

	/*$(document).off('change', '.bank_dd').on('change', '.bank_dd', function(){
		if($(this).val() > 0) {
			var selected_bank = $(this).children(':selected').text();
			$('#lf_payment_method_container').removeClass('d-none');
			$('#late_fee_payment_method').text(selected_bank);
		} else {
			$('#lf_payment_method_container').addClass('d-none');
			$('#late_fee_payment_method').text('');
		}
	});*/

	$(document.body).off('change', '#usersNameDD,#usersEmailDD,#usersPhoneDD,#usersStatusDD').on('change', '#usersNameDD,#usersEmailDD,#usersPhoneDD,#usersStatusDD', function(){
		var layout = $('#user_list_layout').val();
		getPendingUserList(layout);
	});

	$(document).off('click', '.save_rent_collection_data').on('click', '.save_rent_collection_data', function(){
		if($rentCollectionForm.valid()){
			$('.save_rent_collection_data').attr('disabled', true);
			var paymentArray = [];
		    $('.schedule_payment_hidden_input').each(function(key, value) {
		        var sp_id = $('#scheduled_payment_'+key).val();
			    var payment_id = $('#payment_detail_'+key).val();
			    var payment_due_date = $('#payment_due_date_'+key).val();
			    paymentArray.push({
					'sp_id' : sp_id,
					'payment_id' : payment_id,
					'payment_due_date':payment_due_date
				});
			});
			ConfirmPayment('rent',JSON.stringify(paymentArray));
		} else {
			$('.save_rent_collection_data').attr('disabled', false);
		}
	});

	/* Link property to bank */
	$(document).off('click', '.bank_property_dd, #property_bank_authorize_checkbox').on('click', '.bank_property_dd, #property_bank_authorize_checkbox', function(){
		if($('#property_bank_authorize_checkbox').is(":checked") && $('.bank_property_dd').val() > 0) {
			$('.authorize_rent_subscription_button').attr('disabled', false);
		} else {
			$('.authorize_rent_subscription_button').attr('disabled', true);
		}
	});

	$(document).off('click', '.link_bank_and_property').on('click', '.link_bank_and_property', function(e){
		e.stopPropagation();
		var module_name = $(this).attr('data-module');
		var user_id = user.user_details.user_id;
		if(module_name == 'Bank') {
			var payment_method_id = $(this).attr('data-id');
			var payment_method_name = $(this).attr('data-payment-method');
			$('#linkPropertyModal').modal('show');
			openLoadingDialog($('#bp_dropdown_container'));
			setTimeout(function(){
				getPropertyListForPayment(user_id, payment_method_id, payment_method_name);
			}, 500);
		} else if(module_name == 'Property') {
			var property_id = $(this).attr('data-id');
			var property_name = $(this).attr('data-name');
			var lease_count = $(this).attr('data-lease-count');
			var lease_message = $(this).attr('data-lease-message');
			var payment_count = $(this).attr('data-payment-id');
			var payment_message = $(this).attr('data-payment-message');

			$('#linkPropertyModal').modal('show');
			openLoadingDialog($('#bp_dropdown_container'));
			setTimeout(function(){
				getBankListForProperty(user_id, property_id, property_name);
			}, 500);
		}
	});

	$(document).off('click', '.authorize_rent_subscription_button').on('click', '.authorize_rent_subscription_button', function(){
		if($('.bank_property_dd').val() > 0) {
			var is_authorized = 0;
			if($('#property_bank_authorize_checkbox').is(":checked")) {
				is_authorized = 1;
			} else {
				var response = {
	                "response_data": {
	                	"success" : false,
	                  	"message": 'Please select agree checkbox.'
	                }
	            };
	            display_error_message(response);
				return false;
			}

			var module_name = $(this).attr('data-module');
			if(module_name == 'Bank') {
				var property_id = $('.bank_property_dd').val();
				var payment_method_id = $('#selected_payment_method_id').val();
			} else if(module_name == 'Property') {
				var payment_method_id = $('.bank_property_dd').val();
				var property_id = $('#selected_property_id').val();
			}

			authorizeRentDepositData(module_name, property_id, payment_method_id, is_authorized);
		} else {
			var response = {
                "response_data": {
                	"success" : false,
                  	"message": 'Select payment method for rent deposit.'
                }
            };
            display_error_message(response);
			return false;
		}
	});

	/* End : Myaccount */

	$(document).off('click', '.get_assumed_user_data').on('click', '.get_assumed_user_data', function() {
		var id = $(this).attr('data-id');
	    var name = $(this).attr('data-name');
	    var email = $(this).attr('data-email');
	    getAssumedUserData('add', email);
	});

	$(document).off('click', '.remove_assumed_identity').on('click', '.remove_assumed_identity', function() {
		var email = localStorage.getItem("primary");
		getAssumedUserData('remove', email);
	});


	/* Properties APIS */

	// Get properties chat window
	$(document).off('click', '.get_property_chat').on('click', '.get_property_chat', function() {
		var user_id = user.user_details.user_id;
	    var property_id = $(this).attr('data-id');
	    var property_name = $(this).attr('data-name');
	    $('#main_container').load('components/chat.html', function() {
	    	$('#chatroom_property_id').val(property_id);
	    	$('.property_name_span').html(' - '+property_name);
			loadChatroomData(user_id,property_id);
		});
	});

	// Load add New property page
	$(document).off('click', '.add_new_chatroom').on('click', '.add_new_chatroom', function(){
		var property_user_datatable = initDatatable('property-user-datatable');
		var chatroom_property_id = $('#chatroom_property_id').val();
		getPropertyUserList(chatroom_property_id);
	});

	// Save selected chatroom data for user
	$(document).off('click', '.save_chatroom_data').on('click', '.save_chatroom_data', function() {

		if($("input:checkbox[name=property_user_name]:checked").length == 0) {
			var response = {
                "response_data": {
                	"success" : false,
                  	"message": 'Please select atleast one user.'
                }
            };
            display_error_message(response);
			return false;
		}

	    var property_id = $('#chatroom_property_id').val();
	    var user_id = user.user_details.user_id;
	    var chatUserArray = [];
	    //add logged in user to chatrrom members
	    chatUserArray.push({
			'user_id' : user_id
		});

		// add selected user to chatroom members
		$("input:checkbox[name=property_user_name]:checked").each(function() {
        	var chat_user_id = $(this).attr('data-user-id');
        	var user_name = $(this).attr('data-username');
			chatUserArray.push({
				'user_id' : chat_user_id
			});
        });

		// Create chatroom name
        var name = $("input:checkbox[name=property_user_name]:checked").map(function(i, opt) {
		  return $(this).attr('data-user-id');;
		}).toArray().join(',');
		name += ','+user_id;

		saveChatroomData(user_id,property_id,name,JSON.stringify(chatUserArray));
	});

	// Applications left side user click event api
	$(document).off('click', '.chatroom_list').on('click', '.chatroom_list', function() {
		var chatroom_id = $(this).attr('data-id');
		$(".chatroom_list").removeClass('active');
    	$(this).addClass('active');
    	getChatroomMessage(chatroom_id);
	});

	// Applications left side user click event api

	$(document).off('keypress', '#chatroom_message').on('keypress', '#chatroom_message', function(event) {
		var key = event.which;
		if(key == 13)  // the enter key code
		{
		    $('#save_chatroom_message').trigger('click');
		    return false;
		}
	});

	$(document).off('click', '#save_chatroom_message').on('click', '#save_chatroom_message', function() {
		var user_id = user.user_details.user_id;
		var chatroom_id = $('#chatroom_id').val();
		var chatroom_message = $('#chatroom_message').val();
    	saveChatroomMessage(user_id,chatroom_id,chatroom_message);
	});

	// Change property layout

	$(document).off('click', '.change_layout').on('click', '.change_layout', function(){
		var module_name = $(this).attr('data-module');
	    var layout = $(this).attr('id');
	    changeLayout(layout,module_name);
	});

	// Get user properties list
	$(document).off('click', '.back_button').on('click', '.back_button', function() {
		$('.card_list_heading').show();
		var module_name = $(this).attr('data-module');
		if(module_name == 'users') {
			var layout = $(this).attr('data-layout');
			$('#navbar_role_name').html('Users');
			openLoadingDialog($('#user_detail_info_container'));

			if(localStorage.getItem('filter_data') != null) {
				var open_for = 'search';
				setTimeout(function(){
				    $('#main_container').load('components/users.html', open_for, function(){
				    	$('#open_for').val(open_for);
				    	getUsersFilterData(localStorage.getItem('filter_data'))
				    });
				}, 500);
			} else {
				$('#user_search_container').removeClass('d-none');
				getPendingUserList(layout);
			}
		} else if(module_name == 'property') {
			var layout = $(this).attr('data-layout');
			$('#navbar_role_name').html('Properties');
			if(localStorage.getItem('filter_data') != null) {
				openLoadingDialog($('.property_data_container'));
				var open_for = 'search';
		        $('#main_container').load('components/properties.html', open_for, function() {
		        	$('#open_for').val(open_for);
		        	getPropertiesFilterData(localStorage.getItem('filter_data'));
	            });
			} else {
				if($('#property_search_container').length > 0) {
					openLoadingDialog($('.property_data_container'));
					window.history.pushState("", "", '?action=properties');
					if(user_service_name == sa_role){
						$('#property_search_container').removeClass('d-none');
					}
					loadVerticalProperty();
				} else {
					window.history.pushState("", "", '?action=properties');
					$('#main_container').load('components/properties.html');
				}
			}

			if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
				setTimeout(function(){
					disableForAssumeIdentity();
				}, 2000);
			}
		} else if(module_name == 'ticket') {
			openLoadingDialog($('#main_container'));
			setTimeout(function(){
			    $('#main_container').load('components/support.html');
			}, 500);

			if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
				setTimeout(function(){
					disableForAssumeIdentity();
				}, 2000);
			}
		}
	});

	// Get user properties list
	$(document).off('click', '.user_properties_list').on('click', '.user_properties_list', function() {
		var property_id = $(this).attr('data-id');
		var payment_status = $(this).attr('data-status');
		$('#property_search_container').addClass('d-none');
		//if(user_service_name == sa_role || payment_status == 4) {
			var rand_num1 = random_number(11);
			var rand_num2 = random_number(20);
			var pid = rand_num1+property_id+rand_num2;
			window.history.pushState("", "", '?action=properties&pid='+pid);
			loadPropertyDetailLayout(property_id, 'vertical', '');
			localStorage.removeItem("account_role");
		//}
	});

	// Load add New lease page
	$(document).off('click', '.edit_property_detail').on('click', '.edit_property_detail', function(){
		$('#PropertyDetailModal').modal('show');
		var module_name = $(this).attr('data-module');
		var property_id = $(this).attr('data-id');
		$('#PropertyDetailModal #property_detail_container_update').load('components/property/property_detail_edit_form.html', function(){
			getPropertyDetailEditForm();
		});
	});

	// When click on property table row display property detail
	$(document).off('click', '.property_table_row').on('click', '.property_table_row', function(){
		var property_id = $(this).attr('data-id');
		var payment_status = $(this).attr('data-status');
	    if(user_service_name == sa_role || payment_status == 4) {
	    	var rand_num1 = random_number(11);
			var rand_num2 = random_number(20);
			var pid = rand_num1+property_id+rand_num2;
			window.history.pushState("", "", '?action=properties&pid='+pid);
			loadPropertyDetailLayout(property_id, 'table', '');
		}
	});

	$(document).off('click', '.late_fee_checkbox').on('click', '.late_fee_checkbox', function(){
		var property_id = $(this).attr('data-id');
		if($(this).is(":checked")) {
			var late_fee_flag = 1;
		} else {
			var late_fee_flag = 0;
		}
		updateLateFeeValue(property_id,late_fee_flag);
	});

	$(document).off('mouseover', '.dz-image-preview').on('mouseover', '.dz-image-preview', function(){
		$('.dz-error-mark').css('opacity',1);
	}).off('mouseout', '.dz-image-preview').on('mouseout', '.dz-image-preview', function(){
		$('.dz-error-mark').css('opacity',0);
	});

	// Load Property setup page
	// $(document).off('click', '.property_setup_fee').on('click', '.property_setup_fee', function(){
	// 	var property_id = $(this).attr('data-id');
	// 	var user_id = $(this).attr('data-user-id');
	// 	var payment_details_id = $(this).attr('data-payment-id');
	// 	var property_name = $(this).attr('data-property-name');
	// 	var property_setup_fee_authorized = $(this).attr('data-property-setup-fee_authorized');
	// 	if(property_setup_fee_authorized == 1 && payment_details_id == 0) {
	// 		$('.modal-backdrop').remove();
	// 		$('#linkPropertyModal').modal('show');
	// 		openLoadingDialog($('#bp_dropdown_container'));
	// 		setTimeout(function(){
	// 			getBankListForProperty(user_id, property_id, property_name);
	// 		}, 500);
	// 	} else {
	// 		checkIfPropertySetupPlanAvailable(property_id);
	// 	}
	// });

	// Load Property Pending Review
	$(document).off('click', '.property_pending_review .card').on('click', '.property_pending_review .card', function(e){
		e.preventDefault();
		if(user_service_name != sa_role) {
			var property_id = $(this).parent('.property_pending_review').attr('data-id');
			var response = {
	            "response_data": {
	                "success": false,
	                "message": 'Property is under review by admin'
	            }
	        };
	        display_error_message(response);
	    }
	});

	/*$(document).off('keyup', '#coupon_code').on('keyup', '#coupon_code', function(){
		var coupon_code = $(this).val();
		if($.trim(coupon_code) == '') {
			$('.apply_coupon_code').attr('disabled',true);
		} else {
			$('.apply_coupon_code').attr('disabled',false);
		}
	});*/

	// Coupon code
	$(document).off('click', '.apply_coupon_code').on('click', '.apply_coupon_code', function(){
		var coupon_code = $('#coupon_code').val();
		if($.trim(coupon_code) == '') {
			var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Coupon code required'
                }
            };
            display_error_message(response);
		} else {
			var trigger_from = "apply_button_clicked";
			var data_for = $(this).attr('data-for');
			applyCouponCode('property_setup_fee', coupon_code, trigger_from, data_for);
		}
	});

	// Remove Coupon code
	$(document).off('click', '.remove_coupon').on('click', '.remove_coupon', function(){
		$('#coupon_code').attr('readonly',false);
		$('#coupon_code').val('');
		$('#applied_coupon').html('');
		$('#apply_coupon_code').text('Apply');
		$('#apply_coupon_code').addClass('apply_coupon_code');
		$('#apply_coupon_code').removeClass('remove_coupon');
		$("#property_coupon_id").val(0);
		//$("#property_plan_id").val(0);

		//var property_id = $('#newly_added_property_id').val();
		//checkIfPropertySetupPlanAvailable(property_id);

		/*var property_id = $('#edit_property_id').val();
		var subscription_plan_id = $('#subscription_plan_id').val();
		if(property_id > 0) {
			removeCouponCode(subscription_plan_id);
		}*/

		$('#subscription_plan_id').val(0);
	});

	// Property complete transaction
	$(document).off('click', '#property_payment_agree').on('click', '#property_payment_agree', function(){
		if($('#property_payment_agree').is(":checked")) {
			$('.complete_property_transaction').attr('disabled', false);
		} else {
			$('.complete_property_transaction').attr('disabled', true);
		}
	});

	// Property complete transaction
	$(document).off('click', '.complete_property_transaction').on('click', '.complete_property_transaction', function(){
		if($propertySetupCheckoutForm.valid()){
			completePropertyTransaction();
		}
	});

	// Process Property setup fees
	$(document).off('click', '.process_property_setup_fee').on('click', '.process_property_setup_fee', function(e){
		e.stopImmediatePropagation();
		//$('.process_property_setup_fee').attr('disabled', true);
		$('.process_property_setup_fee').addClass('add_opacity');
		$('.process_property_setup_fee').html('Processing');
		var property_id = $(this).attr('data-id');
		processPropertySetupFees(property_id);		
	});

	// Open waive late fee modal
	$(document).off('click', '.open_waive_late_fee_modal').on('click', '.open_waive_late_fee_modal', function(e){
		e.stopPropagation();
		var sp_id = $(this).attr('data-id');
		var property_id = $(this).attr('data-property-id');
		var property_name = $('#p_name_'+property_id).attr('title');

		$('#waiveLateFeeModal').modal('show');
		openLoadingDialog($('#waive_late_fee_container'));
		setTimeout(function(){
			getPropertyLateFeeDetail(sp_id, property_id, property_name);
		}, 500);
	});

	// $(document).off('click', '.enable_disable_late_fee').on('click', '.enable_disable_late_fee', function(e){
	// 	e.stopPropagation();
	// 	var sp_id = $(this).attr('data-id');
	// 	var type = $(this).attr('data-type');

	// 	setTimeout(function(){
	// 		enableDisableLateFee(sp_id, type);
	// 	}, 500);
	// });

	$(document).off('click', '.enable_disable_late_fee').on('click', '.enable_disable_late_fee', function(e){
		e.stopPropagation();
		var ps_id = $(this).attr('data-id');
		var type = $(this).attr('data-type');
		ConfirmLateFeeEnableDisable('Late Fee', ps_id, type);
	});

	$(document).off('click', '.change_payment_due_date').on('click', '.change_payment_due_date', function(e){
		e.stopPropagation();
		var ps_id = $(this).attr('data-id');
		var due_date = $(this).attr('data-due-date');
		var ps_month = $(this).attr('data-month');	
		var ps_payment_type = $(this).attr('data-payment-type');
		
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Change Payment Due Date');
		setTimeout(function(){
			changePaymentDueDate(ps_id, due_date, ps_month, ps_payment_type);
		}, 500);

		// to hide kebab menu.
		$('body').trigger('click');	
	});

	// Change payment due date
	$(document).off('click', '.save_payment_due_date').on('click', '.save_payment_due_date', function(e){
		// e.stopPropagation();
		if($validatePaymentDueDate.valid()){
			var payment_schedule_id = $('#payment_schedule_id').val();
			savePaymentDueDate(payment_schedule_id);
		}
	});

	$(document).off('click', '.waive_late_fee').on('click', '.waive_late_fee', function(){
		$('.waive_late_fee').attr('disabled', true);
		var spArray = [];
	    $('.waived_late_fee_input').each(function(key, value) {
		    var sp_id = $(this).attr('data-id');
		    var outstanding_amount = $('#late_fee_amount_'+sp_id).val();
		    var amount = $(this).val();
	        spArray.push({
				'sp_id' : sp_id,
				'amount' : amount,
				'outstanding_amount' : outstanding_amount,
			});
		});
		saveWaivedLateFeeData(JSON.stringify(spArray));
	});

	// Change lease dropdown and get tenants
	$(document.body).off('change', '.add_document_lease_dd').on('change', '.add_document_lease_dd', function(e){
		e.stopPropagation();
		var lease_id = $(this).val();
		if(lease_id > 0) {
			/* start : #635 This is worked when we add signature document from documents */
			$('.account_type_t').removeClass('d-none');
			$('.document_title').removeClass('d-none');
			$('.save_template_data').attr('disabled', true);
			/* end : #635 This is worked when we add signature document from documents */
			$('.add_new_lease_agreements').attr('disabled', false);
		} else {
			/* start : #635 This is worked when we add signature document from documents */
			$('.account_type_t').addClass('d-none');
			$('.document_title').addClass('d-none');
			$('.save_template_data').attr('disabled', false);
			/* end : #635 This is worked when we add signature document from documents */

			$('.add_new_lease_agreements').attr('disabled', true);
		}
	});

	// Change lease dropdown and get tenants
	$(document.body).off('change', '.lease_property_dd').on('change', '.lease_property_dd', function(e){
		e.stopPropagation();
		var lease_id = $(this).val();
		openLoadingDialog($('#tenant_dropdown_container'));
		setTimeout(function(){
			if(lease_id > 0) {
				getTenantListForLease(lease_id);
			} else {
				$('.save_first_month_rent_collection_data').attr('disabled', true);
				$('#tenant_dropdown_container').empty();
				return false;
			}
		}, 500);
	});

	// Change Tenant dropdown and get lease detial
	$(document.body).off('change', '.tenant_property_dd').on('change', '.tenant_property_dd', function(e){
		e.stopPropagation();
		var property_id = $("#selected_property_id").val();
		var lease_id = $("#selected_lease_id").val();
		var tenant_id = $(this).val();

		if(tenant_id != "") {
			$('.save_first_month_rent_collection_data').attr('disabled', false);
			if($('#first_month_rent_collection_container').is(':empty')) {
				openLoadingDialog($('#first_month_rent_collection_container'));
				getFirstMonthPaymentDetail(property_id,lease_id);
			}
		} else {
			$('.save_first_month_rent_collection_data').attr('disabled', true);
			return false;
		}
	});

	$(document.body).off('change', '.request_to_pay_amount').on('change', '.request_to_pay_amount', function(){
		var currentInput = $(this).val();
	    var fixedInput = currentInput.replace(/[A-Za-z!@#$%^&*()]/g, '');
	    $(this).val(fixedInput);

	    var key = $(this).attr('data-key');
	    var changed_amount = $(this).val();
	    changed_amount = changed_amount.replace(',','');
		var main_amount = $('#main_amount_'+key).val();
	    var type = getPaymentType(key);
	    if(Number(changed_amount) > Number(main_amount)) {
	    	var res = {
                "response_data": {
                    "success": false,
                    "message": type+' amount should be less than or equal to $'+main_amount,
                }
            };
            display_error_message(res);
		} else {
			var sum = 0;
		    $('.request_to_pay_amount').each(function() {
		    	var amount = $(this).val();
		    	var amt = amount.replace(',','');
		        sum += Number(amt);
		        // FOrmat number again after sum
		        $(this).val(currencyFormat(Number(amt)));
		    });
		    $('.request_to_pay_total').val(sum);
		    $('#request_to_pay_total').html('$ '+currencyFormat(sum));
		}
	});

	// Save first month payment data
	$(document).off('click', '.save_first_month_rent_collection_data').on('click', '.save_first_month_rent_collection_data', function(){

		// Get current date
		var fullDate = new Date()
		var twoDigitMonth = ((fullDate.getMonth().length+1) === 1)? (fullDate.getMonth()+1) : '0' + (fullDate.getMonth()+1);
		var currentDate = twoDigitMonth + "/" + fullDate.getDate() + "/" + fullDate.getFullYear();

		// Check due date cannot be less than current date
		var d_cnt = 0;
		var a_cnt = 0;
		var t_cnt = 0;
		var total_due_date_record = $("input[class*='fm_due_date_']").length;
		var total_amount_record = $(".request_to_pay_amount").length;
		$("input[class*='fm_due_date_']").each(function(key, value) {
			var type = getDateType(key);
			var due_date = $(this).val();
		    if(new Date(due_date) >= new Date(currentDate)) {
				d_cnt++;
			} else {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": type+' due date cannot be less than current date',
	                }
	            };
	            display_error_message(res);
	            return false;
			}
		});

		// Check amount cannot be gretar than main amount
		$(".request_to_pay_amount").each(function(key, value) {
		    var changed_amount = $(this).val();
		    changed_amount = changed_amount.replace(',','');
		    var key = $(this).attr('data-key');
		    var main_amount = $('#main_amount_'+key).val();
		    var type = getPaymentType(key);
		    if(Number(changed_amount) <= Number(main_amount)) {
				a_cnt++;
			} else {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": type+' amount should be less than or equal to $'+main_amount,
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			if(Number(changed_amount) <= dwolla_transaction_amount) {
				t_cnt++;
			} else {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": type+' Single transaction amount should be less than $'+dwolla_transaction_amount,
	                }
	            };
	            display_error_message(res);
	            return false;
			}
		});

		if(d_cnt == total_due_date_record && a_cnt == total_amount_record && t_cnt == total_amount_record) {
			// if(docusign_signature_enabled == 'true'){
			// 	var property_id = $('#rs_property_id').val();	//new workflow
			// 	var lease_id = $('#rs_lease_id').val();
			// 	var tenant_id = $('#rs_invite_tenant_id').val();
			// 	var user_id = $('#rs_invite_tenant_user_id').val();
			// } else {
				var property_id = $('#selected_property_id').val();	//dropdown workflow
				var lease_id = $('#selected_lease_id').val();
				var tenant_id = $('.tenant_property_dd').val();
				var user_id = $('.tenant_property_dd').find(':selected').map(function() {
					return $(this).attr("data-user-id");
				}).get().join(',');
			// }

			if(tenant_id == '') {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please select tenant',
	                }
	            };
	            display_error_message(res);
	            return false;
	        }

	        $('.save_first_month_rent_collection_data').attr('disabled', true);
			saveFirstMonthPaymentDetail(property_id,lease_id,tenant_id,user_id);
		}
	});

	// Load add New property page
	$(document).off('click', '.add_new_property').on('click', '.add_new_property', function(){
		$('#propertyAddModal').modal('show');
		$('#propertyAddModal #property_detail_container').load('components/property/add_new_property.html', function(){
			getStateDropdown();
			$('.add_property_coupon_code').removeClass('d-none');
			getDefaultPlanDetail();
		});
	});

	//Load expense detailed report
	/*$(document).off('click','.get_expense_detailed_report').on('click','.get_expense_detailed_report', function(){
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    getExpenseDetailedReport();
		}, 500);
	})*/

	$(document).off('click','.add_manual_transaction').on('click','.add_manual_transaction', function(){
		$('#addManualTransaction').modal('show');
		$('#addManualTransaction #add_transaction_manual_container').load('components/reports/add_transaction.html',function(){

		})
	})

	$(document.body).off('change', '#transaction_property').on('change', '#transaction_property', function(){
		$('.partial_payment_card').addClass('d-none');
		$('.pp_due_date_div').addClass('d-none');
		$('#is_partial_payment').val(0);
		getTenantListForTransaction();
		$("#transaction_payment option").attr('disabled', false);
		setTimeout( function(){
			listTransactions();
		}, 700)
		if($('#transaction_property').val() != ''){
			$('#transaction_tenant').attr('disabled', false);
			$('#transaction_payment').attr('disabled', false);
			$('#transaction_payment').val('');
			$('#transaction_rent_month').val('');
			$('#manual_transaction_amount').val('');
		} else {
			$('#transaction_tenant').attr('disabled', true);
			$('#transaction_payment').attr('disabled', true);
			$('#transaction_payment').val('');
			$('#transaction_rent_month').val('');
			$('#manual_transaction_amount').val('');
		}
	})

	$(document.body).off('change', '#transaction_tenant').on('change', '#transaction_tenant', function(){
		$('.partial_payment_card').addClass('d-none');
		$('.pp_due_date_div').addClass('d-none');
		$('#is_partial_payment').val(0);
		$("#transaction_payment option").attr('disabled', false);
		setTimeout( function(){
			listTransactions();
		}, 700)
		if($('#transaction_tenant').val() != ''){
			$('#transaction_payment').attr('disabled', false);
			$('#transaction_payment').val('');
			$('#transaction_rent_month').val('');
			$('#manual_transaction_amount').val('');
		} else {
			$('#transaction_payment').attr('disabled', true);
			$('#transaction_payment').val('');
			$('#transaction_rent_month').val('');
			$('#manual_transaction_amount').val('');
		}
	})

	$(document.body).off('change', '#transaction_payment').on('change', '#transaction_payment', function(){
		$('.partial_payment_card').addClass('d-none');
		$('.pp_due_date_div').addClass('d-none');
		$('#is_partial_payment').val(0);
		if($('#transaction_payment').val() == 7){
			$('#transaction_rent_month').removeClass('readonly').addClass('readonly_white');
			$('#transaction_rent_month').attr('disabled', false);
			// var next_month_rent = getNextMonthRentDetail();
			// $('#transaction_rent_month').val(next_month_rent);
			var user_id = user.user_details.user_id;
			var property_id = $('#transaction_property').val();
			var tenant_id = $('#transaction_tenant').val();
			var payments = [];
			$.ajax({
				url: api_url+"scheduled-payments/list-transactions-for-tenants",
				type: 'POST',
				data: 'property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response) {
					if(response.response_data.success == true){
						for(var key in response.payment_data){
							if(response['payment_data'][key]['payment_type'] == 7 && (response['payment_data'][key]['status_constant'] == 'pending_authorization' || response['payment_data'][key]['status_constant'] == 'pending_collection')){
								const date = new Date(response['payment_data'][key]['rent_month'])
								payments.push(date);
							}
						}
					}
				}
			})
			setTimeout(
				function() {
					if(payments.length != 0){
						var max_date = new Date(Math.max.apply(null,payments));
						var min_date = new Date(Math.min.apply(null,payments));
						var set_date = getFormattedDate(min_date);
						$.ajax({
							url: api_url+"scheduled-payments/get-payment-details",
							type: 'POST',
							data: 'rent_month='+set_date+'&payment_type=7&property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id,
							beforeSend: function(xhr) {
								xhr.setRequestHeader('Authorization', accessToken);
							},
							success: function(response) {
								var amount = response.payment_data.total_with_late_fee;
								$('#manual_transaction_amount').val(currencyFormat(Number(amount)));
							}
						})
						$('#transaction_rent_month').val(set_date);
						$('#manual_transaction_amount').attr('readonly', false);
						$('input[name^="transaction_rent_month"]').datepicker("destroy");
						$('input[name^="transaction_rent_month"]').datepicker({
							format: 'mm/dd/yyyy',
							minViewMode: 1,
							autoclose: true,
							useCurrent: true,
							forceParse: false,
							startDate: min_date,
							endDate: min_date,
							// todayHighlight: true,
							// setDate: min_date
						});
					} else{
						var next_month_rent = getNextMonthRentDetail();
					}
				}, 700
			)
		} else if($('#transaction_payment').val() == 3 || $('#transaction_payment').val() == 4){
			var user_id = user.user_details.user_id;
			var rent_month = $('#transaction_rent_month').val();
			var payment_type = $('#transaction_payment').val();
			var property_id = $('#transaction_property').val();
			var tenant_id = $('#transaction_tenant').val();
			$.ajax({
				url: api_url+"scheduled-payments/get-payment-details",
				type: 'POST',
				data: 'rent_month='+rent_month+'&payment_type='+payment_type+'&property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response) {
					if(response.response_data.success == true){
						var amount = response.payment_data.total_with_late_fee;
						$('#transaction_rent_month').removeClass('readonly').addClass('readonly_white');
						// $('#transaction_rent_month').attr('disabled', true);
						$('#manual_transaction_amount').val(currencyFormat(Number(amount)));
						$('.partial_payment_card').addClass('d-none');
						$('.pp_due_date_div').addClass('d-none');
						$('#is_partial_payment').val(0);
						$('#manual_transaction_amount').attr('readonly', false);
						var rent_month = getFormattedDate(response.payment_data.rent_month);
						$('#transaction_rent_month').val(rent_month);
						$('input[name^="transaction_rent_month"]').datepicker("destroy");
						$('input[name^="transaction_rent_month"]').datepicker({
							format: 'mm/dd/yyyy',
							// minViewMode: 1,
							autoclose: true,
							useCurrent: true,
							forceParse: false,
							startDate: rent_month,
							endDate: rent_month,
							// todayHighlight: true
						});
					} else{
						$('#manual_transaction_amount').attr('readonly', false);
						$('#manual_transaction_amount').val('');
						$('#transaction_rent_month').val('');
						$('#transaction_rent_month').removeClass('readonly').addClass('readonly_white');
						$('#transaction_rent_month').attr('disabled', false);
						$('input[name^="transaction_rent_month"]').datepicker("destroy");
						$('input[name^="transaction_rent_month"]').datepicker({
							format: 'mm/dd/yyyy',
							// minViewMode: 1,
							autoclose: true,
							useCurrent: true,
							forceParse: false,
							todayHighlight: true,
							startDate: new Date()
						});
					}
				}
			});
		}else{
			$('#transaction_rent_month').attr('readonly', true);
			$('#transaction_rent_month').val('');
			if($('#transaction_payment').val() != ''){
				$('#manual_transaction_amount').attr('readonly', false);
				$('#manual_transaction_amount').val('');
			} else{
				$('#manual_transaction_amount').attr('readonly', true);
				$('#manual_transaction_amount').val('');
			}
		}
	});

	$(document.body).off('change', '#transaction_rent_month').on('change', '#transaction_rent_month', function(){
		if($('#transaction_payment').val() == 7){
			var user_id = user.user_details.user_id;
			var rent_month = $('#transaction_rent_month').val();
			var payment_type = $('#transaction_payment').val();
			var property_id = $('#transaction_property').val();
			var tenant_id = $('#transaction_tenant').val();
			$.ajax({
				url: api_url+"scheduled-payments/get-payment-details",
				type: 'POST',
				data: 'rent_month='+rent_month+'&payment_type='+payment_type+'&property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response) {
					if(response.response_data.success == true){
						var amount = response.payment_data.total_with_late_fee;
						$('#manual_transaction_amount').val(currencyFormat(Number(amount)));
						$('.partial_payment_card').addClass('d-none');
						$('.pp_due_date_div').addClass('d-none');
						$('#is_partial_payment').val(0);
					}
				}
			});
		}
	});

	$(document.body).off('change', '.manual_transaction_amount').on('change', '.manual_transaction_amount', function(){
		var amount = $(this).val();
		var amt = amount.replace(/,/g, '');
		$(this).val(currencyFormat(Number(amt)));
		showPartialPaymentData();
	});

	//Load add New Expense page
	$(document).off('click','.add_new_expense').on('click','.add_new_expense', function(){
		$('#addExpenseModal').modal('show');
		$('#addExpenseModal #add_expense_container').load('components/expenses/new_expense.html',function(){
			getStateDropdownForSignup();
		})
	})

	$(document).off('click','.add_new_vendor').on('click','.add_new_vendor', function(){
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/new_vendor.html', function(){
			getStateDropdownForSignup();
		});
	})

	$(document).off('click','.edit_vendor').on('click','.edit_vendor', function(){
		var vendor_id = $(this).attr('data-id');
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/edit_vendor.html', vendor_id, function(){
			getVendorDetails(vendor_id);
			// getStateDropdownForSignup();
		});
	})


	$(document).off('click','.delete_vendor').on('click','.delete_vendor', function(){
		var vendor_id = $(this).attr('data-id');
		ConfirmDelete('Pay To User', vendor_id);
	})

	$(document).off('click','.add_account').on('click','.add_account', function(){
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/new_account.html', function(){
			getExpenseCategories('add_account');
			$('#accountSubCategory').attr('disabled',true);
			var html = '';
            html += "<option value=''>Select Sub Category</option>";
			$('#accountSubCategory').html(html);
			$('#accountSubCategory').val('');
		});
	})

	$(document.body).off('change', '#accountCategory').on('change', '#accountCategory', function(){
		var page = '';
		var category_id = $('#accountCategory').val();
		if(category_id != ''){
			getExpenseSubCategories(page,category_id);
			// $('#inputSubCategory').attr('disabled',false);
		} else {
			$('#accountSubCategory').attr('disabled',true);
			var html = '';
            html += "<option value=''>Select Sub Category</option>";
			$('#accountSubCategory').html(html);
			$('#accountSubCategory').val('');
		}
	})

	$(document).off('click','.add_new_sub_account').on('click','.add_new_sub_account', function(){
		var account_id = $(this).attr('data-id');
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/new_sub_account.html',account_id, function(){
			$('#accountId').val(account_id);
		});
	})

	$(document).off("click", ".view_account").on('click', '.view_account', function () {
		var account_id = $(this).attr('data-id');
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/edit_account.html', account_id, function(){
			getAccountById(account_id);
		});
	});

	$(document).off("click", ".view_sub_account").on('click', '.view_sub_account', function () {
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/edit_sub_account.html', function(){});
		var sub_account_id = $(this).attr('data-id');
		getSubAccountById(sub_account_id);
	});

	$(document).off("click", ".delete_account").on('click', '.delete_account', function (e) {
		e.preventDefault();
		var account_id = $(this).attr('data-id');
		ConfirmDelete('Account', account_id);
		return false;
	});

	$(document).off("click", ".delete_sub_account").on('click', '.delete_sub_account', function (e) {
		e.preventDefault();
		var sub_account_id = $(this).attr('data-id');
		ConfirmDelete('Sub Account', sub_account_id);
		return false;
	});

	$(document).off('click','.submit_vendor').on('click','.submit_vendor', function(){
		if($validateVendor.valid()){
			submitVendor();
		}
	});

	$(document).off('click','.update_vendor').on('click','.update_vendor', function(){
		if($validateVendor.valid()){
			updateVendor();
		}
	});

	$(document).off('click','.submit_account').on('click','.submit_account', function(){
		if($validateAccount.valid()){
			submitAccount();
		}
	});

	$(document).off('click','.submit_sub_account').on('click','.submit_sub_account', function(){
		if($validateSubAccount.valid()){
			submitSubAccount();
		}
	});

	$(document).off('click','.edit_account').on('click','.edit_account', function(){
		if($validateAccount.valid()){
			editAccount();
		}
	});

	$(document).off('click','.edit_sub_account').on('click','.edit_sub_account', function(){
		if($validateSubAccount.valid()){
			editSubAccount();
		}
	});

	$(document).off('change').on('change', '.fileToUpload', function(e)
    {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.addEventListener('load',  () => {
			const imageContent = reader.result.replace('data:', '').replace(/^.+,/, '');
			//console.log(imageContent);return false;
			var user_id = user.user_details.user_id;

			var obj = {};
			obj.user_id = user_id;
			obj.file_content = imageContent;
			$('#addExpenseItemModal').modal('show');
			openLoadingDialog($('#add_expense_item_container'));
			$.ajax({
				url: expense_api_url+"scanexpense",
				//dataType: "json",
				data: JSON.stringify(obj),
				type: 'POST',
				beforeSend: function(xhr) {
					xhr.setRequestHeader('x-api-key', expense_x_api_key);
					xhr.setRequestHeader('customer-id', expense_customer_id);
					xhr.setRequestHeader('Accept', 'application/json');
					xhr.setRequestHeader('Content-Type', 'application/json');
				},
				success: function(response) {
					if(response.status == "ERROR") {
						$('#addExpenseItemModal').modal('hide');
						var message = {
							"response_data": {
								"success": false,
								"message": response.message
							}
						};
						display_error_message(message);
					} else {
						if(Object.keys(response.data.summaryFields).length != 0 && Object.keys(response.data.lineItems).length != 0) {
							// console.log(response);return false;
							// openLoadingDialog($('#add_expense_item_container'));
							$('#addExpenseItemModal #add_expense_item_container').load('components/expenses/expense_item.html',function(){
								var page = "add_expense";
								getExpenseAccounts(page);
								var account_id = $('#inputAccount').val();
								if(account_id != ''){
									getExpenseSubAccounts(page, account_id);
									$('#inputSubAccount').attr('disabled',false);
								}
								getExpenseTaxes(page);
								datepicker('inputDate');
								var x = createScannedExpense(response);
								$('#inputMerchant').val(x['merchant_name']);
								$('#inputAmount').val(x['total']);
								$('#inputInvoice').val(x['invoice_number']);
								$('#inputDate').val(x['date']);
								var source = document.getElementById('inputFile');
								var input = document.getElementById('fileToUpload');
								source.files = input.files;
								var output = document.getElementById('scannedFileList');
								var children = "";
								for (var i = 0; i < input.files.length; ++i) {
									children += '<li>' + input.files.item(i).name + '</li>';
								}
								output.innerHTML = '<ul>'+children+'</ul>';
							})
							// saveScannedExpense(response);
						} else {
							$('#addExpenseItemModal').modal('hide');
							$('#add_expense_item_container').html('');
							var message = {
								"response_data": {
									"success": false,
									"message": 'Please upload valid reciept.'
								}
							};
							display_error_message(message);
						}
					}
				}
			});
		});
    });

	$(document).off('click','.delete_expense').on('click','.delete_expense', function(e){
		e.preventDefault();
		var expense_id = $(this).attr('data-id');
		ConfirmDelete('Expense Report', expense_id);
		return false;
	});

	$(document).off('click','.add_expense_item').on('click','.add_expense_item', function(){
		$('#addExpenseItemModal').modal('show');
		$('#addExpenseItemModal #add_expense_item_container').load('components/expenses/expense_item.html',function(){
			setTimeout(function(){
				var page = "add_expense";
				getExpenseAccounts(page);
				// var account_id = $('#inputAccount').val();
				// if(account_id != '' && account_id != null){
				// 	getExpenseSubAccounts(page,account_id);
				// }
				// getExpenseTaxes(page);
				datepicker('inputDate');
			}, 500);
		})
	})

	$(document).off('click','.edit_line_item').on('click','.edit_line_item', function(){
		var line_item_id = $(this).attr('data-id');
		var status = $(this).attr('data-status');
		$('#addExpenseItemModal').modal('show');
		$('#addExpenseItemModal #add_expense_item_container').load('components/expenses/expense_item.html', line_item_id, function(){
			$('#line_item_id').val(line_item_id);
			if(status == 'Submitted' || (localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1)){
				$('.update_expense').addClass('d-none');
				$('.attachment_expenses').addClass('d-none');
				$('#inputDate').css('background', '#e5eaef');
				$('#expense_item_form input').attr('disabled', true);
				$('#expense_item_form select').attr('disabled', true);
			}
			if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
				// setTimeout(function(){
					disableForAssumeIdentity();
				// }, 3000);
			}
			var expense_id = $('#selected_expense_id').val();
			getLineItemDetail(expense_id, line_item_id);
		})
	})

	$(document).off('click','.delete_line_item').on('click','.delete_line_item', function(e){
		e.preventDefault();
		var line_item_id = $(this).attr('data-id');
		ConfirmDelete('Expense', line_item_id);
		return false;
	});

	$(document).off('change', '.image-upload').on('change', '.image-upload', function(){
		var input = document.getElementById('inputFile');
	    var output = document.getElementById('inputFileList');
	    var children = "";
	    for (var i = 0; i < input.files.length; ++i) {
	        children += '<li>' + input.files.item(i).name + '</li>';
	    }
		output.innerHTML = '<ul>'+children+'</ul>';
	});

	$(document).on('dragover','.drag_drop_expense', function(ev) {
        ev.preventDefault();
        // Entering drop area. Highlight area
		$(".drag_drop_expense").removeClass("border-dashed-grey");
        $(".drag_drop_expense").addClass("border-dashed-red");
    });

	$(document).on('dragleave','.drag_drop_expense', function(ev) {
        // Going out of drop area. Remove Highlight
		$(".drag_drop_expense").removeClass("border-dashed-red");
        $(".drag_drop_expense").addClass("border-dashed-grey");
    });

	$(document).on('drop','.drag_drop_expense', function(ev) {
        // Dropping files
        ev.preventDefault();
        ev.stopPropagation();
        if(ev.originalEvent.dataTransfer){
			if(ev.originalEvent.dataTransfer.files.length > 1 && $('#inputFile').attr('multiple') != 'multiple'){
				var response = {
					"response_data": {
						"success": false,
						"message": 'Only one file is acceptable.'
					}
				};
				display_error_message(response);
				return false;
			}
			if(ev.originalEvent.dataTransfer.files.length) {
				var droppedFiles = ev.originalEvent.dataTransfer.files;
				var source = document.getElementById('inputFile');
				source.files = ev.originalEvent.dataTransfer.files;
				var children = "";
            for(var i = 0; i < droppedFiles.length; i++)
				{
                children += '<li>' + droppedFiles[i].name + '</li>';
					// $("#inputFileList").append("<br />"+ droppedFiles[i].name);
				}
				$("#inputFileList").html("<ul>"+children+"</ul>");
			}
        }

        $(".drag_drop_expense").removeClass("border-dashed-red");
		$(".drag_drop_expense").addClass("border-dashed-grey");
        return false;
    });

	$(document.body).off('change', '#inputAccount').on('change', '#inputAccount', function(){
		var page = '';
		var account_id = $('#inputAccount').val();
		if(account_id != ''){
			getExpenseSubAccounts(page,account_id);
			// $('#inputSubCategory').attr('disabled',false);
		} else {
			$('#inputSubCategory').attr('disabled',true);
			var html = '';
            html += "<option value=''>Select Sub Category</option>";
			$('#inputSubCategory').html(html);
			$('#inputSubCategory').val('');
		}
	})

	$(document).off('click', '.account_row').on('click', '.account_row', function(){
		var data_no = $(this).attr('data-no');
		$('.acc_data_number'+data_no).each(function(){
			if(!$(this).hasClass('d-none')){
				$(this).addClass('d-none');
			} else {
				$(this).removeClass('d-none');
				var show_all_check = document.getElementById('show_all_accounts');
				if(!show_all_check.checked){
					var data_cnt = $(this).attr('data-cnt');
					var flag = 0;
					$('.sub_calc'+data_cnt).each(function(){
						flag = flag + Number($(this).attr('data-val'));
					});
					if(flag == 0){
						if(!$(this).hasClass('d-none')){
							$(this).addClass('d-none');
						}
					}
				}
			}
		});
	});

	$(document).off('click','.open_expense_item').on('click','.open_expense_item', function(){
		var title = $(this).attr('data-title');
		var id = $(this).attr('data-id');
		$('#showExpenseModal').modal('show');
		$('#showExpenseModal #show_expense_item_container').load('components/reports/show_expenses.html', function(){
			$('.show_expenses_title').html(title);
			openLoadingDialog($('#show_expenses_media'));
			if(id != ''){
				showExpenseItems(id);
			} else {
				var html = '<h3 class="text-center mt-3"> No Data Available </h3>';
				$('#show_expenses_media').html(html);
			}
		});

	});

	$(document).off('click', '.sub_category_row').on('click', '.sub_category_row', function(){
		var data_no = $(this).attr('data-no');

		$('.sub_cat_calc'+data_no).each(function(){
			var data_number = $(this).attr('data-no');
			if(!$(this).hasClass('d-none')){
				$(this).addClass('d-none');
				$('.acc_data_number'+data_number).addClass('d-none');
			} else {
				$(this).removeClass('d-none');
				$('.acc_data_number'+data_number).removeClass('d-none');
				var show_all_check = document.getElementById('show_all_accounts');
				if(!show_all_check.checked){
					var sum = 0;
					$('.account'+data_number).each(function(){
						sum = sum + Number($(this).attr('data-val'));
					});

					if(sum == 0){
						if(!$(this).hasClass('d-none')){
							$(this).addClass('d-none');
							if(!$('.acc_data_number'+data_number).hasClass('d-none')){
								$('.acc_data_number'+data_number).addClass('d-none');
							}
						}
					} else {
						$(this).removeClass('d-none');
						$('.acc_data_number'+data_number).removeClass('d-none');
						$('.acc_data_number'+data_number).each(function(){
							var data_cnt = $(this).attr('data-cnt');
							var flag = 0;
							$('.sub_calc'+data_cnt).each(function(){
								flag = flag + Number($(this).attr('data-val'));
							});
							if(flag == 0){
								if(!$(this).hasClass('d-none')){
									$(this).addClass('d-none');
								}
							}
						});
					}
				}
			}
		});

	})

	$(document).off('change', '#expand_collapse').on('change','#expand_collapse', function() {
		setTimeout(function(){
			$("#expense-report-datatable").DataTable().columns.adjust().draw();
		}, 500);
        if(this.checked) {
            $(this).next('label').text("Collapse");
			var show_all_check = document.getElementById('show_all_accounts');
			if(show_all_check.checked){

				$('.sub_account_row').removeClass('d-none');
				// $('.exp_col').removeClass('d-none');
				// $('.val_total_on_collaps').each(function(){
				// 	$(this).html('-');
				// });


			} else {
				$('.sub_category_row').each(function(){
					var data_no = $(this).attr('data-no');
					$('.sub_cat_calc'+data_no).each(function(){
						var data_number = $(this).attr('data-no');
						var sum = 0;
						$('.account'+data_number).each(function(){
							sum = sum + Number($(this).attr('data-val'));
						});

						if(sum == 0){
							if(!$(this).hasClass('d-none')){
								$(this).addClass('d-none');
								if(!$('.acc_data_number'+data_number).hasClass('d-none')){
									$('.acc_data_number'+data_number).addClass('d-none');
								}
							}
						} else {
							$(this).removeClass('d-none');
							$('.acc_data_number'+data_number).removeClass('d-none');
							$('.acc_data_number'+data_number).each(function(){
								var data_cnt = $(this).attr('data-cnt');
								var flag = 0;
								$('.sub_calc'+data_cnt).each(function(){
									flag = flag + Number($(this).attr('data-val'));
								});
								if(flag == 0){
									if(!$(this).hasClass('d-none')){
										$(this).addClass('d-none');
									}
								} else {
									$(this).removeClass('d-none');
								}
							});
						}
					});
				});
			}
        } else {
            $(this).next('label').text("Expand");

			var show_all_check = document.getElementById('show_all_accounts');
			if(show_all_check.checked){
				$('.sub_account_row').addClass('d-none');
				// $('.exp_col').addClass('d-none');
				// $('.val_total_on_collaps').each(function(){
				// 	var val = $(this).attr('data-val');
				// 	if(val == 0){
				// 		$(this).html('-');
				// 	} else {
				// 		$(this).html(currency_sign+' '+currencyFormat(Number(val)));
				// 	}
				// });
			} else {

				$('.sub_category_row').each(function(){
					var data_no = $(this).attr('data-no');
					$('.sub_cat_calc'+data_no).each(function(){
						var data_number = $(this).attr('data-no');
						var sum = 0;
						$('.account'+data_number).each(function(){
							sum = sum + Number($(this).attr('data-val'));
						});

						if(sum == 0){
							if(!$(this).hasClass('d-none')){
								$(this).addClass('d-none');
								if(!$('.acc_data_number'+data_number).hasClass('d-none')){
									$('.acc_data_number'+data_number).addClass('d-none');
								}
							}
						} else {
							$(this).removeClass('d-none');
							$('.acc_data_number'+data_number).addClass('d-none');
						}
					});
				});
			}
        }
    });

	$(document).off('change', '#prorate_enabled').on('change','#prorate_enabled', function() {
		// if($('#monthly_rent_amount').val() == '' || $('#start_date').datepicker('getDate') == null){
		if($('#monthly_rent_amount').val() == '' || $('#start_date > input').val() == ''){
			var response = {
				"response_data": {
					"success": false,
					"message": 'Please Enter Monthly Rent Amount & Select Start Date.'
				}
			};
			display_error_message(response);
			$('#prorate_enabled').prop('checked',false);
			$('.calc_pro_rate').html('');
			$('.calc_pro_rate_display').html('');
			$('#prorated_amount').val('');
		} else {
			if((this).checked){
				var amount = Number($('#monthly_rent_amount').val().replace(/,/gi, ''));
				var selectedDate = new Date($('#start_date > input').val());
				// var selectedDate = $('#start_date').datepicker('getDate');
				var year = selectedDate.getFullYear();
				var month = selectedDate.getMonth();
				var last_date = new Date(year, month + 1, 0);
				var total_days = last_date.getDate();
				if(selectedDate.getDate() != 1){
					var number_of_days = total_days - selectedDate.getDate() + 1;
				} else{
					var number_of_days = 0;
				}
				var sub_amount = amount / total_days;
				var prorated_amount = number_of_days * sub_amount;

				var msg_html = '<ul>';
					msg_html += '<li> Total amount is : $ '+currencyFormat(Number(amount))+'</li>';
					msg_html += '<li> Total days in month of selected start date : '+total_days+' days</li>';
					msg_html += '<li> Amount / Total Days : $ '+currencyFormat(Number(sub_amount))+' (Per Day Amount)</li>';
					msg_html += '<li> Number of days from selected start date to month end : '+number_of_days+' days</li>';
					msg_html += '<li> Per Day Amount * Number of days from start date to month end : $ '+currencyFormat(Number(prorated_amount))+'</li>';
				msg_html += '</ul>';
				var html = '<i class="fas fa-fw fa-info-circle" title="How prorated amount is calculated?" data-container="body" data-toggle="popover" data-placement="top" data-content="'+msg_html+'"></i>'
				$('.calc_pro_rate').html(html);

				$('[data-toggle="popover"]').popover({
					html: true,
					animation:false,
					sanitize: false,
					placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
				});

				var display_html = 'Prorated amount will be : $ '+currencyFormat(Number(prorated_amount));
				$('.calc_pro_rate_display').html(display_html);
				$('#prorated_amount').val(currencyFormat(Number(prorated_amount)));
				$('.pro_rate_due_date_container').removeClass('d-none');
			} else {
				$('#prorate_enabled').prop('checked',false);
				$('.calc_pro_rate').html('');
				$('.calc_pro_rate_display').html('');
				$('#prorated_amount').val('');
				$('.pro_rate_due_date_container').addClass('d-none');
			}
		}
	});

	$(document).off('change', '.month_to_month_enabled').on('change','.month_to_month_enabled', function() {
		$('#month_to_month_value').val('');
		$('#lease_calc_amount').val('');
		if($('#monthly_rent_amount').val() == ''){
			var response = {
				"response_data": {
					"success": false,
					"message": 'Please Enter Monthly Rent Amount.'
				}
			};
			display_error_message(response);
			$('input.month_to_month_enabled[value="off"]').prop('checked',true);
			// $('#month_to_month_enabled').prop('checked',false);
			$('.month_fee_check').addClass('d-none');
			// $('.month_to_month_method').attr('disabled',true);
		} else {
			if($('.month_to_month_enabled:checked').val() == 'percentage'){
				$('.month_fee_check').removeClass('d-none');
			} else if($('#lease_add_form .month_to_month_enabled:checked').val() == 'fix_amount') {
				$('.month_fee_check').removeClass('d-none');
			} else {
				$('.month_fee_check').addClass('d-none');
				$('input.month_to_month_enabled[value="off"]').prop('checked',true);
			}
		}
	});

	$(document).off('change', '.month_to_month_method').on('change','.month_to_month_method', function() {
		$('#month_to_month_value').val('');
		$('#lease_calc_amount').val('');
		if($('#monthly_rent_amount').val() == ''){
			var response = {
				"response_data": {
					"success": false,
					"message": 'Please Enter Monthly Rent Amount.'
				}
			};
			display_error_message(response);
			$('input.month_to_month_method[value="off"]').prop('checked',true);
			$('.month_fee_check').addClass('d-none');
		} else {
			if($('.month_to_month_method:checked').val() == 'percentage'){
				$('.month_fee_check').removeClass('d-none');
				$('#month_to_month_enabled').val('on');
				$('#month_to_month_value').attr("placeholder", "Enter Percentage");
			} else if($('.month_to_month_method:checked').val() == 'fix_amount') {
				$('.month_fee_check').removeClass('d-none');
				$('#month_to_month_enabled').val('on');
				$('#month_to_month_value').attr("placeholder", "Enter Amount");
			} else {
				$('.month_fee_check').addClass('d-none');
				$('input.month_to_month_method[value="off"]').prop('checked',true);
				$('#month_to_month_enabled').val('');
				$('#month_to_month_value').attr("placeholder", "");
			}
		}
	});

	$(document).off('change', '#monthly_rent_amount').on('change','#monthly_rent_amount', function() {
		if($('.month_to_month_method:checked').val() != 'off'){
			$('#month_to_month_value').trigger('change');
		}

		if($('#prorate_enabled').prop('checked') == true){
			$('#prorate_enabled').trigger('change');
		}
	});

	$(document).off('change', '#month_to_month_value').on('change','#month_to_month_value', function() {
		var amount = Number($('#monthly_rent_amount').val().replace(/,/gi, ''));
		if($('.month_to_month_method:checked').val() == 'percentage'){
			var factor = Number($('#month_to_month_value').val().replace(/,/gi, '')) / 100;
			amount *= factor;
		} else if($('.month_to_month_method:checked').val() == 'fix_amount') {
			amount = Number($('#month_to_month_value').val().replace(/,/gi, ''));
		}
		$('#month_to_month_value').val(currencyFormat(Number($('#month_to_month_value').val().replace(/,/gi, ''))))
		$('#lease_calc_amount').val(currencyFormat(Number(amount)));
	});

	$(document).off('change', '.partial_payment_method').on('change','.partial_payment_method', function() {
		$('#partial_payment_value').val('');
		if($('#monthly_rent_amount').val() == ''){
			var response = {
				"response_data": {
					"success": false,
					"message": 'Please Enter Monthly Rent Amount.'
				}
			};
			display_error_message(response);
			$('input.partial_payment_method[value="off"]').prop('checked',true);
			$('.partial_allowed_check').addClass('d-none');
		} else {
			if($('.partial_payment_method:checked').val() == 'percentage'){
				$('.partial_allowed_check').removeClass('d-none');
				$('#partial_payment_enabled').val('on');
				$('#partial_payment_value').attr('placeholder', 'Enter Percentage');
			} else if($('.partial_payment_method:checked').val() == 'fix_amount') {
				$('.partial_allowed_check').removeClass('d-none');
				$('#partial_payment_enabled').val('on');
				$('#partial_payment_value').attr('placeholder', 'Enter Amount');
			} else {
				$('.partial_allowed_check').addClass('d-none');
				$('input.partial_payment_method[value="off"]').prop('checked',true);
				$('#partial_payment_enabled').val('');
				$('#partial_payment_value').attr('placeholder', '');
			}
		}
	});

	$(document).off('change', '#show_all_accounts').on('change','#show_all_accounts', function() {
		setTimeout(function(){
			$("#expense-report-datatable").DataTable().columns.adjust().draw();
		}, 500);
        if(this.checked) {
			var expand_collaps_check = document.getElementById('expand_collapse');
			$('.account_row').each(function(){
				var data_number = $(this).attr('data-no');
				$(this).removeClass('d-none');
				if(expand_collaps_check.checked){
					$('.acc_data_number'+data_number).removeClass('d-none');
				}
			});
        } else {
			$('.sub_category_row').each(function(){
				var data_no = $(this).attr('data-no');
				$('.sub_cat_calc'+data_no).each(function(){
					var data_number = $(this).attr('data-no');
					var sum = 0;
					$('.account'+data_number).each(function(){
						sum = sum + Number($(this).attr('data-val'));
					});

					if(sum == 0){
						if(!$(this).hasClass('d-none')){
							$(this).addClass('d-none');
							if(!$('.acc_data_number'+data_number).hasClass('d-none')){
								$('.acc_data_number'+data_number).addClass('d-none');
							}
						}
					} else {
						$('.acc_data_number'+data_number).each(function(){
							var data_cnt = $(this).attr('data-cnt');
							var flag = 0;
							$('.sub_calc'+data_cnt).each(function(){
								flag = flag + Number($(this).attr('data-val'));
							});
							if(flag == 0){
								if(!$(this).hasClass('d-none')){
									$(this).addClass('d-none');
								}
							}
						})
					}
				});
			});
        }
    });

	$(document).off('click','.view_expense_document').on('click','.view_expense_document', function(){
		var document_id = $(this).attr('data-id');
		var display_name = $(this).attr('data-value');
		$('#viewExpenseDocumentModel').modal('show');
		$('#viewExpenseDocumentModel #view_expense_document_container').load('components/expenses/view_expense_document.html', function(){
			$('.expense_document_title').html(display_name);
			openLoadingDialog($('#expense_document_media'));
			viewExpenseDocument(document_id,display_name);
		});
	})

	//load add new Expense Line item
	$(document).off('click','.add_line_item').on('click','.add_line_item', function(){
		getLineItems();
	})

	//remove Expense Line item
	$(document).off('click','.remove_line_item').on('click','.remove_line_item', function(r){

        if($('#line_item_table tbody tr').length > 1){
			if (confirm("Are you sure, you want to delete this line item ?"))
        	{
				$(this).closest('tr').animate( {backgroundColor:'rgb(230,239,194)'}, 500).fadeOut(500,function()
            	{
					var amount = $(this).closest('tr').find("input.inputAmount").val();
					var f_amount = amount.replace(/,/g, '');

					var updatedAmt = ($("#totalAmountInput").val())-f_amount;
					$("#expenseTotalAmount").html(currency_sign+' '+currencyFormat((updatedAmt)));
					$("#totalAmountInput").val(updatedAmt.toFixed(2));
					var tableID = $(this).closest('table').attr('id');
					$(this).closest('tr').remove();
					arrangeslno(tableID);

					// Add deleted line item id in hidden input for update expense api data
					var deletedRowId = $(this).closest('tr').attr('data-id');
					if(deletedRowId != null) {
						var delimeter = '';
						if($('#deletedLineItemId').val() != "") { delimeter = ','; }
						var deletedExpenseID = $('#deletedLineItemId').val($('#deletedLineItemId').val()+delimeter+deletedRowId);
					}
				});
			}
        }

    })

	$(document).off('click', '.remove_exp_documents').on('click','.remove_exp_documents', function(){
		var dataDocId = $(this).attr('data-id');
		if(dataDocId != null){
			var delimeter = '';
			if($('#deletedDocuments').val() != "") { delimeter = ','; }
			var deletedDocumentId = $('#deletedDocuments').val($('#deletedDocuments').val()+delimeter+dataDocId);
			$('#doc_'+dataDocId).remove();
		}
	})

	$(document).off('click','.submit_expense').on('click','.submit_expense', function(r){
		if($validateExpense.valid()){
			saveExpenses();
		}
	})

	$(document).off('click','.send_expense').on('click','.send_expense', function(r){
		if($('#missingItems').html() != ''){
			MissingFields($('#missingItems').html());
		} else {
			var expense_id = $('#selected_expense_id').val();
			var type = 'expense_submit';
			updateExpense(expense_id, type);
		}
	})

	$(document).off('click','.cancel_expense').on('click','.cancel_expense', function(r){
		$('#main_container').load('components/expenses.html',function(){
			//alert('here');
			if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
				setTimeout(function(){
					disableForAssumeIdentity();
				}, 1000);
			}
		})
	})

	$(document).off('change', '.inputAmount').on('change', '.inputAmount', function(){
    //     // console.log($("#inputAmount").val());
    //     calculateTotal();
		var val = $(this).val();
		var total = parseFloat(val.replace(/,/g, ''));

		var amount = $(this).val();
		var amt = amount.replace(/,/g, '');
		$(this).val(currencyFormat(Number(amt)));
    });

	$(document).off('change', '.tax_calculate').on('change', '.tax_calculate', function(){
		if($('#expenseAmount').val() != undefined || $('#expenseAmount').val() != null){
			var amount = $('#expenseAmount').val().replace(/,/gi, '');
		} else {
			var amount = 0;
		}

		if($('#taxPercentage').val() != undefined || $('#taxPercentage').val() != null){
			var tax = $('#taxPercentage').val().replace(/,/gi, '');
		} else {
			var tax = 0;
		}

		var taxAmount = amount * ( tax / 100 );
		taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
		var taxTotal = amount * (1 + (tax/100));
		taxTotal = Math.round((taxTotal + Number.EPSILON) * 100) / 100;
		$('#expenseAmount').val(currencyFormat(Number(amount)));
		$('#taxAmount').val(currencyFormat(Number(taxAmount)));
		$('#inputAmount').val(currencyFormat(Number(taxTotal)));
    });


	$(document).off('change', '.expense_tax_dd').on('change', '.expense_tax_dd', function(){
		var val = $('#inputTaxes option:selected').attr('data-percentage');
		$('#taxPercentage').val(val);
        if($('#expenseAmount').val() != undefined || $('#expenseAmount').val() != null){
			var amount = $('#expenseAmount').val().replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, '');
		} else {
			var amount = 0;
		}
		var taxAmount = amount * ( val / 100 );
		taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
		var taxTotal = amount * (1 + (val/100));
		taxTotal = Math.round((taxTotal + Number.EPSILON) * 100) / 100;
		$('#taxAmount').val(currencyFormat(Number(taxAmount)));
		$('#inputAmount').val(currencyFormat(Number(taxTotal)));
    });

    $(document).off('click','.save_expense_item').on('click','.save_expense_item', function(r){
		if($validateExpenseItem.valid()){
			//saveExpenses();
			alert('dev');
		}
	})

	$(document).off('keypress', '.inputContact').on('keypress', '.inputContact', function(){
        // console.log($("#inputContact").val());
		$('.inputContact').mask('(000) 000-0000');
    });

	$(document).off('keypress', '.vendorPhone').on('keypress', '.vendorPhone', function(){
        // console.log($("#vendorPhone").val());
		$('.vendorPhone').mask('(000) 000-0000');
    });

	$(document).off('click','.update_expense').on('click','.update_expense', function(r){
		var type = $(this).attr('data-type');
		if(type == 'expense_header') {
			if($validateExpense.valid()){
				var expense_id = $('#selected_expense_id').val();
				updateExpense(expense_id, type);
			}
		} else {
			if($validateExpenseItem.valid()){
				var expense_id = $('#selected_expense_id').val();
				updateExpense(expense_id, type);
			}
		}
	})

	$(document).off('click', '.save_transaction').on('click', '.save_transaction', function(){
		if($validateManualTransactions.valid()){
			postManualTransaction();
		}
	})

	$(document.body).off('keyup', '#partial_pay_amount').on('keyup', '#partial_pay_amount', function(){
		var amount = $(this).val();
		var sender_user_id = $('#payment_schedule_sender_id').val();
		var amt = Number(amount.replace(/,/g, ''));
		var zero = 0.00;
		// $(this).val(currencyFormat(amt));

		var month_rent = $('.pp_monthly_rent').attr('data-value').replace(/,/g, '');
		if(amt >= Number(month_rent) || amt == 0){
			if(amt >= Number(month_rent)){
				var message = {
					"response_data": {
						"success": false,
						"message": 'Partial amount should be less than monthy rent.'
					}
				};
				display_error_message(message);
				$('#partial_pay_amount').val('');
			}

			$('.pp_partial_amount').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
			$('#pp_partial_amount').val(zero);

			$('.pp_balance').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
			$('#pp_balance').val(zero);

			if($(this).attr('data-partial-method') == 'P'){
				$('.pp_payment_fee').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
				$('#pp_payment_fee').val(zero);
			}

			$('.pp_authorized_rent').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
			$('#pp_authorized_rent').val(zero);

			$('.pp_balance_due').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
			$('#pp_balance_due').val(zero);

			$('.pp_total_balance_amount').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
			$('#pp_total_balance_amount').val(zero);
		} else {
			var partial_amount = amt;
			var balance = month_rent - partial_amount;
			var partial_payment_fee = $(this).attr('data-partial-fee-amount');
			partial_payment_fee = partial_payment_fee.replace(/,/g, '');
			if($(this).attr('data-partial-method') == 'P'){
				var pp_fee = (balance * (partial_payment_fee)/100);
			} else{
				var pp_fee = partial_payment_fee;
			}
			var total = amt;
			if(sender_user_id > 0){
				var balance_due = parseFloat(balance) + parseFloat(pp_fee);
			} else {
				var balance_due = parseFloat(balance);
			}

			$('.pp_partial_amount').html('<strong>$ '+currencyFormat(Number(partial_amount))+'</strong></div>');
			$('#pp_partial_amount').val(partial_amount);

			$('.pp_balance').html('<strong>$ '+currencyFormat(Number(balance))+'</strong></div>');
			$('#pp_balance').val(balance);

			if(sender_user_id > 0){
				$('.pp_payment_fee').html('<strong>$ '+currencyFormat(Number(pp_fee))+'</strong></div>');
				$('#pp_payment_fee').val(pp_fee);
			} else {
				$('.pp_payment_fee').html('<strong>$ '+currencyFormat(Number(zero))+'</strong></div>');
				$('#pp_payment_fee').val(zero);
			}

			$('.pp_authorized_rent').html('<strong>$ '+currencyFormat(Number(total))+'</strong></div>');
			$('#pp_authorized_rent').val(total);

			$('.pp_balance_due').html('<strong>$ '+currencyFormat(Number(balance_due))+'</strong></div>');
			$('#pp_balance_due').val(balance_due);

			$('.pp_total_balance_amount').html('<strong>$ '+currencyFormat(Number(balance_due))+'</strong></div>');
			$('#pp_total_balance_amount').val(balance_due);
		}
	});

	$(document).off('click', '.save_partial_payment_data').on('click', '.save_partial_payment_data', function(){
		if($validateEditTransaction.valid()){
			localStorage.removeItem('account_role');
			savePartialPayment('');
		}
	})

	$(document).off('click', '.edit_transaction').on('click', '.edit_transaction', function(){
		$('#editTransactionModal').modal('show');
		var tenant_id = $(this).attr('data-user-id');
		var property_id = $(this).attr('data-property-id');
		var scheduled_payment_id = $(this).attr('data-id');
		editTransaction(tenant_id, property_id, scheduled_payment_id);
	})

	$(document).off('click','.view_expense').on('click','.view_expense', function(){
		var expense_id = $(this).attr('data-id');
		var status = $(this).attr('data-status');
		var length = $(this).attr('data-length');
		$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
			$('#selected_expense_id').val(expense_id);
			if(status == 'Submitted'){
				$('.send_expense').addClass('d-none');
				$('.edit_expense').addClass('d-none');
				$('.add_expense_item').addClass('d-none');
				$('.camera-upload').addClass('d-none');
			} else {
				if(length == 0){
					$('.send_expense').attr('disabled',true);
				}
			}
			if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
				// setTimeout(function(){
					disableForAssumeIdentity();
				// }, 3000);
			}
			setTimeout(function(){
				viewExpense(expense_id);
				getStateDropdownForSignup();
			}, 500);
		})
	})

	$(document).off('click','.edit_expense').on('click','.edit_expense', function(){
		var expense_id = $('#selected_expense_id').val();
		$('#expense_header_detail').find('select').attr('disabled', false);
		$('.edit_expense').addClass('d-none');
		$('.update_expense, .cancel_update').removeClass('d-none');
		$('#expense_header_detail').find('input').attr('disabled', false);
	})

	$(document).off('click','.cancel_update').on('click','.cancel_update', function(){
		var expense_id = $('#selected_expense_id').val();
		$('#expense_header_detail').find('select').attr('disabled', true);
		$('.update_expense, .cancel_update').addClass('d-none');
		$('.edit_expense').removeClass('d-none');
		$('#expense_header_detail').find('input').attr('disabled', true);
	})

	// Property document upload dropzone
	$(document).off('click', '.get_property_image_dropzone').on('click', '.get_property_image_dropzone', function(){
		$('#propertyImageDropzoneModal').modal('show');
	});

	// Property document upload dropzone
	$(document).off('click', '.get_property_document_dropzone').on('click', '.get_property_document_dropzone', function(){
		$('#propertyDocumentDropzoneModal').modal('show');
	});

	// Assign lease agreement document between peoperty owner and rent synergy admin
	$(document).off('click', '.add_lease_aggrement_signature_document').on('click', '.add_lease_aggrement_signature_document', function(){
		$('#templateListModal').modal('show');
		getZohoTemplateList();
	});

	$(document).off('click', '.upload_dwolla_document').on('click', '.upload_dwolla_document', function(){
		$('#DwollaKycModal').modal('show');
		openLoadingDialog($('#dwolla_document_container'));
		setTimeout(function(){
			$('#dwolla_document_container').load('components/bank/add_bank.html', function(){
				$('#addBankCollapse').remove();
				$('#kyc_document_form').removeClass('d-none');
				$('#dwolla_document_container .card-header').remove();
                $('#dwolla_document_container .card').removeClass('border');
                $('#dwolla_document_container .card').removeClass('card');
			});
		}, 1000);
	});

	$(document).off('click', '.upload_kyc_document').on('click', '.upload_kyc_document', function(){
		if($kycDocForm.valid()){
			if(kycDocumentDropzone.files.length == 0) {
				$('.submit_agreement_detail').attr('disabled', false);
				var response = {
					"response_data": {
						"success": false,
						"message": 'Please select a document'
					}
				};
				display_error_message(response);
				return false;
			} else {
				$(this).attr('disabled', true);
				$(this).text('Uploading...');
				var formData = new FormData();
				formData.append("user_id", user.user_details.user_id);
				formData.append("documentType", $('#kyc_doc_type').val());
				formData.append("first_time_signup", $('#first_time_signup').val);
				formData.append("file", kycDocumentDropzone.files[0]);

				$.ajax({
					method: 'POST',
					url: api_url+"payment-details/upload-kyc-document",
					data: formData,
					processData: false,
					contentType: false,
					cache: false,
					beforeSend: function(xhr) {
						xhr.setRequestHeader('Authorization', accessToken);
					},
					success: function(response) {
						display_error_message(response);
						if(response.response_data.success == true) {
							$('#DwollaKycModal').modal('hide');
							$('.upload_kyc_document').attr('disabled', false);
							$('.upload_kyc_document').text('Upload');
							getMyAccountDocumentWidget();
						} else {
							$('.upload_kyc_document').attr('disabled', false);
							$('.upload_kyc_document').text('Upload');
						}
					}
				});
			}
		}
	});

	$(document).off('change', '.property_user_type').on('change', '.property_user_type', function(){
		$('#zohoSignerListContainer').remove();
		$("input:radio[name='document_name']").prop('checked', false);
		$("div[class*='account_type_']").addClass('d-none');
		var type = $("input[name='property_user_type']:checked").attr('data-type');
		if(type == "t") {
			var property_id = $('#selected_property_id').val();
			getActiveLease(property_id);
			$('.save_template_data').attr('disabled', true);
			$('.document_title').addClass('d-none');
		} else {
			$('#active_lease_container').addClass('d-none');
			$('#active_lease_container').html('');
			$('.account_type_'+type).removeClass('d-none');
			$('.document_title').removeClass('d-none');
			$('.save_template_data').attr('disabled', true);
		}
	});

	$(document).off('click', '.redirect_to_property').on('click', '.redirect_to_property', function(){
		$('body').css('overflow-y', 'auto');
		var data = JSON.parse(localStorage.getItem('user'));
		var checklist_seq_no = data.user_details.cheklist_seq_no;
		var total_steps = data.user_details.total_steps;
		if(checklist_seq_no < total_steps) {
			window.location.href = site_url+'dashboard.html';
		} else {
			window.location.href = site_url+'dashboard.html?action=properties';
		}
	});

	$(document).off('click', '.create_lease_agreement').on('click', '.create_lease_agreement', function(){
		$('#add_new_contract_container').removeClass('d-none');
		$('.master_contract_preview_container').addClass('d-none');
		$('#master_contract_preview_container').html('');
		$('#addNewContractModal').modal('show');
		var open_from = $(this).attr('data-open-from');
		var payment_schedule_id = $(this).attr('data-ps-id');
		// var contract_id = $(this).attr('data-id');
		// var contract_name = $(this).attr('data-name');
		$('#addNewContractModal #add_new_contract_container').load('components/property/add_new_contract.html', function() {
			openLoadingDialog($('#master_contracts_div'));
			$('#add_contract_open_from').val(open_from);
			$('#payment_schedule_id').val(payment_schedule_id);
			if(open_from == 'reports'){
				$('.master_contract_title').html('Select Eviction Notice Contract');
				$('.add_new_lease_agreements').text('Preview');
				$('.add_new_lease_agreements').attr('disabled', true);
				$('.add_new_lease_agreements').addClass('preview_contract_in_reports').removeClass('add_new_lease_agreements');
			}
		});
	});

	$(document).off('click', '.preview_contract_in_reports').on('click', '.preview_contract_in_reports', function(){
		var master_contract_id = $("input[name='add_new_contract_radio']:checked").val();
		var payment_schedule_id = $('#payment_schedule_id').val();
		$('#add_new_contract_container').addClass('d-none');
		$('.master_contract_preview_container').removeClass('d-none');
		openLoadingDialog($('#master_contract_preview_container'));
		setTimeout(function() {
			previewMasterContractContent('', master_contract_id, payment_schedule_id, 'master_contract_preview_container', 'reports');
		}, 300);
	});

	$(document).off('click', '.upload_eviction_document').on('click', '.upload_eviction_document', function(e){
		// e.stopPropagation();
		var payment_schedule_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Upload Eviction Contract');
		setTimeout(function(){
			getUploadEvictionDocumentData(payment_schedule_id);
		}, 500);
	});

	$(document).off('click', '.save_eviction_contract').on('click', '.save_eviction_contract', function(e){
		// if($validateEvictionDocument.valid()){
			savePaymentScheduleAttachment();
		// }
	});

	$(document).off('click', '.back_to_master_list').on('click', '.back_to_master_list', function(){
		$('#add_new_contract_container').removeClass('d-none');
		$('.master_contract_preview_container').addClass('d-none');
		$('#master_contract_preview_container').html();
		$('.download_eviction_document').attr('href','javascript:void(0)');
		$('.download_eviction_document').attr('download','');
	});

	$(document).off('click', '.send_contract_for_signature').on('click', '.send_contract_for_signature', function(){
		var ids = [];
		$('input[name="contracts_list[]"]:checked').each(function() {
			ids.push($(this).val());
		});
		ids = ids.toString();

		var len = $('input[name="contracts_list[]"]:checked').length;
		if(len > 0){
			checkForVariables(ids);
		}
	});

	$(document.body).off('click', '.send_for_sign_checkbox').on('click','.send_for_sign_checkbox', function() {
    	var len = $('input[name="contracts_list[]"]:checked').length;
		if(len > 0){
			$('.send_contract_for_signature').removeClass('add_opacity');
			if(len>1){
				$('.document_widget_action').addClass('d-none')
			} else{
				if($('.document_widget_action').hasClass('d-none')){
					$('.document_widget_action').removeClass('d-none')
				}
			}
		} else {
			$('.send_contract_for_signature').addClass('add_opacity');
		}
	});

	$(document).off('click', '.add_new_contract_radio').on('click', '.add_new_contract_radio', function(){
		var master_contract_id = $(this).attr('data-value');
		getAddNewContractDetails(master_contract_id);
	});

	$(document).off('change', 'input:radio[name=add_new_contract_radio]:checked').on('change', 'input:radio[name=add_new_contract_radio]:checked', function(){
		if($('#add_contract_open_from').val() == 'add_document'){
			var data_type = $(this).attr('data-type');
			if(data_type == 'p_doc'){
				$('.add_new_lease_agreements').attr('disabled', false);
				$('#active_lease_container').addClass('d-none');
				$('#active_lease_container').html('');
			} else {
				$('.add_new_lease_agreements').attr('disabled', true);
				var property_id = $('#selected_property_id').val();
				getActiveLease(property_id);
			}
		} else {
			$('.add_new_lease_agreements').attr('disabled', false);
			$('.preview_contract_in_reports').attr('disabled', false);
		}
	});

	$(document).off('click', '.add_new_lease_agreements').on('click', '.add_new_lease_agreements', function(){
		if($("input[name='add_new_contract_radio']:checked").val()){
			$(this).attr('disabled', true);
			$(this).text('Processing...');
			var data_type = $("input[name='add_new_contract_radio']:checked").attr('data-type');
			var property_id = $('#selected_property_id').val();
			var template_id = $("input[name='add_new_contract_radio']:checked").val();
			var lease_id = $('.add_document_lease_dd').val();

			if(data_type != 'p_doc' && lease_id <= 0){
				var response = {
					"response_data": {
						"success" : false,
						"message": 'Please select lease.'
					}
				};
				display_error_message(response);
				return false;
			} else {
				var property_name = $('#navbar_role_name').html();
				if(signature_enabled == 'docusign'){
					createContracts(property_id, template_id, property_name, lease_id);
				} else {
					createZohoDocument(property_id, template_id, property_name, lease_id);
				}
			}
		} else {
			var response = {
				"response_data": {
					"success" : false,
					"message": 'Please select master contract.'
				}
			};
			display_error_message(response);
			return false;
		}
	});

	$(document).off('click', '.sign_contract').on('click', '.sign_contract', function(){
		var contract_id = $(this).attr('data-id');
		var type = $(this).attr('data-type');
		// var redirect_url = window.location.href;
		$(this).attr('disabled', true);
		$(this).text('Processing...');

		var redirect_url = window.domain + 'thank-you.html';
		signContract(contract_id, redirect_url);
	});

	$(document).off('click', '.sign_zoho_document').on('click', '.sign_zoho_document', function(){
		var zoho_document_id = $(this).attr('data-id');
		// var redirect_url = window.location.href;
		$(this).attr('disabled', true);
		$(this).text('Processing...');

		var redirect_url = window.domain + 'thank-you.html';
		signZohoDocument(zoho_document_id, redirect_url);
	});

	$(document).off('click', '.download_signed_contract').on('click', '.download_signed_contract', function(){
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		var contract_id = $('#contract_id').val();
		var contract_name = $('#contract_name').val();
		var type = $("input[name='download_contract_type']:checked").val();
		downloadContract(contract_id, contract_name, type);
	});

	$(document).off('click', '.download_sign_contract_modal').on('click', '.download_sign_contract_modal', function(){
		$('#ContractDocumentModal').modal('show');
		openLoadingDialog($('#contract_document_download_container'));
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		$('#ContractDocumentModal #contract_document_download_container').load('components/lease-agreements/download_contract.html', function(){
			$('#contract_id').val(contract_id);
			$('#contract_name').val(contract_name);
		});
	});

	$(document).off('click', '.download_zoho_signed_contract').on('click', '.download_zoho_signed_contract', function(){
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		var contract_id = $('#contract_id').val();
		var contract_name = $('#contract_name').val();
		var status = $('#data_status').val();
		var type = $("input[name='download_contract_type']:checked").val();
		downloadZohoContract(contract_id, contract_name, type, status);
	});

	$(document).off('click', '.download_zoho_contract_modal').on('click', '.download_zoho_contract_modal', function(){
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		var status = $(this).attr('data-status');
		if(status == 'zd_document_completed'){
			$('#ZohoDocumentModal').modal('show');
			openLoadingDialog($('#contract_document_download_container'));
			$('#ZohoDocumentModal #zoho_document_download_container').load('components/zoho-documents/download_contract.html', function(){
				$('#contract_id').val(contract_id);
				$('#contract_name').val(contract_name);
				$('#data_status').val(status);
			});
		} else {
			downloadZohoContract(contract_id, contract_name, '', status);
		}
	});

	$(document).off('click', '.void_contract').on('click', '.void_contract', function(){
		var contract_id = $('#contract_id').val();
		var contract_name = $('#contract_name').val();
		var void_reason = $('#void_reason').val();
		if($('#void_reason').val()){
			$(this).attr('disabled', true);
			$(this).text('Processing...');
			var contract_id = $('#contract_id').val();
			var contract_name = $('#contract_name').val();
			var void_reason = $('#void_reason').val();
			voidContract(contract_id, contract_name, void_reason);
		} else {
			var response = {
				"response_data": {
					"success" : false,
					"message": 'Please provide the reason to void the contract.'
				}
			};
			display_error_message(response);
			return false;
		}
	});

	$(document).off('click', '.void_contract_modal').on('click', '.void_contract_modal', function(){
		$('#VoidDocumentModal').modal('show');
		openLoadingDialog($('#void_contract_container'));
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		$('#VoidDocumentModal #void_contract_container').load('components/lease-agreements/void_contract.html', function(){
			$('#contract_id').val(contract_id);
			$('#contract_name').val(contract_name);
		});
	});

	$(document).off('click', '.recall_contract_modal').on('click', '.recall_contract_modal', function(){
		$('#RecallDocumentModal').modal('show');
		openLoadingDialog($('#void_contract_container'));
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		$('#RecallDocumentModal #recall_contract_container').load('components/zoho-documents/recall_contract.html', function(){
			$('#contract_id').val(contract_id);
			$('#contract_name').val(contract_name);
		});
	});

	$(document).off('click', '.recall_contract').on('click', '.recall_contract', function(){
		var contract_id = $('#contract_id').val();
		var contract_name = $('#contract_name').val();
		var void_reason = $('#void_reason').val();
		if($('#void_reason').val()){
			$(this).attr('disabled', true);
			$(this).text('Processing...');
			var contract_id = $('#contract_id').val();
			var contract_name = $('#contract_name').val();
			var void_reason = $('#void_reason').val();
			recallContract(contract_id, contract_name, void_reason);
		} else {
			var response = {
				"response_data": {
					"success" : false,
					"message": 'Please provide the reason to void the contract.'
				}
			};
			display_error_message(response);
			return false;
		}
	});

	$(document).off('click', '.extend_document_modal').on('click', '.extend_document_modal', function(){
		$('#extendDocumentModal').modal('show');
		openLoadingDialog($('#contract_document_download_container'));
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		var expiration_date = $(this).attr('expiration-date');
		$('#extendDocumentModal #extend_document_container').load('components/zoho-documents/extend_document.html', function(){
			$('#contract_id').val(contract_id);
			$('#contract_name').val(contract_name);
			$('#zoho_document_expiration_date').html(expiration_date);
		});
	});

	$(document).off('click', '.extend_zoho_document').on('click', '.extend_zoho_document', function(){
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		var contract_id = $('#contract_id').val();
		var contract_name = $('#contract_name').val();
		extendZohoDocument(contract_id, contract_name);
	});

	$(document).off('click', '.remind_zoho_document').on('click', '.remind_zoho_document', function(){
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		remindZohoDocument(contract_id, contract_name);
	});

	$(document).off('click', '.edit_contract_in_container').on('click', '.edit_contract_in_container', function(){
		var property_id = $('#selected_property_id').val();
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		if(signature_enabled == 'docusign'){
			$('#main_container').load('components/lease-agreements/edit_contract_content.html', function(){
				openLoadingDialog($('.contract_creation_steps'));
				$('#property_id').val(property_id);
				$('#contract_id').val(contract_id);
				$('#contract_name').val(contract_name);
				$('#preview_edited_contract').attr('data-id', contract_id)
				getUserContent(contract_id, 'edit');
			});
		} else if(signature_enabled == 'zoho_document'){
			$('#main_container').load('components/zoho-documents/edit_contract_content.html', function(){
				openLoadingDialog($('.contract_creation_steps'));
				$('#property_id').val(property_id);
				$('#zoho_document_id').val(contract_id);
				$('#contract_name').val(contract_name);
				$('#preview_edited_contract').attr('data-id', contract_id)
				getZohoDocumentContent(contract_id, 'edit');
			});
		}
	});

	$(document).off('click', '.delete_contract').on('click', '.delete_contract', function(){
		var contract_id = $(this).attr('data-id');
		ConfirmDelete('Contract', contract_id);
	});

	$(document).off('click', '.preview_master_contract').on('click', '.preview_master_contract', function(){
		var content = CKEDITOR.instances['master_contract_content'].getData();
		if(content != '') {
			$('#preview_master_contract, #master_contract_form_container').addClass('d-none');
			$('.master_contract_preview_container, .get_contract_form').removeClass('d-none');
			$('.list_master_contracts').addClass('d-none');
			openLoadingDialog($('#master_contract_preview_container'));
			previewMasterContractContent(encodeURIComponent(content), 0, 0, 'master_contract_preview_container', 'master_contract');
		} else {
			var message = {
				"response_data": {
					"success": false,
					"message": 'Please enter content in editor'
				}
			};
			display_error_message(message);
		}
	});

	$(document).off('click', '.get_contract_form').on('click', '.get_contract_form', function(){
		$('#preview_master_contract, #master_contract_form_container').removeClass('d-none');
		$('.master_contract_preview_container, .get_contract_form').addClass('d-none');
		$('.list_master_contracts').removeClass('d-none');
		$('#master_contract_preview_container').html('');
	});

	$(document).off('click', '.preview_contract_in_container').on('click', '.preview_contract_in_container', function(){
		var property_id = $('#selected_property_id').val();
		var contract_id = $(this).attr('data-id');
		var contract_name = $(this).attr('data-name');
		if(signature_enabled == 'docusign'){
			$('#main_container').load('components/lease-agreements/preview_contract_content.html', function(){
				openLoadingDialog($('.contract_creation_steps'));
				$('#property_id').val(property_id);
				$('#contract_id').val(contract_id);
				$('.contract_name').html(contract_name);
			});
		} else if(signature_enabled == 'zoho_document'){
			$('#main_container').load('components/zoho-documents/preview_contract_content.html', function(){
				openLoadingDialog($('.contract_creation_steps'));
				$('#property_id').val(property_id);
				$('#zoho_document_id').val(contract_id);
				$('.contract_name').html(contract_name);
			});
		}
	});

	$(document).off('click', '.preview_edited_contract').on('click', '.preview_edited_contract', function(){
		var content = CKEDITOR.instances['user_contract_content'].getData();
		var contract_id = $(this).attr('data-id');
		if(signature_enabled == 'docusign'){
			getUserContent(contract_id, 'preview', encodeURIComponent(content), 'edit');
		} else if(signature_enabled == 'zoho_document'){
			getZohoDocumentContent(contract_id, 'preview', encodeURIComponent(content), 'edit');
		}
	});

	$(document).off('click', '.back_to_edit').on('click', '.back_to_edit', function(){
		$('.user_contract_ck_editor_div').removeClass('d-none');
		$('#user_contents_container').addClass('d-none');
		$('#back_to_edit').addClass('d-none');
		$('#preview_edited_contract').removeClass('d-none');
		$('.view_contract_variables').removeClass('d-none');
		$('.card-title').html('Edit Contract Contents');
	});

	$(document).off('click', '.cancel_contract').on('click', '.cancel_contract', function(){
		var pid = getUrlParameter('pid');
		if(typeof pid !== "undefined") {
			var pid = pid.substring(11, pid.length-20);
			loadPropertyDetailLayout(pid, 'vertical', '');
			setTimeout(function() {
				scrollToTop('property_document_widget');
			}, 3500)
		}
	});

	$(document).off('click', '.update_user_content').on('click', '.update_user_content', function(){
		var property_id = $(this).attr('data-property-id');
		var contract_id = $(this).attr('data-id');
		if(signature_enabled == 'docusign'){
			updateUserContent(property_id, contract_id);
		} else if(signature_enabled == 'zoho_document'){
			updateZohoDocumentContent(property_id, contract_id);
		}
	});

	$(document).off('click', '.delete_property_contract').on('click', '.delete_property_contract', function(){
		var user_contract_id = $(this).attr('data-id');
		ConfirmDelete('PropertyContract', user_contract_id);
	});

	$(document).off('change', '.zoho_template_radio').on('change', '.zoho_template_radio', function(){
		$('#zohoSignerListContainer').remove();
		var zoho_template_id = $("input:radio[name='document_name']:checked").attr('data-id');
	    var user_type = $("input:radio[name='property_user_type']:checked").val();
	    var property_id = $("#selected_property_id").val();
	    var lease_id = $("#it_selected_lease_id").val();
	    var signature_required = $(this).attr('data-signature-required');
	    getZohoTemplateSignerList(zoho_template_id,user_type,property_id,lease_id,signature_required);
	});

	// Save selected template data for user
	$(document).off('click', '.save_template_data').on('click', '.save_template_data', function() {
		$(this).attr('disabled', true);
		var rs_invite_tenant = $('#rs_invite_tenant').val();
		if(rs_invite_tenant == 1) {
			$(this).html('<div class="loader"></div><div class="ml-2 float-right">Sending</div>');
		}

		var zoho_template_id = $("input[name='document_name']:checked").attr('data-id');
	    var user_type = $("input[name='property_user_type']:checked").val();
	    var property_id = $("#selected_property_id").val();

	    var userArray = [];
	    $("select[name^='property_tenant_dd']").each(function(key, value) {
		    var user_id = $(this).val();
		    userArray.push({
				'user_id' : user_id
			});
		});

		assignedZohoTemplate(zoho_template_id,user_type,property_id,JSON.stringify(userArray));
	});

	// Send for Signatures
	$(document).off('click', '.send_for_signatures').on('click', '.send_for_signatures', function() {
		$(this).addClass('add_opacity');
		var zoho_template_id = $(this).attr('data-zoho-template-id');
	    var property_id = $("#selected_property_id").val();
	    sendForSignature(zoho_template_id,property_id);
	});

	// Delete user template
	$(document).off('click', '.delete_property_template').on('click', '.delete_property_template', function() {
	    var user_template_id = $(this).attr('data-id');
	    ConfirmDelete('Property Document', user_template_id);
	});

	$(document).off('click', '.next_invite_tenant_step').on('click', '.next_invite_tenant_step', function(e){
		if(signature_enabled == 'zoho_template') {
			getSignupNextStep(1,2);
			var type = 'PO';
			getInviteTenantZohoTemplateList(type);
		} else {
			openLoadingDialog($('#invite_tenant_data_container'));
			setTimeout(function(){
				$('.invite_tenant_title').text('Request first month payment');
				var property_id = $('#selected_property_id').val();
				var property_name = $('#navbar_role_name').text();
				getLeaseListForProperty(property_id, property_name);
			}, 500);
		}
	});

	// Invite Tenant go to tenant lease document section
	$(document).off('click', '.go_to_tenant_lease_section').on('click', '.go_to_tenant_lease_section', function(e){
		if($('#tenant_signature_required').val() == 'true'){
			// getSignupNextStep(1,2);
			getSignupNextStep(2,3);
			var type = 'T';
			getInviteTenantZohoTemplateList(type);
		} else if($('#can_authorize_payment').val() == 'false'){
			getSignupNextStep(3,4);
			getSignupNextStep(4,5);
			openLoadingDialog($('#invite_tenant_data_container'));
			$('.invite_tenant_title').text('Invite Tenant');
			$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
		} else {
			getSignupNextStep(2,3);
			getSignupNextStep(3,4);
			openLoadingDialog($('#invite_tenant_data_container'));
			setTimeout(function(){
				var property_id = $('#selected_property_id').val();
				var property_name = $('#navbar_role_name').text();
				getLeaseListForProperty(property_id, property_name);
			}, 500);
		}
	});

	// Invite Tenant go to first month payment section
	$(document).off('click', '.go_to_fm_payment_section').on('click', '.go_to_fm_payment_section', function(e){
		if($('#can_authorize_payment').prop('checked') == true){
			// getSignupNextStep(2,3);
			getSignupNextStep(3,4);
			openLoadingDialog($('#invite_tenant_data_container'));
			setTimeout(function(){
				var property_id = $('#selected_property_id').val();
				var property_name = $('#navbar_role_name').text();
				getLeaseListForProperty(property_id, property_name);
			}, 500);
		} else {
			getSignupNextStep(3,4);
			getSignupNextStep(4,5);
			openLoadingDialog($('#invite_tenant_data_container'));
			$('.invite_tenant_title').text('Invite Tenant');
			$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
		}
	});

	// Invite Tenant go to invite tenant last step section
	$(document).off('click', '.go_to_invite_tenant_section').on('click', '.go_to_invite_tenant_section', function(e){
		// getSignupNextStep(3,4);
		getSignupNextStep(4,5);
		openLoadingDialog($('#invite_tenant_data_container'));
		$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
	});

	// Load edit property page
	$(document).off('click', '.edit_property_address').on('click', '.edit_property_address', function(e){
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		$('#propertyEditModal #edit_property_address_container').load('components/property/edit_property_address.html', function() {
			if(user_service_name == sa_role) {
				$('.series_llc_name_container').removeClass('d-none');
				$('.landlord_contract_name_container').removeClass('d-none');
			}
			getPropertyAddressDetail(property_id,'vertical');
			getStateDropdown();
		});
	});

	// Update property address
	$(document).off('click', '.update_property_address').on('click', '.update_property_address', function() {
		var type = "property_edit";
		var address1 = $('#edit_property_address_line1').val();
   		var address2 = $('#edit_property_address_line2').val();
   		var city = $('#edit_property_city').val();
   		var state = $('#edit_property_state').val();
   		var zipcode = $('#edit_property_zip_code').val();
   		if($editPropertyForm.valid()){
			$('.update_property_address').attr('disabled', true);
			$('.update_property_address').text('Processing...');
	   		verify_usps_address(type,address1,address2,city,state,zipcode)
			//updatePropertyAddress();
		}
	});

	// Update property detail
	$(document).off('click', '.update_property_data').on('click', '.update_property_data', function() {
		var property_id = $('#selected_property_id').val();
		var user_id = user.user_details.user_id;
	    var property_type_id = $('#property_types').val();
	    var no_of_units = $('#property_no_of_units').val();
	    var year_built = $('#property_year_built').val();
	    var square_feet = $('#property_square_feet').val();
	    var bedrooms = $('#property_bedrooms').val();
	    var bathrooms = $('#property_bathrooms').val();
	    var parking_spaces = $('#property_parking_spaces').val();
	    var swimming_pool = $('#property_swimming_pool').val();
	    var type_of_cooling = $('#property_type_of_colling').val();
	    var type_of_heating = $('#property_type_of_heating').val();
	    var type_of_water_heater = $('#property_type_of_water_heater').val();
	    var ac_filter_size = $('#property_ac_filter_size').val();
	    var ac_model = $('#property_ac_make_and_model').val();
	    var water_heater_model = $('#property_water_heater_make_and_model').val();
	    var additional_information = $('#property_additional_information').val();

	    var hoa = $('#hoa').val();
	    var flooring = $('#flooring').val();
	    var fireplace = $('#fireplace').val();
	    var water_heater = $('#water_heater').val();
	    var fans = $('#fans').val();
	    var pergola = $('#pergola').val();
	    var exterior = $('#exterior').val();
	    var foundation = $('#foundation').val();
	    var roof = $('#roof').val();
	    var fence = $('#fence').val();
	    var parcel_number = $('#parcel_number').val();
	    var lot_size = $('#lot_size').val();

	    var data = 'user_id='+user_id+'&property_type_id='+property_type_id+'&no_of_units='+no_of_units+'&built_year='+year_built+'&square_feet='+square_feet+'&no_of_bed='+bedrooms+'&no_of_bath='+bathrooms+'&no_of_parking='+parking_spaces+'&swimming_pool='+swimming_pool+'&type_of_cooling='+type_of_cooling+'&type_of_heating='+type_of_heating+'&type_of_water_heater='+type_of_water_heater+'&ac_filter_size='+ac_filter_size+'&ac_model='+ac_model+'&water_heater_model='+water_heater_model+'&additional_information='+additional_information+'&hoa='+hoa+'&flooring='+flooring+'&fireplace='+fireplace+'&water_heater='+water_heater+'&fans='+fans+'&pergola='+pergola+'&exterior='+exterior+'&foundation='+foundation+'&roof='+roof+'&fence='+fence+'&parcel_number='+parcel_number+'&lot_size='+lot_size;

	    $.ajax({
	        method: 'POST',
	        url: api_url+"properties/edit/"+property_id,
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	            display_error_message(response);
	            if(response.response_data.success == true) {
	                getPropertyDetail(property_id,'vertical');

	                getPropertyDetailWidgetInfo();
	                $('#PropertyDetailModal').modal('hide');
	            }
	        }
	    });
	});

	// Delete property data
	$(document).off('click', '.delete_property_data').on('click', '.delete_property_data', function(e) {
		e.stopPropagation();
	    var property_id = $(this).attr('data-id');
	    ConfirmDelete('Property', property_id);
	});

	// Property image hover
	$(document).off('mouseenter', '.property_image').on('mouseenter', '.property_image', function() {
		$(this).find('.property_cover_image').removeClass('d-none');
		$(this).find('.delete_property_image').removeClass('d-none');
	}).off('mouseleave', '.property_image').on('mouseleave', '.property_image', function() {
		$(this).find('.property_cover_image').addClass('d-none');
		$(this).find('.delete_property_image').addClass('d-none');
	});

	$(document).off('click', '.property_image').on('click', '.property_image', function(e) {
		e.stopPropagation();
		var id = $(this).attr('data-id');
		var property_id = $(this).attr('data-property-id');
		$('#imagePreviewModal').modal('show');
		$('.modal-dialog').css('max-width', '100%');
		$('.modal-dialog').css('top', '45%');
		$('.modal-backdrop.show').css('opacity', '0.97');
		openLoadingDialog($('#image_preview_container'));
		loadPropertyImagePreview(id, property_id);
	});

	// Delete property image
	$(document).off('click', '.delete_property_image').on('click', '.delete_property_image', function(e) {
		e.stopPropagation();
	    var property_id = $('#selected_property_id').val();
	    var attachment_id = $(this).attr('data-id');
	    ConfirmDelete('Property Image', attachment_id);
	});

	// Delete property image
	$(document).off('click', '.undelete_property_image').on('click', '.undelete_property_image', function() {
	    var property_id = $('#selected_property_id').val();
	    var attachment_id = $(this).attr('data-id');
	    unDeletePropertyImage(attachment_id);
	});

	// Set property cover image
	$(document).off('click', '.property_cover_image').on('click', '.property_cover_image', function(e){
		e.stopPropagation();
		var attachment_id = $(this).attr('data-id');
		updatePropertyCoverImage(attachment_id);
	});

	// View document preview
	$(document).off('click', '.view_document_preview').on('click', '.view_document_preview', function(){
		var type = $(this).attr('data-type');
		var file_url = $(this).attr('data-url');
		var document_name = $(this).attr('data-name');
		var request_id = $(this).attr('data-request-id');

		$('#viewDocumentModal').modal('show');
		$('#viewDocumentModal #document_preview_container').load('components/property/view_document.html', function(){
			$('#file_type').val(type);
			$('#document_request_id').val(request_id);
			$('#document_url').val(file_url);
			$('#document_name').val(document_name);
			var user_id = user.user_details.user_id;
			$('#d_user_id').val(user_id);
		});
	});

	$(document).off('click', '.remind_zoho_template').on('click', '.remind_zoho_template', function(){
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		var request_id = $(this).attr('data-request-id');
		remindZohoTemplate(request_id);
	});

	$(document).off('click', '.share_document').on('click', '.share_document', function(){
		var user_attachment_id = $(this).attr('data-id');
		var user_id = user.user_details.user_id;
		$.ajax({
			method: 'POST',
			url: api_url+"properties/share-document",
			data: 'user_attachment_id='+user_attachment_id+'&user_id='+user_id,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				display_error_message(response);
				getPropertyDocumentWidgetInfo();
			}
		})
	})

	$(document).off('click', '.unshare_document').on('click', '.unshare_document', function(){
		var user_attachment_id = $(this).attr('data-id');
		var user_id = user.user_details.user_id;
		$.ajax({
			method: 'POST',
			url: api_url+"properties/un-share-document",
			data: 'user_attachment_id='+user_attachment_id+'&user_id='+user_id,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				display_error_message(response);
				getPropertyDocumentWidgetInfo();
			}
		})
	})

	// Download Zoho Document
	$(document).off('click', '.download_zoho_document').on('click', '.download_zoho_document', function(){
		$('.download_zoho_document').addClass('add_opacity');
		var type = $(this).attr('data-type');
		var filename = $(this).attr('data-filename');
		var request_id = $(this).attr('data-request-id');
		downloadZohoDocument(filename, type, request_id);
	});

	// Save Property
	$(document).off('click', '.save_property_data').on('click', '.save_property_data', function() {
		var type = "property_add";
		var address1 = $('#add_property_address_line1').val();
   		var address2 = $('#add_property_address_line2').val();
   		var city = $('#add_property_city').val();
   		var state = $('#add_property_state').val();
   		var zipcode = $('#add_property_zip_code').val();
   		if($propertyForm.valid()){
   			var selected_setup_fee_payment_id = $('#selected_setup_fee_payment_id').val();
   			if(selected_setup_fee_payment_id > 0) {
				if($('.bank_property_dd').val() > 0) {
					var is_authorized = 0;
					if($('#property_bank_authorize_checkbox').is(":checked")) {
						is_authorized = 1;
					} else {
						var response = {
							"response_data": {
								"success" : false,
								"message": 'Please select agree checkbox.'
							}
						};
						display_error_message(response);
						return false;
					}
				} else {
					var response = {
						"response_data": {
							"success" : false,
							"message": 'Select payment method for rent deposit.'
						}
					};
					display_error_message(response);
					return false;
				}
			}
			$('.save_property_data').attr('disabled', true);
			$('.save_property_data').text('Processing...');
	   		verify_usps_address(type,address1,address2,city,state,zipcode);
	   		//savePropertyData();
	   	}
	});

	$(document).off('click', '.same_as_owner_address').on('click', '.same_as_owner_address', function() {
		var property_id = $('#selected_property_id').val();
		var same_as_owner_address = 0;
	    if($(this).is(":checked")) {
	    	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', true);
	    	$('.save_property_primary_contact_detail').attr('disabled', true);
	    	same_as_owner_address = 1;
	    } else {
	    	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', false);
	    	$('.save_property_primary_contact_detail').attr('disabled', false);
	    	same_as_owner_address = 0;
	    }

	    $.ajax({
	        method: 'POST',
	        url: api_url+"properties/primary-contact-address",
	        data: 'property_id='+property_id+'&same_as_owner_address='+same_as_owner_address,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	            if(response.response_data.success == false) {
	            	display_error_message(response);
	            }
	        }
	    });
	});

	$(document).off('click', '.save_property_primary_contact_detail').on('click', '.save_property_primary_contact_detail', function() {
		var type = "property_primary_contact";
		var address1 = $('#primary_contact_address_line1').val();
        var address2 = $('#primary_contact_address_line2').val();
        var city = $('#primary_contact_city').val();
        var state = $('#primary_contact_state').val();
        var zipcode = $('#primary_contact_zip_code').val();
        if($propertyContactForm.valid()){
        	verify_usps_address(type,address1,address2,city,state,zipcode);
        	//var property_id = $('#selected_property_id').val();
			//savePropertyPrimaryContactDetail(property_id);
		}
	});

	$(document).off('click', '.module_tab_container .nav-link').on('click', '.module_tab_container .nav-link', function() {
	    var href = $(this).attr('href');
	    if(href == "#p-lease") {
	    	openLoadingDialog($('#lease_data_container'));
	    	$('#lease_data_container').load('components/property/lease_detail_table.html');
	    } else if(href == "#p-documents") {
	    	openLoadingDialog($('#document_data_container'));
	    	$('#document_data_container').load('components/property/document_detail.html');
	    } else if (href == "#p-accounting") {
	    	openLoadingDialog($('#accounting_data_container'));
	    	$('#accounting_data_container').load('components/property/accounting_detail_table.html');
	    } else if (href == "#p-tenants") {
	    	openLoadingDialog($('#tenant_data_container'));
	    	$('#tenant_data_container').load('components/property/tenant_detail_table.html');
	    } else if (href == "#p-service-requests") {
	    	$('#ticket_open_from').val('property_tab');
	    	openLoadingDialog($('#service_request_data_container'));
	    	$('#service_request_data_container').load('components/support.html');
	    } else if (href == "#a-overview") {
	    	openLoadingDialog($('#property_block_container'));
	    	if(user_service_name == po_role) {
	    		$('#property_block_container').load('components/myaccount/po_overview.html');
	    	} else if(user_service_name == t_role) {
	    		$('#property_block_container').load('components/myaccount/t_overview.html');
	    	} else {
	    		$('#property_block_container').html('No data available');
	    	}
	    } else if (href == "#a-payment" || href == "#u-payment") {
	    	openLoadingDialog($('#payment_data_container'));
	    	$('#payment_data_container').load('components/myaccount/payment_detail_table.html');
	    } else if (href == "#a-invoice") {
	    	openLoadingDialog($('#invoice_data_container'));
	    	$('#invoice_data_container').load('components/myaccount/invoice_detail_table.html');
	    } else if (href == "#a-audit") {
	    	openLoadingDialog($('#audit_data_container'));
	    	var user_id = user.user_details.user_id;
	    	getUserActivities(user_id);
	    	//$('#audit_data_container').load('components/myaccount/payment_detail_table.html');
	    }  else if (href == "#a-linked-account") {
	    	openLoadingDialog($('#linked_account_data_container'));
	    	getLinkedAccount();
	    } else if (href == "#u-overview" || href == "#u-details" || href == "#u-billing") {
	    	var user_id = $('#selected_user_id').val();
	    	getPendingUserData(user_id);
	    } else if(href == "#u-background") {
	    	getUserBackCreditReportFile('BackgroundCheckReport');
	    } else if(href == "#u-credit") {
	    	getUserBackCreditReportFile('CreditReport');
	    } else if(href == "#p-coupons") {
			$('#coupon_details_data_section').addClass('d-none');
	    	loadCouponData();
	    } else if(href == "#p-plans") {
	    	$('#plan_pricing_data_section').addClass('d-none');
	    	loadPlanData();
	    } else if(href == "#p-subscriptions") {
	    	loadSubscriptionData();
	    } else if(href == "#p-groups") {
	    	loadGroupData();
	    } else if(href == "#p-features") {
	    	loadFeaturesData();
	    } else if(href == "#p-services") {
	    	loadServicesData();
	    } else if(href == "#p-service-permissions") {
	    	loadFeaturePermissionsData();
	    } else if(href == "#p-inbox") {
	    	getSystemEmailDatas('inbox');
	    } else if(href == "#p-sent-emails") {
	    	getSystemEmailDatas('sent-email');
	    } else if(href == "#p-email-templates") {
	    	getEmailTemplates();
	    } else if(href == "#r-summary") {
	    	getPaymentSummaryReport();
	    } else if(href == "#r-transaction") {
	    	getTransactionReport();
	    } else if(href == "#r-payment-receipt") {
	    	// Dropdown value
			var dd_property_id = $('.transaction_report_property_dd').val(null).trigger('change.select2');
			var dd_payment_type = $('.transaction_report_pt_dd').val(null).trigger('change.select2');
			var dd_transaction_type = $('.transaction_report_tt_dd').val(null).trigger('change.select2');
	    	var date_macro = '';
	    	//getPaymentReceiptReport(date_macro);
	    	getPaymentReceiptCardReport(date_macro);
	    } else if(href == "#r-payment-schedule" || href == "#r-current-upcoming-payment" || href == "#r-overdue-summary") {
	    	$('#payment_schedule_container, #current_upcoming_payment_container, #overdue_rent_summary_container').html('');
			$('#ps_filter_form_container').load('components/reports/payment_schedules_filters.html');
			$('#ps_filter_form_container').removeClass('d-none');

			if(document.getElementById("searchSidenav")){
				document.getElementById("searchSidenav").style.width = "0";
			}
			setTimeout(function() {
				document.getElementById("main_wrapper_container").style.marginRight = "0";
			}, 100);
	    } else if(href == "#r-expenses") {
			$('#payment_schedule_container, #current_upcoming_payment_container, #overdue_rent_summary_container').html('');
			$('#ps_filter_form_container').addClass('d-none');
			$('#ps_filter_form_container').html('');
			if(document.getElementById("searchSidenav")){
				document.getElementById("searchSidenav").style.width = "0";
			}
			setTimeout(function() {
				document.getElementById("main_wrapper_container").style.marginRight = "0";
			}, 100);
	    	openLoadingDialog($('#expense_report_data_container'));
	    	setTimeout(function(){
		    	getExpenseDetailedReport();
		    }, 200);
	    } else if(href == "#t-zoho-template") {
	    	openLoadingDialog($('#zoho_data_container'));
	    	setTimeout(function(){
			    getZohoTemplates();
			}, 2000);
	    } else if(href == "#r-vendor") {
	    	loadVendorData();
	    } else if(href == "#r-account") {
	    	loadAccountData();
	    }
	});

	$(document).off('click', '.clear-filter').on('click', '.clear-filter', function(){
		var data_module = $(this).attr('data-module');
		if(data_module == 'property'){
			$('.filter_property_name').val(null).trigger('change.select2');
			$('.filter_tenant_name').val(null).trigger('change.select2');
			$('.filter_lease_detail').val(null).trigger('change.select2');
			$('.filter_transaction_status').val(null).trigger('change.select2');
			loadVerticalProperty();
		} else if(data_module == 'users'){
			$('.usersNameDD').val(null).trigger('change.select2');
			$('.usersEmailDD').val(null).trigger('change.select2');
			$('.usersPhoneDD').val(null).trigger('change.select2');
			$('.usersStatusDD').val(null).trigger('change.select2');
			getPendingUserList($('#user_list_layout').val());
		} else if(data_module == 'reports'){
			$('.transaction_report_property_dd').val(null).trigger('change.select2');
			$('.transaction_report_pt_dd').val(null).trigger('change.select2');
			$('.transaction_report_tt_dd').val(null).trigger('change.select2');
			getPaymentReceiptCardReport('');
		} else if(data_module == 'payment_schedule'){
			if($('#selected_property_id').val()){
				$('.ps_filter_lease_id').val(0).trigger('change.select2');
				$('.ps_filter_po_users').val('').trigger('change.select2');
			} else {
				$('.ps_filter_po_name').val(0).trigger('change.select2');
				$('.ps_filter_property_name').val(0).trigger('change.select2');
				$('.ps_filter_for_property').addClass('d-none');
				$('.ps_filter_lease_id').html('');
				$('.ps_filter_po_users').html('');
			}
			$('#ps_filter_start_date > input').val('');
			$('#ps_filter_end_date > input').val('')
			// $('input[name="ps_filter_date_range"]').val('');
			if($('#selected_property_id').val()){
				getPropertiesPaymentScheduleWidgetInfo();
			} else {
				getMyAccountPaymentScheduleWidget();
			}
		}
	})

	/* Start : Invoice */

	$(document).off('click', '.view_invoice').on('click', '.view_invoice', function(){
		var invoice_id = $(this).attr('data-invoice-id');
		$('#invoiceModal').modal('show');
		openLoadingDialog($('#invoice_content_container'));
		setTimeout(function(){
		    getInvoiceData(invoice_id);
		}, 500);
	});

	$(document).off('click', '.download_invoice').on('click', '.download_invoice', function(){
		var invoice_id = $(this).attr('data-invoice-id');
		var open_from = $(this).attr('data-from');

		DownloadPdf('Downloading Invoice Copy ...');

		if(open_from == 'invoice_list') {
			getInvoiceData(invoice_id);
		} else if(open_from == 'invoice_preview') {
			$('#invoiceModal').modal('hide');
		}

		setTimeout(function(){
			$('.hide_download_invoice').remove();
			var element = $("#invoice_content_container").html();
			html2pdf(element, {
			  	margin:       0,
			  	filename:     'invoice.pdf',
			  	image:        { type: 'jpeg', quality: 1 },
			  	html2canvas:  { scale: 2, logging: false, dpi: 192, letterRendering: true },
			  	jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
			});
			$('.dialog-ovelay').remove();
		}, 1000);
	});

	$(document).off('click', '.email_invoice').on('click', '.email_invoice', function(){
		var invoice_id = $(this).attr('data-invoice-id');
		DownloadPdf('Sending Invoice Copy ...');
		getInvoiceData(invoice_id);
		setTimeout(function(){
			$('.hide_download_invoice').remove();
			var element = $("#invoice_content_container").html();

			var opt = {
				margin:       0,
			  	filename:     'invoice.pdf',
			  	image:        { type: 'jpeg', quality: 1 },
			  	html2canvas:  { scale: 2, logging: false, dpi: 192, letterRendering: true },
			  	jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
			};

			html2pdf().set(opt).from(element).toPdf().output('datauristring').then(function (pdfAsString) {
			    // The PDF has been converted to a Data URI string and passed to this function.
			    // Use pdfAsString however you like (send as email, etc)! For instance:
			    //console.log(pdfAsString);
			    //return false;
			    sendInvoiceEmail(pdfAsString);
			});
			$('.dialog-ovelay').remove();
		}, 1000);
	});

	/* End : Invoice */

	$(document).off('change', '#pet_policy').on('change', '#pet_policy', function(){
		var val = $(this).val();
		if(val == 2) {
			$('#pet_deposit_amount').attr('readonly', false);
			$('#pd_due_date > input').attr('disabled', false);
		} else {
			$('#pet_deposit_amount').val('');
			$('#pd_due_date > input').val('');
			$('#pet_deposit_amount').attr('readonly', true);
			$('#pd_due_date > input').attr('disabled', true);
		}
	});

	// Load add New lease page
	$(document).off('click', '.add_new_lease').on('click', '.add_new_lease', function(){
		var flag = $(this).attr('data-status');
		if(flag == 0){
			$('#leaseAddModal').modal('show');
			var module_name = $(this).attr('data-module');
			var lease_tenant_id = $(this).attr('data-id');
			if(typeof $(this).attr('data-end-date') === 'undefined'){
				var start_date = 0;
			} else {
				var start_date = new Date($(this).attr('data-end-date'));
				start_date.setDate(start_date.getDate() + 1);
			}

			$('#leaseAddModal #lease_detail_container_add').load('components/property/add_new_lease.html', function(){
				if(start_date != 0){
					$('#start_date').datepicker('setStartDate', start_date);
					$('#end_date').datepicker('setStartDate', start_date);
				}
				// $('#sd_due_date').datepicker('setStartDate', start_date);
				// $('#pd_due_date').datepicker('setStartDate', start_date);
				$('#module_name').val(module_name);
				if(typeof lease_tenant_id === 'undefined') {
					$('#lease_tenant_id').val(0);
				} else {
					$('#lease_tenant_id').val(lease_tenant_id);
				}
			});
		} else {
			if(flag == 1){
				var message = 'Please disable month to month in existing lease to add new lease.'
			} else {
				var message = 'To add new lease, please contact support@rentysynergy.com to disable month to month in existing lease.'
			}
			var response = {
				"response_data": {
					"success": false,
					"message": message
				}
			};
			display_error_message(response);
		}
	});

	// Format amount
	$(document).off('change', '.format_amount').on('change', '.format_amount', function(e){
		var amount = $(this).val();
		var amt = amount.replace(',','');
		$(this).val(currencyFormat(Number(amt)));
	});

	// Load edit lease page
	$(document).off('click', '.edit_lease_detail').on('click', '.edit_lease_detail', function(e){
		e.stopPropagation();
		var lease_id = $(this).attr('data-id');
		$('#leaseEditModal #lease_detail_container_edit').load('components/property/edit_lease.html', function() {
			getLeaseDetailById('edit', lease_id);
		});
	});

	// Save Lease
	$(document).off('click', '.save_lease_data').on('click', '.save_lease_data', function() {
	    saveLeaseData();
	});

	// Update Lease
	$(document).off('click', '.update_lease_data').on('click', '.update_lease_data', function() {
	    updateLeaseData();
	});

	// Load existing tenant
	$(document).off('click', '.list_existing_tenant').on('click', '.list_existing_tenant', function() {
		var tenant_ids = $(this).attr('data-tenant-id');
		var lease_id = $(this).attr('data-id');
		var module_name = $(this).attr('data-module');
		$('#tenantListModal #tenant_list_container').load('components/property/existing_tenant_detail.html', function(){
			$('#selected_lease_id').val(lease_id);
			getExistingTenantDetail(tenant_ids);
		});
	});

	// delete lease
	$(document).off('click', '.delete_lease_detail').on('click', '.delete_lease_detail', function(e){
		e.stopPropagation();
		var lease_id = $(this).attr('data-id');
		ConfirmDelete('Lease', lease_id);
	});

	// Assign existing lease to tenent
	$(document).off('click', '.assign_tenant_to_lease').on('click', '.assign_tenant_to_lease', function() {
		$(this).attr('disabled', true);
		var property_id = $('#selected_property_id').val();
		var lease_id = $('#selected_lease_id').val();
		var tenant_id = 0;

		var tenant_lease_array = [];

		// add selected user to chatroom members
		$("input:checkbox[name=existing_tenant_checkbox]:checked").each(function() {
        	var selected_tenant_id = $(this).attr('data-id');
        	tenant_lease_array.push({
				'id' : selected_tenant_id
			});
        });

        assignTenantToLease(property_id,lease_id,tenant_id,JSON.stringify(tenant_lease_array))
	});

	// Accountint cancel transfer
	$(document).off('click', '.cancel_payment').on('click', '.cancel_payment', function() {
	    var id = $(this).attr('data-id');
	    var cancel_type = $(this).attr('data-type');
	    ConfirmCancel('Transaction', id, cancel_type);
	});

	$(document).off('click', '.cancel_payment_schedule').on('click', '.cancel_payment_schedule', function() {
	    var id = $(this).attr('data-id');
	    var cancel_type = $(this).attr('data-type');
	    ConfirmCancel('Payment Schedule', id, cancel_type);
	});

	// Regenerate cancelled transaction
	$(document).off('click', '.regenerate_transaction').on('click', '.regenerate_transaction', function() {
	    var id = $(this).attr('data-id');
	    var payment_type = $(this).attr('data-payment-type');
	    ConfirmRegenerate('Transaction', id, payment_type);
	});

	// Hide show transaction detail
	$(document).off('click', '.show_hide_transaction_detail').on('click', '.show_hide_transaction_detail', function() {
		var element = $(this);

		/*$("div[id^='tran_card_']").each(function() {
        	if(!$(this).hasClass('d-none')) {
        		$(this).addClass('d-none');
        		$(this).html('Show More <i class="align-middle fas fa-fw fa-angle-down"></i>');
        	}
        }); */

		var id = element.attr('data-id');
		$('#tran_card_'+id).toggleClass('d-none');
		if($('#tran_card_'+id).hasClass('d-none')) {
			element.html('<i class="align-middle fas fa-fw fa-angle-down" data-toggle="tooltip" data-placement="bottom" title="Show more" data-original-title="Show more"></i>');
		} else {
			element.html('<i class="align-middle fas fa-fw fa-angle-up" data-toggle="tooltip" data-placement="bottom" title="Hide more" data-original-title="Hide more"></i>');
		}
	});

	// Authorize Subscription Fee
	$(document).off('click', '.authorize_subscription_fee').on('click', '.authorize_subscription_fee', function() {
	    var id = $(this).attr('data-id');
	    var property_id = $(this).attr('data-property-id');
	    var payment_type = $(this).attr('data-payment-type');
	    var payment_details_id = $(this).attr('data-payment-id');
	    var rent_month = $(this).attr('data-month');
	    var open_from = $(this).attr('data-open-from');
	    //alert(id); alert(property_id); alert(payment_type); alert(payment_details_id); alert(rent_month); alert(open_from); return false;
	    $('#authorizeSubscriptionFeeModal').modal('show');
	    getSubscriptionDetail(id, property_id, payment_type, payment_details_id, rent_month, open_from);
	});

	$(document).off('click', '#subscription_fee_authorize_checkbox').on('click', '#subscription_fee_authorize_checkbox', function(){
		if(!$('#subscription_fee_authorize_checkbox').is(":checked")) {
			$('.save_subscription_fee_data').attr('disabled', true);
		} else {
			$('.save_subscription_fee_data').attr('disabled', false);
		}
	});

	$(document).off('click', '.save_subscription_fee_data').on('click', '.save_subscription_fee_data', function(){
		var open_from = $('#subscription_fee_open_from').val();
		var scheduled_payment_id = $('#subscription_scheculed_payment_id').val();
		var property_id = $('#subscription_property_id').val();
		var payment_detail_id = $('#subscription_payment_detail_id').val();

		$('.save_subscription_fee_data').attr('disabled', true);
		authorizeSubscriptionFee(scheduled_payment_id, property_id, payment_detail_id, open_from);
	});

	$(document).off('change', '#primary_tenant_enabled,#allowed_to_autorize_and_pay_rent,#allowed_to_sign_contract').on('change', '#primary_tenant_enabled,#allowed_to_autorize_and_pay_rent,#allowed_to_sign_contract', function(){
		if($('#primary_tenant_enabled').prop('checked') == false && $('#allowed_to_autorize_and_pay_rent').prop('checked') == false && $('#allowed_to_sign_contract').prop('checked') == false){
			$('#allowed_to_sign_in').prop('checked', false);
			$('#allowed_to_sign_in').parent('label').addClass('add_opacity');
		} else {
			if($('#primary_tenant_enabled').prop('checked') == true || $('#allowed_to_autorize_and_pay_rent').prop('checked') == true){
				$('#allowed_to_sign_contract').prop('checked', true);
				$('#allowed_to_sign_contract').parent('label').addClass('add_opacity');
				$('#allowed_to_sign_in').prop('checked', true);
				$('#allowed_to_sign_in').parent('label').addClass('add_opacity');
			} else {
				$('#allowed_to_sign_contract').parent('label').removeClass('add_opacity');
				$('#allowed_to_sign_in').parent('label').removeClass('add_opacity');
			}
		}
	});

	// Start : Tenants

	// Load add New tenants page
	$(document).off('click', '.add_new_tenant').on('click', '.add_new_tenant', function(){
		// $('#tenantAddModal').modal('show');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		var module_name = $(this).attr('data-module');
		var tenant_lease_id = $(this).attr('data-id');
		var tenant_count = $(this).attr('data-tenant-count');
		// $('#tenantAddModal #tenant_detail_container_add').load('components/property/add_new_tenant.html', function(){
		$('#sidebar_heading').html('Add New Tenant');
		$('#sidebar_content_area').load('components/property/tenant_add_edit.html', function(){
			$('.save_tenant_data').removeClass('d-none');
			if(tenant_count == 0){
				$('#primary_tenant_enabled').parent('label').addClass('add_opacity');
				$('#allowed_to_autorize_and_pay_rent').parent('label').addClass('add_opacity');
				$('#allowed_to_sign_contract').parent('label').addClass('add_opacity');
				$('#allowed_to_sign_in').parent('label').addClass('add_opacity');
			} else {
				$('#primary_tenant_enabled').prop('checked', false);
				$('#allowed_to_autorize_and_pay_rent').prop('checked', false);
			}

			$('#module_name').val(module_name);
			if(typeof tenant_lease_id === 'undefined') {
				$('#tenant_lease_id').val(0);
			} else {
				$('#tenant_lease_id').val(tenant_lease_id);
			}
		});
	});

	// Add signup skip button
	$(document).off('click', '.signup_skip_button').on('click', '.signup_skip_button', function() {
		var module_name = $(this).attr('data-button');
		if(module_name == 'add-tenant') {
	    	skipTenant();
	    } else if(module_name == 'add-lease') {
	    	skipLease();
	    }
	});

	// Add tenant skip button
	// $(document).off('click', '.signup_step_skip_button').on('click', '.signup_step_skip_button', function() {
	// 	var module_name = $(this).attr('data-button');
	// 	if(module_name == 'add-tenant') {
	//     	skipAddTenant();
	//     } else if(module_name == 'add-lease') {
	//     	skipAddLease();
	//     }
	// });

	// Save Tenant
	$(document).off('click', '.save_tenant_data').on('click', '.save_tenant_data', function() {
		var button_type = $(this).attr('data-button');
	    saveTenantData(button_type);
	});

	// Save and Submit Tenant
	$(document).off('click', '.save_and_submit_tenant_data').on('click', '.save_and_submit_tenant_data', function() {
	    var button_type = $(this).attr('data-button');
	    saveTenantData(button_type);
	});

	// Load edit tenant page
	$(document).off('click', '.edit_tenant_detail').on('click', '.edit_tenant_detail', function(e){
		// e.stopPropagation();
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		var tenant_id = $(this).attr('data-id');
		// $('#tenantEditModal #tenant_detail_container_edit').load('components/property/edit_tenant.html', function() {
		$('#sidebar_heading').html('Edit Tenant');
		$('#sidebar_content_area').load('components/property/tenant_add_edit.html', function() {
			$('#tenant_add_form').attr('id', 'tenant_edit_form');
			$('.update_tenant_data').removeClass('d-none');
			getTenantDetailById(tenant_id);
		});
	});

	// delete tenant
	$(document).off('click', '.delete_tenant_detail').on('click', '.delete_tenant_detail', function(e){
		e.stopPropagation();
		var tenant_id = $(this).attr('data-id');
		ConfirmDelete('Tenant',tenant_id);
	});

	// delete tenant
	$(document).off('click', '.undelete_tenant_detail').on('click', '.undelete_tenant_detail', function(e){
		e.stopPropagation();
		var tenant_id = $(this).attr('data-id');
		ConfirmDelete('Tenant',tenant_id);
	});

	// Update tenant
	$(document).off('click', '.update_tenant_data').on('click', '.update_tenant_data', function() {
		var button_type = $(this).attr('data-button');
	    updateTenantData(button_type);
	});

	// Update and submit tenant
	$(document).off('click', '.update_and_submit_tenant_data').on('click', '.update_and_submit_tenant_data', function() {
		var button_type = $(this).attr('data-button');
	    updateTenantData(button_type);
	});

	// Submit tenant detail
	$(document).off('click', '.submit_tenant_detail').on('click', '.submit_tenant_detail', function(e) {
		e.stopPropagation();
		var property_id = $('#selected_property_id').val();
		var tenant_id = $(this).attr('data-id');
		submitTenantDetail(property_id, tenant_id);
	});

	// Invite tenant detail
	$(document).off('click', '.invite_tenant').on('click', '.invite_tenant', function(e) {
		e.stopPropagation();
		var property_id = $('#selected_property_id').val();
		var tenant_id = $(this).attr('data-id');
		var tenant_user_id = $(this).attr('data-user-id');

		checkIfTenantLinkedWithLease(property_id,tenant_id,tenant_user_id);
	});

	// Invite tenant detail
	$(document).off('click', '.check_invite_code').on('click', '.check_invite_code', function(e) {
		e.stopPropagation();
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		addAccessCode();
	});

	// Invite tenant detail
	$(document).off('click', '.rs_admin_invite_tenant').on('click', '.rs_admin_invite_tenant', function(e) {
		e.stopPropagation();
		$('.rs_admin_invite_tenant').attr('disabled', true);
		var property_id = $('#selected_property_id').val();
		var tenant_id = $('#rs_invite_tenant_id').val();
		inviteTenant(property_id, tenant_id);
	});

	// Load existing lease
	$(document).off('click', '.list_existing_lease').on('click', '.list_existing_lease', function() {
	    var tenant_id = $(this).attr('data-id');
	    var lease_id = $(this).attr('data-l-id');
	    var lease_ids = $(this).attr('data-lease-id');
		var module_name = $(this).attr('data-module');
		$('#leaseListModal #lease_list_container').load('components/property/existing_lease_detail.html', function(){
			$('#selected_tenant_id').val(tenant_id);
			getExistingLeaseDetail(lease_ids, lease_id);
		});
	});

	// Assign existing tenant to lease
	$(document).off('click', '.assign_lease_to_tenant').on('click', '.assign_lease_to_tenant', function() {
		$(this).attr('disabled',true);
		var property_id = $('#selected_property_id').val();
		var tenant_id = $('#selected_tenant_id').val();
		var lease_id = 0;

		var tenant_lease_array = [];

		// add selected user to chatroom members
		$("input:checkbox[name=existing_lease_checkbox]:checked").each(function() {
        	var selected_lease_id = $(this).attr('data-id');
        	tenant_lease_array.push({
				'id' : selected_lease_id
			});
        });

        assignLeaseToTenant(property_id,lease_id,tenant_id,JSON.stringify(tenant_lease_array))
	});

	// Load existing documents
	$(document).off('click', '.list_tenant_document').on('click', '.list_tenant_document', function() {
	    var tenant_ids = $(this).attr('data-tenant-id');
		$('#documentListModal #document_list_container').load('components/property/existing_document_detail.html', tenant_ids, function(){
			getTenantSignedDocument(tenant_ids);
		});
	});

	// Ticket

	// Get user properties list
	$(document).off('click', '.ticket_list').on('click', '.ticket_list', function() {
	    var ticket_id = $(this).attr('data-id');
	    var status = $(this).attr('data-status');
	    $(".ticket_list").removeClass('active');
    	$(this).addClass('active');
	    loadTicketDetail(ticket_id,status);
	});

	// When click on ticket table row display ticket detail
	$(document).off('click', '.ticket_table_row').on('click', '.ticket_table_row', function(){
	    var ticket_id = $(this).attr('data-id');
	    getTicketDetailPage(ticket_id);
	});

	//
	$(document).off('click', '.share_with_toggle').on('click', '.share_with_toggle', function(){
	    var id = $(this).attr('data-id');
	    var module_name = $(this).attr('data-module');
	    if( $(this).is(':checked') ){
		    updateShareTicketUser(id, module_name, 'on');
		}
		else{
		    updateShareTicketUser(id, module_name, 'off');
		}
	});

	// Load add New Tciket as Draft
	$(document).off('click', '.add_new_ticket').on('click', '.add_new_ticket', function(){
		saveDraftTicket();
	});

	// Update ticket data as Draft
	$(document).off('change', '#tickets-property-id').on('change', '#tickets-property-id', function(){
		updateDraftTicket('tickets-property-id');

		if(user_service_name == sa_role) {
			var property_id = $(this).val();
			if(property_id > 0) {
				getPropertyRelatedUsers(property_id);
			} else {
				$('#to_whom_container').html('');
			}
		}
	});

	// Update ticket data as Draft
	$(document).off('change', '.to_whom_dd').on('change', '.to_whom_dd', function(){
		updateDraftTicket('to_whom_dd');
	});

	// Update ticket data as Draft
	$(document).off('keyup', '#tickets-title').on('keyup', '#tickets-title', function(){
		updateDraftTicket('tickets-title');
	});

	// Add New Ticket
	$(document).off('click', '.save_ticket_data').on('click', '.save_ticket_data', function() {
	    saveTicketData();
	});

	// Delete Draft Ticket
	$(document).off('click', '.delete_ticket_data').on('click', '.delete_ticket_data', function(e) {
		e.preventDefault();
		var ticket_id = $(this).attr('data-id');
		ConfirmDelete('Ticket',ticket_id);
		return false;
	});

	// Resolve Ticket
	$(document).off('click', '.resolve_ticket_comment').on('click', '.resolve_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
		var status = $(this).attr('data-status');
		$('.resolve_ticket_comment, .close_ticket_comment, .reopen_ticket_comment').attr('disabled', true);
	    updateTicketStatus(ticket_id,status);
	});

	// Close Ticket
	$(document).off('click', '.reopen_ticket_comment').on('click', '.reopen_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
		var status = $(this).attr('data-status');
		$('.close_ticket_comment, .reopen_ticket_comment').attr('disabled', true);
	    updateTicketStatus(ticket_id,status);
	});

	// Close Ticket
	$(document).off('click', '.close_ticket_comment').on('click', '.close_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
		var status = $(this).attr('data-status');
		$('.close_ticket_comment, .reopen_ticket_comment').attr('disabled', true);
	    updateTicketStatus(ticket_id,status);
	});

	// Post ticket comment
	$(document).off('click', '.add_ticket_comment').on('click', '.add_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
	    addTicketComment(ticket_id);
	});

	// Edit ticket comment
	$(document).off('click', '.edit_comment').on('click', '.edit_comment', function() {
		var comment_id = $(this).attr('data-id');
		editTicketComment(comment_id);
	});

	// Update ticket comment
	$(document).off('click', '.update_comment').on('click', '.update_comment', function() {
		var comment_id = $('#edit_comment_id').val();
		updateTicketComment(comment_id);
	});

	// Cancel ticket comment edit
	$(document).off('click', '.cancel_comment').on('click', '.cancel_comment', function() {
		var comment_id = $('#edit_comment_id').val();
		cancelTicketComment(comment_id);
	});

	// Delete ticket comment
	$(document).off('click', '.delete_comment').on('click', '.delete_comment', function() {
		var comment_id = $(this).attr('data-id');
		ConfirmDelete('Comment', comment_id);
	});

	/*$(document).off('keyup', '#property_additional_information').on('keyup', '#property_additional_information', function() {
		var textlen = maxLength - $(this).val().length;
  		$('#rchars').text(textlen);
	});*/

	// Process Pending Payment
	$(document).off('click', '.process_pending_payment').on('click', '.process_pending_payment', function(e) {
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		$(this).attr('disabled', true);
		processPendingPayment(property_id);
	});

	// Pay to Property Owner
	$(document).off('click', '.pay_to_property_owner').on('click', '.pay_to_property_owner', function(e) {
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		$(this).attr('disabled', true);
		payToPropertyOwner(property_id);
	});

	// New Master Contract Creation UI
	$(document).off("click", ".add_master_contract").on('click', '.add_master_contract', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/master-contracts/view_master_contract.html', function(e) {
				getMasterContractDetail(0, 'add');
			});
		}, 500);
	});

	$(document).off("click", ".list_master_contracts").on('click', '.list_master_contracts', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/master_contracts.html');
		}, 500);
	});

	$(document).off("click", ".save_master_contract").on('click', '.save_master_contract', function (e) {
		if($masterContractForm.valid()){
			$(this).text('Processing...');
			$(this).attr('disabled', true);
			saveMasterContract();
		}
	});

	$(document).off("click", ".edit_master_contract").on('click', '.edit_master_contract', function (e) {
		var id = $(this).attr('data-id');
		openLoadingDialog($('#main_container'));
		// setTimeout(function(){
		    $('#main_container').load('components/master-contracts/view_master_contract.html',function(){
				$('.save_master_contract').attr('data-id', id);
				$('#master_contract_modal_heading').html('Edit Master Contract');
				getMasterContractDetail(id);
				$('.save_master_contract').removeClass('save_master_contract').addClass('update_master_contract');
			});
		// }, 500);
	});

	$(document).off("click", ".view_contract_variables").on('click', '.view_contract_variables', function (e) {
		openRightSidebar();
		getContractVariables(0, 'add');
	});

	$(document).off("click", ".update_master_contract").on('click', '.update_master_contract', function (e) {
		if($masterContractForm.valid()){
			$(this).text('Processing...');
			$(this).attr('disabled', true);
			var id = $(this).attr('data-id');
			updateMasterContract(id);
		}
	});

	$(document).off("click", ".delete_master_contract").on('click', '.delete_master_contract', function (e) {
		var id = $(this).attr('data-id');
		ConfirmDelete('MasterContract', id);
	});

	// Zoho Template Create UI
	$(document).off("click", ".create_zoho_template").on('click', '.create_zoho_template', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/template/create_template.html', function(){
		    	//getFloatingActionButton('Template', '#create-template-wizard');
		    	setTimeout(function () {
		            getDocumentTypes();
		        }, 300);

		    	var html = '';
				$.each(role_dd, function( key, value ) {
					html += '<option value="'+value+'">'+value+'</option>';
				});
				$('#role1').html(html);
		    });
		}, 500);
	});

	// Zoho Template Edit UI
	$(document).off("click", ".edit_zoho_template").on('click', '.edit_zoho_template', function (e) {
		var template_id = $(this).attr('data-template-id');
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/template/update_template.html', template_id, function(){
		    	var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var id = rand_num1+template_id+rand_num2;
		    	window.history.pushState("", "", '?action=template&id='+id);
		    	getZohoTemplateDetailById(template_id);
		    	//getFloatingActionButton('Template', '#update-template-wizard');
		    });
		}, 2000);
	});

	// Zoho Template Delete
	$(document).off("click", ".delete_zoho_template").on('click', '.delete_zoho_template', function (e) {
    	var template_id = $(this).attr('data-template-id');
    	ConfirmDelete('ZohoTemplate', template_id);
	});

	// master Agreement Create UI
	$(document).off("click", ".create_master_agreement").on('click', '.create_master_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/agreement/create_master_agreement.html');
		}, 500);
	});

	// Master Agreement submit create agreement detail
	$(document).off("click", ".submit_master_agreement_detail").on('click', '.submit_master_agreement_detail', function (e) {
		if($createMasterAgreementForm.valid()){
			createMasterAgreement();
		}
	});

	// cancel create master_agreement
	$(document).off("click", ".cancel_create_master_agreement").on('click', '.cancel_create_master_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// master Agreement Disabled
	$(document).off("click", ".disable_master_agreement").on('click', '.disable_master_agreement', function (e) {
		var master_agreement_id = $(this).attr('data-master-agreement-id');
	    ConfirmDisable('MasterAgreement', master_agreement_id);
	});

	// Add signature agreement to property
	$(document).off("click", ".add_signature_agreement").on('click', '.add_signature_agreement', function (e) {
		var master_agreement_id = $(this).attr('data-master-agreement-id');
		var html_form = $(this).attr('data-form');
		var table_name = $(this).attr('data-table');
		var agreement_url = $(this).attr('data-url');
		$('#addSignatureAgreement').modal('show');
		openLoadingDialog($('#addSignatureAgreement .agreement_data_form'));
		setTimeout(function(){
		    $('#addSignatureAgreement .agreement_data_form').load('components/agreement/forms/'+html_form, function() {
		    	$('#selected_master_agreement_id').val(master_agreement_id);
		    	$('#selected_agreement_table').val(table_name);
		    	$('#selected_agreement_url').val(agreement_url);
		    	var service_id = 0;
		    	getServices(service_id, 'agreement');
		    });
		}, 500);
	});

	$(document).off('click', '.save_empty_agreement_data').on('click', '.save_empty_agreement_data', function() {
	    if($agreementForm.valid()){
	    	$('.save_empty_agreement_data').attr('disabled', true);
	        var master_agreement_id = $('#selected_master_agreement_id').val();
	        var agreement_property_id = $('#agreement_property_id').val();
	        var agreement_service_id = $('#agreement_service_id').val();
	        var property_owner_name = $('#property_owner_name').val();
	        var property_address = $('#property_address').val();
	        var tenant_name = $('#tenant_name').val();
	        var monthly_rent = $('#monthly_rent').val();
	        var selected_agreement_table = $('#selected_agreement_table').val();

	        var data = 'user_id='+user_id+'&master_agreement_id='+master_agreement_id+'&property_id='+agreement_property_id+'&agreement_service_id='+agreement_service_id+'&property_owner_name='+property_owner_name+'&property_address='+property_address+'&tenant_name='+tenant_name+'&monthly_rent='+monthly_rent+'&selected_agreement_table='+selected_agreement_table;

	        var pdfBase64data = '';
	        const { PDFDocument } = PDFLib
	        async function fillForm() {
	            // Fetch the PDF with form fields
	            const formUrl = $('#selected_agreement_url').val();
	            const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())

	            // Load a PDF with form fields
	            const pdfDoc = await PDFDocument.load(formPdfBytes)

	            // Get the form containing all the fields
	            const form = pdfDoc.getForm()

	            // Get all fields in the PDF by their names
	            form.getTextField('landlord_field').setText(property_owner_name);
	            form.getTextField('tenant_field').setText(tenant_name);
	            form.getTextField('address_field').setText(property_address);
	            form.getTextField('rent_field').setText(monthly_rent);

	            // Flatten the form's fields
	            form.flatten();

	            // Serialize the PDFDocument to bytes (a Uint8Array)
	            const pdfBytes = await pdfDoc.save()
	            const bytes  = new Uint8Array( pdfBytes );
	            const blob   = new Blob( [ bytes ], { type: "application/pdf" } );

	            var reader = new FileReader();
	            reader.readAsDataURL(blob);
	            reader.onloadend = function() {
	                pdfBase64data = reader.result;
	                data = data+'&document='+encodeURIComponent(pdfBase64data);

	                $.ajax({
	                    method: 'POST',
	                    url: api_url+"master-agreements/add-signature-agreement",
	                    data: data,
	                    beforeSend: function(xhr) {
	                        xhr.setRequestHeader('Authorization', accessToken);
	                    },
	                    success: function(response) {
	                        display_error_message(response);
	                        if(response.response_data.success == true) {
	                            $('#addSignatureAgreement').modal('hide');
	                            $('.modal-backdrop').remove();
	                            $('.module_tab_container .nav-link').removeClass('active');
	                            $('a.nav-link[href="#t-agreements"]').addClass('active');
	                            var agreement_id = response.agreement.id;
	                            openLoadingDialog($('#main_container'));
								setTimeout(function(){
								    $('#main_container').load('components/agreement/update_agreement.html', agreement_id, function(){
								    	var rand_num1 = random_number(11);
										var rand_num2 = random_number(20);
										var eaid = rand_num1+agreement_id+rand_num2;
								    	window.history.pushState("", "", '?action=template&eaid='+eaid);
								    	getAgreementDetailById(agreement_id);
								    });
								}, 2000);
	                        } else {
	                        	$('.save_empty_agreement_data').attr('disabled', false);
	                        }
	                    }
	                });
	            }

	            // Trigger the browser to download the PDF document
	            //download(pdfBytes, "edited.pdf", "application/pdf");
	        }

	        fillForm();
	    }
	});

	/* Start : Agreements */

	// Agreement Create UI
	$(document).off("click", ".create_agreement").on('click', '.create_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/agreement/create_agreement.html', function(){
		    	//getFloatingActionButton('Template', '#create-template-wizard');
		    	setTimeout(function () {
		            getDocumentTypes();
		        }, 300);

		    	var html = '';
				$.each(role_dd, function( key, value ) {
					html += '<option value="'+value+'">'+value+'</option>';
				});
				$('#role1').html(html);
		    });
		}, 500);
	});

	// cancel create agreement
	$(document).off("click", ".cancel_create_agreement").on('click', '.cancel_create_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// Agreement submit create agreement detail
	$(document).off("click", ".submit_agreement_detail").on('click', '.submit_agreement_detail', function (e) {
		if($createAgreementForm.valid()){
			createNewAgreement();
		}
	});

	// Agreement Edit UI
	$(document).off("click", ".edit_agreement").on('click', '.edit_agreement', function (e) {
		var agreement_id = $(this).attr('data-agreement-id');
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/agreement/update_agreement.html', agreement_id, function(){
		    	var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var eaid = rand_num1+agreement_id+rand_num2;
		    	window.history.pushState("", "", '?action=template&eaid='+eaid);
		    	getAgreementDetailById(agreement_id);
		    });
		}, 2000);
	});

	// submit agreement template detail
	$(document).off("click", ".update_agreement_detail").on('click', '.update_agreement_detail', function (e) {
		if($updateAgreementForm.valid()){
			updateAgreement();
		}
	});

	$(document).off("click", ".back_to_edit_agreement").on('click', '.back_to_edit_agreement', function (e) {
		var agreement_id = $('#pdf_agreement_id').val();
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
			$('#main_container').load('components/agreement/update_agreement.html', agreement_id, function(){
				var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var eaid = rand_num1+agreement_id+rand_num2;
		    	window.history.pushState("", "", '?action=template&eaid='+eaid);
				getAgreementDetailById(agreement_id);
			});
		}, 2000);
	});

	// Agreement document image hover
	$(document).off('mouseenter', '.preview_agreement_document').on('mouseenter', '.preview_agreement_document', function() {
		$(this).find('.delete_agreement_document').removeClass('d-none');
	}).off('mouseleave', '.preview_agreement_document').on('mouseleave', '.preview_agreement_document', function() {
		$(this).find('.delete_agreement_document').addClass('d-none');
	});

	// Delete property image
	$(document).off('click', '.delete_agreement_document').on('click', '.delete_agreement_document', function() {
	    var agreement_id = $(this).attr('data-agreement-id');
	    var document_id = $(this).attr('data-document-id');
	    var id = agreement_id+'&&'+document_id;
	    ConfirmDelete('Agreement', id);
	});

	// Save document data with coordibnate
	$(document).off("click", ".save_agreement_document_field_data").on('click', '.save_agreement_document_field_data', function (e) {

		//$('.save_agreement_document_data').attr('disabled',true);

		var recipients = [];
	  	$( "div.recipient_row" ).each(function( index ) {
	  		var recipient_action_id = $(this).attr('data-action-id');
	  		var recipient_role = $(this).attr('data-role');
	  		var recipient_email = $(this).attr('data-email');
	  		var recipient_name = $(this).attr('data-name');
	  		var signing_order = $(this).attr('data-signing-order');

	  		var recipient_row = {
				'agreement_recipient_id' : recipient_action_id,
				'signing_order': signing_order,
				'role' : recipient_role,
				'recipient_email' : '',
				'recipient_name' : '',
				'deleted_fields': [],
				'deleted_radio_fields': [],
				'fields' : {
					"checkbox" : [],
					"radiogroup" : [],
					"signature" : [],
					"image" : [],
					"text" : [],
					"email" : [],
					"date" : [],
					"name" : [],
					"company" : [],
					"jobtitle" : [],
				},
			};

			/* Create deleted field array and pass dynamic value */
	  		var deleted_fields_arr = [];
			if($('#'+recipient_action_id).val()) {
				var deleted_fields = $('#'+recipient_action_id).val();
	    		deleted_fields_arr =  deleted_fields.split(',');
	    		recipient_row['deleted_fields'] = deleted_fields_arr;
	    	}

	  		// Create field array and append to recipients

	  		var field_label = '';
		  	var field_array = [];

			$( ".viewer-body .image-header .item_element" ).each(function( index ) {

			  	var action_id = $(this).attr('data-action-id');

			  	if(recipient_action_id == action_id) {

			  		var field_category = $(this).attr('field-category');
			  		field_label = field_category;

			  		/* Left and Top position calculation */

			  		var leftPixel = parseFloat($(this).css('left'));
			  		var topPixel = parseFloat($(this).css('top'));

			  		/*console.log(canvas_width);
			  		console.log(canvas_height);*/

			  		// Percentage with 817 * dynamic
			  		var leftPercentage = Math.round(((leftPixel / canvas_width) * 100));
			  		var topPercentage = Math.round(((topPixel / canvas_height) * 100));

			  		/*console.log('before');
			  		console.log(leftPercentage);
			  		console.log(topPercentage);*/

			  		// Created field data array
				    var field_data = {
			  			'fieldtype_id' : $(this).attr('field-type-id'),
			  			'fieldtype_name' : $(this).attr('id'),
			  			'fieldtype_category' : $(this).attr('field-category'),
			  			'agreement_document_id' : $(this).closest('div.image-header').attr('document-id'),
			  			'agreement_recipient_id' : action_id,
			  			'page_no' : $(this).attr('page-no'),
			  			'is_mandatory': true,
			  			'x_coord' : leftPercentage,
			  			'y_coord' : topPercentage,
			  			'abs_width' : 100,
			  			'abs_height' : 25,
			  		};

			  		if($(this).attr('field-id') > 0) {
			  			field_data['field_id'] = $(this).attr('field-id');
			  		} else {
			  			field_data['field_id'] = 0;
			  		}

				  	recipient_row['fields'][''+field_label+''].push(field_data);
				}
			});

			recipients.push(recipient_row);
	  	});

	  	//console.log(recipients);
	  	//console.log(JSON.stringify(recipients));
	  	//return false;

	  	var agreement_id = $('#pdf_agreement_id').val();
    	saveAgreementDocumentFieldData(agreement_id, JSON.stringify(recipients));
	});

	$(document).off('click', '.document_send_for_review').on('click', '.document_send_for_review', function(){
		$(this).addClass('add_opacity');
		var agreement_id = $(this).attr('data-agreement-id');
		sendDocumentForReview(agreement_id);
	});

	$(document).off('click', '.review_document').on('click', '.review_document', function(){
		var ticket_id = $(this).attr('data-ticket-id');
		var property_id = $(this).attr('data-property-id');
		var agreement_id = $(this).attr('data-agreement-id');
		var agreement_recipient_id = $(this).attr('data-agreement-recipient-id');

		$('#viewDocumentModal').modal('show');
		openLoadingDialog($('#document_preview_container'));
		$('#viewDocumentModal #document_preview_container').load('components/agreement/view_draft_document.html', function(){
			$('#selected_agreement_recipient_id').val(agreement_recipient_id);
			$('#selected_agreement_id').val(agreement_id);
			$('#selected_property_id').val(property_id);
			$('#selected_ticket_id').val(ticket_id);
			getDocumentForReview(property_id,agreement_id);

			// Load Ticket Detail
			openLoadingDialog($('#ticket_detail_container'));
			setTimeout(function(){
				$('#ticket_detail_container').load('components/ticket/ticket_detail.html', ticket_id , function(){
					loadTicketDetail(ticket_id);
			        $('.close_ticket_comment').addClass('d-none');
			        $('.reopen_ticket_comment').addClass('d-none');
			        $('#ticket_detail_container .card-header').remove();
			    });
			}, 1000);
		});
	});

	$(document).off('click', '.accept_document').on('click', '.accept_document', function(){
		var agreement_recipient_id = $('#selected_agreement_recipient_id').val();
		var agreement_id = $('#selected_agreement_id').val();
		var property_id = $('#selected_property_id').val();
		acceptDocument(agreement_recipient_id,agreement_id,property_id);
	});

	$(document).off('click', '.download_draft_document').on('click', '.download_draft_document', function(){
		var agreement_id = $('#selected_agreement_id').val();
		var property_id = $('#selected_property_id').val();
		downloadDraftDocument(agreement_id, property_id);
	});

	$(document).off('click', '.document_send_for_signature').on('click', '.document_send_for_signature', function(){
		$(this).addClass('add_opacity');
		var agreement_id = $(this).attr('data-agreement-id');
		var property_id = $(this).attr('data-property-id');
		sendDocumentForSignature(agreement_id, property_id);
	});

	$(document).off('click', '.sign_document').on('click', '.sign_document', function(){
		var ticket_id = $(this).attr('data-ticket-id');
		var property_id = $(this).attr('data-property-id');
		var agreement_id = $(this).attr('data-agreement-id');
		var agreement_recipient_id = $(this).attr('data-agreement-recipient-id');

		$('#viewDocumentModal').modal('show');
		openLoadingDialog($('#document_preview_container'));
		$('#viewDocumentModal #document_preview_container').load('components/agreement/view_final_document.html', function(){
			$('#selected_agreement_recipient_id').val(agreement_recipient_id);
			$('#selected_agreement_id').val(agreement_id);
			$('#selected_property_id').val(property_id);
			$('#selected_ticket_id').val(ticket_id);
			getDocumentForSignature(property_id,agreement_id,agreement_recipient_id);

			// Load Ticket Detail
			openLoadingDialog($('#ticket_detail_container'));
			setTimeout(function(){
				$('#ticket_detail_container').load('components/agreement/signature_form.html', function(){

			    });
			}, 1000);
		});
	});

	/* End : Agreemnts */

	// Zoho Template UI > Add Receipents
	$(document).off("click", ".addRowButton").on('click', '.addRowButton', function (e) {
		var current_sno = $('#recipients_container .recipients_data').length;
		var next_sno = $('#recipients_container .recipients_data').length + 1;

		$('.addRowButton[data-row-id='+current_sno+']').remove();
		$('.removeRowButton[data-row-id='+current_sno+']').removeClass('d-none');

		var data = '';
		data += '<div id="recipients_'+next_sno+'" class="form-row recipients_data">';
            data += '<div class="form-group col-md-5 col-xl-5">';
            	data += '<div class="input-group mb-2 mr-sm-2">';
					data += '<div class="input-group-prepend">';
						data += '<input type="hidden" name="action_id[]" id="action_id'+next_sno+'" class="action_input" value="">';
						data += '<input type="hidden" name="seq_no[]" id="seq_no'+next_sno+'" class="sequenceNo" value="'+next_sno+'">';
						data += '<div class="input-group-text sequenceNo">'+next_sno+'</div>';
					data += '</div>';
					//data += '<input type="text" name="role[]" id="role'+next_sno+'" class="form-control" placeholder="Role">';
					data += '<select name="role[]" id="role'+next_sno+'" class="form-control">';
						var selected = '';
						$.each(role_dd, function( key, value ) {
							data += '<option value="'+value+'">'+value+'</option>';
						});
					data += '</select>';
				data += '</div>';
            data += '</div>';
            /*data += '<div class="form-group col-md-5 col-xl-5">';
            	data += '<input type="text" name="recipient_email[]" id="recipient_email'+next_sno+'" class="form-control" placeholder="Email">';
            data += '</div>';*/
            /*data += '<div class="form-group col-md-3 col-xl-3">';
            	data += '<input type="text" name="recipient_name[]" id="recipient_name'+next_sno+'" type="text" class="form-control" placeholder="Name">';
	        data += '</div>';*/
	        data += '<div class="action_btn_group form-group col-md-2 col-xl-2">';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="removeRowButton btn btn-default mr-2"><i class="fas fa-minus"></i></a>';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>';
	        data += '</div>';
        data += '</div>';

        $('#recipients_container').append(data);

        if($('.recipients_data').length > 1) {
        	$('.recipients_data:first').find('.removeRowButton').remove();
        }
	});

	// Zoho Template UI > Remove Receipents
	$(document).off("click", ".removeRowButton").on('click', '.removeRowButton', function (e) {
		var seq_no = $(this).attr('data-row-id');

		$('#recipients_'+seq_no).remove();

		var deletedRowId = $(this).closest('.recipients_data').find('input.action_input').val();
		if(deletedRowId != null) {
			var delimeter = '';
			if($('#deletedZohoActionId').val() != "") { delimeter = ','; }
			var deletedActionIds = $('#deletedZohoActionId').val($('#deletedZohoActionId').val()+delimeter+deletedRowId);

			if($('#deletedAgreementRecipientId').val() != "") { delimeter = ','; }
			var deletedAgreementRecipientIds = $('#deletedAgreementRecipientId').val($('#deletedAgreementRecipientId').val()+delimeter+deletedRowId);
		}

		if($('#recipients_container .recipients_data').length == 1) {
			$('.action_btn_group').html('');
			$('.action_btn_group').append('<a href="javascript:void(0)" data-row-id="1" class="removeRowButton btn btn-default mr-2 d-none"><i class="fas fa-minus"></i></a>');
			$('.action_btn_group').append('<a href="javascript:void(0)" data-row-id="1" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>');
		} else if($('#recipients_container .recipients_data').length > 1 && $('#recipients_container .recipients_data .addRowButton').length == 0) {
			var last_seq_no = $('#recipients_container .recipients_data:last').find('input.sequenceNo').val();
			$('#recipients_'+last_seq_no+' .action_btn_group').append('<a href="javascript:void(0)" data-row-id="'+last_seq_no+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>');
		}

		arrangeSeqNo(seq_no);
	});

	// User Contract Form UI > Add Receipents
	$(document).off("click", ".addRecipientButton").on('click', '.addRecipientButton', function (e) {
		var current_sno = $('#recipients_container .recipients_data').length;
		var next_sno = $('#recipients_container .recipients_data').length + 1;

		$('.addRecipientButton[data-row-id='+current_sno+']').remove();
		$('.removeRecipientButton[data-row-id='+current_sno+']').removeClass('d-none');

		var data = '';
		data += '<div id="recipients_'+next_sno+'" class="form-row recipients_data">';
			data += '<div class="form-group col-md-4 col-xl-4">';
                data += '<div class="applicantNo">Primary Applicant</div>';
            data += '</div>';
            data += '<div class="form-group col-md-6 col-xl-6">';
            	data += '<div class="input-group mb-2 mr-sm-2">';
					data += '<div class="input-group-prepend">';
						data += '<input type="hidden" name="action_id[]" id="action_id'+next_sno+'" class="action_input" value="">';
						data += '<input type="hidden" name="seq_no[]" id="seq_no'+next_sno+'" class="sequenceNo" value="'+next_sno+'">';
						data += '<div class="input-group-text sequenceNo">'+next_sno+'</div>';
					data += '</div>';
					data += '<select name="role[]" id="role'+next_sno+'" class="form-control">';
						var selected = '';
						$.each(role_dd, function( key, value ) {
							data += '<option value="'+value+'">'+value+'</option>';
						});
					data += '</select>';
				data += '</div>';
            data += '</div>';
            data += '<div class="action_btn_group form-group col-md-2 col-xl-2">';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="removeRecipientButton btn btn-default mr-2"><i class="fas fa-minus"></i></a>';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="addRecipientButton btn btn-default"><i class="fas fa-plus"></i></a>';
	        data += '</div>';
        data += '</div>';

        $('#recipients_container').append(data);

        if($('.recipients_data').length > 1) {
        	$('.recipients_data:first').find('.removeRecipientButton').remove();
        }
	});

	// Zoho document image hover
	$(document).off('mouseenter', '.preview_zoho_document').on('mouseenter', '.preview_zoho_document', function() {
		$(this).find('.delete_zoho_document').removeClass('d-none');
	}).off('mouseleave', '.preview_zoho_document').on('mouseleave', '.preview_zoho_document', function() {
		$(this).find('.delete_zoho_document').addClass('d-none');
	});

	// Delete property image
	$(document).off('click', '.delete_zoho_document').on('click', '.delete_zoho_document', function() {
	    var template_id = $(this).attr('data-template-id');
	    var document_id = $(this).attr('data-document-id');
	    var id = template_id+'&&'+document_id;
	    ConfirmDelete('Document', id);
	});

	// Zoho toggle more setting menu
	$(document).off("click", ".more_setting").on('click', '.more_setting', function (e) {
		$('#more_setting').toggleClass('d-none');
	});

	// Zoho cancel create template
	$(document).off("click", ".cancel_create_template").on('click', '.cancel_create_template', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// Zoho submit zoho create template detail
	$(document).off("click", ".submit_zoho_template_detail").on('click', '.submit_zoho_template_detail', function (e) {
		if($createTemplateForm.valid()){
			$(this).attr('disabled', true);
			zohoCreateNewTemplate();
		}
	});

	// Zoho submit zoho create template detail
	$(document).off("click", ".update_zoho_template_detail").on('click', '.update_zoho_template_detail', function (e) {
		if($updateTemplateForm.valid()){
			$(this).attr('disabled', true);
			zohoUpdateTemplate();
		}
	});

	// Zoho recipient row detail
	$(document).off("click", ".recipient_row").on('click', '.recipient_row', function (e) {
		$('.recipient_row').removeClass("recipient_row_bg");
		var bg_color = $(this).attr('data-bg-color');
		var border_color = $(this).attr('data-border-color');
		$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+"; border-left: 3px solid "+border_color+" !important; border-right: 3px solid "+border_color+" !important; } </style>").appendTo("head");
		$(this).addClass("recipient_row_bg");

		// Change color of fields section
		/*$('#field_header').css({'background': border_color, 'color': 'white'});
		$('.field-li-view').css({'border': '1px solid '+border_color});
		$('.field-li-view .drag-icon').css({'color': border_color});
		$('.field-li-view .field-icon').css({'background-color': border_color});*/
	});

	$(document).off("click", ".item_element").on('click', '.item_element', function (e) {
		var field_id = $(this).attr('field-id');
		var action_id = $(this).attr('data-action-id');
		var id = $(this).attr('data-id');
		var type = $(this).attr('name');
		ConfirmFieldDelete(type, id, field_id, action_id);
	});


	$(document).off("click", ".back_to_edit_template").on('click', '.back_to_edit_template', function (e) {
		var template_id = $('#pdf_template_id').val();
		var rand_num1 = random_number(11);
		var rand_num2 = random_number(20);
		var tid = rand_num1+template_id+rand_num2;
		window.history.pushState("", "", '?action=template&id='+tid);
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
			$('#main_container').load('components/template/update_template.html', template_id, function(){
				getZohoTemplateDetailById(template_id);
		    	//getFloatingActionButton('Template', '#update-template-wizard');
			});
		}, 2000);
	});

	// Save document data with coordibnate
	$(document).off("click", ".save_document_data").on('click', '.save_document_data', function (e) {

		$('.save_document_data').attr('disabled',true);

		var actions = [];
	  	$( "div.recipient_row" ).each(function( index ) {
	  		var recipient_action_id = $(this).attr('data-action-id');
	  		var recipient_role = $(this).attr('data-role');
	  		var recipient_email = $(this).attr('data-email');
	  		var recipient_name = $(this).attr('data-name');

	  		var action_type = $(this).attr('data-action-type');
	  		var signing_order = $(this).attr('data-signing-order');
	  		var in_person_name = $(this).attr('data-in-person-name');
	  		var verify_recipient = $(this).attr('data-verify-recipient');
	  		var verification_type = $(this).attr('data-verification-type');

	  		var recipient_row = {
				'action_id' : recipient_action_id,
				'action_type': action_type,
				'signing_order': signing_order,
				'role' : recipient_role,
				'recipient_email' : '',
				'recipient_name' : '',
				'in_person_name': in_person_name,
				'verify_recipient': verify_recipient,
				'verification_type': verification_type,
				'deleted_fields': [],
				'deleted_radio_fields': [],
				'fields' : {
					"text_fields" : [],
					"check_boxes" : [],
					"image_fields" : [],
					"radio_groups" : [],
					"date_fields" : []
				},
			};

			/* Create deleted field array and pass dynamic value */
	  		var deleted_fields_arr = [];
			if($('#'+recipient_action_id).val()) {
				var deleted_fields = $('#'+recipient_action_id).val();
	    		deleted_fields_arr =  deleted_fields.split(',');
	    		recipient_row['deleted_fields'] = deleted_fields_arr;
	    	}

	  		// Create field array and append to actions

	  		var field_label = '';
		  	var field_array = [];

			$( ".viewer-body .image-header .item_element" ).each(function( index ) {

			  	var action_id = $(this).attr('data-action-id');

			  	if(recipient_action_id == action_id) {

			  		var field_category = $(this).attr('field-category');
			  		if(field_category == 'textfield') {
			  			field_label = 'text_fields';
			  		} else if(field_category == 'checkbox') {
			  			field_label = 'check_boxes';
			  		}else if(field_category == 'image') {
			  			field_label = 'image_fields';
			  		} else if(field_category == 'radiogroup') {
			  			field_label = 'radio_groups';
			  		} else if(field_category == 'datefield') {
			  			field_label = 'date_fields';
			  		}

			  		/* Left and Top position calculation */

			  		var leftPixel = parseFloat($(this).css('left'));
			  		var topPixel = parseFloat($(this).css('top'));

			  		/*console.log(canvas_width);
			  		console.log(canvas_height);*/

			  		// Percentage with 817 * dynamic
			  		var leftPercentage = Math.round(((leftPixel / canvas_width) * 100));
			  		var topPercentage = Math.round(((topPixel / canvas_height) * 100));

			  		/*console.log('before');
			  		console.log(leftPercentage);
			  		console.log(topPercentage);*/

			  		// original_width and original_height
			  		var original_width = $(this).closest('.image-header').attr('original-width');
			  		var original_height = $(this).closest('.image-header').attr('original-height');
			  		leftPercentage = Math.round(((original_width * leftPercentage) / 100));
			  		topPercentage = Math.round(((original_height * topPercentage) / 100));

			  		/*console.log('after');
			  		console.log(leftPercentage);
			  		console.log(topPercentage);*/

			  		// Created field data array
				    var field_data = {
			  			'field_type_name' : $(this).attr('id'),
			  			'field_name' : $(this).attr('id'),
			  			'document_id' : $(this).closest('div.image-header').attr('document-id'),
			  			'action_id' : action_id,
			  			'page_no' : $(this).attr('page-no'),
			  			'is_mandatory': true,
			  			'x_coord' : leftPercentage,
			  			'y_coord' : topPercentage,
			  			'abs_width' : 100,
			  			'abs_height' : 25,
			  		};

			  		if($(this).attr('field-id') > 0) {
			  			field_data['field_id'] = $(this).attr('field-id');
			  		}

				  	recipient_row['fields'][''+field_label+''].push(field_data);
				}
			});

			actions.push(recipient_row);
	  	});

	  	/*console.log(actions);
	  	console.log(JSON.stringify(actions));
	  	return false;*/

	  	var template_id = $('#pdf_template_id').val();
    	saveZohoDocumentData(template_id, JSON.stringify(actions));
	});

	// Add New Linking account
	$(document).off('click', '.add_new_account').on('click', '.add_new_account', function(){
	    $('#linkAccountModal').modal('show');
	    openLoadingDialog($('#link_form_container'));
		$('#link_form_container').load('components/myaccount/verify.html', function(){

	    });
	});

	// Link Account button
	$(document).off('click', '.link_account_btn').on('click', '.link_account_btn', function(){
		if(!$passcodeForm.valid()){
			return false;
		} else {
			linkAccount();
		}
	});

	// Link Account button
	$(document).off('click', '.unlink_account').on('click', '.unlink_account', function(){
		var user_id = user.user_details.user_id;
		var id = $(this).attr('data-id');
		unlinkAccount(id, user_id);
		$(this).closest('.switch_user').remove();
	});

	$(document).off("keypress keyup blur", ".no_special_char_allow").on('keypress keyup blur', '.no_special_char_allow', function (e) {
       	var report_input = $(this).val();
       	var regExp = /[`~!@%#^|+\=?;'"<>\{\}\[\]\\\/]/gi;
       	var isSplChar = regExp.test(report_input);
       	if(isSplChar)
       	{
           	var no_spl_char = report_input.replace(/[`~!@%#^|+\=?;'"<>\{\}\[\]\\\/]/gi, '');
           	$(this).val(no_spl_char);
       	}
   	});

	   $(document).off("keypress keyup blur", ".invoice_number_char_allow").on('keypress keyup blur', '.invoice_number_char_allow', function (e) {
		var report_input = $(this).val();
		var regExp = /[`~!@%^|+=?;'"<>\{\}\[\]]/gi;
		var isSplChar = regExp.test(report_input);
		if(isSplChar)
		{
			var no_spl_char = report_input.replace(/[`~!@%^|+=?;'"<>\{\}\[\]]/gi, '');
			$(this).val(no_spl_char);
		}
	});

   	$(document).off("keypress keyup blur", ".allow_numeric_with_decimal").on('keypress keyup blur', '.allow_numeric_with_decimal', function (e) {
       	$(this).val($(this).val().replace(/[^0-9\.|\,]/g,''));
        if(event.which == 44)
        {
        	return true;
        }
        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57  )) {
        	event.preventDefault();
        }
   	});

   	$(document).off("keypress keyup blur", ".numbersOnly").on('keypress keyup blur', '.numbersOnly', function (e) {
       	this.value = this.value.replace(/[^0-9]/g,'');
   	});

   	// Subscription Section

   	$(document).off("change", ".get_pricing_data").on('change', '.get_pricing_data', function (e) {
   		var plan_id = $('input[name="plan-radio"]:checked').attr('data-id');
   		if(plan_id > 0) {
   			loadPlanPricingData(plan_id);
   		}
   	});

	$(document).off("change", ".get_coupon_details_data").on('change', '.get_coupon_details_data', function (e) {
		var coupon_id = $('input[name="coupon-radio"]:checked').attr('data-id');
		if(coupon_id > 0) {
			loadCouponDetailsData(coupon_id);
		}
	});

   	// Start : Coupon Section

   	$(document).off("click", ".add_new_coupon").on('click', '.add_new_coupon', function (e) {
   		$('#coupon_form_container_edit').html('');
   		$('#couponAddModal').modal('show');
		openLoadingDialog($('#coupon_form_container_add'));
		$('#couponAddModal #coupon_form_container_add').load('components/subscription/add_coupon.html');
   	});

   	$(document).off("click", ".edit_coupon").on('click', '.edit_coupon', function (e) {
   		$('#coupon_form_container_add').html('');
   		var coupon_id = $(this).attr('data-id');
   		$('#couponEditModal').modal('show');
		openLoadingDialog($('#coupon_form_container_edit'));
		$('#couponEditModal #coupon_form_container_edit').load('components/subscription/edit_coupon.html', coupon_id, function(){
			$('#selected_coupon_id').val(coupon_id);
			setTimeout(function(){
				getCouponDetailById(coupon_id);
			}, 500);
		});
   	});

   	$(document).off('click', '.save_coupon_data').on('click', '.save_coupon_data', function() {
		var type = $(this).attr('data-type');
	    saveCouponData(type);
	});

	// Start : Plan Section

	$(document).off("click", ".add_new_plan").on('click', '.add_new_plan', function (e) {
   		$('#plan_detail_container_edit').html('');
   		$('#planAddModal').modal('show');
		openLoadingDialog($('#plan_detail_container_add'));
		$('#planAddModal #plan_detail_container_add').load('components/subscription/add_plan.html');
   	});

   	$(document).off("click", ".edit_plan").on('click', '.edit_plan', function (e) {
   		$('#plan_detail_container_add').html('');
   		var plan_id = $(this).attr('data-id');
   		$('#planEditModal').modal('show');
		openLoadingDialog($('#plan_detail_container_edit'));
		$('#planEditModal #plan_detail_container_edit').load('components/subscription/edit_plan.html', plan_id, function(){
			$('#selected_plan_id').val(plan_id);
			setTimeout(function(){
				getPlanDetailById(plan_id);
			}, 500);
		});
   	});

   	$(document).off('click', '.save_plan_data').on('click', '.save_plan_data', function() {
		var type = $(this).attr('data-type');
	    savePlanData(type);
	});

	$(document).off("click", ".add_new_coupon_detail").on('click', '.add_new_coupon_detail', function (e) {
		$('#coupon_details_container_edit').html('');
		$('#couponDetailsAddModal').modal('show');
		openLoadingDialog($('#coupon_details_container_add'));
		$('#couponDetailsAddModal #coupon_details_container_add').load('components/subscription/add_coupon_details.html', function(e){
			// var coupon_id = $('input[name="coupon-radio"]:checked').attr('data-id');
			// $('#selected_coupon_id').val(coupon_id);
		});
	});

	// Start : Plan Pricing Section

	$(document).off("click", ".add_new_plan_pricing").on('click', '.add_new_plan_pricing', function (e) {
   		$('#plan_pricing_detail_container_edit').html('');
   		$('#planPricingAddModal').modal('show');
		openLoadingDialog($('#plan_pricing_detail_container_add'));
		$('#planPricingAddModal #plan_pricing_detail_container_add').load('components/subscription/add_plan_pricing.html');
   	});

   	$(document).off("click", ".edit_plan_pricing").on('click', '.edit_plan_pricing', function (e) {
   		$('#plan_pricing_detail_container_add').html('');
   		var plan_pricing_id = $(this).attr('data-id');
   		$('#planPricingEditModal').modal('show');
		openLoadingDialog($('#plan_pricing_detail_container_edit'));
		$('#planPricingEditModal #plan_pricing_detail_container_edit').load('components/subscription/edit_plan_pricing.html', plan_pricing_id, function(){
			$('#selected_plan_pricing_id').val(plan_pricing_id);
			setTimeout(function(){
				getPlanPricingDetailById(plan_pricing_id);
			}, 500);
		});
   	});

	$(document).off("change", "#property_management_fee_type").on('change', '#property_management_fee_type', function (e) {
		var pmf_val = $(this).val();
		if(pmf_val == 'P') {
			$('.pmf_tag_line').html('of the monthly rent amount');
		} else {
			$('.pmf_tag_line').html('');
		}
	});	   

	$(document).off("click", ".edit_coupon_discount_details").on('click', '.edit_coupon_discount_details', function (e) {
		$('#coupon_details_container_add').html('');
		var selected_coupon_details_id = $(this).attr('data-id');
		$('#couponDetailsEditModal').modal('show');
		openLoadingDialog($('#coupon_details_container_edit'));
		$('#couponDetailsEditModal #coupon_details_container_edit').load('components/subscription/edit_coupon_details.html', selected_coupon_details_id, function(){
			$('#selected_coupon_details_id').val(selected_coupon_details_id);
			getCouponDetailsData('edit');
		});
	});

   	$(document).off('click', '.save_plan_pricing_data').on('click', '.save_plan_pricing_data', function() {
		var type = $(this).attr('data-type');
	    savePlanPricingData(type);
	});

	$(document).off('click', '.save_coupon_details_data').on('click', '.save_coupon_details_data', function() {
		var type = $(this).attr('data-type');
	    saveCouponDetailsData(type);
	});

	/* Permission Groups and Roles */

	$(document).off("click", ".add_new_group").on('click', '.add_new_group', function (e) {
   		$('#group_form_container_edit').html('');
   		$('#groupAddModal').modal('show');
		openLoadingDialog($('#group_form_container_add'));
		$('#groupAddModal #group_form_container_add').load('components/permission/add_group.html');
   	});

   	$(document).off("click", ".edit_group").on('click', '.edit_group', function (e) {
   		$('#group_form_container_add').html('');
   		var group_id = $(this).attr('data-id');
   		$('#groupEditModal').modal('show');
		openLoadingDialog($('#group_form_container_edit'));
		$('#groupEditModal #group_form_container_edit').load('components/permission/edit_group.html', group_id, function(){
			$('#selected_group_id').val(group_id);
			setTimeout(function(){
				getGroupDetailById(group_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_group_data').on('click', '.save_group_data', function() {
		var type = $(this).attr('data-type');
	    saveGroupData(type);
	});

	// Delete group data
	$(document).off('click', '.delete_group').on('click', '.delete_group', function(e) {
		e.stopPropagation();
	    var group_id = $(this).attr('data-id');
	    ConfirmDelete('Group', group_id);
	});

	/* Features code start */

	$(document).off("click", ".add_new_feature").on('click', '.add_new_feature', function (e) {
   		$('#feature_form_container_edit').html('');
   		$('#featureAddModal').modal('show');
		openLoadingDialog($('#feature_form_container_add'));
		$('#featureAddModal #feature_form_container_add').load('components/permission/add_feature.html');
   	});

   	$(document).off("click", ".edit_feature").on('click', '.edit_feature', function (e) {
   		$('#feature_form_container_add').html('');
   		var feature_id = $(this).attr('data-id');
   		$('#featureEditModal').modal('show');
		openLoadingDialog($('#feature_form_container_edit'));
		$('#featureEditModal #feature_form_container_edit').load('components/permission/edit_feature.html', feature_id, function(){
			$('#selected_feature_id').val(feature_id);
			setTimeout(function(){
				getFeatureDetailById(feature_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_feature_data').on('click', '.save_feature_data', function() {
		var type = $(this).attr('data-type');
	    saveFeatureData(type);
	});

	// Get Feature Item
	$(document).off("change", ".get_feature_item").on('change', '.get_feature_item', function (e) {
   		var feature_id = $('input[name="feature-radio"]:checked').attr('data-id');
   		if(feature_id > 0) {
   			loadFeatureItemData(feature_id);
   		}
   	});

   	$(document).off("click", ".add_new_feature_item").on('click', '.add_new_feature_item', function (e) {
   		$('#feature_item_form_container_edit').html('');
   		$('#featureItemAddModal').modal('show');
		openLoadingDialog($('#feature_item_form_container_add'));
		$('#featureItemAddModal #feature_item_form_container_add').load('components/permission/add_feature_item.html');
   	});

   	$(document).off("click", ".edit_feature_item").on('click', '.edit_feature_item', function (e) {
   		$('#feature_item_form_container_add').html('');
   		var feature_item_id = $(this).attr('data-id');
   		$('#featureItemEditModal').modal('show');
		openLoadingDialog($('#feature_item_form_container_edit'));
		$('#featureItemEditModal #feature_item_form_container_edit').load('components/permission/edit_feature_item.html', feature_item_id, function(){
			$('#selected_feature_item_id').val(feature_item_id);
			setTimeout(function(){
				getFeatureItemDetailById(feature_item_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_feature_item_data').on('click', '.save_feature_item_data', function() {
		var type = $(this).attr('data-type');
	    saveFeatureItemData(type);
	});

	/* Feature code end */

	$(document).off("click", ".add_new_service").on('click', '.add_new_service', function (e) {
   		$('#service_form_container_edit').html('');
   		$('#serviceAddModal').modal('show');
		openLoadingDialog($('#service_form_container_add'));
		$('#serviceAddModal #service_form_container_add').load('components/permission/add_service.html');
   	});

   	$(document).off("click", ".edit_service").on('click', '.edit_service', function (e) {
   		$('#service_form_container_add').html('');
   		var service_id = $(this).attr('data-id');
   		$('#serviceEditModal').modal('show');
		openLoadingDialog($('#service_form_container_edit'));
		$('#serviceEditModal #service_form_container_edit').load('components/permission/edit_service.html', service_id, function(){
			$('#selected_service_id').val(service_id);
			setTimeout(function(){
				getServiceDetailById(service_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_service_data').on('click', '.save_service_data', function() {
		var type = $(this).attr('data-type');
	    saveServiceData(type);
	});

	// Delete service data
	$(document).off('click', '.delete_service').on('click', '.delete_service', function(e) {
		e.stopPropagation();
	    var service_id = $(this).attr('data-id');
	    ConfirmDelete('Service', service_id);
	});

	// Get Service Member
	$(document).off("change", ".get_service_member").on('change', '.get_service_member', function (e) {
   		var service_id = $('input[name="service-radio"]:checked').attr('data-id');
   		if(service_id > 0) {
   			loadServiceMemberData(service_id);
   		}
   	});

	//get sub-categories-data
	$(document).off("change", ".get_sub_account_data").on('change', '.get_sub_account_data', function (e) {
		var user_id = user.user_details.user_id;
		var account_id = $('input[name="account-radio"]:checked').attr('data-id');
		loadSubAccountData(account_id, user_id);
	});

   	// Add new service member
   	$(document).off("click", ".add_new_service_member").on('click', '.add_new_service_member', function (e) {
   		$('#service_member_form_container_edit').html('');
   		$('#serviceMemberAddModal').modal('show');
		openLoadingDialog($('#service_member_form_container_add'));
		$('#serviceMemberAddModal #service_member_form_container_add').load('components/permission/add_service_member.html', function() {
			getUserList();
		});
   	});

   	// Save service member data
   	$(document).off('click', '.save_service_member_data').on('click', '.save_service_member_data', function() {
		saveServiceMemberData();
	});

   	// Delete user service data
	$(document).off('click', '.delete_user_service').on('click', '.delete_user_service', function(e) {
		e.stopPropagation();
	    var user_service_id = $(this).attr('data-id');
	    ConfirmDelete('ServiceMember', user_service_id);
	});

	// Save Permissions data
   	$(document).off('click', '.save_permission_data').on('click', '.save_permission_data', function() {
		var permissionArray = [];
		$('.permission_checkbox').each(function(key, value) {
			var service_name = $(this).html();
			var service_id = $(this).attr('data-service-id');
			var feature_id = $(this).attr('data-feature-id');
			var feature_item_id = $(this).attr('data-feature-item-id');
			if($(this).is(":checked")) {
				permissionArray.push({
					'service_id' : service_id,
					'feature_id' : feature_id,
					'feature_item_id' : feature_item_id,
				});
			}
		});

		//console.log(JSON.stringify(permissionArray));
		saveFeaturePermissionData(JSON.stringify(permissionArray));
	});
	/* End */

	$(document).off('click', '.close_transaction_detail_modal').on('click', '.close_transaction_detail_modal', function() {
		$(this).closest('div.popover').popover('hide');
	});

	// $(document).off('click', '.payment_type_tr').on('click', '.payment_type_tr', function() {
	// 	var cnt = $(this).attr('data-id');
	// 	$('.payment_type_child_tr_'+cnt).toggleClass("d-none");
	// });

	$(document).off('click', '.sender_receiver_detail').on('click', '.sender_receiver_detail', function() {
		var type = $(this).attr('data-type');
		var cnt = $(this).attr('data-id');

		if(type == 'sender') {
			$('#sender_detail_container_'+cnt).toggleClass("d-none");
		} else if(type == 'receiver') {
			$('#receiver_detail_container_'+cnt).toggleClass("d-none");
		}

	    if($(this).hasClass('toggle')) {
	        $(this).html('<i class="align-middle fas fa-fw fa-angle-down"></i>');
	        // $(container).html('');
	        $(this).removeClass('toggle');
	        return false;
	    }

	    $(this).toggleClass('toggle');
	    $(this).html('<i class="align-middle fas fa-fw fa-angle-up"></i>');
	});

	$(document).off('click', '.overview_detail').on('click', '.overview_detail', function() {
	    var container = '';
	    var type = $(this).attr('data-id');
	    if(type == "po_current_monthly_bill") {
	        container = '#current_monthly_bill_data_container';
	    } else if(type == "po_total_property") {
	        container = '#total_property_data_container';
	    } else if(type == "t_current_month_rent") {
	        container = '#current_month_rent_data_container';
	    } else if (type == "t_total_pending_amount") {
	        container = '#total_pending_amount_data_container';
	    } else if(type == "t_total_property") {
	        container = '#total_property_data_container';
	    }

	    if($(this).hasClass('toggle')) {
	        $(this).html('<i class="align-middle fas fa-fw fa-angle-down"></i>');
	        $(container).html('');
	        $(this).removeClass('toggle');
	        return false;
	    }

	    $(this).toggleClass('toggle');
	    $(this).html('<i class="align-middle fas fa-fw fa-angle-up"></i>');
	    openLoadingDialog($(container));
	    getMyAccountOverviewDetail(type, container);
	});

	/* Start : Dwolla func transfer */
	$(document).off("change", ".verified_user_dd").on('change', '.verified_user_dd', function (e) {
		var uid = $('.verified_user_dd option:selected').val();
		var dwolla_uid = $('.verified_user_dd option:selected').attr('data-dwolla-id');
		getBalanceAmount(uid, dwolla_uid);
		getFundingSource(uid, dwolla_uid, 'main');
	});

	$(document).off("click", ".dwolla_transfer_fund").on('click', '.dwolla_transfer_fund', function (e) {
   		$('#transfer_fund_container').html('');
   		$('#transferFundModal').modal('show');
		openLoadingDialog($('#transfer_fund_container'));
		var fund_type = $(this).attr('data-type');
		var uid = $('.verified_user_dd option:selected').val();
		var dwolla_uid = $('.verified_user_dd option:selected').attr('data-dwolla-id');
		if(fund_type == 'transfer_to_bank') {
			$('#transferFundModal #transfer_fund_container').load('components/dwolla/transfer_to_bank.html', function() {
				$('.balance_amount_container').html('<span class="bold-black">Available Balance Amount :</span> <span class="bold-grey green">'+$('#dwolla_balance h1').html()+'</span>');
				$('#transfer_to').attr('disabled',true);
				getFundingSource(uid, dwolla_uid, 'btn');
			});
		} else if(fund_type == 'add_to_balance') {
			$('#transferFundModal #transfer_fund_container').load('components/dwolla/add_to_balance.html', function() {
				$('.balance_amount_container').html('<span class="bold-black">Available Balance Amount :</span> <span class="bold-grey green">'+$('#dwolla_balance h1').html()+'</span>');
				$('#transfer_from').attr('disabled',true);
				getFundingSource(uid, dwolla_uid, 'btn');
			});
		}
   	});

   	$(document).off("click", ".dwolla_transfer_btn").on('click', '.dwolla_transfer_btn', function (e) {
   		$(this).attr('disabled', true);
   		var fund_type = $(this).attr('data-type');
		var uid = $('.verified_user_dd option:selected').val();
		var dwolla_uid = $('.verified_user_dd option:selected').attr('data-dwolla-id');
		if(fund_type == 'transfer_to_bank') {
			transferToBank(uid, dwolla_uid);
		} else if(fund_type == 'add_to_balance') {
			addToBalance(uid, dwolla_uid);
		}
   	});

   	/* End : Dwolla func transfer */

   	/* start : system email datas */

	$(document).off("click", ".email_preview").on('click', '.email_preview', function (e) {
   		var id = $(this).attr('data-id');
   		var type = $(this).attr('data-type');
   		if(type == 'sent') {
   			getSentEmailPreview(id);
   		} else if(type == 'main') {
   			getMainEmailPreview(id);
   		}
   	});

   	$(document).off("click", ".resend_email").on('click', '.resend_email', function (e) {
   		var id = $(this).attr('data-id');
   		resendEmail(id);
   	});

   	/* start : email templates */

   	$(document).off("click", ".add_email_template").on('click', '.add_email_template', function (e) {
   		$('#add_email_template_container').html('');
   		$('#addEmailTemplateModal').modal('show');
		openLoadingDialog($('#add_email_template_container'));
		$('#addEmailTemplateModal #add_email_template_container').load('components/email/add_email_template.html', function() {
			getEmailIdentifier();
		});
   	});

   	$(document).off("click", ".edit_email_template").on('click', '.edit_email_template', function (e) {
   		$('#edit_email_template_container').html('');
   		$('#editEmailTemplateModal').modal('show');
		openLoadingDialog($('#edit_email_template_container'));
		var email_template_id = $(this).attr('data-id');
		$('#editEmailTemplateModal #edit_email_template_container').load('components/email/edit_email_template.html', function() {
			getEmailTemplateDetail(email_template_id);
			$('#selected_email_template_id').val(email_template_id);
		});
   	});

   	$(document).off("click", ".save_email_template_data").on('click', '.save_email_template_data', function (e) {
		var type = $(this).attr('data-type');
		saveEmailTemplateData(type);
   	});

   	/* Search functionality start */

	$(document).off('click', '#search_pin_unpin').on('click', '#search_pin_unpin', function() {
		var type = $(this).attr('data-type');
		if(type == 'pin') {
			$(this).attr('data-type', 'unpin');
			$(this).attr('src', 'icon/unpin.png');
			localStorage.setItem('search', 'unpin');
		} else {
			$(this).attr('data-type', 'pin');
			$(this).attr('src', 'icon/pin.png');
			localStorage.setItem('search', 'pin');
		}
	});

	$(document).off('click', '.open_search_more_modal_box').on('click', '.open_search_more_modal_box', function() {
		var search_modal = $(this).attr('data-type');
		var search_data_container = $(this).attr('data-container');
		$('#'+search_modal).modal('show');
		openLoadingDialog($('#'+search_data_container));
		openSearchMoreModalBox(search_modal);
	});

	$(document).off('click', '.open_ps_filter_modal_box').on('click', '.open_ps_filter_modal_box', function() {
		var search_modal = $(this).attr('data-type');
		var search_data_container = $(this).attr('data-container');
		$('#'+search_modal).modal('show');
		openLoadingDialog($('#'+search_data_container));
		openPSFilterModalBox(search_modal);
	});

	$(document).off('mouseover', '.alphabet-li').on('mouseover', '.alphabet-li', function() {
		var char = $(this).attr('data-char');
		$("ul.ul-search-modal li:not(.container_"+char+")").addClass('add_opacity');
	});

	$(document).off('mouseout', '.alphabet-li').on('mouseout', '.alphabet-li', function() {
		$("ul.ul-search-modal li").removeClass('add_opacity');
	});

	// Search list
	$(document).off('keyup', '.modal_search_input').on('keyup', '.modal_search_input', function(){
	    var text = $(this).val().toLowerCase();
	    $('ul.ul-search-modal li').hide();
	    $('ul.ul-search-modal li').each(function(){
	        if($(this).find('span').text().toLowerCase().indexOf(""+text+"") != -1 ){
	            $(this).find('span').closest('ul.ul-search-modal li').show();

	            //console.log($(this).find('span').text().charAt(0).toUpperCase());
	            var firstChar = $(this).find('span').text().charAt(0).toUpperCase();
	            $('#main_li_'+firstChar).show();
	        }
	    });
	});

	$(document).off('change', '.search_date_macro').on('change', '.search_date_macro', function() {

		$('#search_lease_start_date > input').val('');
		$('#search_lease_end_date > input').val('');
		$('#search_lease_container ul li').removeClass('add_opacity');

		var macro = $(this).val();
		// 1 = Equal To, 2 = Greater Than, 3 = Less Than, 4 = Between
		if(macro == 1 || macro == 2 || macro == 3) {
			$('#search_lease_start_date').addClass('add_opacity');
			$('#search_lease_end_date').removeClass('add_opacity');
		} else if(macro == 4) {
			$('#search_lease_start_date, #search_lease_end_date').removeClass('add_opacity');
		} else {
			$('#search_lease_start_date, #search_lease_end_date').addClass('add_opacity');
		}
	});

	$(document).off('change', '#search_lease_start_date, #search_lease_end_date').on('change', '#search_lease_start_date, #search_lease_end_date', function() {
		$('#search_lease_container ul li').removeClass('add_opacity');
	    var macro = $('.search_date_macro').val();
	    var start_date = formatDateYmd($('#search_lease_start_date > input').val());
	    var end_date = formatDateYmd($('#search_lease_end_date > input').val());
	    start_date = new Date(start_date);
	    end_date = new Date(end_date);
	    $('#search_lease_container ul li').each(function(key, value) {
	        var s_date = formatDateYmd($(this).attr('data-start'));
	        var e_date = formatDateYmd($(this).attr('data-end'));
	        s_date = new Date(s_date);
	        e_date = new Date(e_date);
	        if(macro == 1) {
	            if(!(e_date.getTime() == end_date.getTime())) {
	                $(this).addClass('add_opacity');
	            }
	        } else if(macro == 2) {
	            if(!(e_date.getTime() > end_date.getTime())) {
	                $(this).addClass('add_opacity');
	            }
	        } else if(macro == 3) {
	            if(!(e_date.getTime() < end_date.getTime())) {
	                $(this).addClass('add_opacity');
	            }
	        } else if(macro == 4) {
	        	if(!(s_date.getTime() >= start_date.getTime() && e_date.getTime() <= end_date.getTime())) {
	            	$(this).addClass('add_opacity');
	            }
	        }
	    });
	});

	$(document).off('change', '.search_modal_checkbox').on('change', '.search_modal_checkbox', function(){
		var element;
		var field = $(this).attr('data-field');
		var type = $(this).attr('data-modal');
		var value = $(this).attr('data-id');
		if(type == 'searchPropertyModal') {
			element = 'property_search_checkbox';
	    } else if(type == 'searchTenantModal') {
	    	element = 'tenant_search_checkbox';
	    } else if(type == 'searchLeaseModal') {
			element = 'lease_search_checkbox';
	    } else if(type == 'searchTransactionModal') {
	    	element = 'transaction_search_checkbox';
	    } else if(type == 'searchUserNameModal') {
	    	element = 'user_name_search_checkbox';
	    } else if(type == 'searchUserEmailModal') {
	    	element = 'user_email_search_checkbox';
	    } else if(type == 'searchUserPhoneModal') {
	    	element = 'user_phone_search_checkbox';
	    } else if(type == 'searchUserStatusModal') {
	    	element = 'user_status_search_checkbox';
	    }

	    if($(this).is(":checked")) {
    		$( "."+element+"[value='"+value+"']").prop('checked', true);
    	} else {
    		$( "."+element+"[value='"+value+"']").prop('checked', false);
    	}
	});

	$(document).off('change', '.property_search_checkbox, .tenant_search_checkbox, .transaction_search_checkbox, .lease_search_checkbox, .user_name_search_checkbox, .user_email_search_checkbox, .user_phone_search_checkbox, .user_status_search_checkbox').on('change', '.property_search_checkbox, .tenant_search_checkbox, .transaction_search_checkbox, .lease_search_checkbox, .user_name_search_checkbox, .user_email_search_checkbox, .user_phone_search_checkbox, .user_status_search_checkbox', function() {

		var field = $(this).attr('data-field');

		var html = '';

		if(field == 'property') {

		    // Get Selected proeprty data
		    var propertyIDs = "";
			$('.property_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = propertyIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					propertyIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="property" data-field="property"></i><input type="hidden" name="selected_property_data" class="selected_property_data" value="'+value+'"></span>';
				}
			});
			propertyIDs = propertyIDs.slice(0, -1);

			// Get Selected tenant data
			var tenantIDs = "";
			$('.tenant_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = tenantIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					tenantIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="tenant" data-field="property"></i><input type="hidden" name="selected_tenant_data" class="selected_tenant_data" value="'+value+'"></span>';
				}
			});
			tenantIDs = tenantIDs.slice(0, -1);

			// Get Selected lease data
		    var leaseIDs = "";
			$('.lease_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = leaseIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					leaseIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="lease" data-field="property"></i><input type="hidden" name="selected_lease_data" class="selected_lease_data" value="'+value+'"></span>';
				}
			});
			leaseIDs = leaseIDs.slice(0, -1);

			// Get Selected transaction data
			var transactionIDs = "";
			$('.transaction_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = transactionIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
				   transactionIDs += value + ",";
			    	html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="transaction" data-field="property"></i><input type="hidden" name="selected_transaction_data" class="selected_transaction_data" value="'+value+'"></span>';
				}
			});
			transactionIDs = transactionIDs.slice(0, -1);

			/* Add selected filter in filter container */

			$('#filter_data_container').html(html);
			$('#filter_data_container').addClass('m-3');
			localStorage.setItem('search_fields', $('#filter_data_container').html());

		    var data = 'propertyIDs='+propertyIDs+'&tenantIDs='+tenantIDs+'&leaseIDs='+leaseIDs+'&transactionIDs='+transactionIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

	        var open_for = 'search';
	        $('#main_container').load('components/properties.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getPropertiesFilterData(data);
	        });

		} else if(field == 'user') {

			// Get Selected user name data
		    var userNameIDs = "";
			$('.user_name_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userNameIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userNameIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="username" data-field="user"></i><input type="hidden" name="selected_user_name_data" class="selected_user_name_data" value="'+value+'"></span>';
				}
			});
			userNameIDs = userNameIDs.slice(0, -1);

			// Get Selected user email data
		    var userEmailIDs = "";
			$('.user_email_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userEmailIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userEmailIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="useremail" data-field="user"></i><input type="hidden" name="selected_user_email_data" class="selected_user_email_data" value="'+value+'"></span>';
				}
			});
			userEmailIDs = userEmailIDs.slice(0, -1);

			// Get Selected user phone data
		    var userPhoneIDs = "";
			$('.user_phone_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userPhoneIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userPhoneIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="userphone" data-field="user"></i><input type="hidden" name="selected_user_phone_data" class="selected_user_phone_data" value="'+value+'"></span>';
				}
			});
			userPhoneIDs = userPhoneIDs.slice(0, -1);

			// Get Selected user status data
		    var userStatusIDs = "";
			$('.user_status_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userStatusIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userStatusIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="userstatus" data-field="user"></i><input type="hidden" name="selected_user_status_data" class="selected_user_status_data" value="'+value+'"></span>';
				}
			});
			userStatusIDs = userStatusIDs.slice(0, -1);

			/* Add selected filter in filter container */

			$('#filter_data_container').html(html);
			$('#filter_data_container').addClass('m-3');
			localStorage.setItem('search_fields', $('#filter_data_container').html());

		    var data = 'userNameIDs='+userNameIDs+'&userEmailIDs='+userEmailIDs+'&userPhoneIDs='+userPhoneIDs+'&userStatusIDs='+userStatusIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

	        var open_for = 'search';
	        $('#main_container').load('components/users.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getUsersFilterData(data);
	        });
		}
	});

	$(document.body).off('change', '.filter_property_name, .filter_tenant_name, .filter_lease_detail, .filter_transaction_status').on('change', '.filter_property_name, .filter_tenant_name, .filter_lease_detail, .filter_transaction_status', function (){
		loadVerticalProperty();
	});

	$(document).off('click', '.delete_selected_filter').on('click', '.delete_selected_filter', function(){
		var id = $(this).attr('data-id');
		var type = $(this).attr('data-type');
		var field = $(this).attr('data-field');

		if(type == 'property') {
			$('.property_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'tenant') {
			$('.tenant_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'lease') {
			$('.lease_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'transaction') {
			$('.transaction_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'username') {
			$('.user_name_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'useremail') {
			$('.user_email_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'userphone') {
			$('.user_phone_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'userstatus') {
			$('.user_status_search_checkbox[value="'+id+'"]').prop('checked', false);
		}

		// Remove clicked item and hidden input
		$(this).closest('span').remove();

		if(field == 'property') {
			// Get Selected proeprty data
		    var propertyIDs = "";
			$('.selected_property_data').each(function () {
				var value = $(this).val();
				propertyIDs += value + ",";
			});
			propertyIDs = propertyIDs.slice(0, -1);

			// Get Selected tenant data
			var tenantIDs = "";
			$('.selected_tenant_data').each(function () {
				var value = $(this).val();
				tenantIDs += value + ",";
			});
			tenantIDs = tenantIDs.slice(0, -1);

			// Get Selected lease data
		    var leaseIDs = "";
			$('.selected_lease_data').each(function () {
				var value = $(this).val();
				leaseIDs += value + ",";
			});
			leaseIDs = leaseIDs.slice(0, -1);

			// Get Selected transaction data
			var transactionIDs = "";
			$('.selected_transaction_data').each(function () {
				var value = $(this).val();
				transactionIDs += value + ",";
			});
			transactionIDs = transactionIDs.slice(0, -1);

			var data = 'propertyIDs='+propertyIDs+'&tenantIDs='+tenantIDs+'&leaseIDs='+leaseIDs+'&transactionIDs='+transactionIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

		    var open_for = 'search';
	        $('#main_container').load('components/properties.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getPropertiesFilterData(data);
	        });
	    } else if(field == 'user') {
	    	// Get Selected user name
		    var userNameIDs = "";
			$('.selected_user_name_data').each(function () {
				var value = $(this).val();
				userNameIDs += value + ",";
			});
			userNameIDs = userNameIDs.slice(0, -1);

			// Get Selected user email
			var userEmailIDs = "";
			$('.selected_user_email_data').each(function () {
				var value = $(this).val();
				userEmailIDs += value + ",";
			});
			userEmailIDs = userEmailIDs.slice(0, -1);

			// Get Selected user phone
		    var userPhoneIDs = "";
			$('.selected_user_phone_data').each(function () {
				var value = $(this).val();
				userPhoneIDs += value + ",";
			});
			userPhoneIDs = userPhoneIDs.slice(0, -1);

			// Get Selected user status
		    var userStatusIDs = "";
			$('.selected_user_status_data').each(function () {
				var value = $(this).val();
				userStatusIDs += value + ",";
			});
			userStatusIDs = userStatusIDs.slice(0, -1);

			var data = 'userNameIDs='+userNameIDs+'&userEmailIDs='+userEmailIDs+'&userPhoneIDs='+userPhoneIDs+'&userStatusIDs='+userStatusIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

		    var open_for = 'search';
	        $('#main_container').load('components/users.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getUsersFilterData(data);
	        });
	    }

        localStorage.setItem('search_fields', $('#filter_data_container').html());

        if($.trim($('#filter_data_container').html()) == '') {
        	$('#filter_data_container').removeClass('m-3');
        }

	});

	$(document).off('click', '.clear_search_property_data').on('click', '.clear_search_property_data', function() {
		$('#filter_data_container').html('');
		$('#filter_data_container').removeClass('m-3');
		$('.property_search_checkbox, .tenant_search_checkbox, .lease_search_checkbox, .transaction_search_checkbox, .user_name_search_checkbox, .user_email_search_checkbox, .user_phone_search_checkbox, .user_status_search_checkbox').prop('checked', false);

		var propertyIDs = '';
		var tenantIDs = '';
		var leaseIDs = '';
		var transactionIDs = '';
		var userNameIDs = '';
		var userEmailIDs = '';
		var userPhoneIDs = '';
		var userStatusIDs = '';

	    localStorage.removeItem("filter_data");
	    localStorage.removeItem("search");
	    localStorage.removeItem('search_fields');

	    var click_from = $(this).attr('data-click');
	    if(click_from = 'form') {
	    	var html = '';
	    	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">No Record Found</div></a>';
	    	$('#main_container').html(html);
	    }

	    $('#search_pin_unpin').attr('data-type', 'unpin');
		$('#search_pin_unpin').attr('src', 'icon/unpin.png');
		localStorage.setItem('search', 'unpin');
	});

	$(document).off('click', '.close_announcement').on('click', '.close_announcement', function() {
		$('#announcement-sticky-bar').addClass('d-none');
		$('#announcement-sticky-bar').html('');
		$('.wrapper').css('padding-top', '0px');
		$('.simplebar-content').css('padding-top', '0px');
		$('.sidebar-sticky .sidebar-content').css('top', 0);
		$('#assumed_user_email').css('top', '0.7%');
		localStorage.setItem("hide_announcement", 1);  //put the object back
	});

	// START #493 ==> SIGNUP ACCORDION WORKFLOW

	$(document).on('shown.bs.collapse', '#inquiry_accordion', function () {
		$('.inquiry_acc').addClass('border-top');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
		getInquiryDetail();
	});

	$(document).on('hidden.bs.collapse', '#inquiry_accordion', function () {
		$('.inquiry_acc').removeClass('border-top').removeClass('show');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
	});

	$(document).on('shown.bs.collapse', '#welcome_accordion', function () {
		$('.welcome_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
	});

	$(document).on('hidden.bs.collapse', '#welcome_accordion', function () {
		$('.welcome_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
	});

	$(document).on('shown.bs.collapse', '#explore_accordion', function () {
		$('.explore_acc').addClass('border-top');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
	});

	$(document).on('hidden.bs.collapse', '#explore_accordion', function () {
		$('.explore_acc').removeClass('border-top');
		var data = JSON.parse(localStorage.getItem('user'));
		var service_id = data.user_details.service_id;
		if (service_id != 0) {
			$('.explore_acc').removeClass('show');
			$('#headingExplore span.accordion_arrow').remove();
			setTimeout(function(){
				$('#explore_accordion').addClass('null_pointer');
			}, 300);
		} else {
			$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		}
	});

	$(document).on('shown.bs.collapse', '#code_accordion', function () {
		$('.code_acc').addClass('border-top');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
	});

	$(document).on('hidden.bs.collapse', '#code_accordion', function () {
		$('.code_acc').removeClass('border-top show');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
	});

	$(document).on('shown.bs.collapse', '#invite_code_accordion', function () {
		$('.invite_code_acc').addClass('border-top');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
	});

	$(document).on('hidden.bs.collapse', '#invite_code_accordion', function () {
		$('.invite_code_acc').removeClass('border-top show');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
	});

	$(document).on('shown.bs.collapse', '.scrollToTop', function(){
		scrollToTop($(this).attr('id'));
	});

	$(document).on('shown.bs.collapse', '#tenant_kyc_detail_accordion', function () {
		// $('.tenant_kyc_detail_media').removeClass('minHeight100');
		$('#explore_accordion').trigger('hidden.bs.collapse');
		$('#invite_code_accordion').trigger('hidden.bs.collapse');
		$('.tenant_kyc_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
		openLoadingDialog($('.tenant_kyc_detail_media'));

		$('.tenant_kyc_detail_media').load('components/bank/add_unverified_customer.html', function(){
		});
	});

	$(document).on('hidden.bs.collapse', '#tenant_kyc_detail_accordion', function () {
		$('.tenant_kyc_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.tenant_kyc_detail_media').html('');
		if($('#kyc_flag').val() == 1){
			$('#tenant_kyc_detail_accordion').addClass('null_pointer');
		}
	});

	$(document).on('shown.bs.collapse', '#kyc_detail_accordion', function () {
		scrollToTop($(this).attr('id'));
		$('#invite_code_accordion').trigger('hidden.bs.collapse');
		$('#explore_accordion').trigger('hidden.bs.collapse');
		$('#code_accordion').trigger('hidden.bs.collapse');
		$('.kyc_detail_media').removeClass('minHeight100');
		$('.kyc_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
		openLoadingDialog($('.kyc_detail_media'));
		setTimeout(function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
				method: 'POST',
				url: api_url+"dwolla-documents",
				data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response) {
					var data = JSON.parse(localStorage.getItem('user'));
					var dwolla_customer_status = data.user_details.dwolla_customer_status;
					if(response.ButtonEnable == 0 && dwolla_customer_status == 4){
						var message_html = '<h4 class="text-center mt-6 green">Your document has been successfully uploaded and is now pending verification from Dwolla. We will notify you once the verification process is complete. Thank you for your patience.</h4>';
						$('.kyc_detail_media').html(message_html);
						$('.kyc_detail_media').addClass('minHeight100');
					} else {
						$('.kyc_detail_media').load('components/bank/add_bank.html', function(){
							$('.kyc_detail_media .card-header').remove();
							$('#formAccordion .card').removeClass('border');
							$('#formAccordion .card').removeClass('card');
							$('#kyc-detail-wizard').removeClass('d-none');
							$('.submit_kyc_detail').text('Submit');
							$('.submit_kyc_detail').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
							getStateDropdown();

							if(dwolla_customer_status == 2){
								$('#ssn').mask('000-00-0000');
								$('#ssn_note_4').addClass('d-none');
								$('#ssn_note_9').removeClass('d-none');
								$('#kyc-detail-wizard p').html("Our initial identity verification was unsuccessful. Please verify your details and, if necessary, provide your full 9-digit Social Security Number for a second attempt. Note that a subsequent failure may change your status to 'Document' or 'Suspended', at which point our Rent Synergy Support Team will assist you further in coordination with Dwolla.").addClass('red');
							}
							if(dwolla_customer_status == 4){
								$('#addBankCollapse').remove();
								$('#kyc_document_form').removeClass('d-none');
							}
							if(dwolla_customer_status == 5){
								$('.kyc_detail_media').html('<h4 class="text-center mt-4 grey">Your account has been temporarily suspended. We are in contact with our payment processor, Dwolla, to understand the details. Rest assured, we will notify you promptly if any additional information is required and will keep you updated on the status of your account.</h4>');
								$('.kyc_detail_media').addClass('minHeight100');
							}
						});
					}
				}
			});
		}, 1000);
	});

	$(document).on('hidden.bs.collapse', '#kyc_detail_accordion', function () {
		$('.kyc_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.kyc_detail_media').html('');
		if($('#kyc_flag').val() == 1){
			$('#kyc_detail_accordion').addClass('null_pointer');
		}
	});

	$(document).on('shown.bs.collapse', '#bank_details_accordion', function (e) {
		$('#invite_code_accordion').trigger('hidden.bs.collapse');
		$('#explore_accordion').trigger('hidden.bs.collapse');
		$('#code_accordion').trigger('hidden.bs.collapse');
    	scrollToTop($(this).attr('id'));

		if($('#kyc_flag').val() == 1){
			$('#kyc_detail_accordion').addClass('null_pointer');
		}
		$('.payment_detail_media').removeClass('minHeight100');
		$('.bank_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');

		var html = '';
		html += '<button type="button" id="link-account" class="btn btn-orange mr-3 d-none">Instant verification (Recommended)</button>';
		$('.payment_detail_media').html(html);
		$('#link-account').trigger('click');
	});

	$(document).on('hidden.bs.collapse', '#bank_details_accordion', function () {
		$('.bank_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.payment_detail_media').html('');
		if($('#bank_flag').val() == 1){
			$('#bank_details_accordion').addClass('null_pointer');
		}
	});

	$(document).off('click', '#link-account').on('click', '#link-account', function() {
		var first_time_signup = $('#first_time_signup').val();
		var container = '';
		if(first_time_signup == 1){
			container = '.payment_detail_media';
		} else {
			$('#paymentAddModal').modal('show');
			container = '#payment_form_container';
		}
		openLoadingDialog($(container));
		setTimeout(function(){
			$(container).load('components/bank/instant_verification.html', function(){
				if(first_time_signup == 1){
					$('<button type="button" class="btn btn-orange mr-2 skip_payment">Skip</button>').insertBefore('.open_plaid_modal');
					$('.card-header').remove();
					$('.card').removeClass('border');
				}
			});
		}, 1000);
	});

	$(document).off('click', '.open_plaid_modal').on('click', '.open_plaid_modal', function() {
		$(this).attr('disabled', true);
		$(this).text('Processing...');
		$('.skip_payment').attr('disabled', true);
		createLinkToken();
	});

	$(document).off('click', '#manually-link-account').on('click', '#manually-link-account', function() {
		var first_time_signup = $('#first_time_signup').val();
		var container = '';
		if(first_time_signup == 1){
			container = '.payment_detail_media';
		} else {
			$('#paymentAddModal').modal('show');
			container = '#payment_form_container';
		}
		openLoadingDialog($(container));
		setTimeout(function(){
			$(container).load('components/bank/add_bank.html', function(){
				$('.payment_detail_media .card-header').remove();
				if(first_time_signup == 1){
					$('.card-header').remove();
					$('#formAccordion .card').removeClass('border');
					$('#formAccordion .card').removeClass('card');
				} else {
					$('.card-actions').removeClass('d-none');
				}
				$('#payment-detail-wizard').removeClass('d-none');
				//$('#payment-detail-wizard h1').html(html);
				$('.skip_payment').addClass('d-none');
				$('.save_payment_detail').text('Agree');
				/*$('<button type="button" class="btn btn-primary mr-2 payment_detail_back_button">Back</button>').insertBefore('.save_payment_detail');*/
				$('.save_payment_detail').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
				getStateDropdown();
			});
		}, 1000);
	});

	$(document).on('shown.bs.collapse', '#property_detail_accordion', function () {
		$('#explore_accordion').trigger('hidden.bs.collapse');
		$('#code_accordion').trigger('hidden.bs.collapse');
		scrollToTop($(this).attr('id'));
		if($('#bank_flag').val() == 1){
			$('#bank_details_accordion').addClass('null_pointer');
		}
		$('.property_detail_media').removeClass('minHeight100');
		$('.prop_acc').addClass('border-bottom');
		getPropertyMedia();
	});

	$(document).on('hidden.bs.collapse', '#property_detail_accordion', function () {
		$('.prop_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.property_detail_media').html('');
	});

	$(document).on('shown.bs.collapse', '#tenant_detail_accordion', function () {
		$('#explore_accordion').trigger('hidden.bs.collapse');
		$('#code_accordion').trigger('hidden.bs.collapse');
		scrollToTop($(this).attr('id'));
		$('.tenant_detail_media').removeClass('minHeight100');
		$('.tenant_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
		openLoadingDialog($('.tenant_detail_media'));
		setTimeout(function(){
			var tz = jstz.determine();
			var timezone = tz.name();
			var data = JSON.parse(localStorage.getItem('user'));
			var property_id = data.user_details.first_property_id;
			var data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;
			$.ajax({
				method: 'POST',
				url: api_url+"tenants",
				data:data,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response){
					if(response.response_data.success == true){
						if(response.TotalTenants != 0){
							var list_html = '';
							list_html += '<div class="mt-2">';
								// list_html += '<div class="text-right"><button type="button" class="btn btn-orange mb-2 signup_add_tenant">Add Tenant</button></div>';
								// list_html += '<div class="text-right"><i class="fas fa-fw fa-plus orange fs-18 mb-2 signup_add_tenant"></i></div>';
								list_html += '<div class="text-right mb-1"><a href="javascript:void(0);" class="signup_add_tenant">Add Tenant</a></div>';
								list_html += '<table class="col-xl-12 table card-table table-striped">';
								for(var key in response.tenants){
									list_html += '<tr>';
										list_html += '<td>' + response.tenants[key].user.first_name + ' ' + response.tenants[key].user.last_name + '</td>';
										// list_html += '<td>' + response.tenants[key].user.last_name + '</td>';
										list_html += '<td>' + response.tenants[key].user.email + '</td>';
										list_html += '<td class="text-right">';
											if(response.tenants[key].user.cognito_sub == null){
												list_html += '<a href="javascript:void(0);" class="signup_edit_tenant mr-2" data-id="'+response.tenants[key].id+'"><i class="align-middle fas fa-fw fa-edit"></i></a>';
											}
											if(response.TotalTenants != 1){
												list_html += '<a href="javascript:void(0);" class="signup_delete_tenant" data-id="'+response.tenants[key].id+'"><i class="align-middle tenant-trash fas fa-fw fa-trash"></i></a>';
											}
										list_html += '</td>';
									list_html += '</tr>';
								}
								list_html += '</table>';
							list_html += '</div>';
							$('.tenant_detail_media').html(list_html);
						} else{
							$('.tenant_detail_media').load('components/property/add_new_tenant.html', function(){
								$('#primary_tenant_enabled').parent('label').addClass('add_opacity');
								$('#allowed_to_autorize_and_pay_rent').parent('label').addClass('add_opacity');
								$('#allowed_to_sign_contract').parent('label').addClass('add_opacity');
								$('#allowed_to_sign_in').parent('label').addClass('add_opacity');
								$('<button data-button="add-tenant" type="button" class="btn btn-orange mr-2 signup_skip_button">Skip</button>').insertBefore('.save_and_submit_tenant_data');
								$('.tenant_detail_media .card-header').remove();
								$('.tenant_detail_media .card').eq(0).removeClass('card border');
								$('.tenant_detail_media .card-actions').remove();
								$('.save_tenant_data').remove();
								$('.save_and_submit_tenant_data').removeClass('d-none');
								$('.save_and_submit_tenant_data').text('Save');
								$('.save_and_submit_tenant_data').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
							});
						}
					}
				}
			});
		}, 1000);
	});

	$(document).on('shown.bs.collapse', '#zoho_lease_accordion', function () {
		openLoadingDialog($('.zoho_lease_media'));
		$('#doc_signing_flag').val(0);
		$('#invite_code_accordion').trigger('hidden.bs.collapse');
		$('#explore_accordion').trigger('hidden.bs.collapse');
		scrollToTop($(this).attr('id'));
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
		$('.zoho_lease_acc').addClass('border-bottom');

		getZohoSignUrl();
		var intervalId = setInterval(getZohoSignUrl, 15000);
		$('#interval_id').val(intervalId);
	});

	$(document).on('hidden.bs.collapse', '#zoho_lease_accordion', function () {
		$('.zoho_lease_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.zoho_lease_media').html('');
		$('#doc_signing_flag').val(1);
		clearInterval($('#interval_id').val());
	});

	$(document).off('click', '.signup_edit_tenant').on('click', '.signup_edit_tenant', function(){
		var tenant_id = $(this).attr("data-id");
		openLoadingDialog($('.tenant_detail_media'));
		$('.tenant_detail_media').load('components/property/edit_tenant.html', function(){
			$('.tenant_detail_media .card-header').remove();
			$('.tenant_detail_media .card').eq(0).removeClass('card border');
			$('.tenant_detail_media .card-actions').remove();
			$('.update_tenant_data').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
			$('<button type="button" class="btn btn-orange mr-2 edit_tenant_back_button">Back</button>').insertBefore($('.update_tenant_data'));
			getTenantDetailById(tenant_id);
		})
	});

	$(document).off('click', '.signup_add_tenant').on('click', '.signup_add_tenant', function(){
		openLoadingDialog($('.tenant_detail_media'));
		setTimeout(function(){
			$('.tenant_detail_media').load('components/property/add_new_tenant.html', function(){
				$('#primary_tenant_enabled').prop('checked', false);
				$('#allowed_to_autorize_and_pay_rent').prop('checked', false);
				$('.tenant_detail_media .card-header').remove();
				$('.tenant_detail_media .card').eq(0).removeClass('card border');
				$('.tenant_detail_media .card-actions').remove();
				$('.save_tenant_data').remove();
				$('.save_and_submit_tenant_data').removeClass('d-none');
				$('.save_and_submit_tenant_data').text('Save');
				$('.save_and_submit_tenant_data').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
				$('<button type="button" class="btn btn-orange mr-2 edit_tenant_back_button">Back</button>').insertBefore($('.save_and_submit_tenant_data'));
			}, 1000);
		});
	});

	$(document).off('click', '.signup_delete_tenant').on('click', '.signup_delete_tenant', function(){
		var tenant_id = $(this).attr("data-id");
		ConfirmDelete('Tenant',tenant_id);
	});

	$(document).off('click', '.edit_tenant_back_button').on('click', '.edit_tenant_back_button', function(){
		$('#tenant_detail_accordion').trigger('shown.bs.collapse');
	});

	$(document).on('hidden.bs.collapse', '#tenant_detail_accordion', function () {
		$('.tenant_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.tenant_detail_media').html('');
		if($('#tenant_flag').val() == 1 && $('#lease_flag').val() == 1){
			$('#property_detail_accordion,#tenant_detail_accordion,#lease_detail_accordion').addClass('null_pointer');
		}
	});

	$(document).on('shown.bs.collapse', '#lease_detail_accordion', function () {
		scrollToTop($(this).attr('id'));
		$('#explore_accordion').trigger('hidden.bs.collapse');
		$('#code_accordion').trigger('hidden.bs.collapse');
		$('.lease_detail_media').removeClass('minHeight100');
		$('.lease_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
		openLoadingDialog($('.lease_detail_media'));
		setTimeout(function(){
			$('.lease_detail_media').load('components/property/add_new_lease.html', function(){
				$('<button data-button="add-lease" type="button" class="btn btn-orange mr-2 signup_skip_button">Skip</button>').insertBefore('.save_lease_data');
				$('.lease_detail_media .card-header').remove();
				$('.lease_detail_media .card').eq(0).removeClass('card border');
				$('.lease_detail_media .card-actions').remove();
				$('.save_lease_data').text('Save');
				$('.save_lease_data').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
				$('#lease_add_form .card-body').css('max-height', '100%');
			});

			var tz = jstz.determine();
			var timezone = tz.name();
			var data = JSON.parse(localStorage.getItem('user'));
			var property_id = data.user_details.first_property_id;
			var user_id = user.user_details.user_id;
			var data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;
			$.ajax({
				method: 'POST',
				url: api_url+"leases",
				data:data,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response){
					if(response.response_data.success == true){
						if(response.TotalLeases == 1){
							$('.lease_detail_media').load('components/property/edit_lease.html', function(){
								$('.lease_detail_media .card-header').remove();
								$('.lease_detail_media .card').eq(0).removeClass('card border');
								$('.lease_detail_media .card-actions').remove();
								$('.update_lease_data').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
								$('#lease_edit_form .card-body').css('max-height', '100%');
								getLeaseDetailById('edit', response.leases[0].id);
							});
						}
					}
				}
			});

		}, 1000);
	});
	$(document).on('hidden.bs.collapse', '#lease_detail_accordion', function () {
		$('.lease_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.lease_detail_media').html('');
		if($('#tenant_flag').val() == 1 && $('#lease_flag').val() == 1){
			$('#property_detail_accordion,#tenant_detail_accordion,#lease_detail_accordion').addClass('null_pointer');
		}
	});

	$(document).on('shown.bs.collapse', '#link_payment_accordion', function () {
		$('.link_acc').addClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');

		openLoadingDialog($('.link_payment_media'));
		setTimeout(function(){
			var property_id = user.user_details.first_property_id
			var user_id = user.user_details.user_id;
			var property_name = user.user_details.first_property_name;
			getBankListForProperty(user_id, property_id, property_name);
		}, 1000);
	});
	$(document).on('hidden.bs.collapse', '#link_payment_accordion', function () {
		$('.link_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.link_payment_media').html('');
	});

	$(document).on('shown.bs.collapse', '#sign_lease_accordion', function () {
		$('.sign_lease_acc').addClass('border-bottom');
		openLoadingDialog($('.sign_lease_media'));
		setTimeout(function(){
			getZohoSignUrl(user_id);
		}, 1000);
	});
	$(document).on('hidden.bs.collapse', '#sign_lease_accordion', function () {
		$('.sign_lease_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.sign_lease_media').html('');
	});

	$(document).on('shown.bs.collapse', '.payment_report_accordion', function () {
		var mno = $(this).attr('data-id');
		$('#transactionMonthHeading'+mno).addClass('border-bottom-left-radius0 border-bottom-right-radius0');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
	});

	$(document).on('hidden.bs.collapse', '.payment_report_accordion', function () {
		var mno = $(this).attr('data-id');
		$('#transactionMonthHeading'+mno).removeClass('border-bottom-left-radius0 border-bottom-right-radius0');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
	});

	$(document.body).off('click', '.expense_collapse_transaction_month_accordion').on('click', '.expense_collapse_transaction_month_accordion', function (){
		var type = $(this).attr('data-type');
		if(type == 'collapse') {
			$(this).html('Expand All');
			$(this).attr('data-type','expand');
			$('.payment_report_accordion').each(function(key, value) {
				var accordion_id = $(this).attr('data-id');
				$('#transactionMonthCollapse'+accordion_id).removeClass('show');
				$('#transactionMonthHeading'+accordion_id).removeClass('border-bottom-left-radius0 border-bottom-right-radius0');
				$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
			});
		} else {
			$(this).html('Collapse All');
			$(this).attr('data-type','collapse');
			$('.payment_report_accordion').each(function(key, value) {
				var accordion_id = $(this).attr('data-id');
				$('#transactionMonthCollapse'+accordion_id).addClass('show');
				$('#transactionMonthHeading'+accordion_id).addClass('border-bottom-left-radius0 border-bottom-right-radius0');
				$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
			});
		}
	});

	// New Payment Workflow : Start

	$(document.body).off('click', '.create_payment_schedule').on('click', '.create_payment_schedule', function (){
		// if($psPaymentForm.valid()){
			$(this).text('Processing...');
			$(this).attr('disabled', true);
			$(this).addClass('add_opacity');
			var lease_id = $('#ps_lease_id').val();
			var paymentSettingArray = [];
			// $('.ps_payment_type_id').each(function(key, value) {
			// 	var payment_type_id = $(this).val();
			// 	var autopay = 1;
			// 	if(!$('#ps_autopay_'+payment_type_id).is(":checked")) {
			// 		autopay = 0;
			// 	}
			// 	paymentSettingArray[payment_type_id] = autopay;
			// });
			createPaymentScheduleEntry(lease_id, paymentSettingArray);
		// }
	});

	$(document.body).off('change', '.ps_first_payment_route').on('change', '.ps_first_payment_route', function (){
		var dd_count = Number($(this).attr('data-dd-count'));
		var payment_type_id = $(this).attr('data-payment-type-id');
		var payment_route_id = $(this).val();
		var allPaymentRoute = [];
		$('.ps_payment_route_'+payment_type_id).each(function(key, value) {
			allPaymentRoute.push($(this).val());
		});

		getPaymentRouteDetail(payment_route_id, payment_type_id, dd_count, allPaymentRoute);
	});

	$(document.body).off('click', '.add_payment_route').on('click', '.add_payment_route', function (){
		$(this).addClass('add_opacity');
		var dd_count = Number($(this).attr('data-dd-count'));
		var payment_type_id = $(this).attr('data-payment-type-id');
		var payment_route_id = $('#ps_payment_route_'+payment_type_id+'_dd_'+dd_count).val();
		var allPaymentRoute = [];
		$('.ps_payment_route_'+payment_type_id).each(function(key, value) {
			allPaymentRoute.push($(this).val());
		});

		addAnotherPaymentRoute(payment_route_id, payment_type_id, dd_count, allPaymentRoute);
	});

	$(document.body).off('click', '.remove_payment_route').on('click', '.remove_payment_route', function (){

		if($(this).attr('data-ps-route-id')){
			if($('#removed_payment_route_id').val()){
				var removed_payment_routes = $('#removed_payment_route_id').val()+','+$(this).attr('data-ps-route-id');
			} else {
				var removed_payment_routes = $(this).attr('data-ps-route-id');
			}
			$('#removed_payment_route_id').val(removed_payment_routes);
		}

		var dd_count = Number($(this).attr('data-dd-count'));
		var payment_type_id = $(this).attr('data-payment-type-id');
		// $('#ps_payment_route_'+payment_type_id+'_dd_'+dd_count).parent('div').parent('div').remove();
		// $(this).parent('div').remove();
		$('#ps_payment_route_'+payment_type_id+'_dd_'+dd_count).closest('.ps_route_container').remove();

		dd_count = Number(dd_count) - 1;		
		$('#add_payment_route_'+payment_type_id).attr('data-dd-count', dd_count);
		$('#add_payment_route_'+payment_type_id).removeClass('add_opacity');
		$('#ps_payment_route_'+payment_type_id+'_dd_'+dd_count).parent('div').removeClass('add_opacity_7');
	});

	$(document.body).off('change', '.ps_get_payment_details').on('change', '.ps_get_payment_details', function (){
		var user_id = $(this).val();
		var id = $(this).attr('data-target-id');
		var payment_detail_id = $(this).attr('data-ps-detail-id');
		getPSAllBanks(user_id, id, payment_detail_id);
	});

	$(document.body).off('click', '.get_payment_options').on('click', '.get_payment_options', function (){
		var lease_id = $(this).attr('data-id');
		openRightSidebar('50%');
		openLoadingDialog($('#sidebar_content_area'));
		setTimeout(function(){
			$('#sidebar_heading').html('Payment Schedule');
			getPaymentOptions(lease_id);
		}, 500);		
	});

	$(document.body).off('click', '.remove_payment_schedules').on('click', '.remove_payment_schedules', function (){
		var lease_id = $(this).attr('data-id');
		ConfirmDelete('Payment Schedules', lease_id);
	});

	$(document.body).off('click', '.ps_all_filters').on('click', '.ps_all_filters', function (){
		var lease_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		setTimeout(function(){
			$('#sidebar_heading').html('Payment Schedule Filters');
			$('#sidebar_content_area').load('components/reports/payment_schedules_filters.html', function() {

	        });
		}, 500);
	});

	$(document.body).off('change', '.ps_apply_filters').on('change', '.ps_apply_filters', function (){
		if($('#selected_property_id').val()){
			getPropertiesPaymentScheduleWidgetInfo();
		} else {
			getMyAccountPaymentScheduleWidget();
		}
	});

	$(document.body).off('change', '.ps_filter_po_name').on('change', '.ps_filter_po_name', function (){
		getPaymentScheduledFilterData();
	});

	$(document.body).off('change', '.ps_filter_property_name').on('change', '.ps_filter_property_name', function (){
		var property_id = $(this).val();
		$('#ps_filter_lease_id').html('');
		$('#ps_filter_po_users').html('');
		if(property_id != 0){
			if(user_service_name != t_role){
				$('.ps_filter_for_property').removeClass('d-none');
			}
			$.ajax({
				url: api_url+"leases",
				type: 'POST',
				data: 'property_id='+property_id,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response) {
					if(response.response_data.success == true) {
						var lease_html = '';
						// if(Object.keys(response.leases).length > 1){
							lease_html += '<option value="0">All Lease</option>';
						// }

						for(var lkey in response.leases){
							lease_html += '<option value="'+response.leases[lkey].id+'">'+response.leases[lkey].start_date+' - '+response.leases[lkey].end_date+'</option>';
						}
						$('#ps_filter_lease_id').html(lease_html);
						$(".ps_filter_lease_id").each(function() {
							$(this)
								.wrap("<div class=\"position-relative\"></div>")
								.select2({
								placeholder: "All Lease",
								//dropdownParent: $(this).parent()
								});
						})

						getPSUsersListSeach();
						if(!$('#selected_property_id').val()){
							getMyAccountPaymentScheduleWidget();
						}
					} else {
						display_error_message(response);
					}
				}
			});
		} else {
			$('.ps_filter_for_property').addClass('d-none');
			getMyAccountPaymentScheduleWidget();
		}
	});

	$(document.body).off('change', '.ps_filter_lease_id').on('change', '.ps_filter_lease_id', function (){
		getPSUsersListSeach();
	});

	$(document).off('click', '.associate_payment_details').on('click', '.associate_payment_details', function() {

		var openFrom = $(this).attr('data-type');
		if(openFrom == 'separate') {
			var data_id = $(this).attr('data-id');
			$('div[class*="payment_schedule_tr_"]').removeClass('bg-lightgrey');
			$('.payment_schedule_tr_'+data_id).addClass('bg-lightgrey');
		} else {
			$('.associate_payment_details').closest('tr').removeClass('bg-lightgrey');
			$(this).closest('tr').addClass('bg-lightgrey');
		}

		var payment_schedule_id = $(this).attr('data-id');
		var type = $(this).attr('data-type');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		if(type == 'combine_data') {
			var total_amount = $(this).attr('data-amount');
			var payment_type = $(this).attr('data-payment-type');
			setTimeout(function(){
				$('#sidebar_heading').html('Detail');
				loadAssociatePayments(payment_schedule_id, type, total_amount);
			}, 500);
		} else {
			setTimeout(function(){
				$('#sidebar_heading').html('Activity');
				loadAssociatePayments(payment_schedule_id, type, 0);
			}, 500);
		}
	});

	$(document).off('click', '.regenerate_payment_schedule').on('click', '.regenerate_payment_schedule', function() {
		var payment_schedule_id = $(this).attr('data-id');
		var payment_type = $(this).attr('data-payment-type');
	    ConfirmRegenerate('Payment Schedule', payment_schedule_id, payment_type);
	});

	$(document).off('click', '.authorize_payment_schedule').on('click', '.authorize_payment_schedule', function() {
		var payment_schedule_id = $(this).attr('data-id');
		var from = $(this).attr('data-from');
		var title = $(this).attr('data-title');
		// $('#authorizePaymentModal').modal('show');
		// openLoadingDialog($('#ps_rent_collection_container'));

		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		if(from == 'notification'){
			$('.back_button_right_sidebar').removeClass('d-none').addClass('open_notification_section');
		} else {
			$('.back_button_right_sidebar').addClass('d-none').removeClass('open_notification_section');
		}
		setTimeout(function(){
			$('#sidebar_heading').html(title);
			getPaymentScheduleDetails(payment_schedule_id, from);
		}, 500);
	});

	$(document).off('click', '.get_partial_payment_data').on('click', '.get_partial_payment_data', function() {
		var payment_schedule_id = $(this).attr('data-id');
		// $('#getPartialPaymentModal').modal('show');
		// openLoadingDialog($('#get_partial_payment_data_container'));

		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));

		setTimeout(function(){
			$('#sidebar_heading').html('Partial Payment');
			getPartialPaymentData(payment_schedule_id);
		}, 500);
	});

	$(document).off('click', '.save_ps_partial_payment_data').on('click', '.save_ps_partial_payment_data', function() {
		if($validatePartialTransaction.valid()){
			savePartialPaymentData();
		}
	});

	$(document).off('click', '.ps_save_rent_collection_data').on('click', '.ps_save_rent_collection_data', function(){
		if($rentCollectionForm.valid()){
			$('.ps_save_rent_collection_data').attr('disabled', true);
			var payment_schedule_id = Number($('#payment_schedule_id').val());
			ConfirmPayment('ps_rent',payment_schedule_id);
		} else {
			$('.ps_save_rent_collection_data').attr('disabled', false);
		}
	});

	$(document).off('click', '#ps_payment_authorize_checkbox').on('click', '#ps_payment_authorize_checkbox', function(){
		if(!$('#ps_payment_authorize_checkbox').is(":checked")) {
			$('.ps_save_rent_collection_data').attr('disabled', true);
		} else {
			$('.ps_save_rent_collection_data').attr('disabled', false);
		}
	});

	$(document).off('click', '.update_auto_pay').on('click', '.update_auto_pay', function(e) {
		$(this).attr('disabled', true);
		$(this).parent('label').addClass('add_opacity');
		var payment_schedule_id = $(this).attr('data-id');
		updateAutoPay(payment_schedule_id);
	});


	$(document).off('click', '.save_ps_payment_details').on('click', '.save_ps_payment_details', function() {
		$(this).attr('disabled', true);
		if($validatePSPaymentDetails.valid()){
			savePSPaymentDetails();
		}
	});

	$(document).off('click', '.save_ps_payment_route').on('click', '.save_ps_payment_route', function() {
		// $(this).attr('disabled', true);
		ConfirmPaymentRoute('Change Payment Route');
	});

	$(document).off('change', '#main_ps_month_checkbox').on('change', '#main_ps_month_checkbox', function() {
		if(this.checked){
			$('input[name="ps_months[]"]').not('.do_not_change').prop('checked', true);
		} else {
			$('input[name="ps_months[]"]').not('.do_not_change').prop('checked', false);
		}
	});

	// $(document).off('click', '.change_ps_payment_details').on('click', '.change_ps_payment_details', function(){
	// 	var ids = [];
	// 	$('input[name="ps_list[]"]:checked').each(function() {
	// 		ids.push($(this).val());
	// 	});
	// 	ids = ids.toString();

	// 	var len = $('input[name="ps_list[]"]:checked').length;
	// 	if(len > 0){
	// 		getPSPaymentDetails(ids);
	// 	}
	// });

	$(document).off('click', '.change_ps_payment_details').on('click', '.change_ps_payment_details', function(){
		var payment_schedule_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Change Payment Details');
		getPSPaymentDetails(payment_schedule_id);
	});

	$(document).off('click', '.change_ps_payment_route').on('click', '.change_ps_payment_route', function(){
		var payment_schedule_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Change Payment Route');
		getPSPaymentRoute(payment_schedule_id);
	});

	$(document).off('click', '.get_related_waived_fees_data_sidebar').on('click', '.get_related_waived_fees_data_sidebar', function() {
		var open_from = $(this).attr('data-open-from');
		var payment_fee_details_id = $(this).attr('data-id');
		var total_fee = $(this).attr('data-total-amount');
		var fee_type = $(this).attr('data-fee-type');
		var fee_type_id = $(this).attr('data-fee-type-id');
		var remaining_fee = $(this).attr('data-remaining-amount');

		if($(this).hasClass('opened')) {
			$("#fee_child_"+payment_fee_details_id).removeClass('minHeight100');
			$('#fee_child_'+payment_fee_details_id).html('');
			$(this).removeClass('opened');
			$('#fee_child_'+payment_fee_details_id).removeClass('pb-4');
			$(this).removeClass('fa-times').addClass('fa-info-circle');
		} else {
			$(this).addClass('opened');
			$(this).addClass('fa-times').removeClass('fa-info-circle');
			$("#fee_child_"+payment_fee_details_id).addClass('minHeight100');
			openLoadingDialog($('#fee_child_'+payment_fee_details_id));
			setTimeout(function(){
				var data = getWaivedLateFeeDetails(payment_fee_details_id, total_fee, remaining_fee, fee_type_id);
				$('#fee_child_'+payment_fee_details_id).html(data);
				$('#fee_child_'+payment_fee_details_id).addClass('pb-4');
			}, 500);
		}
	});

	$(document).off('keyup', '.payment_schedule_applied_fees').on('keyup', '.payment_schedule_applied_fees', function() {
		if(Number($(this).val()) > Number($(this).attr('data-max-amount'))){
			$(this).val('');
			var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Should be less than respected fee amount: $'+$(this).attr('data-max-amount')
                }
            };
	        display_error_message(response);
			// $('#ps_total_waive_fee_amount').val(0.00);
			// $('.ps_total_waive_fee_amount').html('<strong>$0.00</strong>');

			// $('#ps_total_after_waive_fee').val(0.00);
			// $('.ps_total_after_waive_fee').html('<strong>$0.00</strong>');
	        // return false;
		}

		var total = 0;
		$('.payment_schedule_applied_fees').each(function(){
			total += Number($(this).val());
		});

		var payment_schedule_total = Number($('#ps_total_waive_fee_amount').attr('data-total'));
		payment_schedule_total = payment_schedule_total - total;

		$('#ps_total_waive_fee_amount').val(total);
		$('.ps_total_waive_fee_amount').html('<strong>$'+currencyFormat(Number(total))+'</strong>');

		$('#ps_total_after_waive_fee').val(payment_schedule_total);
		$('.ps_total_after_waive_fee').html('<strong>$'+currencyFormat(Number(payment_schedule_total))+'</strong>');
	});

	$(document).off('click', '.close_waive_fee_container').on('click', '.close_waive_fee_container', function(e){
		e.stopPropagation();
		$('.waive_payment_schedule_late_fee').removeClass('add_opacity');
		$('#waive_fee_data_container').addClass('d-none');
		$('#waive_fee_data_container').html('');
		$('.save_ps_as_paid').attr('disabled', false);
	});

	// Open waive late fee modal
	$(document).off('click', '.waive_payment_schedule_late_fee').on('click', '.waive_payment_schedule_late_fee', function(e){
		// e.stopPropagation();
		var payment_schedule_id = $(this).attr('data-id');
		var open_from = $(this).attr('data-open-from');
		if(open_from == 'mark_as_paid'){
			$('#waive_fee_data_container').removeClass('d-none');
			$(this).addClass('add_opacity');
			$('.save_ps_as_paid').attr('disabled', true);
			openLoadingDialog($('#waive_fee_data_container'));
		} else {
			openRightSidebar();
			openLoadingDialog($('#sidebar_content_area'));
		}
		setTimeout(function(){
			getPaymentFeeDetails(payment_schedule_id, open_from);
		}, 500);
	});

	// Open waive late fee modal
	$(document).off('click', '.mark_ps_as_paid').on('click', '.mark_ps_as_paid', function(e){
		// e.stopPropagation();
		var payment_schedule_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Mark As Paid');
		setTimeout(function(){
			getPSDetails(payment_schedule_id);
		}, 500);
	});

	$(document).off('click', '.mark_ps_as_deleted').on('click', '.mark_ps_as_deleted', function(e){
		// e.stopPropagation();
		var payment_schedule_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Mark As Deleted');
		setTimeout(function(){
			getPSDetails(payment_schedule_id, 'mark_as_deleted');
		}, 500);
	});

	$(document).off('click', '.undo_delete_ps').on('click', '.undo_delete_ps', function(e){
		// e.stopPropagation();
		var payment_schedule_id = $(this).attr('data-id');
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		$('#sidebar_heading').html('Undo Delete');
		setTimeout(function(){
			getPSDetailsForUndoDelete(payment_schedule_id);
		}, 500);
	});

	$(document).off('click', '.save_undo_delete_ps').on('click', '.save_undo_delete_ps', function(e){
		if($validateMarkAsPaid.valid()){
			var payment_schedule_id = $('#payment_schedule_id').val();
			undoDeletePS(payment_schedule_id);
		}
	});

	// Mark payment schedule as paid
	$(document).off('click', '.save_ps_as_paid').on('click', '.save_ps_as_paid', function(e){
		// e.stopPropagation();
		if($validateMarkAsPaid.valid()){
			var payment_schedule_id = $('#payment_schedule_id').val();
			savePSAsPaid(payment_schedule_id);
		}
	});

	// Mark payment schedule as deleted
	$(document).off('click', '.save_ps_as_deleted').on('click', '.save_ps_as_deleted', function(e){
		// e.stopPropagation();
		if($validateMarkAsPaid.valid()){
			var payment_schedule_id = $('#payment_schedule_id').val();
			savePSAsPaid(payment_schedule_id, 'mark_as_deleted');
		}
	});

	$(document).off('click', '.waive_ps_late_fee').on('click', '.waive_ps_late_fee', function(){
		var total = $('#ps_total_after_waive_fee').val();
		var open_from = $(this).attr('data-open-from');

		if(total <= 0){
			var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Please enter amount'
                }
            };
	        display_error_message(response);
	        return false;
		} else {
			$(this).attr('disabled', true);
			savePSWaivedLateFeeData(open_from);
		}
	});

	$(document).off('click', '.close_waived_late_fee_details_modal').on('click', '.close_waived_late_fee_details_modal', function() {
		$(this).closest('div.popover').popover('hide');
	});

	// New Payment Workflow : End

	$(document).on('shown.bs.collapse', '#first_month_auth_accordion', function () {
		$('.fmp_acc').addClass('border-bottom');
		openLoadingDialog($('.first_month_auth_media'));
		setTimeout(function(){
			var first_property_id = 0;
			var lease_id = 0;
			getFMPaymentDetail(user_id,first_property_id,lease_id);
		}, 1000);
	});
	$(document).on('hidden.bs.collapse', '#first_month_auth_accordion', function () {
		$('.fmp_acc').removeClass('border-bottom');
		$(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
		$('.first_month_auth_media').html('');
	});

	$(document).off('change', '.user_role_radio').on('change', '.user_role_radio', function(){
		var user_role = $("input:radio[name='user_role_radio']:checked").attr('data-role');
		var service_id = Number($('.user_role_radio:checked').val());
		updateServiceRole(user_id, service_id);
		getServicePage(service_id, 'radio');
	});

	$(document).off('click', '#nav_help_icon').on('click', '#nav_help_icon', function(){
		openRightSidebar();
		openLoadingDialog($('#sidebar_content_area'));
		setTimeout(function(){
			$('#sidebar_heading').html('Help Desk');
	        $('#sidebar_content_area').load('components/helpdesk.html', function() {
	        	loadOnBoardTicket();
	        });
	    }, 500);
	});

	$(document).off('click', '.save_ticket_comment').on('click', '.save_ticket_comment', function(){
		if($.trim(comment_editor.value()) == "") {
	        var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Comment should not be blank.'
                }
            };
	        display_error_message(response);
	        return false;
	    }
	    $(this).attr('disabled', true);
		$(this).text('Processing...')
		var module_id = $(this).attr('ticket-id');
		var module_name = 'tickets';
		var comment = comment_editor.value();
    	var formatted_markdown_comment = parse_markdown_to_html(comment_editor.value());
		var data = 'user_service_name='+user_service_name+'&module_id='+module_id+'&module_name='+module_name+'&user_id='+user_id+'&comment='+encodeURIComponent(comment)+'&formatted_markdown_comment='+encodeURIComponent(formatted_markdown_comment);
		$.ajax({
			method: 'POST',
			url: api_url+"comments/add",
			data: data,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				display_error_message(response);
				$('.save_ticket_comment').attr('disabled', false);
				$('.save_ticket_comment').text('Save');
				if(response.response_data.success == true) {
					openLoadingDialog($('#sidebar_content_area'));
					setTimeout(function(){
						$('#sidebar_content_area').load('components/helpdesk.html', function() {
				        	loadOnBoardTicket();
				        });
				    }, 500);
				}
			}
		});
	});

	// END #493 ==> SIGNUP ACCORDION WORKFLOW

	var announcementStr = '';
	$("#announcement_text").each(function(){
        announcementStr = $(this).text();
        if($.trim(announcementStr).length > announcementStrWord){
            var newStr = announcementStr.substring(0, announcementStrWord);
            var removedStr = announcementStr.substring(announcementStrWord, $.trim(announcementStr).length);
            $(this).empty().html(newStr);
            //$(this).append(' <a href="javascript:void(0);" class="read-more black">read more...</a>');
        }
    });

    $(".read-more").click(function(){
    	var announcement_id = $(this).attr('data-id');
        $('#readMore').modal('show');
        openLoadingDialog($('#readMore .modal-body'));
        getAnnouncementDetail(announcement_id);
    });

	$('body').on('click', function (e) {
	    $('[data-toggle=popover]').each(function () {
	        // hide any open popovers when the anywhere else in the body is clicked
	        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
	            $(this).popover('hide');
	        }
	    });
	});

	$(document).off('keyup', '.max_char_count').on('keyup', '.max_char_count', function(){
		var characterCount = $(this).val().length,
			current = $('#current'),
			maximum = $('#maximum'),
			theCount = $('#the-count');

		current.text(characterCount);

		if (characterCount >= 400) {
		  maximum.css('color', '#8f0001');
		  current.css('color', '#8f0001');
		  theCount.css('font-weight','bold');
		} else {
		  maximum.css('color','#666');
		  theCount.css('font-weight','normal');
		}
	});

	$(document).off('click', '.copy_button').on('click', '.copy_button', function(){
		var id = $(this).attr('data-id');
		var type = $(this).attr('data-type');
		var textToCopy = '';
		if(type == 'balance') {
			textToCopy = $('.balance_'+id).text();
		} else if(type == 'bank') {
			textToCopy = $('.bank_'+id).text();
		}
        var tempTextarea = $('<textarea>');
        $('body').append(tempTextarea);
        tempTextarea.val(textToCopy).select();
        document.execCommand('copy');
        tempTextarea.remove();

		Snackbar.show({
			text: 'Copied : '+textToCopy,
			textColor: '#FFFFFF',
			backgroundColor: 'green',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	});

	$(document).off('click', '.get_bank_balance').on('click', '.get_bank_balance', function() {
        $(this).attr('disabled', true);
        $(this).text('Processing...');
		var fs_id = $(this).attr('data-fs-id');
		getBankBalance(fs_id);
    });
});

// #94: START
//this function is used to get new access token when old access token is about to expire
window.attemptRefresh = function() {
	var user = JSON.parse(localStorage.getItem('user'));
	if(user){
		var current_time = Math.floor(new Date().getTime() / 1000);
		var expiry_time = user.authentication.expire_in;
		if((expiry_time - current_time) < attempt_refresh_time){ //{(access_token expiry time) - (current time)) < 5 minutes (300 seconds)}
			$.ajax({
		        method: 'POST',
		        url: api_url+'auth/attempt-refresh',
		        data: 'refresh_token='+user.authentication.RefreshToken,
			    success: function(response) {
	            	if(response.response_data.success == false) {
			    		display_error_message(response);
			    	} else {
						user.authentication.AccessToken = response.authentication.AccessToken;
						user.authentication.expire_in = response.authentication.expire_in;
			    		localStorage.setItem('user', JSON.stringify(user));
						accessToken = response.authentication.AccessToken
			    	}
		        }
		    });
		}
	} else{
		clearInterval(access_interval);
	}
}

// this function is used to calculate idle time
window.idleTimerIncrement = function() {
	var user = JSON.parse(localStorage.getItem('user'));
	if(user){
		idleTime++;
		if(idleTime > session_idle_timer){ //if idle time is greater than var 'session idle timer' minutes show the 'session about to expire' modal box
			$('#SessionExpireModal').modal('show');
			let count = 15; //15 seconds countdown when modal boxes opens to choose from 'log out' or 'stay logged in' else logs out if countdown expires.
			countdown_timer = setInterval(function() {
				count--;
				$('#session_timeout_message').html(count)
				if (count === 0) {
					clearInterval(countdown_timer);
					var user = JSON.parse(localStorage.getItem('user'));
					var accessToken = user.authentication.AccessToken;
					var user_id = user.user_details.user_id;
					$.ajax({
						method: 'POST',
						url: api_url+"auth/signout",
						data:"user_id="+user_id,
						xhrFields: {
							withCredentials: true
						},
						beforeSend: function(xhr) {
							xhr.setRequestHeader('Authorization', accessToken);
						},
						success: function(response) {
							display_error_message(response);
							localStorage.clear();
							if(response.response_data.success == false) {
								if(response.redirect_step == 'login') {
									window.location.href = site_url;
								}
							} else {
								window.location.href = site_url;
							}
						}
					});
				}
			}, 1000);
		}
	}
}
// #94: END

function reset_form() {
	$('#login-form').trigger("reset");
	$('#passcode-form').trigger("reset");
	$('#create-account-wizard').trigger("reset");
}

window.arrangeSeqNo = function() {
	var i=1;
	$('.recipients_data').each(function(){
		$(this).find("div.sequenceNo").html(i);
		$(this).find("input.sequenceNo").val(i);
		$(this).attr("id", 'recipients_'+i);

		$(this).find("input.action_input").attr('id', 'action_id'+i);
		$(this).find("input.sequenceNo").attr('id', 'seq_no'+i);

		$(this).find("select.role").attr('id', 'role'+i);
		//$(this).find("input.recipient_email").attr('id', 'recipient_email'+i);
		//$(this).find("input.recipient_name").attr('id', 'recipient_name'+i);

		$(this).find('a.addRowButton').attr("data-row-id", i);
		$(this).find('a.removeRowButton').attr("data-row-id", i);

		i++;
	});
}

window.getStateDropdownForSignup = function(selected_state = '') {
	$.ajax({
        method: 'GET',
        url: api_url+"states",
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>State</option>";
                for (var key in response.states) {
	                if (response.states.hasOwnProperty(key)) {
	                	var selected = '';
	                	if(response.states[key].state_code == selected_state) { selected = 'selected'; }
				  		html += '<option value="'+response.states[key].state_code+'" '+selected+'>'+response.states[key].state_name+'</option>';
				  	}
				}
				$('#state').html(html);
				$('#property_state').html(html);
				$('#user_state').html(html);
				$('#inputState').html(html);
				$('#vendorState').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getStateDropdown = function() {
	$.ajax({
        method: 'GET',
        url: api_url+"states/state2",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>State</option>";
                for (var key in response.states) {
	                if (response.states.hasOwnProperty(key)) {
				  		html += '<option value="'+response.states[key].state_code+'">'+response.states[key].state_name+'</option>';
				  	}
				}
				$('#state').html(html);
				$('#property_state').html(html);
				//$('#add_property_state').html(html);
				//$('#edit_property_state').html(html);
				$('#user_state').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.completeRegistration = function() {
	var email = $('#email_address').val();
	var usersub = $('#usersub').val();

	var business_name = $('#business_name').val();
	var address_line1 = $('#address_line1').val();
	var address_line2 = $('#address_line2').val();
	var city = $('#city').val();
	var state = $('#state').val();
	var zip_code = $('#zip_code').val();
	var country = $('#country').val();

	var property_id = $('#property_id').val();
	var property_user_id = $('#property_user_id').val();
	var tenant_user_id = $('#tenant_user_id').val();
	var service_id = $('#create_account_service_input').val();
	var service_constant = $('#service_constant').val();

	// When tenants directly signup from our UI
	if(service_id == 2 && tenant_user_id == '') {
		var tenant_user_id = 0;
		var first_name = $('#property_owner_firstname').val();
		var last_name = $('#property_owner_lastname').val();
		var property_email = $('#property_owner_email_address').val();

		var address_line1 = $('#property_address_line1').val();
		var address_line2 = $('#property_address_line2').val();
		var city = $('#property_city').val();
		var state = $('#property_state').val();
		var zip_code = $('#property_zip_code').val();
		var country = $('#property_country').val();
	}

	var data = 'usersub='+usersub+'&email='+email+'&business_name='+business_name+'&address_line1='+address_line1+'&address_line2='+address_line2+'&city='+city+'&state='+state+'&zip_code='+zip_code+'&country='+country+'&service_id='+service_id+'&service_constant='+service_constant+'&property_user_id='+property_user_id+'&tenant_user_id='+tenant_user_id+'&property_id='+property_id+'&first_name='+first_name+'&last_name='+last_name+'&property_email='+property_email+'&wizard_name=signup&seq_no=5';

	$.ajax({
        method: 'POST',
        url: api_url+"auth/user-business",
        data: data
    }).then(function(response) {
    	display_error_message(response);
    	if(response.response_data.success == false) {
    		$('.complete_registration').attr('disabled', false);
    	} else {
    		// Checkilist step 5
    		getSignupNextStep(5,6);
    		window.location.href = site_url+'signin.html';
    	}
    }, function(response) {
        // hang on this step if the error occur
    });
    return false;
}

window.verify_usps_address = function(type,address1,address2,city,state,zipcode) {
	var data = 'address1='+address1+'&address2='+address2+'&city='+city+'&state='+state+'&zipcode='+zipcode;
	if(type == 'kyc') {
		$('.submit_kyc_detail').attr('disabled', true);
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
            success: function(response) {
            	if(response.response_data.message != 'VERIFIED') {
            		$('.submit_kyc_detail').attr('disabled', false);
            		response.response_data.success = false;
	            	display_error_message(response);
	            }

	            if(response.response_data.success == true && response.response_data.message == 'VERIFIED') {
	            	$('#user_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#user_address_line2').val(response.data.Address1);
			   		}
			   		$('#user_city').val(response.data.City);
			   		$('#user_state').val(response.data.State);
			   		$('#user_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		//if(typeof response.data.ReturnText === 'undefined' && response.response_data.message == 'VERIFIED') {
			   		if(response.response_data.message == 'VERIFIED') {
			   			submitKycDetail();
			   		}
	            }
	        }
	    });
	} else if(type == 'update_dwolla_customer') {
		$('.update_dwolla_customer').attr('disabled', true);
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
            success: function(response) {
            	if(response.response_data.message != 'VERIFIED') {
            		$('.update_dwolla_customer').attr('disabled', false);
            		response.response_data.success = false;
	            	display_error_message(response);
	            }

	            if(response.response_data.success == true && response.response_data.message == 'VERIFIED') {
	            	$('#customer_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#customer_address_line2').val(response.data.Address1);
			   		}
			   		$('#customer_city').val(response.data.City);
			   		$('#customer_state').val(response.data.State);
			   		$('#customer_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		if(response.response_data.message == 'VERIFIED') {
			   			updateDwollaCustomer();
			   		}
	            }
	        }
	    });
	} else if(type == 'property_add') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
					$('.save_property_data').attr('disabled', false);
					$('.save_property_data').text('Add Property');
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {

	                $('#add_property_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#add_property_address_line2').val(response.data.Address1);
			   		}
			   		$('#add_property_city').val(response.data.City);
			   		$('#add_property_state').val(response.data.State);
			   		$('#add_property_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			if(response.data.State == 'TX') {
			   				savePropertyData();
			   			} else {
			   				var response = {
		                        "response_data": {
		                            "success": false,
		                            "message": 'Address should be belongs to Texas state only'
		                        }
		                    };
		                    display_error_message(response);
							$('.save_property_data').attr('disabled', false);
							$('.save_property_data').text('Add Property');
			   			}
			   		}
	            }
	        }
	    });
	} else if(type == 'property_edit') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
					$('.update_property_address').attr('disabled', false);
					$('.update_property_address').text('Update');
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {

	                $('#edit_property_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#edit_property_address_line2').val(response.data.Address1);
			   		}
			   		$('#edit_property_city').val(response.data.City);
			   		$('#edit_property_state').val(response.data.State);
			   		$('#edit_property_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			if(response.data.State == 'TX') {
			   				updatePropertyAddress();
			   			} else {
			   				var response = {
		                        "response_data": {
		                            "success": false,
		                            "message": 'Address should be belongs to Texas state only'
		                        }
		                    };
		                    display_error_message(response);
							$('.update_property_address').attr('disabled', false);
							$('.update_property_address').text('Update');
			   			}
			   		}
	            }
	        }
	    });
	} else if(type == 'property_primary_contact') {
		var property_id = $('#selected_property_id').val();
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {

	                $('#primary_contact_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#primary_contact_address_line2').val(response.data.Address1);
			   		}
			   		$('#primary_contact_city').val(response.data.City);
			   		$('#primary_contact_state').val(response.data.State);
			   		$('#primary_contact_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			savePropertyPrimaryContactDetail(property_id);
			   		}
	            }
	        }
	    });
	} else if(type == 'edit_address') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {

	                $('#address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#address_line2').val(response.data.Address1);
			   		}
			   		$('#city').val(response.data.City);
			   		$('#state').val(response.data.State);
			   		$('#zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			if(response.data.State == 'TX') {
			   				updateAddress();
			   			} else {
			   				var response = {
		                        "response_data": {
		                            "success": false,
		                            "message": 'Address should be belongs to Texas state only'
		                        }
		                    };
		                    display_error_message(response);
			   			}
			   		}
	            }
	        }
	    });
	}
}

window.initDatatable = function(tableId) {
	var column = [];
	if(tableId == 'ticket-datatable') {
		column = [
            { className: 'all', targets: [0], width:'5%', orderable: true},
            { className: 'all', targets: [1], width:'20%', orderable: true},
            { targets: [2], width:'15%', orderable: true},
            { targets: [3], width:'15%', orderable: true},
            { targets: [4], width:'15%', orderable: true},
            { className: 'text-right', targets: [5], width:'15%', orderable: true},
            { targets: [6], width:'10%', orderable: false},
            { targets: [7], width:'10%', orderable: false}
        ];
	} else if(tableId == 'lease-datatable') {
		column = [
            { className: 'all', targets: [0], orderable: true},
            { className: 'all', targets: [1], orderable: true},
            { className: 'text-right', targets: [2], orderable: true},
            { className: 'text-right', targets: [3], orderable: true},
            { targets: [4], orderable: true},
            { targets: [5], orderable: true},
            { targets: [6], orderable: true},
            { targets: [7], orderable: false},
            { targets: [8], orderable: false}
        ];
	} else if(tableId == 'existing-tenant-datatable') {
		column = [
            { className: 'all', targets: [0], width:'5%', orderable: false},
            { className: 'all', targets: [1], width:'20%', orderable: true},
            { className: 'all', targets: [2], width:'20%', orderable: true},
            { targets: [3], width:'45%', orderable: true},
            { targets: [4], width:'10%', orderable: true},
        ];
	}

	var table = $("#"+tableId).DataTable({
	    responsive: true,
	    info: false,
	    lengthChange: false,
	    searching: false,
	    columnDefs: column,
	    order: []
	});
	return table;
}

window.drag_drop_reorder = function(tableId) {
	// Drag & Drop ordering update
    $('.'+tableId).sortable({
        cursor: 'move',
        update: function (event, ui) {
            var data = $(this).sortable('serialize');
            // POST to server using $.post or $.ajax
            $.ajax({
            	url: api_url+"user-templates/order",
                data: data,
                type: 'POST',
                dataType: 'json',
                beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
                success: function(response) {
                    display_error_message(response);
			        if(response.response_data.success == true) {
			            document_datatable.clear().draw();
			            var user_id = $('#selected_user_id').val();
			            getUserZohoDocumentList(user_id);
			        }
                }
            });
        }
    });
}

window.getServices = function(service_id, type) {
	$.ajax({
        method: 'GET',
	    url: api_url+"services",
        success: function(response) {
        	if(response.response_data.success == false) {
	            display_api_error(response);
	        } else {
	            var html = '';
	            if(type == 'contract') {
            		html += '<select name="contract_service_id"  id="contract_service_id" class="form-control">';
            	} else if(type == 'agreement') {
            		html += '<select name="agreement_service_id"  id="agreement_service_id" class="form-control">';
            	}
	            	for (var key in response.services) {
	                	if (response.services.hasOwnProperty(key)) {
	                		if(response.services[key].constant_name == '1dc82947-e650-494a-b798-986a6f701d1f' || response.services[key].constant_name == 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
	                			var selected = '';
			                	if(service_id == response.services[key].id) {
			                		selected = "selected";
			                	}
		                		html += '<option value="'+response.services[key].id+'" '+selected+'>'+response.services[key].service_name+'</option>';
		                	}
						}
					}
				html += '</select>';
				$('#document_type_container').html(html);
	        }
        }
    });
}

window.getUserIpAddress = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"users/get-ip-address",
        success: function(response) {
        	//console.log(response);
        	localStorage.setItem('ip_address', response.source_ip);
        	window.ip_address = localStorage.getItem('ip_address');
        }
    });
}

window.loadChecklistData = function(name,user_type) {
	$.ajax({
        method: 'POST',
        url: api_url+"checklists",
        data:'name='+name+'&user_type='+user_type,
        success: function(response) {
        	if(response.response_data.success == false) {
	            display_api_error(response);
	        } else {
	        	$('#signup_total_steps').html(response.totalChecklists);
	            var html = "";
	            var pb_class = 'pb-4';
	            html += '<ul class="timeline mt-4 mb-4 ml-3">';
	            for (var key in response.checklists) {
	                if (response.checklists.hasOwnProperty(key)) {
	                	var cnt = parseInt(key)+1;
	                	if(response.totalChecklists == cnt) {
	                		pb_class = '';
	                	}
	                	html += '<li id="signup_checkilist_step_'+response.checklists[key].seq_no+'" class="timeline-item '+pb_class+'">';
	                		html += '<strong>'+response.checklists[key].steps+'</strong>';
	                	html += '</li>';
	                }
	            }
	            html += '</ul>';
	            $('#signup-checklist-steps').html(html);
	            $('#signup_step_name').html($('#signup_checkilist_step_1').html());
	            $('#signup_checkilist_step_1').addClass('orange');

	            // Completed checklist steps
	            if(localStorage.getItem("user") !== null) {
		            if(user_service_name == t_role && user.user_details.cheklist_seq_no == 3) {
		            	var checklist_completed_seq = user.user_details.cheklist_seq_no + 1;
		            } else {
		            	var checklist_completed_seq = user.user_details.cheklist_seq_no;
		            }

		            var i;
					for (i = 1; i <= checklist_completed_seq; i++) {
					  	$('head').append('<style>#signup_checkilist_step_'+i+':before{background:green !important;}</style>');
					  	$('#signup_checkilist_step_'+i).removeClass('orange');
					}
					var current_step = checklist_completed_seq + 1;
					$('#signup_step_name').html($('#signup_checkilist_step_'+current_step).html());
					$('#signup_completed_steps').html(parseInt($('#signup_completed_steps').html()) + checklist_completed_seq);

					// Add orange class to current step
					var current_step = checklist_completed_seq + 1;
				    $('#signup_checkilist_step_'+current_step).addClass('orange');

				    // Reset green and orange color
				    if(name == 'rs_invite_tenant') {
				    	var i;
						for (i = 1; i <= response.totalChecklists; i++) {
						  	$('head').append('<style>#signup_checkilist_step_'+i+':before{background:white !important;}</style>');
						  	$('#signup_checkilist_step_'+i).removeClass('orange');
						}
				    }
				}
	        }
        }
    });
}

window.loadCompletedChecklistSteps = function(user_id, user_type){
	$.ajax({
        method: 'POST',
        url: api_url+"user-checklists/get-checklist-completed-steps",
        data:'user_id='+user_id+'&user_type='+user_type,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			if(response.response_data.success == true){
				var cirlcle_down_icon = '<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>';
				var verified_icon = '<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />';
				var edit_icon = '<i class="align-middle mr-2 fas fa-fw fa-edit"></i>';
				if(user_type == po_role){
					$('#kyc_detail_accordion').removeClass('add_opacity_lock');
					$('#headingOne span.accordion_arrow').html(cirlcle_down_icon);
					$('#link_payment_accordion').addClass('add_opacity_lock');
					$('#lease_detail_accordion').addClass('add_opacity_lock');
					$('#tenant_detail_accordion').addClass('add_opacity_lock');
					$('#property_detail_accordion').addClass('add_opacity_lock');
					// $('#series_llc_accodion').addClass('add_opacity_lock');
					$('#bank_details_accordion').addClass('add_opacity_lock');
					if(response.response_data.error_code != 0){
						$('#headingOne span.accordion_arrow').html(cirlcle_down_icon);
						var status = '';
						var data = JSON.parse(localStorage.getItem('user'));
						var dwolla_customer_status = data.user_details.dwolla_customer_status;
						if(dwolla_customer_status != 0 && dwolla_customer_status != 1){
							switch(dwolla_customer_status){
								case 2:
									status = 'Retry';
									break;
								case 3:
									status = 'KBA';
									break;
								case 4:
									status = 'Document';
									break;
								case 5:
									status = 'Suspended';
									break;
							}
							// $('#headingOne .card-title').append('<span class="badge badge-danger fs-12 mr-2 ml-2">'+status+'</span>');
							$('#headingOne .card-title span').html(status);
						}
					} else {
						var completed_steps = response.user_checklist_data.steps_completed.split(',');
						if(completed_steps.includes('1')){
							$('#bank_details_accordion').removeClass('add_opacity_lock');
							$('#headingTwo span.accordion_arrow').html(cirlcle_down_icon);
							$('#kyc_detail_accordion').trigger('hidden.bs.collapse');
							$('#headingOne span.accordion_verfiy').html(verified_icon);
							$('#headingOne span.accordion_arrow').remove();
							$('#kyc_detail_accordion').addClass('null_pointer');
							$('.kyc_status_message').addClass('d-none');

							if(completed_steps.includes('2')){
								$('#property_detail_accordion').removeClass('add_opacity_lock');
								$('#headingThree span.accordion_arrow').html(cirlcle_down_icon);
								$('#bank_details_accordion').trigger('hidden.bs.collapse');
								//$('#headingTwo span.accordion_verfiy').html(verified_icon);
								$('#bank_details_accordion').addClass('null_pointer');
								$('#headingTwo span.accordion_arrow').remove();

								$('#headingTwo span.accordion_verfiy').html(verified_icon);

								if(completed_steps.includes('3')){
									$('#property_detail_accordion').trigger('hidden.bs.collapse');
									$('#headingThree span.accordion_verfiy').html(verified_icon);
									$('#headingThree span.accordion_arrow').remove();
									$('#headingThree span.accordion_edit').html(edit_icon);
									$('#tenant_detail_accordion').removeClass('add_opacity_lock');
									$('#headingFour span.accordion_arrow').html(cirlcle_down_icon);
									$('#lease_detail_accordion').removeClass('add_opacity_lock');
									$('#headingFive span.accordion_arrow').html(cirlcle_down_icon);

									if(completed_steps.includes('4')){
										$('#tenant_detail_accordion').trigger('hidden.bs.collapse');
										$('#headingFour span.accordion_verfiy').html(verified_icon);
										$('#headingFour span.accordion_arrow').remove();
										$('#headingFour span.accordion_edit').html(edit_icon);
										$('#tenant_flag').val(1);
									}

									if(completed_steps.includes('5')){
										$('#lease_detail_accordion').trigger('hidden.bs.collapse');
										$('#headingFive span.accordion_verfiy').html(verified_icon);
										$('#headingFive span.accordion_arrow').remove();
										$('#headingFive span.accordion_edit').html(edit_icon);
										$('#lease_flag').val(1);
									}

									if(completed_steps.includes('4') && completed_steps.includes('5')){
										$('#headingThree span.accordion_edit,#headingFour span.accordion_edit,#headingFive span.accordion_edit').remove();
										$('#property_detail_accordion,#tenant_detail_accordion,#lease_detail_accordion').addClass('null_pointer');

										if(signature_enabled == 'zoho_template'){
											$('#contracts_accordion').removeClass('d-none');
										} else {
											var data = JSON.parse(localStorage.getItem('user'));
											var property_id = data.user_details.first_property_id;
											if(signature_enabled == 'docusign'){
												getContracts(property_id);
											} else if(signature_enabled == 'zoho_document'){
												getZohoDocuments(property_id);
											}
										}

										if(completed_steps.includes('6')){
											$('#main_container').load('components/onboarding_completed.html');
											var user = JSON.parse(localStorage.user);
											user.user_details.steps_left = 0;
											user.user_details.cheklist_seq_no = 6;
											localStorage.setItem("user", JSON.stringify(user));
										}
									}
								}
							}
						}
					}
				} else if(user_type == t_role){
					// if($('#tenant_invite_code').val() == ''){
						checkAccessCodeExist();
					// }
					$('#first_month_auth_accordion').addClass('add_opacity_lock');
					// $('#texas_lease_accordion').addClass('add_opacity_lock');
					// $('#zoho_lease_accordion').addClass('add_opacity_lock');
					$('#bank_details_accordion').addClass('add_opacity_lock');
					if(response.response_data.error_code != 0){
						$('#headingOne span.accordion_arrow').html(cirlcle_down_icon);
						var status = '';
						var data = JSON.parse(localStorage.getItem('user'));
						var dwolla_customer_status = data.user_details.dwolla_customer_status;
						if(dwolla_customer_status != 0 && dwolla_customer_status != 1){
							switch(dwolla_customer_status){
								case 2  :
									status = 'Retry';
									break;
								case 3:
									status = 'KBA';
									break;
								case 4:
									status = 'Document';
									break;
								case 5:
									status = 'Suspended';
									break;
							}
							// $('#headingOne .card-title').append('<span class="badge badge-danger fs-12 mr-2 ml-2">'+status+'</span>');
							$('#headingOne .card-title span').html(status);
						}
					} else {
						var completed_steps = response.user_checklist_data.steps_completed.split(',');
						if(completed_steps.includes('7')){
							$('#bank_details_accordion').removeClass('add_opacity_lock');
							$('#headingTwo span.accordion_arrow').html(cirlcle_down_icon);
							$('#tenant_kyc_detail_accordion').trigger('hidden.bs.collapse');
							$('#headingOne span.accordion_verfiy').html(verified_icon);
							$('#headingOne span.accordion_arrow').remove();
							$('#tenant_kyc_detail_accordion').addClass('null_pointer');
							$('.kyc_status_message').addClass('d-none');

							if(completed_steps.includes('8')){
								// $('#contracts_accordion').removeClass('d-none');
								$('#bank_details_accordion').trigger('hidden.bs.collapse');
								//$('#headingTwo span.accordion_verfiy').html(verified_icon);
								$('#bank_details_accordion').addClass('null_pointer');
								$('#headingTwo span.accordion_arrow').remove();


								// if(signature_enabled == 'zoho_template'){
									// 	// $('#texas_lease_accordion').parent().remove();
									// 	$('#zoho_lease_accordion').removeClass('add_opacity_lock');
									// 	$('#headingZohoLease span.accordion_arrow').html(cirlcle_down_icon);
									// 	$('#zoho_lease_accordion').removeClass('d-none');
									// } else {
										// $('#zoho_lease_accordion').parent().remove();
										// // $('#texas_lease_accordion').removeClass('add_opacity_lock');
										setTimeout(function(){
											var property_id = $('#tenant_property_id').val();
											if(property_id != 0){
												// This function is implemented for
												getSignupZohoTemplateList(property_id);
												// if(signature_enabled == 'docusign'){
												// 	getContracts(property_id);
												// } else if(signature_enabled == 'zoho_document'){
												// 	getZohoDocuments(property_id);
												// }
											}
										}, 300);
								// }

								$('#headingTwo span.accordion_verfiy').html(verified_icon);

								if(completed_steps.includes('9')){
									$('#main_container').load('components/onboarding_completed.html');
									var user = JSON.parse(localStorage.user);
									user.user_details.steps_left = 0;
									user.user_details.cheklist_seq_no = 3;
									localStorage.setItem("user", JSON.stringify(user));
								}
							}
						}
					}
				} else if(user_type == o_role){
					$('.textarea_count').removeClass('d-none');
					$('#inquiry_accordion').trigger('shown.bs.collapse');
					var completed_steps = response.user_checklist_data.steps_completed.split(',');
					if(completed_steps.includes('15')){
						$('#thanks_accordion').parent('div').removeClass('d-none').addClass('d-flex');
						$('#thanks_accordion').removeClass('d-none');
						// $('#inquiry_accordion').trigger('hidden.bs.collapse');
						$('#headingInquiry span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#headingInquiry span.accordion_arrow').remove();
						// $('.textarea_count').remove();
					}
				}
				setTimeout(function(){
					$('.accordion_arrow').removeClass('add_po_signup_loader');
				}, 250);
				setTimeout(function() {
					$('.add_opacity_lock').addClass('null_pointer').removeClass('bg-white').find('span.accordion_arrow').html('<img src="icon/lock.svg" class="filter-8 mr-2 mt-1" style="width: 20px;"/>');
				}, 400);
			}
		}
	});
}

window.getInquiryDetail = function(){
	$.ajax({
	    method: 'POST',
	    url: api_url+"users/get-inquiry-detail",
	    data: 'user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(response) {
			if(Object.keys(response.inquiry_detail).length > 0){
				$('#inquiry_textarea').val(response.inquiry_detail.message);
				$('#inquiry_textarea').attr('disabled', true);
				$('#submit_inquiry').attr('disabled', true);
				$('#submit_inquiry').addClass('d-none');
				$('#the-count').addClass('d-none');
			} else {
				$('#submit_inquiry').removeClass('d-none');
				$('#the-count').removeClass('d-none');
				$('#inquiry_textarea').attr('disabled', false);
			}
		}
	});
}

window.sendInquiryMail = function(query){
	$.ajax({
	    method: 'POST',
	    url: api_url+"users/inquiry-mail",
	    data: 'user_id='+user_id+'&query='+query+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(response) {
			display_error_message(response);
			$('#submit_inquiry').text('Submit');
			$('#submit_inquiry').attr('disabled', false);
			if(response.response_data.success == true){
				$('#thanks_accordion').parent('div').removeClass('d-none').addClass('d-flex');
				$('#thanks_accordion').removeClass('d-none');
				// $('.textarea_count').remove();
				$('#explore_accordion').trigger('hidden.bs.collapse');
				$('#submit_inquiry').addClass('d-none');
				$('#the-count').addClass('d-none');
				$('#inquiry_accordion').trigger('hidden.bs.collapse');
				$('#headingInquiry span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
				$('#headingInquiry span.accordion_arrow').remove();
				if(user_service_name == o_role){
					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 1;
					user.user_details.steps_left = 0;
					localStorage.setItem("user", JSON.stringify(user));
				}
			}
		}
	});
}

window.updateServiceRole = function(user_id, service_id){
	$.ajax({
	    method: 'POST',
	    url: api_url+"user-services/set-user-role",
	    data: 'user_id='+user_id+'&service_id='+service_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(response) {
			var user = JSON.parse(localStorage.user);
			user_service_name = response.data.service.constant_name;
			user.user_details.service_id = response.data.service_id;
			user.user_details.service_constant = response.data.service.constant_name;
			user.permissionData = response.permissionData;

			user.user_details.cheklist_seq_no = response.cheklist_seq_no;
			user.user_details.total_steps = response.total_steps;
			user.user_details.steps_left = response.steps_left;
			localStorage.setItem("user", JSON.stringify(user));
			if($('#first_time_signup').val() == 1){
				$('#headingExplore span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
				$('#headingExplore span.accordion_arrow').remove();
			}
		}
	})
}

window.getServicePage = function(service_id, type = ''){
	// var data = JSON.parse(localStorage.getItem('user'));
	// var service_id = data.user_details.service_id;
	if (service_id != 0) {
		$('#headingExplore span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
		$('#headingExplore span.accordion_arrow').remove();
		if(type == ''){
			$('#explore_accordion').trigger('hidden.bs.collapse');
		}
		if(service_id == 3){
			$('.onboarding_task_to_complete').removeClass('d-none');
			$('.po_referral_code').removeClass('d-none');
			$('.textarea_count').addClass('d-none');
			$('.tenant_referral_code').addClass('d-none');
			$('#thanks_accordion').parent('div').addClass('d-none').removeClass('d-flex');
			$("#flexRadioDefault1").prop('checked', true);
			$('.user_signup_form').load('components/po_signup.html', function() {
				openLoadingDialog($('.add_po_signup_loader'));
				loadCompletedChecklistSteps(user_id, po_role);
			});
		} else if(service_id == 4){
			$("#flexRadioDefault2").prop('checked', true);
			$('.onboarding_task_to_complete').addClass('d-none');
			$('.po_referral_code').addClass('d-none');
			$('.textarea_count').addClass('d-none');
			$('.tenant_referral_code').removeClass('d-none');
			setTimeout(function() {
				$('.user_signup_form').load('components/t_signup.html', function() {
					openLoadingDialog($('.add_po_signup_loader'));
					loadCompletedChecklistSteps(user_id, t_role);
				});
			}, 500);
		} else if(service_id == 5){
			$('.onboarding_task_to_complete').addClass('d-none');
			$('.textarea_count').addClass('d-none');
			$('.tenant_referral_code').addClass('d-none');
			$('.po_referral_code').addClass('d-none');
			$("#flexRadioDefault3").prop('checked', true);
			$('.tenant_referral_code').addClass('d-none');
			$('.po_referral_code').addClass('d-none');
			setTimeout(function() {
				// 	$('.textarea_count').removeClass('d-none');
				loadCompletedChecklistSteps(user_id, o_role);
			}, 500);
		}
	}
}

window.getReferralCode = function(){
	if(apply_default_coupon_code == true) {
		$('#coupon_code').attr('readonly',true);
		$('#coupon_code').val(default_property_setup_coupon_code);
		$('#apply_coupon_code').remove();
		$('#collapseCode #subscription_offer_message_section').removeClass('d-none');
		var trigger_from = "apply_button_clicked";
		var coupon_code = $('#coupon_code').val();
		applyCouponCode('property_setup_fee', coupon_code, trigger_from);
	}
}

window.editOnBoardTicket = function(){
	var ticket_id = $('.save_ticket_comment').attr('ticket-id');
	var user_detail = JSON.parse(localStorage.getItem('user_address'));
	var title = 'Welcome ' + user_detail.user_details.first_name + ' ' + user_detail.user_details.last_name + ', to Rent Synergy! Your Personal Onboarding Assistance.';
	var description = "Hi there! We're thrilled to have you on board. If you have any questions or need assistance as you setup your account, feel free to reach out right here.";
	var formatted_markdown_description = parse_markdown_to_html(description);
	var status = 1;
	var to_whom = '';
	var data = 'user_id='+user_id+'&user_service_name='+user_service_name+'&title='+encodeURIComponent(title)+'&description='+encodeURIComponent(description)+'&status='+status+'&to_whom='+to_whom+'&formatted_markdown_description='+encodeURIComponent(formatted_markdown_description);
	$.ajax({
		method: 'POST',
		url: api_url+"tickets/edit/"+ticket_id,
		data: data,
		async: true,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.save_ticket_comment').removeClass('add_opacity');
			$('#contract_ticket_container textarea').removeClass('add_opacity');
			$('#contract_ticket_container textarea').text('');
		}
	});
}

window.loadOnBoardTicket = function(){
	var user = JSON.parse(localStorage.getItem('user'));
	var tz = jstz.determine();
	var timezone = tz.name();
	// var first_time_signup = $('#first_time_signup').val();
	var first_time_signup = 1;
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&user_service_name='+user.user_details.service_constant+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup;
	$.ajax({
		method: 'POST',
		url: api_url+"tickets",
		data: data,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(response.response_data.success == true){
				if(response.TotalTickets == 0){
					var user_detail = JSON.parse(localStorage.getItem('user_address'));
					var title = 'Onboard ' + user_detail.user_details.first_name + ' ' + user_detail.user_details.last_name;
					var description = "Welcome aboard! We're thrilled to have you on board. Should you have any questions or require assistance while setting up your account, please don't hesitate to contact us directly through this ticket.";
					var formatted_markdown_description = parse_markdown_to_html(description);
					var status = 1;
					var to_whom = '';
					var data = 'user_id='+user_id+'&title='+encodeURIComponent(title)+'&description='+encodeURIComponent(description)+'&status='+status+'&to_whom='+to_whom+'&formatted_markdown_description='+encodeURIComponent(formatted_markdown_description);

					$.ajax({
						method: 'POST',
						url: api_url+"tickets/add-on-board-ticket",
						data: data,
						beforeSend: function(xhr) {
							xhr.setRequestHeader('Authorization', accessToken);
						},
						success: function(response) {
							if(response.response_data.success == true) {
								$('.ticket_details').html('<h5 class="text-center fs-20">'+title+'</h5>');
								$('.onboard_description').html('<p class="text-justify">'+description+'<p>');
								$('.save_ticket_comment').attr('ticket-id', response.id);

								var user = JSON.parse(localStorage.user);
							   	user.user_details.onboard_ticket_created = 1;
							   	localStorage.setItem("user", JSON.stringify(user));  //put the object back
							}
						}
					});
				} else {
					$('.ticket_details').html('<h5 class="text-center fs-20">'+response.tickets[0].title+'</h5>');
					$('.onboard_description').html('<p class="text-justify">'+response.tickets[0].description+'<p>');
					$('.save_ticket_comment').attr('ticket-id', response.tickets[0].id);

					/*if(typeof user_service_name === 'undefined' || user_service_name == '') {
						$('#comment_section').removeClass('d-none');
						$('#comment_section').html('<div class="justify-content-center d-flex"><lottie-player src="/img/lottie-files/userrole.json" background="transparent" speed="1" style="width: 100%; height: 100%" loop autoplay direction="1" mode="bounce" class="ml-auto mr-auto"></lottie-player></div><div class="justify-content-center d-flex width-100 fs-20">Please Choose Your Role</div>');
					} else {*/
						var ticket_id = response.tickets[0].id;
						var tz = jstz.determine();
						var timezone = tz.name();
						$.ajax({
							method: 'POST',
							url: api_url+"comments",
							data: 'module_id='+ticket_id+'&module_name=tickets&user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name,
							beforeSend: function(xhr) {
								xhr.setRequestHeader('Authorization', accessToken);
							},
							success: function(response) {
								if(response.response_data.success == true) {
									var event_cnt = 0;
									var timeline_key = 0;
									// for(var key in response.comments.reverse()){
									for(var key in response.comments){
										var comment_id = response.comments[key].id;
                						var ticket_user_name = response.comments[key].user.first_name+' '+response.comments[key].user.last_name;
                						var ticket_user_email = response.comments[key].user.email;
                						var converted_created = response.comments[key].converted_created;
                						var description = response.comments[key].comment;
										if(response.comments[key]['event'] == 'comment'){
											event_cnt = 0;
											var next_record = parseInt(key) + 1;
											if (response.comments.hasOwnProperty(next_record)) {
												var mb_0 = '';
												if(response.comments[next_record]['event'] != 'comment') {
													mb_0 = 'mb-0';
												}
											}

											var html = '<div class="card shadow flex-fill w-100 border '+mb_0+'"><div class="card-header bg-light"><h5 class="mb-0 comment-title">'+ticket_user_name+'&nbsp;<small class="fs-12">('+ticket_user_email+') opened this ticket on '+converted_created+' </small></h5></div><div id="comment_description_'+comment_id+'" class="p-4">'+description+'</div></div>';

											$('.ticket_comments').append(html);
											parse_markdown('comment_description_'+comment_id);
										} else {
											if(event_cnt == 0) {
												var ul_data = '<ol id="custom_timeline_'+key+'" class="custom_timeline"></ol>';
												$('.ticket_comments').append(ul_data);
												timeline_key = key;
											}

											var event_icon = '';
											if(response.comments[key]['event'] == 'role_event') {
												event_icon = '<img src="icon/activity/role.svg" class="filter-grey">';
											} else if(response.comments[key]['event'] == 'dwolla_event') {
												event_icon = '<img src="icon/activity/dwolla.svg" class="filter-grey">';
											} else if(response.comments[key]['event'] == 'payment_event') {
												event_icon = '<img src="icon/activity/payment.svg" class="filter-grey">';
											} else if(response.comments[key]['event'] == 'property_event') {
												event_icon = '<img src="icon/activity/property.svg" class="filter-grey">';
											} else if(response.comments[key]['event'] == 'coupon_event') {
												event_icon = '<img src="icon/activity/coupon.svg" class="filter-grey">';
											} else if(response.comments[key]['event'] == 'tenant_event') {
												event_icon = '<img src="icon/activity/tenant.svg" class="filter-grey">';
											} else if(response.comments[key]['event'] == 'lease_event') {
												event_icon = '<img src="icon/activity/lease.svg" class="filter-grey">';
											}

											var li_data = '';
											li_data += '<li class="custom-timeline-item">';
												li_data += '<span class="timeline-item-icon | faded-icon">';
													li_data += event_icon;
												li_data += '</span>';
												li_data += '<div class="timeline-item-description">';
													//li_data += '<i class="avatar | small"><img src="icon/avatar.jpg" /></i>';
													li_data += '<span><strong class="bold-black">'+ticket_user_name+'</strong> '+response.comments[key].comment+' on <time datetime="21-01-2021">'+response.comments[key].converted_created+'</time></span>';
												li_data += '</div>';
											li_data += '</li>';

											$('#custom_timeline_'+timeline_key).append(li_data);
											event_cnt++;
										}
									}
								}
							}
						});
					//}
				}
			}
		}
	});
}

window.getPropertyMedia = function(){
	$('#property_detail_accordion').trigger('hidden.bs.collapse');

	var data = 'user_id='+user_id+'&user_service_name='+user_service_name;

	$.ajax({
	    method: 'POST',
	    url: api_url+"properties",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			openLoadingDialog($('.property_detail_media'));

			if(response.TotalProperties == 1){
				// if(response.Properties[0].property_setup_fee_authorized != 1){
				// 	checkIfPropertySetupPlanAvailable(response.Properties[0].id);
				// } else{
					setTimeout(function(){
						$('#property_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
						$('.property_detail_media').load('components/property/edit_property_address.html', function(){
							getStateDropdown();
							$('.property_detail_media .card-header').remove();
							$('.property_detail_media .card').eq(0).removeClass('card border');
							$('.update_property_address').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');
							getPropertyAddressDetail(response.Properties[0].id, 'vertical');
							// getStateDropdown();
						});
					}, 1000);
				// }
			} else{
				setTimeout(function(){
					$('#property_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
					$('.property_detail_media').load('components/property/add_new_property.html', function(){
						getStateDropdown();
						$('.add_property_coupon_code').remove();
						$('.property_detail_media .card-header').remove();
						$('.property_detail_media .card').eq(0).removeClass('card border');
						$('.save_property_data').parent('div').removeClass('mb-2').addClass('mb-1').removeClass('mt-2').addClass('mt-3');

						getDefaultPlanDetail();
					});
				}, 1000);
			}

			$('.prop_acc').addClass('border-bottom');
		} else {
			display_error_message(response);
		}
	});
}

window.getAssumedUserData = function(type, email, service_id = 0) {
	var user_id = user.user_details.user_id;
	$.ajax({
	    method: 'POST',
	    url: api_url+"auth/get-assumed-user-data",
		data: 'email='+email+'&user_id='+user_id+'&service_id='+service_id+'&type='+type,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	if(type == 'add' || type == 'linked_service') {
	        		localStorage.setItem('primary', user_email);
					localStorage.setItem('allow_ai_access', allow_ai_access);
	        	} else if(type == 'remove') {
	        		localStorage.removeItem("primary");
					localStorage.removeItem("allow_ai_access");
	        	}

	    		var user = JSON.parse(localStorage.user);
			   	user.user_details = response.user_details;
			   	user.permissionData = response.permissionData;
			   	user.leaseInfo = response.leaseInfo;
			   	user.announcementInfo = response.announcementInfo;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back
	    		localStorage.setItem('page_name', 'dashboard');

	    		window.user = JSON.parse(localStorage.getItem('user'));
				window.status_code = user.user_details.status_code;
				window.user_service_name = $.trim(user.user_details.service_constant);
	    		if(user_service_name == po_role || user_service_name == t_role) {
					var checklist_seq_no = user.user_details.cheklist_seq_no;
					var total_steps = user.user_details.total_steps;
					var steps_left = user.user_details.steps_left;
					if((steps_left != 0 || checklist_seq_no < total_steps || checklist_seq_no == 0 || user_service_name == o_role) && status_code == 1 && (user_service_name == po_role || user_service_name == t_role)) {
						window.location.href = site_url+'dashboard.html';
					} else {
						window.location.href = site_url+'dashboard.html?action=properties';
					}
				} else {
					window.location.href = site_url+'dashboard.html?action=properties';
				}
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.loadUserDetail = function(user_id) {
	$.ajax({
	    method: 'POST',
	    url: api_url+"users/details",
	    data: 'user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
		        localStorage.setItem('user_address', JSON.stringify(response));
		    }
	    }
	});
}

window.loadLeftMenu = function() {
	$('#navbar_role_name').html(humanize(current_page));
	$('#logged_in_as').html('<i class="far fa-user mr-1"></i> Logged in as : <span class="orange">'+user.user_details.user_type+'</span>');
	$('#profile_email').html('<i class="far fa-envelope mr-1"></i> '+user.user_details.email);

	if(dwolla_customer_status == 2) {
		$('<a id="update_dwolla_kyc" class="dropdown-item d-none" href="javascript:void(0)"><i class="far fa-envelope mr-1"></i> Update Dwolla KYC</a>').insertAfter('#profile_email');
	}

	// Sidebar menu api
	getMenuItem();

	var page_name = getUrlParameter('action');
	if (typeof page_name !== "undefined") {
		load_selected_page(page_name);
	}
}

window.getMenuItem = function() {
	var html = "";
   html += '<div class="sidebar-content" data-simplebar><a class="sidebar-brand" href="javascript:void(0)"><span class="align-middle"><img src="img/logo/Rent_Preview_File_v2.png" height="26px" /></span> </a><ul class="sidebar-nav">';
    	for (var key in user.permissionData) {
        	if (user.permissionData.hasOwnProperty(key)) {
        		var action = user.permissionData[key].action;
        		var page_class = action.split('.').slice(0, -1).join('.');

				if(page_class == 'master-data' && (user_service_name == po_role || user_service_name == t_role)) {
					user.permissionData[key].name = 'Contact';
				}

				var enable_sidemenu = 1;
				if(page_class == 'dwolla-fund-transfer' || page_class == 'dwolla-webhook'){
					if(allow_ai_access != 1){
						var enable_sidemenu = 0;
					}
				}

        		if(page_class != 'dashboard' && enable_sidemenu == 1) {
					if(!(page_class == 'template' && allow_ai_access != 1)){
						html += '<li data-page-name="'+page_class+'" class="sidebar-item"><a href="javascript:void(0)" class="sidebar-link"> <img src="'+user.permissionData[key].icon+'"/> <span class="align-middle">'+user.permissionData[key].name+'</span></a></li>';
					}
        		}
            }
        }
    html += '</ul>';

    var d = new Date();
	var current_year = d.getFullYear();

    html += '<footer id="footer" class="footer">';
        html += '<div class="row text-muted">';
		    html += '<div class="col-12 text-left">';
		        html += '<ul class="list-inline text-left">';
		            html += '<li class="list-inline-item"> <a class="text-muted" href="privacy.html" target="_blank">Privacy</a> </li>';
		            html += '<li class="list-inline-item"> <a class="text-muted" href="tos.html" target="_blank">Terms</a> </li>';
		            html += '<li class="list-inline-item">  &copy; <span class="footer_year">'+current_year+'</span> </li>';
		        html += '</ul>';
		    html += '</div>';
		html += '</div>';
	html += '</footer>';

    html += '</div>';
    $('#sidebar').html(html);
}

window.loadDashboardPage = function() {
	var user_id = user.user_details.user_id;
	var data = JSON.parse(localStorage.getItem('user'));
	var checklist_seq_no = data.user_details.cheklist_seq_no;
	var total_steps = data.user_details.total_steps;
	var steps_left = data.user_details.steps_left;
	if(user_service_name == sa_role) {
		$('.sidebar-toggle').show();
		$('.hamburger').removeClass('d-none');
		$('#sidebar').removeClass('d-none');
		$('#notification_section').removeClass('d-none');
		// setTimeout(function(){
        // 	$('.search-toggle').removeClass('d-none');
		// }, 5000);
	} else {
		if(steps_left != 0 || checklist_seq_no < total_steps || checklist_seq_no == 0 || user_service_name == o_role){
		// if(checklist_seq_no < total_steps || checklist_seq_no == 0){
			var first_property_id = user.user_details.first_property_id;
			var first_property_name = user.user_details.first_property_name;
			$('#first_property_id').val(first_property_id);
			$('#first_property_name').val(first_property_name);

			$('.sidebar-toggle').hide();
			$('#user_my_account').hide();
			$('.hamburger').addClass('d-none');
			$('#sidebar').addClass('d-none');
			$('#notification_section').addClass('d-none');
			$('#onboard_ticket_section').removeClass('d-none');

			//$('#payment_form_container').removeClass('d-none');
			//checkKycDetailStatusAtDashboard();
			//$('#first_time_login_checklist_container').removeClass('d-none');

			$('#main_container').load('components/first_time_signup_checklist_wizard.html', function(){
				var given_name = data.user_details.given_name;
				var family_name = data.user_details.family_name;
				$('#navbar_role_name').html('Welcome, '+given_name+' '+family_name+'!');
				$('#first_time_signup').val(1);
				if(data.user_details.onboard_ticket_created == 0) {
					setTimeout(function() {
					 	loadOnBoardTicket();
					}, 300);
				}
				if (!$(".user_role_radio").is(":checked")) {
					$('#explore_accordion').trigger('shown.bs.collapse');
					$('.po_referral_code').addClass('d-none');
					$('.tenant_referral_code').addClass('d-none');
					$('.onboarding_task_to_complete').addClass('d-none');
				} else {
					$('.explore_acc').removeClass('show');
					openLoadingDialog($('.user_signup_form'));

					// $('#explore_accordion').trigger('hidden.bs.collapse');
					var user_role = $("input:radio[name='user_role_radio']:checked").attr('data-role');
					if(user_role == 'po') {
						$('.tenant_referral_code').addClass('d-none');
					} else if(user_role == 'tenant') {
						$('.po_referral_code').addClass('d-none');
					}
				}
			});
		} else {
			var action = getUrlParameter('action');
			if(typeof action === "undefined") {
				window.location.href = site_url+'dashboard.html?action=properties';
			}
		}
	}

	if(!(steps_left != 0 || checklist_seq_no < total_steps || checklist_seq_no == 0 || user_service_name == o_role) || user_service_name == sa_role){
		//getPendingSignatureContract();
		$('#user_my_account').show();

		$('.search-toggle').removeClass('d-none');

		if(localStorage.getItem("hide_announcement") != '1') {
			getAnnouncement();
		}

		// Get notification and set in right sidebar
		getNotificationData('get_count');
	}
}

// window.getNotificationData = function() {
// 	// Get Bank Detail
// 	if(user.permissionData['DASHBOARD']["feature_items['verify_bank']"]) {
// 		$('#unverified_bank_section').removeClass('d-none');
// 		$.ajax({
// 			method: 'POST',
// 			url: api_url+"users/details",
// 			data: 'user_id='+user.user_details.user_id,
// 			beforeSend: function(xhr) {
// 				xhr.setRequestHeader('Authorization', accessToken);
// 			},
// 			success: function(response) {
// 				if(response.response_data.success == true) {
// 					var notification_html = "";
// 					if(Object.keys(response.userPaymentData).length > 0){

// 						notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
// 							notification_html += '<div class="col-12 col-md-12">';
// 								notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/bank.svg" class="filter-15" style="width: 23px;"/></span> Bank</div>';
// 							notification_html += '</div>';
// 						notification_html += '</div>';

// 						for (var key in response.userPaymentData) {
// 							if (response.userPaymentData.hasOwnProperty(key)) {
// 								var status_class = 'red';
// 								var display = '';
// 								if(response.userPaymentData[key].status == 2) {
// 									status_class = 'green';
// 									display = 'none';
// 								}
// 								notification_html += '<div class="card light-border m-3">';
// 									notification_html += '<div id="bank_card_'+response.userPaymentData[key].id+'" class="notifcation-group-item list-group-item unverified_bank no_border">';
// 										notification_html += '<div class="row no-gutters align-items-center">';
// 											notification_html += '<div class="col-12">';
// 												notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
// 													notification_html += '<tbody>';
// 														notification_html += '<tr>';
// 															notification_html += '<td class="grey">Bank Name</td>';
// 															notification_html += '<td class="text-right bold-black">'+response.userPaymentData[key].bank_name+'</td>';
// 														notification_html += '</tr>';
// 														notification_html += '<tr>';
// 															notification_html += '<td class="grey">Account Number</td>';
// 															notification_html += '<td class="text-right bold-black">'+response.userPaymentData[key].account_number+'</td>';
// 														notification_html += '</tr>';
// 														notification_html += '<tr>';
// 															notification_html += '<td class="grey">Account Type</td>';
// 															notification_html += '<td class="text-right bold-black">'+response.userPaymentData[key].account_type+'</td>';
// 														notification_html += '</tr>';
// 														notification_html += '<tr>';
// 															notification_html += '<td class="grey">Status</td>';
// 															notification_html += '<td class="text-right bold-black '+status_class+'">'+response.userPaymentData[key].status_label+'</td>';
// 														notification_html += '</tr>';
// 													notification_html += '</tbody>';
// 												notification_html += '</table>';
// 												if(response.userPaymentData[key].status == 1) {
// 													notification_html += '<button data-id='+response.userPaymentData[key].id+' type="button" class="btn btn-orange verify_bank_detail w-100 mt-3" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false" style="display:'+display+'">Verify Bank Detail</button>';
// 												}
// 											notification_html += '</div>';
// 										notification_html += '</div>';
// 									notification_html += '</div>';
// 								notification_html += '</div>';
// 							}
// 						}
// 						$('#sidebar_content_area').append(notification_html);
// 					}
// 				} else {
// 					display_error_message(response);
// 				}
// 			}
// 		});
// 	}

// 	// Get Upcoming Payment Detail && Review and Authorize first month payment
// 	if(user_service_name == t_role && user.permissionData['DASHBOARD']["feature_items['authorize_rent_payment']"]) {
// 		$('#upcoming_payment_section').removeClass('d-none');
// 		getUpcomingPaymentDetail();

// 		$('#review_first_payment_section').removeClass('d-none');
// 		getReviewFirstMonthPaymentDetail();
// 	}

// 	// Get subscription fee failed transaction
// 	if(user_service_name == po_role) {
// 		getSubscriptionFeeFailedTransaction();
// 	}

// 	getUserLeaseInfo();
// 	$('.search-toggle').removeClass('d-none');

// 	if(signature_enabled == 'docusign'){
// 		getPendingSignatureContractDetails();
// 	} else {
// 		getPendingZohoDocuments();
// 	}
// }

window.getNotificationData = function(type = '') {
	var first_time_signup = $('#first_time_signup').val();
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
		method: 'POST',
		url: api_url+"users/get-notification-data",
		data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			var notification_html = '';
			if(response.response_data.success == true) {
				$('#notification_count, #notification_indicator').html(response.total_notification);

				if(type != 'get_count'){
					if(response.total_notification == 0){
						notification_html += '<div class="h-100 text-center pt-7">';
							notification_html += '<lottie-player src="img/lottie-files/onboarding_completed.json" background="transparent" speed="1" style="width: 300px; height: 300px" loop autoplay direction="1" mode="bounce" class="m-auto"></lottie-player>';
							notification_html += "<h3 class='mt-4'>You're all caught up!</h3>";
						notification_html += '</div>';
					} else {
						// Get Bank Detail
						if(user.permissionData['DASHBOARD']["feature_items['verify_bank']"]) {
							notification_html += verifyBankNotification(response.userPaymentData);
						}

						if(new_payment_schedule_enabled == 'true'){
							notification_html += getReviewAllPaymentDetail(response.psUnauthorizedPayments);
						} else {
							// Get Upcoming Payment Detail && Review and Authorize first month payment
							if(user_service_name == t_role && user.permissionData['DASHBOARD']["feature_items['authorize_rent_payment']"]) {
								$('#review_first_payment_section').removeClass('d-none');
								notification_html += getReviewFirstMonthPaymentDetail(response.fmUnauthorizedPayments);

								$('#upcoming_payment_section').removeClass('d-none');
								notification_html += getUpcomingPaymentDetail(response.spUnauthorizedPayments);
							}

							// Get subscription fee failed transaction
							if(user_service_name == po_role) {
								notification_html += getSubscriptionFeeFailedTransaction(response.failedSubscriptionFeeSP);
							}
						}

						notification_html += getUserLeaseInfo(response.leaseInfo);
						$('.search-toggle').removeClass('d-none');

						if(signature_enabled == 'docusign'){
							notification_html += getPendingSignatureContractDetails(response.pendingDocusignContracts);
						} else {
							notification_html += getPendingZohoDocuments(response.pendingZohoDocuments);
						}
					}
				}
			}

			$('#sidebar_content_area').html(notification_html);
		}
	});
}

window.verifyBankNotification = function(userPaymentData){
	$('#unverified_bank_section').removeClass('d-none');

	var notification_html = "";
	if(userPaymentData.length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/bank1.svg" class="filter-15" style="width: 23px;"/></span> Bank</div>';
			notification_html += '</div>';
		notification_html += '</div>';

		for (var key in userPaymentData) {
			var status_class = 'red';
			var display = '';
			if(userPaymentData[key].status == 2) {
				status_class = 'green';
				display = 'none';
			}
			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="bank_card_'+userPaymentData[key].id+'" class="notifcation-group-item list-group-item unverified_bank no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						notification_html += '<div class="col-12">';
							notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
								notification_html += '<tbody>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Bank Name</td>';
										notification_html += '<td class="text-right bold-black">'+userPaymentData[key].bank_name+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Account Number</td>';
										notification_html += '<td class="text-right bold-black">'+userPaymentData[key].account_number+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Account Type</td>';
										notification_html += '<td class="text-right bold-black">'+userPaymentData[key].account_type+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Status</td>';
										notification_html += '<td class="text-right bold-black '+status_class+'">'+userPaymentData[key].status_label+'</td>';
									notification_html += '</tr>';
								notification_html += '</tbody>';
							notification_html += '</table>';
							if(userPaymentData[key].status == 1) {
								notification_html += '<button data-id='+userPaymentData[key].id+' type="button" class="btn btn-orange verify_bank_detail w-100 mt-3" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false" style="display:'+display+'">Verify Bank Detail</button>';
							}
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

window.getPendingUserList = function(layout) {
	if(user.permissionData['USERS']["feature_items['list']"]) {
		var user_name_ids = $('#usersNameDD').val();
		var user_email_ids = $('#usersEmailDD').val();
		var user_phone_ids = $('#usersPhoneDD').val();
		var user_status_ids = $('#usersStatusDD').val();
		var data = 'usersNameDD='+user_name_ids+'&usersEmailDD='+user_email_ids+'&usersPhoneDD='+user_phone_ids+'&usersStatusDD='+user_status_ids+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

		$.ajax({
			method: 'POST',
		    url: api_url+"users/applications",
			data: data,
		    beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
				display_expense_message(response);
	        	if(response.response_data.success == true) {
		    		loadUserCard(layout, response);
	            }
	        }
		})
	}
}

window.loadUserCard = function(layout, response) {
	var html = "";
	var table_html = "";
	if(response.TotalUsers == 0) {
		html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		table_html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
	} else {

		if(layout == 'table_layout') {
			$('#user_search_col').removeClass('col-lg-12').addClass('col-lg-8');
		} else {
			$('#user_search_col').removeClass('col-lg-8').addClass('col-lg-12');
		}

		if(layout == 'table_layout') {
			table_html += '<div class="col-auto col-md-12 col-lg-12 col-xl-12">';
				table_html += '<div class="row justify-content-center">';
			        table_html += '<div id="expensesDataContainer" class="col-auto col-md-12 col-lg-12 col-xl-8">';
			            table_html += '<div id="search_section_container"></div>';
			            table_html += '<div class="card">';
			                table_html += '<div class="card-body">';
			                    table_html += '<table id="user-datatable" class="table card_datatable" cellspacing="10" cellpadding="10" style="width: 100%;">';
			                        table_html += '<thead class="bold-black fs-14 d-none">';
			                            table_html += '<tr>';
			                                table_html += '<th>Name</th>';
			                            table_html += '</tr>';
			                        table_html += '</thead>';
			                        table_html += '<tbody id="user_table_row" class="dt_card_body">';
		}

	    for (var key in response.users) {
	        if (response.users.hasOwnProperty(key)) {
	            var username = response.users[key].first_name+' '+response.users[key].last_name;
	            var email = response.users[key].email;
	            var phone = response.users[key].phone;
	            var address_line1 = "";
		        var address_line2 = "";
		        var city = "";
		        var state = "";
		        var zip_code = "";

		        if(response.users[key].UserService != null) {
			        if(response.users[key].UserService.address != null) {
			        	if(response.users[key].UserService.address.address_line1 != "") {
			                address_line1 = response.users[key].UserService.address.address_line1;
			                address_line1 = address_line1.toLowerCase();
			            }
			            if(response.users[key].UserService.address.address_line2 != "") {
			                address_line2 = response.users[key].UserService.address.address_line2
			                address_line2 = ', '+address_line2.toLowerCase();
			            }
			            if(response.users[key].UserService.address.city != "") {
			                city = response.users[key].UserService.address.city;
			                city = city.toLowerCase();
			            }
			            if(response.users[key].UserService.address.state != "") {
			                state = response.users[key].UserService.address.state;
			                state = state.toUpperCase();
			            }
			            if(response.users[key].UserService.address.zip_code != "") {
			                zip_code = response.users[key].UserService.address.zip_code;
			            }
			        }
			    }

			    var user_type = '';
			    var type = '';
			    var user_bg_class = '';
			    if(response.users[key].user_service_name == po_role) {
		        	user_bg_class = 'po_bg';
		        	type = 'PO';
		        } else if(response.users[key].user_service_name == t_role) {
		        	user_bg_class = 't_bg';
		        	type = 'T';
		        } else {
		        	user_bg_class = 'a_bg';
		        	type = 'A';
		        }

				if(layout == 'table_layout') {
					var phone_verified = '<i class="align-middle mr-2 fas fa-fw fa-exclamation-circle red"  data-toggle="tooltip" data-placement="bottom" data-original-title="Unverified"></i>';
					var email_verified = '<i class="align-middle mr-2 fas fa-fw fa-exclamation-circle red"  data-toggle="tooltip" data-placement="bottom" data-original-title="Unverified"></i>';
					if(response.users[key].phone_verified == 1) {
						phone_verified = '<i class="align-middle mr-2 fas fa-fw fa-check-circle green" data-toggle="tooltip" data-placement="bottom" data-original-title="Verified"></i>';
					}
					if(response.users[key].email_verified == 1) {
						email_verified = '<i class="align-middle mr-2 fas fa-fw fa-check-circle green" data-toggle="tooltip" data-placement="bottom" data-original-title="Verified"></i>';
					}
				} else {
					var phone_verified = '<span class="red">Unverified</span>';
					var email_verified = '<span class="red">Unverified</span>';
					if(response.users[key].phone_verified == 1) {
						phone_verified = '<span class="green">Verified</span>';
					}
					if(response.users[key].email_verified == 1) {
						email_verified = '<span class="green">Verified</span>';
					}
				}

		        var status = '';
		        if(response.users[key].status == 0) {
	            	status += '<span class="badge badge-info">'+response.users[key].status_label+'</span>';
	            } else if(response.users[key].status == 1) {
	            	status += '<span class="badge badge-success">'+response.users[key].status_label+'</span>';
	            } else if(response.users[key].status == 2) {
	            	status += '<span class="badge badge-warning">'+response.users[key].status_label+'</span>';
	            } else if(response.users[key].status == 5) {
	            	status += '<span class="badge badge-danger">'+response.users[key].status_label+'</span>';
	            }

	            var user_delete_btn = '';
	            /*if(allow_ai_access == 1) {
	            	user_delete_btn = '<a class="dropdown-item delete_user" href="javascript:void(0)" data-id="'+response.users[key].id+'" data-type="'+type+'">Delete</a>';
	            }*/

	            var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item pending_verification_user" href="javascript:void(0)" data-id="'+response.users[key].id+'" data-type="'+type+'">Detail</a>'+user_delete_btn+'</div></div></div>';

	            if(layout == 'table_layout') {
	            	table_html += '<tr data-id="'+response.users[key].id+'" data-type="'+type+'" class="pending_verification_user">';
						table_html += '<td>';
							table_html += '<div class="row card">';
				                table_html += '<div class="row justify-content-center">';
				                	table_html += '<div class="col-12 col-md-6 col-lg-2">';
				                        table_html += '<div class="card-body height-100 width-100 '+user_bg_class+'">';
				                        		table_html += '<div class="circle"><p class="h1 text-center bold-black">'+type+'</p></div>';
				                        table_html += '</div>';
				                    table_html += '</div>';
				                    table_html += '<div class="col-12 col-md-6 col-lg-8">';
				                        table_html += '<div class="card-body text-left">';
				                        	table_html += '<p id="u_name_'+response.users[key].id+'" class="bold-black fs-18">'+username+'</p>';
				                        	table_html += '<small class="fs-14"><i class="far fa-envelope mr-1"></i>&nbsp; '+email+'&nbsp;'+email_verified+'</small><br>';
				                        	if(phone != null && phone != '') {
												phone = phone.split('+1');
			        							phone = phone[1];
												table_html += '<small class="fs-14"><i class="fas fa-phone mr-1"></i>&nbsp; <small class="phone_number fs-14">'+phone+'</small>&nbsp;'+phone_verified+'</small><br>';
											}
				                        	if(response.users[key].UserService != null && response.users[key].UserService.address != null) {
												table_html += '<small class="capitalize fs-14"><i class="far fa-address-card mr-1"></i>&nbsp;'+address_line1+address_line2+'</small><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small class="fs-14">'+city+', '+state+' '+zip_code+'</small>';
											} else {
												table_html += '<small class="capitalize fs-14"></small><br>&nbsp;<small class="fs-14">&nbsp;</small>';
											}
				                        table_html += '</div>';
				                    table_html += '</div>';
				                    table_html += '<div class="col-12 col-md-6 col-lg-2">';
				                        table_html += '<div class="card-body text-right">'+status+'</div>';
				                    table_html += '</div>';
				                table_html += '</div>';
				            table_html += '</div>';
						table_html += '</td>';
					table_html += '</tr>';
				} else {
			        html += '<div data-id="'+response.users[key].id+'" data-type="'+type+'" class="col-12 col-md-6 col-xl-3 pending_verification_user">';
			        	html += '<div class="card">';
			        		html += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 id="u_name_'+response.users[key].id+'" class="card-title capitalize mb-0 mr-3">'+username+'</h5></div>';
			        		html += '<div class="'+user_bg_class+'">';
			        				html += '<div class="circle"><p class="h1 text-center bold-black">'+type+'</p></div>';
			        		html += '</div>';
			        		html += '<div class="card-body">';
			        			html += '<table class="table mb-0 card-table word-break">';
									html += '<tbody>';
										html += '<tr>';
											html += '<td><i class="far fa-envelope mr-1"></i></td>';
											html += '<td class="text-left bold-black">'+email+'<br>'+email_verified+'</td>';
										html += '</tr>';
										html += '<tr>';
										if(phone != null && phone != '') {
											phone = phone.split('+1');
		        							phone = phone[1];
											html += '<td><i class="fas fa-phone mr-1"></i></td>';
											html += '<td class="text-left"><span class="phone_number">'+phone+'</span><br>'+phone_verified+'</td>';
										} else {
											html += '<td>&nbsp;</td>';
											html += '<td class="text-left">&nbsp;<br>&nbsp;</td>';
										}
										html += '</tr>';
										html += '<tr>';
											if(response.users[key].UserService != null && response.users[key].UserService.address != null) {
												html += '<td><i class="far fa-address-card mr-1"></i></td>';
												html += '<td class="text-left"><div class="card-text capitalize">'+address_line1+address_line2+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
											} else {
												html += '<td>&nbsp;</td>';
												html += '<td class="text-left"><div class="card-text capitalize">&nbsp;</div></td>';
											}
										html += '</tr>';
									html += '</tbody>';
								html += '</table>';
								html += '<div class="text-right mt-4">'+status+'</div>';
			        		html += '</div>';
			        	html += '</div>';
			        html += '</div>';
			    }
	        }
	    }

	    if(layout == 'table_layout') {
									table_html += '</tbody>';
						        table_html += '</table>';
			                table_html += '</div>';
			            table_html += '</div>';
			        table_html += '</div>';
			    table_html += '</div>';
			table_html += '</div>';

		    html = table_html;
		}
	}

	$('#user_detail_info_container').html(html);
	$('.phone_number').mask('(000) 000-0000');

	if(layout == 'table_layout') {
		if ($.fn.DataTable.isDataTable("#user-datatable")) {
			$("#user-datatable").DataTable().destroy();
		}
		var user_table = $("#user-datatable").DataTable({
			ordering: false
		});
		$("#user-datatable tbody tr td:first").addClass('border-top');
	}

	getFloatingActionButton('All-User', '#user_detail_info_container');
}

window.getPendingUserData = function(user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"users/details",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
	        	$('#selected_user_id').val(user_id);

	        	/* Overview */

	        	if(response.user_details.status_code == 5) {
	            	$('.reject_user_button').attr('disabled', true);
	            } else {
	            	$('.reject_user_button').attr('disabled', false);
	            }

	            var status = '';
	            if(response.user_details.status_code == 0) {
	            	status += '<span class="badge badge-info">'+response.user_details.status+'</span>';
	            } else if(response.user_details.status_code == 1) {
	            	status += '<span class="badge badge-success">'+response.user_details.status+'</span>';
	            } else if(response.user_details.status_code == 2) {
	            	status += '<span class="badge badge-warning">'+response.user_details.status+'</span>';
	            } else if(response.user_details.status_code == 5) {
	            	status += '<span class="badge badge-danger">'+response.user_details.status+'</span>';
	            }

	            var bank_added = '';
	            if(response.user_details.is_bank_added == 1) {
	            	bank_added += '<span class="badge badge-success">Available</span>';
	            } else {
	            	bank_added += '<span class="badge badge-danger">Not Available</span>';
	            }

	            $('#user_application_status').html(status);
	            $('#user_payment_status').html(bank_added);
	            $('#user_background_check_status').text(response.user_details.background_check_status);

				/* User Detail */

	            $('#user_firstname').val(response.user_details.first_name);
	            $('#user_lastname').val(response.user_details.last_name);
	            var phone = response.user_details.phone;
	            if(phone != null) {
            		phone = phone.slice(2);
            		$('#user_primary_phone').val(phone);
	            }
	            setTimeout(function(){
	            	$('#user_primary_phone').mask('(000) 000-0000');
				}, 3000);

	            $('#user_email_address').val(response.user_details.email);

	            $('#user_busines_name').val(response.user_details.business_name);
	            var address_line2 = "";
	            if(response.user_details.address_line2 != "") {
	                address_line2 = response.user_details.address_line2+','
	            }
	            var address = response.user_details.address_line1+', '+address_line2+response.user_details.city+', '+response.user_details.state+' '+response.user_details.zip_code;
	            $('#user_primary_contact_address').val(address);

	            // call credit report check api to get uploaded file
	            //getUserBackCreditReportFile('BackgroundCheckReport');
	            //getUserBackCreditReportFile('CreditReport');

	            if(response.user_details.user_service_name == t_role) {
	        		$("a[href='#u-billing']").parent('li').addClass('add_opacity');
	        		$("a[href='#u-background']").parent('li').addClass('add_opacity');
	        		$("a[href='#u-credit']").parent('li').addClass('add_opacity');
	        	}

	    		// Get Floating Action Button (Approve and Reject) | DO not display button for already approved user
	    		if(response.user_details.status_code == 2 || response.user_details.status_code == 5) {
					getFloatingActionButton('Application', '#u-overview');
				}
	        } else {
	            display_error_message(response);
	        }
        }
    });
}

window.deleteUser = function(user_id) {
	if(user_id > 0){
		$.ajax({
			method : 'POST',
			url : api_url+"users/delete-user",
			data : 'user_id='+user_id,
			beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
				display_expense_message(response);
	        	if(response.response_data.success == true) {
	        		getPendingUserList('card_layout');
	            }
	        }
		})
	}
}

window.deleteUserBackCreditReportFile = function(id,module_name) {
	if(id > 0) {
		var user_id = user.user_details.user_id;
        $.ajax({
            method: 'POST',
            url: api_url+"user-attachments/delete",
            data: 'id='+id+'&user_id='+user_id,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
        }).then(function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
               if(module_name == 'Background Check Report') {
                    $('#background_check_dropzone').show();
                    $('#backgroundCollapse').find('.footer-content').html('Upload report file by dragging & dropping or selecting');
                    $('#background_check_preview').html('');
                    $('#backgroundAccordion').find('.card-header > .accordion_arrow').remove('accordion_arrow');
                } else if(module_name == 'Credit Report') {
                    $('#credit_check_dropzone').show();
                    $('#creditCollapse').find('.footer-content').html('Upload report file by dragging & dropping or selecting');
                    $('#credit_check_preview').html('');
                    $('#creditAccordion').find('.card-header > .accordion_arrow').remove();
                }
            }
        }, function() {
            // hang on this step if the error occur
        });
    } else {
        alert('File not found');
    }
}

window.getUserBackCreditReportFile = function(module_name) {
    var selected_user = $('#selected_user_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"users/reports",
        data: 'user_id='+selected_user+'&module_name='+module_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	for (var key in response.report_details) {
	                if (response.report_details.hasOwnProperty(key)) {
	                	var kebab_item = '';
		                if(module_name == 'BackgroundCheckReport') {
		                    $('#background_check_dropzone').hide();
		                    $('#background_check_preview').html('<iframe src="'+response.report_details[0].aws_url+'" width="100%" height="500px" frameborder="0"></iframe>');
		                } else if(module_name == 'CreditReport') {
		                    $('#credit_check_dropzone').hide();
		                    $('#credit_check_preview').html('<iframe src="'+response.report_details[0].aws_url+'" width="100%" height="500px" frameborder="0"></iframe>');
		                }

		                kebab_item += '<a data-id="'+response.report_details[0].id+'" data-module="'+module_name+'" class="dropdown-item delete_report_file" href="javascript:void(0)">Delete</a>';
			            var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-0 mt-1 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';

		                if(response.report_details != null && module_name == 'BackgroundCheckReport') {
			                $('#backgroundAccordion').find('.card-header').append('<span class="accordion_arrow">'+kebab+' </span>');
			            }

			            if(response.report_details != null && module_name == 'CreditReport') {
			                $('#creditAccordion').find('.card-header').append('<span class="accordion_arrow">'+kebab+' </span>');
			            }
		            }
		        }
            } else {
                Snackbar.show({
                    text: response.response_data.message,
                    pos: 'bottom-left'
                });
            }
        }
    })
}

window.loadBankAccountType = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"payment-details/get-account-type",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>Select Account Type</option>";
                $.each(response.accountType, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.skipPaymentDetail = function(user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"users/skip-payment-details",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
				$('#headingTwo span.accordion_arrow').remove();
				$('#headingTwo span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
				$('#bank_flag').val(1);
				var message_html = '<h3 class="text-center mt-6 green">You Have Skipped This Step.</h3>';
				$('.payment_detail_media').html(message_html);
				$('.payment_detail_media ').addClass('minHeight100');

				var user = JSON.parse(localStorage.user);
				user.user_details.cheklist_seq_no = 2;
				if(user_service_name == po_role){
					user.user_details.steps_left = 4;
					$('#property_detail_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
					$('#property_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
				} else {
					user.user_details.steps_left = 1;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
					// loadCompletedChecklistSteps(user_id, t_role);
					loadDashboardPage();

					// if(signature_enabled == 'zoho_template'){
					// 	$('#zoho_lease_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock').removeClass('d-none');
					// } else {
					// 	setTimeout(function(){
					// 		var property_id = $('#tenant_property_id').val();
					// 		if(property_id != 0){
					// 			if(signature_enabled == 'docusign'){
					// 				getContracts(property_id);
					// 			} else if(signature_enabled == 'zoho_document'){
					// 				getZohoDocuments(property_id);
					// 			}
					// 		}
					// 	}, 500);
					// }

					// $('#headingTexasLease span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
				}
				// localStorage.setItem("user", JSON.stringify(user));  //put the object back
            }
        }
    });
}

window.checkKycDetailStatus = function(open_from = '') {
	$.ajax({
        method: 'POST',
        url: api_url+"users/details",
        data: 'user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
       			if(response.user_details.dwolla_customer_status == 3 || response.user_details.dwolla_customer_status == 4 || response.user_details.dwolla_customer_status == 5) {
					var message = '';
					if(response.user_details.dwolla_customer_status == 4) {
						message = 'We need additional details to complete your identity verification. Rent Synergy support team is notified and will contact and assist you in completing this process.';
					} else if(response.user_details.dwolla_customer_status == 5) {
						message = 'Could not complete the verification and your account is suspended. Rent Synergy support team is notified and will contact and assist you in completing this process.';
					}

					var response = {
		                "response_data": {
		                    "success": false,
		                    "message": message
		                }
		            };
		            display_error_message(response);
				} else {
					var modal1 = '';
					var container1 = '';
					if(open_from == 'profile') {
						modal1 = 'kycModal';
						container1 = 'kyc_form_container';
					} else {
						modal1 = 'paymentAddModal';
						container1 = 'payment_form_container';
					}

					$('#'+modal1).modal('show');
					openLoadingDialog($('#'+container1));
					$('#'+modal1+' #'+container1).load('components/bank/add_bank.html', function(){
						if(open_from == 'profile') {
							$('#kyc_form_container').find('.card-title').text('Update KYC');
							$('#hidden_input').val('profile');
						}
						$('.card-actions').removeClass('d-none');
						$('.skip_payment').addClass('d-none');
						$('#ssn').mask('0000');
						if(response.user_details.dwolla_customer_status == 1) {
							$('#kyc-detail-wizard').addClass('d-none');
							// $('#payment-detail-wizard').removeClass('d-none');
							$('#add_bank_methods').removeClass('d-none');
							// $('#'+container1).load('components/bank/add_bank_methods.html', function(){
							// 	$('#add_bank_container').val(container1);
							// });
						} else {
							if(response.user_details.dwolla_customer_status == 2) {
								$('#kyc_status').html('<span class="orange">Retry</span>');
								$('#ssn').mask('000-00-0000');
								$('#ssn_note_4').addClass('d-none');
			    				$('#ssn_note_9').removeClass('d-none');
			    			}
							$('#kyc-detail-wizard').removeClass('d-none');
						}
						setTimeout(function(){
							$('#dwolla_customer_status').val(response.user_details.dwolla_customer_status);
							getStateDropdown();
						}, 500);
					});
				}
            }
        }
    });
}

window.submitUnverifiedKycDetail = function(){
	$('.submit_unverified_customer').text('Processing...');
	$('.submit_unverified_customer').attr('disabled', true);
	var tz = jstz.determine();
	var timezone = tz.name();

	var first_name = $('#user_first_name').val();
	var last_name = $('#user_last_name').val();
	var email = $('#user_email_id').val();
	var first_time_signup = $('#first_time_signup').val();

	var data = 'user_id='+user.user_details.user_id+'&first_name='+first_name+'&last_name='+last_name+'&email='+email+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/submit-unverified-kyc-details",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
			$('.submit_unverified_customer').attr('disabled', false);
			$('.submit_unverified_customer').text('Submit');
			var dwolla_customer_status = response.response_data.dwolla_customer_status;
			var dwolla_customer_status_lable = response.response_data.dwolla_customer_status_lable;
			$('#dwolla_customer_status').val(dwolla_customer_status);
			var first_time_signup = $('#first_time_signup').val();
			// set dwolla customer status in localstorage
			var user = JSON.parse(localStorage.user);
			user.user_details.dwolla_customer_status = dwolla_customer_status;
			localStorage.setItem("user", JSON.stringify(user));  //put the object back

			if(response.response_data.success == true) {
				if(first_time_signup == 1){
					$('#tenant-kyc-detail-wizard').addClass('add_opacity');
					$('.submit_unverified_customer').attr('disabled', true);
					$('#headingOne span.accordion_arrow').remove();

					if(dwolla_customer_status == 0) {
						$('#kyc_status').html('<span class="green">'+humanize(dwolla_customer_status_lable)+'</span>');

						var user = JSON.parse(localStorage.user);
						// user.user_details.cheklist_seq_no = 5;
						user.user_details.cheklist_seq_no = 1;
						user.user_details.steps_left = 2;
						localStorage.setItem("user", JSON.stringify(user));

						var message_html = '';
						if(response.response_data.message != ''){
							message_html += '<p>'+response.response_data.message+'</p>';
						} else {
							message_html += '<h3 class="text-center mt-6 green">Successfully Submitted KYC Details.</h3>';
						}
						$('.tenant_kyc_detail_media').html(message_html);
						$('.tenant_kyc_detail_media').addClass('minHeight100');
						$('#kyc_flag').val(1);
						$('#headingOne span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#bank_details_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
						$('#bank_details_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
					}
				} else {
					closeRightSidebar();
					getMyAccountPaymentDetailWidget();
				}
			}
		}
	});
}

window.submitKycDetail = function() {

	$('.submit_kyc_detail').text('Processing...');
	$('.submit_kyc_detail').attr('disabled', true);
	var tz = jstz.determine();
	var timezone = tz.name();

	var first_name = $('#user_first_name').val();
	var last_name = $('#user_last_name').val();
	var email = $('#user_email_id').val();
	var address1 = $('#user_address_line1').val();
	var address2 = $('#user_address_line2').val();
	var city = $('#user_city').val();
	var state = $('#user_state').val();
	var zipcode = $('#user_zip_code').val();
	var country = $('#user_country').val();
	var ssn = $('#ssn').val();
	var date_of_birth = $('#kyc-detail-wizard input[name="date_of_birth"]').val();
	var first_time_signup = $('#first_time_signup').val();

	var data = 'user_id='+user.user_details.user_id+'&first_name='+first_name+'&last_name='+last_name+'&email='+email+'&address1='+address1+'&address2='+address2+'&city='+city+'&state='+state+'&zipcode='+zipcode+'&country='+country+'&ssn='+ssn+'&date_of_birth='+date_of_birth+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/submit-kyc-details",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	display_error_message(response);
    	$('.submit_kyc_detail').text('Agree & Continue');
    	var dwolla_customer_status = response.response_data.dwolla_customer_status;
    	var dwolla_customer_status_lable = response.response_data.dwolla_customer_status_lable;
    	$('#dwolla_customer_status').val(dwolla_customer_status);

		var first_time_signup = $('#first_time_signup').val();
    	var user = JSON.parse(localStorage.user);
   		user.user_details.dwolla_customer_status = dwolla_customer_status;
		localStorage.setItem("user", JSON.stringify(user));  //put the object back

    	if(response.response_data.success == true) {
    		var open_page = $('#hidden_input').val();
    		if(open_page != 'profile') {
    			//$('#payment-detail-wizard').removeClass('d-none');
    		}
    		$('#kyc-detail-wizard').addClass('add_opacity');
    		$('.submit_kyc_detail').attr('disabled', true);

    		if(dwolla_customer_status == 1) {
    			if(open_page == 'profile') {
    				$('#update_dwolla_kyc').remove(); // remove link from profile section
					closeRightSidebar();
					getMyAccountPaymentDetailWidget();
    			}

				$('#kyc_status').html('<span class="green">'+humanize(dwolla_customer_status_lable)+'</span>');
    			// Below code for initial signup step
				if(first_time_signup == 1) {
					$('#kyc-detail-wizard').addClass('d-none');
					$('#explore_accordion').trigger('hidden.bs.collapse');
					$('#newSignupModal').modal('hide');
					$('.modal-backdrop').remove();
					$('.kyc_status_message').addClass('d-none');
					var user = JSON.parse(localStorage.user);
					// user.user_details.cheklist_seq_no = 7;
					user.user_details.cheklist_seq_no = 1;
					user.user_details.steps_left = 5;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
					var message_html = '';
					if(response.response_data.message != ''){
						message_html += '<p>'+response.response_data.message+'</p>';
					} else {
						message_html += '<h3 class="text-center mt-6 green">Successfully Submitted KYC Details.</h3>';
					}
					$('.kyc_detail_media').html(message_html);
					$('.kyc_detail_media').addClass('minHeight100');
					$('#headingOne span.accordion_arrow').remove();
					$('#kyc_flag').val(1);
					$('#headingOne span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
					$('#bank_details_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
					$('#bank_details_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
					$('#headingOne .card-title .badge-danger').remove();
				}
    		} else if(dwolla_customer_status == 2) {
				if(first_time_signup == 1) {
					// $('#headingOne .card-title').append('<span class="badge badge-danger fs-12 mr-2 ml-2">Retry</span>');
					$('#headingOne .card-title span').html('Retry');
				}
    			$('#kyc-detail-wizard').removeClass('add_opacity');
    			$('#payment-detail-wizard').addClass('d-none');
    			$('.submit_kyc_detail').attr('disabled', false);
    			$('#ssn').val('');
    			$('#ssn').mask('000-00-0000');
    			$('#ssn_note_4').addClass('d-none');
    			$('#ssn_note_9').removeClass('d-none');
				$('#kyc-detail-wizard p').html("Our initial identity verification was unsuccessful. Please verify your details and, if necessary, provide your full 9-digit Social Security Number for a second attempt. Note that a subsequent failure may change your status to 'Document' or 'Suspended', at which point our Rent Synergy Support Team will assist you further in coordination with Dwolla.").addClass('red');
    		}
    	} else {
    		$('.submit_kyc_detail').attr('disabled', false);
    		if(dwolla_customer_status == 3) {
				if(first_time_signup == 1) {
					$('#headingOne .card-title span').html('KBA');
				}
    			$('#paymentAddModal').modal('hide');
    			$('#payment-detail-wizard').addClass('d-none');
    			$('#kyc_status').html('<span class="red">'+humanize(dwolla_customer_status_lable)+'</span>');
    		} else if(dwolla_customer_status == 4 || dwolla_customer_status == 5) {
				if(first_time_signup == 1) {
					if(dwolla_customer_status == 4){
						$('#headingOne .card-title span').html('Document');
						$('#addBankCollapse').remove();
						$('#kyc_document_form').removeClass('d-none');
					} else {
						$('#headingOne .card-title span').html('Suspended');
						$('.kyc_detail_media').html('<h4 class="text-center mt-4 grey">Your account has been temporarily suspended. We are in contact with our payment processor, Dwolla, to understand the details. Rest assured, we will notify you promptly if any additional information is required and will keep you updated on the status of your account.</h4>');
						$('.kyc_detail_media').addClass('minHeight100');
					}
				}
    			$('#kyc-detail-wizard').addClass('add_opacity');
    			$('#paymentAddModal').modal('hide');
    			$('#payment-detail-wizard').addClass('d-none');
    			$('#kyc_status').html('<span class="red">'+humanize(dwolla_customer_status_lable)+'</span>');
    		}
    	}
    });
}

window.createLinkToken = function() {
    $.post({
        url: api_url+'dwollas/create-link-token',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
                var link_token = response.data.link_token;
                // console.log(link_token);
                var linkHandler = Plaid.create({
                    //token: promise,
                    token: link_token,
                    onSuccess: function(public_token, metadata) {
                        // console.log('Public Token:' + public_token);
                        // console.log(metadata);
                        // Exchange public token to get access token
                        exchangePublicTokenForAccessToken(public_token, metadata);
                    },
                    onEvent: (eventName, metadata) => {
                        //console.log("Event:", eventName);
                        //console.log("Metadata:", metadata);
                    },
                    onExit: (error, metadata) => {
                        // console.log(error, metadata);
						$('.open_plaid_modal').attr('disabled', false);
						$('.open_plaid_modal').text('Continue');
						$('.skip_payment').attr('disabled', false);
                    },
                });
                linkHandler.open();
            }
        }
    });
}

window.exchangePublicTokenForAccessToken = function(public_token, metadata) {
    var account_id = metadata.accounts[0].id;
    $.post({
        url: api_url+'dwollas/exchange-public-token',
        data:"public_token="+public_token,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
                var access_token = response.data.access_token;
                var item_id = response.data.item_id;
                var request_id = response.data.request_id;
                // console.log('Access Token:'+ access_token);
                // exchange access token for processor token
                exchangeAccessTokenForProcessorToken(access_token, account_id, metadata)
            } else {
				$('.open_plaid_modal').attr('disabled', false);
				$('.open_plaid_modal').text('Continue');
				$('.skip_payment').attr('disabled', false);
			}
        }
    });
}

window.exchangeAccessTokenForProcessorToken = function(access_token, account_id, metadata) {
    $.post({
        url: api_url+'dwollas/exchange-access-token',
        data:"access_token="+access_token+'&account_id='+account_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
				var funding_source_name = metadata.institution.name;
				var account_number = metadata.account.mask;
                var plaid_account_id = metadata.account_id;
                var account_type = metadata.account.subtype;
                var processor_token = response.data.processor_token;
                var request_id = response.data.request_id;
                // console.log('Processor Token:'+ processor_token);
                // Create funding source
                createFundingSource(processor_token, funding_source_name, account_type, account_number, plaid_account_id, access_token);
            } else {
				$('.open_plaid_modal').attr('disabled', false);
				$('.open_plaid_modal').text('Continue');
				$('.skip_payment').attr('disabled', false);
			}
        }
    });
}

window.createFundingSource = function(processor_token, funding_source_name, account_type, account_number, plaid_account_id, access_token) {
	var first_time_signup = $('#first_time_signup').val();
	var user_id = user.user_details.user_id;
    $.post({
        url: api_url+'dwollas/create-funding-source',
        data:"user_id="+user_id+"&processor_token="+processor_token+'&funding_source_name='+funding_source_name+'&account_number='+account_number+'&plaid_account_id='+plaid_account_id+'&account_type='+account_type+'&first_time_signup='+first_time_signup+'&access_token='+access_token,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			display_error_message(response);
            if(response.response_data.success == true) {
                //console.log('Funding source id : '+response.funding_source_id);
                var funding_source_id = response.funding_source_id;
                var first_time_signup = $('#first_time_signup').val();
                if(first_time_signup == 1) {
					$('#headingTwo span.accordion_arrow').remove();
					$('#headingTwo span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
					$('#bank_flag').val(1);
					var message_html = '<h3 class="text-center mt-3 green">Bank Added Successfully.</h3>';
					$('.payment_detail_media').html(message_html);
					$('.payment_detail_media ').addClass('minHeight100');

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 2;
					if(user_service_name == po_role){
						user.user_details.steps_left = 4;
						$('#property_detail_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
						$('#property_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
					} else {
						user.user_details.steps_left = 1;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
						// loadCompletedChecklistSteps(user_id, t_role);
						loadDashboardPage();

						// if(signature_enabled == 'zoho_template'){
						// 	$('#zoho_lease_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock').removeClass('d-none');
						// 	$('#headingZohoLease span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
						// } else {
						// 	setTimeout(function(){
						// 		var property_id = $('#tenant_property_id').val();
						// 		if(property_id != 0){
						// 			if(signature_enabled == 'docusign'){
						// 				getContracts(property_id);
						// 			} else if(signature_enabled == 'zoho_document'){
						// 				getZohoDocuments(property_id);
						// 			}
						// 		}
						// 	}, 500);
						// }
					}
					// localStorage.setItem("user", JSON.stringify(user));  //put the object back
				} else {
					$('#paymentAddModal').modal('hide');
					getMyAccountPaymentDetailWidget();
					closeRightSidebar();
				}
            } else {
				$('.open_plaid_modal').attr('disabled', false);
				$('.open_plaid_modal').text('Continue');
				$('.skip_payment').attr('disabled', false);
			}
        }
    });
}

window.savePaymentDetail = function() {
	$('.save_payment_detail').attr('disabled', true);
	var tz = jstz.determine();
	var timezone = tz.name();
	var payment_id = $('#payment_id').val();
	var bank_name = $('#bank_name').val();
	var account_type = $('#account_type').val();
	var routing_number = $('#routing_number').val();
	var account_number = $('#account_number').val();
	var bank_term_condition = 0;
	if($('#electronic_checkbox').is(":checked")) {
		bank_term_condition = 1;
	}
	var first_time_signup = $('#first_time_signup').val();

	var data = 'user_id='+user.user_details.user_id+'&payment_id='+payment_id+'&bank_name='+bank_name+'&account_type='+account_type+'&routing_number='+routing_number+'&account_number='+account_number+'&bank_term_condition='+bank_term_condition+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/add-payment-details",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	display_error_message(response);
    	if(response.response_data.success == true) {
    		var payment_open_from = $('#payment_open_from').val();
    		if(typeof payment_open_from === 'undefined') {
    			// Below code for initial signup step
    			var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {
					$('#newSignupModal').modal('hide');
					$('.modal-backdrop').remove();
					$('#headingTwo span.accordion_arrow').remove();

					$.ajax({
						method: 'POST',
						url: api_url+"payment-details/user-banks",
						data:'user_id='+user_id,
						beforeSend: function(xhr) {
							xhr.setRequestHeader('Authorization', accessToken);
						},
						success: function(response) {
							if(response.response_data.success == true) {
								if(response.userBanks[0].status == 2){
									$('#headingTwo span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
								} else{
									$('#headingTwo .card-title').append('<span class="badge badge-danger fs-12 mr-2 ml-2">'+response.userBanks[0].status_label+'</span>');
									$('#headingTwo span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
								}
							}
						}
					});

					$('#bank_flag').val(1);
					var message_html = '<h3 class="text-center mt-3 green">Bank Added Successfully.</h3>';
					$('.payment_detail_media').html(message_html);

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 2;
					if(user_service_name == po_role){
						user.user_details.steps_left = 4;
						$('#property_detail_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
						$('#property_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
					} else {
						user.user_details.steps_left = 1;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
						// loadCompletedChecklistSteps(user_id, t_role);
						loadDashboardPage();
						// if(user.user_details.cheklist_seq_no == 3){
						// 	loadCompletedChecklistSteps(user_id, t_role);
						// } else {
						// 	setTimeout(function(){
						// 		var property_id = $('#tenant_property_id').val();
						// 		if(property_id != 0){
						// 			getSignupZohoTemplateList(property_id);
						// 		}
						// 	}, 500);
						// }
					}
				}
    		} else {
    			if(payment_open_from == "myaccount") {
    				$('#paymentAddModal').modal('hide');
    				//openLoadingDialog($('#payment_data_container'));
	    			//$('#payment_data_container').load('components/myaccount/payment_detail_table.html');
	    			getMyAccountPaymentDetailWidget();
	    			closeRightSidebar();
	    		}
    		}
    	} else {
    		$('.save_payment_detail').attr('disabled', false);
    	}
    });
}

/* Start : Update unverified to verified customer */

window.updateDwollaCustomer = function() {

	$('.update_dwolla_customer').text('Processing...');
	$('.update_dwolla_customer').attr('disabled', true);

	var first_name = $('#customer_first_name').val();
	var last_name = $('#customer_last_name').val();
	var email = $('#customer_email_id').val();
	var address1 = $('#customer_address_line1').val();
	var address2 = $('#customer_address_line2').val();
	var city = $('#customer_city').val();
	var state = $('#customer_state').val();
	var zipcode = $('#customer_zip_code').val();
	var country = $('#customer_country').val();
	var ssn = $('#customer_ssn').val();
	var date_of_birth = $('#kyc-detail-wizard input[name="customer_date_of_birth"]').val();

	var data = 'user_id='+user.user_details.user_id+'&first_name='+first_name+'&last_name='+last_name+'&email='+email+'&address1='+address1+'&address2='+address2+'&city='+city+'&state='+state+'&zipcode='+zipcode+'&country='+country+'&ssn='+ssn+'&date_of_birth='+date_of_birth;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/update-dwolla-customer",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		$('#updateCustomerModal').modal('hide');
	    	} else {
	    		$('.update_dwolla_customer').attr('disabled', false);
	    		$('.update_dwolla_customer').text('Update Customer');
	    	}
	    }
	});
}

window.getZohoSignUrl = function(){
	var user_id = user.user_details.user_id;
	$.ajax({
		method: 'POST',
		url: api_url+"user-templates/get-zoho-sign-in-link",
		data: 'user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(response.response_data.success == true) {
				if($('#doc_signing_flag').val() == 0){
					var sign_url = response.sign_url.sign_url;
					$('.zoho_lease_media').html('<iframe src="'+sign_url+'" width="100%" height="600" frameborder="0">');
					$('#doc_signing_flag').val(1);
				}
			} else {
				$('#doc_signing_flag').val(1);
				clearInterval($('#interval_id').val());
				if(response.isTempSigned == 1) {
					var message_html = '<h3 class="text-center mt-6 green">'+response.response_data.message+'</h3>';
					$('.zoho_lease_media').html(message_html);
					$('.zoho_lease_media').addClass('minHeight100');

					$('#headingZohoLease span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
					$('#headingZohoLease span.accordion_arrow').remove();
					var user = JSON.parse(localStorage.user);
					user.user_details.steps_left = 0;
					user.user_details.cheklist_seq_no = 3;
					localStorage.setItem("user", JSON.stringify(user));
					setTimeout(function() {
						$('#main_container').load('components/onboarding_completed.html');
					}, 1000);
				} else {
					if(response.isTempSigned == 0) {
						var message_html = '<h3 class="text-center mt-6 grey">'+response.response_data.message+'</h3>';
						$('.zoho_lease_media').html(message_html);
						$('.zoho_lease_media').addClass('minHeight100');
					} else {
						display_error_message(response);
					}
				}
			}
		}
	});
}

/* End : Update unverified to verified customer */

window.downloadZohoDocument = function(filename, type, request_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/download-pdf",
        data: 'request_id='+request_id+'&type='+type+'&user_id='+user_id,
        dataType: 'text',
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
    		const linkSource = `data:application/pdf;base64,${response}`;
			const downloadLink = document.createElement("a");
			const fileName = filename+".pdf";
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
			$('.download_zoho_document').removeClass('add_opacity');
	    }
	});
}

window.remindZohoTemplate = function(request_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"user-templates/remind-document",
		data : 'request_id='+request_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			$('.remind_zoho_template').attr('disabled', false);
			$('.remind_zoho_template').text('Send Reminder');
		}
	});
}

window.checkZohoDocumentStatus = function(user_id) {
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/get-template-sign-status",
        data: 'user_id='+user_id+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	if(user_service_name == t_role) {
					// Checkilist step 7
					getSignupNextStep(7,8);

					var user = JSON.parse(localStorage.user);
			   		user.user_details.cheklist_seq_no = 7;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back

					openLoadingDialog($('#signup_data_container'));
					var user_id = user.user_details.user_id;
					var property_id = 0;
					var lease_id = 0;
					getFMPaymentDetail(user_id,property_id,lease_id);
				}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.verify_micro_deposit = function(payment_detail_id,micro_deposit_1,micro_deposit_2) {
	$('.verify_micro_deposit').attr('disabled', true);
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/verify-micro-deposit",
        data: 'user_id='+user_id+'&payment_id='+payment_detail_id+'&amount1='+micro_deposit_1+'&amount2='+micro_deposit_2,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	var payment_open_from = $('#payment_open_from').val();
    			if(typeof payment_open_from === 'undefined') {
	            	$('#bank_card_'+payment_detail_id).remove();
	            	$('#microDepositModal').modal('hide');
	                $('#micro_deposit_form_container').html('');
	            } else {
	            	if(payment_open_from == "myaccount") {
	    				$('#microDepositModal').modal('hide');
	    				//openLoadingDialog($('#payment_data_container'));
		    			//$('#payment_data_container').load('components/myaccount/payment_detail_table.html');
		    			getMyAccountPaymentDetailWidget();
		    		}
	            }
            } else {
            	$('.verify_micro_deposit').attr('disabled', false);
            }
        }
    });
}

/* Start : First Month Payment Code */
window.getReviewFirstMonthPaymentDetail = function(fmUnauthorizedPayments) {
	var notification_html = "";
	if(Object.keys(fmUnauthorizedPayments.arrFMP).length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/authorization.svg" class="filter-15" style="width: 23px;"/></span>Authorize First Month Rent</div>';
			notification_html += '</div>';
		notification_html += '</div>';

		for (var key in fmUnauthorizedPayments.arrFMP) {
			var name = stripHtml(fmUnauthorizedPayments.arrFMP[key].property_name);
			if (name.length > 18) {
				var shortname = name.substring(0, 18) + " ...";
				name = shortname;
			}

			var add_opacity = '';
			var exclamation = '';
			var dwolla_status_array = ['retry', 'kba', 'document', 'suspended'];
			if(dwolla_status_array.includes(fmUnauthorizedPayments.response_data.dwolla_customer_status)){
				//var auth_msg = 'Authorize Payment button is not <br> enabled since your account is in <br>'+ fmUnauthorizedPayments.response_data.dwolla_customer_status +' status';
				var auth_msg = 'Authorize Payment button is not enabled since your account is in '+ fmUnauthorizedPayments.response_data.dwolla_customer_status +' status';
				add_opacity = 'add_opacity';
				//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			}

			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="first_month_payment_card_'+key+'" class="notifcation-group-item list-group-item review_first_payment_section no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						notification_html += '<div class="col-12">';
							notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
								notification_html += '<tbody>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Type</td>';
										notification_html += '<td class="text-right bold-black">First Month Rent</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Property Name</td>';
										notification_html += '<td class="text-right bold-black">'+fmUnauthorizedPayments.arrFMP[key].property_name+'</td>';
									notification_html += '</tr>';
									var payment_due_date = '';
									for (var lkey in fmUnauthorizedPayments.arrFMP[key]) {
										if (fmUnauthorizedPayments.arrFMP[key].hasOwnProperty(lkey)) {
											if(lkey != 'totalSum' && lkey != 'property_name' && lkey != 'property_id' && lkey != 'allow_rent_collection') {

												if(fmUnauthorizedPayments.arrFMP[key][lkey].payment_type_lable == 'Rent') {
													payment_due_date = fmUnauthorizedPayments.arrFMP[key][lkey].payment_due_date;
													notification_html += '<tr>';
														notification_html += '<td class="grey">Payment Due Date</td>';
														notification_html += '<td class="text-right bold-black">'+payment_due_date+'</td>';
													notification_html += '</tr>';
												}

												var payment_type_lable = fmUnauthorizedPayments.arrFMP[key][lkey].payment_type_lable;
												var formated_total = fmUnauthorizedPayments.arrFMP[key][lkey].formated_total;
												notification_html += '<tr>';
													notification_html += '<td class="grey">'+payment_type_lable+'</td>';
													notification_html += '<td class="text-right bold-black">$'+formated_total+'</td>';
												notification_html += '</tr>';
											}
										}
									}
									notification_html += '<tr>';
										notification_html += '<td class="bold-grey fs-16 pt-3">Total Amount Due</td>';
										notification_html += '<td class="text-right bold-black fs-16 pt-3"><strong>$'+fmUnauthorizedPayments.arrFMP[key].totalSum+'</strong></td>';
									notification_html += '</tr>';
								notification_html += '</tbody>';
							notification_html += '</table>';

							if(fmUnauthorizedPayments.arrFMP[key].allow_rent_collection == 0){
								notification_html += '<button data-property-id='+fmUnauthorizedPayments.arrFMP[key].property_id+' data-lease-id='+key+' type="button" class="btn btn-orange mt-3 w-100 authorize_fm_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#reviewFirstMonthPaymentModal" data-backdrop="static" data-keyboard="false">Review Payment</button>'+exclamation;
							} else{
								notification_html += '<button data-property-id='+fmUnauthorizedPayments.arrFMP[key].property_id+' data-lease-id='+key+' type="button" class="btn btn-orange mt-3 w-100 authorize_fm_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#reviewFirstMonthPaymentModal" data-backdrop="static" data-keyboard="false">Authorize Payment</button>'+exclamation;
							}

							if(auth_msg) {
								notification_html += '<div class="mt-3"><small class="bold-black">Note : </small><small class="grey">'+auth_msg+'</small></div>';
							}

						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

window.getReviewAllPaymentDetail = function(psUnauthorizedPayments) {
	var notification_html = "";
	if(Object.keys(psUnauthorizedPayments.paymentScheduleData).length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/authorization.svg" class="filter-15" style="width: 23px;"/></span>Authorize Payment Schedule</div>';
			notification_html += '</div>';
		notification_html += '</div>';

		for (var key in psUnauthorizedPayments.paymentScheduleData) {
			var name = stripHtml(psUnauthorizedPayments.paymentScheduleData[key].property.name);
			var address = '';
			if(psUnauthorizedPayments.paymentScheduleData[key].property != null && psUnauthorizedPayments.paymentScheduleData[key].property.address != null) {
				address = psUnauthorizedPayments.paymentScheduleData[key].property.address.address_line1+', '+psUnauthorizedPayments.paymentScheduleData[key].property.address.address_line2+'<br>'+psUnauthorizedPayments.paymentScheduleData[key].property.address.city+', '+psUnauthorizedPayments.paymentScheduleData[key].property.address.state+' '+psUnauthorizedPayments.paymentScheduleData[key].property.address.zip_code;
			}

			var add_opacity = '';
			var exclamation = '';
			if(psUnauthorizedPayments.paymentScheduleData[key].is_disabled == 1) {
				//var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
				var auth_msg = 'Authorize Payment button is not enabled since the previous month payment is still pending for authorization.';
				add_opacity = 'add_opacity';
				//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			}
			var dwolla_status_array = ['retry', 'kba', 'document', 'suspended'];
			if(dwolla_status_array.includes(psUnauthorizedPayments.dwolla_customer_status)){
				//var auth_msg = 'Authorize Payment button is not <br> enabled since your account is in <br>'+ psUnauthorizedPayments.dwolla_customer_status+' status';
				var auth_msg = 'Authorize Payment button is not enabled since your account is in '+ psUnauthorizedPayments.dwolla_customer_status+' status';
				add_opacity = 'add_opacity';
				//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			}

			if(key > 0 && psUnauthorizedPayments.paymentScheduleData[Number(key-1)] && psUnauthorizedPayments.paymentScheduleData[Number(key-1)].property_id == psUnauthorizedPayments.paymentScheduleData[key].property_id){
				add_opacity = 'add_opacity';
			}

			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="payment_schedule_card_'+psUnauthorizedPayments.paymentScheduleData[key].id+'" class="notifcation-group-item list-group-item review_payment_schedule_section no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						notification_html += '<div class="col-12">';
							notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
								notification_html += '<tbody>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Type</td>';
										notification_html += '<td class="text-right bold-black">'+psUnauthorizedPayments.paymentScheduleData[key].payment_type.name+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Property Name</td>';
										notification_html += '<td class="text-right bold-black">'+name+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Payment Due Date</td>';
										notification_html += '<td class="text-right bold-black">'+psUnauthorizedPayments.paymentScheduleData[key].due_date+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="bold-grey fs-16 pt-3">Total Amount Due</td>';
										notification_html += '<td class="text-right bold-black fs-16 pt-3"><strong>$'+psUnauthorizedPayments.paymentScheduleData[key].total+'</strong></td>';
									notification_html += '</tr>';
								notification_html += '</tbody>';
							notification_html += '</table>';

							if(psUnauthorizedPayments.paymentScheduleData[key].lease.allow_rent_collection == 0){
								notification_html += '<button data-property-id='+psUnauthorizedPayments.paymentScheduleData[key].property_id+' data-lease-id='+psUnauthorizedPayments.paymentScheduleData[key].lease_id+' type="button" data-id="'+psUnauthorizedPayments.paymentScheduleData[key].id+'" data-from="notification" class="w-100 btn btn-orange mt-2 authorize_payment_schedule '+add_opacity+'" data-toggle="modal" data-target="#authorizePaymentModal" data-backdrop="static" data-keyboard="false" data-title="Review Payment">Review Payment</button>'+exclamation;
							} else {
								notification_html += '<button data-property-id='+psUnauthorizedPayments.paymentScheduleData[key].property_id+' data-lease-id='+psUnauthorizedPayments.paymentScheduleData[key].lease_id+' type="button" data-id="'+psUnauthorizedPayments.paymentScheduleData[key].id+'" data-from="notification" class="w-100 btn btn-orange mt-2 authorize_payment_schedule '+add_opacity+'" data-toggle="modal" data-target="#authorizePaymentModal" data-backdrop="static" data-keyboard="false" data-title="Authorize Payment">Authorize Payment</button>'+exclamation;
							}

							if(auth_msg) {
								notification_html += '<div class="mt-3"><small class="bold-black">Note : </small><small class="grey">'+auth_msg+'</small></div>';
							}
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

window.getFMPaymentDetail = function(user_id = 0, property_id = 0, lease_id = 0) {
	var tz = jstz.determine();
	var timezone = tz.name();
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/get-unauthorized-payments",
        data: 'user_id='+user_id+'&property_id='+property_id+'&lease_id='+lease_id+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var cls = '';
            	if(response.isPaymentAuthorized == 1 || response.isAllowedToAuthorized == 0) {
            		cls = 'add_opacity';
            	}

            	var bank_html = "<option value=''>Select Payment Method</option>";
                for (var key in response.paymentDetails) {
			        if (response.paymentDetails.hasOwnProperty(key)) {
			        	bank_html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+'</option>';
					}
				}

            	var html = '';
            	if(first_time_signup != 1) {
            		html += '<input type="hidden" id="selected_lease_id" value="'+lease_id+'">';
            	}

            	html += '<input type="hidden" id="is_payment_authorized" value="'+response.isPaymentAuthorized+'">';
            	html += '<input type="hidden" id="is_allowed_to_authorized" value="'+response.isAllowedToAuthorized+'">';
            	if(response.isPaymentAuthorized == 1 || response.isAllowedToAuthorized == 0) {
            		html += '<div id="msg_div" class="text-center" style="height:400px;"><h3 class="green">'+response.response_data.message+'</h3></div>';
            	}
				var allow_rent_collection = 1;
            	html += '<form id="review-first-month-payment-wizard" class="wizard-primary create-account-form '+cls+'" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	for (var key in response.arrFMP) {
		            		if (response.arrFMP.hasOwnProperty(key)) {
						    	for (var lkey in response.arrFMP[key]) {
						            if (response.arrFMP[key].hasOwnProperty(lkey)) {
						            	if(lkey != 'totalSum' && lkey != 'property_name' && lkey != 'property_id' && lkey != 'allow_rent_collection') {
											allow_rent_collection = response.arrFMP[key].allow_rent_collection;
						            		var line = '(One time payment)';
						            		if(response.arrFMP[key][lkey].payment_type_lable == 'Rent') {
						            			line = '';
						            			response.arrFMP[key][lkey].payment_type_lable = 'First Month Rent';
						            		}

											html += '<input type="hidden" name="first_month_payment_id[]" id="first_month_payment_'+lkey+'" class="first_month_payment_hidden_input" value="'+response.arrFMP[key][lkey].id+'">';

									        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
									            html += '<div class="border-bottom">';
												if(response.arrFMP[key][lkey].payment_type == 7 && response.arrFMP[key][lkey].is_partial_payment == 1){
									                html += '<h5 class="card-title"><span class="fs-22">'+response.arrFMP[key][lkey].payment_type_lable+'</span> <div class="badge badge-primary ml-2">PARTIAL PAYMENT</div></h5>';
												} else{
									                html += '<h5 class="card-title"><span class="fs-22">'+response.arrFMP[key][lkey].payment_type_lable+'</span> '+line+'</h5>';
												}
									            html += '</div>';
									            html += '<div class="rent_collection_data mt-2">';
									                html += '<div class="row">';
									                    html += '<div class="col-12 col-md-3 col-xl-3">';
									                        html += '<div class="mt-2">';
									                            html += '<div class="black mb-2"><strong>Due Date</strong></div>';
									                            html += '<div><strong>'+response.arrFMP[key][lkey].payment_due_date+'</strong></div>';
									                        html += '</div>';
									                    html += '</div>';

									                    var disabled = '';
									                    if(response.arrFMP[key][lkey].total <= 0) {
									                    	disabled = 'add_opacity';
									                    }

									                    html += '<div class="col-12 col-md-5 col-xl-6">';
														if(allow_rent_collection != 0){
									                        html += '<div class="mt-2">';
									                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
									                            html += '<div><select name="fm_payment_detail_id[]" id="fm_payment_detail_'+lkey+'" class="bank_dd form-control '+disabled+'">'+bank_html+'</select></div>';
									                        html += '</div>';
														}
									                    html += '</div>';
									                    html += '<div class="col-12 col-md-4 col-xl-3 text-right">';
									                        html += '<div class="mt-2">';
									                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
									                            html += '<div class="black fs-18"><strong>$ '+response.arrFMP[key][lkey].formated_total+'</strong></div>';
									                        html += '</div>';
									                    html += '</div>';

														if(response.arrFMP[key][lkey].payment_type == 7){
															if(response.arrFMP[key][lkey].is_partial_payment == 1 && response.arrFMP[key][lkey].lease.prorate_enabled == 1 && response.arrFMP[key][lkey].lease.prorated_amount != 0){
																html += '<div class="col-12 col-md-12 col-xl-12 mt-3">';
																		html += 'Total rent amount due is $'+currencyFormat(Number(response.arrFMP[key][lkey].total))+'. The above amount is the partial payment of $'+response.arrFMP[key][lkey].partial_amount+' and includes prorated rent from '+response.arrFMP[key][lkey].lease.prorate_start_date+' to '+response.arrFMP[key][lkey].lease.prorate_end_date+', totaling $'+currencyFormat(Number(response.arrFMP[key][lkey].lease.prorated_amount))+'.';
																html += '</div>';
															} else{
																if(response.arrFMP[key][lkey].lease.prorate_enabled == 1 && response.arrFMP[key][lkey].lease.prorated_amount != 0){
																	html += '<div class="col-12 col-md-12 col-xl-12 mt-3">';
																			html += 'The above amount includes prorated rent from '+response.arrFMP[key][lkey].lease.prorate_start_date+' to '+response.arrFMP[key][lkey].lease.prorate_end_date+', totaling $'+currencyFormat(Number(response.arrFMP[key][lkey].lease.prorated_amount))+'.';
																	html += '</div>';
																}
															}
														}

									                html += '</div>';
									            html += '</div>';
									        html += '</div>';
									    }
								    }
								}

						        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
						            html += '<div class="rent_collection_data">';
						                html += '<div class="row">';
						                    html += '<div class="col-12 col-md-6 col-xl-9">';
						                        html += '<h5 class="card-title"><span class="fs-22">Total</h5>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
						                        html += '<div class="black fs-18"><strong>$ '+response.arrFMP[key].totalSum+'</strong></div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
								if(allow_rent_collection != 0){
									html += '<div class="col-auto d-sm-block mb-2 mt-4">';
										html += '<div class="first_month_payment_checkbox">';
											html += '<label class="custom-control custom-checkbox m-0">';
											html += '<input name="first_month_payment_checkbox" id="fm_payment_authorize_checkbox" type="checkbox" class="custom-control-input">';
											html += '<span class="custom-control-label">'+authorize_text+'</span>';
											html += '</label>';
										html += '</div>';
									html += '</div>';
								}
						    }
						}
				    html += '</div>';
				html += '</form>';

				if(first_time_signup == 1) {
					$('#signup_data_container').html(html);
					if(response.isPaymentAuthorized == 1 || response.isAllowedToAuthorized == 0) {
						$('#signup_data_container').append('<div id="brd-top" class="border-top"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange authorize_first_month_payment">Next</button></div></div></div>');
					} else {
						$('#signup_data_container').append('<div id="brd-top" class="border-top"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange authorize_first_month_payment" disabled="">Agree & Authorize</button></div></div></div>');
					}
				} else {
					$('#review_first_month_payment_container').html(html);
				}

				if(allow_rent_collection == 0){
					// $('#reviewFirstMonthPaymentModal .card-tile').html('Review');
					$('.review_title').html('Review');
					$('#reviewFirstMonthPaymentModal .border-top').remove();
				}

				if(response.isPaymentAuthorized == 0 || response.isAllowedToAuthorized == 1) {
					addFMPaymentValidation();
				}

				// If data not available do not display button
				if(response.isPaymentAuthorized == 0 && response.isAllowedToAuthorized == 0) {
					$('#msg_div').addClass('mt-5 pt-5');
					$('#brd-top').removeClass('border-top');
					$('.authorize_first_month_payment').addClass('d-none');
				} else {
					$('#msg_div').removeClass('mt-5 pt-5');
					$('#brd-top').addClass('border-top');
					$('.authorize_first_month_payment').removeClass('d-none');
				}
            }
        }
    });
}

window.authorizeFirstMonthPaymentData = function(paymentArray) {
	var is_payment_authorized = $('#is_payment_authorized').val();
	var first_time_signup = $('#first_time_signup').val();
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/authorized-payments",
        data: 'payments='+paymentArray+'&user_id='+user.user_details.user_id+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup+'&isPaymentAuthorized='+is_payment_authorized,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	$('.authorize_first_month_payment').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
            	if(first_time_signup == 1) {
            		var user = JSON.parse(localStorage.user);
			   		user.user_details.cheklist_seq_no = 8;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
            		window.location.href = site_url+'dashboard.html?action=properties';
            	} else {
	            	$('#reviewFirstMonthPaymentModal').modal('hide');
	            	// var lease_id = $('#selected_lease_id').val();
	            	// $('#first_month_payment_card_'+lease_id).remove();
	            	// var notification = parseInt($('#notification_count').text());
					// var total_notification = notification - 1;
					// $('#notification_indicator, #notification_count').text(total_notification);
					getNotificationData();
	            }
            }
        }
    });
}

var $fmPaymentForm;
window.addFMPaymentValidation = function() {
	$fmPaymentForm = $("#review-first-month-payment-wizard");
	$fmPaymentForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "fm_payment_detail_id[]": { required: true }
	    },
	    messages: {
	        "fm_payment_detail_id[]": { required: '' }
	    }
	});
}

/* Start : Rent Collection Code */

var $refundSDForm;
window.addSecurityDepositRefundValidation = function() {
	$refundSDForm = $("#refund_security_deposit_form");
	$refundSDForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "security_refunded_amount": { required: true, notEqual: '0.00' },
	        "refund_description": { required: true },
	        "refund_payment_method_id": { required: true },
	    },
	    messages: {
	        "security_refunded_amount": { required: '', notEqual:"" },
	        "refund_description": { required: '' },
	        "refund_payment_method_id": { required: '' },
	    }
	});
}

$.validator.addMethod('notEqual', function (value, el, param) {
	return parseInt(value) > parseInt(param);
});

window.getUserPaymentMethod = function(user_ids, payment_method_id) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:'user_id='+user_ids,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
        		html += "<option value=''>Select Payment Method</option>";
            	for (var key in response.userBanks) {
	                if (response.userBanks.hasOwnProperty(key)) {
                		if(response.userBanks[key].status == 2) {
                			var selected;
                			if(response.userBanks[key].id == payment_method_id) {
                				selected = 'selected';
                			}
							if(response.userBanks[key].account_number){
								var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
								html += '<option data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" '+selected+'>'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
							} else {
								var payment_method = response.userBanks[key].bank_name;
								html += '<option data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" '+selected+'>'+response.userBanks[key].bank_name+'</option>';
							}
	                	}
					}
				}
				$('#refund_payment_method_id').html(html);
            }
        }
    });
}

window.saveSecurityDepositRefundData = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/save-security-deposit-refund-data",
        data: $('#refund_security_deposit_form').serialize()+'&user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		loadTableLeaseDetail();
        		$('#refundSecurityAmountModal').modal('hide');
			} else {
				$('.save_security_deposit_refund_data').attr('disabled', false);
			}
        }
    });
}

var $vacantForm;
window.addVacantDateValidation = function() {
	$vacantForm = $("#vacant_date_add_form");
	$vacantForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "vacant_date": { required: true },
	    },
	    messages: {
	        "vacant_date": { required: '' },
	    }
	});
}

window.sendLeaseExtensionNotification = function(key, type, tenant_lease_id, vacant_date) {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/send-lease-extension-notification",
        data: 'user_id='+user.user_details.user_id+'&type='+type+'&tenant_lease_id='+tenant_lease_id+'&vacant_date='+vacant_date,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
	        	var user = JSON.parse(localStorage.user);
				user.leaseInfo[key].lease.is_extended = type;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back
				location.reload();
			} else {
				$('.add_vacant_date').attr('disabled', false);
			}
        }
    });
}

window.getUserServiceList = function() {
	if(user.userServiceList != null) {
		if(user.userServiceList.length > 1) {
			var html = '';
			html += '<a class="nav-flag dropdown-toggle" href="#" id="serviceDropdown" data-toggle="dropdown"> <img class="ninedot" src="icon/ninedot.png"/> </a>';
	        html += '<div class="dropdown-menu dropdown-menu-right dropdown-lg" aria-labelledby="serviceDropdown">';
	            html += '<div id="serviceDropdown" class="row">';
			for (var key in user.userServiceList) {
		        if (user.userServiceList.hasOwnProperty(key)) {
		        	var service_name = user.userServiceList[key].service.service_name;
	                html += '<div data-id="'+user.userServiceList[key].service.id+'" class="col-12 col-md-6 col-xl-4 d-flex role_detail change_service">';
	                    html += '<div class="card flex-fill">';
	                        html += '<div class="card-body text-center">';
	                            html += '<img src="'+user.userServiceList[key].service.image+'" alt="'+service_name+'" class="img-fluid rounded-circle mb-2" width="100" height="100">';
	                        html += '</div>';
	                        html += '<div class="text-center">';
	                            html += '<h6>'+service_name+'</h6>';
	                        html += '</div>';
	                    html += '</div>';
	                html += '</div>';
		        }
		    }
	    		html += '</div>';
	        html += '</div>';
			$('#service_dropdown_li').html(html);
		} else {
			$('#service_dropdown_li').remove();
		}
	}
}

window.getAnnouncement = function() {
	if(user.announcementInfo != null) {
		$('#announcement-sticky-bar').removeClass('d-none');
		var close_btn = '';
		if(user.announcementInfo.close_button == 'Yes') {
			close_btn = '<a href="JavaScript:void(0)" style="position: absolute; top: 5px; right: 20px;"><i style="font-size: 24px;color: initial;" class="far fa-times-circle close_announcement" aria-hidden="true"></i></a>';
		}

		var html = '';
		var read_more = '';
		if(user.announcementInfo.readmore == 'Yes') {
			read_more = '<a data-id="'+user.announcementInfo.id+'" href="javascript:void(0);" class="read-more black">More info...</a>';
		}
		var msg = user.announcementInfo.message;
    	html += '<input type="hidden" name="announcement_title" id="announcement_title" value="'+user.announcementInfo.title+'"/>';
    	html += '<span id="announcement_text" style="font-weight:bold">'+msg+'</span>&nbsp;&nbsp;'+read_more+'&nbsp;'+close_btn;

		$('#announcement-sticky-bar').html(html);
	    $('.wrapper').css('padding-top', '36px');
	    $('.simplebar-content').css('padding-top', '36px !important');
	    $('.sidebar-sticky .sidebar-content').css('top', 36);
	    $('#assumed_user_email').css('top', '4.7%');

	    if(user.announcementInfo.time_duration > 0) {
	    	setTimeout(function() {
		    	$('#announcement-sticky-bar').addClass('d-none');
			    $('#announcement-sticky-bar').html('');
				$('.wrapper').css('padding-top', '0px');
				$('.simplebar-content').css('padding-top', '0px');
				$('.sidebar-sticky .sidebar-content').css('top', 0);
				$('#assumed_user_email').css('top', '0.7%');
				localStorage.setItem("hide_announcement", 1);  //put the object back
			}, user.announcementInfo.time_duration); // <-- time in milliseconds
		}
	} else {
		$('#announcement-sticky-bar').addClass('d-none');
		$('#announcement-sticky-bar').html('');
		$('.wrapper').css('padding-top', '0px');
		$('.simplebar-content').css('padding-top', '0px');
		$('.sidebar-sticky .sidebar-content').css('top', 0);
		$('#assumed_user_email').css('top', '0.7%');
		localStorage.setItem("hide_announcement", 1);  //put the object back
	}
}

window.getUserLeaseInfo = function(leaseInfo) {
	$('#user_lease_info_section').removeClass('d-none');
	var notification_html = '';
	if(leaseInfo.length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/lease1.svg" class="filter-15" style="width: 23px;"/></span> Lease Info</div>';
			notification_html += '</div>';
		notification_html += '</div>';

		for (var key in leaseInfo) {
			if(leaseInfo[key].other_lease_active == 0) {
				var name = stripHtml(leaseInfo[key].property.name);
				notification_html += '<div class="card light-border m-3">';
					notification_html += '<div id="user_lease_card_'+leaseInfo[key].id+'" class="notifcation-group-item list-group-item user_lease_info_section no_border">';
						notification_html += '<div class="row no-gutters align-items-center">';
							notification_html += '<div class="col-12">';
								notification_html += '<div class="fs-18 bold-black" title="'+stripHtml(leaseInfo[key].property.name)+'">'+name+'</div>';
								if(leaseInfo[key].status == 4) {
									notification_html += '<div class="fs-16 p-4 text-center">';
									notification_html += '<p>Your current lease is ended on</p> <p class="fs-16 bold-black">'+leaseInfo[key].lease.end_date+'</p>';
									notification_html += '</div>';
								} else {
									if(leaseInfo[key].lease.is_extended == null || leaseInfo[key].lease.is_extended == '') {
										notification_html += '<div class="text-dark">';
										notification_html += '<p>Your current lease is ending on</p> <p class="fs-16 bold-black">'+leaseInfo[key].lease.end_date+'</p> <p>Do you want to request for lease extension ?</p>';
										notification_html += '</div>';
										notification_html += '<div class="mt-2 d-flex">';
											notification_html += '<div class="col-6">';
												notification_html += '<button data-value="Yes" data-key="'+key+'" data-property-name="'+leaseInfo[key].property.name+'" data-lease-id="'+leaseInfo[key].lease.id+'" data-tenant-lease-id='+leaseInfo[key].id+' type="button" class="btn btn-orange lease_extension_btn w-100">Yes</button>';
											notification_html += '</div>';
											notification_html += '<div class="col-6">';
												notification_html += '<button data-value="No" data-key="'+key+'" data-property-name="'+leaseInfo[key].property.name+'" data-lease-id="'+leaseInfo[key].lease.id+'" data-tenant-lease-id='+leaseInfo[key].id+' type="button" class="btn btn-primary lease_extension_btn w-100">No</button>';
											notification_html += '</div>';
										notification_html += '</div>';
									} else {
										notification_html += '<div class="text-dark">';
										notification_html += '<p>Your current lease is ending on</p> <p class="fs-16 bold-black">'+leaseInfo[key].lease.end_date+'</p>';
										notification_html += '</div>';
									}
								}
							notification_html += '</div>';
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			}
		}
	}
	return notification_html;
}

// window.getUserLeaseInfo = function() {
// 	if(user.leaseInfo != null) {
// 		$('#user_lease_info_section').removeClass('d-none');
// 		var html = '';
// 		var notification_html = '';
// 		if(Object.keys(user.leaseInfo).length > 0){
// 			notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
// 				notification_html += '<div class="col-12 col-md-12">';
// 					notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/lease1.svg" class="filter-15" style="width: 23px;"/></span> Lease Info</div>';
// 				notification_html += '</div>';
// 			notification_html += '</div>';

// 			for (var key in user.leaseInfo) {
// 				if (user.leaseInfo.hasOwnProperty(key)) {
// 					if(user.leaseInfo[key].other_lease_active == 0) {
// 						var name = stripHtml(user.leaseInfo[key].property.name);
// 						notification_html += '<div class="card light-border m-3">';
// 							notification_html += '<div id="user_lease_card_'+user.leaseInfo[key].id+'" class="notifcation-group-item list-group-item user_lease_info_section no_border">';
// 								notification_html += '<div class="row no-gutters align-items-center">';
// 									notification_html += '<div class="col-12">';
// 										notification_html += '<div class="fs-18 bold-black" title="'+stripHtml(user.leaseInfo[key].property.name)+'">'+name+'</div>';
// 										if(user.leaseInfo[key].status == 4) {
// 											notification_html += '<div class="fs-16 p-4 text-center">';
// 											notification_html += '<p>Your current lease is ended on</p> <p class="fs-16 bold-black">'+user.leaseInfo[key].lease.end_date+'</p>';
// 											notification_html += '</div>';
// 										} else {
// 											if(user.leaseInfo[key].lease.is_extended == null || user.leaseInfo[key].lease.is_extended == '') {
// 												notification_html += '<div class="text-dark">';
// 												notification_html += '<p>Your current lease is ending on</p> <p class="fs-16 bold-black">'+user.leaseInfo[key].lease.end_date+'</p> <p>Do you want to request for lease extension ?</p>';
// 												notification_html += '</div>';
// 												notification_html += '<div class="mt-2">';
// 													notification_html += '<button data-value="Yes" data-key="'+key+'" data-property-name="'+user.leaseInfo[key].property.name+'" data-lease-id="'+user.leaseInfo[key].lease.id+'" data-tenant-lease-id='+user.leaseInfo[key].id+' type="button" class="btn btn-orange mr-2 lease_extension_btn">Yes</button>';
// 													notification_html += '<button data-value="No" data-key="'+key+'" data-property-name="'+user.leaseInfo[key].property.name+'" data-lease-id="'+user.leaseInfo[key].lease.id+'" data-tenant-lease-id='+user.leaseInfo[key].id+' type="button" class="btn btn-primary mr-2 lease_extension_btn">No</button>';
// 												notification_html += '</div>';
// 											} else {
// 												notification_html += '<div class="text-dark">';
// 												notification_html += '<p>Your current lease is ending on</p> <p class="fs-16 bold-black">'+user.leaseInfo[key].lease.end_date+'</p>';
// 												notification_html += '</div>';
// 											}
// 										}
// 									notification_html += '</div>';
// 								notification_html += '</div>';
// 							notification_html += '</div>';
// 						notification_html += '</div>';
// 					}
// 				}
// 			}
// 			$('#sidebar_content_area').append(notification_html);
// 		}
// 	}
// }

window.getSubscriptionFeeFailedTransaction = function(failedSubscriptionFeeSP) {
	var notification_html = "";
	if(Object.keys(failedSubscriptionFeeSP.arrSP).length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/tiles/rent.svg" class="filter-15" style="width: 23px;"/></span>Authorize Failed Subscription Fee</div>';
			notification_html += '</div>';
		notification_html += '</div>';
		for (var key in failedSubscriptionFeeSP.arrSP) {
			var name = stripHtml(failedSubscriptionFeeSP.arrSP[key].property.name);
			var address = '';
			if(failedSubscriptionFeeSP.arrSP[key].property != null && failedSubscriptionFeeSP.arrSP[key].property.address != null) {
				address = failedSubscriptionFeeSP.arrSP[key].property.address.address_line1+', '+failedSubscriptionFeeSP.arrSP[key].property.address.address_line2+'<br>'+failedSubscriptionFeeSP.arrSP[key].property.address.city+', '+failedSubscriptionFeeSP.arrSP[key].property.address.state+' '+failedSubscriptionFeeSP.arrSP[key].property.address.zip_code;
			}

			var add_opacity = '';
			var exclamation = '';
			if(failedSubscriptionFeeSP.arrSP[key].is_disabled == 1) {
				//var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
				var auth_msg = 'Authorize Payment button is not enabled since the previous month payment is still pending for authorization.';
				add_opacity = 'add_opacity';
				add_opacity = 'add_opacity';
				//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			}

			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="subscription_fee_card_'+failedSubscriptionFeeSP.arrSP[key].id+'" class="notifcation-group-item list-group-item subscription_fee_section no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						notification_html += '<div class="col-12">';
							notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
								notification_html += '<tbody>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Type</td>';
										notification_html += '<td class="text-right bold-black">'+failedSubscriptionFeeSP.arrSP[key].payment_type_lable+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Property Name</td>';
										notification_html += '<td class="text-right bold-black">'+name+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Payment Due Date</td>';
										notification_html += '<td class="text-right bold-black">'+failedSubscriptionFeeSP.arrSP[key].rent_month_formatted+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="bold-grey fs-16 pt-3">Total Amount Due</td>';
										notification_html += '<td class="text-right bold-black fs-16 pt-3"><strong>$'+failedSubscriptionFeeSP.arrSP[key].formated_total+'</strong></td>';
									notification_html += '</tr>';
								notification_html += '</tbody>';
							notification_html += '</table>';
							notification_html += '<button data-id="'+failedSubscriptionFeeSP.arrSP[key].id+'" data-property-id="'+failedSubscriptionFeeSP.arrSP[key].property_id+'" data-payment-type="'+failedSubscriptionFeeSP.arrSP[key].payment_type+'" data-payment-id="'+failedSubscriptionFeeSP.arrSP[key].payment_details_id+'" data-month="'+failedSubscriptionFeeSP.arrSP[key].rent_month+'" data-open-from="notification" type="button" class="btn btn-orange mt-3 w-100 authorize_subscription_fee '+add_opacity+'">Authorize Payment</button>'+exclamation;
							if(auth_msg) {
								notification_html += '<div class="mt-3"><small class="bold-black">Note : </small><small class="grey">'+auth_msg+'</small></div>';
							}
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

// window.getSubscriptionFeeFailedTransaction = function() {
// 	var tz = jstz.determine();
// 	var timezone = tz.name();
// 	$.ajax({
// 	    method: 'POST',
// 	    url: api_url+"scheduled-payments/get-subscription-fee-failed-transaction",
// 		data: 'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
// 	    beforeSend: function(xhr) {
// 	        xhr.setRequestHeader('Authorization', accessToken);
// 	    },
// 	    success: function(response) {
// 	        if(response.response_data.success == true) {
// 	        	var notification_html = "";
// 		        if(Object.keys(response.arrSP).length > 0){
// 					notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
// 						notification_html += '<div class="col-12 col-md-12">';
// 							notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/tiles/rent.svg" class="filter-15" style="width: 23px;"/></span>Authorize Failed Subscription Fee</div>';
// 						notification_html += '</div>';
// 					notification_html += '</div>';
// 			    	for (var key in response.arrSP) {
// 			            if (response.arrSP.hasOwnProperty(key)) {
// 			            	var name = stripHtml(response.arrSP[key].property.name);
// 			            	var address = '';
// 			                if(response.arrSP[key].property != null && response.arrSP[key].property.address != null) {
// 			                	address = response.arrSP[key].property.address.address_line1+', '+response.arrSP[key].property.address.address_line2+'<br>'+response.arrSP[key].property.address.city+', '+response.arrSP[key].property.address.state+' '+response.arrSP[key].property.address.zip_code;
// 			                }

// 			                var add_opacity = '';
// 			                var exclamation = '';
// 			                if(response.arrSP[key].is_disabled == 1) {
// 			                	//var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
// 								var auth_msg = 'Authorize Payment button is not enabled since the previous month payment is still pending for authorization.';
// 			                	add_opacity = 'add_opacity';
// 			                	add_opacity = 'add_opacity';
// 			                	//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
// 			                }

// 							notification_html += '<div class="card light-border m-3">';
// 								notification_html += '<div id="subscription_fee_card_'+response.arrSP[key].id+'" class="notifcation-group-item list-group-item subscription_fee_section no_border">';
// 									notification_html += '<div class="row no-gutters align-items-center">';
// 										notification_html += '<div class="col-12">';
// 											notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
// 												notification_html += '<tbody>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="grey">Type</td>';
// 														notification_html += '<td class="text-right bold-black">'+response.arrSP[key].payment_type_lable+'</td>';
// 													notification_html += '</tr>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="grey">Property Name</td>';
// 														notification_html += '<td class="text-right bold-black">'+name+'</td>';
// 													notification_html += '</tr>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="grey">Payment Due Date</td>';
// 														notification_html += '<td class="text-right bold-black">'+response.arrSP[key].rent_month_formatted+'</td>';
// 													notification_html += '</tr>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="bold-grey fs-16 pt-3">Total Amount Due</td>';
// 														notification_html += '<td class="text-right bold-black fs-16 pt-3"><strong>$'+response.arrSP[key].formated_total+'</strong></td>';
// 													notification_html += '</tr>';
// 												notification_html += '</tbody>';
// 											notification_html += '</table>';
// 											notification_html += '<button data-id="'+response.arrSP[key].id+'" data-property-id="'+response.arrSP[key].property_id+'" data-payment-type="'+response.arrSP[key].payment_type+'" data-payment-id="'+response.arrSP[key].payment_details_id+'" data-month="'+response.arrSP[key].rent_month+'" data-open-from="notification" type="button" class="btn btn-orange mt-3 w-100 authorize_subscription_fee '+add_opacity+'">Authorize Payment</button>'+exclamation;
// 											if(auth_msg) {
// 												notification_html += '<div class="mt-3"><small class="bold-black">Note : </small><small class="grey">'+auth_msg+'</small></div>';
// 											}
// 										notification_html += '</div>';
// 									notification_html += '</div>';
// 								notification_html += '</div>';
// 							notification_html += '</div>';
// 			            }
// 			        }
// 			    }
// 		        $('#sidebar_content_area').append(notification_html);
// 		        $('[data-toggle="popover"]').popover({
// 		        	html: true,
// 				    animation:false,
// 				    sanitize: false,
// 				    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
// 		        });
// 	        } else {
// 	        	display_error_message(response);
// 	        }
// 	    }
// 	});
// }

window.getUpcomingPaymentDetail = function(spUnauthorizedPayments) {
	var notification_html = "";
	if(Object.keys(spUnauthorizedPayments.arrSP).length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/authorization.svg" class="filter-15" style="width: 23px;"/></span>Authorize Rent</div>';
			notification_html += '</div>';
		notification_html += '</div>';
		for (var key in spUnauthorizedPayments.arrSP) {
			var name = stripHtml(spUnauthorizedPayments.arrSP[key].property.name);
			var address = '';
			if(spUnauthorizedPayments.arrSP[key].property != null && spUnauthorizedPayments.arrSP[key].property.address != null) {
				address = spUnauthorizedPayments.arrSP[key].property.address.address_line1+', '+spUnauthorizedPayments.arrSP[key].property.address.address_line2+'<br>'+spUnauthorizedPayments.arrSP[key].property.address.city+', '+spUnauthorizedPayments.arrSP[key].property.address.state+' '+spUnauthorizedPayments.arrSP[key].property.address.zip_code;
			}

			var add_opacity = '';
			var exclamation = '';
			if(spUnauthorizedPayments.arrSP[key].is_disabled == 1) {
				//var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
				var auth_msg = 'Authorize Payment button is not enabled since the previous month payment is still pending for authorization.';
				add_opacity = 'add_opacity';
				//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			}
			var dwolla_status_array = ['retry', 'kba', 'document', 'suspended'];
			if(dwolla_status_array.includes(spUnauthorizedPayments.response_data.dwolla_customer_status)){
				//var auth_msg = 'Authorize Payment button is not <br> enabled since your account is in <br>'+ spUnauthorizedPayments.response_data.dwolla_customer_status +' status';
				var auth_msg = 'Authorize Payment button is not enabled since your account is in '+ spUnauthorizedPayments.response_data.dwolla_customer_status +' status';
				add_opacity = 'add_opacity';
				//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			}

			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="upcoming_payment_card_'+spUnauthorizedPayments.arrSP[key].id+'" class="notifcation-group-item list-group-item upcoming_payment_section no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						notification_html += '<div class="col-12">';
							notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
								notification_html += '<tbody>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Type</td>';
										notification_html += '<td class="text-right bold-black">'+spUnauthorizedPayments.arrSP[key].payment_type_lable+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Property Name</td>';
										notification_html += '<td class="text-right bold-black">'+name+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="grey">Payment Due Date</td>';
										notification_html += '<td class="text-right bold-black">'+spUnauthorizedPayments.arrSP[key].payment_due_date+'</td>';
									notification_html += '</tr>';
									notification_html += '<tr>';
										notification_html += '<td class="bold-grey fs-16 pt-3">Total Amount Due</td>';
										notification_html += '<td class="text-right bold-black fs-16 pt-3"><strong>$'+spUnauthorizedPayments.arrSP[key].formated_total+'</strong></td>';
									notification_html += '</tr>';
								notification_html += '</tbody>';
							notification_html += '</table>';
							if(spUnauthorizedPayments.arrSP[key].application_status != 'pending_collection'){
								notification_html += '<button data-property-id='+spUnauthorizedPayments.arrSP[key].property_id+' data-scheduled-payment-id='+spUnauthorizedPayments.arrSP[key].id+' type="button" class="btn btn-orange mt-3 w-100 authorize_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Authorize Payment</button>'+exclamation;
							} else{
								notification_html += '<button data-property-id='+spUnauthorizedPayments.arrSP[key].property_id+' data-scheduled-payment-id='+spUnauthorizedPayments.arrSP[key].id+' type="button" class="btn btn-orange mt-3 w-100 authorize_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Review Payment</button>'+exclamation;
							}
							if(auth_msg) {
								notification_html += '<div class="mt-3"><small class="bold-black">Note : </small><small class="grey">'+auth_msg+'</small></div>';
							}
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

// window.getUpcomingPaymentDetail = function() {
// 	var tz = jstz.determine();
// 	var timezone = tz.name();
// 	$.ajax({
// 	    method: 'POST',
// 	    url: api_url+"scheduled-payments/get-unauthorized-payments",
// 		data: 'tenant_id='+user.user_details.user_id+'&user_timezone='+timezone,
// 	    beforeSend: function(xhr) {
// 	        xhr.setRequestHeader('Authorization', accessToken);
// 	    },
// 	    success: function(response) {
// 	        if(response.response_data.success == true) {
// 	        	var html = "";
// 	        	var notification_html = "";
// 				if(Object.keys(response.arrSP).length > 0){
// 					notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
// 						notification_html += '<div class="col-12 col-md-12">';
// 							notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/notification/authorization.svg" class="filter-15" style="width: 23px;"/></span>Authorize Rent</div>';
// 						notification_html += '</div>';
// 					notification_html += '</div>';
// 					for (var key in response.arrSP) {
// 						if (response.arrSP.hasOwnProperty(key)) {

// 							var name = stripHtml(response.arrSP[key].property.name);
// 							var address = '';
// 							if(response.arrSP[key].property != null && response.arrSP[key].property.address != null) {
// 								address = response.arrSP[key].property.address.address_line1+', '+response.arrSP[key].property.address.address_line2+'<br>'+response.arrSP[key].property.address.city+', '+response.arrSP[key].property.address.state+' '+response.arrSP[key].property.address.zip_code;
// 							}

// 							var add_opacity = '';
// 							var exclamation = '';
// 							if(response.arrSP[key].is_disabled == 1) {
// 								//var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
// 								var auth_msg = 'Authorize Payment button is not enabled since the previous month payment is still pending for authorization.';
// 								add_opacity = 'add_opacity';
// 								//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
// 							}
// 							var dwolla_status_array = ['retry', 'kba', 'document', 'suspended'];
// 							if(dwolla_status_array.includes(response.response_data.dwolla_customer_status)){
// 								//var auth_msg = 'Authorize Payment button is not <br> enabled since your account is in <br>'+ response.response_data.dwolla_customer_status +' status';
// 								var auth_msg = 'Authorize Payment button is not enabled since your account is in '+ response.response_data.dwolla_customer_status +' status';
// 								add_opacity = 'add_opacity';
// 								//exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
// 							}

// 							notification_html += '<div class="card light-border m-3">';
// 								notification_html += '<div id="upcoming_payment_card_'+response.arrSP[key].id+'" class="notifcation-group-item list-group-item upcoming_payment_section no_border">';
// 									notification_html += '<div class="row no-gutters align-items-center">';
// 										notification_html += '<div class="col-12">';
// 											notification_html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
// 												notification_html += '<tbody>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="grey">Type</td>';
// 														notification_html += '<td class="text-right bold-black">'+response.arrSP[key].payment_type_lable+'</td>';
// 													notification_html += '</tr>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="grey">Property Name</td>';
// 														notification_html += '<td class="text-right bold-black">'+name+'</td>';
// 													notification_html += '</tr>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="grey">Payment Due Date</td>';
// 														notification_html += '<td class="text-right bold-black">'+response.arrSP[key].payment_due_date+'</td>';
// 													notification_html += '</tr>';
// 													notification_html += '<tr>';
// 														notification_html += '<td class="bold-grey fs-16 pt-3">Total Amount Due</td>';
// 														notification_html += '<td class="text-right bold-black fs-16 pt-3"><strong>$'+response.arrSP[key].formated_total+'</strong></td>';
// 													notification_html += '</tr>';
// 												notification_html += '</tbody>';
// 											notification_html += '</table>';
// 											if(response.arrSP[key].application_status != 'pending_collection'){
// 												notification_html += '<button data-property-id='+response.arrSP[key].property_id+' data-scheduled-payment-id='+response.arrSP[key].id+' type="button" class="btn btn-orange mt-3 w-100 authorize_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Authorize Payment</button>'+exclamation;
// 											} else{
// 												notification_html += '<button data-property-id='+response.arrSP[key].property_id+' data-scheduled-payment-id='+response.arrSP[key].id+' type="button" class="btn btn-orange mt-3 w-100 authorize_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Review Payment</button>'+exclamation;
// 											}
// 											if(auth_msg) {
// 												notification_html += '<div class="mt-3"><small class="bold-black">Note : </small><small class="grey">'+auth_msg+'</small></div>';
// 											}
// 										notification_html += '</div>';
// 									notification_html += '</div>';
// 								notification_html += '</div>';
// 							notification_html += '</div>';
// 						}
// 					}
// 				}
// 		        $('#sidebar_content_area').append(notification_html);
// 		        $('[data-toggle="popover"]').popover({
// 		        	html: true,
// 				    animation:false,
// 				    sanitize: false,
// 				    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
// 		        });
// 	        } else {
// 	        	display_error_message(response);
// 	        }
// 	    }
// 	});
// }

window.getSchedulePaymentDetail = function(tenant_id, property_id, scheduled_payment_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-scheduled-payments",
        data: 'tenant_id='+tenant_id+'&property_id='+property_id+'&scheduled_payment_id='+scheduled_payment_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var bank_html = "<option value=''>Select Payment Method</option>";
                for (var key in response.paymentDetails) {
			        if (response.paymentDetails.hasOwnProperty(key)) {
			        	bank_html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+'</option>';
					}
				}

				var line = '(One time payment)';
				if(response.scheduledPayment.payment_type == 2 || response.scheduledPayment.payment_type == 7) {
					line = 'for the month of '+response.scheduledPayment.payment_due_date2;
				}

            	var html = '';
            	html += '<input type="hidden" id="selected_payment_id" value="'+scheduled_payment_id+'">';
            	html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.scheduledPayment.trn_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {

							// Add exclamation button
							var late_fee_exclamation = '<i id="open_latefee_info" data-id="'+response.scheduledPayment.id+'" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2" data-container="body" data-toggle="popover"></i>';
							var other_fee_exclamation = '<i id="open_otherfee_info" data-id="'+response.scheduledPayment.id+'" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2" data-container="body" data-toggle="popover"></i>';

							html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					        	html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.scheduledPayment.payment_type_lable+'</span> '+line+'</h5>';
					            html += '</div>';
								html += '<div class="rent_collection_data mt-2">';
                                	html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(A) Rent Due by '+response.scheduledPayment.rent_month+'</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(B) No. of days late after '+response.scheduledPayment.lateFeeafterDays+' day of the month</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>'+response.scheduledPayment.no_of_days+' day(s)</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
									html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(C) Late fee <span>'+late_fee_exclamation+'</span></div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_late_fee_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
									html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(D) Additional Fees/Penalties <span>'+other_fee_exclamation+'</span></div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.ach_return_fee+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(E) Fees waived</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_waived_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                            html += '</div>';
						}

				        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 mb-1 bg-white">';
				            html += '<div class="rent_collection_data">';
				                html += '<div class="row">';
				                    html += '<div class="col-12 col-md-6 col-xl-9">';
				                    	if(response.scheduledPayment.trn_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
				                        	html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong> (A) + (C) + (D) - (E)</h5>';
				                        } else {
				                        	html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong></h5>';
				                        }
				                    html += '</div>';
				                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
				                    	if(response.scheduledPayment.trn_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
				                        	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
				                        } else {
				                        	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
				                        }
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';

						if(response.scheduledPayment != null) {
		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

							var payment_due_date = response.scheduledPayment.payment_due_date;
		            		var formatted_payment_due_date = getFormattedDate(payment_due_date);

		            		html += '<input type="hidden" name="scheduled_payment_id[]" id="scheduled_payment_0" class="schedule_payment_hidden_input" value="'+response.scheduledPayment.id+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';
					                	// check if due date is less then or equal to current date, then add class add_opacity
					                	var d1 = new Date(formatted_payment_due_date);
					                	var d2 = new Date(current_date);

					                	var add_opacity = '';
					            		if(d1.getTime() <= d2.getTime()) {
					            			add_opacity = 'add_opacity';
		    							}

	    								html += '<div class="col-12 col-md-4 col-xl-4">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Due Date</strong></div>';
										        html += '<div class="input-group date mb-2 other_month_due_date '+add_opacity+'" data-target-input="nearest">';
										            html += '<input type="text" name="payment_due_date[]" id="payment_due_date_0" class="payment_due_date form-control readonly_white" autocomplete="off" readonly="" value="'+formatted_payment_due_date+'" />';
										            html += '<div class="input-group-append">';
										                html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
										            html += '</div>';
										        html += '</div>';
										    html += '</div>';
										html += '</div>';

										html += '<div class="col-12 col-md-4 col-xl-4">';
											if(response.scheduledPayment.application_status != 'pending_collection'){
					                        html += '<div class="mt-2">';
					                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
					                            html += '<div><select name="payment_detail_id[]" id="payment_detail_0" class="bank_dd form-control">'+bank_html+'</select></div>';
					                        html += '</div>';
											}
					                    html += '</div>';
					                    html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
					                            html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';

									if(response.scheduledPayment.transaction_parent_id > 0){
										html += '<div class="col-12 col-md-12 col-xl-12 mt-2">';
												html += 'Total rent amount due is $'+response.scheduledPayment.parent_total+'. The above amount is the balance due amount of $'+response.scheduledPayment.formatted_total+'.';
										html += '</div>';
									}

					            html += '</div>';
					        html += '</div>';
						}

						if(response.scheduledPayment.application_status != 'pending_collection'){
							html += '<div class="col-auto d-sm-block mb-2 mt-4">';
								html += '<div class="pay_now_checkbox">';
									html += '<label class="custom-control custom-checkbox m-0">';
									html += '<input name="pay_now_checkbox" id="payment_authorize_checkbox" type="checkbox" class="custom-control-input">';
									html += '<span class="custom-control-label">'+authorize_text+'</span>';
									html += '</label>';
								html += '</div>';
							html += '</div>';
						}
				    html += '</div>';
				html += '</form>';

				if(response.scheduledPayment.application_status == 'pending_collection'){
					$('#payNowModal .border-top').remove();
					$('.payment_due_date').attr('disabled', true);
					$('.review_title').html('Payment Review');
				}

				$('#rent_collection_container').html(html);

				tenant_authorize_datepicker("other_month_due_date", formatted_payment_due_date);

				addRentCollectionValidation();

				// Get Late Fee Charges detail
				var late_fee_title = 'Late Fee Calculation';
				var other_fee_title = 'Additional Fees/Penalties';
				openLateFeePopoverModal(late_fee_title, response.scheduledPayment.primary_percentage, response.scheduledPayment.secondary_percentage);
				openOtherFeePopoverModal(other_fee_title);
            }
        }
    });
}

window.editTransaction = function(tenant_id, property_id, scheduled_payment_id){
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-scheduled-payments",
        data: 'tenant_id='+tenant_id+'&property_id='+property_id+'&scheduled_payment_id='+scheduled_payment_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var bank_html = "<option value=''>Select Payment Method</option>";
                for (var key in response.paymentDetails) {
			        if (response.paymentDetails.hasOwnProperty(key)) {
			        	bank_html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+'</option>';
					}
				}

            	var html = '';
            	html += '<input type="hidden" id="selected_payment_id" value="'+scheduled_payment_id+'">';
            	html += '<form id="edit-transaction-form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.scheduledPayment != null) {
		            		var line = '(One time payment)';
		            		if(response.scheduledPayment.payment_type == 2 || response.scheduledPayment.payment_type == 7) {
		            			line = 'for the month of '+response.scheduledPayment.payment_due_date2;
		            		}

		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

							var payment_due_date = response.scheduledPayment.payment_due_date;
		            		var formatted_payment_due_date = getFormattedDate(payment_due_date);

		            		html += '<input type="hidden" name="scheduled_payment_id" id="scheduled_payment_0" class="schedule_payment_hidden_input" value="'+response.scheduledPayment.id+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.scheduledPayment.payment_type_lable+'</span> '+line+'</h5>';
					            html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';
					                	// check if due date is less then or equal to current date, then add class add_opacity
					                	var d1 = new Date(formatted_payment_due_date);
					                	var d2 = new Date(current_date);

					                	var add_opacity = '';
					            		if(d1.getTime() <= d2.getTime()) {
					            			add_opacity = 'add_opacity';
		    							}

	    								html += '<div class="col-12 col-md-4 col-xl-6">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Balance Due Date</strong></div>';
										        html += '<div class="input-group date mb-2 balance_due_date '+add_opacity+'" data-target-input="nearest">';
										            html += '<input type="text" name="payment_due_date" id="payment_due_date_0" class="payment_due_date form-control readonly_white" readonly autocomplete="off" value="'+formatted_payment_due_date+'" />';
										            html += '<div class="input-group-append">';
										                html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
										            html += '</div>';
										        html += '</div>';
										    html += '</div>';
										html += '</div>';

										html += '<div class="col-12 col-md-4 col-xl-6 d-none">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
					                            html += '<div><select name="payment_detail_id" id="payment_detail_0" class="bank_dd form-control">'+bank_html+'</select></div>';
					                        html += '</div>';
					                    html += '</div>';
					                    html += '<div class="col-12 col-md-4 col-xl-6">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black mb-2"><strong>Partial Payment</strong></div>';
					                            html += '<input type="text" name="partial_pay_amount" id="partial_pay_amount" lease-id="'+response.scheduledPayment.lease_id+'" class="allow_numeric_with_decimal form-control" autocomplete="off" value="" />';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					            html += '</div>';
					        html += '</div>';

							//For partial Payment
							html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';

										html += '<div class="col-12 col-md-4 col-xl-6">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Monthly Rent</strong></div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-6 text-right">';
										    html += '<div class="mt-2">';
											html += '<input type="hidden" id="pp_monthly_rent" name="pp_monthly_rent">';
												html += '<div class="black fs-18 pp_monthly_rent" data-value="'+response.scheduledPayment.formatted_total+'"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
										    html += '</div>';
										html += '</div>';

	    								html += '<div class="col-12 col-md-4 col-xl-6">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Partial Payment</strong></div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-6 text-right">';
										    html += '<div class="mt-2">';
											html += '<input type="hidden" id="pp_partial_amount" name="pp_partial_amount">';
											html += '<div class="black fs-18 pp_partial_amount"><strong>$ 0.00</strong></div>';
										    html += '</div>';
										html += '</div>';

										html += '<div class="col-12 col-md-4 col-xl-6">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Balance</strong></div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-6 text-right">';
										    html += '<div class="mt-2">';
												html += '<input type="hidden" id="pp_balance" name="pp_balance">';
												html += '<div class="black fs-18 pp_balance"><strong>$ 0.00</strong></div>';
										    html += '</div>';
										html += '</div>';

										html += '<div class="col-12 col-md-4 col-xl-6">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Partial Payment Fee </strong><small id="pp_payment_fee_label"></small></div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-6 text-right">';
										    html += '<div class="mt-2">';
												html += '<input type="hidden" id="pp_payment_fee" name="pp_payment_fee">';
												html += '<div class="black fs-18 pp_payment_fee"><strong>$ 0.00</strong></div>';
										    html += '</div>';
										html += '</div>';

					                html += '</div>';
					            html += '</div>';
					        html += '</div>';

						}

						if(response.scheduledPayment.trn_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
							html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					        	html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">Late Fee</span></h5><p>The late fee is $'+response.scheduledPayment.latePricePerDay+' every day until the late fee is the equivalent of '+response.scheduledPayment.lateMaxPercentag+'% of the rent.</p>';
					            html += '</div>';
                                html += '<div class="rent_collection_data mt-2">';
                                	html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(A) Rent Due by '+response.scheduledPayment.rent_month+'</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2 ml-4">(1) No. of days late after '+response.scheduledPayment.lateFeeafterDays+' day of the month</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div>'+response.scheduledPayment.no_of_days+' day(s)</div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2 ml-4">(2) Late fee applied per day</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div>$ '+response.scheduledPayment.price_per_day+'</div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(B) Outstanding late fee (1) x (2)</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_late_fee_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(C) Late fee waived</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_waived_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                            html += '</div>';
						}

				        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
				            html += '<div class="rent_collection_data">';
				                html += '<div class="row">';
				                    html += '<div class="col-12 col-md-6 col-xl-9">';
				                    	if(response.scheduledPayment.trn_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
				                        	html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong> (A) + (B) - (C)</h5>';
				                        } else {
				                        	html += '<h5 class="card-title"><span class="fs-16"><strong>Authorized Rent</strong></h5>';
				                        }
				                    html += '</div>';
				                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
				                    	if(response.scheduledPayment.trn_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
				                        	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
				                        } else {
				                        	html += '<div class="black fs-18 pp_authorized_rent"><strong>$ 0.00</strong></div>';
											html += '<input type="hidden" id="pp_authorized_rent" name="pp_authorized_rent">';
				                        }
				                    html += '</div>';
				                html += '</div>';

								html += '<div class="row">';
				                    html += '<div class="col-12 col-md-6 col-xl-9">';
										html += '<h5 class="card-title"><span class="fs-16"><strong>Balance Due</strong></h5>';
				                    html += '</div>';
				                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
										html += '<div class="black fs-18 pp_balance_due"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
										html += '<input type="hidden" id="pp_balance_due" name="pp_balance_due">';
				                    html += '</div>';
				                html += '</div>';

				            html += '</div>';
				        html += '</div>';
				    html += '</div>';
				html += '</form>';

				$('#edit_transaction_container').html(html);

				tenant_authorize_datepicker("balance_due_date", formatted_payment_due_date);

				editTransactionValidation();
            }
        }
    });
}

window.savePartialPayment = function(){
	var pp_data = $('#edit-transaction-form').serialize();
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
		method: 'POST',
		url: api_url+"scheduled-payments/create-partial-payment-entry",
		data: pp_data+'&user_timezone='+timezone+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response){
			$('#editTransactionModal').modal('hide');
			display_error_message(response);
			localStorage.removeItem('account_role');
			getPropertyAccountingWidgetInfo('');
		}
	})
}

window.saveRentCollectionData = function(paymentArray) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/authorized-payments",
        data: 'payments='+paymentArray+'&user_timezone='+timezone+'&user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	$('.save_rent_collection_data').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#payNowModal').modal('hide');
            	var scheduled_payment_id = $('#selected_payment_id').val();

				getNotificationData();
            	// Enable next card
            	// $('#upcoming_payment_card_'+scheduled_payment_id).next('div.upcoming_payment_section').find('.authorize_rent_btn').removeClass('add_opacity');
            	// // Remove current card
            	// $('#upcoming_payment_card_'+scheduled_payment_id).remove();
            	// When authorized payment from detail page below function call and reload monthly card
            	getMonthlyRentWidgetInfo();
            	// var notification = parseInt($('#notification_count').text());
            	// var total_notification = notification - 1;
            	// $('#notification_indicator, #notification_count').text(total_notification);
            }
        }
    });
}

var $rentCollectionForm;
window.addRentCollectionValidation = function() {
	$rentCollectionForm = $("#rent-collection-wizard");
	$rentCollectionForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "payment_due_date[]": { required: true },
	        "payment_detail_id[]": { required: true },
			"ps_payment_detail" : { required: true }
	    },
	    messages: {
	        "payment_due_date[]": { required: '' },
	        "payment_detail_id[]": { required: '' },
			"ps_payment_detail" : { required: '' }
	    }
	});
}

var $validateEditTransaction;
window.editTransactionValidation = function(){
	$validateEditTransaction = $("#partial-transaction-form");
	$validateEditTransaction.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
			"payment_due_date": { required: true },
	        // "payment_detail_id": { required: true },
			"partial_pay_amount": { required: true },
			"pp_due_date": { required: true },
		},
	    messages: {
			"payment_due_date": { required: '' },
	        // "payment_detail_id": { required: '' },
			"partial_pay_amount": { required: '' },
			"pp_due_date": { required: '' },
	    }
	});
}

var $validatePartialTransaction;
window.partialTransactionValidation = function(){
	$validatePartialTransaction = $("#partial-transaction-form");
	$validatePartialTransaction.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
			"payment_due_date": { required: true },
	        // "payment_detail_id": { required: true },
			"partial_pay_amount": { required: true },
			"pp_due_date": { required: true },
		},
	    messages: {
			"payment_due_date": { required: '' },
	        // "payment_detail_id": { required: '' },
			"partial_pay_amount": { required: '' },
			"pp_due_date": { required: '' },
	    }
	});
}

var $autopayForm;
window.addAutopayValidation = function() {
	$autopayForm = $("#autopay-collection-wizard");
	$autopayForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
			"is_autopay": { required : true },
	        "autopay_date": { required: true },
	        "autopay_payment_detail_id": { required: true }
	    },
	    messages: {
			"is_autopay": { required : '' },
	        "autopay_date": { required: '' },
	        "autopay_payment_detail_id": { required: '' }
	    }
	});
}

var $validatePSPaymentDetails;
window.psPaymentDetailsValidation = function(){
	$validatePSPaymentDetails = $("#ps-payment-details-form");
	$validatePSPaymentDetails.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
			"ps_sender_user_id": { required: true },
	        "ps_sender_payment_detail_id": { required: true },
			"ps_receiver_user_id": { required: true },
			"ps_receiver_payment_detail_id": { required: true },
		},
	    messages: {
			"ps_sender_user_id": { required: '' },
	        "ps_sender_payment_detail_id": { required: '' },
			"ps_receiver_user_id": { required: '' },
			"ps_receiver_payment_detail_id": { required: '' },
	    }
	});
}

/*  LInk Bank to Property*/

window.getPropertyListForPayment = function(user_id, payment_method_id, payment_method_name) {
	var payment_method_name = payment_method_name.split('&&');
	$.ajax({
        method: 'POST',
        url: api_url+"properties/link-payment-property-list",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<div class="rent-detail-card text-center p-3 bg-white"><h5 class="card-title mb-0 grey">Select property for payment method</h5><div class="fs-18 mt-2 bold-black">'+payment_method_name[0]+' ('+payment_method_name[1]+')</div></div>';
            	html += '<input type="hidden" name="selected_payment_method_id" id="selected_payment_method_id" value="'+payment_method_id+'">';
            	html += '<input type="hidden" name="selected_payment_method_name" id="selected_payment_method_name" value="'+payment_method_name+'">';
            	html += '<select name="bank_property_dd" class="form-control bank_property_dd mt-3">';
	            	html += "<option value=''>Select Property</option>";
	                $.each( response.propertyList, function( key, value ) {
					  	html += '<option data-module="Bank" value="'+key+'">'+value+'</option>';
					});
				html += '</select>';
				html += '<div id="rent_deposit_subscription_container" class="mt-3"></div>';

				$('#bp_dropdown_container').html(html);
            }
        }
    });
}

window.getBankListForProperty = function(user_id, property_id, property_name) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:'user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<div class="rent-detail-card text-center p-3 bg-white"><h5 class="card-title mb-0 grey">Select bank account to deposit monthly rent for</h5><div class="fs-18 mt-2 bold-black">'+property_name+'</div></div>';
            	html += '<input type="hidden" name="selected_property_id" id="selected_property_id" value="'+property_id+'">';
            	html += '<select name="bank_property_dd" class="form-control bank_property_dd mt-3">';
	            	html += "<option value=''>Select Payment Method</option>";
	            	for (var key in response.userBanks) {
		                if (response.userBanks.hasOwnProperty(key)) {
							if(response.userBanks[key].account_number){
								var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
								html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'">'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
							} else {
								var payment_method = response.userBanks[key].bank_name;
								html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'">'+response.userBanks[key].bank_name+'</option>';
							}
						}
					}
				html += '</select>';

				html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form mt-5 mb-5" action="javascript:void(0)">';
				    html += '<div id="linkPropertyCollapse">';
						html += '<div class="col-auto d-sm-block mb-2 mt-4">';
				            html += '<div class="rent_deposit_checkbox">';
				                html += '<label class="custom-control custom-checkbox m-0">';
				                html += '<input name="is_authorized" id="property_bank_authorize_checkbox" type="checkbox" class="custom-control-input">';
				                html += '<span class="custom-control-label">I agree and authorize this above bank account to be linked with the selected property, process and deposit monthly rent by the Dwolla payment system. In order to cancel this authorization, I will change the payment settings within my account.</span>';
				                html += '</label>';
				            html += '</div>';
				        html += '</div>';
	                html += '</div>';
				html += '</form>';

				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {

					html += '<div class="border-top">';
	                    html += '<div class="row">';
	                        html += '<div class="col-auto ml-auto text-right mt-2 mb-2 mr-2">';
	                            html += '<button type="button" data-module="Property" class="btn btn-orange authorize_rent_subscription_button" disabled="">Agree & Authorize</button>';
	                        html += '</div>';
	                    html += '</div>';
	                html += '</div>';

					$('#signup_data_container').html('<div class="row mt-5"><div class="col-sm-12 col-md-12 col-lg-6 mx-auto border">'+html+'</div></div>');
				} else {
					$('#bp_dropdown_container').html(html);
				}
            }
        }
    });
}

window.getBankListDD = function() {
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
       	method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:'user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(first_time_signup == 1 && response.totalBanks == 0) {
        		$('#selected_setup_fee_payment_id').val(0);
				$('.save_property_data').attr('disabled', false);
				$('.setup_fee_payment_selection_container').addClass('d-none');
        	} else {
        		display_error_message(response);
        	}
        	if(response.response_data.success == true) {
            	var html = '';
				for (var key in response.userBanks) {
					if (response.userBanks.hasOwnProperty(key)) {
						if(first_time_signup == 1) {
							var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
							if(response.userBanks[key].account_number == null){
								html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" selected>'+response.userBanks[key].bank_name+'</option>';
							} else{
								html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" selected>'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
							}
						} else {
							if(response.userBanks[key].status == 2) {
								var selected = '';
								if(response.totalBanks == 1){
									selected = 'selected';
								}
								var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
								if(response.userBanks[key].account_number == null){
									html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" '+selected+'>'+response.userBanks[key].bank_name+'</option>';
								} else{
									html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" '+selected+'>'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
								}
							}
						}
					}
				}
				$('.bank_property_dd').append(html);
				if(first_time_signup == 1) {
					$('.bank_property_dd').attr('readonly', true);
					setTimeout(function() {
						$('.bank_property_dd').addClass('null_pointer');
					}, 200);
				}
			}
        }
    });
}

/* Authorized Rent and Subscription */

window.authorizeRentDepositData = function(module_name, property_id, payment_method_id, is_authorized) {
	$('.authorize_rent_subscription_button').attr('disabled',true);
	$('.save_link_bank_to_property').attr('disabled',true);
	var user_id = user.user_details.user_id;
	var first_time_signup = $('#first_time_signup').val();
	//var total = $('#rent_subscription_total').val();
	//var is_negative_total = $('#is_negative_total').val();

	$.ajax({
        method: 'POST',
        url: api_url+"properties/authorized-link-property-payment",
        //data: 'user_id='+user_id+'&property_id='+property_id+'&payment_details_id='+payment_method_id+'&is_authorized='+is_authorized+'&total='+total+'&is_negative_total='+is_negative_total,
        data: 'user_id='+user_id+'&property_id='+property_id+'&payment_details_id='+payment_method_id+'&is_authorized='+is_authorized+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var first_time_signup = $('#first_time_signup').val();
            	if(first_time_signup == 1) {
            		// Checkilist step 13
					getSignupNextStep(13,14);

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 13;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
					window.location.href = site_url+'dashboard.html?action=properties';
            	} else {
	            	$('#linkPropertyModal').modal('hide');
	            	if(module_name == 'Bank') {
	            		loadTablePaymentDetail();
	            	} else if(module_name == 'Property') {
	            		$('#main_container').load('components/properties.html');
	            	}
	            }
            } else {
            	$('.authorize_rent_subscription_button').attr('disabled',false);
            	$('.save_link_bank_to_property').attr('disabled',false);
            }
        }
    });
}

/* End : Rent Collection Code End here */

window.checkIfTenantLinkedWithLease = function(property_id,tenant_id,tenant_user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/get-lease-detail",
        data: 'property_id='+property_id+'&tenant_id='+tenant_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	//display_error_message(response);
            if(response.response_data.success == true) {
            	$('#inviteTenantModal').modal('show');
            	$('#inviteTenantModal #invite_tenant_step_container').load('components/property/invite_tenant.html', function(){
            		$('#it_selected_lease_id').val(response.tenantLease.lease_id);
            		$('#it_selected_tenant_user_id').val(tenant_user_id);
					$('#rs_invite_tenant_id').val(tenant_id);
					$('#rs_invite_tenant').val(1);
					// //var type = 'PO';
					// //getInviteTenantZohoTemplateList(type);
					$('#invite_tenant_data_container').load('components/property/edit_tenant.html', function() {
						if(signature_enabled == 'docusign' || signature_enabled == 'zoho_document'){
							$('.confirm_edit_tenant').parent().removeClass('card border');
							$('.invite_tenant_title').text('Confirm tenant details');
						}
						// $('#tenant_edit_form .card-body').removeClass('card-body');
						$('.confirm_edit_tenant').remove();
						$('.update_tenant_data').text('Next');
						$('.update_tenant_data').attr('data-button', 'invite');
						getTenantDetailById(tenant_id);
					});
				});
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getInviteTenantZohoTemplateList = function(type) {
	openLoadingDialog($('#invite_tenant_data_container'));
    var property_id = $('#selected_property_id').val();
    var lease_id = $('#it_selected_lease_id').val();
    var tenant_user_id = $('#it_selected_tenant_user_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"zoho-templates",
        data: 'property_id='+property_id+'&lease_id='+lease_id+'&tenant_user_id='+tenant_user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
        	var po_checked = '';
        	var t_checked = '';
        	if(type == 'PO') {
        		var po_checked = 'checked';
        	} else if(type == 'T') {
        		var t_checked = 'checked';
        	}

        	var html = "";
        	var dd_html = "";

        	dd_html += '<div class="row pt-4 bg-white d-none">';
	        	dd_html += '<div class="form-group col-md-12 col-xl-12">';
				    dd_html += '<div class="form-row">';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="po" value="'+po_role+'" '+po_checked+'>';
				                    dd_html += '<span class="custom-control-label">Property Owner</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="t" value="'+t_role+'" '+t_checked+'>';
				                    dd_html += '<span class="custom-control-label">Tenant</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				    dd_html += '</div>';
				dd_html += '</div>';
			dd_html += '</div>';

			html += dd_html;

        	/* Document List */

        	html += '<h4 class="m-3 document_title">Document List</h4>';
        	html += '<div id="sign_doc_container" class="row mt-2 bg-white">';
        	for (var key in response.zohoTemplates) {
	            if (response.zohoTemplates.hasOwnProperty(key)) {

	            	var user_class = '';
	            	var template_disabled = 'add_opacity';
	            	if(response.zohoTemplates[key].account_type_label == '1dc82947-e650-494a-b798-986a6f701d1f') { // PO
	            		user_class = 'po';
	            		template_disabled = '';
	            	} else if(response.zohoTemplates[key].account_type_label == 'c963a422-edb4-4bad-97ed-276c5abb3b79') { // TENANT
	            		user_class = 't';
	            		if(response.zohoTemplates[key].send_in_order == 0) {
							template_disabled = '';
	            		}
	            	}

	            	if(type == 'PO') {
	            		var account_type = '1dc82947-e650-494a-b798-986a6f701d1f';
	            	} else if(type == 'T') {
	            		var account_type = 'c963a422-edb4-4bad-97ed-276c5abb3b79';
	            	}

	            	var signature_required = response.zohoTemplates[key].signature_rquired;
					var msg = '<small class="grey">('+signature_required+' Signature Required)</small>';

					var add_opacity = '';
					if(response.zohoTemplates[key].disabled == 1) {
						msg = '<small class="red">('+response.zohoTemplates[key].message+')</small>';
						add_opacity = 'add_opacity';
					}

	            	if(response.zohoTemplates[key].account_type == account_type) {
		            	html += '<div class="col-auto col-md-12 col-xl-12 flex account_type_'+user_class+' '+template_disabled+' '+add_opacity+'">';
		                    html += '<div class="flex-fill">';
		                        html += '<div class="mt-2 mb-2">';
		                            html += '<div class="black"><label class="custom-control custom-radio" style="word-break:break-all"><input name="document_name" type="radio" class="custom-control-input zoho_template_radio" data-id='+response.zohoTemplates[key].id+' data-signature-required="'+signature_required+'"><span class="custom-control-label">'+response.zohoTemplates[key].zoho_templateName+' '+msg+'</span></label></div>';
		                        html += '</div>';
		                    html += '</div>';
		                html += '</div>';
		            }
			    }
			}
			html += '</div>';

			if(type == 'PO' && response.POTemplateStatus > 0) {
				$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.poMessage+'</h3></div>');
				$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange go_to_tenant_lease_section">Next</button></div></div></div>');
			//} else if(type == 'T' && response.TTemplateStatus > 1) {
			} else if(type == 'T' && response.tMessage != '') {
				$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.tMessage+'</h3></div>');
				if(new_payment_schedule_enabled == 'true'){
					var redirect_class = 'go_to_invite_tenant_section';
				} else {
					var redirect_class = 'go_to_fm_payment_section';
				}
				$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange '+redirect_class+'">Next</button></div></div></div>');
			} else {
				$('#invite_tenant_data_container').html(html);
			}
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getZohoTemplateList = function() {

	$('.save_template_data').attr('disabled', true);
	openLoadingDialog($('#template_list_container'));

    var property_id = $('#selected_property_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"zoho-templates",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {

        	var html = "";
        	var dd_html = "";

        	dd_html += '<div class="row pt-4 bg-white">';
	        	dd_html += '<div class="form-group col-md-12 col-xl-12">';
				    dd_html += '<div class="form-row">';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="po" value="'+po_role+'">';
				                    dd_html += '<span class="custom-control-label">Property Owner</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="t" value="'+t_role+'">';
				                    dd_html += '<span class="custom-control-label">Tenant</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				    dd_html += '</div>';
				dd_html += '</div>';
			dd_html += '</div>';

			html += dd_html;

			/* Lease List For Tenant */
			html += '<div id="active_lease_container" class="row mt-2 d-none"></div>';
        	/* Document List */

        	html += '<h4 class="m-3 document_title d-none">Document List</h4>';
        	html += '<div id="sign_doc_container" class="row mt-2 bg-white">';
        	for (var key in response.zohoTemplates) {
	            if (response.zohoTemplates.hasOwnProperty(key)) {

	            	var user_class = '';
	            	var template_disabled = 'add_opacity';
	            	if(response.zohoTemplates[key].account_type_label == '1dc82947-e650-494a-b798-986a6f701d1f') { // PO
	            		user_class = 'po';
	            		template_disabled = '';
	            	} else if(response.zohoTemplates[key].account_type_label == 'c963a422-edb4-4bad-97ed-276c5abb3b79') { // TENANT
	            		user_class = 't';
	            		if(response.zohoTemplates[key].send_in_order == 0) {
							template_disabled = '';
	            		}
	            	}

	            	var signature_required = response.zohoTemplates[key].signature_rquired;
	            	var msg = '<small class="grey">('+signature_required+' Signature Required)</small>';

	            	var add_opacity = '';
	            	if(response.zohoTemplates[key].disabled == 1) {
	            		msg = '<small class="red">('+response.zohoTemplates[key].message+')</small>';
	            		add_opacity = 'add_opacity';
	            	}

	            	html += '<div class="col-auto col-md-12 col-xl-12 flex d-none account_type_'+user_class+' '+template_disabled+' '+add_opacity+'">';
	                    html += '<div class="flex-fill">';
	                        html += '<div class="mt-2 mb-2">';
	                            html += '<div class="black"><label class="custom-control custom-radio" style="word-break:break-all"><input name="document_name" type="radio" class="custom-control-input zoho_template_radio" data-id='+response.zohoTemplates[key].id+' data-signature-required="'+signature_required+'"><span class="custom-control-label">'+response.zohoTemplates[key].zoho_templateName+' '+msg+'</span></label></div>';
	                        html += '</div>';
	                    html += '</div>';
	                html += '</div>';
			    }
			}
			html += '</div>';

			/*if(response.zohoTemplates != null) {
	        	$('.save_template_data').attr('disabled', false);
	        }*/

	        $('#template_list_container').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getZohoTemplateSignerList = function(zoho_template_id,user_type,property_id,lease_id,signature_required) {

	$('.save_template_data').attr('disabled', false);

	// start : #635 When we add signature document from documents section
	if($('.add_document_lease_dd').length > 0) {
		lease_id = $('.add_document_lease_dd').val();
	}
	// end : #635 When we add signature document from documents section

	var data = 'zoho_template_id='+zoho_template_id+'&user_type='+user_type+'&property_id='+property_id+'&lease_id='+lease_id;
	var url = api_url+"user-templates/get-template-signature-details";

	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var allRecipients = response.arrRecipints;
            	var totalRecipients = Object.keys(allRecipients).length + 1;
            	if(signature_required != totalRecipients) {
            		$('.save_template_data').addClass('d-none');
            		var response = {
                        "response_data": {
                            "success": false,
                            "message": 'Please select document with '+totalRecipients+' signature required'
                        }
                    };
                    display_error_message(response);
            	} else {
            		$('.save_template_data').removeClass('d-none');

	            	var html = "";
	            	var dd_html = "";

	            	/* Create Tenants Dropdown */

		    		dd_html += "<option value=''>--- Select User ---</option>";
				    $.each( response.arrRecipints, function( key, value ) {
			        	dd_html += '<option value="'+key+'">'+value+'</option>';
					});

				    /* Create Roles */

	            	html += '<div id="zohoSignerListContainer" class="row mt-2 bg-white">';
		            	html += '<h4 class="m-3">Signer List</h4>';
		            	html += '<form id="document-signer-list">';
			            	for (var key in response.arrSignatureDetails) {
				            	if (response.arrSignatureDetails.hasOwnProperty(key)) {
				            		html += '<div class="form-group col-md-12 col-xl-12">';
									    html += '<div class="form-row">';
									        html += '<div class="col-md-6">';
									            html += '<input name="role_name" type="text" class="form-control" value="'+response.arrSignatureDetails[key].recipients+'" disabled>';
									        html += '</div>';
									        html += '<div class="col-md-6">';
									        	if(key == 0) {
									            	html += response.arrSignatureDetails[key].label;
									            } else {
									            	html += '<select name="property_tenant_dd[]" id="property_tenant_dd_'+key+'" class="form-control property_tenant_dd">'+dd_html+'</select>';
									            }
									        html += '</div>';
									    html += '</div>';
									html += '</div>';
								}
							}
						html += '</form>';
					html += '</div>';

					var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						$('#invite_tenant_data_container').append(html);
						$('#save_btn').remove();
						if(user_type == po_role) {
							$('#invite_tenant_data_container').append('<div id="save_btn" class="row"><div class="col-auto ml-auto text-right mt-3 mb-2"><button type="button" class="btn btn-orange save_template_data">Send for Signature</button></div></div>');
						} else if(user_type == t_role) {
							$('#invite_tenant_data_container').append('<div id="save_btn" class="row"><div class="col-auto ml-auto text-right mt-3 mb-2"><button type="button" class="btn btn-orange save_template_data">Next</button></div></div>');
						}
					} else {
						$('#template_list_container').append(html);
					}

					documentSignerValidation();
				}
            }
        }
    });
}

var $documentSignerForm;
window.documentSignerValidation = function() {
	$documentSignerForm = $("#document-signer-list");
	$documentSignerForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	        $(element).parents(".form-group").addClass('text-right');
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "property_tenant_dd[]": { required: true, check_duplicate_tenant: true }
	    },
	    messages: {
	        "property_tenant_dd[]": { required: '', check_duplicate_tenant: 'Tenant should not be same' }
	    }
	});

	$.validator.addMethod("check_duplicate_tenant", function(value, element) {
		var flag = true;
		var current_value = $(element).val();
		var current_id = $(element).attr('id');
		$("select[name^='property_tenant_dd']").each(function(key, value) {
		    var element_value = $(this).val();
		    var element_id = $(this).attr('id');
		    if(current_id != element_id) {
		    	if(current_value == element_value) {
		    		flag = false;
		    	}
		    }
		});
		return flag;
	});
}

window.assignedZohoTemplate = function(zoho_template_id,user_type,property_id,userArray) {
	if($documentSignerForm.valid()){
		var user_id = user.user_details.user_id;
		// start : #635 When we add signature document from documents section
		if($('.add_document_lease_dd').length > 0) {
			var lease_id = $('.add_document_lease_dd').val();
		} else {
			var lease_id = $('#it_selected_lease_id').val();
		}
		// end : #635 When we add signature document from documents section
		var data = 'user_id='+user_id+'&zoho_template_id='+zoho_template_id+'&user_type='+user_type+'&property_id='+property_id+'&lease_id='+lease_id+'&users='+userArray;
		var url = api_url+"user-templates/assign-property-template";
		$.ajax({
	        method: 'POST',
	        url: url,
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(data) {
	            display_error_message(data);
	            if(data.response_data.success == true) {
	            	// Below code will check that from where function is called based on that it will load data
	            	var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						if(user_type == po_role) {
							sendForSignature(zoho_template_id,property_id);
						} else if(user_type == t_role) {
							// getSignupNextStep(2,3);
							if($('#can_authorize_payment').prop('checked') == false || new_payment_schedule_enabled == 'true'){
								getSignupNextStep(3,4);
								getSignupNextStep(4,5);
								openLoadingDialog($('#invite_tenant_data_container'));
								$('.invite_tenant_title').text('Invite Tenant');
								$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
							} else {
								getSignupNextStep(3,4);
								openLoadingDialog($('#invite_tenant_data_container'));
								setTimeout(function(){
									var property_name = $('#navbar_role_name').text();
									getLeaseListForProperty(property_id, property_name);
								}, 500);
							}
						}
					} else {
						$('#templateListModal').modal('hide');
	            		getPropertyDocument();
					}
					getPropertyDocumentWidgetInfo();
	            } else {
	            	$('.save_template_data').attr('disabled',false);
	            }
	        }
	    });
	} else {
		$('.save_template_data').attr('disabled',false);
		var rs_invite_tenant = $('#rs_invite_tenant').val();
		if(rs_invite_tenant == 1) {
			if(user_type == po_role) {
				$('.save_template_data').html('Send for Signature');
			} else {
				$('.save_template_data').html('Next');
			}
		}
	}
}

window.sendForSignature = function(zoho_template_id,property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/doc-sign",
        data: 'zoho_template_id='+zoho_template_id+'&property_id='+property_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	// Below code will check that from where function is called based on that it will load data
            	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					if($('#tenant_signature_required').val() == 'true'){
						// getSignupNextStep(1,2);
						getSignupNextStep(2,3);
						var type = 'T';
						getInviteTenantZohoTemplateList(type);
					} else if($('#can_authorize_payment').val() == 'false'){
						getSignupNextStep(3,4);
						getSignupNextStep(4,5);
						openLoadingDialog($('#invite_tenant_data_container'));
						$('.invite_tenant_title').text('Invite Tenant');
						$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
					} else {
						getSignupNextStep(2,3);
						getSignupNextStep(3,4);
						openLoadingDialog($('#invite_tenant_data_container'));
						setTimeout(function(){
							var property_name = $('#navbar_role_name').text();
							getLeaseListForProperty(property_id, property_name);
						}, 500);
					}
				} else {
					getPropertyDocument();
					getPropertyDocumentWidgetInfo();
				}
            } else {
            	$('.send_for_signatures').removeClass('add_opacity');
            }
        }
    });
}

window.deleteUserTemplate = function(user_template_id) {
	var logged_in_user = user.user_details.user_id;
	var user_id = $('#selected_propoerty_user_id').val();
	var url = api_url+"user-templates/delete";
    var data = 'id='+user_template_id+'&logged_in_user='+logged_in_user;
	$.ajax({
	    method: 'POST',
	    url: url,
	    data: data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    display_error_message(response);
	    if(response.response_data.success == true) {
	        $('#user_template_'+user_template_id).remove();
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.uploadFile = function(file,module_name) {
	$.ajax({
        method: 'POST',
        url: api_url+"user-attachments/reports",
        data: getFileObj(module_name, file),
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(module_name == 'BackgroundCheckReport') {
            	backgroundCheckDropzone.removeFile(file);
            } else if(module_name == 'CreditReport') {
            	creditCheckDropzone.removeFile(file);
            }
            getUserBackCreditReportFile(module_name);
        }
    });
}

window.getFileObj = function(module_name, file) {
	var user_id = user.user_details.user_id;
    var formData = new FormData();
    formData.append('module_name', module_name);

    if(module_name == 'BackgroundCheckReport') {
    	var selected_user = $('#selected_user_id').val();
    	formData.append('attachment', file); // attach dropzone image element
    	formData.append('user_id', selected_user);
    	formData.append('user_id', user_id);
    	formData.append('module_name', 'background_check_report');
    } else if(module_name == 'CreditReport') {
    	var selected_user = $('#selected_user_id').val();
    	formData.append('attachment', file); // attach dropzone image element
    	formData.append('user_id', selected_user);
    	formData.append('user_id', user_id);
    	formData.append('module_name', 'credit_report');
    } else if(module_name == 'Property') {
    	var property_id = $('#selected_property_id').val();
    	formData.append("attachment[]", file);
	    formData.append('property_id', property_id);
	    formData.append('user_id', user_id);
	    formData.append('module_name', 'property_image');
    } else if(module_name == 'Property Checklist') {
    	var property_id = $('#selected_property_id').val();
    	formData.append("checklist_attachment[]", file);
	    formData.append('property_id', property_id);
	    formData.append('user_id', user_id);
	    formData.append('module_name', 'property_document');
    }

    return formData;
}

window.loadUserLists = function() {
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;
	var user_id = user.user_details.user_id;
	var ui = 'ai';

	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    data: 'user_id='+user_id+'&ui='+ui,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        if(response.TotalUsers == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
					html += '<div class="assument_dientity_user ml-4 mr-4">';
						html += '<input type="text" name="search" id="search_user" class="form-control search_input my-3" autocomplete="off" autofocus placeholder="Search user ..."></input>';
						for (var key in response.users) {
							if (response.users.hasOwnProperty(key)) {
								var name = response.users[key].first_name+' '+response.users[key].last_name;
								var email = response.users[key].email;
								html += '<a data-id="'+response.users[key].id+'" data-name="'+name+'" data-email="'+response.users[key].email+'" href="javascript:void(0)" class="list-group-item get_assumed_user_data no_underline"><div class="row no-gutters align-items-center media"><div class="col-1 bold-black"><i class="far fa-user"></i></div><div class="col-11 pl-2"><div class="bold-black">'+name+'<br><small>'+email+'</small></div></div></div></a>';
							}
						}
					html += "</div>";
			    }
			    $('#sidebar_content_area').html(html);
				$('#search_user').focus();
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.getUserData = function() {
	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        if(response.TotalUsers == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	var html = '';
	    			html += "<option value=''>Select Owner</option>";
			    	for (var key in response.users) {
			            if (response.users.hasOwnProperty(key)) {
			                var name = response.users[key].first_name+' '+response.users[key].last_name;
			                var email = response.users[key].email;
			                var id = response.users[key].id;
			                var selected = '';
			                html += '<option value="'+id+'" '+selected+'>'+name+' ('+email+')</option>';
			            }
			        }
			    }
			    $('#group_owner').html(html);
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.loadHeaderPropertiesList = function(module_name = '') {
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;

	var method = 'POST';
	var url = api_url+"properties";
	var data = 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

	$.ajax({
	    method: method,
	    url: url,
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    if(response.response_data.success == true) {
	    	if(module_name == 'add_new_ticket') {
	    		var property_id = $('#selected_property_id').val();
	    		var ticket_property_id = $('#ticket_property_id').val();
	    		var html = '';
	    		html += "<option value=''>Select Property</option>";
			    for (var key in response.Properties) {
			        if (response.Properties.hasOwnProperty(key)) {
			            var id = response.Properties[key].id;
			            var name = response.Properties[key].name;
			            var selected = '';
			            if(ticket_property_id == id || response.TotalProperties == 1) {
			            	selected = "selected";
			            }
			            html += '<option value="'+id+'" '+selected+'>'+name+'</option>';
			        }
			    }
			    $('#tickets-property-id').html(html);
			    if(user_service_name == sa_role) {
			    	getPropertyRelatedUsers(ticket_property_id);
			    }
	    	} else if(module_name == 'expenses') {
				var selected = '';
				if(Object.keys(response.Properties).length == 1){
					selected = 'selected';
				}
				var html = '';
	    		html += "<option value=''>Select Property</option>";
			    for (var key in response.Properties) {
			        if (response.Properties.hasOwnProperty(key)) {
			            var id = response.Properties[key].id;
			            var name = response.Properties[key].name;
			            html += '<option value="'+id+'" '+selected+'>'+name+'</option>';
			        }
			    }
			    $('#inputProperty').html(html);
			} else {
	    		var html = "";
		        if(response.TotalProperties == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	for (var key in response.Properties) {
			            if (response.Properties.hasOwnProperty(key)) {
			                var name = response.Properties[key].name;
			                html += '<a data-id="'+response.Properties[key].id+'" data-name="'+name+'" href="javascript:void(0)" class="list-group-item get_property_chat"><div class="row no-gutters align-items-center media"><div class="col-2"><img src="icon/property.svg" class="rounded-circle mr-1" alt="'+name+'" width="50" height="50"></div><div class="col-10 pl-2"><div class="text-dark">'+name+'</div></div></div></a>';
			            }
			        }
			    }
			    $('#properties_indicator').html(response.TotalProperties);
			    $('#header_properties_count').html('Total '+response.TotalProperties+' Properties');
		        $('#header_property_info_container').html(html);
	    	}
	    } else {
	        display_error_message(response);
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.loadVerticalProperty = function() {

	localStorage.removeItem('property_name');

	// Get list of user properties
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;

	var propertyIDs = $('.filter_property_name').val();
	var tenantIDs = $('.filter_tenant_name').val();
	var leaseIDs = $('.filter_lease_detail').val();
	var transactionIDs = $('.filter_transaction_status').val();

	var method = 'POST';
	var url = api_url+"properties";
	var data = 'propertyIDs='+propertyIDs+'&tenantIDs='+tenantIDs+'&leaseIDs='+leaseIDs+'&transactionIDs='+transactionIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

	$.ajax({
	    method: method,
	    url: url,
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		if(response.TotalProperties == 1 && response.search_enable == 0) {
		    		var property_id = response.Properties[0].id;
		    		var payment_status = response.Properties[0].payment_status;
		    		var property_name = response.Properties[0].name;
		    		loadPropertyCard(response, 'leftmenu');
		    	} else {
		    		loadPropertyCard(response, 'leftmenu');
		    	}
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.loadPropertyCard = function(response, open_from) {
	var html = "";
	var table_html = "";
    if(response.TotalProperties == 0) {
    	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
    	table_html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
    } else {
    	var layout = $('#selected_layout').val();
    	if(layout == 'table_layout') {
			$('#property_search_col').removeClass('col-lg-12').addClass('col-lg-8');
		} else {
			$('#property_search_col').removeClass('col-lg-8').addClass('col-lg-12');
		}

		if(layout == 'table_layout') {
			table_html += '<div class="col-auto col-md-12 col-lg-12 col-xl-12">';
				table_html += '<div class="row justify-content-center">';
			        table_html += '<div id="expensesDataContainer" class="col-auto col-md-12 col-lg-12 col-xl-8">';
			            table_html += '<div id="search_section_container"></div>';
			            table_html += '<div class="card">';
			                table_html += '<div class="card-body">';
			                    table_html += '<table id="property-datatable" class="table card_datatable" cellspacing="10" cellpadding="10" style="width: 100%;">';
			                        table_html += '<thead class="bold-black fs-14 d-none">';
			                            table_html += '<tr>';
			                                table_html += '<th>Name</th>';
			                            table_html += '</tr>';
			                        table_html += '</thead>';
			                        table_html += '<tbody id="property_table_row" class="dt_card_body">';
		}

    	for (var key in response.Properties) {
		    if (response.Properties.hasOwnProperty(key)) {
		    	var p_id = response.Properties[key].id;
		        var name = response.Properties[key].name;
		        //var property_name = name.toLowerCase();
				var property_name = name;
		        var address_line1 = "";
		        var address_line2 = "";
		        var city = "";
		        var state = "";
		        var zip_code = "";
		        var payment_status = "";
		        var kebab = "";

		        if(response.Properties[key].address != null) {
		        	if(response.Properties[key].address.address_line1 != "") {
		                address_line1 = response.Properties[key].address.address_line1;
		                address_line1 = address_line1.toLowerCase();
		            }
		            if(response.Properties[key].address.address_line2 != "") {
		                address_line2 = response.Properties[key].address.address_line2
		                address_line2 = ', '+address_line2.toLowerCase();
		            }
		            if(response.Properties[key].address.city != "") {
		                city = response.Properties[key].address.city;
		                city = city.toLowerCase();
		            }
		            if(response.Properties[key].address.state != "") {
		                state = response.Properties[key].address.state;
		                state = state.toUpperCase();
		            }
		            if(response.Properties[key].address.zip_code != "") {
		                zip_code = response.Properties[key].address.zip_code;
		            }
		        }

		        // Set kebab menu conditions
		        var kebab_item = '';

		        if(user_service_name == sa_role) {
		        	// if(response.Properties[key].payment_details_id > 0 && response.Properties[key].payment_status == 2) {
					if(response.Properties[key].payment_status == 2) {
		        		kebab_item += '<a class="dropdown-item process_property_setup_fee" href="javascript:void(0)" data-id="'+p_id+'">Process Setup Fee</a>';
		        	}
		        }

            	if(user_service_name == sa_role || (user_service_name == po_role && response.Properties[key].payment_status == 1)) {
            		if(user.permissionData['PROPERTIES']["feature_items['property_edit']"]) {
                		kebab_item += '<a class="dropdown-item edit_property_address" href="javascript:void(0)" data-id="'+p_id+'" data-toggle="modal" data-target="#propertyEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
                	}
                }

                if(user.permissionData['PROPERTIES']["feature_items['property_delete']"]) {
		        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-module="Property" class="dropdown-item delete_property_data" href="javascript:void(0)">Delete</a>';
		        }

		        if($.trim(kebab_item) != '' && user_service_name != t_role) {
		        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
		        }

		        // Payment status conditions
		        if(user_service_name == sa_role) {
		        	if(response.Properties[key].payment_status == 1) {
			        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
			        } else if(response.Properties[key].payment_status == 2) {
			        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
			        } else if(response.Properties[key].payment_status == 3) {
			        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
			        } else if(response.Properties[key].payment_status == 4) {
			        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
			        }
			        html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card user_properties_list">';

			        table_html += '<tr data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="property_card user_properties_list">';
		        } else {
			        if(response.Properties[key].payment_status == 1 && user_service_name == po_role) {
			        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
			        	// html += '<div data-property-setup-fee_authorized="'+response.Properties[key].property_setup_fee_authorized+'" data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false" class="col-12 col-md-6 col-xl-3 property_card property_setup_fee">';

			        	// table_html += '<tr data-property-setup-fee_authorized="'+response.Properties[key].property_setup_fee_authorized+'" data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false" class="property_card property_setup_fee">';
			        } else if(response.Properties[key].payment_status == 2) {
			        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
			        	html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card property_pending_review">';

			        	table_html += '<tr data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="property_card property_pending_review">';
			        } else if(response.Properties[key].payment_status == 3) {
			        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
			        	html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card user_properties_list processing_setup_fee">';

			        	table_html += '<tr data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="property_card user_properties_list processing_setup_fee">';
			        } else if(response.Properties[key].payment_status == 4) {
			        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';

			        	// If proeprty linked with payment detail then when user click on card redirect to detail page
			        	// If not then ask for link payment method
			        	// if(response.Properties[key].payment_details_id > 0) {
			        		html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card user_properties_list">';

			        		table_html += '<tr data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" class="property_card user_properties_list">';
			        	// } 
						// else {
			        	// 	html += '<div data-property-setup-fee_authorized="'+response.Properties[key].property_setup_fee_authorized+'" data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false" class="col-12 col-md-6 col-xl-3 property_card property_setup_fee">';

			        	// 	table_html += '<tr data-property-setup-fee_authorized="'+response.Properties[key].property_setup_fee_authorized+'" data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+p_id+'" data-user-id="'+response.Properties[key].user.id+'" data-status="'+response.Properties[key].payment_status+'" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false" class="property_card property_setup_fee">';
			        	// }
			        }
			    }

			    var name = stripHtml(property_name);
				if (name.length > 35) {
				    var shortname = name.substring(0, 35) + " ...";
				    name = shortname;
				}

				var po_detail = '';
				if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
			        po_detail = '<h5>Property owner detail:</h5>'+response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'<br>'+response.Properties[key].user.email;
			    }

		        var cover_image = '';
		        if(response.Properties[key].cover_image != null) {
		        	cover_image = response.Properties[key].cover_image.aws_url;
		        } else {
		        	cover_image = "img/sample/home-placeholder-profile.png";
		        }

		        var phone = response.Properties[key].user.phone;
		        if(phone != null) {
		        	phone = phone.split('+1');
		        	phone = phone[1];
		        } else {
		        	phone = '&nbsp';
		        }

		        var lease_status = '';
		        var bg_class = '';
		        if(response.Properties[key].lease_status == 4) {
		        	lease_status += '<div class="badge badge-danger">Lease Expired</div>';
		        	bg_class = 'bg-azure';
		        }

		        if(layout == 'table_layout') {
		        	table_html += '<td>';
		        		table_html += '<div class="row card">';
		        			table_html += '<div class="card-header bg-light"><span class="accordion_arrow">'+kebab+' </span><h5 id="p_name_'+p_id+'" class="card-title capitalize bold-black mb-0 mr-3" title="'+stripHtml(property_name)+'">'+name+'</h5></div>';
		        			table_html += '<div class="row justify-content-center">';
								table_html += '<div class="col-12 col-md-3 col-lg-3 center no_border">';
									table_html += '<div class="img-container ml-3">';
										table_html += '<img src="'+cover_image+'" alt="Property Image">';
									table_html += '</div>';
								table_html += '</div>';
								table_html += '<div class="col-12 col-md-6 col-lg-6">';
									table_html += '<div class="card-body text-left">';
										table_html += '<table class="table mb-0 card-table word-break">';
												table_html += '<tbody>';
													if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
														table_html += '<tr>';
															table_html += '<td class="nowrap"><i class="far fa-user mr-1"></i></td>';
															table_html += '<td class="text-left bold-black">'+response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'</td>';
														table_html += '</tr>';
													}
														table_html += '<tr>';
															table_html += '<td class="nowrap"><i class="far fa-address-card mr-1"></i></td>';
															table_html += '<td class="text-left"><div class="capitalize">'+stripHtml(address_line1)+stripHtml(address_line2)+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
														table_html += '</tr>';
													if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
														table_html += '<tr>';
															table_html += '<td class="nowrap"><i class="far fa-envelope mr-1"></i></td>';
															table_html += '<td class="text-left">'+response.Properties[key].user.email+'</td>';
														table_html += '</tr>';
														table_html += '<tr>';
															table_html += '<td class="nowrap"><i class="fas fa-phone mr-1"></i></td>';
															table_html += '<td class="text-left phone_number">'+phone+'</td>';
														table_html += '</tr>';
													}
												table_html += '</tbody>';
										table_html += '</table>';
									table_html += '</div>';
								table_html += '</div>';
								if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
									table_html += '<div class="col-12 col-md-3 col-lg-3">';
										table_html += '<div class="card-body text-right">'+lease_status+' '+payment_status+'</div>';
									table_html += '</div>';
								}
							table_html += '</div>';
						table_html += '</div>';
		        	table_html += '</td></tr>';
		        } else {
			        html += '<div class="card">';
						html += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 id="p_name_'+p_id+'" class="card-title capitalize mb-0 mr-3" title="'+stripHtml(property_name)+'">'+name+'</h5></div>';
						html += '<div class="img-container b-top b-bottom">';
							html += '<img src="'+cover_image+'" alt="Property Image">';
						html += '</div>';
						html += '<div class="card-body '+bg_class+'">';
							html += '<table class="table mb-0 card-table word-break">';
								html += '<tbody>';
									if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
										html += '<tr>';
											html += '<td class="nowrap"><i class="far fa-user mr-1"></i></td>';
											html += '<td class="text-left bold-black">'+response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'</td>';
										html += '</tr>';
									}
										html += '<tr>';
											html += '<td class="nowrap"><i class="far fa-address-card mr-1"></i></td>';
											html += '<td class="text-left"><div class="capitalize">'+stripHtml(address_line1)+stripHtml(address_line2)+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
										html += '</tr>';
									if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
										html += '<tr>';
											html += '<td class="nowrap"><i class="far fa-envelope mr-1"></i></td>';
											html += '<td class="text-left">'+response.Properties[key].user.email+'</td>';
										html += '</tr>';
										html += '<tr>';
											html += '<td class="nowrap"><i class="fas fa-phone mr-1"></i></td>';
											html += '<td class="text-left phone_number">'+phone+'</td>';
										html += '</tr>';
									}
								html += '</tbody>';
							html += '</table>';
							if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
								html += '<div class="text-right mt-4">'+lease_status+' '+payment_status+'</div>';
							}
						html += '</div>';
					html += '</div>';
				}

				html += '</div>';
		    }
		}

		if(layout == 'table_layout') {
									table_html += '</tbody>';
						        table_html += '</table>';
			                table_html += '</div>';
			            table_html += '</div>';
			        table_html += '</div>';
			    table_html += '</div>';
			table_html += '</div>';

		    html = table_html;
		}
    }

    $('.property_data_container').html(html);
    $('.phone_number').mask('(000) 000-0000');

    if(layout == 'table_layout') {
		if ($.fn.DataTable.isDataTable("#property-datatable")) {
			$("#property-datatable").DataTable().destroy();
		}
		var user_table = $("#property-datatable").DataTable({
			ordering: false
		});
		$("#property-datatable tbody tr td:first").addClass('border-top');
	}

    // Get Floating Action Button
	getFloatingActionButton('Property', '.property_data_container');
}

window.loadTablePropertiesDetail = function() {
	localStorage.removeItem('property_name');
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;

	if(user_service_name == sa_role) {
		var method = 'GET';
		var url = api_url+"properties";
		var data = '';
	} else {
		var method = 'POST';
		var url = api_url+"properties/details";
		var data = 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;
	}

    $.ajax({
        method: method,
        url: url,
        data:data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
        	var html = '';
        	var table_html = '';
			if(response.TotalProperties == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
	            for (var key in response.Properties) {
	                if (response.Properties.hasOwnProperty(key)) {

	                	// Cover Image
	                	var cover_image = '';
		                if(response.Properties[key].cover_image != null) {
		                	cover_image = response.Properties[key].cover_image.aws_url;
		                } else {
		                	cover_image = "icon/property.svg";
		                }

		                // Address detail
	                	var p_id = response.Properties[key].id;
	                    var name = response.Properties[key].name;
	                    var property_name = name.toLowerCase();
	                    var name = stripHtml(property_name);
	                    var address_line1 = "";
				        var address_line2 = "";
				        var city = "";
				        var state = "";
				        var zip_code = "";
				        var payment_status = "";
				        var kebab = "";

				        if(response.Properties[key].address != null) {
				        	if(response.Properties[key].address.address_line1 != "") {
				                address_line1 = response.Properties[key].address.address_line1;
				                address_line1 = address_line1.toLowerCase();
				            }
				            if(response.Properties[key].address.address_line2 != "") {
				                address_line2 = response.Properties[key].address.address_line2
				                address_line2 = ', '+address_line2.toLowerCase();
				            }
				            if(response.Properties[key].address.city != "") {
				                city = response.Properties[key].address.city;
				                city = city.toLowerCase();
				            }
				            if(response.Properties[key].address.state != "") {
				                state = response.Properties[key].address.state;
				                state = state.toUpperCase();
				            }
				            if(response.Properties[key].address.zip_code != "") {
				                zip_code = response.Properties[key].address.zip_code;
				            }
				        }

				        // PO detail
				        var po_detail = response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'<br>'+response.Properties[key].user.email;

				        // Set kebab menu conditions
				        var kebab_item = '';
		                if(user_service_name == sa_role || (user_service_name == po_role && response.Properties[key].payment_status == 1)) {
		                	kebab_item += '<a class="dropdown-item edit_property_address" href="javascript:void(0)" data-id="'+p_id+'" data-toggle="modal" data-target="#propertyEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
		                }

				        if(user_service_name == sa_role && response.Properties[key].payment_status == 2) {
				        	kebab_item = '<a class="dropdown-item process_property_setup_fee" href="javascript:void(0)" data-id="'+p_id+'">Process Setup Fee</a>';
				        }

				        kebab_item += '<a data-id="'+response.Properties[key].id+'" data-module="Property" class="dropdown-item delete_property_data" href="javascript:void(0)">Delete</a>';

				        // Payment status conditions
				        var table_row = '';
				        if(user_service_name == sa_role) {
				        	if(response.Properties[key].payment_status == 1) {
					        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
					        } else if(response.Properties[key].payment_status == 2) {
					        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
					        } else if(response.Properties[key].payment_status == 3) {
					        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
					        } else if(response.Properties[key].payment_status == 4) {
					        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
					        }
					        table_html += '<tr>';
					        kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_table_row" href="javascript:void(0)">View</a>';
				        } else {
			                if(response.Properties[key].payment_status == 1) {
					        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	// kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_setup_fee" href="javascript:void(0)" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false">View</a>';
					        } else if(response.Properties[key].payment_status == 2) {
					        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_pending_review" href="javascript:void(0)">View</a>';
					        } else if(response.Properties[key].payment_status == 3) {
					        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item processing_setup_fee processing_setup_fee" href="javascript:void(0)">View</a>';
					        } else if(response.Properties[key].payment_status == 4) {
					        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_table_row" href="javascript:void(0)">View</a>';
					        }
					    }

					    if($.trim(kebab_item) != '' && user_service_name != t_role) {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
				        }

				        if(user_service_name == t_role) {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a data-id="'+response.Properties[key].id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_table_row" href="javascript:void(0)">View</a></div></div></div>';
				        }

					    var checked = '';
					    if(response.Properties[key].is_late_fee_enabled == 1) {
					    	checked = 'checked';
					    }

					    var checkbox = '';
	                    checkbox += '<label class="custom-control custom-checkbox">';
			            checkbox += '<input type="checkbox" name="late_fee_checkbox" class="custom-control-input late_fee_checkbox" data-id="'+response.Properties[key].id+'" '+checked+'>';
			            checkbox += '<span class="custom-control-label text-hide">Checkbox</span>';
			            checkbox += '</label>';

					    // Table based UI
	                		table_html += '<td class="bg-light">'+response.Properties[key].id+'</td>';
	                		table_html += '<td class="bg-light"><h5 id="p_name_'+response.Properties[key].id+'" title="'+property_name+'" class="card-title my-2 fs-16">'+name+'</h5><div class="small">'+address_line1+', '+address_line2+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
	                		table_html += '<td>'+po_detail+'</td>';
                            table_html += '<td class="text-center"><img src="'+cover_image+'" class="rounded-circle mr-1" alt="property name" width="50" height="50"></td>';
                            table_html += '<td class="text-center">'+payment_status+'</td>';
                            table_html += '<td class="text-center">'+kebab+'</td>';
                            if(user_service_name == sa_role) {
                            	table_html += '<td class="text-center">'+checkbox+'</td>';
                            	$('#late_fee_column').removeClass('d-none');
                            } else {
                            	$('#late_fee_column').remove();
                            }
                        table_html += '</tr>';

	                }
	            }
        	}

        	// Table UI

			$('#property_table_row').html(table_html);
			$('#propertyDataContainer').html(html);
        	// Get Floating Action Button
			getFloatingActionButton('Property', '#propertyDataContainer');

			var property_table = $("#property-datatable").DataTable({
				dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '550px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 2
		        },
		        buttons: [
		           {
		              extend: 'colvis',
		              text: "Columns Visibility ",
		              collectionLayout: 'two-column',
		              postfixButtons: ['colvisRestore'],
		           }
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    },
				initComplete: function () {
		          	//console.log("The Table has been initialized!")
		        }
			});

			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');

        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.loadPropertyDetailLayout = function(property_id, open_from, property_name) {
	$('.card_list_heading').hide();
	if(property_name == '') {
		var property_name = $('#p_name_'+property_id).attr('title');
	}
    if (localStorage.getItem("property_name") === null) {
    	localStorage.setItem('property_name',property_name);
    }
    $('#open_from').val(open_from);

    var container = '';
    if($('#property_search_container').length > 0) {
    	container = '.property_data_container';
    } else {
    	container = '#main_container';
    }

    openLoadingDialog($(container));
    setTimeout(function(){
	    $(container).load('components/property/detail.html', property_id , function(){
	    	$('#selected_property_id').val(property_id);
	        $('#open_from').val(open_from);
	        //getPropertyDetail(property_id,open_from);
	        $('.property_name').html(property_name);
	        $('#navbar_role_name').html(property_name);
	        $('.back_button').attr('data-layout',open_from);

	        if(!user.permissionData['PROPERTIES']["feature_items['lease_list']"]) {
	        	$('a[href="#p-lease"]').closest('li').remove();
				$('#p-lease').remove();
	        }

	        if(!user.permissionData['PROPERTIES']["feature_items['accounting_list']"]) {
	        	$('a[href="#p-accounting"]').closest('li').remove();
	        	$('#p-accounting').remove();
	        }

	        if(!user.permissionData['PROPERTIES']["feature_items['tenant_list']"]) {
	        	$('a[href="#p-tenants"]').closest('li').remove();
	        	$('#p-tenants').remove();
	        }

	        if(!user.permissionData['SUPPORT']["feature_items['list']"]) {
	        	$('a[href="#p-service-requests"]').closest('li').remove();
	        	$('#p-service-requests').remove();
	        }

	        getFloatingActionButton('PropertyDetail', '#main_container');
	    });
	}, 500);

	if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
		setTimeout(function(){
			disableForAssumeIdentity();
		}, 3000);
	}
}

window.updateLateFeeValue = function(property_id, late_fee_flag) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/update-late-fee-value",
        data: 'user_id='+user_id+'&property_id='+property_id+'&late_fee_flag='+late_fee_flag,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        }
    });
}

// window.checkIfPropertySetupFeeAuthorized = function(property_id) {
// 	var user_id = user.user_details.user_id;
// 	$.ajax({
//         method: 'POST',
//         url: api_url+"properties/check-if-property-setup-fee-authorized",
//         data: 'user_id='+user_id+'&property_id='+property_id,
//         beforeSend: function(xhr) {
// 	        xhr.setRequestHeader('Authorization', accessToken);
// 	    },
//         success: function(response) {
//         	if(response.isSetupFeeAuthorized == 1) {
// 				openLoadingDialog($('#signup_data_container'));

//         		// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
//         		openLoadingDialog($('#signup_data_container'));
// 				setTimeout(function(){
// 					getBankListForProperty(user_id, property_id, response.property_name);
// 				}, 500);
//             } else {
//     			checkIfPropertySetupPlanAvailable(property_id);
//             }
//         }
//     });
// }

// window.checkIfPropertySetupPlanAvailable = function(property_id) {
// 	var user_id = user.user_details.user_id;
// 	$.ajax({
//         method: 'POST',
//         url: api_url+"properties/get-setup-checkout-data",
//         data: 'user_id='+user_id+'&property_id='+property_id,
//         beforeSend: function(xhr) {
// 	        xhr.setRequestHeader('Authorization', accessToken);
// 	    },
//         success: function(response) {
//         	if(response.response_data.success == true) {

//         		// Below code for initial signup step
// 				var first_time_signup = $('#first_time_signup').val();
// 				if(first_time_signup == 1) {
// 					// Checkilist step 9
// 					getSignupNextStep(9,10);

// 					var user = JSON.parse(localStorage.user);
// 					user.user_details.cheklist_seq_no = 9;
// 					localStorage.setItem("user", JSON.stringify(user));  //put the object back

// 					openLoadingDialog($('#signup_data_container'));
// 					var container = '#signup_data_container';
// 				} else {
// 					var container = '#propertyCheckoutModal #property_setup_checkout_container';
// 				}

//         		if(response.plan == null) {
//         			$(container).load('components/property/property_setup_fees.html', property_id, function(){
//         				var message = '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
//         				$('#property_setup_checkout_form').html(message);

//         				if(first_time_signup == 1) {
// 		        			$('#signup_data_container .fa-window-close').remove();
// 		        			$('#signup_data_container .card').addClass('popup-card-fixed-width');
// 		        			$('#signup_data_container .accordion').removeAttr('id');
// 		        		}
//         			});
//         		} else {
//         			openLoadingDialog($(container));
//         			setTimeout(function(){
// 	        			$(container).load('components/property/property_setup_fees.html', property_id, function(){
// 							$('#newly_added_property_id').val(property_id);

// 							$("#plan_id").val(response.plan.id);
// 							$("#plan_name").html(response.plan.name);
// 		        			$('#property_setup_fee_container').html('$'+response.plan.plan_pricings[0].property_setup_fee);
// 		        			$('#property_setup_fee_input').val(response.plan.plan_pricings[0].property_setup_fee);

// 		        			$("#coupon_id").val(0);
// 		        			$("#coupon_amount").val(0.00);

// 		        			$('#property_setup_fee_subtotal_container').html('$'+response.plan.plan_pricings[0].property_setup_fee);
// 		        			$('#property_setup_fee_subtotal_input').val(response.plan.plan_pricings[0].property_setup_fee);

// 		        			$('#property_setup_fee_tax_container').html('$0.00');
// 		        			$('#property_setup_fee_tax_amount_input').val(0.00);
// 		        			$('#property_setup_fee_tax_percentage_input').val(0);

// 		        			$('#property_setup_fee_total_container').html('$'+response.plan.plan_pricings[0].property_setup_fee);
// 		        			$('#property_setup_fee_total_input').val(response.plan.plan_pricings[0].property_setup_fee);

// 		        			var html = "<option value=''>Select Payment Method</option>";
// 					        if(response.paymentDetails != null) {
// 				            	for (var key in response.paymentDetails) {
// 					                if (response.paymentDetails.hasOwnProperty(key)) {
// 										if(response.paymentDetails[key].account_number){
// 											html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+' ('+response.paymentDetails[key].account_number+')</option>';
// 										} else {
// 											html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+'</option>';
// 										}
// 									}
// 								}
// 							}

// 					        $('#property_setup_payment_method').html(html);

// 					        // start #454: Apply Default coupon code : NOSETUPFEE
// 					        if(apply_default_coupon_code == true) {
// 					        	$('#property_setup_checkout_section').addClass('d-none');
// 					        	$('#subscription_offer_message_section').removeClass('d-none');
// 					        	$('#myModalLabel').html('<b>Special Offer: Exclusive Benefits for Property Owners</b>');
// 					        	$('#myModalLabel').addClass('text-center');
// 					        	var trigger_from = "after_property_add";
// 				        		applyCouponCode('property_setup_fee', '', trigger_from);
// 				        		$('#property_payment_agree').prop('checked', true);
// 					        	$('.complete_property_transaction').attr('disabled', false);
// 					        	$('.complete_property_transaction').html('Next');
// 					        } else {
// 					        	// Apply coupon code which applied when added new property
// 						        if(response.subscriptionDetail != null && response.subscriptionDetail.coupon != null) {
// 						        	if(response.subscriptionDetail.coupon.type == 2 || response.subscriptionDetail.coupon.type == 3) {
// 						        		var coupon_code = response.subscriptionDetail.coupon.code;
// 						        		var trigger_from = "after_property_add";
// 						        		applyCouponCode('property_setup_fee', coupon_code, trigger_from);
// 						        	}
// 						        }
// 					        }

// 					        if(first_time_signup == 1) {
// 			        			$('#signup_data_container .fa-window-close').remove();
// 			        			$('#signup_data_container .card').addClass('popup-card-fixed-width');
// 			        			$('#signup_data_container .accordion').removeAttr('id');
// 			        		}
// 						});
// 					}, 500);
//         		}
//             } else {
//             	display_error_message(response);
//             }
//         }
//     });
// }

window.checkCouponCode = function(type, coupon_id) {
	var user_id = user.user_details.user_id;

	$.ajax({
        method: 'POST',
        url: api_url+"properties/check-coupon",
        data: 'user_id='+user_id+'&coupon_id='+coupon_id+'&type='+type,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
					$('#apply_coupon_code').remove();
        			$("#property_coupon_id").val(response.coupon_id);
        			$("#property_plan_id").val(response.plan_id);

        			$('#coupon_code').attr('readonly',true);
        			$('#coupon_code').val(response.coupon_code);

        			$("#coupon_id").val(response.coupon_id);
        			$(".subscription_offer_message_section").html(response.coupon_message);
        			$("#coupon_amount").val(response.couponAmount);
        			$("#property_setup_fee_coupon_container").html('$'+response.couponAmount);

					if($('#first_time_signup').val() == 1){
						$('#headingCode span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#headingCode span.accordion_arrow').remove();
					}

					if(response.coupon_id == 0){
						$('#applied_coupon').html('');
					} else {
						$('#apply_coupon_code').text('Clear');
						$('#apply_coupon_code').removeClass('apply_coupon_code');
						$('#apply_coupon_code').addClass('remove_coupon');
						$("#applied_coupon").html('<strong>Coupon Applied '+response.coupon_code);
					}

        			$('#property_setup_fee_subtotal_container').html('$'+response.finalAmont);
        			$('#property_setup_fee_subtotal_input').val(response.finalAmont);

        			$('#property_setup_fee_total_container').html('$'+response.finalAmont);
        			$('#property_setup_fee_total_input').val(response.finalAmont);

        			if(response.finalAmont <= 0) {
        				$('#property_setup_payment_method').attr('disabled',true);
        			} else {
        				$('#property_setup_payment_method').attr('disabled',false);
        			}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.applyCouponCode = function(type, coupon_code, trigger_from, data_for = '') {
	//$('.apply_coupon_code').attr('disabled',true);
	var first_time_signup = $('#first_time_signup').val();
	var property_id = user.user_details.first_property_id;
	var user_id = user.user_details.user_id;
	var property_name = '';
	if($('#add_property_address_line1').val() != null) {
		property_name =  $('#add_property_address_line1').val()+' '+$('#add_property_address_line2').val();
	}

	$.ajax({
        method: 'POST',
        url: api_url+"properties/apply-coupon",
        data: 'user_id='+user_id+'&type='+type+'&coupon_code='+coupon_code+'&property_name='+property_name+'&trigger_from='+trigger_from+'&first_time_signup='+first_time_signup+'&property_id='+property_id+'&data_for='+data_for,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	//$('.apply_coupon_code').attr('disabled',false);
        	if(response.response_data.success == true) {
					$('#apply_coupon_code').remove();
        			$("#property_coupon_id").val(response.coupon_id);
        			$("#property_plan_id").val(response.plan_id);

        			$('#coupon_code').attr('readonly',true);
        			$('#coupon_code').val(response.coupon_code);

        			$("#coupon_id").val(response.coupon_id);
        			$("#coupon_amount").val(response.couponAmount);
					$(".subscription_offer_message_section").html(response.coupon_message);
        			$("#property_setup_fee_coupon_container").html('$'+response.couponAmount);

					if($('#first_time_signup').val() == 1){
						$('#explore_accordion').trigger('hidden.bs.collapse');
						var user = JSON.parse(localStorage.user);
						user.user_details.first_coupon_id = response.coupon_id;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
						$('#headingCode span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#headingCode span.accordion_arrow').remove();
					}

					if(response.coupon_id == 0){
						$('#applied_coupon').html('');
					} else {
						$('#apply_coupon_code').text('Clear');
						$('#apply_coupon_code').removeClass('apply_coupon_code');
						$('#apply_coupon_code').addClass('remove_coupon');
						$("#applied_coupon").html('<strong>Coupon Applied '+response.coupon_code);
					}

        			$('#property_setup_fee_subtotal_container').html('$'+response.finalAmont);
        			$('#property_setup_fee_subtotal_input').val(response.finalAmont);

        			$('#property_setup_fee_total_container').html('$'+response.finalAmont);
        			$('#property_setup_fee_total_input').val(response.finalAmont);

        			if(response.finalAmont <= 0) {
        				$('#property_setup_payment_method').attr('disabled',true);
        			} else {
        				$('#property_setup_payment_method').attr('disabled',false);
        			}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.completePropertyTransaction = function() {

	$('.complete_property_transaction').attr('disabled',true);

	var user_id = user.user_details.user_id;
	var property_id = $('#newly_added_property_id').val();
	var plan_id = $('#plan_id').val();
	var payment_type = 1;
	var item_name = $('#plan_name').text();
	var price = $('#property_setup_fee_input').val();
	var coupon_id = $('#coupon_id').val();
	var coupon_amount = $('#coupon_amount').val();
	var subtotal = $('#property_setup_fee_subtotal_input').val();
	var tax_percentage = $('#property_setup_fee_tax_percentage_input').val();
	var tax_amount = $('#property_setup_fee_tax_amount_input').val();
	var total = $('#property_setup_fee_total_input').val();
	var payment_details_id = $('#property_setup_payment_method').val();

	if($('#property_payment_agree').is(":checked")) {
		var status = 1;
	} else {
		var status = 0;
	}

	var first_time_signup = $('#first_time_signup').val();

	var data = 'user_id='+user_id+'&property_id='+property_id+'&plan_id='+plan_id+'&payment_type='+payment_type+'&price='+price+'&coupon_id='+coupon_id+'&coupon_amount='+coupon_amount+'&sub_total='+subtotal+'&tax_percentage='+tax_percentage+'&tax_amount='+tax_amount+'&total='+total+'&payment_details_id='+payment_details_id+'&status='+status+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"properties/save-setup-checkout-data",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var property_name = $('#p_name_'+property_id).attr('title');
        		// Below code for initial signup step
				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {

					// Checkilist step 10
					getSignupNextStep(10,11);

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 10;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back

					openLoadingDialog($('#signup_data_container'));
					$('#signup_data_container').load('components/property/add_new_tenant.html', function() {
						$('.card-header').remove();
						$('#signup_data_container .card').removeClass('border');
						$('.save_tenant_data').remove();
						$('.save_and_submit_tenant_data').removeClass('d-none');
						$('.save_and_submit_tenant_data').text('Next');
						// start #440: Add skip button
						$('<button data-button="add-tenant" type="button" class="btn btn-orange mr-2 signup_step_skip_button">Skip</button>').insertBefore('.save_and_submit_tenant_data');
						// end #440: Add skip button
					});
				} else {
	        		$('#propertyCheckoutModal').modal('hide');
	        		$('.modal-backdrop').remove();

	        		var layout = $('#selected_layout').val();
	            	if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}

	        		// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
	        		setTimeout(function(){
		        		if(response.proeprty_payment_details_id == 0) {
		        			$('#linkPropertyModal').modal('show');
							openLoadingDialog($('#bp_dropdown_container'));
							setTimeout(function(){
								getBankListForProperty(response.property_user_id, property_id, property_name);
							}, 500);
		        		}
		        	}, 1000);
				}
            } else {
            	$('.complete_property_transaction').attr('disabled',false);
            }
        }
    });
}

window.processPropertySetupFees = function(property_id) {
	var user_id = user.user_details.user_id;

	if(new_payment_schedule_enabled == 'true'){
		var url = api_url+"payment-schedules/process-setup-fee";
	} else {
		var url = api_url+"payment-details/process-setup-fee";
	}

	$.ajax({
        method: 'POST',
        url: url,
        data: 'user_id='+user_id+'&property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var layout = $('#selected_layout').val();
            	if(layout == 'card_layout') {
					$('#main_container').load('components/properties.html');
				} else {
					$('#main_container').load('components/property/property_table.html');
				}
            } else {
				//$('.process_property_setup_fee').attr('disabled', false);
				$('.process_property_setup_fee').removeClass('add_opacity');
				$('.process_property_setup_fee').html('Process Setup Fee');
			}            
        }
    });
}

window.getPropertyLateFeeDetail = function(sp_id, property_id, property_name) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-late-fee-data",
        data: 'user_id='+user_id+'&sp_id='+sp_id+'&user_timezone='+timezone+'&property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
				html += '<div id="rent_collection_container">';
            	html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    for (var key in response.scheduledPayments) {
	                	if (response.scheduledPayments.hasOwnProperty(key)) {
		            		var payment_due_date = response.scheduledPayments[key].payment_due_date;
		            		var formatted_payment_due_date = getFormattedDate(payment_due_date);

							// Add exclamation button
							var late_fee_exclamation = '<i id="open_latefee_info" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2" data-container="body" data-toggle="popover"></i>';

		            		html += '<input type="hidden" name="scheduled_payment_id[]" id="scheduled_payment_'+key+'" class="schedule_payment_hidden_input" value="'+response.scheduledPayments[key].id+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.scheduledPayments[key].payment_due_date2+'</span></h5>';
					            html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(A) Rent Due by '+formatted_payment_due_date+'</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayments[key].formatted_total+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(B) No. of days late after '+response.scheduledPayments[key].lateFeeafterDays+' day of the month</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>'+response.scheduledPayments[key].no_of_days+' day(s)</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
									html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(C) Late fee <span>'+late_fee_exclamation+'</span></div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayments[key].formatted_late_fee_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
									html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(D) Fees waived</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayments[key].formatted_waived_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
					                html += '<div class="row border-top">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2"><strong>Total due as of '+response.scheduledPayments[key].current_date+'</strong> (A) + (C) - (D)</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black fs-18"><strong>$ '+response.scheduledPayments[key].formatted_totalSum+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Waive Late Fee</strong></div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><input type="hidden" name="late_fee_amount[]" id="late_fee_amount_'+response.scheduledPayments[key].id+'" value="'+response.scheduledPayments[key].late_fee_amount+'" class="form-control"><input type="text" name="waive_late_fee[]" id="waive_late_fee_'+key+'" data-id="'+response.scheduledPayments[key].id+'" class="form-control border allow_numeric_with_decimal format_amount waived_late_fee_input" placeholder="Enter amount"></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					            html += '</div>';
					        html += '</div>';
					    }
					}
				    html += '</div>';
				html += '</form>';
				html += '</div>';
        		$('#waive_late_fee_container').html(html);

				// Get Late Fee Charges detail
				var late_fee_title = 'Late Fee Calculation';
				openLateFeePopoverModal(late_fee_title, response.scheduledPayments[0].primary_percentage, response.scheduledPayments[0].secondary_percentage);
            }
        }
    });
}

// window.enableDisableLateFee = function(sp_id, type){
// 	$.ajax({
//         method: 'POST',
//         url: api_url+"scheduled-payments/enable-disable-late-fee",
//         data: 'sp_id='+sp_id+'&type='+type+'&user_id='+user.user_details.user_id,
//         beforeSend: function(xhr) {
// 	        xhr.setRequestHeader('Authorization', accessToken);
// 	    },
//         success: function(response) {
//         	display_error_message(response);
//             if(response.response_data.success == true) {
// 				getPropertyAccountingWidgetInfo('');
//             }
//         }
//     });
// }

window.enableDisableLateFee = function(ps_id, type){
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/enable-disable-late-fee",
        data: 'ps_id='+ps_id+'&type='+type+'&user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
				}
            }
        }
    });
}

window.changePaymentDueDate = function(payment_schedule_id, due_date, month, payment_type) {
	var html = '';
	html += '<form id="payment-due-date-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
		html += '<div id="payNowCollapse">';			
			html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_id" class="payment_schedule_hidden_input" value="'+payment_schedule_id+'">';
			html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
				html += '<div class="row pb-3">';
					html += '<div class="col-8 col-md-8 col-xl-6">';
						html += '<div class="text-left"><strong>Payment Type</strong></div>';
					html += '</div>';
					html += '<div class="col-4 col-md-4 col-xl-6">';
						html += '<div class="text-right"><strong>'+payment_type+'</strong></div>';
					html += '</div>';
				html += '</div>';
				html += '<div class="row pb-3">';
					html += '<div class="col-8 col-md-8 col-xl-6">';
						html += '<div class="text-left"><strong>Month</strong></div>';
					html += '</div>';
					html += '<div class="col-4 col-md-4 col-xl-6">';
						html += '<div class="text-right"><strong>'+month+'</strong></div>';
					html += '</div>';
				html += '</div>';
				html += '<div class="rent_collection_data mt-2">';
					html += '<div class="row">';
						var d2 = convertDate(due_date);
						html += '<div class="form-group col-md-12 mb-0">';
							html += '<div class="form-row">';
								html += '<div class="form-group col-6 col-md-6 col-xl-6">';
									html += '<strong>Payment Due Date <span class="text-danger">*</span></strong>';
								html += '</div>';
								html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
									html += '<div class="input-group date mb-2 ps_due_date" data-target-input="nearest">';
										html += '<input type="text" name="ps_due_date" id="ps_due_date" class="form-control readonly_white" autocomplete="off" readonly="" value="'+d2+'" />';
										html += '<div class="input-group-append">';
											html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';

						html += '<div class="form-group col-md-12 mb-0">';
							html += '<div class="form-row">';
								html += '<div class="form-group col-12 col-md-12 col-xl-12">';
									html += '<strong>Remarks <span class="text-danger">*</span></strong>';
								html += '</div>';
								html += '<div class="form-group col-12 col-md-12 col-xl-12 text-right">';
									html += '<textarea name="inquiry_textarea" id="inquiry_textarea" class="max_char_count" maxlength="500" placeholder="Type Any Remark ..." autofocus></textarea>';
									html += '<div id="the-count" class="float-right">';
										html += '<span id="current">0</span>';
										html += '<span id="maximum">/ 500</span>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
				html += '</div>';
			html += '</div>';

			html += '<div class="text-center mt-3">';
				html += '<div class="col-auto ml-auto">';
					html += '<button type="button" class="btn btn-orange save_payment_due_date" width-100">Save</button>';
				html += '</div>';
			html += '</div>';
		html += '</div>';
	html += '</form>';

	$('#sidebar_content_area').html(html);
	$('#inquiry_textarea').css('min-height', '100px');

	ps_tenant_authorize_datepicker("ps_due_date", due_date);

	paymentDueDateValidation();
}

window.savePaymentDueDate = function(payment_schedule_id){

	var ps_due_date = $('#ps_due_date').val();
	var inquiry_textarea = $('#inquiry_textarea').val();

	var url = api_url+'payment-schedules/change-payment-due-date';
	
	$.ajax({
		method: 'POST',
		url: url,
		data: 'payment_schedule_id='+payment_schedule_id+'&ps_due_date='+ps_due_date+'&inquiry_textarea='+inquiry_textarea+'&user_id='+user.user_details.user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				closeRightSidebar();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
				}
			}
		}
	});
}

window.saveWaivedLateFeeData = function(spArray) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/waive-late-fee",
        data: 'sp_array='+spArray+'&user_timezone='+timezone+'&user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	$('.waive_late_fee').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#waiveLateFeeModal').modal('hide');
            	// loadVerticalProperty();
				getPropertyAccountingWidgetInfo('');
            }
        }
    });
}

window.getActiveLease = function(property_id) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"leases",
	    data:'property_id='+property_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
        		var html = '';
				html += '<p class="bold-grey fs-18">Select lease for which you want to add signature document</p>';
            	html += '<select name="add_document_lease_dd" class="form-control add_document_lease_dd mt-3">';
	            	html += "<option value=''>Select Lease</option>";
	            	for (var key in response.leases) {
		                if (response.leases.hasOwnProperty(key)) {
							if($('#add_contract_open_from').val() == 'add_document'){
								// #757 : response.leases[key].status == 5 means pending signature status, this is when user exptend lease and auto creating lease in background
								if(response.leases[key].status == 1 || response.leases[key].status == 5) {
									var lease_date = response.leases[key].start_date+' - '+response.leases[key].end_date+' ('+response.leases[key].status_label+')'+' ($'+response.leases[key].monthly_rent_amount+')'+' ('+response.leases[key].tenant+')';
									html += '<option value="'+response.leases[key].id+'">'+lease_date+'</option>';
								}
							} else {
								if(response.leases[key].status == 1) {
									var lease_date = response.leases[key].start_date+' - '+response.leases[key].end_date+' ('+response.leases[key].status_label+')'+' ($'+response.leases[key].monthly_rent_amount+')'+' ('+response.leases[key].tenant+')';
									html += '<option value="'+response.leases[key].id+'">'+lease_date+'</option>';
								}
							}		                	
						}
					}
				html += '</select>';
				$('#active_lease_container').removeClass('d-none');
				$('#active_lease_container').html(html);
            }
        }
    });
}

window.getLeaseListForProperty = function(property_id, property_name) {
	var lease_id = 0;
	if($('#it_selected_lease_id').length > 0) {
		lease_id = $('#it_selected_lease_id').val();
	}
	$.ajax({
       	method: 'POST',
	    url: api_url+"leases",
	    data:'property_id='+property_id+'&lease_id='+lease_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	if(response.isFMPAuthReqSent == 0) {
	            	var html = '';
	            	html += '<div class="rent-detail-card text-center p-3 bg-white"><h5 class="card-title mb-0 grey">Select lease for property</h5><div class="fs-18 mt-2 bold-black capitalize">'+property_name+'</div></div>';
	            	html += '<input type="hidden" name="selected_property_id" id="selected_property_id" value="'+property_id+'">';
	            	html += '<select name="lease_property_dd" class="form-control lease_property_dd mt-3">';
		            	html += "<option value=''>Select Lease</option>";
		            	for (var key in response.leases) {
			                if (response.leases.hasOwnProperty(key)) {
			                	if(response.leases[key].status == 1) {
			                		var lease_date = response.leases[key].start_date+' - '+response.leases[key].end_date+' ('+response.leases[key].status_label+')'+' ($'+response.leases[key].monthly_rent_amount+')'+' ('+response.leases[key].tenant+')';
			                		html += '<option value="'+response.leases[key].id+'">'+lease_date+'</option>';
			                	}
							}
						}
					html += '</select>';
					html += '<div id="tenant_dropdown_container" class="mt-3"></div>';

					// Below code will check that from where function is called based on that it will load data
	        		var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						$('#invite_tenant_data_container').html(html);
					} else {
						$('#lease_dropdown_container').html(html);
					}
				} else {
					$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.FMPmessage+'</h3></div>');
					$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange go_to_invite_tenant_section">Next</button></div></div></div>');
				}
            }
        }
    });
}

window.requestInviteFMPayment = function(property_id, property_name) {
	$.ajax({
		method: 'POST',
		url: api_url+"leases",
		data:'property_id='+property_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				if(response.isFMPAuthReqSent == 0) {
					$('#rs_property_id').val(property_id);
					var lease_id = response.leases[0].id;
					var tenant_ids = response.leases[0].tenant_table_ids;
					var tenant_user_ids = response.leases[0].tenant_ids;
					$('#rs_lease_id').val(lease_id);
					$('#rs_invite_tenant_id').val(tenant_ids);
					$('#rs_invite_tenant_user_id').val(tenant_user_ids);
					var html = '';
					html += '<div class="rent-detail-card text-center p-3 bg-white"><div class="fs-18 mt-2 bold-black capitalize">'+property_name+'</div></div>';
					html += '<div class="rent-detail-card p-3 bg-white"><b>Lease:</b> '+response.leases[0].start_date+' - '+response.leases[0].end_date+' ('+response.leases[0].status_label+')'+' ($'+response.leases[0].monthly_rent_amount+')'+' ('+response.leases[0].tenant+')'+'</div>';

					var tenant_names = response.leases[0].tenant.split(',');
					var tenant_email = response.leases[0].tenant_email.split(',');
					html += '<div class="rent-detail-card pl-3 bg-white"><b>Tenants:</b> ';
					for(var tkey in tenant_names){
						html += tenant_names[tkey] + ' (' + tenant_email[tkey] + ') ';
					};
					html += '</div>';
					html += '<div id="first_month_rent_collection_container" class="mt-3"></div>';

					// Below code will check that from where function is called based on that it will load data
					var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						$('#invite_tenant_data_container').html(html);
					} else {
						$('#lease_dropdown_container').html(html);
					}

					if(tenant_ids != "") {
						$('.save_first_month_rent_collection_data').attr('disabled', false);
						if($('#first_month_rent_collection_container').is(':empty')) {
							openLoadingDialog($('#first_month_rent_collection_container'));
							getFirstMonthPaymentDetail(property_id,lease_id);
						}
					} else {
						$('.save_first_month_rent_collection_data').attr('disabled', true);
						return false;
					}
				} else {
					$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.FMPmessage+'</h3></div>');
					$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange go_to_invite_tenant_section">Next</button></div></div></div>');
				}
			}
		}
	});
}

window.getTenantListForLease = function(lease_id) {
	var property_id = $('#selected_property_id').val();
	$.ajax({
       	method: 'POST',
	    url: api_url+"tenants/get-tenants",
	    data:'property_id='+property_id+'&lease_id='+lease_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<input type="hidden" name="selected_lease_id" id="selected_lease_id" value="'+lease_id+'">';
            	html += '<select name="tenant_property_dd" class="form-control tenant_property_dd mt-3" data-toggle="select2" multiple>';
	            	//html += "<option value=''>Select Tenant</option>";
	            	for (var key in response.tenantData) {
		                if (response.tenantData.hasOwnProperty(key)) {
	                		var tenant_name = response.tenantData[key].name+' ('+response.tenantData[key].email+')';
	                		html += '<option data-user-id="'+response.tenantData[key].user_id+'" value="'+response.tenantData[key].tenant_id+'">'+tenant_name+'</option>';
						}
					}
				html += '</select>';
				html += '<div id="first_month_rent_collection_container" class="mt-3"></div>';
				$('#tenant_dropdown_container').html(html);

				$(".tenant_property_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select Tenant",
				            dropdownParent: $(this).parent()
				        });
				})
            }
        }
    });
}

window.getFirstMonthPaymentDetail = function(property_id,lease_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/get-first-month-rent-details",
        data: 'property_id='+property_id+'&lease_id='+lease_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	$('.save_first_month_rent_collection_data').attr('disabled', false);

            	var html = '';
            	html += '<form id="first-month-rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';

				    	for (var key in response.leaseData) {
			            	if (response.leaseData.hasOwnProperty(key)) {

			            		var line = '(One time payment)';
			            		if(key == 0) {
			            			line = ''; //'for the month of '+response.leaseData[key].payment_due_date;
			            		}

			            		var amount_field = '';
			            		var date_field = '';
								if(response.leaseData[key].type == 'rent') {
									amount_field = 'rent_amount';
									date_field = 'rent';
								} else if(response.leaseData[key].type == 'sd') {
									amount_field = 'sd_amount';
									date_field = 'sd';
								} else if(response.leaseData[key].type == 'pd') {
									amount_field = 'pd_amount';
									date_field = 'pd';
								}

								if ( typeof response.leaseData[key].prorate_enabled !== 'undefined' ) {
									var prorate_enabled = response.leaseData[key].prorate_enabled;
								} else {
									var prorate_enabled = 0;
								}

								if(response.leaseData[key].main_amount > 0) {
									html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
							            html += '<div class="border-bottom">';
							                html += '<h5 class="card-title"><span class="fs-22">'+response.leaseData[key].payment_type_lable+'</span> '+line+'</h5>';
							            html += '</div>';
							            html += '<div class="rent_collection_data mt-2">';
							                html += '<div class="row">';
							                    html += '<div class="col-12 col-md-6 col-xl-6">';
							                        html += '<div class="mt-2">';
														html += '<input data-type="'+response.leaseData[key].type+'" type="hidden" name="prorate_'+date_field+'" class="form-control readonly_white" autocomplete="off" readonly="" value="'+prorate_enabled+'" />';
							                            html += '<div class="black mb-2"><strong>Due Date</strong></div>';
							                            html += '<input data-type="'+response.leaseData[key].type+'" type="hidden" name="original_'+date_field+'_due_date" class="form-control readonly_white" autocomplete="off" readonly="" value="'+response.leaseData[key].payment_due_date+'" />';
							                            html += '<div class="input-group date mb-2 first_month_due_date" id="due_date_'+key+'" data-target-input="nearest">';
							                            	html += '<input data-type="'+response.leaseData[key].type+'" type="text" name="'+date_field+'_due_date" class="form-control fm_due_date_'+key+' readonly_white" autocomplete="off" readonly="" value="'+response.leaseData[key].payment_due_date+'" />';

					                                        html += '<div class="input-group-append">';
					                                            html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
					                                        html += '</div>';
					                                    html += '</div>';
							                        html += '</div>';
							                    html += '</div>';
							                    html += '<div class="col-12 col-md-6 col-xl-6 text-right">';
							                        html += '<div class="mt-2">';
							                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
							                            html += '<div class="black fs-18">';
							                            		html += '<div class="input-group mb-2">';
																	html += '<div class="input-group-prepend">';
																		html += '<span class="input-group-text">$</span>';
																	html += '</div>';
																	html += '<input data-type="'+response.leaseData[key].type+'" id="main_amount_'+key+'" type="hidden" class="fm_main_amount form-control text-right" value="'+response.leaseData[key].main_amount+'">';
																	html += '<input data-type="'+response.leaseData[key].type+'" data-key="'+key+'" type="text" name='+amount_field+' class="form-control text-right request_to_pay_amount" value="'+response.leaseData[key].amount+'">';
																html += '</div>';
														html += '</div>';
							                        html += '</div>';
							                    html += '</div>';

												if(response.leaseData[key].type == 'rent'){
													if(response.leaseData[key].prorate_enabled == 1 && response.leaseData[key].prorate_amount != 0){
														html += '<div class="col-12 col-md-12 col-xl-12 mt-2">';
															html += 'The above amount includes prorated rent from '+response.leaseData[key].prorate_start_date+' to '+response.leaseData[key].prorate_end_date+', totaling $'+response.leaseData[key].prorate_amount_formatted+'.';
														html += '</div>';
													}
												}

							                html += '</div>';
							            html += '</div>';
							        html += '</div>';
						    	}
						    }
						}

				        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
				            html += '<div class="rent_collection_data">';
				                html += '<div class="row">';
				                    html += '<div class="col-6 col-md-6 col-xl-9">';
				                        html += '<h5 class="card-title"><span class="fs-22">Total</h5>';
				                    html += '</div>';
				                    html += '<div class="col-6 col-md-6 col-xl-3 text-right">';
				                    	html += '<input type="hidden" class="form-control text-right request_to_pay_total" value="'+response.totalSum+'">';
				                        html += '<strong><div id="request_to_pay_total" class="black fs-18">$ '+response.totalSum+'</div></strong>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
				    html += '</div>';
				html += '</form>';

				$('#first_month_rent_collection_container').html(html);

				datepicker("first_month_due_date");

				//addRentCollectionValidation();

				// Below code will check that from where function is called based on that it will load data
	        	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					$('#save_btn').remove();
					$('#invite_tenant_data_container').append('<div id="save_btn" class="row border-top mt-3"><div class="col-auto ml-auto text-right mt-3 mb-2"><button type="button" class="btn btn-orange save_first_month_rent_collection_data">Request Payment Authorization</button></div></div>');
				}
            }
        }
    });
}

window.saveFirstMonthPaymentDetail = function(property_id, lease_id, tenant_id,user_id) {
	var logged_in_user = user.user_details.user_id;
	var total = $('.request_to_pay_total').val();
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/add-payment-request",
        data: $('#first-month-rent-collection-wizard').serialize()+'&total='+total+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_id='+tenant_id+'&user_id='+user_id+'&logged_in_user='+logged_in_user,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	// Below code will check that from where function is called based on that it will load data
	        	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					getSignupNextStep(4,5);
					openLoadingDialog($('#invite_tenant_data_container'));
					$('.invite_tenant_title').text('Invite Tenant');
					$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
				} else {
					$('#requestToPayModal').modal('hide');
	            	var layout = $('#selected_layout').val();
	            	if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}
				}
            } else {
            	$('.save_first_month_rent_collection_data').attr('disabled', false);
            }
        }
    });
}

window.getDateType = function(key) {
	var type = '';
	var date_key = $("input[class*='fm_due_date_"+key+"']").attr('data-type');
	if(date_key == 'rent') {
		type = 'Rent';
	} else if(date_key == 'sd') {
		type = 'Security deposit';
	} else if(date_key == 'pd') {
		type = 'Pet deposit';
	}
	return type;
}

window.getPaymentType = function(key) {
	var type = '';
	var main_amount_key = $('#main_amount_'+key).attr('data-type');
	if(main_amount_key == 'rent') {
		type = 'Rent';
	} else if(main_amount_key == 'sd') {
		type = 'Security deposit';
	} else if(main_amount_key == 'pd') {
		type = 'Pet deposit';
	}
	return type;
}

window.changeLayout = function(layout,module_name) {
	if(layout == 'table_prop') {
        $('#'+layout).html('<i class="align-middle fas fa-fw fa-grip-vertical"></i>');
        $('#'+layout).attr('id','ver_prop');
        if(module_name == 'property') {
        	$('#selected_layout').val('table_layout');
        	loadVerticalProperty()
        } else if(module_name == 'document') {
        	$('#document_tab_layout').val('table_layout');
        	$('#document_data_container').load('components/property/document_table.html');
        }  else if(module_name == 'user') {
        	$('#user_list_layout').val('table_layout');
        	//$('#user_detail_info_container').load('components/user/user_table.html');
        	getPendingUserList($('#user_list_layout').val());
        }
    } else if(layout == 'ver_prop') {
        $('#'+layout).html('<i class="align-middle fas fa-fw fa-table"></i>');
        $('#'+layout).attr('id','table_prop');
        if(module_name == 'property') {
        	$('#selected_layout').val('card_layout');
        	loadVerticalProperty()
        } else if (module_name == 'ticket') {
        	$('#open_from').val('vertical');
        	$('#selected_layout').val('card_layout');
        	loadTicketList();
        } else if (module_name == 'document') {
        	$('#document_tab_layout').val('card_layout');
        	getPropertyDocument();
        } else if (module_name == 'user') {
        	$('#user_list_layout').val('card_layout');
        	getPendingUserList($('#user_list_layout').val());
        }
    }
	if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
		setTimeout(function(){
			disableForAssumeIdentity();
		}, 2000);
	}
}

window.getPropertyAddressDetail = function(property_id, type) {
	$.ajax({
        method: 'POST',
        //url: api_url+"properties/"+property_id,
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
            $('#selected_property_id').val(property_id);

            if(response.Property.address != null) {
	            $('#edit_property_id').val(property_id);
	            $('#property_payment_status').val(response.Property.payment_status);
	            $('#edit_property_name').val(response.Property.series_llc_name);
				$('#contract_landlord_name').val(response.Property.contract_landlord_name);
				$('#edit_property_address_line1').val(response.Property.address.address_line1);
	            $('#edit_property_address_line2').val(response.Property.address.address_line2);
	            $('#edit_property_city').val(response.Property.address.city);
	            $('#edit_property_state').val(response.Property.address.state);
	            $('#edit_property_zip_code').val(response.Property.address.zip_code);
	            $('#edit_property_country').val(response.Property.address.country);
	        }

	        if(response.subscriptionDetail != null) {
		        $("#subscription_plan_id").val(response.subscriptionDetail.id);
		        $("#property_coupon_id").val(response.subscriptionDetail.coupon_id);
				$("#property_plan_id").val(response.subscriptionDetail.plan_id);

				$('#coupon_code').attr('readonly',true);
				$('#coupon_code').val(response.subscriptionDetail.coupon.code);
				$("#applied_coupon").html('<strong>Coupon Applied '+response.subscriptionDetail.coupon.code);
				$('#apply_coupon_code').text('Clear');
				$('#apply_coupon_code').removeClass('apply_coupon_code');
				$('#apply_coupon_code').addClass('remove_coupon');
			}
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPropertyDetailEditForm = function() {
	setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/view",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            $('#selected_property_user_id').val(response.Property.user_id);

		            $('#update_property_data_btn').html('<button type="button" data-id="'+property_id+'" data-module="Property" class="btn btn-orange float-right update_property_data">Update</button>');

		            if(response.Property.property_type != null) {
		            	$('#property_types').val(response.Property.property_type.id);
		            } else {
		            	$('#property_types').val('');
		            }

		            $('#property_no_of_units').val(response.Property.no_of_units);
		            $('#property_year_built').val(response.Property.built_year);
		            $('#property_square_feet').val(response.Property.square_feet);
		            $('#property_bedrooms').val(response.Property.no_of_bed);
		            $('#property_bathrooms').val(response.Property.no_of_bath);
		            $('#property_parking_spaces').val(response.Property.no_of_parking);
		            $('#property_swimming_pool').val(response.Property.swimming_pool);
		            $('#property_type_of_colling').val(response.Property.type_of_cooling);
		            $('#property_type_of_heating').val(response.Property.type_of_heating);
		            $('#property_type_of_water_heater').val(response.Property.type_of_water_heater);
		            $('#property_ac_filter_size').val(response.Property.ac_filter_size);
		            $('#property_ac_make_and_model').val(response.Property.ac_model);
		            $('#property_water_heater_make_and_model').val(response.Property.water_heater_model);
		            $('#property_additional_information').val(response.Property.additional_information);

		            $('#hoa').val(response.Property.hoa);
				    $('#flooring').val(response.Property.flooring);
				    $('#fireplace').val(response.Property.fireplace);
				    $('#water_heater').val(response.Property.water_heater);
				    $('#fans').val(response.Property.fans);
				    $('#pergola').val(response.Property.pergola);
				    $('#exterior').val(response.Property.exterior);
				    $('#foundation').val(response.Property.foundation);
				    $('#roof').val(response.Property.roof);
				    $('#fence').val(response.Property.fence);
				    $('#parcel_number').val(response.Property.parcel_number);
				    $('#lot_size').val(response.Property.lot_size);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 500);
}

window.getMonthlyRentWidgetInfo = function() {
	openLoadingDialog($('#monthly_rent_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-monthly-rent",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		    		if(response.lease != null && response.lease.status == 4) {
		    			$('#monthly_rent_container').parent('div').remove();
		    		}
		    		if(response.lease == null) {
		    			var html = '';
		            	html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/rent.svg" class="filter-1"/></div>';
								html += '<div class="media-body">';
									html += '<div class="mb-1">Monthly Rent</div>';
									html += '<h3 class="mb-2">$ 0.00</h3>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
		    		} else {

						var add_opacity = '';
						var exclamation = '';
						var dwolla_status_array = ['retry', 'kba', 'document', 'suspended'];
						if(dwolla_status_array.includes(response.response_data.dwolla_customer_status)){
							var auth_msg = 'Authorize Payment button is not <br> enabled since your account is in <br>'+ response.response_data.dwolla_customer_status +' status';
							add_opacity = 'add_opacity';
							exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
						}

		    			var due_str = '';
						var authorize_link = '';
		    			if(response.lease.allow_to_authorize_rent == 1 && Object.keys(response.lease.pending_payment).length !== 0) {
		    				due_str += '<span class="red blink">Due rent for '+response.lease.pending_payment.payment_due_date+'</span>';
		    				if(user_service_name == t_role) {
								if(response.lease.allow_rent_collection != 0){
									authorize_link += '<div><a data-property-id='+property_id+' data-scheduled-payment-id='+response.lease.pending_payment.id+' class="mt-2 authorize_rent_btn btn btn-orange '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Authorize Payment</a>'+exclamation+'</div>';
								} else{
									authorize_link += '<div><a data-property-id='+property_id+' data-scheduled-payment-id='+response.lease.pending_payment.id+' class="mt-2 authorize_rent_btn btn btn-orange '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Review Payment</a>'+exclamation+'</div>';
								}
		    				}
		    			} else {
		    				var due_date = ordinal_suffix_of(1);
				    		var today = new Date();
				    		var month = today.getMonth() + 1;
				    		today.setMonth(month + 1)
							var nextMonth = today.getMonth();
							nextMonth = nextMonth.toLocaleString('en-US', { month: 'long' });
							var nextMonthName = getMonthName(nextMonth);

				    		due_str = 'Due on '+due_date+' of '+nextMonthName;
						}

						var html = '';
		            	html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/rent.svg" class="filter-1"/></div>';
								html += '<div class="media-body">';
									html += '<div class="mb-1">Monthly Rent</div>';
									if(Object.keys(response.lease.pending_payment).length == 0){
										html += '<h3 class="mb-2">$'+response.lease.monthly_rent_amount+'</h3>';
									} else{
										html += '<h3 class="mb-2">$'+response.lease.pending_payment.formated_total+'</h3>';
									}
		                            html += '<h5 class="mb-2">'+due_str+'</h5>';
		                            html += authorize_link;
								html += '</div>';
							html += '</div>';
						html += '</div>';
					}
		            $('#monthly_rent_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getCurrentLeaseWidgetInfo = function() {
	openLoadingDialog($('#current_lease_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-current-lease",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		    		var pet_icon = '<img src="icon/tiles/pet-not-allowed.svg" class="filter-9"/>';
		    		var tenant_icon = '<img src="icon/tiles/tenant.svg" class="filter-9"/>';
		    		if(response.lease == null) {
		    			var html = '';
		            	html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/lease.svg" class="filter-2"/></div>';
								html += '<div class="media-body">';
									//html += '<div class="mb-1">Lease ends on</div>';
									html += '<h3 class="mb-2">Lease not available</h3>';
									html += '<div class="tile-bottom-icon">'+pet_icon+tenant_icon+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
		    		} else {
			    		if(response.lease.pet_policy == 2) {
			    			pet_icon = '<img src="icon/tiles/pet-allowed.svg" class="filter-8"/>';
			    		}

			    		if(response.lease.tenant != '' && response.lease.tenant != null) {
			    			tenant_icon = '<img src="icon/tiles/tenant.svg" class="filter-8 pointer" data-container="body" data-toggle="popover" data-placement="top" title="Tenant" data-content="'+response.lease.tenant+'"/>';
			    		}

			            var html = '';
			            html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/lease.svg" class="filter-2"/></div>';
								html += '<div class="media-body">';
									if(response.lease.status == 4) {
										html += '<div class="mb-1">Lease ended on</div>';
										html += '<h3 class="mb-2 red">'+response.lease.end_date1+'</h3>';
									} else {
										html += '<div class="mb-1">Lease ends on</div>';
										html += '<h3 class="mb-2">'+response.lease.end_date1+'</h3>';
									}
									html += '<div class="tile-bottom-icon">'+pet_icon+tenant_icon+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					}

		            $('#current_lease_container').html(html);

		            $('[data-toggle="popover"]').popover({
			        	html: true,
					    animation:true,
					    sanitize: false,
					    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
			        });
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getTotalIncomeWidgetInfo = function() {
	openLoadingDialog($('#total_income_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-total-income",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            var html = '';
		            html += '<div class="card-body py-4">';
						html += '<div class="media">';
							html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/income.svg" class="filter-3"/></div>';
							html += '<div class="media-body">';
								html += '<div class="mb-1">Total Income</div>';
								html += '<h3 class="mb-2">'+response.total_income+'</h3>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
		            $('#total_income_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getTotalExpenseWidgetInfo = function() {
	openLoadingDialog($('#total_expense_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-total-expense",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            var html = '';
		            html += '<div class="card-body py-4">';
						html += '<div class="media">';
							html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/expense.svg" class="filter-4"/></div>';
							html += '<div class="media-body">';
								html += '<div class="mb-1">Total Expense</div>';
								html += '<h3 class="mb-2">'+response.total_expense+'</h3>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
		            $('#total_expense_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getTicketDetailWidgetInfo = function() {
	openLoadingDialog($('#support_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-ticket-detail",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            var html = '';
		            html += '<div class="card-body py-4">';
						html += '<div class="media">';
							html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/support.svg" class="filter-5"/></div>';
							html += '<div class="media-body">';
								html += '<div class="mb-1">Support Ticket</div>';
								html += '<h4 class="mb-2 blue">Open '+response.totalReported+'</h4>';
								html += '<h4 class="mb-2 orange">Closed '+response.totalCompleted+'</h4>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
		            $('#support_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getPropertyDetailWidgetInfo = function() {
	openLoadingDialog($('#property_detail_widget'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
	    $('#property_detail_widget').load('components/property/property_detail_widget.html', property_id , function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"properties/view",
		        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			            $('#selected_property_user_id').val(response.Property.user_id);

			            if(user.permissionData['PROPERTIES']["feature_items['update_proeprty_detail']"]) {
			            	$('#property_detail_edit_btn').html('<span data-id="'+property_id+'" data-module="Property" class="btn btn-orange float-right edit_property_detail">Edit</span>');
			            }

			            var property_type = '-';
			            if(response.Property.property_type != null) { property_type = response.Property.property_type.name; }

			            var no_of_units = '-';
			            if(response.Property.no_of_units != '' && response.Property.no_of_units != null) { no_of_units = response.Property.no_of_units; }

			            var built_year = '-';
			            if(response.Property.built_year != '' && response.Property.built_year != null) { built_year = response.Property.built_year; }

			            var square_feet = '-';
			            if(response.Property.square_feet != '' && response.Property.square_feet != null) { square_feet = response.Property.square_feet; }

			            var no_of_bed = '-';
			            if(response.Property.no_of_bed != '' && response.Property.no_of_bed != null) { no_of_bed = response.Property.no_of_bed; }

			            var no_of_bath = '-';
			            if(response.Property.no_of_bath != '' && response.Property.no_of_bath != null) { no_of_bath = response.Property.no_of_bath; }

			            var no_of_parking = '-';
			            if(response.Property.no_of_parking != '' && response.Property.no_of_parking != null) { no_of_parking = response.Property.no_of_parking+' Parking Spaces'; }

			            var swimming_pool = '-';
			            if(response.Property.swimming_pool != '' && response.Property.swimming_pool != null) { swimming_pool = response.Property.swimming_pool; }

			            var type_of_cooling = '-';
			            if(response.Property.type_of_cooling != '' && response.Property.type_of_cooling != null) { type_of_cooling = response.Property.type_of_cooling; }

			            var type_of_heating = '-';
			            if(response.Property.type_of_heating != '' && response.Property.type_of_heating != null) { type_of_heating = response.Property.type_of_heating; }

			            var type_of_water_heater = '-';
			            if(response.Property.type_of_water_heater != '' && response.Property.type_of_water_heater != null) { type_of_water_heater = response.Property.type_of_water_heater; }

			            var additional_information = '-';
			            if(response.Property.additional_information != '' && response.Property.additional_information != null) { additional_information = response.Property.additional_information; }

			            var hoa = '-'; if(response.Property.hoa > 0) { hoa = '$ '+response.Property.hoa+' Yearly'; }
			            var flooring = '-'; if(response.Property.flooring != '' && response.Property.flooring != null) { flooring = response.Property.flooring; }
			            var fireplace = '-'; if(response.Property.fireplace != '' && response.Property.fireplace != null) { fireplace = response.Property.fireplace; }
			            var water_heater = '-'; if(response.Property.water_heater != '' && response.Property.water_heater != null) { water_heater = response.Property.water_heater; }
			            var fans = '-'; if(response.Property.fans != '' && response.Property.fans != null) { fans = response.Property.fans; }
			            var pergola = '-'; if(response.Property.pergola != '' && response.Property.pergola != null) { pergola = response.Property.pergola; }
			            var exterior = '-'; if(response.Property.exterior != '' && response.Property.exterior != null) { exterior = response.Property.exterior; }
			            var foundation = '-'; if(response.Property.foundation != '' && response.Property.foundation != null) { foundation = response.Property.foundation; }
			            var roof = '-'; if(response.Property.roof != '' && response.Property.roof != null) { roof = response.Property.roof; }
			            var fence = '-'; if(response.Property.fence != '' && response.Property.fence != null) { fence = response.Property.fence; }
			            var parcel_number = '-'; if(response.Property.parcel_number != '' && response.Property.parcel_number != null) { parcel_number = response.Property.parcel_number; }
			            var lot_size = '-'; if(response.Property.lot_size != '' && response.Property.lot_size != null) { lot_size = response.Property.lot_size; }

			            var html = '';
			            html += '<div class="col-md-12">';
					        html += '<div class="form-row">';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/type.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Type</h5>';
											html += '<div class="mb-0">'+property_type+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/unit.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">No. of Units</h5>';
											html += '<div class="mb-0">'+no_of_units+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/year.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Year Built</h5>';
											html += '<div class="mb-0">'+built_year+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/area.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Interior Livable area</h5>';
											html += '<div class="mb-0">'+square_feet+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					        html += '</div>';
					        html += '<div class="form-row mt-4">';
				        		html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/heating.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Heating</h5>';
											html += '<div class="mb-0">'+type_of_heating+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/cooling.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Cooling</h5>';
											html += '<div class="mb-0">'+type_of_cooling+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/parking.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Parking</h5>';
											html += '<div class="mb-0">'+no_of_parking+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/hoa.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">HOA</h5>';
											html += '<div class="mb-0">'+hoa+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					        html += '</div>';
					    html += '</div>';

					    html += '<div class="col-12 col-lg-12">';
                            html += '<div class="accordion mt-4" id="accordionExample">';
                                html += '<div id="interior_detail_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/interior.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Interior Details</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">';
                                        html += '<div class="card-body">';
                                        	html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Bedrooms</h5>';
															html += '<div class="mb-0">'+no_of_bed+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Bathrooms</h5>';
															html += '<div class="mb-0">'+no_of_bath+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Flooring</h5>';
															html += '<div class="mb-0">'+flooring+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Fireplace</h5>';
															html += '<div class="mb-0">'+fireplace+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
									        html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Water Heater</h5>';
															html += '<div class="mb-0">'+water_heater+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Fans</h5>';
															html += '<div class="mb-0">'+fans+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Pergola</h5>';
															html += '<div class="mb-0">'+pergola+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                                html += '<div id="exterior_detail_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/exterior.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Exterior Details</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">';
                                        html += '<div class="card-body">';
                                        	html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Exterior</h5>';
															html += '<div class="mb-0">'+exterior+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Foundation</h5>';
															html += '<div class="mb-0">'+foundation+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Roof</h5>';
															html += '<div class="mb-0">'+roof+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Fence</h5>';
															html += '<div class="mb-0">'+fence+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
									        html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Parcel Number</h5>';
															html += '<div class="mb-0">'+parcel_number+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Lot Size</h5>';
															html += '<div class="mb-0">'+lot_size+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                                html += '<div id="community_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/community.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Country, Community & Neighborhood Details</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">';
                                        html += '<div class="card-body">';
                                        	html += '<div class="form-row">';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Country</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">City</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">School Dist.</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Neighborhood</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                                html += '<div id="additional_info_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/info.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Additional Information</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">';
                                        html += '<div class="card-body">'+additional_information+'</div>';
                                    html += '</div>';
                                html += '</div>';
                            html += '</div>';
                        html += '</div>';

			            $('#property_detail_container').html(html);

			            $('#interior_detail_accordion, #exterior_detail_accordion, #community_accordion, #additional_info_accordion').on('show.bs.collapse', function () {
						    $(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
						    $(this).find('div:first').addClass('border-bottom');
						});

						$('#interior_detail_accordion, #exterior_detail_accordion, #community_accordion, #additional_info_accordion').on('hide.bs.collapse', function () {
						    $(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
						    $(this).find('div:first').removeClass('border-bottom');
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyPhotoWidgetInfo = function() {
	openLoadingDialog($('#property_photo_widget'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
	    $('#property_photo_widget').load('components/property/property_photo_widget.html', property_id , function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"properties/view",
		        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			        	$('#selected_property_user_id').val(response.Property.user_id);
			            // Create property picture list container
			        	if(user.permissionData['PROPERTIES']["feature_items['update_property_pictures']"]) {
							$('#property_photo_add_btn').html('<span class="btn btn-orange float-right get_property_image_dropzone">Add</span>');
						}

						var img_list = '';
			            var prev_html = '';
			            var img_crousal_list = '';
			            var active = '';
						if(response.totalAttachment == 0) {
							prev_html += '<div class="row justify-content-center">Pictures not available</div>';
							$('#property_picture_container').html(prev_html);
						} else {
				            // Below code is used when user click on image and display full image
				            prev_html += '<div class="pt-0">';
			            		prev_html += '<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">';
					                prev_html += '<ol class="carousel-indicators">';
						                var img_cnt = 0;
						                for (var key in response.Property.user_attachments) {
							                if (response.Property.user_attachments.hasOwnProperty(key)) {
							                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
							                		var act = '';
							                		if(img_cnt == 0) { act = 'active'; }
							                		prev_html += '<li data-target="#carouselExampleIndicators" data-slide-to="'+img_cnt+'" class="'+act+'"></li>';
							                		img_cnt = img_cnt + 1;
						                    	}
						                    }
						                }
					                prev_html += '</ol>';
					                var img_cnt1 = 0;
					                prev_html += '<div class="carousel-inner">';
					                	for (var key in response.Property.user_attachments) {
							                if (response.Property.user_attachments.hasOwnProperty(key)) {
							                	var checked = '';
							                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
							                		if(response.Property.user_attachments[key].cover_image == 1) {
							                    		checked = "checked";
							                    	}
							                		var act1 = '';
							                		if(img_cnt1 == 0) { act1 = 'active'; }
						                			prev_html += '<div class="carousel-item property_image '+act1+'" data-interval="10000" data-id="'+response.Property.user_attachments[key].id+'" data-property-id="'+response.Property.id+'">';
						                				if(user_service_name == po_role) {
						                					prev_html += '<input data-id="'+response.Property.user_attachments[key].id+'" name="property-img-radio" type="radio" class="d-none property_cover_image" '+checked+' data-toggle="tooltip" data-placement="bottom" title="Cover Image" data-original-title="Cover Image"><i class="align-middle mr-2 fas fa-fw fa-trash d-none delete_property_image" data-id="'+response.Property.user_attachments[key].id+'" data-module="Property" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img class="d-block" src="'+response.Property.user_attachments[key].aws_url+'" alt="'+response.Property.user_attachments[key].display_name+'">';
						                				} else {
						                					prev_html += '<img class="d-block" src="'+response.Property.user_attachments[key].aws_url+'" alt="'+response.Property.user_attachments[key].display_name+'">';
						                				}
								                    prev_html += '</div>';
								                    img_cnt1 = img_cnt1 + 1;
							                    }
								            }
								        }
							        prev_html += '</div>';
							        prev_html += '<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">';
					                    prev_html += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
					                    prev_html += '<span class="sr-only">Previous</span>';
					                prev_html += '</a>';
					                prev_html += '<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">';
					                    prev_html += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
					                    prev_html += '<span class="sr-only">Next</span>';
					                prev_html += '</a>';
					            prev_html += '</div>';
					        prev_html += '</div>';

					        $('#property_picture_container').html(prev_html);
							$('.carousel').carousel({
							  	cycle: true
							});
				        }
			            //$('#property_picture_container').html(img_list);
			            //Scrollbar.initAll({damping: .1,thumbMinSize: 20,renderByPixels: true,continuousScrolling: 'auto',alwaysShowTracks: false,wheelEventTarget: null,plugins: {}});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyLeaseWidgetInfo = function() {
	openLoadingDialog($('#property_lease_widget'));
    setTimeout(function(){
    	var user = JSON.parse(localStorage.getItem('user'));
	    var tz = jstz.determine();
		var timezone = tz.name();
		var property_id = $('#selected_property_id').val();
		var user_id = user.user_details.user_id;

		$('#property_lease_widget').load('components/property/property_lease_widget.html', property_id , function(){
			// Set kebab menu conditions
    		var kebab = '';
	        var kebab_item = '';
    		if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['lease_add']"]) {
    			kebab_item += '<a href="javascript:void(0)" class="dropdown-item add_new_lease" data-status="0">Add Lease</a>';
    		}

	        if($.trim(kebab_item) != '') {
	        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	        }
	        $('#property_lease_add_btn').html(kebab);

			$.ajax({
		        method: 'POST',
		        url: api_url+"leases",
		        data: 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		var html = '';
			        	for (var key in response.leases) {
			                if (response.leases.hasOwnProperty(key)) {

			                	var rent_due_date = ordinal_suffix_of(response.leases[key].rent_due_date);
			                	var sd_due_date = '';
			                	var pd_due_date = '&nbsp;';
			                	if(response.leases[key].sd_due_date != null) {
			                		sd_due_date = 'Due on '+response.leases[key].sd_due_date;
			                	}
			                	if(response.leases[key].pd_due_date != null) {
			                		pd_due_date = 'Due on '+response.leases[key].pd_due_date;
			                	}

			                	var add_opacity = '';
			                	var status = '';
			                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
			                    	add_opacity = 'add_opacity';
			                        status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
			                    }

								if(response.leases[key].status == 1 || response.leases[key].status == 5) {
			                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
			                    }

								if(response.leases[key].status == 6) {
									$('.add_new_lease').attr('data-status',2);
									status += '<span class="badge badge-info">'+response.leases[key].status_label+'</span>';
								}

								if(response.leases[key].month_to_month_enabled == 1 && (response.leases[key].status == 1 || response.leases[key].status == 5)){
									$('.add_new_lease').attr('data-status',1);
								}

								if(!$('.add_new_lease').attr('data-start-date') && !$('.add_new_lease').attr('data-end-date')){
									$('.add_new_lease').attr('data-start-date',response.leases[key].start_date);
									$('.add_new_lease').attr('data-end-date',response.leases[key].end_date);
								} else {
									var end_date_check = new Date($('.add_new_lease').attr('data-end-date'));
									var current_end_date = new Date(response.leases[key].end_date);
									if(end_date_check < current_end_date){
										$('.add_new_lease').attr('data-start-date',response.leases[key].start_date);
										$('.add_new_lease').attr('data-end-date',response.leases[key].end_date);
									}
								}

			                    var kebab_item = '';

			                    if((user.permissionData['PROPERTIES']["feature_items['lease_edit']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0 && response.leases[key].status != 6) || user_service_name == sa_role || (localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") == 1)) {
			                    	kebab_item += '<a class="dropdown-item edit_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#leaseEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
			                    }

			                    if(response.leases[key].tenant == '' && user.permissionData['PROPERTIES']["feature_items['lease_delete']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0 && response.leases[key].status != 6) {
			                    	kebab_item += '<a class="dropdown-item delete_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Delete</a>';
			                    }

			                    if(user_service_name == sa_role && response.leases[key].status == 4 && response.leases[key].is_extended == 'No' && response.leases[key].sd_amount > 0 && response.leases[key].is_security_deposit_refunded == 0) {
			                    	kebab_item += '<a class="dropdown-item refund_security_deposit" data-id="'+response.leases[key].id+'" data-sd-amount="'+response.leases[key].security_deposit_amount+'" data-tenants-table-id="'+response.leases[key].tenant_table_ids+'" data-tenant-user-id="'+response.leases[key].tenant_ids+'" data-payment-id="'+response.leases[key].payment_details_id+'" href="javascript:void(0)">Refund Security Deposit</a>';
			                    }

								if(response.leases[key].tenant_ids != '' && response.leases[key].tenant_ids != null && new_payment_schedule_enabled == 'true' && (user_service_name == sa_role || localStorage.getItem("allow_ai_access") == 1)) {
									if(response.leases[key].is_payment_schedule_created == 0){
										// kebab_item += '<a class="dropdown-item create_payment_schedule" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Create Payment Schedule</a>';
										kebab_item += '<a class="dropdown-item get_payment_options" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Create Payment Schedule</a>';
									} 
									
									if(response.leases[key].remove_payment_schedules == 1){
										kebab_item += '<a class="dropdown-item remove_payment_schedules" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Remove Payment Schedules</a>';
									}
								}

			                    var kebab = '';
			                    if(kebab_item != '') {
			                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
			                    }

								// console.log(response.leases[key].tenant);
								var tenant_data = '';
								const tenant_array = response.leases[key].tenant.split(',').forEach(element => {
									tenant_data += element + '<br>';
								});

			                    // Card based UI
			                	html += '<tr data-id="'+response.leases[key].id+'" class="'+add_opacity+'">';
			                		html += '<td class="bold-black">'+response.leases[key].start_date+'</td>';
			                		html += '<td class="bold-black">'+response.leases[key].end_date+'</td>';
			                		html += '<td>$'+response.leases[key].monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
			                		html += '<td>$'+response.leases[key].security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
			                		html += '<td>'+response.leases[key].pet_policy+'<br>$'+response.leases[key].pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
			                		html += '<td>'+tenant_data+'</td>';
			                		html += '<td>';
										if(user.permissionData['PROPERTIES']["feature_items['lease_link_tenant']"] && (localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1)) {
											var add_opacity1 = '';
						                    if(response.leases[key].tenant_ids != '' && response.leases[key].tenant_ids != null) {
						                    	add_opacity1 = 'add_opacity';
						                    }
                                        	html += '<a href="#" data-tenant-id="'+response.leases[key].tenant_ids+'" data-module="lease" data-id="'+response.leases[key].id+'" class="list_existing_tenant disable_action '+add_opacity1+'" data-toggle="modal" data-target="#tenantListModal" data-backdrop="static" data-keyboard="false">Link Tenants</a><br>';
                                        }
									html += '</td>';
			                		html += '<td>'+status+'</td>';
			                		html += '<td>'+kebab+'</td>';
			                	html += '</tr>';
			                }
			            }
			            $('#property-lease-data-container').html(html);

			            var property_lease_table = $("#property-lease-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false,
					        order: [[7,'asc']],
							columnDefs: [
								{
									"targets": [0, 1],
									"type": 'date'
								},
							]
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyTenantWidgetInfo = function() {
	openLoadingDialog($('#property_tenant_widget'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	$('#property_tenant_widget').load('components/property/property_tenant_widget.html', property_id , function(){
    		var user = JSON.parse(localStorage.getItem('user'));
		    var tz = jstz.determine();
			var timezone = tz.name();
			var user_id = user.user_details.user_id;

			$.ajax({
		        method: 'POST',
		        url: api_url+"tenants",
		        data: 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {

					// Set kebab menu conditions
					var kebab = '';
					var kebab_item = '';
					if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['tenant_add']"]) {
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item add_new_tenant" data-tenant-count="'+response.TotalTenants+'">Add Tenant</a>';
					}

					if($.trim(kebab_item) != '') {
						kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					}
					$('#property_tenant_add_btn').html(kebab);

			    	if(response.response_data.success == true) {
			    		var html = '';
			        	if(response.TotalTenants == 0) {
				        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
					    } else {
					    	for (var key in response.tenants) {
				                if (response.tenants.hasOwnProperty(key)) {
				                	var monthly_rent_amount = '-';
				                	var rent_due_date = '-';
				                	var security_deposit_amount = '-';
				                	var pet_policy = '-';
				                	var pet_deposit_amount = '-';
				                	var start_date = '-';
				                	var end_date = '-';
				                	var sd_due_date = '-';
				                	var pd_due_date = '-';

				                	var kebab_item = '';
				                	if((user.permissionData['PROPERTIES']["feature_items['tenant_edit']"] && response.tenants[key].application_constant == 'draft') || user_service_name == sa_role) {
				                    	// kebab_item += '<a class="dropdown-item edit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
				                    	kebab_item += '<a class="dropdown-item edit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Edit</a>';
				                    }

				                    var status = '';
				                    if(response.tenants[key].application_constant == 'draft') {
				                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
				                        if(user_service_name == po_role) {
				                        	kebab_item += '<a class="dropdown-item submit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Submit</a>';
				                        }
				                    } else if(response.tenants[key].application_constant == 'submitted' || (response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0)) {
				                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
				                        if(user_service_name == sa_role && response.tenants[key].allow_to_sign_in == 1) {
				                        	var invite_lable = '';
				                        	if(response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0) {
				                        		invite_lable = 'Re-invite Tenant';
				                        	} else {
				                        		invite_lable = 'Invite Tenant';
				                        	}
				                        	kebab_item += '<a class="dropdown-item invite_tenant" data-id="'+response.tenants[key].id+'" data-user-id="'+response.tenants[key].user_id+'" href="javascript:void(0)">'+invite_lable+'</a>';
				                        }
				                    } else if(response.tenants[key].application_constant == 'invite_sent') {
				                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
				                    } else if(response.tenants[key].application_constant == 'signed_up') {
				                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
				                    } else if(response.tenants[key].application_constant == 'tenant_deleted') {
				                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
				                    } else if(response.tenants[key].application_constant == 'invite_pending') {
				                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
				                    }

				                    // kebab_item += '<a class="dropdown-item invite_tenant" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">'+invite_lable+'</a>';

				                    var add_opacity = '';
				                    if(response.tenants[key].lease_status == 4) {
				                    	add_opacity = 'add_opacity';
										// Change made for #463 task - 3
				                    	//status = '<span class="badge badge-danger">Expired</span>';
										status = '<span class="badge badge-danger">Inactive</span>';
				                    }

				                    if(user.permissionData['PROPERTIES']["feature_items['tenant_delete']"]) {
				                    	if(response.tenants[key].tenant_leases.length <= 0 && response.tenants[key].application_constant != 'tenant_deleted') {
				                    		kebab_item += '<a class="dropdown-item delete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Delete</a>';
			                        	}
			                        }

			                        /*if(user_service_name == sa_role && response.tenants[key].status == 4) {
			                    		kebab_item += '<a class="dropdown-item undelete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Un-delete</a>';
			                    	}*/

			                    	var kebab = '';
			                    	if($.trim(kebab_item) != '') {
							        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
							        }

				                    if(response.tenants[key].tenant_leases.length > 0) {
					                	for (var tlkey in response.tenants[key].tenant_leases) {
					                		if (response.tenants[key].tenant_leases.hasOwnProperty(tlkey)) {
					                			var lease = response.tenants[key].tenant_leases[tlkey].lease;
					                			var add_opacity = '';
					                			if(lease != null) {
					                				add_opacity = 'add_opacity';
							                		monthly_rent_amount = lease.monthly_rent_amount;
							                		rent_due_date = ordinal_suffix_of(lease.rent_due_date);
							                		security_deposit_amount = lease.security_deposit_amount;
							                		pet_policy = lease.pet_policy_term_label;
							                		pet_deposit_amount = lease.pet_deposit_amount;
							                		start_date = lease.start_date;
							                		end_date = lease.end_date;
							                		sd_due_date = 'Due on '+lease.sd_due_date;
							                		pd_due_date = 'Due on '+lease.pd_due_date;
							                	}

							                	var lease_status = '';
							                	if(response.tenants[key].tenant_leases[tlkey].status == 1) {
							                    	lease_status = '<span class="badge badge-info">Submitted</span>';
							                    }
							                    var add_opacity1 = '';
							                	if(response.tenants[key].tenant_leases[tlkey].status == 4) {
							                		add_opacity1 = 'add_opacity';
													// Change made for #463 task - 3
							                    	// lease_status = '<span class="badge badge-danger">Expired</span>';
													lease_status = '<span class="badge badge-danger">Inactive</span>';
							                    }
							                    if(response.tenants[key].tenant_leases[tlkey].status == 5) {
							                    	lease_status = '<span class="badge badge-primary">Pending signature</span>';
							                    }

							                    if(response.tenants[key].application_constant == 'draft') {
													lease_status = '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
												}

							                    html += '<tr data-id="'+response.tenants[key].id+'" data-user-id="'+response.tenants[key].user_id+'" data-user-name="'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'" data-status="'+response.tenants[key].status+'" class="'+add_opacity1+' user_account_role">';
													html += '<td class="bold-black">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><br><small>'+response.tenants[key].user.email+'</small></td>';
							                		html += '<td>'+start_date+'</td>';
							                		html += '<td>'+end_date+'</td>';
													if(response.tenants[key].is_primary){
														html += '<td>Yes</td>';
													} else {
														html += '<td>No</td>';
													}
							                		//html += '<td>$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
							                		//html += '<td>$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
							                		//html += '<td>'+pet_policy+'<br>$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
							                		html += '<td>';
														if(user.permissionData['PROPERTIES']["feature_items['tenant_link_lease']"] && (localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1)) {
				                                        	html += '<a href="#" data-l-id="'+response.tenants[key].tenant_leases[tlkey].lease_id+'" data-lease-id="'+response.tenants[key].lease_ids+'" data-id="'+response.tenants[key].id+'" class="list_existing_lease disable_action '+add_opacity+'" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
				                                        }
													html += '</td>';
							                		html += '<td>'+lease_status+'</td>';
							                		html += '<td>'+kebab+'</td>';
							                	html += '</tr>';
										    }
										}
									} else {
										if(response.tenants[key].status == '4'){
											add_opacity = 'add_opacity';
										}
										html += '<tr data-id="'+response.tenants[key].id+'" data-user-id="'+response.tenants[key].user_id+'" data-user-name="'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'" data-status="'+response.tenants[key].status+'" class="'+add_opacity+' user_account_role">';
					                    	html += '<td class="bold-black">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><br><small>'+response.tenants[key].user.email+'</small></td>';
					                		html += '<td>'+start_date+'</td>';
					                		html += '<td>'+end_date+'</td>';
											if(response.tenants[key].is_primary){
												html += '<td>Yes</td>';
											} else {
												html += '<td>No</td>';
											}
					                		//html += '<td>$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
					                		//html += '<td>$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
					                		//html += '<td>'+pet_policy+'<br>$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
					                		html += '<td>';
												if(user.permissionData['PROPERTIES']["feature_items['tenant_link_lease']"] && (localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1)) {
		                                        	html += '<a href="#" data-lease-id="0" data-id="'+response.tenants[key].id+'" class="list_existing_lease disable_action" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
		                                        }
											html += '</td>';
					                		html += '<td>'+status+'</td>';
					                		html += '<td>'+kebab+'</td>';
					                	html += '</tr>';
									}
				                }
				            }
				        }

			            $('#property-tenant-data-container').html(html);

			            var property_tenant_table = $("#property-tenant-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false,
					        order: [[4,'desc']],
							columnDefs: [
								{
									"targets": [1, 2],
									"type": 'date'
								},
							]
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyDocumentWidgetInfo = function() {
	openLoadingDialog($('#property_document_widget'));
    setTimeout(function(){
    	var user_id = user.user_details.user_id;
		var property_id = $('#selected_property_id').val();

		$('#property_document_widget').load('components/property/property_document_widget.html', property_id , function(){
			$.ajax({
		        method: 'POST',
		        url: api_url+"properties/view",
        		data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		$('#selected_property_user_id').val(response.Property.user_id);

			    		// Set kebab menu conditions
			    		var kebab = '';
				        var kebab_item = '';
			    		if(user.permissionData['PROPERTIES']["feature_items['upload_document']"]) {
			    			kebab_item += '<a href="javascript:void(0)" class="dropdown-item get_property_document_dropzone">Upload Document</a>';
			    		}
			    		if(user.permissionData['PROPERTIES']["feature_items['add_signature_document']"]) {
							if(signature_enabled == 'zoho_template'){
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item add_lease_aggrement_signature_document">Add Signature Document</a>';
							} else {
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item create_lease_agreement" data-status="0" data-open-from="add_document" data-ps-id="0">Add Signature Document</a>';
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item send_contract_for_signature add_opacity" data-status="0">Send For Signature</a>';
							}
						}
				        if($.trim(kebab_item) != '') {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
				        }
				        $('#property_document_btn').html(kebab);

			            // Set Property zoho template and normal pdf file data

			            var checklist_list = '';

			            // Start : Document section
			            var file_cnt = 0;
			            if(response.templateData != null) {
			            	for (var key in response.templateData) {
				                if (response.templateData.hasOwnProperty(key)) {
				                	file_cnt++

				                	// Create data for receipents
				                	var user_popup_html = '';
				                	var user_html = '';
				                	for (var akey in response.templateData[key].recipients) {
				                		if (response.templateData[key].recipients.hasOwnProperty(akey)) {
				                			user_popup_html += '<div>'+response.templateData[key].recipients[akey].recipient+' <br> <strong>'+response.templateData[key].recipients[akey].status+'</strong> <br>on '+response.templateData[key].recipients[akey].date+'</div>';

				                			var status_color = '';
			                				if(response.templateData[key].recipients[akey].status == 'Completed') {
			                					status_color = 'green';
			                				}
			                				user_html += '<div class="fs-14 mb-2">'+response.templateData[key].recipients[akey].recipient+' <br> <strong class="fs-16 '+status_color+'">'+response.templateData[key].recipients[akey].status+'</strong> <br>on '+response.templateData[key].recipients[akey].date+'</div>';
				                		}
				                	}

			                        // Set kebab menu conditions
							        var document_status = '';
							        var kebab = '';
							        var kebab_item = '';
					                var send_for_signature = '';
									var btn_class = '';

									if(user_service_name == sa_role && response.templateData[key].status == 0) {
										kebab_item += '<a data-user-id='+response.templateData[key].user_id+' data-zoho-template-id='+response.templateData[key].zoho_template_id+' data-zohoTemplateID="'+response.templateData[key].zoho_template.zoho_templateID+'" data-zohoActionID="'+response.templateData[key].zoho_template.zoho_actionID+'" class="dropdown-item send_for_signatures" href="javascript:void(0)">Send for Signatures</a>';

										document_status += '<div class="badge badge-danger" title="'+response.templateData[key].zoho_template.zoho_templateName+'">'+response.templateData[key].status_label+'</div>';
									} else {
										if(response.templateData[key].status == 1) {
											btn_class = 'badge-primary';
										} else if(response.templateData[key].status == 2) {
											btn_class = 'badge-primary';
										} else if(response.templateData[key].status == 3) {
											btn_class = 'badge-warning';
										} else if(response.templateData[key].status == 4) {
											btn_class = 'badge-success';
										} else if(response.templateData[key].status == 5) {
											btn_class = 'badge-warning';
										} else if(response.templateData[key].status == 6) {
											btn_class = 'badge-success';
										} else if(response.templateData[key].status == 7) {
											btn_class = 'badge-danger';
										}

										document_status += '<div class="badge '+btn_class+'" title="'+response.templateData[key].zoho_template.zoho_templateName+'" data-container="body" data-toggle="popover" data-placement="top" data-content="'+user_popup_html+'">'+response.templateData[key].status_label+'</div>';
									}

									if(response.templateData[key].status > 0) {
										kebab_item += '<a data-type="zoho" data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';

										kebab_item += '<a data-type="download" data-filename="'+stripHtml(response.templateData[key].zoho_template.zoho_templateName)+'" data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item download_zoho_document" href="javascript:void(0)">Download</a>';
					                }

					                if(response.templateData[key].status == 0) {
					                	kebab_item += '<a data-id="'+response.templateData[key].id+'" class="dropdown-item delete_property_template" href="javascript:void(0)">Delete</a>';
					                }

									if(response.templateData[key].status >= 1 && response.templateData[key].status <= 5 && response.templateData[key].status != 4) {
										kebab_item += '<a data-type="download"  data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item remind_zoho_template" href="javascript:void(0)">Send Reminder</a>';
									}

							        if($.trim(kebab_item) != '') {
							        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
							        }

							        var tr_bg = 'odd';
				                	if(key % 2 == 1) {
				                		tr_bg = 'even';
				                	}

				                	var name = stripHtml(response.templateData[key].zoho_template.zoho_templateName);

							        checklist_list += '<tr id="user_template_'+response.templateData[key].id+'" class="'+tr_bg+'">';
										checklist_list += '<td>&nbsp;</td>';
				                		checklist_list += '<td>'+name+'</td>';
			                            checklist_list += '<td>'+response.templateData[key].zoho_template.document_for+'</td>';
			                            checklist_list += '<td>'+user_html+'</td>';
			                            checklist_list += '<td>'+document_status+'</td>';
			                            checklist_list += '<td>&nbsp</td>';
			                            checklist_list += '<td>&nbsp;</td>';
			                            checklist_list += '<td>'+kebab+'</td>';
			                        checklist_list += '</tr>';
				                }
				            }
			            }

			            // Display normal document
			            for (var key in response.Property.user_attachments) {
			                if (response.Property.user_attachments.hasOwnProperty(key)) {
			                    if(response.Property.user_attachments[key].module_name == "Property Checklist" || response.Property.user_attachments[key].module_name == "Payment Schedules") {
									if(response.Property.user_attachments[key].user.id == user_id || response.Property.user_attachments[key].shared == 1 || user_service_name == sa_role){
										file_cnt++;
										// Set kebab menu conditions
										var kebab = '';
										var kebab_item = '';
										kebab_item += '<a data-type="normal" data-name="'+response.Property.user_attachments[key].display_name+'" data-url="'+response.Property.user_attachments[key].file_url+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';

										kebab_item += '<a class="dropdown-item" href="'+response.Property.user_attachments[key].file_url+'" download="'+response.Property.user_attachments[key].display_name+'" target="_blank">Download</a>';

										if(user.permissionData['PROPERTIES']["feature_items['share_user_document']"]) {
											if(response.Property.user_attachments[key].shared == 0){
												kebab_item += '<a data-type="normal" data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item share_document" href="javascript:void(0)">Share</a>';
											} else{
												kebab_item += '<a data-type="normal" data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item unshare_document" href="javascript:void(0)">Unshare</a>';
											}
										}

										if(response.Property.user_attachments[key].user_id == user_id && response.Property.user_attachments[key].status == 1) {
											kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item delete_property_image" href="javascript:void(0)">Delete</a>';
										}

										if(user_service_name == sa_role && response.Property.user_attachments[key].status == 2) {
											kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item undelete_property_image" href="javascript:void(0)">Un-Delete</a>';
										}

										if($.trim(kebab_item) != '') {
											kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
										}

										var name = response.Property.user_attachments[key].display_name;

										var doc_status = '';
										if(response.Property.user_attachments[key].status == 1) {
											doc_status += '<span class="badge badge-primary">Active</span>';
										} else if(response.Property.user_attachments[key].status == 2) {
											doc_status += '<span class="badge badge-danger">Deleted</span>';
										}

										if(user_service_name == sa_role || response.Property.user_attachments[key].status == 1) {
											var uploaded_by = response.Property.user_attachments[key].user.first_name+' '+response.Property.user_attachments[key].user.last_name;
											var uploaded_on = response.Property.user_attachments[key].created;

											var tr_bg = 'odd';
											if(key % 2 == 1) {
												tr_bg = 'even';
											}

											checklist_list += '<tr id="user_property_attachment_'+response.Property.user_attachments[key].id+'" class="'+tr_bg+'">';
												checklist_list += '<td>&nbsp;</td>';
												if(response.Property.user_attachments[key].shared == 1){
													checklist_list += '<td><span><i class="align-middle mr-2 fas fa-fw fa-users" data-toggle="tooltip" data-placement="bottom" title data-original-title="Shared Document"></i>'+name+'</td>';
												} else{
													checklist_list += '<td>'+name+'</td>';
												}
												checklist_list += '<td>&nbsp;</td>';
												checklist_list += '<td>&nbsp;</td>';
												checklist_list += '<td>'+doc_status+'</td>';
												checklist_list += '<td>'+uploaded_by+'</td>';
												checklist_list += '<td>'+uploaded_on+'</td>';
												checklist_list += '<td>'+kebab+'</td>';
											checklist_list += '</tr>';
										}
									}
			                    }
			                }
			            }

			            $('#property-document-data-container').html(checklist_list);

						listContracts(property_id);
						listZohoDocuments(property_id);

			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertiesPaymentScheduleWidgetInfo = function(property_id = 0, lease_ids = 0, startDate = '', endDate = ''){
	if ($.fn.DataTable.isDataTable("#separate_payment_scheduled_datatable")) {
        $("#separate_payment_scheduled_datatable").DataTable().destroy();
    }
	openLoadingDialog($('#property_payment_schedule_widget'));
	setTimeout(function(){
		property_id = $('#selected_property_id').val();
		$('#property_payment_schedule_widget').load('components/property/property_payment_schedule_widget.html', property_id , function(){
			$('.ps_filter_property_name').attr('disabled', true);
			// $('.ps_filter_for_property').removeClass('d-none');
			var filters = getFilterData();
			var user_id = user.user_details.user_id;
			$.ajax({
				url: api_url+"payment-schedules",
				type: 'POST',
				data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&filters='+filters+'&open_from=properties',
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', accessToken);
				},
				success: function(response) {
					var html = '';
					$('#datatable_buttons').html('');
					if(response.response_data.success == true) {
						var data = response.response_data.data;
						for(var key in data){
							for(var pkey in data[key]){
								var num = 0;
								for(var dkey in data[key][pkey]){
									// if dkey != calc because in calc we are calculating percentage
									if(dkey != 'calc'){
										let kebab_item = '';
										let kebab = '';

										if(data[key][pkey][dkey].sender_user_id == user_id || (data[key][pkey][dkey].sender_user_id == 0 && user_service_name == sa_role)) {
											if(data[key][pkey][dkey].status_constant_name == 'pending_authorization'){
												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Authorize Payment">Authorize Payment</a>';
											} else if(data[key][pkey][dkey].status_constant_name == 'pending_collection'){
												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Review Payment">Review Payment</a>';
											}
										}

										if(user_service_name == sa_role){
											if(data[key][pkey][dkey].status_constant_name == 'deleted'){
												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-payment-type="'+data[key][pkey][dkey].payment_type_id+'" class="dropdown-item undo_delete_ps">Un-Delete</a>';
											}

											if(data[key][pkey][dkey].status_constant_name == 'failed_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_transfer'){
												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-payment-type="'+data[key][pkey][dkey].payment_type_id+'" class="dropdown-item regenerate_payment_schedule">Regenerate Transaction</a>';
											}

											if(data[key][pkey][dkey].sender_user_id > 0 && (data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'pending_collection')){
												if(data[key][pkey][dkey].lease && data[key][pkey][dkey].lease.partial_payment_enabled == 1 && (data[key][pkey][dkey].payment_type.constant == 'rent' || data[key][pkey][dkey].payment_type.constant == 'security_deposit' || data[key][pkey][dkey].payment_type.constant == 'pet_deposit')){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item get_partial_payment_data">Create Partial Payment</a>';
												}

												if(data[key][pkey][dkey].fee_applied == 1){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item waive_payment_schedule_late_fee">Waive Fee</a>';
												}
											}

											if(data[key][pkey][dkey].payment_type.constant != 'rs_bank_to_balance' && data[key][pkey][dkey].payment_type.constant != 'property_setup_fee' && (data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized' || data[key][pkey][dkey].status_constant_name == 'pending_collection')){
												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item mark_ps_as_paid">Mark As Paid</a>';

												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item mark_ps_as_deleted">Mark As Deleted</a>';
											}

											if(data[key][pkey][dkey].payment_type.constant != 'rs_bank_to_balance' && (data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized')){
												kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item change_ps_payment_details" data-title="Change Payment Details">Change Payment Details</a>';

												if(data[key][pkey][dkey].enable_change_route == 1){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item change_ps_payment_route" data-title="Change Payment Route">Change Payment Route</a>';
												}
											}

											if((data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized' || data[key][pkey][dkey].status_constant_name == 'pending_collection')) {
												if(data[key][pkey][dkey].trn_late_fee_enabled == true){
													kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'">Disable Late Fee</a>';
												} else {
													kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="enable" data-id="'+data[key][pkey][dkey].id+'">Enable Late Fee</a>';
												}

												kebab_item += '<a class="dropdown-item change_payment_due_date" href="javascript:void(0)" data-due-date="'+data[key][pkey][dkey].due_date+'" data-month="'+data[key][pkey][dkey].month+'" data-payment-type="'+data[key][pkey][dkey].payment_type.name+'" data-id="'+data[key][pkey][dkey].id+'">Change Payment Due Date</a>';
											}
										}

										if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
											if(data[key][pkey][dkey].status_constant_name == 'transfer_authorized') {
												kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][pkey][dkey].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
											} else if(data[key][pkey][dkey].status_constant_name == 'transfer_in_progress') {
												kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][pkey][dkey].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
											}
										}

										if(kebab_item != '' && (data[key][pkey][dkey].sender_user_id == user_id || user_service_name == sa_role)){
											kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v ml-auto mr-auto" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right">'+kebab_item+'</div></div></div>';
										}

										var status = '';
										if(data[key][pkey][dkey].status_constant_name == 'pending_authorization'){
											status = '<span class="badge badge-grey">'+data[key][pkey][dkey].status+'</span>';
										} else if (data[key][pkey][dkey].status_constant_name == 'failed_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_transfer' || data[key][pkey][dkey].status_constant_name == 'deleted'){
											status = '<span class="badge badge-red">'+data[key][pkey][dkey].status+'</span>';
										} else if (data[key][pkey][dkey].status_constant_name == 'transfer_in_progress' || data[key][pkey][dkey].status_constant_name == 'processing') {
											status = '<span class="badge badge-yellow">'+data[key][pkey][dkey].status+'</span>';
										} else if (data[key][pkey][dkey].status_constant_name == 'processed') {
											status = '<span class="badge badge-green">'+data[key][pkey][dkey].status+'</span>';
										} else if (data[key][pkey][dkey].status_constant_name == 'transfer_authorized') {
											status = '<span class="badge badge-blue">'+data[key][pkey][dkey].status+'</span>';
										} else {
											status = '<span class="badge badge-grey">'+data[key][pkey][dkey].status+'</span>';
										}

										if(user_service_name != sa_role || data[key][pkey][dkey].payment_type.constant == 'property_setup_fee' || data[key][pkey][dkey].associated_payments == 1 || (data[key][pkey][dkey].status_constant_name != 'pending_authorization' && data[key][pkey][dkey].status_constant_name != 'transfer_authorized')) {
											var disabled = 'disabled';
											var add_opacity = 'add_opacity';
										} else {
											var disabled = '';
											var add_opacity = '';
										}

										if(data[key][pkey][dkey].is_autopay == 0){
											var checked = '';
										} else {
											var checked = 'checked';
										}

										html += '<tr class="payment_schedule_tr_'+data[key][pkey][dkey].id+'">';

											// if(user_service_name == sa_role && (data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized')){
											// 	html += '<td class="text-center">';
											// 		html += '<label class="custom-control custom-checkbox" style="display:inline;">';
											// 			html += '<input type="checkbox" class="custom-control-input ps_parent_options" name="ps_list[]" id="ps_parent_options_'+data[key][pkey][dkey].id+'" data-id="'+data[key][pkey][dkey].id+'">';
											// 			html += '<span class="custom-control-label">&nbsp;</span>';
											// 		html += '</label>';
											// 	html += '</td>';
											// } else {
											// 	html += '<td>&nbsp;</td>';
											// }

											html += '<td>'+data[key][pkey][dkey].id+'</td>';

											if(num == 0) {
												html += '<td>'+pkey+'</td>';
												num++;
											} else {
												html += '<td class="grey">'+pkey+'</td>';
											}

											if(data[key][pkey][dkey].due_date) {
												html += '<td>'+data[key][pkey][dkey].due_date+'</td>';
											} else {
												html += '<td>-</td>';
											}

											if(data[key][pkey][dkey].month) {
												html += '<td>'+data[key][pkey][dkey].month+'</td>';
											} else {
												html += '<td>-</td>';
											}

											html += '<td class="text-right">'+data[key][pkey][dkey].total+'</td>';

											html += '<td>';
												html += '<div>';
													html += data[key][pkey][dkey].sender_data.name+'<br>';
													html += data[key][pkey][dkey].sender_data.email+'<br>';
													html += data[key][pkey][dkey].sender_data.bank_name+'<br>';
													html += data[key][pkey][dkey].sender_data.ach;
												html += '</div>';
											html += '</td>';
											html += '<td>';
												html += '<div>';
													html += data[key][pkey][dkey].receiver_data.name+'<br>';
													html += data[key][pkey][dkey].receiver_data.email+'<br>';
													html += data[key][pkey][dkey].receiver_data.bank_name+'<br>';
													html += data[key][pkey][dkey].receiver_data.ach;
												html += '</div>';
											html += '</td>';

											if(data[key][pkey][dkey].coupon_data){
												html += '<td>';
													html += '<div>';
														html += 'Code: '+data[key][pkey][dkey].coupon_data.coupon_code+'<br>';
														html += 'Amount: $'+currencyFormat(Number(data[key][pkey][dkey].coupon_data.coupon_amount))+'<br>';
													html += '</div>';
												html += '</td>';
											} else {
												html += '<td class="text-center">-</td>';
											}

											// html += '<td class="text-center">';
											// 	html += '<label class="custom-control custom-checkbox" style="display:inline;">';
											// 		html += '<input type="checkbox" class="custom-control-input update_auto_pay" '+checked+' '+disabled+' id="auto_pay_'+data[key][pkey][dkey].id+'" data-id="'+data[key][pkey][dkey].id+'">';
											// 		html += '<span class="custom-control-label">&nbsp;</span>';
											// 	html += '</label>';
											// html += '</td>';

											html += '<td>';
												html += '<label class="switch text-center mt-1 mb-0 '+add_opacity+'">';
													html += '<input type="checkbox" class="update_auto_pay" id="auto_pay_'+data[key][pkey][dkey].id+'" data-id="'+data[key][pkey][dkey].id+'" '+checked+'>';
													html += '<div class="slider round">';
														html += '<span class="on pr-3">Yes</span>';
														html += '<span class="off ml-1">No</span>';
													html += '</div>';
												html += '</label>';
											html += '</td>';

											html += '<td>'+status+'</td>';

											// if(data[key][pkey][dkey].associated_payments == 1){
												html += '<td class="text-right associate_payment_details" data-id="'+data[key][pkey][dkey].id+'" data-type="separate"><i class="align-middle fas fa-fw fa-eye ml-4"></i></td>';
											// } else {
											// 	html += '<td class="text-center">&nbsp;</td>';
											// 	//html += '<td class="text-center associate_payment_details" data-id="'+data[key][pkey][dkey].id+'" data-type="separate"><i class="align-middle mr-2 fas fa-fw fa-eye grey"></i></td>';
											// }

											if(kebab_item != '') {
												html += '<td>'+kebab+'</td>';
											} else {
												html += '<td>&nbsp;</td>';
											}
										html += '</tr>';
									}
								}
							}
						}
						$('#payment_schedule_container').html(html);
					}

					// var ps_datatable = $('#separate_payment_scheduled_datatable').DataTable({
					// 	"searching": false,
					// 	"lengthChange": false,
					// 	"info": false,
					// 	"autoWidth": true,
					// 	"paging": false,
					// 	"ordering": false,
					// 	"scrollY": '350px',
					// 	"scrollX": true,
					// 	"responsive": { breakpoints: [
					// 		{ name: 'desktop', width: Infinity },
					// 		{ name: 'tablet',  width: 1024 },
					// 		{ name: 'phone',   width: 768 }
					// 	]},
					// 	"columnDefs": [
					// 		{
					// 			"visible": false,
					// 			"targets": [3,5,6,7]
					// 		},
					// 		{ "className": "all", "width": "3%", "targets": [0] }, //id
					// 		{ "className": "all", "width": "20%", "targets": [1] }, //payment type
					// 		{ "className": "all", "width": "14.25%", "targets": [2]}, //due date
					// 		{ "className": "min-tablet", "width": "17.25%", "targets": [4]}, //amount
					// 		{ "className": "min-tablet", "width": "11.25%", "targets": [8]}, //autopay
					// 		{ "className": "min-tablet", "width": "16.25%", "targets": [9]}, //status
					// 		{ "className": "all", "width": "6.5%", "targets": [10]}, //view
					// 		{ "className": "all", "width": "6.5%", "targets": [11]}, //action
					// 		{
					// 			"targets": [2],
					// 			"type": 'date'
					// 		},
					// 	],
					// 	"dom": 'Bfrtip',
					// 	"fixedHeader": true,
					// 	"pageLength": 6,
					// 	"buttons": [
					// 		{
					// 		"extend": 'colvis',
					// 		"text": "<i class='align-middle mr-2 fas fa-fw fa-eye'></i>",
					// 		"collectionLayout": 'two-column',
					// 		"postfixButtons": ['colvisRestore'],
					// 		}
					// 	],
					// 	"language": {
					// 		"buttons": {
					// 			//colvisRestore: "Select All",
					// 			"colvisRestore": "Restore"
					// 		}
					// 	}
					// });

					$('#separate_payment_scheduled_datatable tfoot').removeClass('d-none');

					var payment_total = '$'+currencyFormat(Number(response.response_data.payment_total));
					$('#ps_payment_total').html(payment_total);

					var ps_datatable = $('#separate_payment_scheduled_datatable').DataTable({
						"searching": false,
						"lengthChange": false,
						"info": false,
						"autoWidth": true,
						"paging": false,
						"ordering": true,
						// If you want display footer then reduce scroll Y to 350px.
						// "scrollY": '350px',
						"scrollY": '400px',
						"scrollX": true,
						"order" : [[3,'desc'], [1,'desc'], [0,'desc']],
						"responsive": { breakpoints: [
							{ name: 'desktop', width: Infinity },
							{ name: 'tablet',  width: 1024 },
							{ name: 'phone',   width: 768 }
						]},
						"columnDefs": [
							{
								"visible": false,
								"targets": [5,6,7]
							},
							{ "className": "all", "targets": [0] }, //id
							{ "className": "all", "targets": [1] }, //payment type
							{ "className": "all", "targets": [2]}, //due date
							{ "className": "all", "targets": [3]}, //month
							{ "className": "min-tablet", "targets": [4]}, //amount
							{ "className": "min-tablet", "targets": [8]}, //autopay
							{ "className": "min-tablet", "targets": [9]}, //status
							{ "className": "all", "targets": [10]}, //view
							{ "className": "all", "targets": [11]}, //action
							{
								"targets": [2, 3],
								"type": 'date'
							},
						],
						"dom": 'Bfrtip',
						"fixedHeader": true,
						"pageLength": 6,
						"buttons": [
							{
							"extend": 'colvis',
							"text": "<i class='align-middle mr-2 fas fa-fw fa-eye'></i>",
							"collectionLayout": 'two-column',
							"postfixButtons": ['colvisRestore'],
							}
						],
						"language": {
							"buttons": {
								//colvisRestore: "Select All",
								"colvisRestore": "Restore"
							}
						}
					});

					// $('.buttons-colvis').insertAfter('#ps_table_heading');
					$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-orange');

					$('.dt-buttons > button').addClass('mr-2');
					$('.dt-buttons').css({
						'position': 'absolute',
						// 'left': '15%',
						'top' : '-20%'
					});
					$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
						'border-radius': '5px'
					});

					$('.dt-buttons').appendTo('#datatable_buttons');

					// Adjust column widths when a column is shown/hidden
					ps_datatable.on('column-visibility.dt', function(e, settings, column, state) {
						ps_datatable.columns.adjust().draw();
					});
				}
			});
		});
	}, 1000);
}

window.getPropertyAccountingWidgetInfo = function(date_macro, user_role = '', account_user_id = '') {
	if ($.fn.DataTable.isDataTable("#property-accounting-datatable")) {
        $("#property-accounting-datatable").DataTable().destroy();
    }
	openLoadingDialog($('#property_accounting_widget'));
    setTimeout(function(){
    	var user_id = user.user_details.user_id;
	    var tz = jstz.determine();
		var timezone = tz.name();
		var property_id = $('#selected_property_id').val();
		if(user_role != ''){
			var service_name = user_role;
		} else{
			var service_name = user_service_name;
		}
		if(account_user_id != ''){
			user_id = account_user_id;
		}

		$('#property_accounting_widget').load('components/property/property_accounting_widget.html', property_id , function(){
			$.ajax({
		        method: 'POST',
		        url: api_url+"scheduled-payments/payment-receipt-report",
		        data: 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+service_name+'&date_macro='+date_macro+'&property_id='+property_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		var html = '';
						if(response.TotalscheduledPayments == 0) {
				        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
					    } else {
					    	for (var key in response.scheduledPayments) {
				                if (response.scheduledPayments.hasOwnProperty(key)) {

				                	// Proeprty Detail
				                	var property_name = response.scheduledPayments[key].property.name;
				                	var property_owner = response.scheduledPayments[key].po_name;

				                	var property_tenant = '';
				                	var tenant_detail = '';
				                	var tenant_name = '';
				                	var tenant_email = '';
				                	if(response.scheduledPayments[key].tenant_detail) {
					                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
				                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
				                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
				                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
				                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
				                            }
				                        }
			                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
			                        	property_tenant += tenant_detail;
			                        }

			                        var property_detail = property_name+'<br>'+'Current Owner:&nbsp;'+property_owner+'<br>Current Tenant:&nbsp;'+property_tenant;

			                        // Payment Method
			                        var bank_data = '';
					                var payment_details = response.scheduledPayments[key].payment_details;
					                if(payment_details != null) {
					                	if(Object.keys(payment_details).length !== 0) {
				                			bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
					                	}
					                }

			                        // Source and Destination
			                        var source_name = '';
			                        var source_email = '';
			                        var source_bank = '';
			                        var destination_name = '';
			                        var destination_email = '';
			                        var destination_bank = '';

			                        var company_name = 'Rent Synergy';
			                        if(response.scheduledPayments[key].payment_type == 1) {
			                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
			                        	source_email = response.scheduledPayments[key].po_email;
			                        	source_bank = bank_data;
			                        	destination_name = company_name;
			                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
			                        	source_name = tenant_name;
			                        	source_email = tenant_email;
			                        	source_bank = bank_data;
			                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
			                        	destination_email = response.scheduledPayments[key].po_email;
			                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
			                        	source_name = tenant_name;
			                        	source_email = tenant_email;
			                        	source_bank = bank_data;
			                        	destination_name = company_name;
			                        } else if(response.scheduledPayments[key].payment_type == 8) {
			                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
			                        	source_email = response.scheduledPayments[key].po_email;
			                        	source_bank = bank_data;
			                        	destination_name = company_name;
			                        } else if(response.scheduledPayments[key].payment_type == 9) {
			                        	source_name = company_name;
			                        	destination_name = tenant_name;
			                        	destination_email = tenant_email;
			                        	destination_bank = bank_data;
			                        }

			                        var source_ach_id = 'ACH Ref : NA';
						            var destination_ach_id = 'ACH Ref : NA';
						            if(response.scheduledPayments[key].dwolla_transfer_detail != null) {
						            	if(response.scheduledPayments[key].dwolla_transfer_detail.source_ach_id != '') {
						                	source_ach_id = 'ACH Ref : ' + response.scheduledPayments[key].dwolla_transfer_detail.source_ach_id;
						                }
						                if(response.scheduledPayments[key].dwolla_transfer_detail.destination_ach_id != '') {
						                	destination_ach_id = 'ACH Ref : ' + response.scheduledPayments[key].dwolla_transfer_detail.destination_ach_id;
						                }
						            }

			                        var source_data = source_name+'<br>'+source_email+'<br>'+source_bank+'<br>'+source_ach_id;
			                        var destination_data = destination_name+'<br>'+destination_email+'<br>'+destination_bank+'<br>'+destination_ach_id;

			                        // Payment Due Date
			                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
				                	if(response.scheduledPayments[key].payment_due_date == null) {
				                		payment_due_date = '';
				                	}

				                	// Rent month
			                        var rent_month = response.scheduledPayments[key].rent_month;
				                	if(response.scheduledPayments[key].rent_month == null) {
				                		rent_month = '';
				                	}

									var authorized_user_name = response.scheduledPayments[key].authorized_by_name;

				                	// Authorized Date
			                        var authorized_date = response.scheduledPayments[key].authorized_date;
			                        var authorized_time = response.scheduledPayments[key].authorized_time;
				                	if(response.scheduledPayments[key].authorized_date == null) {
				                		authorized_date = '';
				                		authorized_time = '';
				                	}

				                	// Initiated date Date
			                        var initiated_date = response.scheduledPayments[key].initiated_date;
			                        var initiated_time = response.scheduledPayments[key].initiated_time;
				                	if(response.scheduledPayments[key].initiated_date == null) {
				                		initiated_date = '';
				                		initiated_time = '';
				                	}

				                	// Completed date Date
			                        var completed_date = response.scheduledPayments[key].completed_date;
			                        var completed_time = response.scheduledPayments[key].completed_time;
				                	if(response.scheduledPayments[key].completed_date == null) {
				                		completed_date = '';
				                		completed_time = '';
				                	}

				                	// Coupon Data
				                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
				                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
				                	if(response.scheduledPayments[key].coupon_id == 0) {
				                		coupon_code = '';
				                		coupon_amount = '';
				                	}

				                	var coupon_data = coupon_code+'<br>'+coupon_amount;

				                	// Status
				                	var application_status = '';
				                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
				                        application_status += '<span class="badge badge-info fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
				                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
				                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
				                        application_status += '<span class="badge badge-success fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer' || response.scheduledPayments[key].application_constant == 'rs_failed_transfer') {
				                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
				                        application_status += '<span class="badge badge-primary fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'pending_collection') {
				                        application_status += '<span class="badge badge-info fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    }

				                    var kebab = '';
				                    var kebab_item = '';
				                    if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
					                    if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
					                    	kebab_item += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
					                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
					                    	kebab_item += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
					                    }
					                }

									if(user.permissionData['PROPERTIES']["feature_items['waive_late_fee']"] && (response.scheduledPayments[key].application_constant == 'pending_authorization' || response.scheduledPayments[key].application_constant == 'pending_collection') && (response.scheduledPayments[key].payment_type == 7 || response.scheduledPayments[key].payment_type == 2) && response.scheduledPayments[key].waive_late_fee > 0 && response.scheduledPayments[key].is_late_fee_enabled == 1) {
										kebab_item += '<a class="dropdown-item open_waive_late_fee_modal" href="javascript:void(0)" data-property-id="'+response.scheduledPayments[key].property_id+'" data-id="'+response.scheduledPayments[key].id+'">Waive Late Fee</a>';
									}

									if(user_service_name == sa_role && (response.scheduledPayments[key].application_constant == 'pending_authorization' || response.scheduledPayments[key].application_constant == 'pending_collection') && (response.scheduledPayments[key].payment_type == 7 || response.scheduledPayments[key].payment_type == 2) && response.scheduledPayments[key].is_late_fee_enabled == 1) {
										kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="disable" data-id="'+response.scheduledPayments[key].id+'">Disable Late Fee</a>';
									}

									if(user_service_name == sa_role && (response.scheduledPayments[key].application_constant == 'pending_authorization' || response.scheduledPayments[key].application_constant == 'pending_collection') && (response.scheduledPayments[key].payment_type == 7 || response.scheduledPayments[key].payment_type == 2) && response.scheduledPayments[key].is_late_fee_enabled == 0) {
										kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="enable" data-id="'+response.scheduledPayments[key].id+'">Enable Late Fee</a>';
									}

					                if(user.permissionData['PROPERTIES']["feature_items['regenerate_transaction']"]) {
					                    if(user_service_name == sa_role && response.scheduledPayments[key].regenerate_transaction == 1 && response.scheduledPayments[key].payment_type != 8) {
				                    		kebab_item += '<a class="dropdown-item regenerate_transaction" data-id="'+response.scheduledPayments[key].id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Regenerate Transaction</a>';
				                    	}
				                    }

				                    if(user_service_name == po_role && response.scheduledPayments[key].authorize_subscription_fee == 1) {
			                    		kebab_item += '<a class="dropdown-item authorize_subscription_fee" data-id="'+response.scheduledPayments[key].id+'" data-property-id="'+response.scheduledPayments[key].property_id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" data-payment-id="'+response.scheduledPayments[key].payment_details_id+'" data-month="'+response.scheduledPayments[key].rent_month+'" data-open-from="account" href="javascript:void(0)">Authorize Subscription Fee</a>';
			                    	}

									if(user.permissionData['PROPERTIES']["feature_items['edit_transaction']"]){
										if((response.scheduledPayments[key].application_constant == 'pending_authorization' || response.scheduledPayments[key].application_constant == 'pending_collection') && (response.scheduledPayments[key].payment_type == 7 || response.scheduledPayments[key].payment_type == 2) && response.scheduledPayments[key].lease.partial_payment_enabled == 1 && response.scheduledPayments[key].is_partial_payment == 0){
											kebab_item += '<a class="dropdown-item edit_transaction" data-user-id="'+response.scheduledPayments[key].userID+'" data-id="'+response.scheduledPayments[key].id+'" data-property-id="'+response.scheduledPayments[key].property_id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" data-payment-id="'+response.scheduledPayments[key].payment_details_id+'" data-month="'+response.scheduledPayments[key].rent_month+'" data-open-from="account" href="javascript:void(0)">Create partial payment</a>';
										}
									}


			                    	if($.trim(kebab_item) != '') {
							        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
							        }

				                    // Add exclamation button, when click display related transaction detail
				                	var exclamation = '';
				                	//if(application_status != '') {
				                		exclamation = '<i data-id="'+response.scheduledPayments[key].id+'" data-transferID="'+response.scheduledPayments[key].dwolla_transferID+'" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
				                	//}

			                        var property_setup_fee = '<span>'+response.scheduledPayments[key].property_setup_fee+'</span>';
			                        var security_deposit_fee = '<span>'+response.scheduledPayments[key].security_deposit_fee+'</span>';
			                        var pet_deposit_fee = '<span>'+response.scheduledPayments[key].pet_deposit_fee+'</span>';
			                        var subscription_fee = '<span>'+response.scheduledPayments[key].subscription_fee+'</span>';
			                        var rent_fee = '<span>'+response.scheduledPayments[key].rent_fee+'</span>';

			                        var rent_due_by = '';
			                        var rent_amount = '';
			                        var no_of_days = '';
			                        var late_fee_per_day = '';
			                        var outstanding_late_fee = '';
			                        var late_fee_waived = '';
			                        var total_rent_due = '';
			                        if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0 && (response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7)) {
				                        rent_due_by = response.scheduledPayments[key].payment_due_date;
				                        rent_amount = '$'+response.scheduledPayments[key].total;
				                        no_of_days = response.scheduledPayments[key].no_of_days;
				                        late_fee_per_day = '$'+response.scheduledPayments[key].price_per_day;
				                        if(response.scheduledPayments[key].late_fee_amount > 0) {
				                        	outstanding_late_fee = '$'+response.scheduledPayments[key].late_fee_amount;
				                        }
				                        if(response.scheduledPayments[key].waived_amount > 0) {
				                        	late_fee_waived = '$'+response.scheduledPayments[key].waived_amount;
				                        }
				                        total_rent_due = '$'+response.scheduledPayments[key].final_total;
				                    }

				                    var receipts = '-';
				                    if(response.scheduledPayments[key].receipts > 0 || response.scheduledPayments[key].receipts != '') {
				                    	receipts = response.scheduledPayments[key].receipts;
				                    }

				                    var payments = '-';
				                    if(response.scheduledPayments[key].payments > 0 || response.scheduledPayments[key].payments != '') {
				                    	payments = response.scheduledPayments[key].payments;
				                    }

				                    var tr_bg = 'odd';
				                	if(key % 2 == 1) {
				                		tr_bg = 'even';
				                	}

				                	html += '<tr data-id="'+response.scheduledPayments[key].id+'" class="'+tr_bg+'">';
				                		html += '<td class="text-left">'+response.scheduledPayments[key].id+'</td>';
				                		//html += '<td>'+property_detail+'</td>';
				                		html += '<td><span class="badge badge-primary fs-12">'+response.scheduledPayments[key].payment_type_label+'</span></td>';
			                            html += '<td>'+application_status+'<span class="ml-2">'+exclamation+'</span></td>';
			                            html += '<td class="text-right">'+receipts+'</td>';
			                            html += '<td class="text-right">'+payments+'</td>';
			                            html += '<td>'+rent_month+'</td>';
			                            html += '<td>'+authorized_user_name+'</td>';
			                            html += '<td>'+authorized_date+'<br><small>'+authorized_time+'</small></td>';
			                            html += '<td>'+initiated_date+'<br><small>'+initiated_time+'</small></td>';
			                            html += '<td>'+completed_date+'<br><small>'+completed_time+'</small></td>';
			                            html += '<td>'+source_data+'</td>';
			                            html += '<td>'+destination_data+'</td>';
			                            html += '<td>'+coupon_data+'</td>';
			                            html += '<td>-</td>';
			                            html += '<td>'+kebab+'</td>';
			                        html += '</tr>';
				                }
				            }
				        }

				        $('#property-accounting-data-container').html(html);
			            $('#property-accounting-datatable thead, #property-accounting-datatable tfoot').removeClass('d-none');
			            //$('#property-accounting-datatable').addClass('table-bordered');

			            $('#payment_total').html(response.payment_total);
				        $('#receipt_total').html(response.receipt_total);

			            var buttonCommon = {
					        exportOptions: {
					            format: {
					                body: function ( data, row, column, node ) {
					                	// Replace <br> with space
					                	var regex = /<br\s*[\/]?>/gi;
					                	data = data.replace(regex, " ");
					                	//data = data.replace(regex, "\n");

					                	// Remove all html tags from data and Remove $ from amount
					                	var regex = /(<([^>]+)>)/ig;
					                    var amt_col = [3,4];
					                    if(amt_col.indexOf(column) !== -1) {
					                    	var data = data.replace(regex, "");
					                    	return data.replace( /[$,]/g, '' );
					                    } else {
					                    	return data.replace(regex, "");
					                    }
					                }
					            }
					        }
					    };

					    var transaction_table = $("#property-accounting-datatable").DataTable({
			            	//responsive: true,
			            	fixedHeader: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'Bfrtip',
			    			autoWidth: false,
			    			paging: false,
			            	info: false,
						    scrollY: '350px',
						    scrollX: true,
					        order: [[0,'desc']],
					        columnDefs: [
					        	{ "visible": false, "targets": [7, 8, 9, 10, 11, 12, 13] },
								{
									"targets": 5,
									"type": 'date'
								},
							],
					        buttons: [
					        	$.extend( true, {}, buttonCommon, {
					                extend: 'excelHtml5',
						            text: '<i class="align-middle fas fa-fw fa-download"></i>',
						            title: 'Rental Ledger',
						            message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
						            autoFilter: true,
						            header: true,
						            footer: true,
						            exportOptions: {
					                    //columns: ':visible'
					                    columns: ':visible:not(.notexport)'
					                },
					                customize: function(xlsx) {
								        var sheet = xlsx.xl.worksheets['sheet1.xml'];
								        datatable_excel_common_formatting(xlsx,sheet);
								    }
					            }),
					            $.extend( true, {}, buttonCommon, {
					                extend: 'colvis',
					              	text: "<i class='align-middle mr-2 fas fa-fw fa-eye'></i>",
					              	collectionLayout: 'two-column',
					              	postfixButtons: ['colvisRestore'],
					            }),
							],
							language: {
						        buttons: {
						          	//colvisRestore: "Select All",
						          	colvisRestore: "Restore"
						        }
						    }
						});

						$('.buttons-html5').removeClass('btn-secondary').addClass('btn-orange');
						$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-orange');
						$('#property-accounting-datatable_filter').css({
							'position': 'absolute',
							'top': '5px',
							'right': '1%'
						})

						var dd = '';
						dd += '<div class="btn-group form-button ml-2">';
						    dd += '<div class="dropdown">';
						         dd += '<button type="button" class="btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-orange dropdown-toggle date-macro" data-toggle="dropdown" data-placement="bottom" data-original-title="Date macros"><i class="align-middle mr-2 far fa-fw fa-calendar-alt"></i></button>';
						        dd += '<ul class="dropdown-menu dropdown-menu-right" id="date_macros" style="right:20px;">';
						            dd += '<li><a href="javascript:void(0)">All Dates</a></li>';
						            dd += '<li><a href="javascript:void(0)">Today</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Week</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Month</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Quarter</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Year</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Week</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Month</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Quarter</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Year</a></li>';
						        dd += '</ul>';
						    dd += '</div>';
						dd += '</div>';

						$('.dt-buttons').append(dd);

						setTimeout(function(){
							if(user.permissionData['REPORTS'] && user.permissionData['REPORTS']["feature_items['account_role_dd']"]) {
								var acc_dd = '';
								acc_dd += '<div class="btn-group form-button ml-2">';
									acc_dd += '<div class="dropdown">';
										acc_dd += '<button type="button" class="btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-orange dropdown-toggle account-role" data-toggle="dropdown" data-placement="bottom" data-original-title="Account Role"><i class="align-middle mr-2 fas fa-fw fa-user"></i></button>';
										acc_dd += '<ul class="dropdown-menu dropdown-menu-right" id="account_role" style="right:20px;">';
											acc_dd += '<li href="javascript:void(0)" class="pointer" service_name="'+sa_role+'">Admin</li>';
											acc_dd += '<li href="javascript:void(0)" class="pointer" service_name="'+po_role+'">Property Owner</li>';
											let tenant_user_id = [];
											$('.user_account_role').each(function(){
												const t_user_id = $(this).attr('data-user-id');
												if(!tenant_user_id.includes(t_user_id)){
													tenant_user_id.push(t_user_id);
													if($(this).attr('data-status') == 2){
														acc_dd += '<li href="javascript:void(0)" data-user-id="'+$(this).attr('data-user-id')+'" class="pointer" service_name="'+t_role+'">'+$(this).attr('data-user-name')+' (T)</li>';
													}
												}
											});
										acc_dd += '</ul>';
									acc_dd += '</div>';
								acc_dd += '</div>';
								if(localStorage.getItem('account_role') != null){
									acc_dd += '<div>';
										acc_dd += '<h5 class="ml-2 mt-2 green">'+localStorage.getItem('account_role')+'</h5>';
									acc_dd += '</div>';
								}

								$('.dt-buttons').append(acc_dd);
							}
						}, 700);

						$('.dt-buttons > button').addClass('mr-2');
						$('.dt-buttons').css({
							'position': 'absolute',
							'left': '11%',
							'top' : '4px'
						});
						$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
							'border-radius': '5px'
						});

						$('.dt-buttons').insertAfter('#property_accounting_widget > .card-header > .card-title');

						// Get Related Transaction Detail
			            var title = 'Transaction Detail';
			            var type = 'RelatedTransaction';
			            openPopoverModal(title,type);
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyDetail = function(property_id, type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        //url: api_url+"properties/"+property_id,
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {

        	$('#selected_property_id').val(property_id);
            $('#selected_property_user_id').val(response.Property.user_id);

            if(user.permissionData['PROPERTIES']["feature_items['update_proeprty_detail']"]) {
            	$('#property_detail_footer').html('<button data-id="'+property_id+'" data-module="Property" class="btn btn-orange my-2 my-sm-0 ml-2 update_property_data">Update</button>');
            }

            if(user_service_name == t_role) {
            	$('.add_new_lease, .add_new_tenant').remove();
            	$('#propertyContactAccordion, #propertyDetailAccordion, #pictureAccordion, #property_picture_list_container, #lease_data_container, #tenant_data_container').addClass('add_opacity');
            }

        	// Create property picture list container
        	if(user.permissionData['PROPERTIES']["feature_items['update_property_pictures']"]) {
	            var img_list = '';
	            var prev_html = '';
	            var img_crousal_list = '';
	            var active = '';

	            for (var key in response.Property.user_attachments) {
	                if (response.Property.user_attachments.hasOwnProperty(key)) {
	                	var checked = '';
	                    if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
	                    	if(response.Property.user_attachments[key].cover_image == 1) {
	                    		checked = "checked";
	                    	}

	                        img_list += '<div id="user_property_media_'+response.Property.user_attachments[key].id+'" data-id="'+response.Property.user_attachments[key].id+'" data-property-id="'+property_id+'" class="col-12 col-md-4 col-xl-4 property_image"> <div class="card bg-light"> <div class="card-img"><input data-id="'+response.Property.user_attachments[key].id+'" name="property-img-radio" type="radio" class="d-none property_cover_image" '+checked+' data-toggle="tooltip" data-placement="bottom" title="Cover Image" data-original-title="Cover Image"><i class="align-middle mr-2 fas fa-fw fa-trash d-none delete_property_image" data-id="'+response.Property.user_attachments[key].id+'" data-module="Property" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img class="card-img-top" src="'+response.Property.user_attachments[key].aws_url+'" alt="Unsplash"></div> </div> </div>';
	                    }
	                }
	            }
	            $('#property_picture_list_container').html(img_list);
	        } else {
	        	$('#p_picture_container').addClass('d-none');
	        }

            if(response.Property.same_as_owner_address == true) {
            	$( "#same_as_owner_address").prop('checked', true);
            	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', true);
            	$('.save_property_primary_contact_detail').attr('disabled', true);
            	//$('#propertyContactAccordion').addClass('add_opacity');
            } else {
            	$( "#same_as_owner_address").prop('checked', false);
            	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', false);
            	$('.save_property_primary_contact_detail').attr('disabled', false);
            	//$('#propertyContactAccordion').removeClass('add_opacity');
            }

            if(response.Property.primary_contact_address != null) {
                $('#primary_contact_firstname').val(response.Property.first_name);
		        $('#primary_contact_lastname').val(response.Property.last_name);
		        $('#primary_contact_businessname').val(response.Property.business_name);
	            $('#primary_contact_id').val(response.Property.primary_contact_address.id);
	            $('#primary_contact_address_line1').val(response.Property.primary_contact_address.address_line1);
	            $('#primary_contact_address_line2').val(response.Property.primary_contact_address.address_line2);
	            $('#primary_contact_city').val(response.Property.primary_contact_address.city);
	            $('#primary_contact_state').val(response.Property.primary_contact_address.state);
	            $('#primary_contact_zip_code').val(response.Property.primary_contact_address.zip_code);
	            $('#primary_contact_country').val(response.Property.primary_contact_address.country);
	        } else {
	        	$('#primary_contact_firstname').val(response.Property.user.first_name);
		        $('#primary_contact_lastname').val(response.Property.user.last_name);
		        $('#primary_contact_businessname').val(response.addressData.address.business_name);
	            $('#primary_contact_address_line1').val(response.addressData.address.address_line1);
	            $('#primary_contact_address_line2').val(response.addressData.address.address_line2);
	            $('#primary_contact_city').val(response.addressData.address.city);
	            $('#primary_contact_state').val(response.addressData.address.state);
	            $('#primary_contact_zip_code').val(response.addressData.address.zip_code);
	            $('#primary_contact_country').val(response.addressData.address.country);
	        }

	        if(user_service_name == 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
		        $('#po_detail_card').addClass('d-none');
		    }

		    var po_detail = response.Property.user.first_name+' '+response.Property.user.last_name+'<br>'+response.Property.user.email;
		    $('#po_detail_card span').html(po_detail);

            if(response.Property.property_type != null) {
            	$('#property_types').val(response.Property.property_type.id);
            } else {
            	$('#property_types').val('');
            }

            $('#property_no_of_units').val(response.Property.no_of_units);
            $('#property_year_built').val(response.Property.built_year);
            $('#property_square_feet').val(response.Property.square_feet);
            $('#property_bedrooms').val(response.Property.no_of_bed);
            $('#property_bathrooms').val(response.Property.no_of_bath);
            $('#property_parking_spaces').val(response.Property.no_of_parking);
            $('#property_swimming_pool').val(response.Property.swimming_pool);
            $('#property_type_of_colling').val(response.Property.type_of_cooling);
            $('#property_type_of_heating').val(response.Property.type_of_heating);
            $('#property_type_of_water_heater').val(response.Property.type_of_water_heater);
            $('#property_ac_filter_size').val(response.Property.ac_filter_size);
            $('#property_ac_make_and_model').val(response.Property.ac_model);
            $('#property_water_heater_make_and_model').val(response.Property.water_heater_model);
            $('#property_additional_information').val(response.Property.additional_information);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.loadPropertyImagePreview = function(id, property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    	    var prev_html = '';

	    	    $('#pname').html(response.Property.name);

	    	    // Below code is used when user click on image and display full image

	            prev_html += '<div class="pt-0">';
            		prev_html += '<div id="carouselmageIndicators" class="carousel slide" data-ride="carousel">';
		                prev_html += '<ol class="carousel-indicators modal-carousel-indicators">';
			                var img_cnt = 0;
			                for (var key in response.Property.user_attachments) {
				                if (response.Property.user_attachments.hasOwnProperty(key)) {
				                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
				                		var act = '';
				                		if(response.Property.user_attachments[key].id == id) { act = 'active'; }
				                		prev_html += '<li data-target="#carouselmageIndicators" data-slide-to="'+img_cnt+'" class="'+act+'"></li>';
				                		img_cnt = img_cnt + 1;
			                    	}
			                    }
			                }
		                prev_html += '</ol>';
		                prev_html += '<div class="carousel-inner">';
		                	for (var key in response.Property.user_attachments) {
				                if (response.Property.user_attachments.hasOwnProperty(key)) {
				                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
				                		var act1 = '';
				                		if(response.Property.user_attachments[key].id == id) { act1 = 'active'; }
			                			prev_html += '<div class="carousel-item '+act1+'" data-interval="10000">';
					                        prev_html += '<img class="d-block" src="'+response.Property.user_attachments[key].aws_url+'" alt="'+response.Property.user_attachments[key].display_name+'">';
					                    prev_html += '</div>';
				                    }
					            }
					        }
				        prev_html += '</div>';
				        prev_html += '<a class="carousel-control-prev" href="#carouselmageIndicators" role="button" data-slide="prev">';
		                    prev_html += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
		                    prev_html += '<span class="sr-only">Previous</span>';
		                prev_html += '</a>';
		                prev_html += '<a class="carousel-control-next" href="#carouselmageIndicators" role="button" data-slide="next">';
		                    prev_html += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
		                    prev_html += '<span class="sr-only">Next</span>';
		                prev_html += '</a>';
		            prev_html += '</div>';
		        prev_html += '</div>';

		        $('#image_preview_container').html(prev_html);

				$('#carouselmageIndicators').carousel({
				  	cycle: true
				});

				// Total counting
				var totalItems = $('#carouselmageIndicators .carousel-item').length;
				var currentIndex = $('#carouselmageIndicators div.carousel-inner div.active').index() + 1;
				$('.totalNum').html(''+currentIndex+'/'+totalItems+'');

				$('#carouselmageIndicators').on('slid.bs.carousel', function() {
				    currentIndex = $('#carouselmageIndicators div.carousel-inner div.active').index() + 1;
				   $('.totalNum').html(''+currentIndex+'/'+totalItems+'');
				});

				// Change position of indicators to display proper in tab and mobile
				$('#modal_indicators').append($('.modal-carousel-indicators'));
	        } else {
	            display_error_message(response);
	        }
	    }
    });
}

window.getPropertyDocument = function(layout) {
	var user_id = user.user_details.user_id;
	var property_id = $('#selected_property_id').val();
	$.ajax({
        method: 'POST',
        //url: api_url+"properties/"+property_id,
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {

        	$('#selected_property_id').val(property_id);
            $('#selected_property_user_id').val(response.Property.user_id);

            // Set Property zoho template and normal pdf file data

            var checklist_list = '';

            // Start : Document section
            var file_cnt = 0;
            if(response.templateData != null) {
	            	for (var key in response.templateData) {
		                if (response.templateData.hasOwnProperty(key)) {
		                	file_cnt++

		                	// Create data for receipents
		                	var user_html = '';
		                	for (var akey in response.templateData[key].recipients) {
		                		if (response.templateData[key].recipients.hasOwnProperty(akey)) {
		                			user_html += '<div>'+response.templateData[key].recipients[akey].recipient+' : <strong>'+response.templateData[key].recipients[akey].status+'</strong> on '+response.templateData[key].recipients[akey].date+'</div>';
		                		}
		                	}

	                        // Set kebab menu conditions
					        var document_status = '';
					        var kebab = '';
					        var kebab_item = '';
			                var send_for_signature = '';
							var btn_class = '';

							var name = response.templateData[key].zoho_template.zoho_templateName;

							if(user_service_name == sa_role && response.templateData[key].status == 0) {
								kebab_item += '<a data-user-id='+response.templateData[key].user_id+' data-zoho-template-id='+response.templateData[key].zoho_template_id+' data-zohoTemplateID="'+response.templateData[key].zoho_template.zoho_templateID+'" data-zohoActionID="'+response.templateData[key].zoho_template.zoho_actionID+'" class="dropdown-item send_for_signatures" href="javascript:void(0)">Send for Signatures</a>';

								document_status += '<div class="badge badge-danger" title="'+response.templateData[key].zoho_template.zoho_templateName+'">'+response.templateData[key].status_label+'</div>';
							} else {
								if(response.templateData[key].status == 1) {
									btn_class = 'badge-primary';
								} else if(response.templateData[key].status == 2) {
									btn_class = 'badge-primary';
								} else if(response.templateData[key].status == 3) {
									btn_class = 'badge-warning';
								} else if(response.templateData[key].status == 4) {
									btn_class = 'badge-success';
								} else if(response.templateData[key].status == 5) {
									btn_class = 'badge-warning';
								} else if(response.templateData[key].status == 6) {
									btn_class = 'badge-success';
								} else if(response.templateData[key].status == 7) {
									btn_class = 'badge-danger';
								}

								document_status += '<div class="badge '+btn_class+'" title="'+response.templateData[key].zoho_template.zoho_templateName+'" data-container="body" data-toggle="popover" data-placement="top" data-content="'+user_html+'">'+response.templateData[key].status_label+'</div>';
							}

							if(response.templateData[key].status > 0) {
								kebab_item += '<a data-type="zoho" data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';
			                }

			                if(response.templateData[key].status == 0) {
			                	kebab_item += '<a data-id="'+response.templateData[key].id+'" class="dropdown-item delete_property_template" href="javascript:void(0)">Delete</a>';
			                }

					        if($.trim(kebab_item) != '') {
					        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					        }

					        if(layout == 'table') {
						        checklist_list += '<tr id="user_template_'+response.templateData[key].id+'">';
			                		checklist_list += '<td class="bg-light">'+response.templateData[key].id+'</td>';
			                		checklist_list += '<td class="bg-light">'+name+'</td>';
		                            checklist_list += '<td>'+response.templateData[key].zoho_template.document_for+'</td>';
		                            checklist_list += '<td>'+user_html+'</td>';
		                            checklist_list += '<td>&nbsp</td>';
		                            checklist_list += '<td>&nbsp</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>'+document_status+'</td>';
		                            checklist_list += '<td>'+kebab+'</td>';
		                        checklist_list += '</tr>';
		                    } else {
		                    	checklist_list += '<div id="user_template_'+response.templateData[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
						        	checklist_list += '<div class="card">';
										checklist_list += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 class="card-title capitalize mb-0 mr-3" title="'+response.templateData[key].zoho_template.zoho_templateName+'">'+name+'</h5></div>';
										checklist_list += '<div class="card-img"><img src="img/sample/pdf.png" width="100" height="100" alt="pdf" class="mb-2 mt-4"></div>';
										checklist_list += '<div class="card-body">';
											checklist_list += '<table class="table mb-0 card-table word-break">';
												checklist_list += '<tbody>';
													checklist_list += '<tr>';
														checklist_list += '<td class="nowrap">Document For</td>';
														checklist_list += '<td class="text-right">'+response.templateData[key].zoho_template.document_for+'</td>';
													checklist_list += '</tr>';
													checklist_list += '<tr>';
														checklist_list += '<td colspan="2" class="nowrap bold-black">Signature Recipients</td>';
													checklist_list += '</tr>';
												checklist_list += '</tbody>';
											checklist_list += '</table>';
											checklist_list += '<div class="mt-2 ml-2">'+user_html+'</div>';
											checklist_list += '<div class="text-right mt-4">'+document_status+'</div>';
										checklist_list += '</div>';
										checklist_list += '</div>';
									checklist_list += '</div>';
		                    }
		                }
		            }
	            }

            // Display normal document
            for (var key in response.Property.user_attachments) {
                if (response.Property.user_attachments.hasOwnProperty(key)) {
                    if(response.Property.user_attachments[key].module_name == "Property Checklist") {
                    	file_cnt++;
                        // Set kebab menu conditions
				        var kebab = '';
				        var kebab_item = '';
				        kebab_item += '<a data-type="normal" data-name="'+response.Property.user_attachments[key].display_name+'" data-url="'+response.Property.user_attachments[key].file_url+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';

				        if(response.Property.user_attachments[key].user_id == user_id && response.Property.user_attachments[key].status == 1) {
				        	kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item delete_property_image" href="javascript:void(0)">Delete</a>';
				        }

				        if(user_service_name == sa_role && response.Property.user_attachments[key].status == 2) {
				        	kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item undelete_property_image" href="javascript:void(0)">Un-Delete</a>';
				        }

				        if($.trim(kebab_item) != '') {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
				        }

				        var name = response.Property.user_attachments[key].display_name;

						var doc_status = '';
	                    if(response.Property.user_attachments[key].status == 1) {
	                        doc_status += '<span class="badge badge-primary">Active</span>';
	                    } else if(response.Property.user_attachments[key].status == 2) {
	                        doc_status += '<span class="badge badge-danger">Deleted</span>';
	                    }

						if(user_service_name == sa_role || response.Property.user_attachments[key].status == 1) {
							var uploaded_by = response.Property.user_attachments[key].user.first_name+' '+response.Property.user_attachments[key].user.last_name;
							var uploaded_on = response.Property.user_attachments[key].created;
							if(layout == 'table') {
								checklist_list += '<tr id="user_property_attachment_'+response.Property.user_attachments[key].id+'">';
			                		checklist_list += '<td class="bg-light">'+response.Property.user_attachments[key].id+'</td>';
			                		checklist_list += '<td class="bg-light">'+name+'</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>'+response.Property.user_attachments[key].file_size+'</td>';
		                            checklist_list += '<td>'+uploaded_by+'</td>';
		                            checklist_list += '<td>'+uploaded_on+'</td>';
		                            checklist_list += '<td>'+doc_status+'</td>';
		                            checklist_list += '<td>'+kebab+'</td>';
	                        	checklist_list += '</tr>';
							} else {
						    	checklist_list += '<div id="user_property_attachment_'+response.Property.user_attachments[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
					        	//checklist_list += '<div class="card"><div class="card-header"><span class="accordion_arrow">'+kebab+'</span><h5 class="card-title capitalize mb-0 mr-3" title="'+response.Property.user_attachments[key].display_name+'">'+name+'</h5></div><div class="card-img"><img src="img/sample/pdf.png" alt="Pdf" class="mb-2 mt-3"></div><div class="card-body"><div>File size: <strong>'+response.Property.user_attachments[key].file_size+'</strong></div><div>Uploaded By: <strong>'+uploaded_by+'</strong></div><div>Uploaded On: <strong>'+uploaded_on+'</strong></div><div class="text-right mt-4">'+doc_status+'</div></div></div></div>';

					        	checklist_list += '<div class="card">';
									checklist_list += '<div class="card-header"><span class="accordion_arrow">'+kebab+'</span><h5 class="card-title capitalize mb-0 mr-3" title="'+response.Property.user_attachments[key].display_name+'">'+name+'</h5></div>';
									checklist_list += '<div class="card-img"><img src="img/sample/pdf.png" alt="Pdf" class="mb-2 mt-3"></div>';
									checklist_list += '<div class="card-body">';
										checklist_list += '<table class="table mb-0 card-table word-break">';
											checklist_list += '<tbody>';
												checklist_list += '<tr>';
													checklist_list += '<td class="nowrap">File Size</td>';
													checklist_list += '<td class="text-right">'+response.Property.user_attachments[key].file_size+'</td>';
												checklist_list += '</tr>';
												checklist_list += '<tr>';
													checklist_list += '<td class="nowrap">Uploaded By</td>';
													checklist_list += '<td class="text-right">'+uploaded_by+'</td>';
												checklist_list += '</tr>';
												checklist_list += '<tr>';
													checklist_list += '<td class="nowrap">Uploaded On</td>';
													checklist_list += '<td class="text-right">'+uploaded_on+'</td>';
												checklist_list += '</tr>';
											checklist_list += '</tbody>';
										checklist_list += '</table>';
										checklist_list += '<div class="text-right mt-4">'+doc_status+'</div>';
									checklist_list += '</div>';
								checklist_list += '</div>';
								checklist_list += '</div>';
					        }
				        }
                    }
                }
            }

            if(file_cnt == 0) {
            	checklist_list += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">No results found</div></a>';
            }

            if(layout == 'table') {
            	$('#document_table_row').html(checklist_list);
	            var document_table = $("#document-datatable").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '500px',
				    scrollX: true,
			        scrollCollapse: true,
			        order: [[0,'desc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
			           {
			              extend: 'colvis',
			              text: "Columns Visibility ",
			              collectionLayout: 'two-column',
			              postfixButtons: ['colvisRestore'],
			           }
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
					initComplete: function () {
			          	//console.log("The Table has been initialized!")
			        }
				});

				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
            } else {
            	$('#document_data_container').html(checklist_list);
            }

            // Get Floating Action Button
			getFloatingActionButton('Document', '#document_data_container');

            // End : Document section
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.savePropertyPrimaryContactDetail = function(property_id) {
	var first_name = $('#primary_contact_firstname').val();
	var last_name = $('#primary_contact_lastname').val();
	var business_name = $('#primary_contact_businessname').val();
	var address_line1 = $('#primary_contact_address_line1').val();
	var address_line2 = $('#primary_contact_address_line2').val();
	var city = $('#primary_contact_city').val();
	var state = $('#primary_contact_state').val();
	var zip_code = $('#primary_contact_zip_code').val();
	var country = $('#primary_contact_country').val();
	var user_id = user.user_details.user_id;

	var data = 'property_id='+property_id+'&first_name='+first_name+'&last_name='+last_name+'&business_name='+business_name+'&address_line1='+address_line1+'&address_line2='+address_line2+'&city='+city+'&state='+state+'&zip_code='+zip_code+'&country='+country+'&usps_verified=1'+'&user_id='+user_id;

	$.ajax({
        method: 'POST',
        url: api_url+"properties/save-primary-contact-address",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {

            }
        }
    });
}

window.loadPropertyTypes = function(container='') {
	$.ajax({
	    method: 'GET',
	    url: api_url+"property-types",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    if(response.response_data.success == true) {
	        var html = "<option value=''>Select Type</option>";
	        for (var key in response.propertyTypes) {
	            if (response.propertyTypes.hasOwnProperty(key)) {
	                var id = response.propertyTypes[key].id;
	                var name = response.propertyTypes[key].name;
	                html += '<option value="'+id+'">'+name+'</option>';
	            }
	        }
	        $('#'+container).html(html);
	    } else {

	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

/* Start : Account */

window.loadTableAccountDetail = function() {
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();

	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&property_id='+property_id+'&user_service_name='+user_service_name;

	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {

	                	var bg_light = '';
	                	if(key % 2 == 1) {
	                		bg_light = 'bg-light';
	                	}

	                	var rent_month = ' ';
	                	if(response.scheduledPayments[key].rent_month != null && response.scheduledPayments[key].payment_type != 1) {
	                		rent_month = response.scheduledPayments[key].rent_month;
	                	}

	                	var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	var bank_name = '';
		                var account_number = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(payment_details != null) {
			                if(Object.keys(payment_details).length !== 0) {
		                		bank_name = response.scheduledPayments[key].payment_details.bank_name;
		                		account_number = response.scheduledPayments[key].payment_details.account_number;
			                }
			            }

	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	var tax_percentage = response.scheduledPayments[key].tax_percentage+'%';
	                	var tax_amount = '$'+response.scheduledPayments[key].tax_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '-';
	                		coupon_amount = '-';
	                	} if(response.scheduledPayments[key].tax_percentage == 0) {
	                		tax_percentage = '-';
	                		tax_amount = '-';
	                	}

	                	var application_status = '';
	                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
	                        application_status += '<span class="badge badge-warning">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
	                        application_status += '<span class="badge badge-warning">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
	                        application_status += '<span class="badge badge-success">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer' || response.scheduledPayments[key].application_constant == 'rs_failed_transfer') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
	                        application_status += '<span class="badge badge-primary">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'pending_collection') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    }

	                    var kebab = '';
	                    if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
		                    if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
		                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a></div></div></div>';
		                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
		                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a></div></div></div>';
		                    }
		                }

		                if(user.permissionData['PROPERTIES']["feature_items['regenerate_transaction']"]) {
		                    if(user_service_name == sa_role && response.scheduledPayments[key].regenerate_transaction == 1 && response.scheduledPayments[key].payment_type != 8) {
	                    		kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item regenerate_transaction" data-id="'+response.scheduledPayments[key].id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Regenerate Transaction</a></div></div></div>';
	                    	}
	                    }

	                    var po_detail = response.propertyOwnerDetail.user.first_name+' '+response.propertyOwnerDetail.user.last_name;
	                    if(user_service_name == t_role) {
	                    	po_detail = 'Rent Synergy';
	                    }

	                	// Card based UI

	                	var payment_amount = response.scheduledPayments[key].final_total;

	                	// Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	//if(application_status != '') {
	                		exclamation = '<i data-id="'+response.scheduledPayments[key].id+'" data-transferID="'+response.scheduledPayments[key].dwolla_transferID+'" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	//}

	                	html += '<div class="card tab-card '+bg_light+'">';
				            html += '<div class="accordion">';
				                html += '<div class="border-bottom">';
				                if(response.scheduledPayments[key].payment_type == 1) {
				                    html += '<h5 class="card-title my-2 fs-16"><span class="bold-black">'+response.scheduledPayments[key].payment_type_label+'</span>&nbsp;<span>'+application_status+'</span>'+exclamation+'</h5>';
				                } else {
				                    html += '<h5 class="card-title my-2 fs-16"><span class="bold-black">'+response.scheduledPayments[key].payment_type_label+'</span><small> for '+rent_month+' </small>&nbsp;<span>'+application_status+'</span>'+exclamation+'</h5>';
				                }
				                    html += '<span class="accordion_arrow">';
				                        html += kebab;
				                    html += '</span>';
				                html += '</div>';
				                html += '<div class="tab_inner_data" data-id="'+response.scheduledPayments[key].id+'">';
				                    html += '<div class="row">';
				                    	html += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Property Owner:</strong></div>';
				                                    html += '<div class="grey"><strong>'+po_detail+'</strong></div>';
				                                    var tenant_detail = '';
							                        if(response.scheduledPayments[key].tenant_detail) {
							                        	html += '<div class="black"><strong>Tenant:</strong></div>';
								                        for (var tkey in response.scheduledPayments[key].tenant_detail) {
					                						if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
					                							tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
					                						}
					                					}
					                					tenant_detail = tenant_detail.replace(/,\s*$/, "");
					                					html += '<div class="grey"><strong>'+tenant_detail+'</strong></div>';
					                				}
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                    	html += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                	html += '<div class="black"><strong>Due Date:</strong></div>';
				                                    html += '<div><small>'+payment_due_date+'</small></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-6 col-xl-3 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Payment Method:</strong></div>';
				                                    html += '<div class="grey"><strong>'+bank_name+'</strong></div>';
				                                    html += '<div class="grey"><strong>'+account_number+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html  += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html  += '<div class="card flex-fill '+bg_light+'">';
				                                html  += '<div class="mt-2">';
				                                    html  += '<div class="black"><strong>Coupon:</strong></div>';
				                                    html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
				                                    html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-6 col-xl-3 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Payment(USD):</strong></div>';
				                                    html += '<div class="black fs-18"> $'+payment_amount+'</div>';
				                                    if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0) {
				                                    	html += '<div><small>(A) Rent Due by '+response.scheduledPayments[key].payment_due_date+' : $'+response.scheduledPayments[key].total+'</small></div>';
				                                    	html += '<div ml-4><small>(1) No. of days late after '+response.scheduledPayments[key].lateFeeafterDays+' day of the month: '+response.scheduledPayments[key].no_of_days+'</small></div>';
				                                    	html += '<div ml-4><small>(2) Late fee applied per day: $'+response.scheduledPayments[key].price_per_day+'</small></div>';
				                                    	html += '<div><small class="red">(B) Outstanding late fee (1) x (2): $'+response.scheduledPayments[key].late_fee_amount+'</small></div>';
				                                    	html += '<div><small class="green">(C) Late fee waived: $'+response.scheduledPayments[key].waived_amount+'</small></div>';
				                                    	html += '<div><small>(D) Total (A) + (B) - (C): $'+payment_amount+'</small></div>';
				                                    }
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
	                }
	            }
	        }

            $('#accountingDataContainer').html(html);

            // Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.cancelPayment = function(id,cancel_type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/cancel-payment",
        data:'scheduled_payment_id='+id+'&type='+cancel_type+'&user_id='+user_id+'&user_service_name='+user_service_name,
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
                loadTableAccountDetail();
                getPropertyAccountingWidgetInfo('');
                localStorage.removeItem('account_role');
            }
        }
    });
}

window.cancelPaymentSchedule = function(id,cancel_type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/cancel-payment",
        data:'payment_schedule_id='+id+'&type='+cancel_type+'&user_id='+user_id+'&user_service_name='+user_service_name,
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
            }
        }
    });
}

window.regenerateTransaction = function(id,payment_type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/regenerate-transaction",
        data:'id='+id+'&user_id='+user_id+'&user_service_name='+user_service_name,
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
                loadTableAccountDetail();
                getPropertyAccountingWidgetInfo('');
                localStorage.removeItem('account_role');
            }
        }
    });
}

window.getSubscriptionDetail = function(id, property_id, payment_type, payment_details_id, rent_month, open_from) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-subscription-fee-detail",
        data: 'user_id='+user_id+'&property_id='+property_id+'&scheduled_payment_id='+id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {

        		openLoadingDialog($('#authorize_subscription_fee_container'));
        		setTimeout(function(){
					var bank_html = "<option value=''>Select Payment Method</option>";
					if(response.paymentDetails != null) {
		                for (var key in response.paymentDetails) {
					        if (response.paymentDetails.hasOwnProperty(key)) {
					        	if(payment_details_id == response.paymentDetails[key].id) {
			            			var b_selected = 'selected';
			            		} else {
			            			var b_selected = '';
			            		}
					        	bank_html += '<option value="'+response.paymentDetails[key].id+'" '+b_selected+'>'+response.paymentDetails[key].bank_name+'</option>';
							}
						}
					}

		        	var html = '';
		            html += '<input type="hidden" name="subscription_property_id" id="subscription_property_id" value="'+property_id+'">';
		            html += '<input type="hidden" name="subscription_scheculed_payment_id" id="subscription_scheculed_payment_id" value="'+id+'">';
		            html += '<input type="hidden" name="subscription_fee_open_from" id="subscription_fee_open_from" value="'+open_from+'">';
		            	html += '<form id="subscription-fee-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
					    html += '<div id="payNowCollapse">';
				    		var add_opacity = 'add_opacity';
					    	if(response.scheduledPayment != null) {
			            		html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
						            html += '<div class="border-bottom">';
						                html += '<h5 class="card-title"><span class="fs-22">Subscription fees</h5> <span class="bold-black">('+response.scheduledPayment.property.name+')</span>';
						            html += '</div>';
						            html += '<div class="rent_collection_data mt-2">';
						                html += '<div class="row">';
						                	html += '<div class="col-12 col-md-4 col-xl-4">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Month</strong></div>';
						                            html += '<div class="black fs-18"><strong>'+response.scheduledPayment.rent_month+'</strong></div>';
						                        html += '</div>';
						                    html += '</div>';
						                	html += '<div class="col-12 col-md-4 col-xl-4 '+add_opacity+'">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
						                            html += '<div><select name="subscription_payment_detail_id" id="subscription_payment_detail_id" class="bank_dd form-control">'+bank_html+'</select></div>';
						                        html += '</div>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
						                            html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
						                        html += '</div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
						            html += '<div class="rent_collection_data">';
						                html += '<div class="row">';
						                    html += '<div class="col-12 col-md-6 col-xl-9">';
						                        html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong></h5>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
						                    	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						    }

						    html += '<div class="col-auto d-sm-block mb-2 mt-4">';
					            html += '<div class="autopay_checkbox">';
					                html += '<label class="custom-control custom-checkbox m-0">';
					                html += '<input name="subscription_fee_authorize_checkbox" id="subscription_fee_authorize_checkbox" type="checkbox" class="custom-control-input">';
					                html += '<span class="custom-control-label">'+authorize_text+'</span>';
					                html += '</label>';
					            html += '</div>';
					        html += '</div>';
					    html += '</div>';
					html += '</form>';

					$('#authorize_subscription_fee_container').html(html);
				}, 1000);

            } else {
            	display_error_message(response);
            }
        }
    });
}

window.authorizeSubscriptionFee = function(scheduled_payment_id, property_id, payment_detail_id, open_from) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	var data = 'user_id='+user_id+'&user_timezone='+timezone+'&scheduled_payment_id='+scheduled_payment_id+'&property_id='+property_id+'&payment_detail_id='+payment_detail_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/authorize-subscription-fee",
        data:data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#authorizeSubscriptionFeeModal').modal('hide');
            	if(open_from == 'account') {
            		getPropertyAccountingWidgetInfo();
            	} else if(open_from == 'notification') {
            		// Enable next card
	            	// $('#subscription_fee_card_'+scheduled_payment_id).next('div.subscription_fee_section').find('.authorize_subscription_fee').removeClass('add_opacity');
	            	// // Remove current card
	            	// $('#subscription_fee_card_'+scheduled_payment_id).remove();
	            	// When authorized payment from detail page below function call and reload monthly card
	            	getPropertyAccountingWidgetInfo();
	            	// var notification = parseInt($('#notification_count').text());
	            	// var total_notification = notification - 1;
	            	// $('#notification_indicator, #notification_count').text(total_notification);
					getNotificationData();
            	}
            }
        }
    });
}

window.getLeaseReport = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&user_service_name='+user_service_name+'&user_id='+user_id;

	$.ajax({
	    method: 'POST',
	    url: api_url+"leases/get-lease-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalLeases == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
	            for (var key in response.leases) {
	                if (response.leases.hasOwnProperty(key)) {

	                	var property_name = '';
		                if(response.leases[key].property != null) {
		                	property_name = response.leases[key].property.name;
		                }

	                	var address = '';
		                if(response.leases[key].property != null && response.leases[key].property.address != null) {
		                	address = response.leases[key].property.address.address_line1+', '+response.leases[key].property.address.address_line2+'<br>'+response.leases[key].property.address.city+', '+response.leases[key].property.address.state+' '+response.leases[key].property.address.zip_code;
		                }

	                	var rent_due_date = ordinal_suffix_of(response.leases[key].rent_due_date);
	                	var sd_due_date = '';
	                	var pd_due_date = '';
	                	if(response.leases[key].sd_due_date != null) {
	                		sd_due_date = 'Due on '+response.leases[key].sd_due_date;
	                	}
	                	if(response.leases[key].pd_due_date != null) {
	                		pd_due_date = 'Due on '+response.leases[key].pd_due_date;
	                	}

	                	var status = '';
	                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
	                        status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
	                    } if(response.leases[key].status == 1 || response.leases[key].status == 5) {
	                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
	                    }

	                    html += '<tr data-id="'+response.leases[key].id+'">';
	                		html += '<td class="bg-light">'+response.leases[key].id+'</td>';
	                		html += '<td class="bg-light"><span class="bold-black">'+property_name+'</span><br><small>'+address+'</small></td>';
	                		html += '<td>'+response.leases[key].start_date+'</td>';
	                		html += '<td>'+response.leases[key].end_date+'</td>';
	                		html += '<td class="text-right bold-black">$'+response.leases[key].monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
	                		html += '<td class="text-right bold-black">$'+response.leases[key].security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
	                		html += '<td class="text-right bold-black">$'+response.leases[key].pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
	                		html += '<td>'+response.leases[key].tenant+'</td>';
	                		html += '<td>'+status+'</td>';
                        html += '</tr>';
	                }
	            }
	        }

            $('#lease_report_data_container').html(html);
            var lease_table = $("#lease-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '600px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 2
		        },
		        buttons: [
		           {
		              extend: 'colvis',
		              text: "Columns Visibility ",
		              collectionLayout: 'two-column',
		              postfixButtons: ['colvisRestore'],
		           }
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    },
				initComplete: function () {
		          	//console.log("The Table has been initialized!")
		        }
			});

			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

// Epxneses code start
window.getExpensesPolicies = function(page = null) {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-policies",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Policy</option>";
            for (var key in response.data) {
                    var id = response.data[key].policy_id;
                    var name = response.data[key].policy_name;
					var is_default = response.data[key].is_default;
					if(page == 'add_expense' && is_default == true){
						html += '<option value="'+id+'" selected>'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
            }
            $('#inputPolicy').html(html);
        }
    })
}

window.loadVendorData = function(){
	var user_id = user.user_details.user_id;
	if ($.fn.DataTable.isDataTable("#vendor-list-datatable")) {
        $("#vendor-list-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'POST',
        url: api_url+"vendors",
		data: "user_id="+user_id+"&user_service_name="+user_service_name,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			var html = '';
        	if(response.response_data.success == true) {
	            for (var key in response.vendors) {
						var kebab = '<a class="dropdown-item edit_vendor" href="javascript:void(0)" data-id="'+response.vendors[key].id+'">Edit</a>';
						kebab += '<a class="dropdown-item delete_vendor" href="javascript:void(0)" data-id="'+response.vendors[key].id+'">Delete</a>';
						html += '<tr>';
							html += '<td>'+response.vendors[key].id+'</td>';
							if(response.vendors[key].name != null){
								html += '<td>'+response.vendors[key].name+'</td>';
							} else {
								html += '<td>-</td>';
							}

							if(response.vendors[key].email != null){
								html += '<td>'+response.vendors[key].email+'</td>';
							} else {
								html += '<td>-</td>';
							}

							if(response.vendors[key].phone != null){
								html += '<td>'+response.vendors[key].phone+'</td>';
							} else {
								html += '<td>-</td>';
							}

							html += '<td>'+response.vendors[key].status_label+'</td>';
							html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
						html+= '</tr>';
	            }
	        } else {
				html += '<p> No Data Available </p>';
	        	// display_error_message(response);
	        }
			$('#vendor_data_container').html(html);

			$("#vendor-list-datatable").DataTable({
				responsive: true
			});
		}
	});
}

window.getVendorDetails = function(vendor_id){
	$.ajax({
        method: 'GET',
        url: api_url+"vendors/detail/"+vendor_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			if(response.response_data.success == true) {
				$('#vendor_id').val(vendor_id);
				$('#vendorName').val(response.vendor.name);

				if(response.vendor.email != null){
					$('#vendorEmail').val(response.vendor.email);
				}

				if(response.vendor.phone != null){
					$('#vendorPhone').val(response.vendor.phone);
				}

				if(response.vendor.vendor_address != null){
					$('#address_id').val(response.vendor.vendor_address.address_id);
					$('#vendorAddress1').val(response.vendor.vendor_address.address.address_line1);
					if(response.vendor.vendor_address.address.address_line2 != null){
						$('#vendorAddress2').val(response.vendor.vendor_address.address.address_line2);
					}

					$('#vendorZip').val(response.vendor.vendor_address.address.zip_code);
					$('#vendorCity').val(response.vendor.vendor_address.address.city);
					$('#vendorCountry').val(response.vendor.vendor_address.address.country).trigger('change');

					var selected_state = response.vendor.vendor_address.address.state;
					if(selected_state != null){
						getStateDropdownForSignup(selected_state);
					} else {
						getStateDropdownForSignup();
					}
				} else {
					getStateDropdownForSignup();
				}
			} else {
				display_error_message(response);
			}
		}
	})
}

window.updateVendor = function(){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"vendors/update",
		data : $('#edit_vendor_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadVendorData();
			}
        }
	})
}

window.submitVendor = function(){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"vendors/create",
		data : $('#new_vendor_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadVendorData();
			}
        }
	})
}

window.deleteVendor = function(vendor_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"vendors/delete",
		data : 'vendor_id='+vendor_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				loadVendorData();
			}
        }
	})
}

window.submitAccount = function(){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"accounts/create",
		data : $('#new_account_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadAccountData();
			}
        }
	})
}

window.submitSubAccount = function(){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"accounts/create-sub-account",
		data : $('#new_sub_account_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();

				var user_id = user.user_details.user_id;
				var account_id = $('input[name="account-radio"]:checked').attr('data-id');
				loadSubAccountData(account_id, user_id);
			}
        }
	})
}

window.editAccount = function(){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"accounts/update",
		data : $('#edit_account_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadAccountData();
			}
        }
	})
}

window.editSubAccount = function(){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"accounts/update-sub-account",
		data : $('#edit_sub_account_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				var user_id = user.user_details.user_id;
				var account_id = $('input[name="account-radio"]:checked').attr('data-id');
				loadSubAccountData(account_id, user_id);
			}
        }
	})
}

window.deleteAccount = function(account_id){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"accounts/delete",
		data : '&user_id='+user_id+'&accountId='+account_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadAccountData();
				$('#sub_account_data_container').addClass('d-none');
			}
        }
	})
}

window.deleteSubAccount = function(sub_account_id){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"accounts/delete-sub-account",
		data : '&user_id='+user_id+'&subAccountId='+sub_account_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				var user_id = user.user_details.user_id;
				var account_id = $('input[name="account-radio"]:checked').attr('data-id');
				loadSubAccountData(account_id, user_id);
			}
        }
	})
}

window.getAccountById = function(account_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"accounts/get-account-by-id",
		data : 'accountId='+account_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			if(response.response_data.success == true){
				$('#accountId').val(account_id);
				$('#accountName').val(response.data.account_name);
				$('#accountDescription').val(response.data.description);
				//$('#isDefault').val(response.data.is_default);
				//$('#isDefault option[value="'+response.data.is_default+'"]').attr("selected",true);
				$('#isDefault').val(''+response.data.is_default+'').trigger('change');
				getExpenseCategories('edit_account', response.data.category_id);
				getExpenseSubCategories('edit_account', response.data.category_id, response.data.sub_category_id)
			}
        }
	})
}

window.getSubAccountById = function(sub_account_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"accounts/get-sub-account-by-id",
		data : 'subAccountId='+sub_account_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			if(response.response_data.success == true){
				$('#subAccountId').val(sub_account_id);
				$('#subAccountName').val(response.data.sub_account_name);
				$('#subAccountDescription').val(response.data.description);
			}
        }
	})
}

window.loadAccountData = function(){
	var user_id = user.user_details.user_id;
	if ($.fn.DataTable.isDataTable("#account-data-datatable")) {
        $("#account-data-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'GET',
        url: api_url+"expenses/get-accounts",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			var html = '';
			for(var key in response.data){
				var radio = '<label class="custom-control custom-radio"><input name="account-radio" type="radio" class="custom-control-input get_sub_account_data" data-id="'+response.data[key].account_id+'"><span class="custom-control-label">&nbsp;</span></label>';
				var kebab = '<a class="dropdown-item view_account" href="javascript:void(0)" data-id="'+response.data[key].account_id+'">Edit</a><a class="dropdown-item delete_account" href="javascript:void(0)" data-id="'+response.data[key].account_id+'">Delete</a>';

				html += '<tr data-id="'+response.data[key].account_id+'">';
					html += '<td>'+radio+'</td>';
					html += '<td>'+response.data[key].account_name+'</td>';
					html += '<td>'+response.data[key].category.category_name+'</td>';
					html += '<td>'+response.data[key].sub_category.sub_category_name+'</td>';
					html += '<td>'+response.data[key].description+'</td>';
					if(response.data[key].is_default == true){
						html += '<td><span class="badge badge-success fs-12">YES</span></td>';
					} else{
						html += '<td><span class="badge badge-primary fs-12">NO</span></td>';
					}
					html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
				html += '</tr>';
			}
			$('#account_row_data_container').html(html);
			var transaction_table = $("#account-data-datatable").DataTable({
				responsive: true
			});
        }
	})
}

window.loadSubAccountData = function(account_id, user_id){
	if ($.fn.DataTable.isDataTable("#sub-account-data-datatable")) {
        $("#sub-account-data-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'POST',
        url: api_url+"expenses/get-sub-accounts",
		data: "account_id="+account_id+"&user_id="+user_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			$('#sub_account_data_container').removeClass('d-none');
			$('.add_new_sub_account').attr('data-id',account_id);
			var html = '';
			for(var key in response.data){
				var kebab = '<a class="dropdown-item view_sub_account" href="javascript:void(0)" data-id="'+response.data[key].sub_account_id+'">Edit</a><a class="dropdown-item delete_sub_account" href="javascript:void(0)" data-id="'+response.data[key].sub_account_id+'">Delete</a>';
				html += '<tr data-id="'+response.data[key].sub_account_id+'">';
				// html += '<td>'+radio+'</td>';
					html += '<td>'+response.data[key].sub_account_name+'</td>';
					html += '<td>'+response.data[key].description+'</td>';
					html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
				html += '</tr>';
			}
			$('#sub-account_row_data_container').html(html);
			var transaction_table = $("#sub-account-data-datatable").DataTable({
				responsive: true
			});
		}
	})

}

// Epxneses code start
window.getExpenseVendor = function(page = null, vendor_id = '') {
	var user_id = user.user_details.user_id;
    $.ajax({
        method: 'POST',
        url: api_url+"vendors",
		data: "user_id="+user_id+"&user_service_name="+user_service_name,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			var html = '';
			html += "<option value=''>Select Pay to</option>";
        	if(response.response_data.success == true) {
	            for (var key in response.vendors) {
	                    var id = response.vendors[key].id;
	                    var name = response.vendors[key].name;
						if(page == 'edit_expense' && id == vendor_id) {
							html += '<option value="'+vendor_id+'" selected="true">'+name+'</option>';
						} else {
							html += '<option value="'+id+'">'+name+'</option>';
						}
	            }
	        } else {
	        	//display_error_message(response);
	        }
			$('#inputVendor').html(html);
        }
    });
}

window.getExpenseAccounts = function(page = null, line_item_account_id = '') {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-expense-accounts",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Account</option>";
            for (var key in response.data) {
				html += '<optgroup label="'+key+'">';
					for(var subkey in response.data[key]){
						var id = response.data[key][subkey].account_id;
						var name = response.data[key][subkey].account_name;
						var is_default = response.data[key][subkey].is_default;
						if(page == 'add_expense' && is_default == true){
							html += '<option value="'+id+'" selected>'+name+'</option>';
						} else if(page == 'edit_expense' && id == line_item_account_id) {
							html += '<option value="'+line_item_account_id+'" selected="true">'+name+'</option>';
						} else {
							html += '<option value="'+id+'">'+name+'</option>';
						}
					}
				html += '</optgroup>';
            }
			//console.log(sub_category_name);
            $('#inputAccount').html(html);

			if(page == 'add_expense'){
				var account_id = $('#inputAccount').val();
				if(account_id != '' && account_id != null){
					getExpenseSubAccounts(page,account_id);
				} else {
					$('#inputSubAccount').attr('disabled',true);
					var html = '';
					html += "<option value=''>Select Sub Account</option>";
					$('#inputSubAccount').html(html);
					$('#inputSubAccount').val('');
				}
			}
        }
    });
}

window.getExpenseCategories = function(page = null, selected_category_id = '') {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-categories",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Category</option>";
            for (var key in response.data) {
				if(response.data[key].category_name == 'Expense' || response.data[key].category_name == 'Income'){
                    var id = response.data[key].category_id;
                    var name = response.data[key].category_name;
					if(page == 'edit_account' && id == selected_category_id) {
						html += '<option value="'+selected_category_id+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
				}
            }
            $('#accountCategory').html(html);
            $('#accountCategory').select2();
        }
    });
}

window.getExpenseSubCategories = function(page = null, category_id, selected_sub_category_id = '') {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"expenses/get-sub-categories",
		data: "category_id="+category_id+"&user_id="+user_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Sub Category</option>";
            for (var key in response.data) {
				if(response.data[key].status == 1){
					var id = response.data[key].sub_category_id;
                    var name = response.data[key].sub_category_name;
					if(page == 'edit_account' && id == selected_sub_category_id) {
						html += '<option value="'+selected_sub_category_id+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
				}
            }
            $('#accountSubCategory').html(html);
			if(!response.data || Object.keys(response.data).length == 0 ){
				$('#accountSubCategory').attr('disabled',true);
				$('#accountSubCategory').val('');
			} else {
				$('#accountSubCategory').attr('disabled',false);
			}
        }
    });
}

window.getExpenseSubAccounts = function(page = null, account_id, line_item_sub_account_id = '') {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"expenses/get-sub-accounts",
		data: "account_id="+account_id+"&user_id="+user_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Sub Account</option>";
            for (var key in response.data) {
				if(response.data[key].status == 1){
					var id = response.data[key].sub_account_id;
                    var name = response.data[key].sub_account_name;
					if(page == 'edit_expense' && id == line_item_sub_account_id) {
						html += '<option value="'+line_item_sub_account_id+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
				}
            }
            $('#inputSubAccount').html(html);
			if(!response.data || Object.keys(response.data).length == 0 ){
				$('#inputSubAccount').attr('disabled',true);
				$('#inputSubAccount').val('');
			} else {
				$('#inputSubAccount').attr('disabled',false);
			}
        }
    });
}

window.getExpenseTaxes = function(page = null, line_item_tax_id = '') {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-taxes",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value='' data-percentage='0'>Select Tax</option>";

            for (var key in response.data) {
                    var id = response.data[key].tax_id;
                    var name = response.data[key].tax_name;
					var tax_percentage = response.data[key].tax_percentage;
					var is_default = response.data[key].is_default;
					if(page == 'add_expense' && is_default == true) {
						html += '<option value="'+id+'" data-percentage="'+tax_percentage+'"  selected>'+name+'</option>';
						$('#taxPercentage').val(tax_percentage);
					} else if(page == 'edit_expense' && id == line_item_tax_id) {
						html += '<option value="'+line_item_tax_id+'" data-percentage="'+tax_percentage+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'" data-percentage="'+tax_percentage+'">'+name+'</option>';
					}
            }
            $('#inputTaxes').html(html);
        }
    });
}


window.getLineItems = function(){

    var slno = $('#line_item_table tbody tr').length + 1;
    var id = 'tablerow'+slno;
    var html = '';

	var cnt = slno - 1;
    html += '<tr id="'+id+'">';
        html += '<td align="center"><lable class="form-control" id="inputsl">'+slno+'</lable><input type="hidden" name="LineItems[inputSeqNo][]" class="form-control" id="inputSeqNo'+slno+'" value="'+slno+'"></td>';
        html += '<td><input type="text" name="LineItems[inputMerchant][]" class="form-control no_special_char_allow" id="inputMerchant'+slno+'" placeholder="Enter Merchant Name"></td>';
        html += '<td><input type="text" name="LineItems[inputInvoice][]" class="form-control invoice_number_char_allow" id="inputInvoice'+slno+'" placeholder="Enter Invoice Number"></td>';
        //html += '<td><input type="date" name="LineItems[inputDate][]" class="form-control no_special_char_allow" id="inputDate'+slno+'"></td>';
		html += '<td>';
			html += '<div class="input-group date" data-target-input="nearest">';
				html += '<input type="text" name="LineItems[inputDate][]" id="inputDate'+slno+'" class="form-control readonly_white" autocomplete="off" readonly=""  data-toggle="tooltip" data-placement="bottom" title data-original-title="Select date" />';
				html += '<div class="input-group-append">';
					html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
				html += '</div>';
			html += '</div>';
		html += '</td>';
        html += '<td><input type="text" name="LineItems[inputAmount][]" class="form-control no_special_char_allow allow_numeric_with_decimal text-right inputAmount" maxlength="10" id="inputAmount'+slno+'" placeholder="Enter Amount"></td>';
        html += '<td><select name="LineItems[inputAccount][]" class="form-control no_special_char_allow" id="inputAccount'+slno+'"></select></td>';
        html += '<td><select name="LineItems[inputTaxes][]" class="form-control no_special_char_allow" id="inputTaxes'+slno+'"></select></td>';
        html += '<td><input type="text" name="LineItems[inputComment][]" class="form-control no_special_char_allow" id="inputComment'+slno+'" placeholder="Comments/Notes"></td>';
        html += '<td><div class="image-upload" data-id="'+slno+'"><label for="inputFile'+slno+'" class="inputFile"><i class="fas fa-plus" data-toggle="tooltip" data-placement="bottom" title data-original-title="Add attachment"><input name="LineItems[inputFile]['+cnt+'][]" id="inputFile'+slno+'" type="file" multiple style="display: none"/></i></label></div><div id="inputFileList'+slno+'"></div></td>';
        html += '<td align="center"><button class="btn btn-danger mb-1 remove_line_item" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove line item" type="button"><i class="fas fa-minus"></i></button></td>';
    html += '</tr>'

    $('#line_item_row_table').append(html);
	var page = "add_expense";
    getExpenseAccounts(page);
    // getExpenseTaxes(page,slno);
	datepicker('inputDate');
}

window.editLineItems = function(line_item){

    var slno = $('#line_item_table tbody tr').length + 1;
    var id = 'tablerow'+slno;
    var html = '';
	var date = line_item.date.split('T');
	var invoice_date = getFormattedDate(date[0]);
	var cnt = slno - 1;

	var document_html = '';
	if(line_item.documents) {
		for(var i = 0 ; i < line_item.documents.length ;  i++) {
			document_html += '<span>';
			document_html += '<lable>'+line_item.documents[i].display_name+'</lable>';
			document_html += '<i class="align-middle mr-2 fas fa-fw fa-trash-alt remove_exp_documents" data-id="'+line_item.documents[i].document_id+'" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove this attachment"></i>';
			document_html += '</span>'
		}
	}

    html += '<tr id="'+id+'" data-id="'+line_item.line_item_id+'">';
		html += '<td align="center"><lable class="form-control" id="inputsl'+slno+'">'+slno+'</lable><input type="hidden" name="LineItems[inputSeqNo][]" class="form-control" id="inputSeqNo'+slno+'" value="'+slno+'"></td>';
        html += '<td><input type="hidden" name="LineItems[lineItemId][]" class="form-control no_special_char_allow" id="lineItemId'+slno+'" value="'+line_item.line_item_id+'"><input type="text" name="LineItems[inputMerchant][]" class="form-control no_special_char_allow" id="inputMerchant'+slno+'" placeholder="Enter Merchant Name" value="'+line_item.merchant_name+'"></td>';
        html += '<td><input type="text" name="LineItems[inputInvoice][]" class="form-control invoice_number_char_allow" id="inputInvoice'+slno+'" placeholder="Enter Invoice Number" value="'+line_item.invoice_number+'"></td>';
        //html += '<td><input type="date" name="LineItems[inputDate][]" class="form-control no_special_char_allow" id="inputDate'+slno+'" value="'+date[0]+'"></td>';
		html += '<td>';
			html += '<div class="input-group date" data-target-input="nearest">';
				html += '<input type="text" name="LineItems[inputDate][]" id="inputDate'+slno+'" class="form-control readonly_white" autocomplete="off" readonly="" value="'+invoice_date+'"  data-toggle="tooltip" data-placement="bottom" title data-original-title="Update date"/>';
				html += '<div class="input-group-append">';
					html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
				html += '</div>';
			html += '</div>';
		html += '</td>';
        html += '<td><input type="text" name="LineItems[inputAmount][]" class="form-control no_special_char_allow allow_numeric_with_decimal inputAmount text-right" maxlength="10" id="inputAmount'+slno+'" placeholder="Enter Amount" value="'+line_item.amount+'"></td>';
        html += '<td><select name="LineItems[inputAccount][]" class="form-control no_special_char_allow" id="inputAccount'+slno+'"></select></td>';
        html += '<td><select name="LineItems[inputTaxes][]" class="form-control no_special_char_allow" id="inputTaxes'+slno+'"></select></td>';
        html += '<td><input type="text" name="LineItems[inputComment][]" class="form-control no_special_char_allow" id="inputComment'+slno+'" placeholder="Comments/Notes" value="'+line_item.description+'"></td>';
        html += '<td><div class="image-upload" data-id="'+slno+'"><label for="inputFile'+slno+'" class="inputFile"><i class="fas fa-plus" data-toggle="tooltip" data-placement="bottom" title data-original-title="Add attachment"><input name="LineItems[inputFile]['+cnt+'][]" id="inputFile'+slno+'" type="file" multiple style="display: none" value="'+line_item.documents+'"/></i></label></div><div id="inputFileList'+slno+'"></div><div id="existing_doc'+slno+'">'+document_html+'</div></td>';
        html += '<td align="center"><button class="btn btn-danger mb-1 remove_line_item" type="button" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove line item"><i class="fas fa-minus"></i></button></td>';
    html += '</tr>'

	$('#line_item_row_table').append(html);
	var line_item_account_id = line_item.account_id;
	var line_item_tax_id = line_item.tax_id;
	var page = "edit_expense";
    getExpenseAccounts(page, line_item_account_id);
    // getExpenseTaxes(page, line_item_tax_id);
	datepicker('inputDate');
}

window.updateExpense = function(expense_id, type){

	var user_id = user.user_details.user_id;

	if(type == 'expense_header') {
		var form = $('#new_expense_form')[0];
		var formData = new FormData(form);
	} else if(type == 'expense_item') {
		var form = $('#expense_item_form')[0];
		var formData = new FormData(form);

		var deletedDocuments = [];
		if($('#deletedDocuments').length && ($('#deletedDocuments').val() != "" || $('#deletedDocuments').val() != null)) {
			deletedDocuments = $('#deletedDocuments').val().split(',');
		}

		for (var i = 0; i < deletedDocuments.length; i++) {
			formData.append('deleted_documents[]', deletedDocuments[i]);
		}
	} else if(type == 'expense_item_delete') {
		var formData = new FormData();

		var deletedLineItemArray = [];
		if($('#deletedLineItemId').length && ($('#deletedLineItemId').val() != "" || $('#deletedLineItemId').val() != null)) {
			deletedLineItemArray = $('#deletedLineItemId').val().split(',');
		}

		for (var i = 0; i < deletedLineItemArray.length; i++) {
			formData.append('deleted_line_items[]', deletedLineItemArray[i]);
		}
	} else if (type == 'expense_submit') {
		var formData = new FormData();
		formData.append('status',1);
	}

	formData.append('user_id', user_id);
	formData.append('expense_id', expense_id);
	formData.append('type', type);

	// scanned file
    var input = document.getElementById('fileToUpload');
	var inputFile = document.getElementById('inputFile');
    if(input.files.length > 0){
		if(input.files[0].name != inputFile.files[0].name){
			formData.append('scannedFile', input.files[0]);
		}
    }

	$.ajax({
		method : 'POST',
		url : api_url+"expenses/update-expense-by-id",
		data : formData,
		processData: false,
        contentType: false,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_expense_message(response);
        	if(response.status == "SUCCESS") {
                $('#addExpenseItemModal').modal('hide');
	        	$('.modal-backdrop').remove();
				if(type == 'expense_submit'){
					$('#main_container').load('components/expenses.html', function(){
					});
				} else {
					var expense_id = response.data.expense_id;
					var status = response.data.status;
					$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
						calculateTotal();
						$('#selected_expense_id').val(expense_id);
						if(status == 1){
							$('.send_expense').addClass('d-none');
							$('.edit_expense').addClass('d-none');
							$('.add_expense_item').addClass('d-none');
							$('.camera-upload').addClass('d-none');
						}
						setTimeout(function(){
							viewExpense(expense_id);
							getStateDropdownForSignup();
						}, 700);
					})
				}
            }
        }
	})
}

window.viewExpense = function(expense_id){
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
    var timezone = tz.name();

    openLoadingDialog($('#line_item_container'));
	$.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-by-id",
		data : 'expense_id='+expense_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			//console.log(response.data.line_items);
			if(response.status == "SUCCESS") {

				if(response.data.status == 'Submitted'){
					$('.send_expense').addClass('d-none');
					$('.edit_expense').addClass('d-none');
					$('.add_expense_item').addClass('d-none');
					$('.camera-upload').addClass('d-none');
				}

				var missing_items = '';
				$('#expense_header_detail').find('select').attr('disabled', true);
				$('#expense_header_detail').find('input').attr('disabled', true);
				var status_label = '';
				if(response.data.status == 'Draft') {
					status_label = '<small class="fs-16 red dark"> ('+response.data.status+') </small>';
				} else if(response.data.status == 'Submitted') {
					status_label = '<small class="fs-16 green dark"> ('+response.data.status+') </small>';
				} else {
					status_label = '<small class="fs-16 dark"> ('+response.data.status+') </small>';
				}
				$('#expense_report_name').html('<h2>'+response.data.expense_name+status_label+'</h2>');
				if(response.data.property_id && response.data.property_id != 0 && response.data.property_id != null){
					$('#inputProperty').val(response.data.property_id);
				} else {
					$('#inputProperty').val('');
					missing_items += '<li> Property </li>';
				}

				if(response.data.policy_id && response.data.policy_id != 0 && response.data.policy_id != null){
					$('#inputPolicy').val(response.data.policy_id);
				} else {
					missing_items += '<li> Policy </li>';
				}

				if(response.data.vendor_name && response.data.vendor_name != '' && response.data.vendor_name != null){
					$('#inputVendor').val(response.data.vendor_name);
				} else {
					missing_items += '<li> Vendor </li>';
				}

				if(response.data.expense_name && response.data.expense_name != '' && response.data.expense_name != null){
					$('#expenseName').val(response.data.expense_name);
				} else {
					missing_items += '<li> Expense Name </li>';
				}

				if(response.data.sub_total && response.data.sub_total != null){
					var f_sub_total = response.data.sub_total.replace(/,/g, '');
					$('#subTotalAmountInput').val(f_sub_total);
					$('#expenseSubTotalAmount').html(currency_sign+' '+response.data.sub_total);
				} else {
					if(response.data.total && response.data.total != null){
						var f_sub_total = response.data.total.replace(/,/g, '');
						$('#subTotalAmountInput').val(f_sub_total);
						$('#expenseSubTotalAmount').html(currency_sign+' '+response.data.total);
					}
				}

				if(response.data.tax_amount && response.data.tax_amount != null){
					var f_tax_amount = response.data.tax_amount.replace(/,/g, '');
					$('#taxAmountInput').val(f_tax_amount);
					$('#expenseTaxAmount').html(currency_sign+' '+response.data.tax_amount);
				}

				if(response.data.total && response.data.total != null){
					var f_total = response.data.total.replace(/,/g, '');
					$('#totalAmountInput').val(f_total);
					$('#expenseTotalAmount').html(currency_sign+' '+response.data.total);
				}

				var line_items = [];
				var html = '';

				if(response.data.line_items.length > 0) {
					for (var key in response.data.line_items) {
	                    if (response.data.line_items.hasOwnProperty(key)) {
							var line_item = response.data.line_items[key];
							//editLineItems(line_item);
							var line_item_id = line_item.line_item_id;
							var index = parseInt(key) + 1;

							if(line_item.merchant_name && line_item.merchant_name != null && line_item.merchant_name != undefined){
								var merchant_name = line_item.merchant_name;
							} else {
								var merchant_name = '-';
								missing_items += '<li> Merchant Name of line_item: '+index+' is missing </li>';
							}

							if(line_item.invoice_number && line_item.invoice_number != null && line_item.invoice_number != undefined){
								var invoice_number = line_item.invoice_number;
							} else {
								var invoice_number = '-';
								missing_items += '<li> Invoice Number of line_item: '+index+' is missing </li>';
							}

							if(!line_item.date){
								missing_items += '<li> Invoice Date of line_item: '+index+' is missing </li>';
							}

							if(line_item.account && line_item.account.length > 0){
								var account_name = line_item.account.account_name;
							}

							if(!line_item.account) {
								missing_items += '<li> Category of line_item: '+index+' is missing </li>';
							}

							if(line_item.total && line_item.total != null){
								var amount = line_item.total;
							} else {
								var amount = 0.00;
								missing_items += '<li> Amount of line_item: '+index+' is missing </li>';
							}

							html += '<div id="line_item_'+line_item_id+'" class="row pointer border-bottom border-top p-2 edit_line_item" data-id="'+line_item_id+'" data-status="'+response.data.status+'">';
								html += '<div class="col-6 col-md-6 text-left"><span class="bold-black">Vendor</span><br>'+merchant_name+'</div>';
								html += '<div class="col-6 col-md-2 text-left"><span class="bold-black">Inv. Number</span><br>'+invoice_number+'</div>';
								html += '<div class="col-6 col-md-3 text-right"><span class="bold-black">Amount</span><br><span class="bold-black fs-18">$ '+amount+'</span></div>';
								var line_item_icon = '';
								if(line_item.documents.length != 0){
									line_item_icon += '<i class="fas fa-fw fa-paperclip mt-3 mr-4 pr-2"></i>';
								}
								if(response.data.status != 'Submitted' && (localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1)){
									line_item_icon += '<i class="mt-2 mr-2 fas fa-fw fa-trash delete_line_item" data-id="'+line_item_id+'"></i>';
								}
								html += '<div class="col-6 col-md-1 text-center">'+line_item_icon+'</div>';
							html += '</div>';
						}
					}
				} else {
					missing_items += '<li> line_items </li>';
					$('.send_expense').attr('disabled',true);
					html += '<div class="center">';
                        html += '<img src="icon/expense.svg" class="filter-8" width="50"><br>';
                        html += '<small>Add your receipt details here by clicking <a href="javascript:void(0);" class="add_expense_item">Add expense</a></small>';
                    html += '</div>';
				}

				$('#line_item_container').html(html);
			}

			var missingItemHtml = '';
			if(missing_items != '') {
				missingItemHtml = '<ul>'+missing_items+'</ul>';
			}

			$('#missingItems').html(missingItemHtml);
		}
	})
}

window.viewExpenseDocument = function(document_id,display_name){
	$.ajax({
		method : 'POST',
		url : api_url+"expenses/view-document",
		data : 'document_id='+document_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			if(response.status == "SUCCESS") {
				var file_url = response.data.file_url;
				var file_ext = response.data.document_data.document_ext;
				var dataURI =  response.data.data_uri;

				var html = '';
				if(file_ext == "pdf"){
					html += '<iframe src="'+file_url+'" width="100%" height="500px" frameborder="0"></iframe>';
				} else {
					html += '<img src="'+file_url+'" width="auto" max-width="100%" max-height="70vh"></img>';
				}

				$('#expense_document_media').html(html);
				var download = '<a download="'+display_name+'" href="'+dataURI+'"><button type="button" class="btn btn-orange m-2" data-toggle="tooltip" data-placement="bottom" title data-original-title="Download">Download</button></a>';
				$('#expense_image_download').html(download);
			} else {
				display_expense_message(response);
			}
		}
	});
}

window.getLineItemDetail = function(expense_id, line_item_id){
	var user = JSON.parse(localStorage.getItem('user'));
    $.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-by-id",
		data : 'expense_id='+expense_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			if(response.status == "SUCCESS") {
				var line_items = [];
				for (var key in response.data.line_items) {
                    if (response.data.line_items.hasOwnProperty(key)) {
						var line_item = response.data.line_items[key];
						if(line_item.line_item_id == line_item_id) {

							if(line_item.total && line_item.total != null){
								$('#inputAmount').val(currencyFormat(Number(line_item.total.replace(/[^0-9\.]/g, ''))));
							}

							// $('#expenseAmount').val(currencyFormat(sub_total));
							$('#inputMerchant').val(line_item.merchant_name);
							$('#inputInvoice').val(line_item.invoice_number);

							if(line_item.date && line_item.date != null){
								var invoice_date = getFormattedDate(line_item.date);
							} else {
								var invoice_date = '';
							}

							$('#inputDate').val(invoice_date);
							$('#inputComment').val(line_item.description);

							var line_item_account_id = line_item.account_id;
							if(line_item.tax_id){
								var line_item_tax_id = line_item.tax_id.replace(/^\s+|\s+$/gm,'');
							} else {
								var line_item_tax_id = '';
							}

							if(line_item.sub_account_id){
								var line_item_sub_account_id = line_item.sub_account_id.replace(/^\s+|\s+$/gm,'');
							} else {
								var line_item_sub_account_id = '';
							}

							var page = "edit_expense";
						    getExpenseAccounts(page,line_item_account_id);
							// $('#inputSubCategory').attr('disabled',false);
							getExpenseSubAccounts(page,line_item_account_id,line_item_sub_account_id);
						    // getExpenseTaxes(page, line_item_tax_id);
							datepicker('inputDate');

							if(line_item.documents){
								if(Object.keys(line_item.documents).length > 0) {
									var document_html = '';
									document_html += '<div class="row fs-16 bold-black mb-3">Uploaded Receipt(s)</div>';
									for(var i = 0 ; i < line_item.documents.length ;  i++) {
										document_html += '<div id="doc_'+line_item.documents[i].document_id+'" class="row border-bottom border-top p-2">';
											document_html += '<div class="col-auto col-md-6 text-left">'+line_item.documents[i].display_name+'</div>';
											document_html += '<div class="col-auto col-md-6 text-right"><i class="fas fa-eye mr-3 view_expense_document" data-id="'+line_item.documents[i].document_id+'" data-value="'+line_item.documents[i].display_name+'" data-toggle="tooltip" data-placement="bottom" title data-original-title="view this attachment"></i><i class="align-middle mr-2 fas fa-fw fa-trash-alt remove_exp_documents" data-id="'+line_item.documents[i].document_id+'" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove this attachment"></i></div>';
										document_html += '</div>';
									}
									$('#existing_doc').html(document_html);
								}
							}

							if(response.data.status == 'Submitted' || (localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1)){
								$('.update_expense').addClass('d-none');
								$('.attachment_expenses').addClass('d-none');
								setTimeout(function(){
									$('#expense_item_form input').attr('disabled', true);
									$('#expense_item_form select').attr('disabled', true);
									$('#inputDate').css('background', '#e5eaef');
								}, 1000);
							}
						}
					}
				}
			}
		}
	})
}

window.deleteExpenseLineItem = function(line_item_id) {
	if(line_item_id != null){
		var delimeter = '';
		if($('#deletedLineItemId').val() != "") { delimeter = ','; }
		var deletedDocumentId = $('#deletedLineItemId').val($('#deletedLineItemId').val()+delimeter+line_item_id);

		var expense_id = $('#selected_expense_id').val();
		var type = 'expense_item_delete';
		if(updateExpense(expense_id, type)) {
			$('#line_item_'+line_item_id).remove();
		}
	}
}

window.getExpenses = function() {
    var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
    var timezone = tz.name();
	var user_id = user.user_details.user_id;
    //var data = '';
    //data = 'user_timezone='+timezone+'&user_service_name='+user_service_name;

    $.ajax({
        method: 'POST',
        url: api_url+"expenses/get-expenses",
		data : 'user_id='+user_id+'&user_service_name='+user_service_name,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.status == "SUCCESS") {
                var html = '';
                var i = 1;
                for (var key in response.data) {
                    if (response.data.hasOwnProperty(key)) {
						var status_label = response.data[key].status_label;
                    	var expense_id = response.data[key].expense_id;
                        var property_name = response.data[key].property_name;
                        var expense_name = response.data[key].expense_name;
						var ct = new Date(response.data[key].created_time);
						var created_time = new Date(ct.getFullYear(),ct.getMonth(),ct.getDate(),ct.getHours(),ct.getMinutes(),ct.getSeconds())
						var date = created_time.toDateString();
						var time = created_time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
						//created_time = moment(created_time).format('ddd MMM DD YYYY hh:mm:ss');

						if(response.data[key].policy){
							var policy_name = response.data[key].policy.policy_name;
						} else {
							var policy_name = '';
						}

                        var total = response.data[key].total;
						if(response.data[key].vendor_name) {
							var vendor_name = response.data[key].vendor_name;
						} else {
							var vendor_name = '';
						}

						var total_line_item = response.data[key].line_items.length;
						var str = '<small>('+total_line_item+' expense)</small>';
						if(total_line_item > 1) {
							str = '<small>('+total_line_item+' expenses)</small>';
						}
						if(response.data[key].status == 0) {
							status_label = '<small class="fs-16 red dark">'+status_label+'</small>';
						} else if(response.data[key].status == 1) {
							status_label = '<small class="fs-16 green dark">'+status_label+'</small>';
						} else {
							status_label = '<small class="fs-16 dark">'+status_label+'</small>';
						}
						html += '<tr class="view_expense pointer" data-id="'+response.data[key].expense_id+'" data-status="'+response.data[key].status_label+'" data-length="'+total_line_item+'">';
							html += '<td>';

								html += '<div class="row card">';
					                html += '<div class="row justify-content-center">';
					                    html += '<div class="col-12 col-md-6 col-lg-6">';
					                        html += '<div class="card-body text-left"><span class="bold-black fs-18">'+expense_name+'</span><br><small>ID: '+expense_id+'</small><br><small>'+date+' '+time+'</small><br><small>'+policy_name+'</small>&nbsp;'+str+'&nbsp;<br>'+status_label+'</div>';
					                    html += '</div>';
					                    html += '<div class="col-12 col-md-6 col-lg-6">';
					                        html += '<div class="card-body expense_total text-right bold-black fs-22">$ '+total;

					                        // if(response.data[key].status == 0) {
												html += '<i class="align-middle mr-2 fas fa-fw fa-trash fs-16 delete_expense" data-id="'+expense_id+'"></i>';
											// }

					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					            html += '</div>';
							html += '</td>';
						html += '</tr>'

						i++;
                    }
                }

                $('#expenses_table_row').html(html);

                var expenses_table = $("#expenses-datatable").DataTable({
					ordering: false
                });

                $("#expenses-datatable tbody tr td:first").addClass('border-top');

                getFloatingActionButton('Expenses','#expensesDataContainer');
            }
        }
    });

}

window.arrangeslno = function(tableID){
	var i = 1;
	$(`#${tableID} tbody tr`).each(function(){
		$(this).find('lable[id^="inputsl"]').html(i);
		$(this).find('lable[id^="inputsl"]').val(i);
		$(this).find('input[name^="LineItems[inputSeqNo]"]').val(i);

		$(this).attr('id', 'tablerow'+i);
		$(this).find('input[name^="LineItems[inputSeqNo]"]').attr('id', 'inputSeqNo'+i);
		$(this).find('input[name^="LineItems[inputMerchant]"]').attr('id', 'inputMerchant'+i);
		$(this).find('input[name^="LineItems[inputInvoice]"]').attr('id', 'inputInvoice'+i);
		$(this).find('input[name^="LineItems[inputDate]"]').attr('id', 'inputDate'+i);
		$(this).find('input[name^="LineItems[inputAmount]"]').attr('id', 'inputAmount'+i);
		$(this).find('select[name^="LineItems[inputAccount]"]').attr('id', 'inputAccount'+i);
		$(this).find('select[name^="LineItems[inputTaxes]"]').attr('id', 'inputTaxes'+i);
		$(this).find('input[name^="LineItems[inputComment]"]').attr('id', 'inputComment'+i);

		var cnt = i - 1;
		$(this).find('input[name^="LineItems[inputFile]"]').attr('id', 'inputFile'+i);
		$(this).find('input[name^="LineItems[inputFile]"]').attr('name', 'LineItems[inputFile]['+cnt+'][]');
		$(this).find('div[id^="existing_doc"]').attr('id', 'existing_doc'+i);
		$(this).find('.image-upload').attr('data-id', i);
		$(this).find('label.inputFile').attr('for', 'inputFile'+i);
		$(this).find('div[id^="inputFileList"]').attr('id', 'inputFileList'+i);
		i++;
	})
}

var $validateVendor;
window.addVendorValidation = function() {
	$validateVendor = $("#new_vendor_form,#edit_vendor_form")
	$validateVendor.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "vendorName": { required: true },
	    },
	    messages: {
	        "vendorName": { required: '' },
	    }
	});
}

var $validateExpense;
window.addExpenseValidation = function() {
	$validateExpense = $("#new_expense_form")
	$validateExpense.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "inputProperty": { required: true },
	        "inputPolicy": { required: true },
			"expenseName": { required: true},
			"inputVendor": { required: true }
	    },
	    messages: {
	        "inputProperty": { required: '' },
	        "inputPolicy": { required: '' },
			"expenseName": { required: ''},
			"inputVendor": { required: '' }
	    }
	});
}

var $validateMarkAsPaid;
window.addMarkAsPaidValidation = function() {
	$validateMarkAsPaid = $('#rent-collection-wizard, #undo_delete_ps_form')
	$validateMarkAsPaid.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "paid_on_date": { required: true },
	        "inquiry_textarea": { required: true }
	    },
	    messages: {
	        "paid_on_date": { required: 'Please Select The Date' },
	        "inquiry_textarea": { required: 'Please Enter Any Remark' }
	    }
	});
}

var $validatePaymentDueDate;
window.paymentDueDateValidation = function() {
	$validatePaymentDueDate = $('#payment-due-date-collection-wizard')
	$validatePaymentDueDate.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "ps_due_date": { required: true },
	        "inquiry_textarea": { required: true }
	    },
	    messages: {
	        "ps_due_date": { required: 'Please Select The Date' },
	        "inquiry_textarea": { required: 'Please Enter Any Remark' }
	    }
	});
}

// var $validateEvictionDocument;
// window.uploadEvictionDocumentValidation = function() {
// 	$validateEvictionDocument = $('#upload-eviction-contract')
// 	$validateEvictionDocument.validate({
// 	    errorPlacement: function errorPlacement(error, element) {
// 	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
// 	    },
// 	    highlight: function(element) {
// 	        $(element).addClass("is-invalid");
// 	    },
// 	    unhighlight: function(element) {
// 	        $(element).removeClass("is-invalid");
// 	    },
// 	    rules: {
// 	        "inquiry_textarea": { required: true }
// 	    },
// 	    messages: {
// 	        "inquiry_textarea": { required: 'Please Enter Any Remark' }
// 	    }
// 	});
// }

var $validateAccount;
window.addAccountValidation = function() {
	$validateAccount = $("#new_account_form, #edit_account_form")
	$validateAccount.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "accountName": { required: true },
	        "accountDescription": { required: true },
			"isDefault": { required: true},
			"accountCategory": {required: true},
			"accountSubCategory": {required: true},
	    },
	    messages: {
	        "accountName": { required: '' },
	        "accountDescription": { required: '' },
			"isDefault": { required: ''},
			"accountCategory": {required: ''},
			"accountSubCategory": {required: ''},
	    }
	});
}

var $validateSubAccount;
window.addSubAccountValidation = function() {
	$validateSubAccount = $("#new_sub_account_form, #edit_sub_account_form")
	$validateSubAccount.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "subAccountName": { required: true },
	        "subAccountDescription": { required: true },
	    },
	    messages: {
	        "subAccountName": { required: '' },
	        "subAccountDescription": { required: '' },
	    }
	});
}

var $validateExpenseItem;
window.addExpenseItemValidation = function() {
	$validateExpenseItem = $("#expense_item_form")
	$validateExpenseItem.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "inputMerchant": { required: true },
			"inputInvoice": { required: true },
			"inputDate": { required: true },
			"inputAmount": { required: true },
	        "inputAccount": { required: true },
	        // "inputTaxes": { required: true },
			"inputSubAccount": {
				required:function(element){
					return $('#inputSubAccount option').length > 1;
				}
			}
	    },
	    messages: {
	        "inputMerchant": { required: '' },
			"inputInvoice": { required: '' },
			"inputDate": { required: '' },
			"inputAmount": { required: '' },
	        "inputAccount": { required: '' },
	        // "inputTaxes": { required: '' },
			"inputSubAccount": {required:''}
	    }
	});
}

var $validateManualTransactions;
window.addManualTranValidation = function() {
	$validateManualTransactions = $("#manual_transaction_form")
	$validateManualTransactions.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "transaction_property": { required: true },
			"transaction_tenant": { required: true },
			"transaction_payment": { required: true },
	        "manual_transaction_amount": { required: true },
			"transaction_rent_month": { required: true },
	    },
	    messages: {
	        "transaction_property": { required: '' },
			"transaction_tenant": { required: '' },
			"transaction_payment": { required: '' },
	        "manual_transaction_amount": { required: '' },
			"transaction_rent_month": { required: '' },
	    }
	});
}

window.saveExpenses = function(){
	var user_id = user.user_details.user_id;
    var form = $('#new_expense_form')[0];
    var formData = new FormData(form);
	formData.append('user_id', user_id);
	formData.append('status', 0);

	$.ajax({
		method : 'POST',
		url : api_url+"expenses/add-expense",
		data : formData,
		processData: false,
	    contentType: false,
	    cache: false,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_expense_message(response);
        	if(response.status == "SUCCESS") {
        		$('#addExpenseModal').modal('hide');
	        	$('.modal-backdrop').remove();
                var expense_id = response.data.expense_id;
				var status = response.data.status;
				$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
					$('#selected_expense_id').val(expense_id);
					if(status == 1){
						$('.send_expense').addClass('d-none');
						$('.edit_expense').addClass('d-none');
						$('.add_expense_item').addClass('d-none');
						$('.camera-upload').addClass('d-none');
					}
					setTimeout(function(){
						viewExpense(expense_id);
						getStateDropdownForSignup();
					}, 700);
				})
            }
        }
	})
}

window.deleteExpense = function(expense_id) {
	var user_id = user.user_details.user_id;
	if(expense_id != null){
		$.ajax({
			method : 'POST',
			url : api_url+"expenses/delete-expense",
			data : 'expense_id='+expense_id+'&user_id='+user_id,
			beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
				display_expense_message(response);
	        	if(response.status == "SUCCESS") {
	        		var expense_id = response.data.expense_id;
					$('#main_container').load('components/expenses.html');
	            }
	        }
		})
	}
}

window.saveScannedExpense = function(response) {
	var user_id = user.user_details.user_id;
	var expObj = {};
	expObj.submitted_by = user_id;
	expObj.status = 0;
	expObj.expense_name = response.data.summaryFields['VENDOR_NAME'];
	if(response.data.summaryFields['SUBTOTAL']){
		expObj.sub_total = Number(response.data.summaryFields['SUBTOTAL'].replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
	} else {
		expObj.sub_total = 0;
	}

	if(response.data.summaryFields['TOTAL']) {
		var total = Number(response.data.summaryFields['TOTAL'].replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
		total = Math.round((total + Number.EPSILON) * 100) / 100;
	} else {
		var total = 0;
	}

	if(response.data.summaryFields['TAX']){
		var taxAmount = Number(response.data.summaryFields['TAX'].replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
		taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
		var subTotal = total - taxAmount;
		subTotal = Math.round((subTotal + Number.EPSILON) * 100) / 100;
		var taxPercentage = taxAmount/subTotal * 100;
		taxPercentage = Math.round((taxPercentage + Number.EPSILON) * 100) / 100;
	} else {
		var taxAmount = 0;
		var sub_total = 0;
		var taxPercentage = 0;
	}

	expObj.total = total;
	var line_items = [];
	if(response.data.summaryFields['INVOICE_RECEIPT_DATE']){
		var date = response.data.summaryFields['INVOICE_RECEIPT_DATE'];
	} else {
		var date = '';
	}

	if(response.data.summaryFields['INVOICE_RECEIPT_ID']) {
		var invoice_number = response.data.summaryFields['INVOICE_RECEIPT_ID'];
	} else {
		var invoice_number = '';
	}

	for (var key in response.data.lineItems) {
		if (response.data.lineItems.hasOwnProperty(key)) {
			var lineData = response.data.lineItems[key];
			lineData.PRICE = Number(lineData.PRICE.replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
			lineData.PRICE = Math.round((lineData.PRICE + Number.EPSILON) * 100) / 100;
			if(taxAmount == 0 && sub_total == 0 && taxPercentage == 0){
				var tax_amount = 0;
				var amount = lineData.PRICE;
				var sub_total_line_item = amount;
			} else {
				var tax_amount = lineData.PRICE * ( taxPercentage / 100 );
				tax_amount = Math.round((tax_amount + Number.EPSILON) * 100) / 100;
				var amount = lineData.PRICE * (1 + (taxPercentage/100));
				amount = Math.round((amount + Number.EPSILON) * 100) / 100;
				var sub_total_line_item = amount - tax_amount;
				sub_total_line_item = Math.round((sub_total_line_item + Number.EPSILON) * 100) / 100;
			}

			if(invoice_number == '' && lineData.PRODUCT_CODE){
				invoice_number = lineData.PRODUCT_CODE;
			}
			line_items[key] = {
				"merchant_name" : response.data.summaryFields['VENDOR_NAME'],
				"total" : amount,
				"sub_total": sub_total_line_item,
				"description" : lineData.EXPENSE_ROW,
				"date" : date,
				"invoice_number" : invoice_number,
				"tax_percentage" : taxPercentage,
				"tax_amount" : tax_amount
			};
		}
	}

	expObj.line_items = line_items;

	//console.log(JSON.stringify(expObj)); return false;
	$.ajax({
		url: api_url+"expenses/add-scanned-expense",
		data: "data="+JSON.stringify(expObj),
		type: "POST",
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(result) {
			var expense_id = result.data.expense_id;
			var status = result.data.status;
			$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
				$('#selected_expense_id').val(expense_id);
				if(status == 1){
					$('.send_expense').addClass('d-none');
					$('.edit_expense').addClass('d-none');
					$('.add_expense_item').addClass('d-none');
					$('.camera-upload').addClass('d-none');
				}
				setTimeout(function(){
					viewExpense(expense_id);
					getStateDropdownForSignup();
				}, 700);
			})
		}
	})
}

window.createScannedExpense = function(response) {

	var line_items = [];
	if(response.data.summaryFields['INVOICE_RECEIPT_DATE']){
		var date = new Date(response.data.summaryFields['INVOICE_RECEIPT_DATE']);
		if(date == 'Invalid Date'){
			date = '';
		}
		else{
			date = getFormattedDate(date);
		}
	} else {
		var date = '';
	}

	if(response.data.summaryFields['INVOICE_RECEIPT_ID']) {
		var invoice_number = response.data.summaryFields['INVOICE_RECEIPT_ID'];
	} else {
		var invoice_number = '';
	}

	var total = currencyFormat(Number(response.data.summaryFields['TOTAL'].replace(/[^0-9\.]/g, '')));

	line_items = {
		"merchant_name" : response.data.summaryFields['VENDOR_NAME'],
		"total" : total,
		"invoice_number" : invoice_number,
		"date" : date,
	};

	return line_items;

}

window.showExpenseItems = function(id){
	var user_id = user.user_details.user_id;
	//var user_id = 219;
    $.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-items",
		data : 'user_id='+user_id+'&sub_account_id='+id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_expense_message(response);
        	if(response.response_data.success == true) {
				var html = '';
				if(Object.keys(response.data).length > 0){
					for (var pkey in response.data) {
						if(pkey != 'total'){
							var p = pkey.split("-");
							html += '<tr>';
								html += '<td>';
									html += '<div class="card row mb-0">';
										html += '<h5>'+p[1]+'</h5>';
										html += '<div class="mt-2">';
											html += '<div id="show_line_item_container" class="col-auto col-md-12">';
												for(var lkey in response.data[pkey]){

													var line_item_id = response.data[pkey][lkey]['line_item_id'];
													var merchant_name = response.data[pkey][lkey]['merchant_name'];
													var invoice_number = response.data[pkey][lkey]['invoice_number'];
													var amount = currencyFormat(Number(response.data[pkey][lkey]['total']));

													html += '<div id="line_item_'+line_item_id+'" class="row pointer border-bottom border-top p-2" data-id="'+line_item_id+'">';
														html += '<div class="col-6 col-md-6 text-left"><span class="bold-black">Merchant</span><br>'+merchant_name+'</div>';
														html += '<div class="col-6 col-md-3 text-left"><span class="bold-black">Inv. Number</span><br>'+invoice_number+'</div>';
														html += '<div class="col-6 col-md-3 text-right"><span class="bold-black">Amount</span><br><span class="bold-black fs-18">$ '+amount+'</span></div>';
													html += '</div>';
												}
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</td>';
							html += '</tr>';
						}
					}
					var total = 'Total = $ '+currencyFormat(Number(response.data['total']));
					$('.category_total_amount').html(total);
					$('#show_expenses_media').html(html);
					$("#expenses-item-datatable").DataTable({
						paging: false,
						info: false
					});
					$("#expenses-item-datatable tbody tr td").css('border', 'none');
				} else {
					html += '<h3 class="text-center mt-3"> No Data Available </h3>';
					$('#show_expenses_media').html(html);
				}
			}
		}
	})
}

window.getExpenseDetailedReport = function() {
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-reports",
		data : 'user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			var html = '';
			if(response.response_data.success == true){
				html += '<table id="expense-report-datatable" class="table table-striped table-bordered" cellspacing="10" cellpadding="10" style="width: 100%;">';
                    html += '<thead>';
                        html += '<tr>';
                            html += '<th class="fs-14 text-center" rowspan="2">Account Type</th>';
                            html += '<th class="fs-14 text-center" rowspan="2">Categories</th>';
                            html += '<th class="fs-14 text-center" rowspan="2">Accounts</th>';
							html += '<th class="fs-14 text-center" rowspan="2">Sub Accounts</th>';
							html += '<th class="fs-14 text-center" colspan="2">Total</th>';
                            for (var key in response.data) {
                            	var p = key.split("-");
								// html += '<th class="fs-14"></th>';
                            	html += '<th class="fs-14 text-center" colspan="2">'+p[1]+'</th>';
                            }
                        html += '</tr>';
						html += '<tr>';
								html += '<th class="fs-10 text-center">Sub Total</th>';
								html += '<th class="fs-10 text-center">Total</th>';
							for (var key in response.data) {
								var p = key.split("-");
								// html += '<th class="fs-14"></th>';
								html += '<th class="fs-10 text-center">Sub Total</th>';
								html += '<th class="fs-10 text-center">Total</th>';
							}
						html += '</tr>';
                    html += '</thead>';

					html += '<tbody>';

						var data_no = 0;
						var acc_cnt = 0;
						for (var key in response.data) {
							for(var ckey in response.data[key]){
								if(ckey != 'property_total'){
									html += '<tr class="">';		//Category Row
									html += '<td class="fs-14 bold-black">'+ckey+'</td>';
									html += '<td></td>';
									html += '<td></td>';
									html += '<td></td>';
									html += '<td></td>';
									html += '<td></td>';
									for (var key in response.data) {
										html += '<td></td>';
										html += '<td></td>';
									}
									html += '</tr>';
									for(var jkey in response.data[key][ckey]){
										if(jkey != 'category_total'){
											html += '<tr class="sub_category_row pointer" data-no="'+data_no+'">';		//Sub Category Row
												html += '<td class="fs-14 lightgrey">'+ckey+'</td>';
												html += '<td class="fs-14 bold-black">'+jkey+'</td>';
												html += '<td></td>';
												html += '<td></td>';
												html += '<td></td>';
												html += '<td></td>';
												for (var key in response.data) {
													html += '<td></td>';
													html += '<td></td>';
												}
											html += '</tr>';

											for(var lkey in response.data[key][ckey][jkey]){
												if(lkey != 'sub_category_id' && lkey != 'sub_category_total'){
													html += '<tr class="account_row sub_cat_data_number'+data_no+' sub_cat_calc'+data_no+' pointer" data-no="'+acc_cnt+'">';		//Account Row
														html += '<td class="fs-14 lightgrey">'+ckey+'</td>';
														html += '<td class="fs-14 lightgrey">'+jkey+'</td>';
														html += '<td class="fs-14">'+lkey+'</td>';
														html += '<td></td>';
														html += '<td></td>';
														html += '<td class="text-right fs-14" id="acct_total'+acc_cnt+'"></td>';
														for (var key in response.data) {
															html += '<td></td>';
															if(Number(response.data[key][ckey][jkey][lkey]['account_total']) == 0) {
																html += '<td class="fs-14 text-right account'+acc_cnt+'" data-type="'+ckey+'" data-val="'+Number(response.data[key][ckey][jkey][lkey]['account_total'])+'">-</td>';
															} else if(ckey == 'Income' && Number(response.data[key][ckey][jkey][lkey]['account_total']) > 0){
																html += '<td class="fs-14 text-right account'+acc_cnt+'" data-type="'+ckey+'" data-val="'+Number(response.data[key][ckey][jkey][lkey]['account_total'])+'">'+currency_sign+' '+currencyFormat(Number(response.data[key][ckey][jkey][lkey]['account_total']))+'</td>';
															} else {
																html += '<td class="fs-14 text-right account'+acc_cnt+' red" data-type="'+ckey+'" data-val="'+Number(response.data[key][ckey][jkey][lkey]['account_total'])+'">( '+currency_sign+' '+currencyFormat(Number(response.data[key][ckey][jkey][lkey]['account_total']))+' )</td>';
															}
														}
													html += '</tr>';
													if(response.data[key][ckey][jkey][lkey]['sub_account']){
														var temp = 0;
														for(var mkey in response.data[key][ckey][jkey][lkey]['sub_account']){
															var className = '';
															if(ckey != 'Income'){
																className = 'open_expense_item';
															}
															html += '<tr data-account-type="'+ckey+'" class="'+className+' sub_account_row sub_cat_data_number'+data_no+' acc_data_number'+acc_cnt+' calc_sub pointer" data-title="'+lkey+' - '+mkey+'" data-id="'+response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_id']+'" data-cnt="'+acc_cnt+temp+'">';		//Sub Account Row
																html += '<td class="fs-14 lightgrey">'+ckey+'</td>';
																html += '<td class="fs-14 lightgrey">'+jkey+'</td>';
																html += '<td class="fs-14 lightgrey">'+lkey+'</td>';
																html += '<td class="fs-14">'+mkey+'</td>';
																html += '<td class="text-right fs-14" id="sub_acct_total'+acc_cnt+temp+'"></td>';
																html += '<td></td>';
																for (var key in response.data) {
																	if(Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total']) == 0) {
																		html += '<td class="fs-14 text-right sub_calc'+acc_cnt+temp+'" data-val="'+Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total'])+'">-</td>';
																	} else if(ckey == 'Income' && Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total']) > 0){
																		html += '<td class="fs-14 text-right sub_calc'+acc_cnt+temp+'" data-val="'+Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total'])+'">'+currency_sign+' '+currencyFormat(Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total']))+'</td>';
																	} else {
																		html += '<td class="fs-14 text-right sub_calc'+acc_cnt+temp+' red" data-val="'+Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total'])+'">( '+currency_sign+' '+currencyFormat(Number(response.data[key][ckey][jkey][lkey]['sub_account'][mkey]['sub_account_total']))+' )</td>';
																	}
																	html += '<td></td>';
																}
															html += '</tr>';
															temp++;
														}
													}
													acc_cnt++;
												}
											}

											html += '<tr class="">';		//Sub Category Total Row
												html += '<td class="fs-14 lightgrey">'+ckey+'</td>';
												html += '<td class="fs-14 bold-black">'+jkey+' Total</td>';
												html += '<td></td>';
												html += '<td></td>';
												html += '<td></td>';
												html += '<td class="bold-black text-right fs-14" id="sub_cat_total'+data_no+'"></td>';
												for (var key in response.data) {
													html += '<td></td>';
													if(ckey == 'Income'){
														html += '<td class="fs-14 text-right bold-black sub_cat_total'+data_no+'" data-type="'+ckey+'" data-val="'+Number(response.data[key][ckey][jkey]['sub_category_total'])+'">'+currency_sign+' '+currencyFormat(Number(response.data[key][ckey][jkey]['sub_category_total']))+'</td>';
													} else {
														html += '<td class="fs-14 text-right bold-black red sub_cat_total'+data_no+'" data-type="'+ckey+'" data-val="'+Number(response.data[key][ckey][jkey]['sub_category_total'])+'">( '+currency_sign+' '+currencyFormat(Number(response.data[key][ckey][jkey]['sub_category_total']))+' )</td>';
													}
												}
											html += '</tr>';
											data_no++;
										}
									}
								}
							}
							break;
                        }

					html += '</tbdoy>';

					html += '<tfoot>';
                    	html += '<tr>';		//Footer Row
                            html += '<td class="bold-black" id="tax_income">Taxable Income</td>';
							html += '<td></td>';
							html += '<td></td>';
							html += '<td></td>';
							html += '<td></td>';
							html += '<td class="bold-black text-right" id="tax_total"></td>';
                            for (var key in response.data) {
								html += '<td></td>';
								if(Number(response.data[key]['property_total']) >= 0){
									html += '<td class="bold-black text-right tax_total" data-val="'+response.data[key]['property_total']+'">'+currency_sign+' '+currencyFormat(Number(response.data[key]['property_total']))+'</td>';
								} else {
									var tot_amt = -(response.data[key]['property_total']);
									html += '<td class="bold-black red text-right tax_total" data-val="'+response.data[key]['property_total']+'">( '+currency_sign+' '+currencyFormat(Number(tot_amt))+' )</td>';
								}
                            }
                        html += '</tr>';
                    html += '</tfoot>';

				html += '</table>';
				$('#expense_report_data_container').html(html);
				var tax_sum = 0;
				$('.tax_total').each(function(){
					tax_sum = tax_sum + Number($(this).attr('data-val'));
				});
				if(tax_sum >= 0){
					$('#tax_total').html(currency_sign+' '+currencyFormat(Number(tax_sum)));
					$('#tax_income').html("Taxable Income (Profit)");
				} else {
					tax_sum = -(tax_sum);
					$('#tax_total').html('( '+currency_sign+' '+currencyFormat(Number(tax_sum))+' )');
					$('#tax_total').addClass('red');
					$('#tax_income').html("Taxable Income (Loss)");
				}

				$('.sub_category_row').each(function(){
					var data_no = $(this).attr('data-no');
					var sub_cat_sum = 0;

					$('.sub_cat_total'+data_no).each(function(){
						sub_cat_sum = sub_cat_sum + Number($(this).attr('data-val'));
					});
					var data_type = $('.sub_cat_total'+data_no).attr('data-type');
					if(data_type == 'Income'){
						$('#sub_cat_total'+data_no).html(currency_sign+' '+currencyFormat(Number(sub_cat_sum)));
					} else{
						$('#sub_cat_total'+data_no).html('( '+currency_sign+' '+currencyFormat(Number(sub_cat_sum))+' )');
						$('#sub_cat_total'+data_no).addClass('red');
					}

					$('.sub_cat_calc'+data_no).each(function(){
						var data_number = $(this).attr('data-no');
						var sum = 0;
						$('.account'+data_number).each(function(){
							sum = sum + Number($(this).attr('data-val'));
						});

						if(sum == 0){
							if(!$(this).hasClass('d-none')){
								$(this).addClass('d-none');
								if(!$('.acc_data_number'+data_number).hasClass('d-none')){
									$('.acc_data_number'+data_number).addClass('d-none');
								}
							}
						} else {
							var data_type = $('.account'+data_number).attr('data-type');
							if(sum == 0){
								$('#acct_total'+data_number).html('-');
							} else if(data_type == 'Income' && sum > 0){
								$('#acct_total'+data_number).html(currency_sign+' '+currencyFormat(Number(sum)));
							} else{
								$('#acct_total'+data_number).html('( '+currency_sign+' '+currencyFormat(Number(sum))+' )');
								$('#acct_total'+data_number).addClass('red');
							}
							$('.acc_data_number'+data_number).each(function(){
								var data_cnt = $(this).attr('data-cnt');
								var flag = 0;
								$('.sub_calc'+data_cnt).each(function(){
									flag = flag + Number($(this).attr('data-val'));
								});

								if(flag == 0){
									if(!$(this).hasClass('d-none')){
										$(this).addClass('d-none');
									}
									$('#sub_acct_total'+data_cnt).html('-');
								} else {
									var account_type = $(this).attr('data-account-type');
									if(account_type == 'Income'){
										$('#sub_acct_total'+data_cnt).html(currency_sign+' '+currencyFormat(Number(flag)));
									} else {
										$('#sub_acct_total'+data_cnt).html('( '+currency_sign+' '+currencyFormat(Number(flag))+' )');
										$('#sub_acct_total'+data_cnt).addClass('red');
									}
								}
							})
						}
					});
				});


				var buttonCommon = {
				    exportOptions: {
				        format: {
				            body: function ( data, row, column, node ) {
				            	// Replace <br> with space
				            	var regex = /<br\s*[\/]?>/gi;
				            	data = data.replace(regex, " ");
				                return data.replace(regex, "");
				            }
				        }
				    }
				};

        		var expense_report_data_table = $("#expense-report-datatable").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '475px',
				    scrollX: true,
			        scrollCollapse: true,
					ordering: false,
			        // order: [[0,'asc']],
			        fixedColumns:   {
			            left: 4
			        },
			        buttons: [
						$.extend( true, {}, buttonCommon, {
							text: 'Download Excel',
							className: 'download_ie_excel',
							action: function(){
								$.ajax({
									method : 'GET',
									url : api_url+"expenses/export-income-expense-excel/"+user_id+"/"+user_service_name,
									dataType:'json',
									beforeSend: function(xhr) {
										xhr.setRequestHeader('Authorization', accessToken);
									},
									success: function(response) {
										var $a = $("<a>");
									    $a.attr("href",response.file);
									    $("body").append($a);
									    $a.attr("download","income_expense_report.xls");
									    $a[0].click();
									    $a.remove();
									}
								})
							}
						}),
						$.extend( true, {}, buttonCommon, {
							text: 'Download PDF',
							className: 'download_ie_pdf',
							action: function(){
								// window.open(api_url+"expenses/export-income-expense-pdf/"+user_id+"/"+user_service_name,'Download');
								$.ajax({
									method : 'GET',
									url : api_url+"expenses/export-income-expense-pdf/"+user_id+"/"+user_service_name,
									dataType:'text',
									beforeSend: function(xhr) {
										xhr.setRequestHeader('Authorization', accessToken);
									},
									success: function(response) {
										var linkSource = 'data:application/pdf;base64,'+ response ;
										var $a = $("<a>");
									    $a.attr("href",linkSource);
									    $("body").append($a);
									    $a.attr("download","income_expense_report.pdf");
									    $a[0].click();
									    $a.remove();
									}
								})
							}
						}),
					    $.extend( true, {}, buttonCommon, {
					        extend: 'colvis',
					      	text: "Columns Visibility ",
					      	collectionLayout: 'two-column',
					      	postfixButtons: ['colvisRestore'],
					    }),
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
				});

				$('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary d-none');
				$('.download_ie_excel').removeClass('btn-secondary').addClass('btn-primary');
				$('.download_ie_pdf').removeClass('btn-secondary').addClass('btn-primary');
				$('.dt-buttons > button').addClass('mr-2');
				$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
					'border-radius': '5px'
				});
				$('.dt-buttons').append('<div class="custom-control custom-switch ml-3 mt-2 fs-16 bold-black"><input type="checkbox" class="custom-control-input pointer" id="show_all_accounts"><label class="custom-control-label" for="show_all_accounts">Show All</label></div>');
				$('.dt-buttons').append('<div class="custom-control custom-switch ml-3 mt-2 fs-16 bold-black"><input type="checkbox" class="custom-control-input pointer" id="expand_collapse" checked><label class="custom-control-label" for="expand_collapse">Collapse</label></div>');

				if(response.response_data.code != 0){
					$('.buttons-colvis').attr('disabled',true);
					$('.download_ie_excel').attr('disabled',true);
					$('.download_ie_pdf').attr('disabled',true);
					$('#show_all_accounts').attr('disabled',true);
					$('#expand_collapse').attr('disabled',true);
				}

				// Adjust column widths when a column is shown/hidden
				expense_report_data_table.on('column-visibility.dt', function(e, settings, column, state) {
					expense_report_data_table.columns.adjust().draw();
				});
			}
		}
	})
}

window.calculateTotal = function(){
    var total = 0;
	$('.inputAmount').each(function(){
        	// if($(this).val() == 0 || $(this).val() == 0.00) {
			// 	$(this).val('0.00');
			// }
			var val = $(this).val();
			total += parseFloat(val.replace(/,/g, ''));

			var amount = $(this).val();
			var amt = amount.replace(/,/g, '');
			$(this).val(currencyFormat(Number(amt)));
    })
    $("#expenseTotalAmount").html(currency_sign+' '+currencyFormat(Number(total)));
    $("#totalAmountInput").val(total.toFixed(2));
}

window.getTenantReport = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();

	var data = '';
	data = 'user_timezone='+timezone+'&user_service_name='+user_service_name;

	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants/get-tenant-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTenants == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.tenants) {
	                if (response.tenants.hasOwnProperty(key)) {

	                	var property_name = '';
		                if(response.tenants[key].property != null) {
		                	property_name = response.tenants[key].property.name;
		                }

	                	var address = '';
		                if(response.tenants[key].property != null && response.tenants[key].property.address != null) {
		                	address = response.tenants[key].property.address.address_line1+', '+response.tenants[key].property.address.address_line2+'<br>'+response.tenants[key].property.address.city+', '+response.tenants[key].property.address.state+' '+response.tenants[key].property.address.zip_code;
		                }

	                	var monthly_rent_amount = '-';
	                	var rent_due_date = '-';
	                	var security_deposit_amount = '-';
	                	var pet_policy = '-';
	                	var pet_deposit_amount = '-';
	                	var start_date = '-';
	                	var end_date = '-';
	                	var sd_due_date = '-';
	                	var pd_due_date = '-';

	                	var status = '';
	                    if(response.tenants[key].application_constant == 'draft') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'submitted') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_sent') {
	                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'signed_up') {
	                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'tenant_deleted') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_pending') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    }

	                    if(response.tenants[key].lease_status == 4) {
	                    	status = '<span class="badge badge-danger">Lease Expired</span>';
	                    }

	                    if(response.tenants[key].tenant_leases.length > 0) {
		                	for (var tlkey in response.tenants[key].tenant_leases) {
		                		if (response.tenants[key].tenant_leases.hasOwnProperty(tlkey)) {
		                			var lease = response.tenants[key].tenant_leases[tlkey].lease;
		                			if(lease != null) {
				                		monthly_rent_amount = lease.monthly_rent_amount;
				                		rent_due_date = ordinal_suffix_of(lease.rent_due_date);
				                		security_deposit_amount = lease.security_deposit_amount;
				                		pet_policy = lease.pet_policy_term_label;
				                		pet_deposit_amount = lease.pet_deposit_amount;
				                		start_date = lease.start_date;
				                		end_date = lease.end_date;
				                		sd_due_date = 'Due on '+lease.sd_due_date;
				                		pd_due_date = 'Due on '+lease.pd_due_date;
				                	}

				                	html += '<tr data-id="'+response.tenants[key].id+'">';
				                		html += '<td class="bg-light">'+response.tenants[key].id+'</td>';
				                		html += '<td class="bg-light"><span class="bold-black">'+property_name+'</span><br><small>'+address+'</small></td>';
				                		html += '<td>'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</td>';
				                		html += '<td>'+start_date+'</td>';
				                		html += '<td>'+end_date+'</td>';
				                		html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
				                		html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
				                		html += '<td>'+pet_policy+'</td>';
				                		html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
				                		html += '<td>'+status+'</td>';
			                        html += '</tr>';
							    }
							}
						} else {
							html += '<tr data-id="'+response.tenants[key].id+'">';
		                		html += '<td class="bg-light">'+response.tenants[key].id+'</td>';
		                		html += '<td class="bg-light"><span class="bold-black">'+property_name+'</span><br><small>'+address+'</small></td>';
		                		html += '<td>'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</td>';
		                		html += '<td>'+start_date+'</td>';
		                		html += '<td>'+end_date+'</td>';
		                		html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
		                		html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
		                		html += '<td>'+pet_policy+'</td>';
		                		html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
		                		html += '<td>'+status+'</td>';
	                        html += '</tr>';
						}
	                }
	            }
	        }

            $('#tenant_report_data_container').html(html);
            var tenant_table = $("#tenant-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '600px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 2
		        },
		        buttons: [
		           {
		              extend: 'colvis',
		              text: "Columns Visibility ",
		              collectionLayout: 'two-column',
		              postfixButtons: ['colvisRestore'],
		           }
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    },
				initComplete: function () {
		          	//console.log("The Table has been initialized!")
		        }
			});

			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPaymentSummaryReport = function() {
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();

	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name;

	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/payment-summary-report",
        data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	for (var key in response.paymentArray) {
	                if (response.paymentArray.hasOwnProperty(key)) {
	                	var html = '';
	                	html += '<div class="col-12 col-md-6 col-xl-4 property_card">';
	                		html += '<div class="card">';
									html += '<div class="card-header border-bottom">';
										html += '<h5 class="card-title mb-0 mr-3">'+response.paymentArray[key].label+'<span class="float-right">'+response.paymentArray[key].formatted_total+'</span></h5>';
									html += '</div>';
									html += '<div class="card-body">';
										html += '<div class="chart chart-md">';
											html += '<canvas id="'+response.paymentArray[key].label+'"></canvas>';
										html += '</div>';
										html += '<div class="status_container mt-5"></div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';

						var labels = [];
						var data = [];
						var color = [];
						var status_label = '';
						for (var akey in response.paymentArray[key]) {
	                		if (response.paymentArray[key].hasOwnProperty(akey)) {
	                			if (typeof response.paymentArray[key][akey] === 'object') {
	                				labels.push(response.paymentArray[key][akey].label);
	                				data.push(response.paymentArray[key][akey].total);
	                				color.push(response.paymentArray[key][akey].color);
	                				status_label += '<span style="text-align:center;word-break:break-all;padding: 0px 18px 0px 18px;font-size:65%;background-color:'+response.paymentArray[key][akey].color+'">&nbsp;</span><span class="fs-12 bold-grey ml-2 mr-2">'+response.paymentArray[key][akey].label+'</span>';
	                			}
	                		}
	                	}

	                	$('#summary_data_section').append(html);
	                	$('.status_container').html(status_label);

						// Pie chart
						new Chart(document.getElementById(response.paymentArray[key].label), {
							type: "pie",
							data: {
								labels: labels,
								datasets: [{
									data: data,
									backgroundColor: color,
									borderColor: "transparent"
								}]
							},
							options: {
								maintainAspectRatio: false,
								legend: {
									display: false
								},
								tooltips: {
						            callbacks: {
						                label: function(tooltipItem, data) {
						                	var label_text = data.labels[tooltipItem.index];
						                  	var value = data.datasets[0].data[tooltipItem.index];
						                  	value = (new Intl.NumberFormat('en-US', {
								                style: 'currency',
								                currency: 'USD',
								            })).format(value);
								            if(label_text == 'Cancelled Authorized Transfer' || label_text == 'Cancelled Transfer' || label_text == 'Failed Transfer' || label_text == 'Refund') {
								            	return ' '+label_text+' : - ('+value+')';
								            } else {
								            	return ' '+label_text+' : '+value;
								            }
						                }
						           }
						        },
							}
						});
	                }
	            }
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getTransactionReport = function() {
	openLoadingDialog($('#transaction_report_data_container'));
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();

	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name;

	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/transaction-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {

	                	// Proeprty Detail
	                	var property_name = response.scheduledPayments[key].property.name;
	                	var property_owner = response.scheduledPayments[key].po_name;

	                	var property_tenant = '';
	                	var tenant_detail = '';
	                	var tenant_name = '';
	                	var tenant_email = '';
	                	if(response.scheduledPayments[key].tenant_detail) {
		                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
	                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
	                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
	                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
	                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
	                            }
	                        }
                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
                        	property_tenant += tenant_detail;
                        }

                        // Source and Destination
                        var source_name = '';
                        var source_email = '';
                        var destination_name = '';
                        var destination_email = '';

                        if(response.scheduledPayments[key].payment_type == 1) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	destination_name = 'Rent Synergy';
                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	destination_email = response.scheduledPayments[key].po_email;
                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	destination_name = 'Rent Synergy';
                        } else if(response.scheduledPayments[key].payment_type == 8) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	destination_name = 'Rent Synergy';
                        } else if(response.scheduledPayments[key].payment_type == 9) {
                        	source_name = 'Rent Synergy';
                        	destination_name = tenant_name;
                        	destination_email = tenant_email;
                        }

                        // Payment Due Date
                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	// Authorized Date
                        var authorized_date = response.scheduledPayments[key].authorized_date;
                        var authorized_time = response.scheduledPayments[key].authorized_time;
	                	if(response.scheduledPayments[key].authorized_date == null) {
	                		authorized_date = '';
	                		authorized_time = '';
	                	}

	                	// Transaction Date
                        var transaction_start_date = response.scheduledPayments[key].transaction_start_date;
                        var transaction_start_time = response.scheduledPayments[key].transaction_start_time;
                        var transaction_end_date = response.scheduledPayments[key].transaction_end_date;
                        var transaction_end_time = response.scheduledPayments[key].transaction_end_time;

	                	// Payment Method
                        var bank_data = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(Object.keys(payment_details).length !== 0) {
	                		bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
		                }

		                // Coupon Data
	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '';
	                		coupon_amount = '';
	                	}

	                	// Status
	                	var application_status = '';
	                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
	                        application_status += '<span class="badge badge-success fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer' || response.scheduledPayments[key].application_constant == 'rs_failed_transfer') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
	                        application_status += '<span class="badge badge-primary fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'pending_collection') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    }

	                    // Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	//if(application_status != '') {
	                		exclamation = '<i data-id="'+response.scheduledPayments[key].id+'" data-transferID="'+response.scheduledPayments[key].dwolla_transferID+'" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	//}

                        var property_setup_fee = '<span>'+response.scheduledPayments[key].property_setup_fee+'</span>';
                        var security_deposit_fee = '<span>'+response.scheduledPayments[key].security_deposit_fee+'</span>';
                        var pet_deposit_fee = '<span>'+response.scheduledPayments[key].pet_deposit_fee+'</span>';
                        var subscription_fee = '<span>'+response.scheduledPayments[key].subscription_fee+'</span>';
                        var rent_fee = '<span>'+response.scheduledPayments[key].rent_fee+'</span>';

                        var rent_due_by = '';
                        var rent_amount = '';
                        var no_of_days = '';
                        var late_fee_per_day = '';
                        var outstanding_late_fee = '';
                        var late_fee_waived = '';
                        var total_rent_due = '';
                        if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0 && (response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7)) {
	                        /*rent_fee += '<div><small>(A) Rent Due by '+response.scheduledPayments[key].payment_due_date+' : $'+response.scheduledPayments[key].total+'</small></div>';
	                        rent_fee += '<div ml-4><small>(1) No. of days late after '+response.scheduledPayments[key].lateFeeafterDays+' day of the month: '+response.scheduledPayments[key].no_of_days+'</small></div>';
	                        rent_fee += '<div ml-4><small>(2) Late fee applied per day: $'+response.scheduledPayments[key].price_per_day+'</small></div>';
	                        rent_fee += '<div><small>(B) Outstanding late fee (1) x (2): $'+response.scheduledPayments[key].late_fee_amount+'</small></div>';
	                        rent_fee += '<div><small>(C) Late fee waived: $'+response.scheduledPayments[key].waived_amount+'</small></div>';
	                        rent_fee += '<div><small>(D) Total (A) + (B) - (C): $'+response.scheduledPayments[key].final_total+'</small></div>';*/

	                        rent_due_by = response.scheduledPayments[key].payment_due_date;
	                        rent_amount = '$'+response.scheduledPayments[key].total;
	                        no_of_days = response.scheduledPayments[key].no_of_days;
	                        late_fee_per_day = '$'+response.scheduledPayments[key].price_per_day;
	                        if(response.scheduledPayments[key].late_fee_amount > 0) {
	                        	outstanding_late_fee = '$'+response.scheduledPayments[key].late_fee_amount;
	                        }
	                        if(response.scheduledPayments[key].waived_amount > 0) {
	                        	late_fee_waived = '$'+response.scheduledPayments[key].waived_amount;
	                        }
	                        total_rent_due = '$'+response.scheduledPayments[key].final_total;
	                    }

	                	html += '<tr data-id="'+response.scheduledPayments[key].id+'">';
	                		html += '<td class="text-left bg-light">'+response.scheduledPayments[key].id+'</td>';
	                		html += '<td class="bg-light"><span class="badge badge-primary fs-12">'+response.scheduledPayments[key].payment_type_label+'</span></td>';
                            html += '<td class="bg-light">'+property_name+'</td>';
                            html += '<td>'+property_owner+'</td>';
                            html += '<td>'+property_tenant+'</td>';
                            html += '<td>'+payment_due_date+'</td>';
                            html += '<td>'+authorized_date+'<br><small>'+authorized_time+'</small></td>';
                            html += '<td>'+transaction_start_date+'<br><small>'+transaction_start_time+'</small></td>';
                            html += '<td>'+transaction_end_date+'<br><small>'+transaction_end_time+'</small></td>';
                            html += '<td>'+application_status+'<span class="ml-2">'+exclamation+'</span></td>';
                            html += '<td>'+source_name+'</td>';
                            html += '<td>'+source_email+'</td>';
                            html += '<td>'+destination_name+'</td>';
                            html += '<td>'+destination_email+'</td>';
                            html += '<td>'+bank_data+'</td>';
                            html += '<td>'+coupon_code+'</td>';
                            html += '<td>'+coupon_amount+'</td>';
                            html += '<td class="text-right">'+property_setup_fee+'</td>';
                            html += '<td class="text-right">'+security_deposit_fee+'</td>';
                            html += '<td class="text-right">'+pet_deposit_fee+'</td>';
                            html += '<td class="text-right">'+subscription_fee+'</td>';
                            html += '<td class="text-right">'+rent_fee+'</td>';

                            html += '<td>'+rent_due_by+'</td>';
                            html += '<td class="text-right">'+rent_amount+'</td>';
                            html += '<td class="text-right">'+no_of_days+'</td>';
                            html += '<td class="text-right">'+late_fee_per_day+'</td>';
                            html += '<td class="text-right">'+outstanding_late_fee+'</td>';
                            html += '<td class="text-right">'+late_fee_waived+'</td>';
                            html += '<td class="text-right">'+total_rent_due+'</td>';
                        html += '</tr>';
	                }
	            }
	        }

	        $('#property_setup_fee_total').html(response.property_setup_fee_total);
	        $('#security_deposit_fee_total').html(response.security_deposit_fee_total);
	        $('#pet_deposit_fee_total').html(response.pet_deposit_fee_total);
	        $('#subscription_fee_total').html(response.subscription_fee_total);
	        $('#rent_fee_total').html(response.rent_fee_total);

	        $('#late_fee_amount_total').html(response.late_fee_amount_total);
	        $('#waived_amount_total').html(response.waived_amount_total);
	        $('#main_total').html(response.main_total);

            $('#transaction_report_data_container').html(html);
            $('#transaction-report-datatable thead, #transaction-report-datatable tfoot').removeClass('d-none');
            $('#transaction-report-datatable').addClass('table-bordered');

            if ($.fn.DataTable.isDataTable("#transaction-report-datatable")) {
	            $("#transaction-report-datatable").DataTable().destroy();
	        }

            var buttonCommon = {
		        exportOptions: {
		            format: {
		                body: function ( data, row, column, node ) {
		                	// Replace <br> with space
		                	var regex = /<br\s*[\/]?>/gi;
		                	data = data.replace(regex, " ");
		                	//data = data.replace(regex, "\n");

		                	// Remove all html tags from data and Remove $ from amount
		                	var regex = /(<([^>]+)>)/ig;
		                    var amt_col = [16,17,18,19,20,21,23,25,26,27,28];
		                    if(amt_col.indexOf(column) !== -1) {
		                    	var data = data.replace(regex, "");
		                    	return data.replace( /[$,]/g, '' );
		                    } else {
		                    	return data.replace(regex, "");
		                    }
		                }
		            }
		        }
		    };

            var transaction_table = $("#transaction-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '425px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 3
		        },
		        buttons: [
		        	$.extend( true, {}, buttonCommon, {
		                extend: 'excelHtml5',
			            text: 'Download Excel',
			            title: 'Transaction Report',
			            message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
			            autoFilter: true,
			            header: true,
			            footer: true,
			            exportOptions: {
							//columns: ':visible'
		                    columns: ':visible:not(.notexport)'
		                },
		                customize: function(xlsx) {
					        var sheet = xlsx.xl.worksheets['sheet1.xml'];
					        datatable_excel_common_formatting(xlsx,sheet);
					    }
		            }),
		            $.extend( true, {}, buttonCommon, {
		                extend: 'colvis',
		              	text: "Columns Visibility ",
		              	collectionLayout: 'two-column',
		              	postfixButtons: ['colvisRestore'],
		            }),
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    }
			});

			$('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
			$('.dt-buttons > button').addClass('mr-2');
			$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
				'border-radius': '5px'
			});

            // Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPaymentReceiptReport = function(date_macro) {
	if ($.fn.DataTable.isDataTable("#payment-receipt-report-datatable")) {
        $("#payment-receipt-report-datatable").DataTable().destroy();
    }
	$('#payment_receipt_report_data_container').html('');
	openLoadingDialog($('#payment_receipt_report_data_container'));
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();

	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name+'&date_macro='+date_macro;

	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/payment-receipt-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {

	                	// Proeprty Detail
	                	var property_name = response.scheduledPayments[key].property.name;
	                	var property_owner = response.scheduledPayments[key].po_name;
	                	var address = '';
		                if(response.scheduledPayments[key].property != null && response.scheduledPayments[key].property.address != null) {
		                	address = response.scheduledPayments[key].property.address.address_line1+', '+response.scheduledPayments[key].property.address.address_line2+'<br>'+response.scheduledPayments[key].property.address.city+', '+response.scheduledPayments[key].property.address.state+' '+response.scheduledPayments[key].property.address.zip_code;
		                }

	                	var property_tenant = '';
	                	var tenant_detail = '';
	                	var tenant_name = '';
	                	var tenant_email = '';
	                	if(response.scheduledPayments[key].tenant_detail) {
		                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
	                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
	                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
	                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
	                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
	                            }
	                        }
                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
                        	property_tenant += tenant_detail;
                        }

                        var property_detail = property_name+'<br>'+'Current Owner:&nbsp;'+property_owner+'<br>Current Tenant:&nbsp;'+property_tenant;

                        // Payment Method
                        var bank_data = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(payment_details != null) {
		                	if(Object.keys(payment_details).length !== 0) {
	                			bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
		                	}
		                }

                        // Source and Destination
                        var source_name = '';
                        var source_email = '';
                        var source_bank = '';
                        var destination_name = '';
                        var destination_email = '';
                        var destination_bank = '';

                        var company_name = 'Rent Synergy';
                        if(response.scheduledPayments[key].payment_type == 1) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	source_bank = bank_data;
                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	destination_email = response.scheduledPayments[key].po_email;
                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 8) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 9) {
                        	source_name = company_name;
                        	destination_name = tenant_name;
                        	destination_email = tenant_email;
                        	destination_bank = bank_data;
                        }

                        var source_data = source_name+'<br>'+source_email+'<br>'+source_bank;
                        var destination_data = destination_name+'<br>'+destination_email+'<br>'+destination_bank;

                        // Payment Due Date
                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	// Authorized Date
                        var authorized_date = response.scheduledPayments[key].authorized_date;
                        var authorized_time = response.scheduledPayments[key].authorized_time;
	                	if(response.scheduledPayments[key].authorized_date == null) {
	                		authorized_date = '';
	                		authorized_time = '';
	                	}

	                	// Initiated date Date
                        var initiated_date = response.scheduledPayments[key].initiated_date;
                        var initiated_time = response.scheduledPayments[key].initiated_time;
	                	if(response.scheduledPayments[key].initiated_date == null) {
	                		initiated_date = '';
	                		initiated_time = '';
	                	}

	                	// Completed date Date
                        var completed_date = response.scheduledPayments[key].completed_date;
                        var completed_time = response.scheduledPayments[key].completed_time;
	                	if(response.scheduledPayments[key].completed_date == null) {
	                		completed_date = '';
	                		completed_time = '';
	                	}

	                	// Coupon Data
	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '';
	                		coupon_amount = '';
	                	}

	                	var coupon_data = coupon_code+'<br>'+coupon_amount;

	                	// Status
	                	var application_status = '';
	                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
	                        application_status += '<span class="badge badge-success fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer' || response.scheduledPayments[key].application_constant == 'rs_failed_transfer') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
	                        application_status += '<span class="badge badge-primary fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'pending_collection') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    }

	                    var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">';
	                    if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
		                    if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
		                    	kebab += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
		                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
		                    	kebab += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
		                    }
		                }

		                if(user.permissionData['PROPERTIES']["feature_items['regenerate_transaction']"]) {
		                    if(user_service_name == sa_role && response.scheduledPayments[key].regenerate_transaction == 1 && response.scheduledPayments[key].payment_type != 8) {
	                    		kebab += '<a class="dropdown-item regenerate_transaction" data-id="'+response.scheduledPayments[key].id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Regenerate Transaction</a>';
	                    	}
	                    }

	                    if(user_service_name == po_role && response.scheduledPayments[key].authorize_subscription_fee == 1) {
                    		kebab += '<a class="dropdown-item authorize_subscription_fee" data-id="'+response.scheduledPayments[key].id+'" data-property-id="'+response.scheduledPayments[key].property_id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Authorize Subscription Fee</a>';
                    	}

                    	kebab += '</div></div></div>';

	                    // Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	//if(application_status != '') {
	                		exclamation = '<i data-id="'+response.scheduledPayments[key].id+'" data-transferID="'+response.scheduledPayments[key].dwolla_transferID+'" class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	//}

                        var property_setup_fee = '<span>'+response.scheduledPayments[key].property_setup_fee+'</span>';
                        var security_deposit_fee = '<span>'+response.scheduledPayments[key].security_deposit_fee+'</span>';
                        var pet_deposit_fee = '<span>'+response.scheduledPayments[key].pet_deposit_fee+'</span>';
                        var subscription_fee = '<span>'+response.scheduledPayments[key].subscription_fee+'</span>';
                        var rent_fee = '<span>'+response.scheduledPayments[key].rent_fee+'</span>';

                        var rent_due_by = '';
                        var rent_amount = '';
                        var no_of_days = '';
                        var late_fee_per_day = '';
                        var outstanding_late_fee = '';
                        var late_fee_waived = '';
                        var total_rent_due = '';
                        if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0 && (response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7)) {
	                        rent_due_by = response.scheduledPayments[key].payment_due_date;
	                        rent_amount = '$'+response.scheduledPayments[key].total;
	                        no_of_days = response.scheduledPayments[key].no_of_days;
	                        late_fee_per_day = '$'+response.scheduledPayments[key].price_per_day;
	                        if(response.scheduledPayments[key].late_fee_amount > 0) {
	                        	outstanding_late_fee = '$'+response.scheduledPayments[key].late_fee_amount;
	                        }
	                        if(response.scheduledPayments[key].waived_amount > 0) {
	                        	late_fee_waived = '$'+response.scheduledPayments[key].waived_amount;
	                        }
	                        total_rent_due = '$'+response.scheduledPayments[key].final_total;
	                    }

	                	html += '<tr data-id="'+response.scheduledPayments[key].id+'">';
	                		html += '<td class="text-left bg-light">'+response.scheduledPayments[key].id+'</td>';
	                		html += '<td class="bg-light">'+property_detail+'</td>';
	                		html += '<td class="bg-light"><span class="badge badge-primary fs-12">'+response.scheduledPayments[key].payment_type_label+'</span></td>';
                            html += '<td>'+application_status+'<span class="ml-2">'+exclamation+'</span></td>';
                            html += '<td class="text-right">'+response.scheduledPayments[key].payments+'</td>';
                            html += '<td class="text-right">'+response.scheduledPayments[key].receipts+'</td>';
                            html += '<td>'+payment_due_date+'</td>';
                            html += '<td>'+authorized_date+'<br><small>'+authorized_time+'</small></td>';
                            html += '<td>'+initiated_date+'<br><small>'+initiated_time+'</small></td>';
                            html += '<td>'+completed_date+'<br><small>'+completed_time+'</small></td>';
                            html += '<td>'+source_data+'</td>';
                            html += '<td>'+destination_data+'</td>';
                            html += '<td>'+coupon_data+'</td>';
                            html += '<td>-</td>';
                            //html += '<td><span class="accordion_arrow">'+kebab+'</span></td>';
                        html += '</tr>';
	                }
	            }
	        }

	        $('#payment_receipt_report_data_container').html(html);
            $('#payment-receipt-report-datatable thead, #payment-receipt-report-datatable tfoot').removeClass('d-none');
            $('#payment-receipt-report-datatable').addClass('table-bordered');

            $('#payment_total').html(response.payment_total);
	        $('#receipt_total').html(response.receipt_total);

	        var buttonCommon = {
		        exportOptions: {
		            format: {
		                body: function ( data, row, column, node ) {
		                	// Replace <br> with space
		                	var regex = /<br\s*[\/]?>/gi;
		                	data = data.replace(regex, " ");
		                	//data = data.replace(regex, "\n");

		                	// Remove all html tags from data and Remove $ from amount
		                	var regex = /(<([^>]+)>)/ig;
		                    var amt_col = [4,5];
		                    if(amt_col.indexOf(column) !== -1) {
		                    	var data = data.replace(regex, "");
		                    	return data.replace( /[$,]/g, '' );
		                    } else {
		                    	return data.replace(regex, "");
		                    }
		                }
		            }
		        }
		    };

            var transaction_table = $("#payment-receipt-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '425px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 3
		        },
		        "columnDefs": [
				    { "visible": false, "targets": [7, 8, 9, 10, 11, 12, 13] }
				],
		        buttons: [
		        	$.extend( true, {}, buttonCommon, {
		                extend: 'excelHtml5',
			            text: 'Download Excel',
			            title: 'Transaction Report',
			            message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
			            autoFilter: true,
			            header: true,
			            footer: true,
			            exportOptions: {
							//columns: ':visible'
		                    columns: ':visible:not(.notexport)'
		                },
		                customize: function(xlsx) {
					        var sheet = xlsx.xl.worksheets['sheet1.xml'];
					        datatable_excel_common_formatting(xlsx,sheet);
					    }
		            }),
		            $.extend( true, {}, buttonCommon, {
		                extend: 'colvis',
		              	text: "Columns Visibility ",
		              	collectionLayout: 'two-column',
		              	postfixButtons: ['colvisRestore'],
		            }),
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    }
			});

			$('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');

			var dd = '';
			dd += '<div class="form-button ml-2">';
			    dd += '<div class="dropdown">';
			         dd += '<button type="button" class="btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-primary dropdown-toggle date-macro" data-toggle="dropdown" data-placement="bottom" data-original-title="Date macros"><i class="material-icons md-dark pmd-sm">date_range</i></button>';
			        dd += '<ul class="dropdown-menu dropdown-menu-right" id="date_macros">';
			            dd += '<li><a href="javascript:void(0)">All Dates</a></li>';
			            dd += '<li><a href="javascript:void(0)">Today</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Week</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Month</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Quarter</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Year</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Week</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Month</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Quarter</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Year</a></li>';
			        dd += '</ul>';
			    dd += '</div>';
			dd += '</div>';

			$('.dt-buttons').append(dd);

			$('.dt-buttons > button').addClass('mr-2');
			$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
				'border-radius': '5px'
			});

			// Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPaymentReceiptCardReport = function(date_macro) {
	if ($.fn.DataTable.isDataTable("#pr-report-datatable")) {
        $("#pr-report-datatable").DataTable().destroy();
    }
	$('#pr_report_data_container').html('');
	// openLoadingDialog($('#totalCardContainer'));
	openLoadingDialog($('#pr_report_data_container'));
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();

	// Dropdown value
	var dd_property_id = $('#transaction_report_property_dd').val();
	dd_property_id = dd_property_id.join(',');
	var dd_payment_type = $('#transaction_report_pt_dd').val();
	dd_payment_type = dd_payment_type.join(',');
	var dd_transaction_type = $('#transaction_report_tt_dd').val();
	dd_transaction_type = dd_transaction_type.join(',');

	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name+'&date_macro='+date_macro+'&dd_property_id='+dd_property_id+'&dd_payment_type='+dd_payment_type+'&dd_transaction_type='+dd_transaction_type;


	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/payment-receipt-transaction-total",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			var card_html = '';
			var total_card_html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
	        	$('#totalCardContainer').html('');
		    } else {
		    	var total_income = '';
		    	var total_expense = '';
		    	if(user_service_name == po_role) { // PO
		    		total_income = response.receipt_total;
		    		total_expense = response.payment_total;
		    	} else if(user_service_name == t_role) { // Tenant
		    		total_income = response.payment_total;
		    		total_expense = response.receipt_total;
		    	} else { // Admin
		    		total_income = response.receipt_total;
		    		total_expense = response.payment_total;
		    	}

				total_card_html += '<div class="row overview_tiles">';
		    		if(user_service_name == sa_role) {
			    		total_card_html += '<div class="col-12 col-md-12 col-lg-3">';
							total_card_html += '<div class="card light-border mb-1">';
								total_card_html += '<div class="media ml-2">';
									total_card_html += '<div class="d-inline-block mt-3 mr-3"><img src="icon/tiles/tran1.svg" class="filter-12"/></div>';
									total_card_html += '<div class="media-body mt-2">';
										total_card_html += '<div class="mb-1 fs-16">Rent Collected</div>';
										total_card_html += '<h3 class="mb-2 dodgerblue">'+response.rent_total+'</h3>';
									total_card_html += '</div>';
								total_card_html += '</div>';
							total_card_html += '</div>';
						total_card_html += '</div>';
					}
					total_card_html += '<div class="col-12 col-md-12 col-lg-3">';
						total_card_html += '<div class="card light-border mb-1">';
							total_card_html += '<div class="media ml-2">';
								total_card_html += '<div class="d-inline-block mt-3 mr-3"><img src="icon/tiles/download.svg" class="filter-10"/></div>';
								total_card_html += '<div class="media-body mt-2">';
									total_card_html += '<div class="mb-1 fs-16">Total Income</div>';
									total_card_html += '<h3 class="mb-2 green">'+total_income+'</h3>';
								total_card_html += '</div>';
							total_card_html += '</div>';
						total_card_html += '</div>';
					total_card_html += '</div>';
					total_card_html += '<div class="col-12 col-md-12 col-lg-3">';
						total_card_html += '<div class="card light-border mb-1">';
							total_card_html += '<div class="media ml-2">';
								total_card_html += '<div class="d-inline-block mt-3 mr-3"><img src="icon/tiles/upload.svg" class="filter-11"/></div>';
								total_card_html += '<div class="media-body mt-2">';
									total_card_html += '<div class="mb-1 fs-16">Total Expenses</div>';
									total_card_html += '<h3 class="mb-2 red">'+total_expense+'</h3>';
								total_card_html += '</div>';
							total_card_html += '</div>';
						total_card_html += '</div>';
					total_card_html += '</div>';
					total_card_html += '<div class="col-12 col-md-12 col-lg-3">';
						total_card_html += '<div class="card light-border mb-1">';
							total_card_html += '<div class="media ml-2">';
								total_card_html += '<div class="d-inline-block mt-3 mr-3"><img src="icon/tiles/money.svg" class="filter-7"/></div>';
								total_card_html += '<div class="media-body mt-2">';
									total_card_html += '<div class="mb-1 fs-16">Total Balance</div>';
									total_card_html += '<h3 class="mb-2">'+response.total+'</h3>';
								total_card_html += '</div>';
							total_card_html += '</div>';
						total_card_html += '</div>';
					total_card_html += '</div>';
				total_card_html += '</div>';

				$('#totalCardContainer').html(total_card_html);
	        }

        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });

	var expenses_table = $("#pr-report-datatable").DataTable({
		serverSide: true,
		processing: true,
		ordering: false,
		pageLength: 10,
		ajax: {
			method: 'POST',
			url: api_url+"scheduled-payments/payment-receipt-report-datatable",
			data: function(d){
				d.user_timezone = timezone;
				d.user_id = user_id;
				d.user_service_name = user_service_name;
				d.date_macro = '';
				d.dd_property_id = dd_property_id;
				d.dd_payment_type = dd_payment_type;
				d.dd_transaction_type = dd_transaction_type;
			},
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
		},
	});

	setTimeout(function(){
		$("#pr-report-datatable tbody tr td:first").addClass('border-top');
		// Get Related Transaction Detail
		var title = 'Transaction Detail';
		var type = 'RelatedTransaction';
		openPopoverModal(title,type);
	}, 3000);

	$("#pr-report-datatable tbody tr td:first").addClass('border-top');
	getFloatingActionButton('Transactions Report','#prDataContainer');

}

/* End : Account */

/* Start : Lease */

window.loadLeaseTerm = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"leases/get-term",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var html = '';
                //var html = "<option value=''>Select Term</option>";
                $.each( response.terms, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.loadLeasePetPolicy = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"leases/get-pet-policy",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>Select Pet Policy</option>";
		        $.each( response.petPolicy, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.loadLeaseRentDueDate = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"leases/get-due-days",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>Select Due Day</option>";
		        $.each( response.dueDays, function( key, value ) {
		        	value = ordinal_suffix_of(value);
				  	html += '<option value="'+key+'">'+value+' of month</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

var $leaseForm;
window.addLeaseValidation = function() {
	$leaseForm = $("#lease_add_form, #lease_edit_form");
	$leaseForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "monthly_rent_amount": { required: true },
	        "term": { required: true },
	        "security_deposit_amount": { required: true },
	        "start_date": { required: true },
	        "end_date": { required: true },
	        "rent_due_date": { required: true },
	        "sd_due_date": { required: true },
	        "pet_policy": { required: true },
	        "pd_due_date": { required: true },
			"month_to_month_value": {required: true},
			"partial_payment_value": {required: true},
			"prorate_due_date": {
				required: function(element) {
                    var prorate_enabled = $('#prorate_enabled').val();
                    return prorate_enabled == 'on';
                }
			}
	    },
	    messages: {
	        "monthly_rent_amount": { required: '' },
	        "term": { required: '' },
	        "security_deposit_amount": { required: '' },
	        "start_date": { required: '' },
	        "end_date": { required: '' },
	        "rent_due_date": { required: '' },
	        "sd_due_date": { required: '' },
	        "pet_policy": { required: '' },
	        "pd_due_date": { required: '' },
			"month_to_month_value": {required: '' },
			"partial_payment_value": {required: '' },
			"prorate_due_date": {required: ''}
	    }
	});
}

window.skipLease = function() {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"leases/skip",
        data:'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
				$('#lease_flag').val(1);
				var message_html = '<h3 class="text-center mt-6 green">You Have Skipped This Step.</h3>';
				$('.lease_detail_media').html(message_html);
				$('.lease_detail_media').addClass('minHeight100');
				$('#headingFive span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
				$('#headingFive span.accordion_arrow').remove();
				var user = JSON.parse(localStorage.user);
				if($('#tenant_flag').val() == 1){
					// user.user_details.steps_left = 1;

					// $('#headingThree span.accordion_edit,#headingFour span.accordion_edit,#headingFive span.accordion_edit').remove();
					// $('#property_detail_accordion,#tenant_detail_accordion').addClass('null_pointer');

					// if(signature_enabled == 'zoho_template'){
					// 	$('#contracts_accordion').removeClass('d-none');
					// } else {
					// 	var data = JSON.parse(localStorage.getItem('user'));
					// 	var property_id = data.user_details.first_property_id;
					// 	if(signature_enabled == 'docusign'){
					// 		getContracts(property_id);
					// 	} else if(signature_enabled == 'zoho_document'){
					// 		getZohoDocuments(property_id);
					// 	}
					// }
					// loadCompletedChecklistSteps(user_id, po_role);
					loadDashboardPage();
				} else {
					user.user_details.steps_left = 2;
				}
				user.user_details.cheklist_seq_no = 5;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back
            }
        }
    });
}

window.skipAddLease = function() {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"leases/skip",
        data:'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                // Checkilist step 12
				getSignupNextStep(12,13);

				var user = JSON.parse(localStorage.user);
				user.user_details.cheklist_seq_no = 12;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back

				// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
				openLoadingDialog($('#signup_data_container'));
				setTimeout(function(){
					var user_id = user.user_details.user_id;
					var property_id = $('#first_property_id').val();
					var property_name = $('#first_property_name').val();
					getBankListForProperty(user_id, property_id, property_name);
				}, 500);
            }
        }
    });
}

window.saveLeaseData = function() {
	if($leaseForm.valid()){
		$('.save_lease_data').attr('disabled',true);
		$('.save_lease_data').text('Processing...');

		var user_id = user.user_details.user_id;
		var first_time_signup = $('#first_time_signup').val();
		if(first_time_signup == 1) {
			var data = JSON.parse(localStorage.getItem('user'));
			var property_id = data.user_details.first_property_id;
		} else {
			var property_id = $('#selected_property_id').val();
		}
		var module_name = $('#module_name').val();
		var lease_tenant_id = $('#lease_tenant_id').val();

		var allow_rent_collection = 0;
		if($('#rent_collection_checkbox:checked').val() == 'on') {
			allow_rent_collection = 1;
		}

		$.ajax({
            method: 'POST',
            url: api_url+"leases/add",
			data: $('#lease_add_form').serialize()+'&property_id='+property_id+'&tenant_id='+lease_tenant_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup+'&allow_rent_collection='+allow_rent_collection,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_lease_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	// Below code for initial signup step
                	if(first_time_signup == 1) {
						var message_html = '<h3 class="text-center mt-6 green">Lease Added Successfully.</h3>';
						$('.lease_detail_media').html(message_html);
						$('.lease_detail_media').addClass('minHeight100');
						$('#headingFive span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#headingFive span.accordion_arrow').remove();
						$('#headingFive span.accordion_edit').html('<i class="align-middle mr-2 fas fa-fw fa-edit"></i>');
						$('#lease_flag').val(1);

						setTimeout(function() {
							var user = JSON.parse(localStorage.user);
							if($('#tenant_flag').val() == 1){
								loadDashboardPage();
								// loadCompletedChecklistSteps(user_id, po_role);
								// $('#headingThree span.accordion_edit,#headingFour span.accordion_edit,#headingFive span.accordion_edit').remove();
								// $('#property_detail_accordion,#tenant_detail_accordion').addClass('null_pointer');

								// if(user.user_details.cheklist_seq_no == 6){
								// 	loadCompletedChecklistSteps(user_id, po_role);
								// } else {
								// 	user.user_details.steps_left = 1;

								// 	if(signature_enabled == 'zoho_template'){
								// 		$('#contracts_accordion').removeClass('d-none');
								// 	} else {
								// 		var data = JSON.parse(localStorage.getItem('user'));
								// 		var property_id = data.user_details.first_property_id;
								// 		if(signature_enabled == 'docusign'){
								// 			getContracts(property_id);
								// 		} else if(signature_enabled == 'zoho_document'){
								// 			getZohoDocuments(property_id);
								// 		}
								// 	}
								// }

							} else {
								user.user_details.steps_left = 2;
							}
							user.user_details.cheklist_seq_no = 5;
							localStorage.setItem("user", JSON.stringify(user));  //put the object back
						}, 300);
					} else {
	                    $('#leaseAddModal').modal('hide');
	                    $('#lease_detail_container_add,#lease_detail_container_edit').html('');
                    	openLoadingDialog($('#lease_data_container'));
	    				$('#lease_data_container').load('components/property/lease_detail_table.html');

	    				getPropertyLeaseWidgetInfo();
	                }
				} else{
					$('.save_lease_data').attr('disabled',false);
					$('.save_lease_data').text('Save');
				}
            }
        });
    }
}

window.updateLeaseData = function() {
	if($leaseForm.valid()){
		$('.update_lease_data').attr('disabled',true);
		$('.update_lease_data').text('Processing...');
		var user_id = user.user_details.user_id;
		var lease_id = $('#lease_id').val();
		var first_time_signup = $('#first_time_signup').val();
		if(first_time_signup == 1){
			var data = JSON.parse(localStorage.getItem('user'));
			var property_id = data.user_details.first_property_id;
		} else{
			var property_id = $('#selected_property_id').val();
		}

		var allow_rent_collection = 0;
		if($('#rent_collection_checkbox:checked').val() == 'on') {
			allow_rent_collection = 1;
		}
		$.ajax({
            method: 'POST',
            url: api_url+"leases/edit/"+lease_id,
            data: $('#lease_edit_form').serialize()+'&property_id='+property_id+'&user_id='+user_id+'&allow_rent_collection='+allow_rent_collection,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                if(response.response_data.success == true) {
					if(first_time_signup == 1){
						var message_html = '<h3 class="text-center mt-6 green">Lease updated successfully.</h3>';
						$('.lease_detail_media').html(message_html);
						$('.lease_detail_media').addClass('minHeight100');
					} else {
						$('#leaseEditModal').modal('hide');
						$('#lease_detail_container_add,#lease_detail_container_edit').html('');
						openLoadingDialog($('#lease_data_container'));
						$('#lease_data_container').load('components/property/lease_detail_table.html');

						getPropertyLeaseWidgetInfo();
					}
                } else{
					$('.update_lease_data').attr('disabled',false);
					$('.update_lease_data').text('Update');
				}
            }
        });
    }
}

window.getLeaseDetailById = function(type, lease_id) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"leases/view/"+lease_id,
        data:'user_id='+user_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	if(type == 'edit') {
	            	setTimeout(function(){
					    $('#lease_id').val(response.lease.id);
		                $('#monthly_rent_amount').val(response.lease.monthly_rent_amount);
		                $('#term').val(response.lease.term);
		                $('#security_deposit_amount').val(response.lease.security_deposit_amount);
		                $('#pet_deposit_amount').val(response.lease.pet_deposit_amount);
		                $('#start_date > input').val(response.lease.start_date);
		                $('#end_date > input').val(response.lease.end_date);
		                $('#pet_policy').val(response.lease.pet_policy);
		                $('#rent_due_date').val(response.lease.rent_due_date);
		                $('#sd_due_date > input').val(response.lease.sd_due_date);
		                $('#pd_due_date > input').val(response.lease.pd_due_date);

						if(response.lease.is_payment_schedule_created == 1){
							$('.ps_disabled_field').addClass('add_opacity');
						}

		                if(response.lease.pet_policy == 2) {
							$('#pet_deposit_amount').attr('readonly', false);
							$('#pd_due_date > input').attr('disabled', false);
						} else {
							$('#pet_deposit_amount').val('');
							$('#pd_due_date > input').val('');
							$('#pet_deposit_amount').attr('readonly', true);
							$('#pd_due_date > input').attr('disabled', true);
						}

						if(response.lease.allow_rent_collection == 0){
							$('#rent_collection_checkbox').attr('checked',false);
						}

						if(response.lease.prorate_enabled == 1){
							$('#prorate_enabled').prop('checked',true);
							$('#prorate_enabled').trigger('change');
							$('#prorate_due_date > input').val(response.lease.prorate_due_date);
						} else {
							$('#prorate_enabled').prop('checked',false);
						}

						if(response.lease.month_to_month_enabled == 1){
							// $('#month_to_month_enabled').prop('checked',true);
							if(response.lease.month_to_month_method == 'F'){
								$('#lease_calc_fix_amount').prop('checked',true);
							} else {
								$('#lease_calc_percentage').prop('checked',true);
							}
							$('#month_to_month_enabled').val('on');
							$('.month_to_month_method').trigger('change');
							$('#month_to_month_value').val(response.lease.month_to_month_value);
							$('#month_to_month_value').trigger('change');
						} else {
							// $('#month_to_month_enabled').prop('checked',false);
							$('#month_to_month_enabled').val('');
							$('#lease_calc_off').prop('checked',true);
						}

						if(response.lease.partial_payment_enabled == 1){
							// $('#partial_payment_enabled').prop('checked',true);
							if(response.lease.partial_payment_method == "F"){
								$('#partial_calc_fix_amount').prop('checked',true);
							} else {
								$('#partial_calc_percentage').prop('checked',true);
							}
							$('#partial_payment_enabled').val('on');
							$('.partial_payment_method').trigger('change');
							$('#partial_payment_value').val(response.lease.partial_payment_value);
						} else {
							$('#partial_payment_enabled').val('');
							$('#partial_calc_off').prop('checked',true);
						}
					}, 100);
	            }

	            if(type == 'autopay') {
					$('.save_autopay_data').attr('disabled', false);
	            	// var autopay_enable_disable = $('#autopay_enable_disable').val();
					var autopay_enable_disable = response.lease.autopay;

					var date_html = '';
	            	// date_html += "<option value=''>Autopay date</option>";
	            	// for (let i = 21; i <= 31; i++) {
	            	// 	if(response.lease.day == i) {
	            	// 		var selected = 'selected';
	            	// 	} else {
	            	// 		var selected = '';
	            	// 	}
					//   	var due_date = ordinal_suffix_of(i);
				    //     date_html += '<option value="'+i+'" '+selected+'>'+due_date+'</option>';
					// 	if(i == 31) {
					// 		var first_date = ordinal_suffix_of(1);
					// 		date_html += '<option value="1" '+selected+'>'+first_date+' - Next month</option>';
					// 	}
					// }
					// #667 to implement manage autopay code with new payment schedule workflow
					var first_date = ordinal_suffix_of(1);
					date_html += '<option value="1" selected>'+first_date+' - of This Month</option>';

					var bank_html = '';
	            	// bank_html += "<option value=''>Select Payment Method</option>";
	                for (var key in response.paymentDetails) {
				        if (response.paymentDetails.hasOwnProperty(key)) {
				        	if(response.lease.autopay_payment_detail_id == response.paymentDetails[key].id) {
		            			var b_selected = 'selected';
		            		} else if(Object.keys(response.paymentDetails).length == 1) {
								var b_selected = 'selected';
							} else {
		            			var b_selected = '';
		            		}
				        	bank_html += '<option value="'+response.paymentDetails[key].id+'" '+b_selected+'>'+response.paymentDetails[key].bank_name+'</option>';
						}
					}

	            	var html = '';
	            	html += '<input type="hidden" name="autopay_lease_id" id="autopay_lease_id" value="'+response.lease.id+'">';
	            	html += '<input type="hidden" name="autopay_property_id" id="autopay_property_id" value="'+response.lease.property_id+'">';
	            	html += '<form id="autopay-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
					    html += '<div id="payNowCollapse">';

							if(autopay_enable_disable == 1) {
								// var add_opacity = '';
								var checked = 'checked';
								var terms = 'Note : By selecting Enable Autopay, I agree and authorize scheduled monthly rent payments to Rent Synergy on the scheduled autopay date for the amount mentioned above from the selected bank account. These payments will be processed by the Dwolla payment processing system. I understand that I can cancel this autopay option by selecting Disable Autopay option from my accounts page.';
							} else {
								// var add_opacity = 'add_opacity';
								var checked = '';
								var terms = 'Note : By selecting Disable Autopay, I am cancelling the automatic procesing of future scheduled rent payments. I understand that future monthly rent payments will be authorized manually and in the absence of doing so may incur additional late fees.';
							}

							html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
								html += '<div class="rent_collection_data">';
									html += '<div class="row">';
										html += '<div class="col-6 col-md-6 col-xl-9">';
											html += '<h5 class="card-title mt-2"><span class="fs-18"><strong>Enable Autopay</strong></h5>';
										html += '</div>';
										html += '<div class="col-6 col-md-6 col-xl-3 text-right">';
											html += '<label class="switch text-center ml-2 mb-0">';
												html += '<input type="checkbox" id="enable_autopay" name="is_autopay" '+checked+'>';
												html += '<div class="slider round">';
													html += '<span class="on pr-3">Yes</span>';
													html += '<span class="off ml-1">No</span>';
												html += '</div>';
											html += '</label>';
										html += '</div>';
										html += '<div class="col-12 col-md-12 col-xl-12 mt-4">';
											html += '<label class="fs-12 grey terms_of_autopay">'+terms+'</label>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';

					    	if(response.lease != null) {
			            		html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
						            html += '<div class="border-bottom">';
						                html += '<h5 class="card-title"><span class="fs-22">Monthly Rent</h5> <span class="bold-black">('+response.lease.property.name+')</span>';
						            html += '</div>';
						            html += '<div class="rent_collection_data mt-2">';
						                html += '<div class="row">';
						                	html += '<div class="col-12 col-md-4 col-xl-4">';
											    html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Autopay Date</strong></div>';
						                            html += '<div><select name="autopay_date" id="autopay_date" class="autopay_dd form-control add_opacity_7" style="width:150px;" data-toggle="select2">'+date_html+'</select></div>';
						                        html += '</div>';
											html += '</div>';

											html += '<div class="col-12 col-md-4 col-xl-4">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
						                            html += '<div><select name="autopay_payment_detail_id" id="autopay_payment_detail_id" class="autopay_dd bank_dd form-control" data-toggle="select2">'+bank_html+'</select></div>';
						                        html += '</div>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
						                            html += '<div class="black fs-18"><strong>$ '+response.lease.monthly_rent_amount+'</strong></div>';
						                        html += '</div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
						            html += '<div class="rent_collection_data">';
						                html += '<div class="row">';
						                    html += '<div class="col-12 col-md-6 col-xl-9">';
						                        html += '<h5 class="card-title"><span class="fs-18"><strong>Total</strong></h5>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
						                    	html += '<div class="black fs-18"><strong>$ '+response.lease.monthly_rent_amount+'</strong></div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						    }

						    // html += '<div class="col-auto d-sm-block mb-2 mt-4">';
					        //     html += '<div class="autopay_checkbox">';
					        //         html += '<label class="custom-control custom-checkbox m-0">';
					        //         html += '<input name="autopay_checkbox" id="autopay_authorize_checkbox" type="checkbox" class="custom-control-input">';
					        //         html += '<span class="custom-control-label">'+terms+'</span>';
					        //         html += '</label>';
					        //     html += '</div>';
					        // html += '</div>';
					    html += '</div>';
					html += '</form>';

					$('#autopay_container').html(html);

					$(".autopay_dd").each(function() {
						$(this)
							.wrap("<div class=\"position-relative\"></div>")
							.select2({
								// placeholder: "Select users",
								dropdownParent: $('#enableAutopayModal')
							});
					})

					$('#autopay_date').parent('div').addClass('add_opacity_7');

					addAutopayValidation();
	            }
            }
        }
    });
}

window.loadTableLeaseDetail = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;

	$.ajax({
	    method: 'POST',
	    url: api_url+"leases",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalLeases == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
	            for (var key in response.leases) {
	                if (response.leases.hasOwnProperty(key)) {

	                	var rent_due_date = ordinal_suffix_of(response.leases[key].rent_due_date);
	                	var sd_due_date = '';
	                	var pd_due_date = '&nbsp;';
	                	if(response.leases[key].sd_due_date != null) {
	                		sd_due_date = 'Due on '+response.leases[key].sd_due_date;
	                	}
	                	if(response.leases[key].pd_due_date != null) {
	                		pd_due_date = 'Due on '+response.leases[key].pd_due_date;
	                	}

	                	var status = '';
	                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
	                        status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
	                    } if(response.leases[key].status == 1 || response.leases[key].status == 5) {
	                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
	                    }

	                    var kebab_item = '';

	                    if((user.permissionData['PROPERTIES']["feature_items['lease_edit']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0) || user_service_name == sa_role) {
	                    	kebab_item += '<a class="dropdown-item edit_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#leaseEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
	                    }

	                    if(response.leases[key].tenant == '' && user.permissionData['PROPERTIES']["feature_items['lease_delete']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0) {
	                    	kebab_item += '<a class="dropdown-item delete_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Delete</a>';
	                    }

	                    if(user_service_name == sa_role && response.leases[key].status == 4 && response.leases[key].is_extended == 'No' && response.leases[key].sd_amount > 0 && response.leases[key].is_security_deposit_refunded == 0) {
	                    	kebab_item += '<a class="dropdown-item refund_security_deposit" data-id="'+response.leases[key].id+'" data-sd-amount="'+response.leases[key].security_deposit_amount+'" data-tenants-table-id="'+response.leases[key].tenant_table_ids+'" data-tenant-user-id="'+response.leases[key].tenant_ids+'" data-payment-id="'+response.leases[key].payment_details_id+'" href="javascript:void(0)">Refund Security Deposit</a>';
	                    }

	                    var kebab = '';
	                    if(kebab_item != '') {
	                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	                    }

	                	var add_opacity = '';
	                	if(response.leases[key].tenant == null) {
	                		add_opacity = 'add_opacity';
	                	}

	                	// Card based UI
	                	html += '<div data-id="'+response.leases[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
							html += '<div class="card">';
								html += '<div class="card-header bg-light"><span class="accordion_arrow">'+kebab+' </span><h5 class="card-title capitalize mb-0 mr-3">'+response.leases[key].start_date+' - '+response.leases[key].end_date+'</h5></div>';
								html += '<div class="card-body">';
									html += '<table class="table mb-0 card-table word-break">';
										html += '<tbody>';
											html += '<tr>';
												html += '<td class="nowrap">Monthly Rent</td>';
												html += '<td class="text-right bold-black">$'+response.leases[key].monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Security Deposit</td>';
												html += '<td class="text-right bold-black">$'+response.leases[key].security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Pet Policy</td>';
												html += '<td class="text-right">'+response.leases[key].pet_policy+'</td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Pet Deposit</td>';
												html += '<td class="text-right bold-black">$'+response.leases[key].pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Tenant</td>';
												html += '<td class="text-right">'+response.leases[key].tenant+'</td>';
											html += '</tr>';
										html += '</tbody>';
									html += '</table>';
									html += '<div class="text-right mt-4">'+status+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
	                }
	            }
	        }
            $('#leaseDataContainer').html(html);
            // Get Floating Action Button
			getFloatingActionButton('Lease', '#leaseDataContainer');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.deleteLease = function(lease_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/delete",
        data:'id='+lease_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                openLoadingDialog($('#lease_data_container'));
    			$('#lease_data_container').load('components/property/lease_detail_table.html');

    			getPropertyLeaseWidgetInfo();
            }
        }
    });
}

window.getExistingLeaseDetail = function(lease_ids, lease_id) {
	openLoadingDialog($('#existingLeaseList'));
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&lease_ids='+lease_ids;

	$.ajax({
	    method: 'POST',
	    url: api_url+"leases",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
				var html = '';
				if(response.TotalLeases == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	var html = '';
			    	html += '<ul style="list-style-type:none;">';
			    	for (var key in response.leases) {
		                if (response.leases.hasOwnProperty(key)) {
		                	if(response.leases[key].status == 1) {
		                		var checked = '';
		                		var add_opacity = '';
		                		if(response.leases[key].id == lease_id) { checked = 'checked'; add_opacity = 'add_opacity'; }
						        html += '<li class="float-left '+add_opacity+'">';
						            html += '<label class="custom-control custom-checkbox">';
						            html += '<input type="checkbox" name="existing_lease_checkbox" class="custom-control-input existing_lease_checkbox" data-id="'+response.leases[key].id+'" '+checked+'>';
						            html += '<span class="custom-control-label">'+response.leases[key].start_date+' - '+response.leases[key].end_date+'</span>';
						            html += '</label>';
						        html += '</li>';
							}
		                }
		            }
		            html += '</ul>';
		        }
		        $('#existingLeaseList').html(html);
	        } else {
	            display_error_message(response);
	        }
	    }
	});
}

window.assignLeaseToTenant = function(property_id,lease_id,tenant_id,tenant_lease_array) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenant-leases/add",
        data: 'user_id='+user_id+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_id='+tenant_id+'&tenant_lease_data='+tenant_lease_array,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#leaseListModal').modal('hide');
				//Tenant container
            	openLoadingDialog($('#tenant_data_container'));
	    		$('#tenant_data_container').load('components/property/tenant_detail_table.html');
	    		getPropertyTenantWidgetInfo();

				//Lease Container
				openLoadingDialog($('#lease_data_container'));
	    		$('#lease_data_container').load('components/property/lease_detail_table.html');
	    		getPropertyLeaseWidgetInfo();
            } else {
            	$('.assign_lease_to_tenant').attr('disabled',false);
            }
        }
    });
}

window.getTenantSignedDocument = function(tenant_ids) {
	var user_id = user.user_details.user_id;
	var property_id = $('#selected_property_id').val();
	var first_time_signup = $('#first_time_signup').val();

	$.ajax({
	    method: 'POST',
	    url: api_url+"user-templates",
	    data:'property_id='+property_id+'&tenant_ids='+tenant_ids+'&user_id='+user_id+'&first_time_signup'+first_time_signup,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTemplates == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	var html = '';
		    	for (var key in response.userTemplates) {
	                if (response.userTemplates.hasOwnProperty(key)) {
	                	html += '<div class="card mb-3 bg-light border">';
						    html += '<div class="p-2">';
						        html += '<div class="float-left">';
						            /*html += '<label class="custom-control custom-checkbox">';
						            html += '<input type="checkbox" name="existing_document_checkbox" class="custom-control-input existing_document_checkbox" data-id="'+response.userTemplates[key].id+'">';
						            html += '<span class="custom-control-label text-hide">Checkbox</span>';
						            html += '</label>';*/
						        html += '</div>';
						        html += '<div>'+response.userTemplates[key].zoho_template.zoho_templateName+'</div>';
						    html += '</div>';
						html += '</div>';
	                }
	            }
	        }
	        $('#existingDocumentList').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

/* End : Lease */

/* Start: Tenants */

var $tenantForm;
window.addTenantValidation = function() {
	$tenantForm = $("#tenant_add_form, #tenant_edit_form");
	$tenantForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "tenant_firstname": { required: true },
	        "tenant_lastname": { required: true },
	        "tenant_email": { required: true, email: true }
	    },
	    messages: {
	        "tenant_firstname": { required: '' },
	        "tenant_lastname": { required: '' },
	        "tenant_email": { required: '', email: 'Please enter valid email address' }
	    }
	});
}

window.skipTenant = function() {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/skip",
        data:'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
				$('#tenant_flag').val(1);
				var message_html = '<h3 class="text-center mt-6 green">You Have Skipped This Step.</h3>';
				$('.tenant_detail_media').html(message_html);
				$('.tenant_detail_media').addClass('minHeight100');
				$('#headingFour span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
				$('#headingFour span.accordion_arrow').remove();
				var user = JSON.parse(localStorage.user);
				if($('#lease_flag').val() == 1){
					// user.user_details.steps_left = 1;
					// $('#headingThree span.accordion_edit,#headingFour span.accordion_edit,#headingFive span.accordion_edit').remove();
					// $('#property_detail_accordion,#lease_detail_accordion').addClass('null_pointer');

					// if(signature_enabled == 'zoho_template'){
					// 	$('#contracts_accordion').removeClass('d-none');
					// } else {
					// 	var data = JSON.parse(localStorage.getItem('user'));
					// 	var property_id = data.user_details.first_property_id;
					// 	if(signature_enabled == 'docusign'){
					// 		getContracts(property_id);
					// 	} else if(signature_enabled == 'zoho_document'){
					// 		getZohoDocuments(property_id);
					// 	}
					// }
					// loadCompletedChecklistSteps(user_id, po_role);
					loadDashboardPage();
				} else {
					user.user_details.cheklist_seq_no = 4;
					user.user_details.steps_left = 1;
				}
				localStorage.setItem("user", JSON.stringify(user));
            }
        }
    });
}

window.saveTenantData = function(button_type) {
	if($tenantForm.valid()){
		$('.save_tenant_data').attr('disabled',true);
		$('.save_and_submit_tenant_data').attr('disabled',true);
		$('.save_and_submit_tenant_data').text('Processing...');
		$('.signup_skip_button').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var first_time_signup = $('#first_time_signup').val();
		if(first_time_signup == 1) {
			var data = JSON.parse(localStorage.getItem('user'));
			var property_id = data.user_details.first_property_id;
		} else {
			var property_id = $('#selected_property_id').val();
		}
		var first_name = $('#tenant_firstname').val();
		var last_name = $('#tenant_lastname').val();
		var email = $('#tenant_email').val();
		var module_name = $('#module_name').val();
		var tenant_lease_id = $('#tenant_lease_id').val();
		if(typeof tenant_lease_id === 'undefined') {
			var lease_id = 0;
		} else {
			var lease_id = tenant_lease_id;
		}
		var data = $('#tenant_add_form').serialize()+'&property_id='+property_id+'&lease_id='+lease_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup;
		// var data = 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&property_id='+property_id+'&lease_id='+lease_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup;

        $.ajax({
            method: 'POST',
            url: api_url+"tenants/add",
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
				$('.signup_skip_button').attr('disabled',false);
                $('.save_tenant_data').attr('disabled',false);
                $('.save_and_submit_tenant_data').attr('disabled',false);
				$('.save_and_submit_tenant_data').text('Save');
                if(response.response_data.success == true) {
                	// Below code for initial signup step
					var first_time_signup = $('#first_time_signup').val();
					if(first_time_signup == 1) {
						$('#tenant_flag').val(1);
						var tenant_id = response.tenant_id;
						var data = JSON.parse(localStorage.getItem('user'));
						var property_id = data.user_details.first_property_id;
                    	submitTenantDetail(property_id, tenant_id);
                    	// ConfirmAddTenant('tenant');
						$('#newSignupModal').modal('hide');
						$('.modal-backdrop').remove();

						$('#tenant_detail_accordion').trigger('shown.bs.collapse');
						$('#headingFour span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#headingFour span.accordion_arrow').remove();
						$('#headingFour span.accordion_edit').html('<i class="align-middle mr-2 fas fa-fw fa-edit"></i>');

						var user = JSON.parse(localStorage.user);
						if($('#lease_flag').val() == 1){
							loadDashboardPage();
							// $('#headingThree span.accordion_edit,#headingFour span.accordion_edit,#headingFive span.accordion_edit').remove();
							// $('#property_detail_accordion,#lease_detail_accordion').addClass('null_pointer');

							// if(user.user_details.cheklist_seq_no == 6){
							// 	loadCompletedChecklistSteps(user_id, po_role);
							// } else {
							// 	user.user_details.steps_left = 1;
							// 	if(signature_enabled == 'zoho_template'){
							// 		$('#contracts_accordion').removeClass('d-none');
							// 	} else {
							// 		var data = JSON.parse(localStorage.getItem('user'));
							// 		var property_id = data.user_details.first_property_id;
							// 		if(signature_enabled == 'docusign'){
							// 			getContracts(property_id);
							// 		} else if(signature_enabled == 'zoho_document'){
							// 			getZohoDocuments(property_id);
							// 		}
							// 	}
							// }
							// loadCompletedChecklistSteps(user_id, po_role);

						} else {
							user.user_details.cheklist_seq_no = 4;
							user.user_details.steps_left = 2;
						}

						localStorage.setItem("user", JSON.stringify(user));
					} else {
	                    $('#tenantAddModal').modal('hide');
	                    $('#tenant_detail_container_add, #tenant_detail_container_edit').html('');
						closeRightSidebar();
	                    if(button_type == 'save') {
		                    if(module_name == 'lease') {
		                    	openLoadingDialog($('#lease_data_container'));
			    				$('#lease_data_container').load('components/property/lease_detail_table.html');
		                    } else {
		                    	openLoadingDialog($('#tenant_data_container'));
			    				$('#tenant_data_container').load('components/property/tenant_detail_table.html');
		                    }
		                } else if(button_type == 'save_submit') {
		                	//var tenant_id = response.tenant_id;
	                    	//inviteTenant(property_id, tenant_id, button_type);
		                }

		                getPropertyTenantWidgetInfo();
		            }
                }
            }
        });
    }
}

window.getTenantDetailById = function(tenant_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"tenants/view/"+tenant_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	setTimeout(function(){
            		if(response.tenant.status >= 2) {
	                	$('.update_and_submit_tenant_data').attr('disabled',true);
	                }

					if(response.tenant.is_primary == 1){
						$('#primary_tenant_enabled').trigger('change');
						$('#primary_tenant_enabled').prop('checked', true);
						$('#primary_tenant_enabled').parent('label').addClass('add_opacity');
					} else {
						$('#primary_tenant_enabled').prop('checked', false);
					}

					if(response.tenant.authorize_rent == 1){
						$('#allowed_to_autorize_and_pay_rent').trigger('change');
						$('#allowed_to_autorize_and_pay_rent').prop('checked', true);
					} else{
						$('#allowed_to_autorize_and_pay_rent').prop('checked', false);
					}

					if(response.tenant.disable_authorize_rent == 1){
						$('#allowed_to_autorize_and_pay_rent').prop('checked', false);
						$('#allowed_to_autorize_and_pay_rent').parent('label').addClass('add_opacity');
					} else if(response.tenant.disable_authorize_rent == 2){
						$('#allowed_to_autorize_and_pay_rent').trigger('change');
						$('#allowed_to_autorize_and_pay_rent').prop('checked', true);
						$('#allowed_to_autorize_and_pay_rent').parent('label').addClass('add_opacity');
					}

					if(response.tenant.signature_required == 1){
						$('#allowed_to_sign_contract').prop('checked', true);
					} else {
						$('#allowed_to_sign_contract').trigger('change');
						$('#allowed_to_sign_contract').prop('checked', false);
					}

					if(response.tenant.allow_to_sign_in == 1){
						$('#allowed_to_sign_in').prop('checked', true);
					} else {
						$('#allowed_to_sign_in').prop('checked', false);
					}

        			$('#tenant_id').val(response.tenant.id);
	                $('#tenant_firstname').val(response.tenant.user.first_name);
	                $('#tenant_lastname').val(response.tenant.user.last_name);
	                $('#tenant_email').val(response.tenant.user.email);
					if(response.tenant.user.cognito_sub){
						$('#allowed_to_sign_in').parent('label').addClass('add_opacity');
						$('.exist_tenant_check').removeClass('d-none');
						// $('#tenant_firstname').attr('disabled', true);
						// $('#tenant_lastname').attr('disabled', true);
						// $('#tenant_email').attr('disabled', true);
						$('#tenant_firstname').addClass('add_opacity');
						$('#tenant_lastname').addClass('add_opacity');
						$('#tenant_email').addClass('add_opacity');
						// $('.update_tenant_data').remove();
						// $('.next_invite_tenant_step').removeClass('d-none');
					}
				}, 100);
            }
        }
    });
}

window.getInvitedTenantDetail = function(invitation_code) {
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/get-invited-tenant-detail",
        data:'invitation_code='+invitation_code,
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
        		$('#property_id').val(response.propertyData.id);
        		$('#property_user_id').val(response.propertyData.user.id);
        		$('#tenant_user_id').val(response.tenant.user.id);

        		$('#tenant_id').val(response.tenant.id);
        	    $('#firstname').val(response.tenant.user.first_name);
                $('#lastname').val(response.tenant.user.last_name);
                $('#email_address').val(response.tenant.user.email);

                if(response.propertyData.address != null) {
	                $('#property_address_line1').val(response.propertyData.address.address_line1);
	                $('#property_address_line2').val(response.propertyData.address.address_line2);
	                $('#property_city').val(response.propertyData.address.city);
	                $('#property_state').val(response.propertyData.address.state);
	                $('#property_zip_code').val(response.propertyData.address.zip_code);
	                $('#property_country').val(response.propertyData.address.country);
	            }
            }
        }
    });
}

window.updateTenantData = function(button_type) {
	if($tenantForm.valid()){
		if(button_type == 'invite' && $('#allowed_to_sign_in').prop('checked') == false){
			var response = {
				"response_data": {
					"success": false,
					"message": 'If you want to invite tenant you must have to unable allow sign in.'
				}
			};
			display_error_message(response);
			return false;
		}
		$('.update_tenant_data').attr('disabled',true);
		$('.update_and_submit_tenant_data').attr('disabled',true);

		var tenant_id = $('#tenant_id').val();
		var first_time_signup = $('#first_time_signup').val();
		if(first_time_signup == 1){
			var data = JSON.parse(localStorage.getItem('user'));
			var property_id = data.user_details.first_property_id;
		} else{
			var property_id = $('#selected_property_id').val();
		}
		var first_name = $('#tenant_firstname').val();
		var last_name = $('#tenant_lastname').val();
		var email = $('#tenant_email').val();
		var authorize_payment = $('#allowed_to_autorize_and_pay_rent').prop('checked');
		$('#can_authorize_payment').val(authorize_payment);
		var allow_sign_in = $('#allowed_to_sign_contract').prop('checked');
		$('#tenant_signature_required').val(allow_sign_in);
		var user_id = user.user_details.user_id;

		var data = $('#tenant_edit_form').serialize()+'&property_id='+property_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup+'&user_service_name='+user_service_name;
		// var data = 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&property_id='+property_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup+'&user_service_name='+user_service_name;

		$.ajax({
            method: 'POST',
            url: api_url+"tenants/edit/"+tenant_id,
			data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
            	$('.update_tenant_data').attr('disabled',false);
            	$('.update_and_submit_tenant_data').attr('disabled',false);
                display_error_message(response);
                if(response.response_data.success == true) {
					if(first_time_signup == 1){
						$('#tenant_detail_accordion').trigger('shown.bs.collapse');
					} else {
						if(button_type != 'invite'){
							$('#tenantEditModal').modal('hide');
							$('#tenant_detail_container_add, #tenant_detail_container_edit').html('');
							closeRightSidebar();
							if(button_type == 'save') {
								openLoadingDialog($('#tenant_data_container'));
								$('#tenant_data_container').load('components/property/tenant_detail_table.html');
							} else if(button_type == 'save_submit') {
								//inviteTenant(property_id, tenant_id, button_type);
							}
							getPropertyTenantWidgetInfo();
						} else {
							if(signature_enabled == 'zoho_template'){
								getSignupNextStep(1,2);
								var type = 'PO';
								getInviteTenantZohoTemplateList(type);
							} else {
								if(new_payment_schedule_enabled == 'true' || authorize_payment == false){
									getSignupNextStep(4,5);
									openLoadingDialog($('#invite_tenant_data_container'));
									$('.invite_tenant_title').text('Invite Tenant');
									$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
								} else {
									openLoadingDialog($('#invite_tenant_data_container'));
									setTimeout(function(){
										$('.invite_tenant_title').text('Request first month payment');
										var property_id = $('#selected_property_id').val();
										var property_name = $('#navbar_role_name').text();
										getLeaseListForProperty(property_id, property_name);
										// requestInviteFMPayment(property_id, property_name); //new workflow
									}, 500);
								}
							}
						}
					}
                }
            }
        });
    }
}

window.deleteTenant = function(tenant_id) {
	var user_id = user.user_details.user_id;
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/delete",
        data:'id='+tenant_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                if(first_time_signup == 1){
					$('#tenant_detail_accordion').trigger('shown.bs.collapse');
				} else{
					openLoadingDialog($('#tenant_data_container'));
					$('#tenant_data_container').load('components/property/tenant_detail_table.html');
					getPropertyTenantWidgetInfo();
				}
            }
        }
    });
}

window.submitTenantDetail = function(property_id, tenant_id) {
	var user_id = user.user_details.user_id;
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/submit",
        data: 'property_id='+property_id+'&tenant_id='+tenant_id+'&first_time_signup='+first_time_signup+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	// Below code for initial signup step
				if(first_time_signup != 1) {
					display_error_message(response);
                	openLoadingDialog($('#tenant_data_container'));
    				$('#tenant_data_container').load('components/property/tenant_detail_table.html');
    				getPropertyTenantWidgetInfo();
    			}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.checkAccessCodeExist = function(){
	if(user_service_name == t_role){
		var user_id = user.user_details.user_id;
		$.ajax({
			method: 'GET',
			url: api_url+"tenants/check-access-code-exist/"+user_id,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				// display_error_message(response);
				if(response.response_data.success == true) {
					if(response.tenantData.signature_required && response.tenantData && Object.keys(response.tenantData).length > 0){

						if(response.tenantData.authorize_rent){
							$('#tenant_kyc_detail_accordion').removeClass('d-none');
							$('#bank_details_accordion').removeClass('d-none');
						} else {
							$('#tenant_kyc_detail_accordion').parent('div').remove();
							$('#bank_details_accordion').parent('div').remove();

							setTimeout(function(){
								var property_id = $('#tenant_property_id').val();
								if(property_id != 0){
									getSignupZohoTemplateList(property_id);
								}
							}, 300);
						}

						$('.onboarding_task_to_complete').removeClass('d-none');
						$('#thanks_accordion').parent('div').addClass('d-none').removeClass('d-flex');
						$('#tenant_invite_code').val(response.tenantData.invite_code);
						$('#tenant_invite_code').attr('disabled', true);
						$('.access_code_message').html('You are listed as a tenant in the property address: <b>'+response.tenantData.property_name+'</b>');
						$('#check_invite_code').remove();
						$('#tenant_kyc_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
						$('#tenant_property_id').val(response.tenantData.property_id);
						$('#invite_code_accordion span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
						$('#invite_code_accordion span.accordion_arrow').remove();
					} else {
						if(response.response_data.inquiry_flag == 1){
							$('#thanks_accordion').parent('div').removeClass('d-none').addClass('d-flex');
							$('#thanks_accordion').removeClass('d-none');
							$('#headingInquiry span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
							$('#headingInquiry span.accordion_arrow').remove();
							// $('.textarea_count').remove();
						}
						// else {
						// 	$('.textarea_count').removeClass('d-none');
						// }
						$('.textarea_count').removeClass('d-none');
						$('.onboarding_task_to_complete').addClass('d-none');
						$('#check_invite_code').attr('disabled', false);
						$('#check_invite_code').text('Submit');
						$('#tenant_invite_code').val('');
						$('#tenant_kyc_detail_accordion').addClass('add_opacity_lock null_pointer').removeClass('bg-white').find('span.accordion_arrow').html('<img src="icon/lock.svg" class="filter-8 mr-2 mt-1" style="width: 20px;"/>');
					}
				}
			}
		});
	}
}

window.addAccessCode = function(){
	var user_id = user.user_details.user_id;
	var email = user.user_details.email;
	var invite_code = $('#tenant_invite_code').val();
	var first_name = user.user_details.given_name;
	var last_name = user.user_details.family_name;
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/add-access-code",
		data: 'email='+email+'&invite_code='+invite_code+'&user_id='+user_id+'&first_name='+first_name+'&last_name='+last_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
			if(response.response_data.success == true && response.tenantData && Object.keys(response.tenantData).length > 0) {
				$('#tenant_invite_code').val(response.tenantData.invite_code);
				$('#tenant_invite_code').attr('disabled', true);
				$('.access_code_message').html('You are listed as a tenant in the property address: <b>'+response.tenantData.property_name+'</b>');
				$('#check_invite_code').remove();
				$('#tenant_kyc_detail_accordion').removeClass('add_opacity_lock null_pointer').addClass('bg-white').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
				$('#tenant_property_id').val(response.tenantData.property_id);
				$('#invite_code_accordion span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
				$('#invite_code_accordion span.accordion_arrow').remove();
			} else {
				$('#check_invite_code').attr('disabled', false);
				$('#check_invite_code').text('Submit');
				$('#tenant_invite_code').val(invite_code);
				// $('#tenant_kyc_detail_accordion').addClass('add_opacity_lock null_pointer').removeClass('bg-white').find('span.accordion_arrow').html('<img src="icon/lock.svg" class="filter-8 mr-2 mt-1" style="width: 20px;"/>');
			}
		}
	});
}

window.inviteTenant = function(property_id, tenant_id){
	var user_id = user.user_details.user_id;
	var lease_id = 0;
	if($('#it_selected_lease_id').length > 0) {
		lease_id = $('#it_selected_lease_id').val();
	}
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/invite",
        data: 'property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id+'&lease_id='+lease_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
			$('.rs_admin_invite_tenant').attr('disabled', false);
			$('.rs_admin_invite_tenant').text('Invite');
            if(response.response_data.success == true) {
				$('#inviteTenantModal').modal('hide');
				openLoadingDialog($('#tenant_data_container'));
				$('#tenant_data_container').load('components/property/tenant_detail_table.html');
				getPropertyTenantWidgetInfo();
			}
		}
	});
}

window.loadTableTenantDetail = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;

	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTenants == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.tenants) {
	                if (response.tenants.hasOwnProperty(key)) {

	                	var monthly_rent_amount = '-';
	                	var rent_due_date = '-';
	                	var security_deposit_amount = '-';
	                	var pet_policy = '-';
	                	var pet_deposit_amount = '-';
	                	var start_date = '-';
	                	var end_date = '-';
	                	var sd_due_date = '-';
	                	var pd_due_date = '-';

	                	var kebab_item = '';
	                	if(user.permissionData['PROPERTIES']["feature_items['tenant_edit']"]) {
	                    	kebab_item += '<a class="dropdown-item edit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
	                    }

	                    var status = '';
	                    if(response.tenants[key].application_constant == 'draft') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                        if(user_service_name == po_role) {
	                        	kebab_item += '<a class="dropdown-item submit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Submit</a>';
	                        }
	                    } else if(response.tenants[key].application_constant == 'submitted' || (response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0)) {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                        if(user_service_name == sa_role) {
	                        	var invite_lable = '';
	                        	if(response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0) {
	                        		invite_lable = 'Re-invite Tenant';
	                        	} else {
	                        		invite_lable = 'Invite Tenant';
	                        	}
	                        	kebab_item += '<a class="dropdown-item invite_tenant" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#inviteTenantModal" data-backdrop="static" data-keyboard="false">'+invite_lable+'</a>';
	                        }
	                    } else if(response.tenants[key].application_constant == 'invite_sent') {
	                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'signed_up') {
	                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'tenant_deleted') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_pending') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    }

	                    if(response.tenants[key].lease_status == 4) {
	                    	status = '<span class="badge badge-danger">Lease Expired</span>';
	                    }

	                    if(user.permissionData['PROPERTIES']["feature_items['tenant_delete']"]) {
	                    	if(response.tenants[key].tenant_leases.length <= 0 && response.tenants[key].application_constant != 'tenant_deleted') {
	                    		kebab_item += '<a class="dropdown-item delete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Delete</a>';
                        	}
                        }

                        /*if(user_service_name == sa_role && response.tenants[key].status == 4) {
                    		kebab_item += '<a class="dropdown-item undelete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Un-delete</a>';
                    	}*/

	                    var kebab = kebab_item;

	                    if(response.tenants[key].tenant_leases.length > 0) {
		                	for (var tlkey in response.tenants[key].tenant_leases) {
		                		if (response.tenants[key].tenant_leases.hasOwnProperty(tlkey)) {
		                			var lease = response.tenants[key].tenant_leases[tlkey].lease;
		                			if(lease != null) {
				                		monthly_rent_amount = lease.monthly_rent_amount;
				                		rent_due_date = ordinal_suffix_of(lease.rent_due_date);
				                		security_deposit_amount = lease.security_deposit_amount;
				                		pet_policy = lease.pet_policy_term_label;
				                		pet_deposit_amount = lease.pet_deposit_amount;
				                		start_date = lease.start_date;
				                		end_date = lease.end_date;
				                		sd_due_date = 'Due on '+lease.sd_due_date;
				                		pd_due_date = 'Due on '+lease.pd_due_date;
				                	}

				                	if(response.tenants[key].tenant_leases[tlkey].status == 1) {
				                    	status = '<span class="badge badge-info">Submitted</span>';
				                    }
				                	if(response.tenants[key].tenant_leases[tlkey].status == 4) {
				                    	status = '<span class="badge badge-danger">Lease Expired</span>';
				                    }
				                    if(response.tenants[key].tenant_leases[tlkey].status == 5) {
				                    	status = '<span class="badge badge-primary">Pending signature</span>';
				                    }

				                	// Card based UI
				                	html += '<div data-id="'+response.tenants[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
										html += '<div class="card">';
											html += '<div class="card-header bg-light"><span class="accordion_arrow"><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></span><h5 class="card-title capitalize mb-0 mr-3">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><small>'+response.tenants[key].user.email+'</small></div>';
											html += '<div class="card-body">';
												html += '<table class="table mb-0 card-table word-break">';
													html += '<tbody>';
														html += '<tr>';
															html += '<td class="nowrap">Lease Start Date</td>';
															html += '<td class="text-right">'+start_date+'</td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Lease End Date</td>';
															html += '<td class="text-right">'+end_date+'</td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Monthly Rent</td>';
															html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Security Deposit</td>';
															html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Pet Policy</td>';
															html += '<td class="text-right">'+pet_policy+'</td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Pet Deposit</td>';
															html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap fs-14">';
																if(user.permissionData['PROPERTIES']["feature_items['tenant_link_lease']"]) {
						                                        	html += '<a href="#" data-lease-id="'+response.tenants[key].lease_ids+'" data-id="'+response.tenants[key].id+'" class="list_existing_lease" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
						                                        }
															html += '</td>';
															html += '<td class="text-right fs-14">&nbsp;</td>';
														html += '</tr>';
													html += '</tbody>';
												html += '</table>';
												html += '<div class="text-right mt-4">'+status+'</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
							    }
							}
						} else {
							// Card based UI

							html += '<div data-id="'+response.tenants[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
								html += '<div class="card">';
									html += '<div class="card-header bg-light"><span class="accordion_arrow"><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></span><h5 class="card-title capitalize mb-0 mr-3">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><small>'+response.tenants[key].user.email+'</small></div>';
									html += '<div class="card-body">';
										html += '<table class="table mb-0 card-table word-break">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td class="nowrap">Lease Start Date</td>';
													html += '<td class="text-right">'+start_date+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Lease End Date</td>';
													html += '<td class="text-right">'+end_date+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Monthly Rent</td>';
													html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Security Deposit</td>';
													html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Pet Policy</td>';
													html += '<td class="text-right">'+pet_policy+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Pet Deposit</td>';
													html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap fs-14">';
														html += '<a href="#" data-lease-id="'+response.tenants[key].lease_ids+'" data-id="'+response.tenants[key].id+'" class="list_existing_lease" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
													html += '</td>';
													html += '<td class="text-right fs-14">&nbsp;</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
										html += '<div class="text-right mt-4">'+status+'</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						}
	                }
	            }
	        }
            $('#tenantDataContainer').html(html);
            // Get Floating Action Button
			getFloatingActionButton('Tenant', '#tenantDataContainer');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getExistingTenantDetail = function(tenant_ids) {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&tenant_ids='+tenant_ids+'&user_id='+user_id;

	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTenants == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	var html = '';
		    	for (var key in response.tenants) {
	                if (response.tenants.hasOwnProperty(key)) {
	                	html += '<div class="col-12">';
		                	html += '<div class="card mb-3 bg-light border">';
							    html += '<div class="p-2">';
							        html += '<div class="float-left">';
							            html += '<label class="custom-control custom-checkbox">';
							            html += '<input type="checkbox" name="existing_tenant_checkbox" class="custom-control-input existing_tenant_checkbox" data-id="'+response.tenants[key].id+'">';
							            html += '<span class="custom-control-label text-hide">Checkbox</span>';
							            html += '</label>';
							        html += '</div>';
									html += '<div>'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+' ('+response.tenants[key].user.email+')</div>';
							    html += '</div>';
							html += '</div>';
						html += '</div>';
	                }
	            }
	        }
	        $('#existingTenantList').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.assignTenantToLease = function(property_id,lease_id,tenant_id,tenantArray) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenant-leases/add",
        data: 'user_id='+user_id+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_id='+tenant_id+'&tenant_lease_data='+tenantArray,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#tenantListModal').modal('hide');
				// Lease Container
            	openLoadingDialog($('#lease_data_container'));
	    		$('#lease_data_container').load('components/property/lease_detail_table.html');
	    		getPropertyLeaseWidgetInfo();

				// Tenant Container
				openLoadingDialog($('#tenant_data_container'));
	    		$('#tenant_data_container').load('components/property/tenant_detail_table.html');
	    		getPropertyTenantWidgetInfo();
            } else {
            	$('.assign_tenant_to_lease').attr('disabled', false);
            }
        }
    });
}

/* End : Tenant */

window.getDefaultPlanDetail = function() {
	$.ajax({
        method: 'POST',
		data: 'user_id='+user_id,
        url: api_url+"plans/get-default-plan-detail",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
				$('#property_plan_id').val(response.plan.id);
				$('#property_setup_fee_input, #property_setup_fee_subtotal_input, #property_setup_fee_total_input').val(response.plan.property_setup_fee);
				$('#property_setup_fee_subtotal_container, #property_setup_fee_total_container').html('$'+response.plan.property_setup_fee_formatted);
				$('#default_property_setup_fee_container').html('$'+response.plan.property_setup_fee_formatted);

				var fts = $('#first_time_signup').val();
				if(fts == 1) {
					var trigger_from = "apply_button_clicked";
					var coupon_code = $('#coupon_code').val();
					if(coupon_code != ''){
						applyCouponCode('property_setup_fee', coupon_code, trigger_from);
					}
				} else {
					// start #454: Apply Default coupon code : NOSETUPFEE
					if(apply_default_coupon_code == true) {
						var trigger_from = "apply_button_clicked";
						applyCouponCode('property_setup_fee', '', trigger_from);
						$('#apply_coupon_code').remove();
					}
					// end #454: Apply Default coupon code : NOSETUPFEE
				}
            } else {
            	display_error_message(response);
            }
        }
    });
}

var $propertyForm;
window.addPropertyValidation = function() {
	$propertyForm = $("#property_add_form");
	$propertyForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "add_property_address_line1": { required: true },
	        "add_property_city": { required: true },
	        "add_property_state": { required: true },
	        "add_property_zip_code": { required: true },
	        "add_property_country": { required: true },
			//"bank_property_dd": { required: true },
	    },
	    messages: {
	        "add_property_address_line1": { required: '' },
	        "add_property_city": { required: '' },
	        "add_property_state": { required: '' },
	        "add_property_zip_code": { required: '' },
	        "add_property_country": { required: '' },
			//"bank_property_dd": { required: '' },
	    }
	});
}

var $editPropertyForm;
window.editPropertyValidation = function() {
	$editPropertyForm = $("#property_edit_form");
	$editPropertyForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "edit_property_address_line1": { required: true },
	        "edit_property_city": { required: true },
	        "edit_property_state": { required: true },
	        "edit_property_zip_code": { required: true },
	        "edit_property_country": { required: true },
	    },
	    messages: {
	        "edit_property_address_line1": { required: '' },
	        "edit_property_city": { required: '' },
	        "edit_property_state": { required: '' },
	        "edit_property_zip_code": { required: '' },
	        "edit_property_country": { required: '' },
	    }
	});
}

var $propertyContactForm;
window.addPropertyContactValidation = function() {
	$propertyContactForm = $("#property-primary-contact-address");
	$propertyContactForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "primary_contact_firstname": { required: true },
	        "primary_contact_lastname": { required: true },
	        "primary_contact_businessname": { required: true },
	        "primary_contact_address_line1": { required: true },
	        "primary_contact_city": { required: true },
	        "primary_contact_state": { required: true },
	        "primary_contact_zip_code": { required: true },
	        "primary_contact_country": { required: true },
	    },
	    messages: {
	    	"primary_contact_firstname": { required: '' },
	        "primary_contact_lastname": { required: '' },
	        "primary_contact_businessname": { required: '' },
	        "primary_contact_address_line1": { required: '' },
	        "primary_contact_city": { required: '' },
	        "primary_contact_state": { required: '' },
	        "primary_contact_zip_code": { required: '' },
	        "primary_contact_country": { required: '' },
	    }
	});
}

window.savePropertyData = function() {
	$('.save_property_data').attr('disabled', true);
	var property_name =  $('#add_property_address_line1').val();
    $.ajax({
        method: 'POST',
        url: api_url+"properties/add",
        data: getPropertyFileObj(),
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var property_id = response.property_id;

            	// Below code for initial signup step
				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup != 1) {
					var layout = $('#selected_layout').val();
					$('#propertyAddModal').modal('hide');
	            	$('.modal-backdrop').remove();
					$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
					openLoadingDialog($('#main_container'));
	            	if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}
	            } else {
	            	$('#first_property_id').val(property_id);
	            	var user = JSON.parse(localStorage.user);
					user.user_details.first_property_id = property_id;
					user.user_details.first_property_name = property_name;
					user.user_details.cheklist_seq_no = 3;
					user.user_details.steps_left = 3;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back

					var message_html = '<h3 class="text-center mt-6 green">Property Added Successfully.</h3>';
					$('.property_detail_media').html(message_html);
					$('.property_detail_media').addClass('minHeight100');
					$('#headingThree span.accordion_verfiy').html('<img src="icon/verified.svg" class="align-middle mr-2 filter-13 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="Success" />');
                    $('#headingThree span.accordion_arrow').remove();
                    $('#headingThree span.accordion_edit').html('<i class="align-middle mr-2 fas fa-fw fa-edit"></i>');

					$('#tenant_detail_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
                    $('#tenant_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
                    $('#lease_detail_accordion').removeClass('null_pointer').addClass('bg-white').removeClass('add_opacity_lock');
                    $('#lease_detail_accordion').find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
	            }

                // checkIfPropertySetupPlanAvailable(property_id);
            } else {
            	$('.save_property_data').attr('disabled', false);
            	$('.save_property_data').text('Add Property');
            }
        }
    });
}

window.updatePropertyAddress = function() {
	var property_id = $('#edit_property_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"properties/edit/"+property_id,
        data: getEditPropertyFileObj(),
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
			$('.update_property_address').attr('disabled', false);
			$('.update_property_address').text('Update');
            if(response.response_data.success == true) {
				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1){
					// loadDashboardPage()
					var message_html = '<h3 class="text-center mt-6 green">Property Updated Successfully.</h3>';
					$('.property_detail_media').html(message_html);
					$('.property_detail_media').addClass('minHeight100');
				} else {
					$('#propertyEditModal').modal('hide');
					$('.modal-backdrop').remove();
					var layout = $('#selected_layout').val();
					if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}
					$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
				}
            }
        }
    });
}

window.getPropertyFileObj = function() {
	var first_time_signup = $('#first_time_signup').val();
	var user_id = user.user_details.user_id;
	var address_line1 = $('#add_property_address_line1').val();
	var address_line2 = $('#add_property_address_line2').val();
	var formData = new FormData();
	formData.append('first_time_signup', first_time_signup);
    formData.append('user_id', user_id);
    //formData.append('name', $('#add_property_name').val());
    formData.append('name', address_line1+' '+address_line2);
    formData.append('address_line1', $('#add_property_address_line1').val());
    formData.append('address_line2', $('#add_property_address_line2').val());
    formData.append('city', $('#add_property_city').val());
    formData.append('state', $('#add_property_state').val());
    formData.append('zip_code', $('#add_property_zip_code').val());
    formData.append('country', $('#add_property_country').val());
    formData.append('usps_verified', 1);

    // Upload Property files
    for (var x = 0; x < addPropertyImageDropzone.files.length; x++) {
        formData.append("attachment[]", addPropertyImageDropzone.files[x]);
    }

    formData.append('plan_id', $('#property_plan_id').val());
    formData.append('coupon_id', $('#property_coupon_id').val());
	formData.append('coupon_amount', $("#coupon_amount").val());
    formData.append('coupon_code', $('#coupon_code').val());
    formData.append('price', $('#property_setup_fee_input').val());
    formData.append('subtotal', $('#property_setup_fee_subtotal_input').val());
    formData.append('tax_percentage',0.00);
    formData.append('tax_amount', 0);
    formData.append('total', $('#property_setup_fee_total_input').val());
	formData.append('payment_agree_status', 1);

	var is_authorized = 0;
	if($('#property_bank_authorize_checkbox').is(":checked")) {
		is_authorized = 1;
	}
	formData.append('is_authorized', is_authorized);
	formData.append('payment_details_id', $('.bank_property_dd').val());
	formData.append('type', 'property_setup_fee');

	var is_already_leased = 0;
	if($('#property_leased_checkbox:checked').val() == 'on') {
		is_already_leased = 1;
	}
	formData.append('is_already_leased', is_already_leased);

    return formData;
}

window.getEditPropertyFileObj = function() {
	var formData = new FormData();
	var user_id = user.user_details.user_id;
    formData.append('series_llc_name', $('#edit_property_name').val());
	formData.append('contract_landlord_name', $('#contract_landlord_name').val());
	formData.append('name', $('#edit_property_address_line1').val()+' '+$('#edit_property_address_line2').val());
    formData.append('address_line1', $('#edit_property_address_line1').val());
    formData.append('address_line2', $('#edit_property_address_line2').val());
    formData.append('city', $('#edit_property_city').val());
    formData.append('state', $('#edit_property_state').val());
    formData.append('zip_code', $('#edit_property_zip_code').val());
    formData.append('country', $('#edit_property_country').val());
    formData.append('type','card_edit');
    formData.append('usps_verified', 1);

    // Upload Property files
    for (var x = 0; x < editPropertyImageDropzone.files.length; x++) {
        formData.append("attachment[]", editPropertyImageDropzone.files[x]);
    }

    formData.append('user_id', user_id);
    return formData;
}

window.updatePropertyCoverImage = function(attachment_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
	    method: 'POST',
	    url: api_url+"properties/update-cover-image",
		data:'attachment_id='+attachment_id+'&user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		display_error_message(response);
	    if(response.response_data.success == true) {

	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.getPropertyUserList = function(property_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"property-users",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
            var html = "";
            html += '<table id="property-user-datatable" class="table table-striped" style="width:100%">';
            html += '<tbody>';
	            if(response.totalUsers == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
		            for (var key in response.propertyUsers) {
		                if (response.propertyUsers.hasOwnProperty(key)) {
		                	//var user_name = response.propertyUsers[key].user.first_name+' '+response.propertyUsers[key].user.last_name;
		                	var user_name = response.propertyUsers[key].user.first_name;
		                	html += '<tr><td><label class="custom-control custom-checkbox"><input name="property_user_name" type="checkbox" class="custom-control-input" data-user-id="'+response.propertyUsers[key].user_id+'" data-username="'+user_name+'"><span class="custom-control-label">'+user_name+'</span></label></td></tr>';
		                }
		            }
		        }
            html += '</tbody>';
            html += '</table>';
            $('#property_user_container').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.deleteProperty = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/delete",
        data: "id="+property_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                var page_name = localStorage.getItem('page_name');
                $('#main_container').load('components/'+page_name+'.html');
            }
        }
    });
}

window.deletePropertyImage = function(attachment_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/change-image",
        data:'attachment_id='+attachment_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                // $('#user_property_media_'+attachment_id).remove();
                // $('#user_property_attachment_'+attachment_id).remove();
                // openLoadingDialog($('#document_data_container'));
	    		// $('#document_data_container').load('components/property/document_detail.html');
	    		// getPropertyPhotoWidgetInfo();

				getPropertyDocumentWidgetInfo();
            }
        }
    });
}

window.unDeletePropertyImage = function(attachment_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/undelete",
        data:'attachment_id='+attachment_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                // openLoadingDialog($('#document_data_container'));
	    		// $('#document_data_container').load('components/property/document_detail.html');

				getPropertyDocumentWidgetInfo();
            }
        }
    });
}

window.loadChatroomData = function(user_id,property_id) {
	$.ajax({
	    method: 'POST',
	    url: api_url+"chatrooms",
	    data:'property_id='+property_id+'&logged_in_user='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    if(response.response_data.success == true) {
	    	var html = "";
	    	var i = 0;
	    	if(response.totalChatrooms == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		        for (var key in response.chatrooms) {
		        	if (response.chatrooms.hasOwnProperty(key)) {
		        		var chatroom_name = response.chatrooms[key].name;
		                html += '<a data-id="'+response.chatrooms[key].id+'" href="javascript:void(0)" class="list-group-item list-group-item-action border-0 chatroom_list"><div class="media"><img src="icon/avatar.jpg" class="rounded-circle mr-1" width="50" height="50"><div class="media-body ml-3 mt-3"><strong id="chatroom-'+response.chatrooms[key].id+'">'+chatroom_name+'</strong></div></div></div></a>';
		            }
		        }
		    }
		    $('#chatroom_info_container').html(html);
	    } else {
	       display_error_message(response);
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.saveChatroomData = function(user_id,property_id,name,chatroom_users) {
	var property_name = $('.property_name_span').html();
	$.ajax({
        method: 'POST',
        url: api_url+"chatrooms/add",
        data: 'user_id='+user_id+'&property_id='+property_id+'&name='+name+'&chatroom_users='+chatroom_users,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var chatroom_id = response.chatroom_id;
            	$('#chatroomAddModal').modal('hide');
            	$('#main_container').load('components/chat.html', function() {
			    	$('#chatroom_property_id').val(property_id);
			    	$('.property_name_span').html(property_name);
			    	loadChatroomData(user_id,property_id);
				});
            }
        }
    });
}

window.getChatroomMessage = function(chatroom_id) {
	$('.chat_info_container_right').removeClass('add_opacity');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"conversations",
        data: 'chatroom_id='+chatroom_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('.chatroom_name').text($('#chatroom-'+chatroom_id).text());
            	$('#chatroom_id').val(chatroom_id);
            	var html = "";
            	if(response.totalConversations == 0) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
	            	for (var key in response.conversations) {
				        if (response.conversations.hasOwnProperty(key)) {
				        	var user_label = '';
				        	var align_class = '';
				        	var message = response.conversations[key].message;
				        	if(user.user_details.user_id == response.conversations[key].user_id) {
				        		user_label = 'You';
				        		align_class = 'chat-message-right';
				        	} else {
				        		user_label = response.conversations[key].user.first_name+' '+response.conversations[key].user.last_name;
				        		align_class = 'chat-message-left';
				        	}
				        	html += '<div class="'+align_class+' pb-4"> <div> <img src="icon/avatar.jpg" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"> <div class="text-muted small text-nowrap mt-2">&nbsp;</div> </div> <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3"> <div class="font-weight-bold mb-1">'+user_label+'</div>'+message+' <div class="text-muted small text-nowrap mt-2">'+response.conversations[key].converted_created+'</div></div></div>';
				        }
				    }
				}
			    $('#chat-messages').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.saveChatroomMessage = function(user_id,chatroom_id,chatroom_message) {
	$.ajax({
        method: 'POST',
        url: api_url+"conversations/add",
        data: 'user_id='+user_id+'&chatroom_id='+chatroom_id+'&message='+chatroom_message,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#chatroom_message').val('');
            	//getChatroomMessage(chatroom_id);
            	$('#chat-messages').scrollTop($('#chat-messages')[0].scrollHeight);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.loadTicketList = function(layout) {

	clearTimeout(typingTimer);

	if ($.fn.DataTable.isDataTable("#ticket-datatable")) {
        $("#ticket-datatable").DataTable().destroy();
    }

	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var user_id = user.user_details.user_id;

	var data = 'user_id='+user_id+'&user_service_name='+user.user_details.service_constant+'&user_timezone='+timezone;

	$.ajax({
	    method: 'POST',
	    url: api_url+"tickets",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	            var html = '';
            	for (var key in response.tickets) {
	                if (response.tickets.hasOwnProperty(key)) {
	                	var submitted_by_email = response.tickets[key].user.email;
	                	var submitted_by = response.tickets[key].user.first_name+' '+response.tickets[key].user.last_name;
	                	var status = '';
	                    var del_btn = '';
	                    var cls = '';
	                    if(response.tickets[key].status == 0) {
	                        status += '<span class="badge badge-info">Draft</span>';
	                        if(user.permissionData['SUPPORT']["feature_items['delete']"]) {
	                        	del_btn += '<i class="align-middle mr-2 fas fa-fw fa-trash fs-16 delete_ticket_data" data-id="'+response.tickets[key].id+'"></i>';
	                        }
	                        if(response.tickets[key].title == '' || response.tickets[key].title == null) {
	                        	cls = 'ticket_table_row pointer';
	                        }
	                    } else if(response.tickets[key].status == 1) {
	                    	status += '<span class="badge badge-primary">Open</span>';
	                    } else if(response.tickets[key].status == 2) {
	                        status += '<span class="badge badge-danger ticket_resolved">Resolved</span>';
	                    } else if(response.tickets[key].status == 3) {
	                        status += '<span class="badge badge-danger ticket_closed">Closed</span>';
	                    } else if(response.tickets[key].status == 4) {
	                        status += '<span class="badge badge-success">Re-open</span>';
	                    }

	                    var property_name = '';
		                if(response.tickets[key].property != null) {
		                	property_name = '<small class="grey pl-1">('+response.tickets[key].property.name+')</small>';
		                }

		                var title = "";
		                if(response.tickets[key].title != null) {
		                	title = response.tickets[key].title;
		                }

		                var bg_color = '';
		                if(user_service_name != sa_role && response.tickets[key].is_shared == 1) {
		                	bg_color = 'bg-light';
		                }

		                var checked = '';
		                if(response.tickets[key].is_shared == 1) {
		                	checked = 'checked';
		                }

		                html += '<tr class="'+cls+'" data-id="'+response.tickets[key].id+'">';
							html += '<td>';
								html += '<div class="row card ticket_card '+bg_color+'">';
					                html += '<div class="row justify-content-center">';
					                    html += '<div class="col-12 col-md-12 col-lg-12">';
					                        html += '<div class="card-body text-left">';
					                        	html += '<div class="bold-black fs-18 mb-2" id="ticket-title-'+response.tickets[key].id+'"><a href="javascript:void(0)" class="bold-black ticket_table_row pointer" data-id="'+response.tickets[key].id+'">'+title+'</a>'+property_name+'&nbsp;'+del_btn+'</div>';
					                        	html += '<div class="grey">';
					                        		if(user_service_name != sa_role && response.tickets[key].is_shared == 1) {
					                        			html += '<small>#'+response.tickets[key].id+' opened on '+response.tickets[key].converted_created+' by '+submitted_by+' ('+submitted_by_email+')</small>&nbsp;&nbsp;<small class="green">(Shared)</small>';
					                        		} else {
					                        			html += '<small>#'+response.tickets[key].id+' opened on '+response.tickets[key].converted_created+' by '+submitted_by+' ('+submitted_by_email+')</small>';
					                        		}
					                        		html += '<small class="ml-2">'+status+'</small>';
					                        		if(user_service_name == sa_role && response.tickets[key].status > 0) {
					                        			if(response.tickets[key].user_type == po_role) {
					                        				html += '<div class="custom-control custom-switch inline-flex mt-2 fs-14"><input type="checkbox" class="custom-control-input share_with_toggle" id="share_with_tenant_'+response.tickets[key].id+'" data-module="ticket" data-id="'+response.tickets[key].id+'" '+checked+'><label class="custom-control-label bold-black" for="share_with_tenant_'+response.tickets[key].id+'">Share with tenant</label></div>';
					                        			}
					                        		}
					                        	html += '</div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					            html += '</div>';
							html += '</td>';
						html += '</tr>'
	                }
	            }

		        $('#ticket_table_row').html(html);
		        var ticket_table = $("#ticket-datatable").DataTable({
					ordering: false
                });
                $("#ticket-datatable tbody tr td:first").addClass('border-top');
		        getFloatingActionButton('Ticket','#ticketDataContainer');
	        } else {
	            display_error_message(response);
	        }
	    }
	});
}

window.loadTicketDetail = function(ticket_id, status) {
	clearTimeout(typingTimer);
	var tz = jstz.determine();
	var timezone = tz.name();

	var user = JSON.parse(localStorage.getItem('user'));
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tickets/view/"+ticket_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	        	$('#ticket_id').val(ticket_id);
	            var property_id = response.ticket.property_id;
				if(response.ticket.property != null){
					var property_name = response.ticket.property.name;
				}
	            var title = response.ticket.title;
	            var description = response.ticket.description;
	            var ticket_user_name = response.ticket.user.first_name+' '+response.ticket.user.last_name;
	            var created = response.ticket.created;
	            var converted_created = response.ticket.converted_created;
	            var status = response.ticket.status;

	            var status_label = '';
	            if(status == 0) {
	            	status_label = '<span class="badge badge-info">Draft</span>';
	            } else if(status == 1) {
	            	status_label = '<span class="badge badge-primary">Open</span>';
	            } else if(status == 2) {
	            	status_label = '<span class="badge badge-primary">Resolved</span>';
	            } else if(status == 3) {
	            	status_label = '<span class="badge badge-danger">Closed</span>';
	            } else if(status == 4) {
	            	status_label = '<span class="badge badge-success">Re-open</span>';
	            }

	            $('#ticket_modal_heading').html('#'+ticket_id+' '+title);

	            if(status == 0) {
	            	$('#ticketEditAccordion').removeClass('d-none');
	            	$('#ticket_edit_form').load('components/ticket/draft_ticket.html', function(){
	            		$('#ticket_modal_heading').html('Draft Ticket');
	            		$('#tickets-title').val(title);
	            		if(description != null){
	            			desc_editor.value(description);
	            		}
	            		$('#add_ticket_id').val(ticket_id);
	            		$('#ticket_property_id').val(response.ticket.property_id);
	            		if(user_service_name == sa_role) {
		            		if(property_id > 0) {
		            			setTimeout(function(){
							        getPropertyRelatedUsers(property_id, response.ticket.to_whom);
							    }, 2000);
						    } else {
						    	$('#to_whom_container').html('');
						    }
						}
						$('.current_status').html(status_label);
	                });
	            } else if(status == 1) { // Open
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	if(response.ticket.user_id == user_id || user_service_name == sa_role) {
	            		$('.resolve_ticket_comment').css('display','inline-block');
	            	} else {
	            		$('.resolve_ticket_comment').css('display','none');
	            	}
	            	$('.reopen_ticket_comment').css('display','none');
	            	/*if(user_service_name == sa_role) {
	            		$('.close_ticket_comment').css('display','inline-block');
	            	} else {
	            		$('.close_ticket_comment').css('display','none');
	            	}*/
	            	$('.close_ticket_comment').css('display','none');
	            	$('.current_status').html(status_label);
	            } else if(status == 2) { // Resolved
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	$('.resolve_ticket_comment').css('display','none');
	            	$('.reopen_ticket_comment').css('display','none');
	            	if(user_service_name == sa_role) {
	            		$('.close_ticket_comment').css('display','inline-block');
	            	} else {
	            		$('.close_ticket_comment').css('display','none');
	            	}
	            	$('.current_status').html(status_label);
	            } else if(status == 3) { // Closed
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	$('.resolve_ticket_comment').css('display','none');
	            	if(response.ticket.user_id == user_id) {
	            		$('.reopen_ticket_comment').css('display','inline-block');
	            	} else {
	            		$('.reopen_ticket_comment').css('display','none');
	            	}
	            	$('.close_ticket_comment').css('display','none');
	            	$('.current_status').html(status_label);
	            } else if(status == 4) { // Re-open
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	if(response.ticket.user_id == user_id) {
	            		$('.resolve_ticket_comment').css('display','inline-block');
	            	} else {
	            		$('.resolve_ticket_comment').css('display','none');
	            	}
	            	$('.reopen_ticket_comment').css('display','none');
	            	/*if(user_service_name == sa_role) {
	            		$('.close_ticket_comment').css('display','inline-block');
	            	} else {
	            		$('.close_ticket_comment').css('display','none');
	            	}*/
	            	$('.close_ticket_comment').css('display','none');
	            	$('.current_status').html(status_label);
	            } else {
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	$('.current_status').html(status_label);
	            }

				if(status != 0) {
					var ticket = response.ticket;
					var is_shared = response.ticket.is_shared;
					getCommentList(ticket, ticket_id, title, ticket_user_name, description, converted_created, created, is_shared);

					// Validation code for shared ticket.
					if(user_service_name == t_role && is_shared == 1) { // For tenants
						$('#add_new_comment_container, #property_detail_footer').remove();
					}
				}

	        } else {
	            display_error_message(response);
	        }

	        getFloatingActionButton('TicketDetail', '#main_container');
	    }
    });
}

window.getCommentList = function(ticket, ticket_id, title, ticket_user_name, description, converted_created, created, is_shared) {
	// Get selected ticket comment list
    $('#comment_section').html('');

    var ticket_data = "";

	if(ticket.property){
    	ticket_data += '<div class="col-12 col-lg-12 text-center"><div class="card-header mb-3"><h4 class="mb-0 comment-title">Property Name : '+ticket.property.name+'</h4></div></div>';
	}

	// ticket detail
	//ticket_data += "<div class='col-12 col-lg-12 d-flex'><h1 class='h3 ml-3 mr-3 mb-4'>#"+ticket_id+' '+title+"</h1></div>";
	ticket_data += '<div class="col-12 col-lg-12 d-flex"> <div class="card shadow flex-fill w-100"> <div class="card-header bg-light"><h5 class="mb-0 comment-title">'+ticket_user_name+'&nbsp;<small class="fs-12">('+ticket.user.email+') opened this ticket on '+converted_created+' </small></h5></div> <div id="ticket_description" class="p-4">'+description+'</div> </div> </div>';

	$('#comment_section').append(ticket_data);
	parse_markdown('ticket_description');

	var tz = jstz.determine();
	var timezone = tz.name();
	var user = JSON.parse(localStorage.getItem('user'));
	var user_id = user.user_details.user_id;

    $.ajax({
        method: 'POST',
        url: api_url+"comments",
        data: 'module_id='+ticket_id+'&module_name=tickets&user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	if(response.response_data.success == true) {
    		var event_cnt = 0;
    		var timeline_key = 0;
    		for (var key in response.comments) {
	            if (response.comments.hasOwnProperty(key)) {
					var comment_data = "";
					var user_name = response.comments[key].user.first_name+' '+response.comments[key].user.last_name;

            		var checked = '';
	                if(response.comments[key].allow_share == 1) {
	                	checked = 'checked';
	                }

					if(response.comments[key]['event'] == 'comment'){
						event_cnt = 0;
						var next_record = parseInt(key) + 1;
						if (response.comments.hasOwnProperty(next_record)) {
							var mb_0 = '';
							if(response.comments[next_record]['event'] != 'comment') {
								mb_0 = 'mb-0';
							}
						}

						comment_data += '<div id="comment_container_'+response.comments[key].id+'" class="col-12 col-lg-12 d-flex comment_container">';
							comment_data += '<div class="card flex-fill w-100 shadow '+mb_0+'">';
								comment_data += '<div class="card-header bg-light">';
									if(response.comments[key].user_id == user_id && (localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1)) {
										comment_data += '<div class="card-actions float-right disable_action">';
											comment_data += '<div class="dropdown show">';
												comment_data += '<a href="#" data-toggle="dropdown" data-display="static"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal align-middle"> <circle cx="12" cy="12" r="1"></circle> <circle cx="19" cy="12" r="1"></circle> <circle cx="5" cy="12" r="1"></circle> </svg> </a>';
												comment_data += '<div class="dropdown-menu dropdown-menu-right">';
													comment_data += '<a class="dropdown-item edit_comment" href="javascript:void(0)" data-id="'+response.comments[key].id+'">Edit</a>';
													comment_data += '<a class="dropdown-item delete_comment" href="javascript:void(0)" data-id="'+response.comments[key].id+'">Delete</a>';
												comment_data += '</div>';
											comment_data += '</div>';
										comment_data += '</div>';
									}
									comment_data += '<h5 class="mb-0 comment-title">'+user_name+'&nbsp;<small class="fs-12">('+response.comments[key].user.email+') commented on '+response.comments[key].converted_created+' </small></h5>';
								comment_data += '</div>';
								comment_data += '<div id="comment_description_'+response.comments[key].id+'" class="p-4">'+response.comments[key].comment+'</div>';
								//if(user_service_name == sa_role && (response.comments[key].user_type == sa_role || response.comments[key].user_type == po_role)) {
								if(user_service_name == sa_role && (ticket.user_type == po_role || response.comments[key].user_type == po_role)) {
									comment_data += '<div class="comment_share_toggle pt-1 pl-4">';
										comment_data += '<div class="custom-control custom-switch inline-flex mt-2 fs-14"><input type="checkbox" class="custom-control-input share_with_toggle" id="share_comment_with_tenant_'+response.comments[key].id+'" data-module="comment" data-id="'+response.comments[key].id+'" '+checked+'><label class="custom-control-label bold-black" for="share_comment_with_tenant_'+response.comments[key].id+'">Share with tenant</label></div>';
									comment_data += '</div>';
								}
							comment_data += '</div>';
						comment_data += '</div>';

						$('#comment_section').append(comment_data);
                		parse_markdown('comment_description_'+response.comments[key].id);
					} else {
						if(event_cnt == 0) {
							var ul_data = '<ol id="custom_timeline_'+key+'" class="custom_timeline"></ol>';
							$('#comment_section').append(ul_data);
							timeline_key = key;
						}

						var event_icon = '';
						if(response.comments[key]['event'] == 'role_event') {
							event_icon = '<img src="icon/activity/role.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'dwolla_event') {
							event_icon = '<img src="icon/activity/dwolla.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'payment_event') {
							event_icon = '<img src="icon/activity/payment.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'property_event') {
							event_icon = '<img src="icon/activity/property.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'coupon_event') {
							event_icon = '<img src="icon/activity/coupon.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'tenant_event') {
							event_icon = '<img src="icon/activity/tenant.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'lease_event') {
							event_icon = '<img src="icon/activity/lease.svg" class="filter-grey">';
						} else if(response.comments[key]['event'] == 'sign_event') {
							event_icon = '<img src="icon/activity/signature.svg" class="filter-grey">';
						}

						var li_data = '';
						li_data += '<li class="custom-timeline-item">';
							li_data += '<span class="timeline-item-icon | faded-icon">';
								li_data += event_icon;
							li_data += '</span>';
							li_data += '<div class="timeline-item-description">';
								//li_data += '<i class="avatar | small"><img src="icon/avatar.jpg" /></i>';
								li_data += '<span><strong class="bold-black">'+user_name+'</strong> '+response.comments[key].comment+' on <time datetime="21-01-2021">'+response.comments[key].converted_created+'</time></span>';
							li_data += '</div>';
						li_data += '</li>';

						$('#custom_timeline_'+timeline_key).append(li_data);
						event_cnt++;
					}

                	// Hide add new comment if ticket or comment shared with tenant
                	if((user_service_name == t_role) && (is_shared == 1 || response.comments[key].allow_share == 1)) {
                		$('#add_new_comment_container, #property_detail_footer').remove();
                	}
	            }
	        }

	        // Validation code for shared ticket.
            if(user_service_name == t_role && is_shared == 1) {
            	$('.card-actions').addClass('d-none');
            }
    	} else {
    		display_error_message(response);
    	}
    }, function() {
        // hang on this step if the error occur
    });
}

var $ticketForm;
window.addTicketValidation = function() {
	$ticketForm = $("#ticket_add_form");
	$ticketForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "tickets-property-id": { required: true },
	        "tickets-title": { required: true },
	    },
	    messages: {
	        "tickets-property-id": { required: '' },
	        "tickets-title": { required: '' }
	    }
	});
}

window.getPropertyRelatedUsers = function(property_id, to_whom = '') {
	$.ajax({
       	method: 'POST',
	    url: api_url+"properties/get-users",
	    data:'property_id='+property_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<p><strong>Send email to</strong></p>';
            	html += '<select name="to_whom_dd" class="form-control to_whom_dd mt-3" data-toggle="select2" multiple>';
	            	//html += "<option value=''>Select User</option>";
	            	for (var key in response.userData) {
		                if (response.userData.hasOwnProperty(key)) {
	                		//var tenant_name = response.userData[key].name+' ('+response.userData[key].email+')';
	                		var tenant_name = response.userData[key].name+' ('+response.userData[key].type+')';
	                		html += '<option data-user-id="'+response.userData[key].user_id+'" value="'+response.userData[key].user_id+'">'+tenant_name+'</option>';
						}
					}
				html += '</select>';
				$('#to_whom_container').html(html);

				$(".to_whom_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select users",
				            tags: true,
				            dropdownParent: $(this).parent()
				        });
				})

			    if(to_whom != null) {
	            	var userIds = to_whom.split(',');
					$(".to_whom_dd").val(userIds).change();
				}
            }
        }
    });
}

window.saveDraftTicket = function() {
	var user_id = user.user_details.user_id;

	if(typeof property_id === 'undefined') {
		var data = 'user_id='+user_id+'&user_service_name='+user_service_name;
	} else {
		var to_whom = '';
		if(user_service_name == sa_role) {
			var to_whom = $('.to_whom_dd').find(':selected').map(function() {
			    return $(this).attr("data-user-id");
			}).get().join(',');
		}

		var property_id = $("#selected_property_id").val();
		var data = 'user_id='+user_id+'&property_id='+property_id+'&to_whom='+to_whom+'&user_service_name='+user_service_name;
	}

	$.ajax({
        method: 'POST',
        url: api_url+"tickets/add",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var ticket_id = response.id;
            	getTicketDetailPage(ticket_id);
            }
        }
    });
}

window.updateDraftTicket = function(element) {
	var field = '';
	if(element == 'tickets-property-id') {
    	field = $('#'+element).val();
    } else if(element == 'tickets-title') {
    	field = $('#'+element).val();
    } else if(element == 'desc_editor') {
    	field = desc_editor.value();
    } else if(element == 'to_whom_dd') {
    	var field = $('.to_whom_dd').find(':selected').map(function() {
		    return $(this).attr("data-user-id");
		}).get().join(',');
    }

    clearTimeout(typingTimer);

    if($.trim(field) != "") {
    	typingTimer = setTimeout(function(){

    		$('.save_ticket_data').text('Saving ...');

            //do stuff here e.g ajax call etc....
            var property_id = $('#tickets-property-id').val();
            var title = $('#tickets-title').val();
            var description = desc_editor.value();
            var user_id = user.user_details.user_id;

            if($('#ticket_open_from').val() == "detail") {
        		var ticket_id = $('#ticket_id').val();
        	} else {
        		var ticket_id = $('#add_ticket_id').val();
        	}

        	var to_whom = $('.to_whom_dd').find(':selected').map(function() {
			    return $(this).attr("data-user-id");
			}).get().join(',');

            var data = '';
			if(element == 'tickets-property-id') {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&title='+encodeURIComponent(title)+'&description='+encodeURIComponent(description)+'&status=0'+'&to_whom='+to_whom+'&user_service_name='+user_service_name;
			} else if(element == 'tickets-title') {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&title='+encodeURIComponent(field)+'&description='+encodeURIComponent(description)+'&status=0'+'&to_whom='+to_whom+'&user_service_name='+user_service_name
			} else if(element == 'to_whom_dd') {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&title='+encodeURIComponent(title)+'&description='+encodeURIComponent(description)+'&status=0'+'&to_whom='+to_whom+'&user_service_name='+user_service_name
			} else {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&description='+encodeURIComponent(field)+'&title='+encodeURIComponent(title)+'&status=0'+'&to_whom='+to_whom+'&user_service_name='+user_service_name
			}

            $.ajax({
		        method: 'POST',
		        url: api_url+"tickets/edit/"+ticket_id,
		        data: data,
		        async: true,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	$('.save_ticket_data').text('Submit');
		            	$('#ticket-title-'+ticket_id).text(title);
		            	$('#ticket-description-'+ticket_id).text(description);
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
        }, doneTypingInterval);
    }
}

window.saveTicketData = function() {
	if($ticketForm.valid()){
		$('.save_ticket_data').attr('disabled', true);

		// Check validaiton for user dropdown
		var to_whom = '';
		if(user_service_name == sa_role) {
			var to_whom = $('.to_whom_dd').find(':selected').map(function() {
			    return $(this).attr("data-user-id");
			}).get().join(',');

			if(to_whom == '') {
				$('.save_ticket_data').attr('disabled', false);
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please select user',
	                }
	            };
	            display_error_message(res);
	            return false;
	        }
	    }

        // Check validation for comment area
		if($.trim(desc_editor.value()) == "") {
			$('.save_ticket_data').attr('disabled', false);
            var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Comment should not be blank.'
                }
            };
            $('.save_ticket_data').attr('disabled', false);
            display_error_message(response);
            return false;
        }

        clearTimeout(typingTimer);

        // Parse markdown to html
        var formatted_markdown_description = parse_markdown_to_html(desc_editor.value());

		var property_id = $('#tickets-property-id').val();
        var ticket_id = $('#add_ticket_id').val();
		var user_id = user.user_details.user_id;
		var title = $('#tickets-title').val();
		var description = desc_editor.value();
		var status = 1;
        var data = 'user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&title='+encodeURIComponent(title)+'&description='+encodeURIComponent(description)+'&status='+status+'&to_whom='+to_whom+'&formatted_markdown_description='+encodeURIComponent(formatted_markdown_description);

        $.ajax({
            method: 'POST',
            url: api_url+"tickets/edit/"+ticket_id,
            data: data,
            async: true,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);encodeURIComponent
                $('.save_ticket_data').attr('disabled', false);
                if(response.response_data.success == true) {
                	openLoadingDialog($('#main_container'));
					setTimeout(function(){
					    $('#main_container').load('components/support.html');
					}, 500);
                }
            }
        });
    }
}

window.updateShareTicketUser = function(id, module_name, flag) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&id='+id+'&module_name='+module_name+'&flag='+flag;

	if(module_name == 'comment') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"comments/view/"+id,
	        data: data,
	        async: true,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	if(response.response_data.success == true) {
	            	// Parse markdown to html
        			var formatted_markdown_comment = parse_markdown_to_html(response.comment.comment);
        			data = data+'&formatted_markdown_comment='+encodeURIComponent(formatted_markdown_comment);
        			shareTicketOrComment(data);
	            }
	        }
	    });
	} else {
		shareTicketOrComment(data);
	}
}

window.shareTicketOrComment = function(data) {
	$.ajax({
        method: 'POST',
        url: api_url+"tickets/share",
        data: data,
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	if(module_name == 'ticket') {
	            	clearTimeout(typingTimer);
	            	openLoadingDialog($('#main_container'));
					setTimeout(function(){
					    $('#main_container').load('components/support.html');
					}, 500);
				}
            }
        }
    });
}

window.updateTicketStatus = function(ticket_id,status) {

	// Check validation for comment area
	if($.trim(comment_editor.value()) == "") {
		$('.resolve_ticket_comment, .close_ticket_comment, .reopen_ticket_comment').attr('disabled', false);
        var response = {
            "response_data": {
            	"success": false,
              	"message": 'Comment should not be blank.'
            }
        };
        display_error_message(response);
        return false;
    }

    // Parse markdown to html
    var formatted_markdown_comment = parse_markdown_to_html(comment_editor.value());
    var user_id = user.user_details.user_id;

	$.ajax({
        method: 'POST',
        url: api_url+"tickets/status/"+ticket_id,
        data: 'status='+status+'&user_id='+user_id+'&user_service_name='+user_service_name+'&formatted_markdown_comment='+formatted_markdown_comment,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	openLoadingDialog($('#main_container'));
				setTimeout(function(){
				    $('#main_container').load('components/support.html');
				}, 500);
            }
        }
    });
}

window.deleteDraftTicket = function(ticket_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tickets/delete/"+ticket_id,
        data:"user_id="+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
				    $('#main_container').load('components/support.html');
				}, 500);
            }
        }
    });
}

window.getTicketDetailPage = function(ticket_id) {
	openLoadingDialog($('#main_container'));
	$('#main_container').load('components/ticket/ticket_detail.html', ticket_id , function(){
		loadTicketDetail(ticket_id);
        if(!user.permissionData['SUPPORT']["feature_items['close']"]) {
        	$('.close_ticket_comment').addClass('d-none');
        }
        if(!user.permissionData['SUPPORT']["feature_items['reopen']"]) {
        	$('.reopen_ticket_comment').addClass('d-none');
        }
        if(!user.permissionData['SUPPORT']["feature_items['add_comment']"]) {
        	$('.add_ticket_comment').addClass('d-none');
        }

		if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
			// setTimeout(function(){
				disableForAssumeIdentity();
			// }, 3000);
		}
    });
}

window.addTicketComment = function(ticket_id) {
	$('.add_ticket_comment').attr('disabled', true);

	var module_id = ticket_id;
	var module_name = 'tickets';
    var user_id = user.user_details.user_id;
    var comment = comment_editor.value();

    var formatted_markdown_comment = parse_markdown_to_html(comment_editor.value());

    var data = '';
    data = 'user_service_name='+user_service_name+'&module_id='+module_id+'&module_name='+module_name+'&user_id='+user_id+'&comment='+encodeURIComponent(comment)+'&formatted_markdown_comment='+encodeURIComponent(formatted_markdown_comment);

    $.ajax({
        method: 'POST',
        url: api_url+"comments/add",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
        		getTicketDetailPage(ticket_id);
            } else {
            	$('.add_ticket_comment').attr('disabled', false);
            }
        }
    });
}

var edit_comment_editor;
window.editTicketComment = function(comment_id) {
    var tz = jstz.determine();
    var timezone = tz.name();
    $.ajax({
        method: 'POST',
        url: api_url+"comments/view/"+comment_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
    }).then(function(response) {
        if(response.response_data.success == true) {
            var comment_data = "";
            comment_data += '<div id="property_detail_footer" class="mb-2 ml-2 small footer-content text-right"> <button class="btn btn-orange my-2 my-sm-0 ml-2 cancel_comment">Cancel</button> <button class="btn btn-orange my-2 my-sm-0 ml-2 update_comment">Save</button></div></div><input type="hidden" name="edit_comment_id" id="edit_comment_id" value="'+comment_id+'"><div class="row"> <div class="col-12 col-lg-12"> <div class="form-row"> <div class="form-group col-md-12"><textarea name="description" id="edit_comment_editor_'+comment_id+'" class="form-control input-sm"></textarea> <div class="markdown_file_upload"> <input multiple="multiple" type="file" class="markdown_sub_upload_input_'+comment_id+'"> <a class="markdown_sub_upload_link_'+comment_id+'" href="javascript:void(0)">Browse</a> or Drag & Drop or Copy from clipboard files and images here <span class="markdown-ext-msg">Allowed extension : .jpg , .jpeg , .zip</span> </div> </div></div>';

            $('#comment_description_'+comment_id).html(comment_data);

            /* markdown editor code */
            edit_comment_editor = initialize_markdown("edit_comment_editor_"+comment_id);
            markdown_drag_drop(edit_comment_editor);
            markdown_sub_comment_selectfile(edit_comment_editor, comment_id);
            // markdown_attachment(edit_comment_editor);
            edit_comment_editor.value(response.comment.comment);
            /* markdown editor code */
            parse_markdown("edit_comment_editor_"+comment_id);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.updateTicketComment = function(comment_id) {
	if($.trim(edit_comment_editor.value()) == "") {
        var response = {
            "response_data": {
              "message": 'Comment should not be blank.'
            }
        };
        display_error_message(response);
        return false;
    }

    var user_id = user.user_details.user_id;
    var comment = edit_comment_editor.value();
    var formatted_markdown_comment = parse_markdown_to_html(edit_comment_editor.value());
    var data = 'comment='+encodeURIComponent(comment)+'&user_id='+user_id+'&user_service_name='+user_service_name+'&formatted_markdown_comment='+encodeURIComponent(formatted_markdown_comment);

    $.ajax({
        method: 'POST',
        url: api_url+"comments/edit/"+comment_id,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#comment_description_'+comment_id).html(comment);
            	parse_markdown('comment_description_'+comment_id);
            }
        }
    });
}

window.cancelTicketComment = function(comment_id) {
	// call comment view api to get created date
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"comments/view/"+comment_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	$('#comment_description_'+comment_id).html(data.comment.comment);
                parse_markdown('comment_description_'+comment_id);
            }
        }
    });
}

window.deleteTicketComment = function(comment_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"comments/delete/"+comment_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#comment_container_'+comment_id).remove();
            }
        }
    });
}

/* Start : Myacocunt */

window.getMyAccountProfileDetailWidget = function() {
	openLoadingDialog($('#myaccount_profile_widget'));
	setTimeout(function(){
    	$('#myaccount_profile_widget').load('components/myaccount/myaccount_profile_widget.html', function(){
			var user_id = user.user_details.user_id;
			var html = '';
			$.ajax({
		        method: 'POST',
		        url: api_url+"users/details",
		        data: 'user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	//$('#myaccount_profile_edit_btn').html('<span class="btn btn-orange float-right edit_profile">Edit</span>');
			        	var status = '';
			            if(response.user_details.status_code == 0) {
			            	status += '<span class="badge badge-info">'+response.user_details.status+'</span>';
			            } else if(response.user_details.status_code == 1) {
			            	status += '<span class="badge badge-success">'+response.user_details.status+'</span>';
			            } else if(response.user_details.status_code == 2) {
			            	status += '<span class="badge badge-warning">'+response.user_details.status+'</span>';
			            } else if(response.user_details.status_code == 5) {
			            	status += '<span class="badge badge-danger">'+response.user_details.status+'</span>';
			            }

			            var username = response.user_details.first_name+' '+response.user_details.last_name;
			            var email = response.user_details.email;
			            var phone = response.user_details.phone;

			            var phone_verified = '<span class="badge badge-danger">Not Verified</span>';
				        var email_verified = '<span class="badge badge-danger">Not Verified</span>';
				        if(response.user_details.phone_verified == 1) {
				        	phone_verified = '<span class="badge badge-success">Verified</span>';
				        }
				        if(response.user_details.email_verified == 1) {
				        	email_verified = '<span class="badge badge-success">Verified</span>';
				        }

				        var optinout = 'Opted Out (SMS)';
				        if(response.user_details.optinout == 1) {
				        	optinout = 'Opted In (SMS)';
				        }

			            html += '<div data-id="'+user_id+'" class="col-12 col-md-12 col-xl-12">';
		        			html += '<table class="table mt-3 mb-0 card-table word-break">';
								html += '<tbody>';
									html += '<tr>';
										html += '<td><img src="icon/profile/user.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+username+'<span class="float-right"><a href="javascript:void(0)" data-field="name" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td><img src="icon/profile/email.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+email+' '+email_verified+'<span class="float-right"><a href="javascript:void(0)" data-field="email" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td><img src="icon/profile/phone.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+phone+' '+phone_verified+'<span class="float-right"><a href="javascript:void(0)" data-field="phone" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td><img src="icon/profile/sms.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+optinout+'<span class="float-right"><a href="javascript:void(0)" data-field="sms" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									if(user_service_name == po_role) {
										html += '<tr>';
											html += '<td><img src="icon/profile/address.svg" class="filter-8"></td>';
											html += '<td class="text-left"><span class="float-right"><a href="javascript:void(0)" data-user-service-id="'+response.user_details.user_service_id+'" data-user-service-address-id="'+response.user_details.user_service_address_id+'" data-field="address" class="edit_address">Edit</a></span><div class="card-text capitalize">'+response.user_details.address_line1+', '+response.user_details.address_line2+'<br>'+response.user_details.city+', '+response.user_details.state+' '+response.user_details.zip_code+'</div></td>';
										html += '</tr>';
									}
								html += '</tbody>';
							html += '</table>';
							html += '<div class="text-right mt-4">'+status+'</div>';
				        html += '</div>';

				        $('#myaccount-profile-data-container').html(html);
			        } else {
			            display_error_message(response);
			        }
		        }
		    });
		});
	}, 1000);
}

window.getProfileEditForm = function(open_from, field, field_value) {
	var user_id = user.user_details.user_id;
	openLoadingDialog($('#profile_container_update'));
	setTimeout(function(){
		$('#editProfileModal #profile_container_update').load('components/myaccount/form/'+field+'.html', function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"users/details",
		        data: 'user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	$('#selected_field_name').val(field);

		            	var disabled = 'disabled';
		            	if(field == 'sms') {
		            		disabled = '';
		            	}

		            	if(field == 'email' || field == 'phone') {
		            		$('#update_profile_data_btn').html('<div class="col-auto ml-auto text-right mt-2 mb-2 mr-3"><button type="button" class="btn btn-orange float-right update_profile_data" '+disabled+'>Verify</button></div>');
		            	} else {
		            		$('#update_profile_data_btn').html('<div class="col-auto ml-auto text-right mt-2 mb-2 mr-3"><button type="button" class="btn btn-orange float-right update_profile_data" '+disabled+'>Update</button></div>');
		            	}

		            	var first_name = response.user_details.first_name;
			            var last_name = response.user_details.last_name;
			            var email = response.user_details.email;
			            var phone = response.user_details.phone;
			            var optinout = response.user_details.optinout;
			            $('#firstname').val(first_name);
			            $('#lastname').val(last_name);
			            $('#email_address').val(email);
			            $('#country_code').val();

			            phone = phone.split('+1');
			            $('#phone_number').val(phone[1]);
			            $('#phone_number').mask('(000) 000-0000');

			            $('#optinout_checkbox').attr('checked', false);
			            if(optinout == 1) {
			            	$('#optinout_checkbox').attr('checked', true);
			            }
			        } else {
			            display_error_message(response);
			        }
		        }
		    });
		});
	}, 1000);
}

window.updateProfile = function(field) {
	var user_id = user.user_details.user_id;
	var data = '';
	if(field == 'name') {
		var first_name = $('#firstname').val();
	    var last_name = $('#lastname').val();
		data = 'user_id='+user_id+'&first_name='+first_name+'&last_name='+last_name;
	} else if(field == 'email') {
		var email_address = $('#email_address').val();
	    data = 'user_id='+user_id+'&email='+email_address;
	} else if(field == 'phone') {
		var country_code = $('#country_code').val();
		var phone = $.trim($('#phone_number').val());
		phone = phone.replace('-', '');
		phone = phone.replace('(', '');
		phone = phone.replace(')', '');
		phone = phone.replace(/\s/g, '');
		phone = country_code+phone;

		if($('#edit_verify_mobile_checkbox').is(":checked")) {
			var verify_mobile = 1;
		} else {
			var verify_mobile = 0;
		}

		if($('#optinout_checkbox').is(":checked")) {
			var optinout = 1;
		} else {
			var optinout = 0;
		}
		data = 'user_id='+user_id+'&phone='+encodeURIComponent(phone)+'&verify_mobile='+verify_mobile+'&optinout='+optinout;
	} else if(field == 'sms') {
		if($('#optinout_checkbox').is(":checked")) {
			var optinout = 1;
		} else {
			var optinout = 0;
		}
		data = 'user_id='+user_id+'&optinout='+optinout;
	}

	$.ajax({
        method: 'POST',
        url: api_url+"auth/update-profile",
        data: data+'&field='+field,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	if(response.change_made == 'yes') {
	            	if(field == 'email') {
	            		$('#email_data_container').addClass('d-none');
	            		$('#verify_email_data_contanier').removeClass('d-none');
	            		$('#destination_email').text(censorEmail(email_address));
	            	} else if(field == 'phone') {
	            		$('#phone_data_container').addClass('d-none');
	            		$('#verify_phone_data_contanier').removeClass('d-none');
	            		$('#destination_phone').text(response.destination);
	            	}
	            } else {
	            	$('#editProfileModal').modal('hide');
	            }
            	getMyAccountProfileDetailWidget();
	        } else {
	            display_error_message(response);
	        }
        }
    });
}

window.verifyEmail = function(field) {
	var user_id = user.user_details.user_id;
	var email_digit_1 = $('#signup-email-digit-1').val();
	var email_digit_2 = $('#signup-email-digit-2').val();
	var email_digit_3 = $('#signup-email-digit-3').val();
	var email_digit_4 = $('#signup-email-digit-4').val();
	var email_digit_5 = $('#signup-email-digit-5').val();
	var email_digit_6 = $('#signup-email-digit-6').val();
	var confirmation_code = email_digit_1+email_digit_2+email_digit_3+email_digit_4+email_digit_5+email_digit_6;
	var data = 'user_id='+user_id+'&confirmation_code='+confirmation_code;
    $.ajax({
        method: 'POST',
        url: api_url+"auth/profile-verify-email",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		$('#editProfileModal').modal('hide');
	    		getMyAccountProfileDetailWidget();
	    	} else {
	    		return false;
	    	}
        }
    });
}

window.verifyPhone = function(field) {
	var user_id = user.user_details.user_id;
	var phone_digit_1 = $('#signup-phone-digit-1').val();
	var phone_digit_2 = $('#signup-phone-digit-2').val();
	var phone_digit_3 = $('#signup-phone-digit-3').val();
	var phone_digit_4 = $('#signup-phone-digit-4').val();
	var phone_digit_5 = $('#signup-phone-digit-5').val();
	var phone_digit_6 = $('#signup-phone-digit-6').val();
	var confirmation_code = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4+phone_digit_5+phone_digit_6;
	var data = 'user_id='+user_id+'&confirmation_code='+confirmation_code;
    $.ajax({
        method: 'POST',
        url: api_url+"auth/profile-verify-phone",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		$('#editProfileModal').modal('hide');
	    		getMyAccountProfileDetailWidget();
	    	} else {
	    		return false;
	    	}
        }
    });
}

window.getAddressEditForm = function(user_service_id, user_service_address_id) {
	var user_id = user.user_details.user_id;
	openLoadingDialog($('#address_container_update'));
	setTimeout(function(){
		$('#editAddressModal #address_container_update').load('components/myaccount/form/address.html', function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"users/details",
		        data: 'user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	$('#selected_user_service_id').val(user_service_id);
		            	$('#selected_user_service_address_id').val(user_service_address_id);

		            	$('#update_address_data_btn').html('<div class="col-auto ml-auto text-right mt-2 mb-2 mr-3"><button type="button" class="btn btn-orange float-right update_address_data">Update</button></div>');

		            	var address_line1 = response.user_details.address_line1;
			            var address_line2 = response.user_details.address_line2;
			            var city = response.user_details.city;
			            var state = response.user_details.state;
			            var zip_code = response.user_details.zip_code;
			            var country = response.user_details.country;

			            $('#address_line1').val(address_line1);
			            $('#address_line2').val(address_line2);
			            $('#city').val(city);
			            $('#state').val(state);
			            $('#zip_code').val(zip_code);
			            $('#country').val(country);

			            var selected_state = state;
			            getStateDropdownForSignup(selected_state);
			        } else {
			            display_error_message(response);
			        }
		        }
		    });
		});
	}, 1000);
}

window.updateAddress = function() {
	var user_id = user.user_details.user_id;

	var address_line1 = $('#address_line1').val();
    var address_line2 = $('#address_line2').val();
    var city = $('#city').val();
    var state = $('#state').val();
    var zip_code = $('#zip_code').val();
    var country = $('#country').val();

	var selected_user_service_id = $('#selected_user_service_id').val();
    var selected_user_service_address_id = $('#selected_user_service_address_id').val();
    var data = '';
	data = 'user_id='+user_id+'&user_service_id='+selected_user_service_id+'&address_id='+selected_user_service_address_id+'&address_line1='+address_line1+'&address_line2='+address_line2+'&city='+city+'&state='+state+'&zip_code='+zip_code+'&country='+country;

	$.ajax({
        method: 'POST',
        url: api_url+"users/update-address",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#editAddressModal').modal('hide');
            	getMyAccountProfileDetailWidget();
	        } else {
	            display_error_message(response);
	        }
        }
    });
}

window.getMyAccountPaymentDetailWidget = function() {
	openLoadingDialog($('#myaccount_payment_detail_widget'));
	// Below confition will check if applications page or my account page open
	var page_name = localStorage.getItem('page_name');
	if(page_name == 'users') {
		var user_id = $('#selected_user_id').val();
		var data = 'user_id='+user_id;
	} else {
		var data = 'user_id='+user.user_details.user_id+'&type=kyc_kebab';
	}

	setTimeout(function(){
    	$('#myaccount_payment_detail_widget').load('components/myaccount/myaccount_payment_detail_widget.html', function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
			    url: api_url+"payment-details/user-banks",
			    data:data,
			    beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			        	if(page_name != 'users') {

							var my_account_kebab_item = '';

							if(response.userKycData.add_kyc == 1){
								my_account_kebab_item += '<a class="dropdown-item" id="open_bank_sidebar" href="javascript:void(0)" data-type="kyc">Add KYC</a>';
							} else {
								if(response.userKycData.allow_to_add_bank == 1){
									my_account_kebab_item += '<a class="dropdown-item" id="open_bank_sidebar" href="javascript:void(0)" data-type="bank">Add Bank</a>';
								}
							}

							var myaccount_bank_kebab = '';
							if(my_account_kebab_item != ''){
								myaccount_bank_kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+my_account_kebab_item+'</div></div></div>';
							}

							$('#myaccount_bank_add_btn').html(myaccount_bank_kebab);
						}

						var html = '';
						for (var key in response.userBanks) {
			                if (response.userBanks.hasOwnProperty(key)) {

			                	var bg_light = '';
			                	if(key % 2 == 1) {
			                		bg_light = 'bg-light';
			                	}

			                	var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;

			                	var kebab_item = '';
			                	var deleted_menu = '';
			                	if(user_service_name == po_role && response.userBanks[key].status == 2) {
			                		kebab_item += '<a class="dropdown-item link_bank_and_property" data-module="Bank" data-id="'+response.userBanks[key].id+'" data-payment-method="'+payment_method+'" href="javascript:void(0)">Link Property</a>';
			                	}

			                	var status = '';
			                    if(response.userBanks[key].status == 0) { // Unverified
			                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
			                    } else if(response.userBanks[key].status == 1) { // Awaiting
			                        status += '<span class="badge badge-warning">'+response.userBanks[key].status_label+'</span>';
			                        kebab_item += '<a href="javascript:void(0)" data-id='+response.userBanks[key].id+' class="dropdown-item verify_bank_detail" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false">Verify Bank Detail</a>';
			                    } else if(response.userBanks[key].status == 2) { // Verified
			                        status += '<span class="badge badge-success">'+response.userBanks[key].status_label+'</span>';
			                    } else if(response.userBanks[key].status == 3) { // Deleted
			                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
			                        //deleted_menu += '<a class="dropdown-item restore_bank_detail" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Restore</a>';
			                    } else if(response.userBanks[key].status == 10) { // Processing
			                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
			                        if(user_service_name == sa_role) {
			                        	kebab_item += '<a class="dropdown-item initiate_micro_deposit" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Initiate Micro Deposit</a>';
			                        }
			                    }

								if(response.userBanks[key].plaid_account_id != '') {
									kebab_item += '<a class="dropdown-item refresh_account_detail" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Refresh Account</a>';
								}

			                    //if(user.user_details.user_id == response.userBanks[key].user_id) {
			                    if(localStorage.getItem("allow_ai_access") == 1) {
			                    	kebab_item += '<a class="dropdown-item delete_bank_detail" data-id="'+response.userBanks[key].id+'" data-message="'+response.userBanks[key].message+'" href="javascript:void(0)">Delete</a>';
			                    }

			                    var kebab = '';
			                    if(response.userBanks[key].status != 3 && kebab_item != '') {
			                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
			                    }

			                    // Table based UI
			                	html += '<tr data-id="'+response.userBanks[key].id+'">';
			                		html += '<td>'+response.userBanks[key].bank_name+'</td>';
									if(response.userBanks[key].account_number){
										html += '<td>'+response.userBanks[key].account_number+'</td>';
									} else {
										html += '<td class="text-center"> - </td>';
									}
			                		html += '<td>'+response.userBanks[key].account_type_label+'</td>';
			                		html += '<td>'+response.userBanks[key].linked_to+'</td>';
			                		html += '<td>'+status+'</td>';
			                		html += '<td>'+kebab+'</td>';
			                	html += '</tr>';
			                }
			            }

						$('#myaccount-payment-data-container').html(html);
						var property_lease_table = $("#myaccount-payment-detail-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

// New Payment Workflow : Start

var $psPaymentForm;
window.PSPaymentValidation = function() {
	$psPaymentForm = $("#payment_schedule_option_form");
	$psPaymentForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "ps_payment_detail_id[]": { required: true },
	        "ps_tenant_detail_id": { required: true },
	        "ps_tenant_payment_detail_id": { required: true },
	        "ps_po_payment_detail_id": { required: true }
	    },
	    messages: {
	        "ps_payment_detail_id[]": { required: '' },
			"ps_tenant_detail_id": { required: '' },
	        "ps_tenant_payment_detail_id": { required: '' },
	        "ps_po_payment_detail_id": { required: '' }
	    }
	});
}

window.deletePaymentSchedules = function(lease_id){
	$.ajax({
	    url: api_url+"payment-schedules/delete-payment-schedules",
	    type: 'POST',
		data: 'lease_id='+lease_id+'&user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			display_error_message(response);
			var html = '';
			if(response.response_data.success == true){
				getPropertyLeaseWidgetInfo();
				getPropertiesPaymentScheduleWidgetInfo();
			}
		}
	});
}

window.getPaymentOptions = function(lease_id){
	$.ajax({
	    url: api_url+"payment-schedules/get-payment-options",
	    type: 'POST',
		data: 'lease_id='+lease_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			display_error_message(response);
			var html = '';
			if(response.response_data.success == true){
				html += '<div class="transction_detail">';
					html += '<form id="payment_schedule_option_form" action="javascript:void(0)">';
						html += '<input type="hidden" name="ps_lease_id" id="ps_lease_id" class="form-control" value="'+lease_id+'">';

						html += '<div class="col-12 col-md-12 col-xl-12 pl-4 pr-4 pt-4">';
							html += '<div class="row justify-content-center">';
								html += '<div class="col-12 col-md-12 col-xl-6 mb-4">';
									html += '<div class="p-3 pl-0 border badge-grey border-bottom-left-radius0 border-bottom-right-radius0">';
										html += '<span class="m-2"><img src="icon/activity/bank.svg" class="filter-15" style="width: 20px;"></span>';
										html += '<span class="po_absolute top_14 fs-18 bold-black">Tenant Payment Method</span>';
									html += '</div>';
									html += '<div class="card-body border border-bottom-left-radius10 border-bottom-right-radius10">';
										var bank_html = '';
										if(Object.keys(response.tenant_details).length > 0){
											for(var tkey in response.tenant_details){
												var selected = '';
												if(Object.keys(response.tenant_details).length == 1){
													selected = 'selected';
												}
												var primary = '';
												if(response.tenant_details[tkey].tenant.is_primary == 1){
													primary = '- Primary';
													selected = 'selected';
												}
												bank_html += '<option value="'+response.tenant_details[tkey].tenant.user.id+'" '+selected+'>'+response.tenant_details[tkey].tenant.user.first_name+' '+response.tenant_details[tkey].tenant.user.last_name+' ('+response.tenant_details[tkey].tenant.user.email+') '+primary+'</option>';
											}
										}

										html += '<div class="form-group">';
											html += '<select name="ps_tenant_detail_id" id="ps_tenant_detail_id" class="form-control ps_get_payment_details ps_tenant_detail_id" data-target-id="ps_tenant_payment_detail_id" data-ps-detail-id="0" data-toggle="select2">'+bank_html+'</select>';
										html += '</div>';
									
										var bank_html = '';

										html += '<div class="form-group">';
											html += '<select name="ps_tenant_payment_detail_id" id="ps_tenant_payment_detail_id" class="ps_tenant_payment_detail_id form-control ps_payment_detail" data-toggle="select2">'+bank_html+'</select>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
								html += '<div class="col-12 col-md-12 col-xl-6 mb-4">';
									html += '<div class="p-3 pl-0 border badge-grey border-bottom-left-radius0 border-bottom-right-radius0">';
										html += '<span class="m-2"><img src="icon/activity/bank.svg" class="filter-15" style="width: 20px;"></span>';
										html += '<span class="po_absolute top_14 fs-18 bold-black">Property Owner Payment Method</span>';
									html += '</div>';
									html += '<div class="card-body border border-bottom-left-radius10 border-bottom-right-radius10">';
										var po_html = '';
										if(Object.keys(response.po_detail).length > 0){
											po_html += '<option value="'+response.po_detail.user.id+'">'+response.po_detail.user.first_name+' '+response.po_detail.user.last_name+' ('+response.po_detail.user.email+')</option>';
										}
										
										html += '<div class="form-group">';
											html += '<select name="ps_po_detail_id" id="ps_po_detail_id" class="form-control ps_po_detail_id" data-toggle="select2" disabled>'+po_html+'</select>';
										html += '</div>';

										var bank_html = '';
										if(Object.keys(response.po_payment_detail).length > 0){
											for(var tkey in response.po_payment_detail){
												var selected = '';
												if(Object.keys(response.po_payment_detail).length == 1){
													selected = 'selected';
												}

												bank_html += '<option value="'+response.po_payment_detail[tkey].id+'" '+selected+'>'+response.po_payment_detail[tkey].bank_name+'</option>';
											}
										}

										html += '<div class="form-group">';
											html += '<select name="ps_po_payment_detail_id" id="ps_po_payment_detail_id" class="ps_po_payment_detail_id form-control ps_payment_detail" data-toggle="select2">'+bank_html+'</select>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';	

						html += '<div class="col-12 col-md-12 col-xl-12 pl-4 pr-4 pt-4">';
							html += '<div class="row justify-content-center">';
								for(var key in response.data){
									html += '<div class="col-12 col-md-12 col-xl-6 mb-4">'
										html += '<div class="p-3 pl-0 border badge-grey border-bottom-left-radius0 border-bottom-right-radius0">';
											html += '<input type="hidden" name="ps_payment_type_id[]" id="ps_payment_type_id_'+response.data[key].payment_type_id+'" class="ps_payment_type_id form-control" value="'+response.data[key].payment_type_id+'">';
											html += '<span class="m-2"><img src="icon/activity/payment.svg" class="filter-15" style="width: 20px;"></span>';
											html += '<span class="po_absolute top_14 fs-18 bold-black">'+response.data[key].payment_type_name+'</span>';
											if(response.subscriptionPlan.plan.enable_payment_routing){

												var add_opacity = '';
												if(response.data[key].disable_add_route == 1){
													add_opacity = 'add_opacity';
												}

												//html += '<a href="javascript:void(0)" class="add_payment_route float-right" id="add_payment_route_'+response.data[key].payment_type_id+'" data-payment-type-id="'+response.data[key].payment_type_id+'" data-dd-count="1">Add Another Route</a>';
												html += '<i class="align-middle fas fa-fw fa-plus-circle fs-18 black add_payment_route float-right '+add_opacity+'" id="add_payment_route_'+response.data[key].payment_type_id+'" data-payment-type-id="'+response.data[key].payment_type_id+'" data-dd-count="1" title="Add Another Route"></i>';
											}
										html += '</div>';
										html += '<div class="card-body border border-bottom-left-radius10 border-bottom-right-radius10">';
											html += '<div class="row" id="ps_payment_route_'+response.data[key].payment_type_id+'">';
												html += '<div class="form-group col-md-12 mb-0 ps_route_container">';

													html += '<div class="form-row">';
														html += '<div class="form-group col-5 col-md-4">';
															html += '<label class="ml-2 mt-2 fs-14 bold-grey">Enable Autopay</label>';
														html += '</div>';
														html += '<div class="form-group col-7 col-md-8">';
															var add_opacity = '';
															var checked = '';
															if(response.data[key].is_autopay == 1){
																checked = 'checked';
															}
															if(response.data[key].allow_rent_collection == 0) {
																add_opacity = 'add_opacity';
																checked = '';
															}

															html += '<label class="switch text-center ml-2 mt-2 mb-0 '+add_opacity+'">';
																html += '<input type="checkbox" id="ps_autopay_'+response.data[key].payment_type_id+'" name="ps_payment_routes['+response.data[key].payment_type_id+'][0][is_autopay]" '+checked+'>';
																html += '<div class="slider round">';
																html += '<span class="on pr-3">Yes</span>';
																html += '<span class="off ml-1">No</span>';
																html += '</div>';
															html += '</label>';
														html += '</div>';														
													html += '</div>';

													html += '<div class="form-row">';
														html += '<div class="form-group col-5 col-md-4">';
															html += '<label class="ml-2 mt-2 fs-14 bold-grey">Payment Route</label>';
														html += '</div>';
														var add_opacity = '';
														if(response.data[key].disable_add_route == 1){
															add_opacity = 'add_opacity_7';
														}
														html += '<div class="form-group col-7 col-md-8 '+add_opacity+'">';
															var payment_route_html = '';
															var payment_route = response.data[key].payment_routes;
															if(Object.keys(payment_route).length > 0){
																for(var prkey in payment_route){
																	var selected = '';
																	if(Object.keys(payment_route).length == 1){
																		selected = 'selected';
																	}
																	var primary = '';
																	if(response.data[key].primary_payment_route_id == payment_route[prkey].id){
																		primary = '(Primary)';
																		selected = 'selected';
																	}
																	payment_route_html += '<option value="'+payment_route[prkey].id+'" '+selected+'>'+payment_route[prkey].sender_service_name+' -> '+payment_route[prkey].receiver_service_name+' '+primary+'</option>';
																}
															}
															html += '<select name="ps_payment_routes['+response.data[key].payment_type_id+'][0][payment_route_id]" id="ps_payment_route_'+response.data[key].payment_type_id+'_dd_1" data-payment-type-id="'+response.data[key].payment_type_id+'" data-dd-count="1" class="form-control ps_first_payment_route ps_payment_route_'+response.data[key].payment_type_id+'" data-toggle="select2">'+payment_route_html+'</select>';
														html += '</div>';														
													html += '</div>';
													
												html += '</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
								}
							html += '</div>';
						html += '</div>';

						html += '<div class="text-center mb-4">';
							html += '<button type="button" class="btn btn-orange mt-2 create_payment_schedule">Create Payment Schedule</button>';
						html += '</div>';
					html += '</form>';
				html += '</div>';
				$('#sidebar_content_area').html(html);
				//$('#create_payment_schedule_container').html(html);

				setTimeout(function(){
					$('#ps_tenant_detail_id').trigger('change');
				}, 300);

				$(".ps_first_payment_route").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "Select Payment Route",
						dropdownParent: $(this).parent()
					});
				});

				$(".ps_tenant_detail_id").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "Select Tenant",
						dropdownParent: $(this).parent()
					});
				});

				$(".ps_po_detail_id").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "Select Property Owner",
						dropdownParent: $(this).parent()
					});
				});

				$(".ps_tenant_payment_detail_id").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "Select Payment Method",
						dropdownParent: $(this).parent()
					});
				});

				$(".ps_po_payment_detail_id").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "Select Payment Method",
						dropdownParent: $(this).parent()
					});
				});
				PSPaymentValidation();
			} else {
				html += '<div class="text-center fs-18 bold-black">'+response.response_data.message+'</div>';
				$('#sidebar_content_area').html(html);
				//$('#create_payment_schedule_container').html(html);
				$('.get_payment_options').attr('disabled', false);
				$('.get_payment_options').removeClass('add_opacity');
			}
		}
	});
}

window.getPaymentRouteDetail = function(payment_route_id, payment_type_id, dd_count, allPaymentRoute){
	$.ajax({
		method: 'POST',
		url: api_url+"payment-routes/get-payment-route",
		data:'user_id='+user_id+'&allPaymentRoute='+allPaymentRoute+'&payment_route_id='+payment_route_id+'&payment_type_id='+payment_type_id+'&dd_count='+dd_count,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);

			if(response.disableAddPaymentRoute == 1){
				$('#add_payment_route_'+payment_type_id).addClass('add_opacity');
			} else {
				$('#add_payment_route_'+payment_type_id).removeClass('add_opacity');
			}
		}
	});
}

window.addAnotherPaymentRoute = function(payment_route_id, payment_type_id, dd_count, allPaymentRoute){
	$.ajax({
		method: 'POST',
		url: api_url+"payment-routes/get-payment-route",
		data:'user_id='+user_id+'&allPaymentRoute='+allPaymentRoute+'&payment_route_id='+payment_route_id+'&payment_type_id='+payment_type_id+'&dd_count='+dd_count+'&type=add_route',
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);

			if(response.disableAddPaymentRoute == 1){
				$('#add_payment_route_'+payment_type_id).addClass('add_opacity');
			} else {
				$('#add_payment_route_'+payment_type_id).removeClass('add_opacity');
			}

			if(response.response_data.success == true) {
				if(Object.keys(response.data).length > 0){
					$("#ps_payment_route_"+payment_type_id+"_dd_"+dd_count).parent('div').addClass('add_opacity_7');

					dd_count = Number(dd_count) + 1;
					var html = '';
					html += '<div class="form-group col-md-12 mb-0 ps_route_container border-top pt-3">';
						html += '<div class="form-row">';
							html += '<div class="form-group col-5 col-md-4">';
								html += '<label class="ml-2 mt-2 fs-14 bold-grey">Enable Autopay</label>';
							html += '</div>';
							html += '<div class="form-group col-7 col-md-8">';
								html += '<label class="switch text-center ml-2 mt-2 mb-0">';
									html += '<input type="checkbox" id="ps_autopay_'+payment_type_id+'" name="ps_payment_routes['+payment_type_id+']['+(dd_count-1)+'][is_autopay]">';
									html += '<div class="slider round">';
									html += '<span class="on pr-3">Yes</span>';
									html += '<span class="off ml-1">No</span>';
									html += '</div>';
								html += '</label>';
							html += '</div>';														
						html += '</div>';

						html += '<div class="form-row">';
							html += '<div class="form-group col-5 col-md-4">';
								html += '<label class="ml-2 mt-2 fs-14 bold-grey">Payment Route</label>';
							html += '</div>';
							html += '<div class="form-group col-6 col-md-7">';
								var payment_route_html = '';
								for(var prkey in response.data){						
									payment_route_html += '<option value="'+response.data[prkey].id+'">'+response.data[prkey].sender_service_name+' -> '+response.data[prkey].receiver_service_name+'</option>';
								}
								html += '<select name="ps_payment_routes['+payment_type_id+']['+(dd_count-1)+'][payment_route_id]" id="ps_payment_route_'+payment_type_id+'_dd_'+dd_count+'" class="form-control ps_first_payment_route ps_payment_route_'+payment_type_id+'" data-toggle="select2">'+payment_route_html+'</select>';
							html += '</div>';						
							html += '<div class="form-group col-1 col-md-1 col-sm-1 col-xl-1 col-lg-1 mt-1">';
								html += '<i class="align-middle fas fa-fw fa-minus-circle fs-18 remove_payment_route" id="remove_payment_route_'+payment_type_id+'_dd_'+dd_count+'" data-dd-count="'+dd_count+'" data-payment-type-id="'+payment_type_id+'" title="Remove Payment Route"></i>';
							html += '</div>';								
						html += '</div>';
					html += '</div>';
					
					$('#ps_payment_route_'+payment_type_id).append(html);

					$("#ps_payment_route_"+payment_type_id+"_dd_"+dd_count).each(function() {
						$(this)
							.wrap("<div class=\"position-relative\"></div>")
							.select2({
							placeholder: "Select Payment Route",
							dropdownParent: $(this).parent()
						});
					});

					$('#add_payment_route_'+payment_type_id).attr('data-dd-count', dd_count);
				}
			}
		}
	});
}

window.getPSAllBanks = function(user_ids, id, payment_detail_id = 0) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:'user_id='+user_ids,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	//display_error_message(response);
            if(response.response_data.success == true) {
				$('#ps_tenant_payment_detail_id').html('');
            	var html = '';
        		html += "<option value=''>Select Payment Method</option>";
            	for (var key in response.userBanks) {
					if(response.userBanks[key].status == 2) {
						let selected = '';
						if(response.userBanks[key].id == payment_detail_id || Object.keys(response.userBanks).length == 1) {
							selected = 'selected';
						}
						var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
						var account_number = '';
						if(response.userBanks[key].account_number){
							account_number = '('+response.userBanks[key].account_number+')';
						}
						html += '<option data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" '+selected+'>'+response.userBanks[key].bank_name+' '+account_number+'</option>';
					}
				}
				$('#'+id).html(html);
            }
        }
    });
}

window.createPaymentScheduleEntry = function(lease_id, paymentSettingArray){
	$.ajax({
	    url: api_url+"payment-schedules/create-payment-entries",
	    type: 'POST',
		// data: 'lease_id='+lease_id,
		// data: 'user_id='+user_id+'&lease_id='+lease_id+'&payments='+paymentSettingArray+'&'+$('#payment_schedule_option_form').serialize(),
		data: 'user_id='+user_id+'&lease_id='+lease_id+'&'+$('#payment_schedule_option_form').serialize(),
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			display_error_message(response);
			$('.create_payment_schedule').text('Create Payment Schedule');
			$('.create_payment_schedule').attr('disabled', false);
			$('.create_payment_schedule').removeClass('add_opacity');
			if(response.response_data.success == true){
				closeRightSidebar();
				getPropertyLeaseWidgetInfo();
				getPropertiesPaymentScheduleWidgetInfo();
			}
		}
	});
}

window.getPaymentScheduledFilterData = function(){
	if(user_service_name == sa_role){
		var user_id = $('.ps_filter_po_name').val();
		if (typeof user_id === "undefined") {
			user_id = 0;
		}
	} else {
		user_id = user.user_details.user_id;
	}
	$.ajax({
	    url: api_url+"properties/list",
	    type: 'POST',
	    data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&open_from=payment_schedule',
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			var property_html = '';
			if(Object.keys(response.propertyData).length > 1){
				property_html += '<option value="0">All Properties</option>';
			}


			for(var pkey in response.propertyData){
				var selected = '';
				if($('#selected_property_id').val() == response.propertyData[pkey].id){
					selected = 'selected';
				}

				property_html += '<option value="'+response.propertyData[pkey].id+'" '+selected+'>'+response.propertyData[pkey].name+'</option>';
			}
			$('#ps_filter_property_name').html(property_html);
			$('#ps_filter_lease_id').html('');
			$('#ps_filter_po_users').html('');
			$('.ps_filter_property_name').trigger('change');

			$(".ps_filter_property_name").each(function() {
				$(this)
					.wrap("<div class=\"position-relative\"></div>")
					.select2({
					placeholder: "BY PROPERTY",
					//dropdownParent: $(this).parent()
				});
			});

			// var p_id = $('.ps_filter_property_name').val();
			// getMyAccountPaymentScheduleWidget(p_id);
		}
	});
}

window.getPaymentStatusData = function(){
	$.ajax({
		url: api_url+"payment-schedules/get-payment-status-list",
		type: 'POST',
		data: 'user_service_name='+user_service_name+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			var html = '';
			// html += '<option value="0" Selected>All Payment Status</option>';
			for(var key in response.paymentStatusData){
				html += '<option value="'+response.paymentStatusData[key].id+'">'+response.paymentStatusData[key].label+'</option>';
			}
			$('#ps_filter_payment_status').html(html);
			$(".ps_filter_payment_status").each(function() {
				$(this)
					.wrap("<div class=\"position-relative\"></div>")
					.select2({
					placeholder: "All Payment Status",
					dropdownParent: $(this).parent()
					});
			})
		}
	});
}

window.getPSPaymentRoute = function(payment_schedule_id){
	$.ajax({
		method : 'POST',
		url : api_url+"payment-schedules/get-payment-route-details",
		data : 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			var html = '';
        	if(response.response_data.success == true) {
				var line = '(One time payment)';
				if(response.paymentScheduleData.payment_type.constant == 'rent' || response.paymentScheduleData.payment_type.constant == 'subscription_fee') {
					line = 'for the month of '+response.paymentScheduleData.month;
				}

            	html += '<form id="ps-payment-change-route-form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
						html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_'+response.paymentScheduleData.id+'" class="payment_schedule_hidden_input" value="'+response.paymentScheduleData.id+'">';
						html += '<input type="hidden" name="payment_type_id" id="payment_type_'+response.paymentScheduleData.payment_type_id+'" class="payment_schedule_hidden_input" value="'+response.paymentScheduleData.payment_type_id+'">';
						html += '<input type="hidden" name="removed_payment_route_id" id="removed_payment_route_id" class="payment_schedule_hidden_input">';

						html += '<div class="rent-detail-card p-3 mb-0 bg-white">';
							html += '<div class="row border-bottom">';
								html += '<div class="form-group col-md-12 mb-0">';
									html += '<div class="form-row">';
										html += '<div class="form-group col-8 col-md-8 col-xl-8">';
											html += '<h5 class="card-title"><span class="fs-22">'+response.paymentScheduleData.payment_type.name+'</span><br> '+line+'</h5>';
										html += '</div>';
										html += '<div class="form-group col-4 col-md-4 col-xl-4">';
											// html += '<div class="fs-22 black text-right"><strong>$'+currencyFormat(Number(response.paymentScheduleData.total))+'</strong></div>';
											if(response.enablePaymentRouting){

												var add_opacity = '';
												if(response.paymentScheduleData.disable_add_route == 1){
													add_opacity = 'add_opacity';
												}

												html += '<i class="align-middle fas fa-fw fa-plus-circle fs-22 black add_payment_route float-right mr-3 mt-2 '+add_opacity+'" id="add_payment_route_'+response.paymentScheduleData.payment_type_id+'" data-payment-type-id="'+response.paymentScheduleData.payment_type_id+'" data-dd-count="1" title="Add Another Route"></i>';
											}
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';


						html += '<div class="rent_collection_data p-3 mb-1 bg-white">';
							html += '<div class="row" id="ps_payment_route_'+response.paymentScheduleData.payment_type_id+'">';

								html += '<div class="form-group col-md-12 mb-0 ps_route_container">';
									html += '<input type="hidden" name="ps_payment_routes['+response.paymentScheduleData.payment_type_id+'][0][payment_schedule_id]" value="'+response.paymentScheduleData.id+'">';

									html += '<div class="form-row">';
										html += '<div class="form-group col-5 col-md-4">';
											html += '<label class="ml-2 mt-2 fs-14 bold-grey">Enable Autopay</label>';
										html += '</div>';
										html += '<div class="form-group col-7 col-md-8">';
											var checked = '';
											if(response.paymentScheduleData.is_autopay == 1){
												checked = 'checked';
											}

											html += '<label class="switch text-center ml-2 mt-2 mb-0">';
												html += '<input type="checkbox" id="ps_autopay_'+response.paymentScheduleData.payment_type_id+'" name="ps_payment_routes['+response.paymentScheduleData.payment_type_id+'][0][is_autopay]" '+checked+'>';
												html += '<div class="slider round">';
												html += '<span class="on pr-3">Yes</span>';
												html += '<span class="off ml-1">No</span>';
												html += '</div>';
											html += '</label>';
										html += '</div>';
									html += '</div>';

									html += '<div class="form-row">';
										html += '<div class="form-group col-5 col-md-4">';
											html += '<label class="ml-2 mt-2 fs-14 bold-grey">Payment Route</label>';
										html += '</div>';
										var add_opacity = '';
										// if(response.paymentScheduleData.disable_dd == 1){
										// 	add_opacity = 'add_opacity_7';
										// }
										html += '<div class="form-group col-7 col-md-8 '+add_opacity+'">';
											var payment_route_html = '';
											var payment_route = response.paymentScheduleData.payment_routes;
											if(Object.keys(payment_route).length > 0){
												for(var prkey in payment_route){
													var selected = '';
													if(response.paymentScheduleData.payment_route_id == payment_route[prkey].id){
														selected = 'selected';
													}
													
													var primary = '';
													if(response.paymentScheduleData.payment_type.primary_payment_route_id == payment_route[prkey].id){
														primary = '(Primary)';
														// selected = 'selected';
													}

													payment_route_html += '<option value="'+payment_route[prkey].id+'" '+selected+'>'+payment_route[prkey].sender_service_name+' -> '+payment_route[prkey].receiver_service_name+' '+primary+'</option>';
												}
											}
											html += '<select name="ps_payment_routes['+response.paymentScheduleData.payment_type_id+'][0][payment_route_id]" id="ps_payment_route_'+response.paymentScheduleData.payment_type_id+'_dd_1" data-payment-type-id="'+response.paymentScheduleData.payment_type_id+'" data-dd-count="1" class="form-control ps_first_payment_route ps_payment_route_'+response.paymentScheduleData.payment_type_id+'" data-toggle="select2">'+payment_route_html+'</select>';
										html += '</div>';
									html += '</div>';

								html += '</div>';
								
								var dd_count = 1;
								for(var lkey in response.linkedPaymentScheduleData){
									dd_count += 1;
									var linkedPaymentSchedule = response.linkedPaymentScheduleData[lkey];
									html += '<div class="form-group col-md-12 mb-0 ps_route_container border-top pt-3">';
										html += '<input type="hidden" name="ps_payment_routes['+linkedPaymentSchedule.payment_type_id+']['+(dd_count-1)+'][payment_schedule_id]" value="'+linkedPaymentSchedule.id+'">';

										html += '<div class="form-row">';
											html += '<div class="form-group col-5 col-md-4">';
												html += '<label class="ml-2 mt-2 fs-14 bold-grey">Enable Autopay</label>';
											html += '</div>';
											html += '<div class="form-group col-7 col-md-8">';
												var checked = '';
												if(linkedPaymentSchedule.is_autopay == 1){
													checked = 'checked';
												}

												html += '<label class="switch text-center ml-2 mt-2 mb-0">';
													html += '<input type="checkbox" id="ps_autopay_'+linkedPaymentSchedule.payment_type_id+'" name="ps_payment_routes['+linkedPaymentSchedule.payment_type_id+']['+(dd_count-1)+'][is_autopay]" '+checked+'>';
													html += '<div class="slider round">';
													html += '<span class="on pr-3">Yes</span>';
													html += '<span class="off ml-1">No</span>';
													html += '</div>';
												html += '</label>';
											html += '</div>';
										html += '</div>';

										html += '<div class="form-row">';
											html += '<div class="form-group col-5 col-md-4">';
												html += '<label class="ml-2 mt-2 fs-14 bold-grey">Payment Route</label>';
											html += '</div>';
											var add_opacity = '';
											// if(linkedPaymentSchedule.disable_dd == 1){
											// 	add_opacity = 'add_opacity_7';
											// }
											html += '<div class="form-group col-6 col-md-7 '+add_opacity+'">';
												var payment_route_html = '';
												var payment_route = linkedPaymentSchedule.payment_routes;
												if(Object.keys(payment_route).length > 0){
													for(var prkey in payment_route){
														var selected = '';
														if(linkedPaymentSchedule.payment_route_id == payment_route[prkey].id){
															selected = 'selected';
														}
														
														var primary = '';
														if(linkedPaymentSchedule.payment_type.primary_payment_route_id == payment_route[prkey].id){
															primary = '(Primary)';
															// selected = 'selected';
														}

														payment_route_html += '<option value="'+payment_route[prkey].id+'" '+selected+'>'+payment_route[prkey].sender_service_name+' -> '+payment_route[prkey].receiver_service_name+' '+primary+'</option>';
													}
												}
												html += '<select name="ps_payment_routes['+linkedPaymentSchedule.payment_type_id+']['+(dd_count-1)+'][payment_route_id]" id="ps_payment_route_'+linkedPaymentSchedule.payment_type_id+'_dd_'+dd_count+'" data-payment-type-id="'+linkedPaymentSchedule.payment_type_id+'" data-dd-count="'+dd_count+'" class="form-control ps_first_payment_route ps_payment_route_'+linkedPaymentSchedule.payment_type_id+'" data-toggle="select2">'+payment_route_html+'</select>';
											html += '</div>';
											html += '<div class="form-group col-1 col-md-1 col-sm-1 col-xl-1 col-lg-1 mt-1">';
												html += '<i class="align-middle fas fa-fw fa-minus-circle fs-18 remove_payment_route" id="remove_payment_route_'+linkedPaymentSchedule.payment_type_id+'_dd_'+dd_count+'" data-dd-count="'+dd_count+'" data-ps-route-id="'+linkedPaymentSchedule.payment_route_id+'" data-payment-type-id="'+linkedPaymentSchedule.payment_type_id+'" data-ps-id="'+linkedPaymentSchedule.id+'" title="Remove Payment Route"></i>';
											html += '</div>';
										html += '</div>';

									html += '</div>';
								}

							html += '</div>';
						html += '</div>';

						if(response.months.length > 0){
							html += '<div class="rent_collection_data p-3 mb-1 bg-white border-top">';
								html += '<div class="row">';
									html += '<div class="form-group col-md-12 mb-0 ">';
										html += '<div class="form-row">';
											html += '<div class="form-group col-12 col-md-12 mb-1 ml-2">';
												html += '<label class="fs-12 grey">';
													html += 'Note : Please be aware that altering or removing any routes will affect transactions with the following statuses: <b>Pending Authorization, Transfer Authorized and Pending Collection</b>. These transactions will be either update or remove permanently. Kindly review your selections carefully before choosing a month.';
												html += '</label>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
									// html += '<div class="form-group col-md-12 mb-0 ">';
									// 	html += '<div class="form-row">';
									// 		html += '<div class="form-group col-12 col-md-12">';
									// 			html += '<strong class="ml-2 mt-2 fs-16 bold-black">Select Months</strong>';
									// 		html += '</div>';
									// 	html += '</div>';
									// html += '</div>';
									html += '<div class="form-group col-md-12 mb-0 ">';
										html += '<div class="form-row">';
											html += '<div class="form-group col-3 col-md-3">';
												html += '<label class="ml-2 mt-2 fs-16 bold-black" for="main_ps_month_checkbox">Months</label>';
											html += '</div>';
											html += '<div class="form-group col-8 col-md-8">';
												html += '<label class="mt-2 fs-16 bold-black" for="main_ps_month_checkbox">Current Payment Routes</label>';
											html += '</div>';
											html += '<div class="form-group col-1 col-md-1">';
												html += '<label class="mt-2 form-check float-right fs-14">';
													html += '<input class="form-check-input" name="main_ps_month_checkbox" id="main_ps_month_checkbox" type="checkbox">';
													html += '<span class="form-check-label">&nbsp;</span>';
												html += '</label>';
											html += '</div>';
										html += '</div>';
										for(var mkey in response.months){
											html += '<div class="form-row">';
												var checked = '';
												var add_opacity = '';
												var do_not_change = '';
												if(response.months[mkey].checked == 1){
													checked = 'checked';
													add_opacity = 'add_opacity_7';
													do_not_change = 'do_not_change';
												}

												html += '<div class="form-group col-3 col-md-3">';
													html += '<label class="ml-2 fs-14 bold-grey '+add_opacity+'" for="ps_month_'+mkey+'">'+response.months[mkey].month+'</label>';
												html += '</div>';
												html += '<div class="form-group col-8 col-md-8">';
													for(var prkey in response.months[mkey].payment_route){
														html += '<label class="fs-14 bold-grey '+add_opacity+'" for="ps_month_'+mkey+'">'+(Number(prkey)+1)+'. '+response.months[mkey].payment_route[prkey]+'</label>';
														html += '<br>';
													}
												html += '</div>';
												html += '<div class="form-group col-1 col-md-1">';
													html += '<label class="form-check float-right fs-14 '+add_opacity+'">';
														html += '<input class="form-check-input '+do_not_change+'"  id="ps_month_'+mkey+'" name="ps_months[]" type="checkbox" value="'+response.months[mkey].value+'" '+checked+'>';
														html += '<span class="form-check-label">&nbsp;</span>';
													html += '</label>';
												html += '</div>';
											html += '</div>';
										}
									html += '</div>';
								html += '</div>';
							html += '</div>';
						}

					html += '</div>';
					// html += '<div class="text-center">';
					// 	html += '<div class="form-row col-12 col-md-12">';
					// 		html += '<div class="form-group col-6 col-md-6">';
					// 			html += '<label class="form-check"><input name="applied_to_all_radio" id="applied_to_month_radio" type="radio" class="form-check-input" checked value="MONTH"><span class="form-check-label">Apply to This Month Only</span></label>';
					// 		html += '</div>';
					// 		html += '<div class="form-group col-6 col-md-6">';
					// 			html += '<label class="form-check"><input name="applied_to_all_radio" id="applied_to_all_radio" type="radio" class="form-check-input"><span class="form-check-label" value="FULL">Apply to All</span></label>';
					// 		html += '</div>';
					// 	html += '</div>';
					// html += '</div>';
					html += '<div class="text-center mt-3">';
						html += '<div class="col-auto ml-auto">';
							html += '<button type="button" class="btn btn-orange save_ps_payment_route width-100">Save</button>';
						html += '</div>';
					html += '</div>';
				html += '</form>';

				$('#sidebar_content_area').html(html);
				$(".ps_first_payment_route").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "Select Payment Route",
						dropdownParent: $(this).parent()
					});
				});

				var add_opacity = '';
				if(response.paymentScheduleData.disable_dd == 1){
					add_opacity = 'add_opacity_7';
				}

				$('#ps_payment_route_'+response.paymentScheduleData.payment_type_id+'_dd_1').parent('div').addClass(add_opacity);
				
			} else {
				$('#sidebar_content_area').html('<h3 class="text-center">'+response.response_data.message+'</h3>');
				html += '<h3 class="text-center"> No Data Available </h3>';
			}
		}
	});
}

window.getPSPaymentDetails = function(payment_schedule_id){
	$.ajax({
		method : 'POST',
		url : api_url+"payment-schedules/get-all-payment-details",
		data : 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			var html = '';
        	if(response.response_data.success == true) {
				var line = '(One time payment)';
				if(response.paymentScheduleData.payment_type.constant == 'rent' || response.paymentScheduleData.payment_type.constant == 'subscription_fee') {
					line = 'for the month of '+response.paymentScheduleData.month;
				}

            	html += '<form id="ps-payment-details-form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
						html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_'+response.paymentScheduleData.id
						+'" class="payment_schedule_hidden_input" value="'+response.paymentScheduleData.id+'">';

						html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
							html += '<div class="row border-bottom">';
								html += '<div class="form-group col-md-12 mb-0">';
									html += '<div class="form-row">';
										html += '<div class="form-group col-8 col-md-8 col-xl-8">';
											html += '<h5 class="card-title"><span class="fs-22">'+response.paymentScheduleData.payment_type.name+'</span><br> '+line+'</h5>';
										html += '</div>';
										html += '<div class="form-group col-4 col-md-4 col-xl-4">';
											html += '<div class="fs-22 black text-right"><strong>$'+currencyFormat(Number(response.paymentScheduleData.total))+'</strong></div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
							html += '<div class="rent_collection_data">';
								html += '<div class="row">';
									html += '<div class="form-group col-md-12 border-top pt-3 mb-0">';
										html += '<div class="form-row">';
											html += '<div class="form-group col-6 col-md-5">';
												html += '<div class="fs-16"><strong>Sender:</strong></div>';
											html += '</div>';
											html += '<div class="form-group col-6 col-md-7">';
												html += '<div class="fs-16">';
													if(response.paymentScheduleData.sender_user_id != 0){
														var user_html = '';
														// user_html += "<option value=''>Select User</option>";
														if(Object.keys(response.paymentScheduleData.sender_users).length > 0){
															for(var ukey in response.paymentScheduleData.sender_users){
																var selected = '';
																if(Object.keys(response.paymentScheduleData.sender_users).length == 1 || response.paymentScheduleData.sender_users[ukey].id == response.paymentScheduleData.sender_user_id){
																	selected = 'selected';
																}

																user_html += '<option value="'+response.paymentScheduleData.sender_users[ukey].id+'" '+selected+'>'+response.paymentScheduleData.sender_users[ukey].first_name+' '+response.paymentScheduleData.sender_users[ukey].last_name+' ('+response.paymentScheduleData.sender_users[ukey].email+')</option>';
															}
														}

														html += '<select name="ps_sender_user_id" id="ps_sender_user_id" class="form-control ps_get_payment_details" data-target-id="ps_sender_payment_detail_id" data-ps-detail-id="'+response.paymentScheduleData.sender_payment_detail_id+'" data-toggle="select2">'+user_html+'</select>';
													} else {
														html += '<strong>'+response.paymentScheduleData.sender_user_name+'</strong>';
														html += '<input type="hidden" name="ps_sender_user_id" id="ps_sender_user_id" value="0">';
													}
												html += '</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';

									html += '<div class="form-group col-md-12 mb-0">';
										html += '<div class="form-row">';
											html += '<div class="form-group col-6 col-md-5">';
												html += '<div class="fs-16"><strong>Payment Method:</strong></div>';
											html += '</div>';
											html += '<div class="form-group col-6 col-md-7">';
												html += '<div class="fs-16">';
													if(response.paymentScheduleData.sender_user_id != 0){
														var bank_html = '';
														// bank_html += "<option value=''>Select Payment Method</option>";
														html += '<select name="ps_sender_payment_detail_id" id="ps_sender_payment_detail_id" class="form-control ps_payment_detail" data-toggle="select2">'+bank_html+'</select>';
													} else {
														html += '<strong>'+response.paymentScheduleData.sender_payment_detail+'</strong>';
														html += '<input type="hidden" name="ps_sender_payment_detail_id" id="ps_sender_payment_detail_id" value="0">';
													}
												html += '</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';

							html += '<div class="rent_collection_data">';
								html += '<div class="row">';
									html += '<div class="form-group col-md-12 border-top pt-3 mb-0">';
										html += '<div class="form-row">';
											html += '<div class="form-group col-6 col-md-5">';
												html += '<div class="fs-16"><strong>Receiver:</strong></div>';
											html += '</div>';
											html += '<div class="form-group col-6 col-md-7">';
												html += '<div class="fs-16">';
													if(response.paymentScheduleData.receiver_user_id != 0){
														var user_html = '';
														// user_html += "<option value=''>Select User</option>";
														if(Object.keys(response.paymentScheduleData.receiver_users).length > 0){
															for(var ukey in response.paymentScheduleData.receiver_users){
																var selected = '';
																if(Object.keys(response.paymentScheduleData.receiver_users).length == 1 || response.paymentScheduleData.receiver_users[ukey].id == response.paymentScheduleData.receiver_user_id){
																	selected = 'selected';
																}

																user_html += '<option value="'+response.paymentScheduleData.receiver_users[ukey].id+'" '+selected+'>'+response.paymentScheduleData.receiver_users[ukey].first_name+' '+response.paymentScheduleData.receiver_users[ukey].last_name+' ('+response.paymentScheduleData.receiver_users[ukey].email+')</option>';
															}
														}
														html += '<select name="ps_receiver_user_id" id="ps_receiver_user_id" class="form-control ps_get_payment_details" data-target-id="ps_receiver_payment_detail_id" data-ps-detail-id="'+response.paymentScheduleData.receiver_payment_detail_id+'" data-toggle="select2">'+user_html+'</select>';
													} else {
														html += '<strong>'+response.paymentScheduleData.receiver_user_name+'</strong>';
														html += '<input type="hidden" name="ps_receiver_user_id" id="ps_receiver_user_id" value="0">';
													}
												html += '</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';

									html += '<div class="form-group col-md-12 mb-0">';
										html += '<div class="form-row">';
											html += '<div class="form-group col-6 col-md-5">';
												html += '<div class="fs-16"><strong>Payment Method:</strong></div>';
											html += '</div>';
											html += '<div class="form-group col-6 col-md-7">';
												html += '<div class="fs-16">';
													if(response.paymentScheduleData.receiver_user_id != 0){
														var bank_html = '';
														// bank_html += "<option value=''>Select Payment Method</option>";
														html += '<select name="ps_receiver_payment_detail_id" id="ps_receiver_payment_detail_id" class="form-control ps_payment_detail" data-toggle="select2">'+bank_html+'</select>';
													} else {
														html += '<strong>'+response.paymentScheduleData.receiver_payment_detail+'</strong>';
														html += '<input type="hidden" name="ps_receiver_payment_detail_id" id="ps_receiver_payment_detail_id" value="0">';
													}
												html += '</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
					html += '<div class="text-center mt-3">';
						html += '<div class="col-auto ml-auto">';
							html += '<button type="button" class="btn btn-orange save_ps_payment_details width-100">Save</button>';
						html += '</div>';
					html += '</div>';
				html += '</form>';

				$('#sidebar_content_area').html(html);
				$('.ps_get_payment_details').trigger('change');
				psPaymentDetailsValidation();

				$(".ps_get_payment_details").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "Select User",
							dropdownParent: $(this).parent()
						});
				})

				$(".ps_payment_detail").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "Select Payment Method",
							dropdownParent: $(this).parent()
						});
				})
			} else {
				$('#sidebar_content_area').html('<h3 class="text-center">'+response.response_data.message+'</h3>');
				html += '<h3 class="text-center"> No Data Available </h3>';
			}
		}
	});
}

window.savePSPaymentRouteDetails = function(id = 0){
	$.ajax({
		method : 'POST',
		url : api_url+"payment-schedules/save-payment-route-details",
		data : 'user_id='+user_id+'&user_service_name='+user_service_name+'&'+$('#ps-payment-change-route-form').serialize(),
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			$('.save_ps_payment_route').attr('disabled', false);
        	if(response.response_data.success == true) {
				closeRightSidebar();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
			}
		}
	});
}

window.savePSPaymentDetails = function(){
	$.ajax({
		method : 'POST',
		url : api_url+"payment-schedules/save-payment-details",
		data : 'user_id='+user_id+'&user_service_name='+user_service_name+'&'+$('#ps-payment-details-form').serialize(),
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			$('.save_ps_payment_details').attr('disabled', false);
        	if(response.response_data.success == true) {
				closeRightSidebar();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
			}
		}
	});
}

window.getFilterData = function(){
	var html = ''
	var property_id = 0;
	if($('#selected_property_id').val()){
		property_id = $('#selected_property_id').val();
	} else {
		property_id = $('#ps_filter_property_name').val();
	}

	if (typeof property_id === "undefined") {
		property_id = 0;
	}

	var filters = {};
	filters.property_id = property_id;
	filters.lease_ids = $('#ps_filter_lease_id').val();
	filters.startDate = $('#ps_filter_start_date > input').val();
	filters.endDate = $('#ps_filter_end_date > input').val();

	filters.po = $('#ps_filter_po_name').val();

	// Get Selected Users associated with property data
	filters.users = $('#ps_filter_po_users').val();

	filters.payment_status = $('#ps_filter_payment_status').val();

	$('#ps_filter_data_container').html(html);
	$('#ps_filter_data_container').addClass('m-3');
	localStorage.setItem('ps_filter_data', $('#ps_filter_data_container').html());

	return JSON.stringify(filters);
}

// window.OLDREMOVEgetMyAccountPaymentScheduleWidget = function() {
// 	var user_id = user.user_details.user_id;
// 	var filters = getFilterData();
// 	openLoadingDialog($('#payment_schedule_container'));

// 	setTimeout(function(){
// 		$.ajax({
// 			url: api_url+"payment-schedules",
// 			type: 'POST',
// 			data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&filters='+filters,
// 			beforeSend: function(xhr) {
// 				xhr.setRequestHeader('Authorization', accessToken);
// 			},
// 			success: function(response) {
// 				var html = '';
// 				$('#datatable_buttons').html('');
// 				if(response.response_data.success == true) {
// 					if(response.response_data.type == 'combine_data') {
// 						var data = response.response_data.data;
// 						html += '<table id="combine_payment_scheduled_datatable" class="table dt-responsive nowrap" style="width:100%; table-layout:fixed;">';
// 							html += '<thead class="bold-black fs-16">';
// 								html += '<tr>';
// 									html += '<th class="" data-priority="1">Payment Type</th>';
// 									html += '<th class="">Month</th>';
// 									html += '<th class="text-right">Amount</th>';
// 									html += '<th class="">&nbsp;</th>';
// 								html += '</tr>';
// 							html += '</thead>';
// 							html += '<tbody class="bold-black fs-14">';
// 								for(var key in data){
// 									var num = 0;
// 									for(var pkey in data[key]){
// 										var new_key = key.replace(/ /g, "_");
// 										if(num == 0) {
// 											html += '<tr class="payment_type_tr"  data-id="'+new_key+'">';
// 											html += '<td class="fs-14">'+key+'</td>';
// 											num++;
// 										} else {
// 											html += '<tr class="payment_type_child_tr_'+new_key+'">';
// 											html += '<td class="fs-14 grey">'+key+'</td>';
// 										}
// 										html += '<td class="fs-14">'+pkey+'</td>';
// 										html += '<td class="fs-14 text-right">'+data[key][pkey].total+'</td>';
// 										html += '<td class="text-center associate_payment_details" data-id="'+data[key][pkey].payment_scheduled_ids+'" data-type="'+response.response_data.type+'" data-amount="'+data[key][pkey].total+'" data-payment-type="'+pkey+'"><i class="align-middle mr-2 fas fa-fw fa-eye"></i></td>';
// 										html += '</tr>';
// 									}
// 								}
// 							html += '</tbody>';
// 						html += '</table>';
// 						$('#payment_schedule_container').html(html);

// 						$('#combine_payment_scheduled_datatable').DataTable({
// 							"searching": false,
// 							"lengthChange": false,
// 							"info": false,
// 							"autoWidth": false,
// 							"paging": false,
// 							"ordering": false,
// 							"sortable": false,
// 							"responsive": { breakpoints: [
// 								{ name: 'desktop', width: Infinity },
// 								{ name: 'tablet',  width: 1024 },
// 								{ name: 'phone',   width: 768 }
// 							]},
// 							"columns": [
// 								{"className": "all", "width": '40%'},
// 								{"className": "all", "width": '25%'},
// 								{"className": "min-tablet", "width": '25%'},
// 								{"className": "all", "width": '10%' }
// 							],
// 							"dom": '<lf<t>ip>',
// 							"fixedHeader": true,
// 							"pageLength": -1
// 						});
// 					} else {
// 						var data = response.response_data.data;
// 						html += '<table id="separate_payment_scheduled_datatable" class="table dt-responsive nowrap" style="width:100%; table-layout:fixed;">';
// 							html += '<thead class="bold-black fs-16">';

// 								// let header_kebab_item = '';
// 								// let header_kebab = '';
// 								// if(user_service_name == sa_role){
// 								// 	header_kebab_item += '<a href="javascript:void(0)" data-type="disable" data-from="payment_schedule" class="dropdown-item change_ps_payment_details add_opacity" data-title="Change Payment Details">Change Payment Details</a>';

// 								// 	if(header_kebab_item != ''){
// 								// 		header_kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v ml-auto mr-auto" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right">'+header_kebab_item+'</div></div></div>';
// 								// 	}
// 								// }

// 								// var cnt = 0;
// 								// for(var key in data){
// 								// 	if(cnt == 0) {
// 										html += '<tr>';
// 											// html += '<th class="fs-18">&nbsp;</th>';
// 											html += '<th class="" data-priority="1">Payment Type</th>';
// 											html += '<th class="">ID</th>';
// 											html += '<th class="">Due Date</th>';
// 											html += '<th class="">Month</th>';
// 											html += '<th class="text-right">Amount</th>';
// 											html += '<th class="">Sender</th>';
// 											html += '<th class="">Receiver</th>';
// 											html += '<th class="">Coupon Detail</th>';
// 											html += '<th class="">Autopay</th>';
// 											html += '<th class="">Status</th>';
// 											html += '<th class="text-right">View</th>';
// 											html += '<th class="">Action</th>';
// 										html += '</tr>';
// 										// cnt++;
// 								// 	}
// 								// }
// 							html += '</thead>';
// 							html += '<tbody class="bold-black fs-14">';
// 								for(var key in data){
// 									for(var pkey in data[key]){
// 										var num = 0;
// 										for(var dkey in data[key][pkey]){

// 											let kebab_item = '';
// 											let kebab = '';

// 											if(data[key][pkey][dkey].sender_user_id == user_id) {
// 												if(data[key][pkey][dkey].status_constant_name == 'pending_authorization'){
// 													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Authorize Payment">Authorize Payment</a>';
// 												} else if(data[key][pkey][dkey].status_constant_name == 'pending_collection'){
// 													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Review Payment">Review Payment</a>';
// 												}
// 											}

// 											if(user_service_name == sa_role){
// 												if(data[key][pkey][dkey].old_record == 0 && (data[key][pkey][dkey].status_constant_name == 'failed_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_transfer')){
// 													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" data-payment-type="'+data[key][pkey][dkey].payment_type_id+'" class="dropdown-item regenerate_payment_schedule">Regenerate Transaction</a>';
// 												}

// 												if(data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'pending_collection'){
// 													if(data[key][pkey][dkey].lease && data[key][pkey][dkey].lease.partial_payment_enabled == 1 && (data[key][pkey][dkey].payment_type.constant == 'rent' || data[key][pkey][dkey].payment_type.constant == 'security_deposit' || data[key][pkey][dkey].payment_type.constant == 'pet_deposit')){
// 														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item get_partial_payment_data">Create Partial Payment</a>';
// 													}

// 													if(data[key][pkey][dkey].fee_applied == 1){
// 														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item waive_payment_schedule_late_fee">Waive Fee</a>';
// 													}
// 												}

// 												if(data[key][pkey][dkey].payment_type.constant != 'property_setup_fee' && (data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized' || data[key][pkey][dkey].status_constant_name == 'pending_collection')){
// 													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item mark_ps_as_paid">Mark As Paid</a>';
// 												}

// 												if(data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized'){
// 													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][pkey][dkey].id+'" class="dropdown-item change_ps_payment_details" data-title="Change Payment Details">Change Payment Details</a>';
// 												}
// 											}

// 											if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
// 												if(data[key][pkey][dkey].status_constant_name == 'transfer_authorized') {
// 													kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][pkey][dkey].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
// 												} else if(data[key][pkey][dkey].status_constant_name == 'transfer_in_progress') {
// 													kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][pkey][dkey].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
// 												}
// 											}

// 											if(kebab_item != '' && (data[key][pkey][dkey].sender_user_id == user_id || user_service_name == sa_role)){
// 												kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v ml-auto mr-auto" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right">'+kebab_item+'</div></div></div>';
// 											}

// 											var status = '';
// 											if(data[key][pkey][dkey].status_constant_name == 'pending_authorization'){
// 												status = '<span class="badge badge-grey">'+data[key][pkey][dkey].status+'</span>';
// 											} else if (data[key][pkey][dkey].status_constant_name == 'failed_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][pkey][dkey].status_constant_name == 'cancelled_transfer' || data[key][pkey][dkey].status_constant_name == 'deleted'){
// 												status = '<span class="badge badge-red">'+data[key][pkey][dkey].status+'</span>';
// 											} else if (data[key][pkey][dkey].status_constant_name == 'transfer_in_progress' || data[key][pkey][dkey].status_constant_name == 'processing') {
// 												status = '<span class="badge badge-yellow">'+data[key][pkey][dkey].status+'</span>';
// 											} else if (data[key][pkey][dkey].status_constant_name == 'processed') {
// 												status = '<span class="badge badge-green">'+data[key][pkey][dkey].status+'</span>';
// 											} else if (data[key][pkey][dkey].status_constant_name == 'transfer_authorized') {
// 												status = '<span class="badge badge-blue">'+data[key][pkey][dkey].status+'</span>';
// 											} else {
// 												status = '<span class="badge badge-grey">'+data[key][pkey][dkey].status+'</span>';
// 											}

// 											if(user_service_name != sa_role || data[key][pkey][dkey].payment_type.constant == 'property_setup_fee' || data[key][pkey][dkey].associated_payments == 1 || (data[key][pkey][dkey].status_constant_name != 'pending_authorization' && data[key][pkey][dkey].status_constant_name != 'transfer_authorized')) {
// 												var disabled = 'disabled';
// 												var add_opacity = 'add_opacity';
// 											} else {
// 												var disabled = '';
// 												var add_opacity = '';
// 											}

// 											if(data[key][pkey][dkey].is_autopay == 0){
// 												var checked = '';
// 											} else {
// 												var checked = 'checked';
// 											}

// 											html += '<tr class="payment_schedule_tr_'+data[key][pkey][dkey].id+'">';

// 												// if(user_service_name == sa_role && (data[key][pkey][dkey].status_constant_name == 'pending_authorization' || data[key][pkey][dkey].status_constant_name == 'transfer_authorized')){
// 												// 	html += '<td class="text-center">';
// 												// 		html += '<label class="custom-control custom-checkbox" style="display:inline;">';
// 												// 			html += '<input type="checkbox" class="custom-control-input ps_parent_options" name="ps_list[]" id="ps_parent_options_'+data[key][pkey][dkey].id+'" data-id="'+data[key][pkey][dkey].id+'">';
// 												// 			html += '<span class="custom-control-label">&nbsp;</span>';
// 												// 		html += '</label>';
// 												// 	html += '</td>';
// 												// } else {
// 												// 	html += '<td>&nbsp;</td>';
// 												// }

// 												if(num == 0) {
// 													html += '<td>'+pkey+'</td>';
// 													num++;
// 												} else {
// 													html += '<td class="grey">'+pkey+'</td>';
// 												}

// 												html += '<td>'+data[key][pkey][dkey].id+'</td>';

// 												if(data[key][pkey][dkey].due_date) {
// 													html += '<td>'+data[key][pkey][dkey].due_date+'</td>';
// 												} else {
// 													html += '<td>-</td>';
// 												}

// 												if(data[key][pkey][dkey].month) {
// 													html += '<td>'+data[key][pkey][dkey].month+'</td>';
// 												} else {
// 													html += '<td>-</td>';
// 												}

// 												html += '<td class="text-right">'+data[key][pkey][dkey].total+'</td>';

// 												html += '<td>';
// 													html += '<div>';
// 														html += data[key][pkey][dkey].sender_data.name+'<br>';
// 														html += data[key][pkey][dkey].sender_data.email+'<br>';
// 														html += data[key][pkey][dkey].sender_data.bank_name+'<br>';
// 														html += data[key][pkey][dkey].sender_data.ach;
// 													html += '</div>';
// 												html += '</td>';
// 												html += '<td>';
// 													html += '<div>';
// 														html += data[key][pkey][dkey].receiver_data.name+'<br>';
// 														html += data[key][pkey][dkey].receiver_data.email+'<br>';
// 														html += data[key][pkey][dkey].receiver_data.bank_name+'<br>';
// 														html += data[key][pkey][dkey].receiver_data.ach;
// 													html += '</div>';
// 												html += '</td>';

// 												if(data[key][pkey][dkey].coupon_data){
// 													html += '<td>';
// 														html += '<div>';
// 															html += 'Code: '+data[key][pkey][dkey].coupon_data.coupon_code+'<br>';
// 															html += 'Amount: '+data[key][pkey][dkey].coupon_data.coupon_amount+'<br>';
// 														html += '</div>';
// 													html += '</td>';
// 												} else {
// 													html += '<td class="text-center">-</td>';
// 												}

// 												// html += '<td class="text-center">';
// 												// 	html += '<label class="custom-control custom-checkbox" style="display:inline;">';
// 												// 		html += '<input type="checkbox" class="custom-control-input update_auto_pay" '+checked+' '+disabled+' id="auto_pay_'+data[key][pkey][dkey].id+'" data-id="'+data[key][pkey][dkey].id+'">';
// 												// 		html += '<span class="custom-control-label">&nbsp;</span>';
// 												// 	html += '</label>';
// 												// html += '</td>';

// 												html += '<td>';
// 													html += '<label class="switch text-center mt-1 mb-0 '+add_opacity+'">';
// 														html += '<input type="checkbox" class="update_auto_pay" id="auto_pay_'+data[key][pkey][dkey].id+'" data-id="'+data[key][pkey][dkey].id+'" '+checked+'>';
// 														html += '<div class="slider round">';
// 															html += '<span class="on pr-3">Yes</span>';
// 															html += '<span class="off ml-1">No</span>';
// 														html += '</div>';
// 													html += '</label>';
// 												html += '</td>';

// 												html += '<td>'+status+'</td>';

// 												// if(data[key][pkey][dkey].associated_payments == 1){
// 													html += '<td class="text-right associate_payment_details" data-id="'+data[key][pkey][dkey].id+'" data-type="separate"><i class="align-middle fas fa-fw fa-eye ml-4"></i></td>';
// 												// } else {
// 												// 	html += '<td class="text-center">&nbsp;</td>';
// 												// 	//html += '<td class="text-center associate_payment_details" data-id="'+data[key][pkey][dkey].id+'" data-type="separate"><i class="align-middle mr-2 fas fa-fw fa-eye grey"></i></td>';
// 												// }

// 												if(kebab_item != '') {
// 													html += '<td>'+kebab+'</td>';
// 												} else {
// 													html += '<td>&nbsp;</td>';
// 												}
// 											html += '</tr>';
// 										}
// 									}
// 								}
// 							html += '</tbody>';
// 						html += '</table>';
// 						$('#payment_schedule_container').html(html);

// 						$('#separate_payment_scheduled_datatable').DataTable({
// 							"searching": false,
// 							"lengthChange": false,
// 							"info": false,
// 							"autoWidth": false,
// 							"paging": false,
// 							"ordering": false,
// 							"sortable": false,
// 							"responsive": { breakpoints: [
// 								{ name: 'desktop', width: Infinity },
// 								{ name: 'tablet',  width: 1024 },
// 								{ name: 'phone',   width: 768 }
// 							]},
// 							"columnDefs": [
// 								{
// 									"visible": false,
// 									"targets": [1,2,5,6,7]
// 								},
// 								{ "className": "all", "width": "20%", "targets": [0] }, //payment type
// 								{ "className": "min-tablet", "width": "3%", "targets": [1] }, //id
// 								// { "className": "all", "width": "14.25%", "targets": [2]}, //due date
// 								{ "className": "all", "width": "14.25%", "targets": [3]}, //month
// 								{ "className": "min-tablet", "width": "17.25%", "targets": [4]}, //amount
// 								{ "className": "min-tablet", "width": "11.25%", "targets": [8]}, //autopay
// 								{ "className": "min-tablet", "width": "16.25%", "targets": [9]}, //status
// 								{ "className": "all", "width": "6.5%", "targets": [10]}, //view
// 								{ "className": "all", "width": "6.5%", "targets": [11]}, //action
// 								{
// 									"targets": [2],
// 									"type": 'date'
// 								},
// 							],
// 							"dom": 'Bfrtip',
// 							"fixedHeader": true,
// 							"pageLength": -1,
// 							"buttons": [
// 								{
// 								   "extend": 'colvis',
// 								   "text": "<i class='align-middle mr-2 fas fa-fw fa-eye'></i>",
// 								   "collectionLayout": 'two-column',
// 								   "postfixButtons": ['colvisRestore'],
// 								}
// 							],
// 							"language": {
// 								"buttons": {
// 									//colvisRestore: "Select All",
// 									"colvisRestore": "Restore"
// 								}
// 							}
// 						});

// 						$('.buttons-colvis').appendTo('#datatable_buttons');
// 						$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-orange');
// 					}
// 				} else {
// 					//display_error_message(response);
// 					$('#payment_schedule_container').html('<h4 style="text-align: center;height: 50px;padding: 22px;">'+response.response_data.message+'</h4>');
// 				}
// 			}
// 		});
// 	}, 500);
// }

window.getCombinedPaymentData = function(response) {
	var data = response.response_data.data;
	var html = '';
	html += '<div class="col-12">';
		html += '<div class="card mb-2">';
			html += '<h3 class="pl-2 pb-2" >Combined Data <span class="float-right fs-16 mr-4 mt-1">(Monthwise all property data)</span></h3>';
		html += '</div>';
	html += '</div>';
	html += '<table id="combine_payment_scheduled_datatable" class="table dt-responsive nowrap" style="width:100%; table-layout:fixed;">';
		html += '<thead class="bold-black fs-16">';
			html += '<tr>';
				html += '<th class="" data-priority="1">Month</th>';
				html += '<th class="">Payment Type</th>';
				html += '<th class="text-right">Amount</th>';
				html += '<th class="">&nbsp;</th>';
			html += '</tr>';
		html += '</thead>';
		html += '<tbody class="bold-black fs-14">';
			for(var key in data){
				var num = 0;
				html += '<tr class="badge-grey">';
					html += '<td colspan="3">'+key+'</td>';
					html += '<td class="text-right">'+data[key].finalAmount+'</td>';
				html += '</tr>';
				for(var pkey in data[key]){
					if(pkey != 'finalAmount') {
						var new_key = key.replace(/ /g, "_");
						if(num == 0) {
							html += '<tr class="payment_type_tr"  data-id="'+new_key+'">';
							//html += '<td class="fs-14 white">'+key+'</td>';
							html += '<td class="fs-14 white">&nbsp;</td>';
							num++;
						} else {
							html += '<tr class="payment_type_child_tr_'+new_key+'">';
							//html += '<td class="fs-14 white">'+key+'</td>';
							html += '<td class="fs-14 white">&nbsp;</td>';
						}
						html += '<td class="fs-14">'+pkey+'</td>';
						html += '<td class="fs-14 text-right">'+data[key][pkey].total+'</td>';
						if(pkey == 'Bank To Balance'){
							html += '<td class="fs-14">&nbsp;</td>';
						} else {
							html += '<td class="text-center associate_payment_details" data-id="'+data[key][pkey].payment_scheduled_ids+'" data-type="'+response.response_data.type+'" data-amount="'+data[key][pkey].total+'" data-payment-type="'+pkey+'"><i class="align-middle mr-2 fas fa-fw fa-eye"></i></td>';
						}
						html += '</tr>';
					}
				}
			}
		html += '</tbody>';
	html += '</table>';
	$('#payment_schedule_container').html(html);

	$('#combine_payment_scheduled_datatable').DataTable({
		"searching": false,
		"lengthChange": false,
		"info": false,
		"autoWidth": false,
		"paging": false,
		"ordering": false,
		"sortable": false,
		"responsive": { breakpoints: [
			{ name: 'desktop', width: Infinity },
			{ name: 'tablet',  width: 1024 },
			{ name: 'phone',   width: 768 }
		]},
		"columns": [
			{"className": "all", "width": '40%'},
			{"className": "all", "width": '25%'},
			{"className": "min-tablet", "width": '25%'},
			{"className": "all", "width": '10%' }
		],
		"dom": '<lf<t>ip>',
		"fixedHeader": true,
		"pageLength": -1
	});
}

window.getSeparatePaymentData = function(response) {
	var html = '';
	html += '<div class="col-12 text-right">';
		html += '<div class="card mb-2">';
			html += '<div class="card-body">';
				html += '<span data-type="collapse" class="expense_collapse_transaction_month_accordion fs-16 bold-black pointer">Collapse All</span>';
			html += '</div>';
		html += '</div>';
	html += '</div>';

	var data = response.response_data.data;
	for(var key in data){
		// get current month
		var current_month = new Date().toLocaleString('en-us',{month:'long', year:'numeric'});

		var mkeyNumber = 0;
		for(var mkey in data[key]){
			var display_accordion = '';
			var border_radius_0 = '';
			if(current_month == mkey) {
				display_accordion = 'show';
				border_radius_0 = 'border-bottom-left-radius0 border-bottom-right-radius0';
			}

			html += '<div class="accordion payment_report_accordion" data-id="'+mkeyNumber+'" id="transactionMonthAccordion'+mkeyNumber+'">';
				html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';

					if($(window).width() < 767 || isTablet()) {
						html += '<div class="pt-2 pb-2 badge-grey" id="transactionMonthHeading'+mkeyNumber+'" data-toggle="collapse" data-target="#transactionMonthCollapse'+mkeyNumber+'" aria-expanded="true" aria-controls="transactionMonthCollapse'+mkeyNumber+'" style="cursor:pointer;">';
					} else {
						html += '<div class="p-1 badge-grey '+border_radius_0+'" id="transactionMonthHeading'+mkeyNumber+'" data-toggle="collapse" data-target="#transactionMonthCollapse'+mkeyNumber+'" aria-expanded="true" aria-controls="transactionMonthCollapse'+mkeyNumber+'" style="cursor:pointer;">';											
					}

						html += '<div class="row mt-1 mr-1 mb-1 ml-0">';
							html += '<div class="col-12 col-md-12">';
								html += '<h4 class="card-title my-2 fs-18 bold-black">'+mkey+'</h4>';
								if(current_month == mkey) {
									html += '<span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up"></i></span>';
								} else {
									html += '<span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
								}
							html += '</div>';
						html += '</div>';
					html += '</div>';

					if($(window).width() < 767 || isTablet()) {
						html += '<div id="transactionMonthCollapse'+mkeyNumber+'" class="mt-3 collapse '+display_accordion+'" aria-labelledby="transactionMonthHeading'+mkeyNumber+'" data-parent="#transactionMonthAccordion'+mkeyNumber+'">';

						html += '<div class="card mb-0 property_card">';
					} else {
						html += '<div id="transactionMonthCollapse'+mkeyNumber+'" class="border border-bottom-left-radius10 border-bottom-right-radius10 collapse '+display_accordion+'" aria-labelledby="transactionMonthHeading'+mkeyNumber+'" data-parent="#transactionMonthAccordion'+mkeyNumber+'">';

						html += '<div class="card mb-0">';
					}

							// Display payment types
							var num = 0;
							for(var dkey in data[key][mkey]){
								if($(window).width() > 767 && !isTablet()) {

									var bg_percentage_color = 'bg-warning';
									var width = data[key][mkey][dkey]['calc']['payment_completed_percentage'];
									var color = 'white';
									if(Number(data[key][mkey][dkey]['calc']['payment_completed_percentage']) > 0 && Number(data[key][mkey][dkey]['calc']['payment_completed_percentage']) < 100){
										bg_percentage_color = 'bg-warning';
									} else if (Number(data[key][mkey][dkey]['calc']['payment_completed_percentage']) == 0){
										bg_percentage_color = 'bg-lightgrey';
										width = 100;
										color = 'black';
									} else if(Number(data[key][mkey][dkey]['calc']['payment_completed_percentage'] == 100)) {
										bg_percentage_color = 'bg-success';
									}

									html += '<div class="card-header bg-light"><h5 class="card-title capitalize bold-black mb-0 mr-3 pl-0 col-4" >'+dkey;
										html += '<div class="progress">';
											html += '<div class="progress-bar '+bg_percentage_color+' '+color+'" role="progressbar" style="width: '+width+'%" aria-valuenow="'+data[key][mkey][dkey]['calc']['payment_completed_percentage']+'" aria-valuemin="0" aria-valuemax="100">'+Number(data[key][mkey][dkey]['calc']['completed_payments'])+'  /  '+Number(data[key][mkey][dkey]['calc']['total_records'])+'</div>';
										html += '</div>';
									html += '</h5></div>';
								}

								// Display Payment schedule detail
								var num = 0;
								for(var pkey in data[key][mkey][dkey]) {
									// rkry = Route array loop

									// if pkey != calc because in calc we are calculating percentage
									if(pkey != 'calc'){
										if($(window).width() < 767 || isTablet()) {
											html += '<div class="">';
										} else {
											html += '<div class="border-top border-bottom">';
										}
											for(var rkey in data[key][mkey][dkey][pkey]) {
												let kebab_item = '';
												let kebab = '';

												if(data[key][mkey][dkey][pkey][rkey].sender_user_id == user_id || (data[key][mkey][dkey][pkey][rkey].sender_user_id == 0 && user_service_name == sa_role)) {
													if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization'){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Authorize Payment">Authorize Payment</a>';
													} else if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection'){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Review Payment">Review Payment</a>';
													}
												}
												
												if(user_service_name == sa_role){
													if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'deleted'){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type_id+'" class="dropdown-item undo_delete_ps">Un-Delete</a>';
													}

													if(data[key][mkey][dkey][pkey][rkey].old_record == 0 && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'failed_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_transfer')){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type_id+'" class="dropdown-item regenerate_payment_schedule">Regenerate Transaction</a>';
													}
												
													if(data[key][mkey][dkey][pkey][rkey].sender_user_id > 0 && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')){
														if(data[key][mkey][dkey][pkey][rkey].lease && data[key][mkey][dkey][pkey][rkey].lease.partial_payment_enabled == 1 && (data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'rent' || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'security_deposit' || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'pet_deposit')){
															kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item get_partial_payment_data">Create Partial Payment</a>';
														}
												
														if(data[key][mkey][dkey][pkey][rkey].fee_applied == 1){
															kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item waive_payment_schedule_late_fee">Waive Fee</a>';
														}
													}
												
													if(data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'rs_bank_to_balance' && data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'property_setup_fee' && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item mark_ps_as_paid">Mark As Paid</a>';

														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item mark_ps_as_deleted">Mark As Deleted</a>';
													}

													if(data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'rs_bank_to_balance' && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized')){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item change_ps_payment_details" data-title="Change Payment Details">Change Payment Details</a>';

														if(data[key][mkey][dkey][pkey][rkey].enable_change_route == 1){
															kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item change_ps_payment_route" data-title="Change Payment Route">Change Payment Route</a>';
														}
													}

													if((data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')) {
														if(data[key][mkey][dkey][pkey][rkey].trn_late_fee_enabled == true){
															kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Disable Late Fee</a>';
														} else {
															kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="enable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Enable Late Fee</a>';
														}

														kebab_item += '<a class="dropdown-item change_payment_due_date" href="javascript:void(0)" data-due-date="'+data[key][mkey][dkey][pkey][rkey].due_date+'" data-month="'+data[key][mkey][dkey][pkey][rkey].month+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type.name+'"  data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Change Payment Due Date</a>';
													}
												}
												
												if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
													if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized') {
														kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
													} else if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_in_progress') {
														kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
													}
												}
												
												if(kebab_item != '' && (data[key][mkey][dkey][pkey][rkey].sender_user_id == user_id || user_service_name == sa_role)){
													kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v ml-auto mr-auto mt-2" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right">'+kebab_item+'</div></div></div>';
												}
												
												var status = '';
												var bg_color = '';
												if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization'){
													status = '<span class="badge badge-grey">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
													bg_color = 'badge-grey';
												} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'failed_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'deleted'){
													status = '<span class="badge badge-red">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
													bg_color = 'badge-red';
												} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_in_progress' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'processing') {
													status = '<span class="badge badge-yellow">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
													bg_color = 'badge-yellow';
												} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'processed') {
													status = '<span class="badge badge-green">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
													bg_color = 'badge-green';
												} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized') {
													status = '<span class="badge badge-blue">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
													bg_color = 'badge-blue';
												} else {
													status = '<span class="badge badge-grey">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
													bg_color = 'badge-grey';
												}
												
												if(user_service_name != sa_role || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'property_setup_fee' || data[key][mkey][dkey][pkey][rkey].associated_payments == 1 || (data[key][mkey][dkey][pkey][rkey].status_constant_name != 'pending_authorization' && data[key][mkey][dkey][pkey][rkey].status_constant_name != 'transfer_authorized')) {
													var add_opacity = 'add_opacity';
												} else {
													var add_opacity = '';
												}
												
												if(data[key][mkey][dkey][pkey][rkey].is_autopay == 0){
													var checked = '';
												} else {
													var checked = 'checked';
												}

												html += '<div class="payment_type_tran_container payment_schedule_tr_'+data[key][mkey][dkey][pkey][rkey].id+'">';
													var sender_bank = '';
													if(data[key][mkey][dkey][pkey][rkey].sender_data.bank_name != '') {
														sender_bank = '<small class="fs-10">'+data[key][mkey][dkey][pkey][rkey].sender_data.bank_name+'</small>';
													}

													var receiver_bank = '';
													if(data[key][mkey][dkey][pkey][rkey].receiver_data.bank_name != '') {
														receiver_bank = '<small class="fs-10">'+data[key][mkey][dkey][pkey][rkey].receiver_data.bank_name+'</small>';
													}

													if($(window).width() < 767 || isTablet()) {
														html += '<div class="row">';
															html += '<div class="col-12">';
																html += '<div class="card border">';
																	html += '<div class="card-header bg-light">';
																		html += '<div class="row">';
																			html += '<div class="col-10">';
																				html += '<div class="no-gutters mt-1">';
																					html += '<h3>'+dkey+'</h3>';
																				html += '</div>';
																			html += '</div>';
																			// html += '<div class="col-2">';
																			// 	html += '<div class="no-gutters mt-2">';
																			// 		html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate"><i class="align-middle fas fa-fw fa-eye fs-16"></i></div>';
																			// 	html += '</div>';
																			// html += '</div>';
																			html += '<div class="col-2">';
																				html += '<div class="no-gutters">';
																					if(kebab_item != '') {
																						html += kebab;
																					}
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';

																	html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate">';
																		html += '<div class="card-header text-center mt-2"><h1 style="font-size:2.50rem;">'+data[key][mkey][dkey][pkey][rkey].total+'</h1></div>';
																		html += '<div class="card-header mb-0">';
																			html += '<div class="row">';
																				html += '<div class="col-5 text-center">';
																					html += '<div class="no-gutters">';
																						html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].sender_data.name+'</div>'+sender_bank;
																					html += '</div>';
																				html += '</div>';
																				html += '<div class="col-2 text-center">';
																					html += '<div class="no-gutters">';
																						html += '<i class="align-middle mr-2 mt-2 fas fa-fw fa-arrow-right"></i>';
																					html += '</div>';
																				html += '</div>';
																				html += '<div class="col-5 text-center">';
																					html += '<div class="no-gutters">';
																						html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].receiver_data.name+'</div>'+receiver_bank;
																					html += '</div>';
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																	html += '<div class="card-header bg-light">';
																		html += '<table class="table-sm border_top_0">';
																			html += '<tbody>';
																				html += '<tr>';
																					html += '<td class="text-left width-100 border_top_0">'+status+'</td>';
																					html += '<td class="text-right border_top_0">';
																						html += '<table class="table-sm border_top_0">';
																							html += '<tr>';
																								html += '<td class="fs-12 bold-black border_top_0">Autopay</td>';
																								html += '<td class="border_top_0">';
																									html += '<label class="switch text-center mt-0 mb-0 '+add_opacity+'">';
																										html += '<input type="checkbox" class="update_auto_pay" id="auto_pay_'+data[key][mkey][dkey][pkey][rkey].id+'" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" '+checked+'>';
																										html += '<div class="slider round">';
																											html += '<span class="on pr-3">Yes</span>';
																											html += '<span class="off ml-1">No</span>';
																										html += '</div>';
																									html += '</label>';
																								html += '</td>';
																							html += '</tr>';
																						html += '</table>';
																					html += '</td>';
																				html += '</tr>';
																			html += '</tbody>';
																		html += '</table>';
																	html += '</div>';

																html += '</div>';
															html += '</div>';
														html += '</div>';
													} else {
														html += '<div class="row">';
															html += '<div class="col-12 col-md-12 col-lg-4">';
																html += '<div class="card no_box_shadow mb-0">';
																	html += '<div class="card-body">';
																		html += '<div class="row">';
																			html += '<div class="col-5 col-md-5 col-xl-5">';
																				html += '<div class="no-gutters mt-2">';
																					html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].sender_data.name+'</div>'+sender_bank;
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-2 col-md-2 col-xl-2">';
																				html += '<div class="no-gutters mt-2">';
																					html += '<i class="align-middle mr-2 mt-2 fas fa-fw fa-arrow-right"></i>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-5 col-md-5 col-xl-5">';
																				html += '<div class="no-gutters mt-2">';
																					html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].receiver_data.name+'</div>'+receiver_bank;
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
															html += '<div class="col-12 col-md-12 col-lg-6">';
																html += '<div class="card no_box_shadow mb-0">';
																	html += '<div class="card-body">';
																		html += '<div class="row">';
																			html += '<div class="col-3 col-md-3 col-xl-3">';
																				html += '<div class="no-gutters">';
																					html += '<small class="bold-grey ml-2 fs-10">Due Date</small><br>';
																					html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].due_date+'</div>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-3 col-md-3 col-xl-3">';
																				html += '<div class="no-gutters">';
																					html += '<small class="bold-grey ml-2 fs-10">Amount</small><br>';
																					html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].total+'</div>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-3 col-md-3 col-xl-3">';
																				html += '<div class="no-gutters">';
																					html += '<small class="bold-grey ml-2 fs-10">Autopay</small><br>';
																					html += '<label class="switch text-center mt-1 mb-0 '+add_opacity+'">';
																						html += '<input type="checkbox" class="update_auto_pay" id="auto_pay_'+data[key][mkey][dkey][pkey][rkey].id+'" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" '+checked+'>';
																						html += '<div class="slider round">';
																							html += '<span class="on pr-3">Yes</span>';
																							html += '<span class="off ml-1">No</span>';
																						html += '</div>';
																					html += '</label>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-3 col-md-3 col-xl-3">';
																				html += '<div class="no-gutters mt-2">';
																					html += status;
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
															html += '<div class="col-12 col-md-12 col-lg-2">';
																html += '<div class="card no_box_shadow mb-0">';
																	html += '<div class="card-body">';
																		html += '<div class="row">';
																			html += '<div class="col-6 col-md-6 col-xl-6">';
																				html += '<div class="no-gutters mt-2">';
																					html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate"><i class="align-middle fas fa-fw fa-eye mt-2"></i></div>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-6 col-md-6 col-xl-6">';
																				html += '<div class="no-gutters mt-2">';
																					if(kebab_item != '') {
																						html += kebab;
																					}
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
													}
												html += '</div>';
											}
										html += '</div>';
									}
								}	
							}
						html += '</div>';
					html += '</div>';
				html += '</div>';
			html += '</div>';

			mkeyNumber++;
		}
	}
	$('#payment_schedule_container').html(html);	
}

window.getCurrentAndUpcomingPaymentData = function(response) {
	var html = '';
	html += '<div class="col-12">';
		html += '<div class="card mb-2">';
			html += '<h3 class="pl-2 pb-3 pt-2">Current & Upcoming Payment <span  data-type="collapse" class="float-right expense_collapse_transaction_month_accordion fs-16 bold-black pointer mr-2 mt-1">Collapse All</span></h3>';
		html += '</div>';
	html += '</div>';

	var data = response.response_data.data;
	var mkeyNumber = 0;
	for(var key in data){
		// get current month
		var current_month = new Date().toLocaleString('en-us',{month:'long', year:'numeric'});
		var display_accordion = '';
		var border_radius_0 = '';
		if(current_month == key) {
			display_accordion = 'show';
			border_radius_0 = 'border-bottom-left-radius0 border-bottom-right-radius0';
		}

		html += '<div class="accordion payment_report_accordion" data-id="'+mkeyNumber+'" id="transactionMonthAccordion'+mkeyNumber+'">';
			html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';

				if($(window).width() < 767 || isTablet()) {
					html += '<div class="pt-2 pb-2 badge-grey" id="transactionMonthHeading'+mkeyNumber+'" data-toggle="collapse" data-target="#transactionMonthCollapse'+mkeyNumber+'" aria-expanded="true" aria-controls="transactionMonthCollapse'+mkeyNumber+'" style="cursor:pointer;">';
				} else {
					html += '<div class="p-1 badge-grey '+border_radius_0+'" id="transactionMonthHeading'+mkeyNumber+'" data-toggle="collapse" data-target="#transactionMonthCollapse'+mkeyNumber+'" aria-expanded="true" aria-controls="transactionMonthCollapse'+mkeyNumber+'" style="cursor:pointer;">';											
				}

					html += '<div class="row mt-1 mr-1 mb-1 ml-0">';
						html += '<div class="col-12 col-md-12">';
							html += '<h4 class="card-title my-2 fs-18 bold-black">'+key+'</h4>';
							if(current_month == key) {
								html += '<span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up"></i></span>';
							} else {
								html += '<span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
							}
						html += '</div>';
					html += '</div>';
				html += '</div>';

				if($(window).width() < 767 || isTablet()) {
					html += '<div id="transactionMonthCollapse'+mkeyNumber+'" class="mt-3 collapse '+display_accordion+'" aria-labelledby="transactionMonthHeading'+mkeyNumber+'" data-parent="#transactionMonthAccordion'+mkeyNumber+'">';

					html += '<div class="card mb-0 property_card">';
				} else {
					html += '<div id="transactionMonthCollapse'+mkeyNumber+'" class="border border-bottom-left-radius10 border-bottom-right-radius10 collapse '+display_accordion+'" aria-labelledby="transactionMonthHeading'+mkeyNumber+'" data-parent="#transactionMonthAccordion'+mkeyNumber+'">';

					html += '<div class="card mb-0">';
				}

					for(var mkey in data[key]){
						if($(window).width() > 767 && !isTablet()) {
							html += '<div class="card-header bg-light border-bottom"><h4 class="capitalize bold-black mb-0 mr-3" >'+mkey+'</h4></div>';
						} else {
							html += '<div class="border-bottom pl-1 pb-4 text-center"><h2 class="capitalize bold-black mb-0 mr-3" >'+mkey+'</h2></div>';
						}
						// Display payment types
						var num = 0;
						for(var dkey in data[key][mkey]){

							if($(window).width() > 767 && !isTablet()) {
								var bg_percentage_color = 'bg-warning';
								var width = data[key][mkey][dkey]['calc']['payment_completed_percentage'];
								var color = 'white';
								if(Number(data[key][mkey][dkey]['calc']['payment_completed_percentage']) > 0 && Number(data[key][mkey][dkey]['calc']['payment_completed_percentage']) < 100){
									bg_percentage_color = 'bg-warning';
								} else if (Number(data[key][mkey][dkey]['calc']['payment_completed_percentage']) == 0){
									bg_percentage_color = 'bg-lightgrey';
									width = 100;
									color = 'black';
								} else if(Number(data[key][mkey][dkey]['calc']['payment_completed_percentage'] == 100)) {
									bg_percentage_color = 'bg-success';
								}

								html += '<div class="card-header"><h5 class="capitalize bold-black mb-0 mr-3 pl-0 col-3">'+dkey;
									html += '<div class="progress">';
										html += '<div class="progress-bar '+bg_percentage_color+' '+color+'" role="progressbar" style="width: '+width+'%" aria-valuenow="'+data[key][mkey][dkey]['calc']['payment_completed_percentage']+'" aria-valuemin="0" aria-valuemax="100">'+Number(data[key][mkey][dkey]['calc']['completed_payments'])+'  /  '+Number(data[key][mkey][dkey]['calc']['total_records'])+'</div>';
									html += '</div>';
								html += '</h5></div>';
							}

							// Display Payment schedule detail
							var num = 0;
							for(var pkey in data[key][mkey][dkey]) {
								// rkry = Route array loop
								// if pkey != calc because in calc we are calculating percentage
								if(pkey != 'calc'){

									if($(window).width() < 767 || isTablet()) {
										html += '<div class="">';
									} else {
										html += '<div class="border-top border-bottom">';
									}
										for(var rkey in data[key][mkey][dkey][pkey]) {
											let kebab_item = '';
											let kebab = '';

											if(data[key][mkey][dkey][pkey][rkey].sender_user_id == user_id || (data[key][mkey][dkey][pkey][rkey].sender_user_id == 0 && user_service_name == sa_role)) {
												if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization'){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Authorize Payment">Authorize Payment</a>';
												} else if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection'){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-from="payment_schedule" class="dropdown-item authorize_payment_schedule" data-title="Review Payment">Review Payment</a>';
												}
											}
											
											if(user_service_name == sa_role){
												if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'deleted'){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type_id+'" class="dropdown-item undo_delete_ps">Un-Delete</a>';
												}

												if(data[key][mkey][dkey][pkey][rkey].old_record == 0 && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'failed_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_transfer')){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type_id+'" class="dropdown-item regenerate_payment_schedule">Regenerate Transaction</a>';
												}
											
												if(data[key][mkey][dkey][pkey][rkey].sender_user_id > 0 && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')){
													if(data[key][mkey][dkey][pkey][rkey].lease && data[key][mkey][dkey][pkey][rkey].lease.partial_payment_enabled == 1 && (data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'rent' || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'security_deposit' || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'pet_deposit')){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item get_partial_payment_data">Create Partial Payment</a>';
													}
											
													if(data[key][mkey][dkey][pkey][rkey].fee_applied == 1){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item waive_payment_schedule_late_fee">Waive Fee</a>';
													}
												}
											
												if(data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'rs_bank_to_balance' && data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'property_setup_fee' && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item mark_ps_as_paid">Mark As Paid</a>';

													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item mark_ps_as_deleted">Mark As Deleted</a>';
												}

												if(data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'rs_bank_to_balance' && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized')){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item change_ps_payment_details" data-title="Change Payment Details">Change Payment Details</a>';

													if(data[key][mkey][dkey][pkey][rkey].enable_change_route == 1){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item change_ps_payment_route" data-title="Change Payment Route">Change Payment Route</a>';
													}
												}

												if((data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')) {
													if(data[key][mkey][dkey][pkey][rkey].trn_late_fee_enabled == true){
														kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Disable Late Fee</a>';
													} else {
														kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="enable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Enable Late Fee</a>';
													}

													kebab_item += '<a class="dropdown-item change_payment_due_date" href="javascript:void(0)" data-due-date="'+data[key][mkey][dkey][pkey][rkey].due_date+'" data-month="'+data[key][mkey][dkey][pkey][rkey].month+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type.name+'"  data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Change Payment Due Date</a>';
												}
											}
											
											if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
												if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized') {
													kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
												} else if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_in_progress') {
													kebab_item += '<a class="dropdown-item cancel_payment_schedule" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
												}
											}
											
											if(kebab_item != '' && (data[key][mkey][dkey][pkey][rkey].sender_user_id == user_id || user_service_name == sa_role)){
												kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v ml-auto mr-auto mt-2" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right">'+kebab_item+'</div></div></div>';
											}
											
											var status = '';
											var bg_color = '';
											if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization'){
												status = '<span class="badge badge-grey">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-grey';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'failed_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'deleted'){
												status = '<span class="badge badge-red">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-red';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_in_progress' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'processing') {
												status = '<span class="badge badge-yellow">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-yellow';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'processed') {
												status = '<span class="badge badge-green">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-green';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized') {
												status = '<span class="badge badge-blue">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-blue';
											} else {
												status = '<span class="badge badge-grey">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-grey';
											}
											
											if(user_service_name != sa_role || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'property_setup_fee' || data[key][mkey][dkey][pkey][rkey].associated_payments == 1 || (data[key][mkey][dkey][pkey][rkey].status_constant_name != 'pending_authorization' && data[key][mkey][dkey][pkey][rkey].status_constant_name != 'transfer_authorized')) {
												var add_opacity = 'add_opacity';
											} else {
												var add_opacity = '';
											}
											
											if(data[key][mkey][dkey][pkey][rkey].is_autopay == 0){
												var checked = '';
											} else {
												var checked = 'checked';
											}

											html += '<div class="payment_type_tran_container payment_schedule_tr_'+data[key][mkey][dkey][pkey][rkey].id+'">';
												var sender_bank = '';
												if(data[key][mkey][dkey][pkey][rkey].sender_data.bank_name != '') {
													sender_bank = '<small class="fs-10">'+data[key][mkey][dkey][pkey][rkey].sender_data.bank_name+'</small>';
												}

												var receiver_bank = '';
												if(data[key][mkey][dkey][pkey][rkey].receiver_data.bank_name != '') {
													receiver_bank = '<small class="fs-10">'+data[key][mkey][dkey][pkey][rkey].receiver_data.bank_name+'</small>';
												}

												if($(window).width() < 767 || isTablet()) {
													html += '<div class="row">';
														html += '<div class="col-12">';
															html += '<div class="card border">';
																html += '<div class="card-header bg-light">';
																	html += '<div class="row">';
																		html += '<div class="col-10">';
																			html += '<div class="no-gutters mt-1">';
																				html += '<h3>'+dkey+'</h3>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2">';
																			html += '<div class="no-gutters">';
																				if(kebab_item != '') {
																					html += kebab;
																				}
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';

																html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate">';
																	html += '<div class="card-header text-center mt-2"><h1 style="font-size:2.50rem;">'+data[key][mkey][dkey][pkey][rkey].total+'</h1></div>';
																	html += '<div class="card-header mb-0">';
																		html += '<div class="row">';
																			html += '<div class="col-5 text-center">';
																				html += '<div class="no-gutters">';
																					html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].sender_data.name+'</div>'+sender_bank;
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-2 text-center">';
																				html += '<div class="no-gutters">';
																					html += '<i class="align-middle mr-2 mt-2 fas fa-fw fa-arrow-right"></i>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-5 text-center">';
																				html += '<div class="no-gutters">';
																					html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].receiver_data.name+'</div>'+receiver_bank;
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
																html += '<div class="card-header bg-light">';
																	html += '<table class="table-sm border_top_0">';
																		html += '<tbody>';
																			html += '<tr>';
																				html += '<td class="text-left width-100 border_top_0">'+status+'</td>';
																				html += '<td class="text-right border_top_0">';
																					html += '<table class="table-sm border_top_0">';
																						html += '<tr>';
																							html += '<td class="fs-12 bold-black border_top_0">Autopay</td>';
																							html += '<td class="border_top_0">';
																								html += '<label class="switch text-center mt-0 mb-0 '+add_opacity+'">';
																									html += '<input type="checkbox" class="update_auto_pay" id="auto_pay_'+data[key][mkey][dkey][pkey][rkey].id+'" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" '+checked+'>';
																									html += '<div class="slider round">';
																										html += '<span class="on pr-3">Yes</span>';
																										html += '<span class="off ml-1">No</span>';
																									html += '</div>';
																								html += '</label>';
																							html += '</td>';
																						html += '</tr>';
																					html += '</table>';
																				html += '</td>';
																			html += '</tr>';
																		html += '</tbody>';
																	html += '</table>';
																html += '</div>';

															html += '</div>';
														html += '</div>';
													html += '</div>';
												} else {
													html += '<div class="row">';
														html += '<div class="col-12 col-md-12 col-lg-4">';
															html += '<div class="card no_box_shadow mb-0">';
																html += '<div class="card-body">';
																	html += '<div class="row">';
																		html += '<div class="col-5 col-md-5 col-xl-5">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].sender_data.name+'</div>'+sender_bank;
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2 col-md-2 col-xl-2">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<i class="align-middle mr-2 mt-2 fas fa-fw fa-arrow-right"></i>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-5 col-md-5 col-xl-5">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].receiver_data.name+'</div>'+receiver_bank;
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
														html += '<div class="col-12 col-md-12 col-lg-6">';
															html += '<div class="card no_box_shadow mb-0">';
																html += '<div class="card-body">';
																	html += '<div class="row">';
																		html += '<div class="col-3 col-md-3 col-xl-3">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey ml-2 fs-10">Due Date</small><br>';
																				html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].due_date+'</div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-3 col-md-3 col-xl-3">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey ml-2 fs-10">Amount</small><br>';
																				html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].total+'</div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-3 col-md-3 col-xl-3">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey ml-2 fs-10">Autopay</small><br>';
																				html += '<label class="switch text-center mt-1 mb-0 '+add_opacity+'">';
																					html += '<input type="checkbox" class="update_auto_pay" id="auto_pay_'+data[key][mkey][dkey][pkey][rkey].id+'" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" '+checked+'>';
																					html += '<div class="slider round">';
																						html += '<span class="on pr-3">Yes</span>';
																						html += '<span class="off ml-1">No</span>';
																					html += '</div>';
																				html += '</label>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-3 col-md-3 col-xl-3">';
																			html += '<div class="no-gutters mt-2">';
																				html += status;
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
														html += '<div class="col-12 col-md-12 col-lg-2">';
															html += '<div class="card no_box_shadow mb-0">';
																html += '<div class="card-body">';
																	html += '<div class="row">';
																		html += '<div class="col-6 col-md-6 col-xl-6">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate"><i class="align-middle fas fa-fw fa-eye mt-2"></i></div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-6 col-md-6 col-xl-6">';
																			html += '<div class="no-gutters mt-2">';
																				if(kebab_item != '') {
																					html += kebab;
																				}
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
													html += '</div>';
												}
											html += '</div>';
										}
									html += '</div>';
								}
							}	
						}
					}

					html += '</div>';
				html += '</div>';
			html += '</div>';
		html += '</div>';
		mkeyNumber++;
	}
	$('#current_upcoming_payment_container').html(html);	
}

window.getOverdueSummary = function(response) {
	var html = '';
	html += '<div class="col-12">';
		html += '<div class="card mb-2">';
			html += '<h3 class="pl-2 pb-3 pt-2">Overdue Rent Summary <span  data-type="collapse" class="float-right expense_collapse_transaction_month_accordion fs-16 bold-black pointer mr-2 mt-1">Collapse All</span></h3>';
		html += '</div>';
	html += '</div>';

	var data = response.response_data.data;
	var mkeyNumber = 0;
	for(var key in data){
		// get current month
		var current_month = new Date().toLocaleString('en-us',{month:'long', year:'numeric'});
		var display_accordion = '';
		var border_radius_0 = '';
		if(current_month == key) {
			display_accordion = 'show';
			border_radius_0 = 'border-bottom-left-radius0 border-bottom-right-radius0';
		}

		html += '<div class="accordion payment_report_accordion" data-id="'+mkeyNumber+'" id="transactionMonthAccordion'+mkeyNumber+'">';
			html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';

				if($(window).width() < 767 || isTablet()) {
					html += '<div class="pt-2 pb-2 badge-grey" id="transactionMonthHeading'+mkeyNumber+'" data-toggle="collapse" data-target="#transactionMonthCollapse'+mkeyNumber+'" aria-expanded="true" aria-controls="transactionMonthCollapse'+mkeyNumber+'" style="cursor:pointer;">';
				} else {
					html += '<div class="p-1 badge-grey '+border_radius_0+'" id="transactionMonthHeading'+mkeyNumber+'" data-toggle="collapse" data-target="#transactionMonthCollapse'+mkeyNumber+'" aria-expanded="true" aria-controls="transactionMonthCollapse'+mkeyNumber+'" style="cursor:pointer;">';											
				}

					html += '<div class="row mt-1 mr-1 mb-1 ml-0">';
						html += '<div class="col-12 col-md-12">';
							html += '<h4 class="card-title my-2 fs-18 bold-black">'+key+'</h4>';
							if(current_month == key) {
								html += '<span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up"></i></span>';
							} else {
								html += '<span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
							}
						html += '</div>';
					html += '</div>';
				html += '</div>';

				if($(window).width() < 767 || isTablet()) {
					html += '<div id="transactionMonthCollapse'+mkeyNumber+'" class="mt-3 collapse '+display_accordion+'" aria-labelledby="transactionMonthHeading'+mkeyNumber+'" data-parent="#transactionMonthAccordion'+mkeyNumber+'">';

					html += '<div class="card mb-0 property_card">';
				} else {
					html += '<div id="transactionMonthCollapse'+mkeyNumber+'" class="border border-bottom-left-radius10 border-bottom-right-radius10 collapse '+display_accordion+'" aria-labelledby="transactionMonthHeading'+mkeyNumber+'" data-parent="#transactionMonthAccordion'+mkeyNumber+'">';

					html += '<div class="card mb-0">';
				}

					for(var mkey in data[key]){
						if($(window).width() > 767 && !isTablet()) {
							html += '<div class="card-header bg-light border-bottom"><h4 class="capitalize bold-black mb-0 mr-3" >'+mkey+'</h4></div>';
						} else {
							html += '<div class="border-bottom pl-1 pb-4 text-center"><h2 class="capitalize bold-black mb-0 mr-3" >'+mkey+'</h2></div>';
						}
						// Display payment types
						var num = 0;
						for(var dkey in data[key][mkey]){

							if($(window).width() > 767 && !isTablet()) {
								html += '<div class="card-header"><h5 class="capitalize bold-black mb-0 mr-3 pl-0 col-3">'+dkey+'</h5></div>';
							}

							// Display Payment schedule detail
							var num = 0;
							for(var pkey in data[key][mkey][dkey]) {
								// rkry = Route array loop
								// if pkey != calc because in calc we are calculating percentage
								if(pkey != 'calc'){

									if($(window).width() < 767 || isTablet()) {
										html += '<div class="">';
									} else {
										html += '<div class="border-top border-bottom">';
									}
										for(var rkey in data[key][mkey][dkey][pkey]) {
											let kebab_item = '';
											let kebab = '';
											
											if(user_service_name == sa_role){											
												if(data[key][mkey][dkey][pkey][rkey].sender_user_id > 0 && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')){											
													if(data[key][mkey][dkey][pkey][rkey].fee_applied == 1){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item waive_payment_schedule_late_fee">Waive Fee</a>';
													}

													if(data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'rent'){
														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item create_lease_agreement" data-open-from="reports" data-ps-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Get Notice To Vacate Contract</a>';

														kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item upload_eviction_document">Upload Notice To Vacate</a>';
													}
												}
											
												if(data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'rs_bank_to_balance' && data[key][mkey][dkey][pkey][rkey].payment_type.constant != 'property_setup_fee' && (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')){
													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item mark_ps_as_paid">Mark As Paid</a>';

													kebab_item += '<a href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" class="dropdown-item mark_ps_as_deleted">Mark As Deleted</a>';
												}

												if((data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_collection')) {
													if(data[key][mkey][dkey][pkey][rkey].trn_late_fee_enabled == true){
														kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="disable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Disable Late Fee</a>';
													} else {
														kebab_item += '<a class="dropdown-item enable_disable_late_fee" href="javascript:void(0)" data-type="enable" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Enable Late Fee</a>';
													}

													kebab_item += '<a class="dropdown-item change_payment_due_date" href="javascript:void(0)" data-due-date="'+data[key][mkey][dkey][pkey][rkey].due_date+'" data-month="'+data[key][mkey][dkey][pkey][rkey].month+'" data-payment-type="'+data[key][mkey][dkey][pkey][rkey].payment_type.name+'" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'">Change Payment Due Date</a>';
												}
											}
											
											if(kebab_item != '' && (data[key][mkey][dkey][pkey][rkey].sender_user_id == user_id || user_service_name == sa_role)){
												kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v ml-auto mr-auto mt-2" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right">'+kebab_item+'</div></div></div>';
											}
											
											var status = '';
											var bg_color = '';
											if(data[key][mkey][dkey][pkey][rkey].status_constant_name == 'pending_authorization'){
												status = '<span class="badge badge-grey">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-grey';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'failed_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_authorized_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'cancelled_transfer' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'deleted'){
												status = '<span class="badge badge-red">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-red';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_in_progress' || data[key][mkey][dkey][pkey][rkey].status_constant_name == 'processing') {
												status = '<span class="badge badge-yellow">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-yellow';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'processed') {
												status = '<span class="badge badge-green">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-green';
											} else if (data[key][mkey][dkey][pkey][rkey].status_constant_name == 'transfer_authorized') {
												status = '<span class="badge badge-blue">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-blue';
											} else {
												status = '<span class="badge badge-grey">'+data[key][mkey][dkey][pkey][rkey].status+'</span>';
												bg_color = 'badge-grey';
											}
											
											if(user_service_name != sa_role || data[key][mkey][dkey][pkey][rkey].payment_type.constant == 'property_setup_fee' || data[key][mkey][dkey][pkey][rkey].associated_payments == 1 || (data[key][mkey][dkey][pkey][rkey].status_constant_name != 'pending_authorization' && data[key][mkey][dkey][pkey][rkey].status_constant_name != 'transfer_authorized')) {
												var add_opacity = 'add_opacity';
											} else {
												var add_opacity = '';
											}
											
											if(data[key][mkey][dkey][pkey][rkey].is_autopay == 0){
												var checked = '';
											} else {
												var checked = 'checked';
											}

											html += '<div class="payment_type_tran_container payment_schedule_tr_'+data[key][mkey][dkey][pkey][rkey].id+'">';
												var sender_bank = '';
												if(data[key][mkey][dkey][pkey][rkey].sender_data.bank_name != '') {
													sender_bank = '<small class="fs-10">'+data[key][mkey][dkey][pkey][rkey].sender_data.bank_name+'</small>';
												}

												var receiver_bank = '';
												if(data[key][mkey][dkey][pkey][rkey].receiver_data.bank_name != '') {
													receiver_bank = '<small class="fs-10">'+data[key][mkey][dkey][pkey][rkey].receiver_data.bank_name+'</small>';
												}

												var payment_fee_details = data[key][mkey][dkey][pkey][rkey].payment_fee_details;
												if(payment_fee_details.length > 0){
													var msg_html = "<table class='table table-sm border fs-14'>";
														msg_html += "<thead>";
															msg_html += "<tr>";
																msg_html += "<th class='bold-black'>Fee Type</th>";
																msg_html += "<th class='bold-black text-right'>Fee Amount</th>";
																msg_html += "<th class='bold-black text-right'>Waived Amount</th>";
																msg_html += "<th class='bold-black text-right'>Total</th>";
															msg_html += '</tr>';
														msg_html += "</thead>";
														msg_html += "<tbody>";
															for(var feekey in payment_fee_details) {
																msg_html += "<tr>";
																	msg_html += "<td class='bold-grey'>"+payment_fee_details[feekey].fee_type.label+"</td>";
																	msg_html += "<td class='text-right'>$"+currencyFormat(Number(payment_fee_details[feekey].fee_amount))+"</td>";
																	msg_html += "<td class='text-right'>$"+currencyFormat(Number(payment_fee_details[feekey].waived_amount))+"</td>";
																	msg_html += "<td class='bold-grey text-right'>$"+currencyFormat(Number(payment_fee_details[feekey].fee_amount - payment_fee_details[feekey].waived_amount))+"</td>";
																msg_html += '</tr>';
															}
														msg_html += '</tbody>';
														msg_html += "<tfoot class='border-top'>";
															msg_html += "<th class='bold-black'>Total</th>";
															msg_html += "<th>&nbsp;</th>";
															msg_html += "<th>&nbsp;</th>";
															msg_html += "<th class='bold-black'>$"+currencyFormat(Number(data[key][mkey][dkey][pkey][rkey].fees_amount))+"</th>";
														msg_html += "</tfoot>";
													msg_html += "</table>";
												} else {
													var msg_html = '';
												}

												if($(window).width() < 767 || isTablet()) {
													html += '<div class="row">';
														html += '<div class="col-12">';
															html += '<div class="card border">';
																html += '<div class="card-header bg-light">';
																	html += '<div class="row">';
																		html += '<div class="col-10">';
																			html += '<div class="no-gutters mt-1">';
																				html += '<h3>'+dkey+'</h3>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2">';
																			html += '<div class="no-gutters">';
																				if(kebab_item != '') {
																					html += kebab;
																				}
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';

																html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate">';
																	html += '<div class="card-header mt-2">';
																		html += '<div class="row">';
																			html += '<div class="col-5 text-center">';
																				html += '<small class="bold-grey fs-10">Amount</small><br>';
																				html += '<div class="black fs-16">$'+currencyFormat(Number(data[key][mkey][dkey][pkey][rkey].amount))+'</div>';
																			html += '</div>';
																			html += '<div class="col-2 text-center">&nbsp;';
																			html += '</div>';
																			html += '<div class="col-5 text-center">';
																				html += '<small class="bold-grey fs-10">Fees</small><br>';

																				html += '<div class="black fs-16">$'+currencyFormat(Number(data[key][mkey][dkey][pkey][rkey].fees_amount));
																					if(payment_fee_details.length > 0){
																						html += '<i class="fas fa-fw fa-info-circle" title="List of Fees Applied" data-container="body" data-toggle="popover" data-placement="top" data-content="'+msg_html+'"></i>';
																					}
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																	html += '<div class="card-header text-center"><small class="bold-grey fs-14">Total</small><br><h1 style="font-size:2.0rem;">'+data[key][mkey][dkey][pkey][rkey].total+'</h1></div>';
																	html += '<div class="card-header mb-0">';
																		html += '<div class="row">';
																			html += '<div class="col-5 text-center">';
																				html += '<div class="no-gutters">';
																					html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].sender_data.name+'</div>'+sender_bank;
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-2 text-center">';
																				html += '<div class="no-gutters">';
																					html += '<i class="align-middle mr-2 mt-2 fas fa-fw fa-arrow-right"></i>';
																				html += '</div>';
																			html += '</div>';
																			html += '<div class="col-5 text-center">';
																				html += '<div class="no-gutters">';
																					html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].receiver_data.name+'</div>'+receiver_bank;
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
																html += '<div class="card-header bg-light">';
																	html += '<div class="row">';
																		html += '<div class="col-6 mt-auto mb-auto">'+status;
																		html += '</div>';
																		html += '<div class="col-6 text-right">';
																			html += '<small class="bold-grey fs-10">Due Date</small><br>';
																			html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].due_date+'</div>';
																			html += '<small class="bold-grey fs-10">'+data[key][mkey][dkey][pkey][rkey].overdue_days+' Days Over Due Date</small>';
																		html += '</div>';
																	html += '</div>';
																	// html += '<table class="table-sm border_top_0">';
																	// 	html += '<tbody>';
																	// 		html += '<tr>';
																	// 			html += '<td class="text-left width-100 border_top_0">'+status+'</td>';
																	// 			html += '<td class="text-right border_top_0">';
																	// 				html += '<small class="bold-grey fs-10">Due Date</small><br>';
																	// 				html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].due_date+'</div>';
																	// 				html += '<small class="bold-grey fs-10">'+data[key][mkey][dkey][pkey][rkey].overdue_days+' Days Over Due Date</small>';
																	// 			html += '</td>';
																	// 		html += '</tr>';
																	// 	html += '</tbody>';
																	// html += '</table>';
																html += '</div>';

															html += '</div>';
														html += '</div>';
													html += '</div>';
												} else {
													html += '<div class="row">';
														html += '<div class="col-12 col-md-12 col-lg-3">';
															html += '<div class="card no_box_shadow mb-0">';
																html += '<div class="card-body">';
																	html += '<div class="row">';
																		html += '<div class="col-5 col-md-5 col-xl-5">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].sender_data.name+'</div>'+sender_bank;
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2 col-md-2 col-xl-2">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<i class="align-middle mr-2 mt-2 fas fa-fw fa-arrow-right"></i>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-5 col-md-5 col-xl-5">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<div class="fs-16 black">'+data[key][mkey][dkey][pkey][rkey].receiver_data.name+'</div>'+receiver_bank;
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
														html += '<div class="col-12 col-md-12 col-lg-7">';
															html += '<div class="card no_box_shadow mb-0">';
																html += '<div class="card-body">';
																	html += '<div class="row">';
																		html += '<div class="col-3 col-md-3 col-xl-3">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey fs-10">Due Date</small><br>';
																				html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].due_date+'</div>';
																				html += '<small class="bold-grey fs-10">'+data[key][mkey][dkey][pkey][rkey].overdue_days+' Days Over Due Date</small>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2 col-md-2 col-xl-2 pl-0">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey fs-10">Amount</small><br>';
																				html += '<div class="black fs-16">$'+currencyFormat(Number(data[key][mkey][dkey][pkey][rkey].amount))+'</div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2 col-md-2 col-xl-2 pl-0">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey fs-10">Fees</small><br>';

																				html += '<div class="black fs-16">$'+currencyFormat(Number(data[key][mkey][dkey][pkey][rkey].fees_amount));
																					if(payment_fee_details.length > 0){
																						html += '<i class="fas fa-fw fa-info-circle" title="List of Fees Applied" data-container="body" data-toggle="popover" data-placement="top" data-content="'+msg_html+'"></i>';
																					}
																				html += '</div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-2 col-md-2 col-xl-2">';
																			html += '<div class="no-gutters">';
																				html += '<small class="bold-grey fs-10">Total</small><br>';
																				html += '<div class="black fs-16">'+data[key][mkey][dkey][pkey][rkey].total+'</div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-3 col-md-3 col-xl-3">';
																			html += '<div class="no-gutters mt-2">';
																				html += status;
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
														html += '<div class="col-12 col-md-12 col-lg-2">';
															html += '<div class="card no_box_shadow mb-0">';
																html += '<div class="card-body">';
																	html += '<div class="row">';
																		html += '<div class="col-6 col-md-6 col-xl-6">';
																			html += '<div class="no-gutters mt-2">';
																				html += '<div class="associate_payment_details" data-type="separate" data-id="'+data[key][mkey][dkey][pkey][rkey].id+'" data-type="separate"><i class="align-middle fas fa-fw fa-eye mt-2"></i></div>';
																			html += '</div>';
																		html += '</div>';
																		html += '<div class="col-6 col-md-6 col-xl-6">';
																			html += '<div class="no-gutters mt-2">';
																				if(kebab_item != '') {
																					html += kebab;
																				}
																			html += '</div>';
																		html += '</div>';
																	html += '</div>';
																html += '</div>';
															html += '</div>';
														html += '</div>';
													html += '</div>';
												}
											html += '</div>';
										}
									html += '</div>';
								}
							}	
						}
					}

					html += '</div>';
				html += '</div>';
			html += '</div>';
		html += '</div>';
		mkeyNumber++;
	}
	$('#overdue_rent_summary_container').html(html);

	$('[data-toggle="popover"]').popover({
		html: true,
		animation:false,
		sanitize: false,
		placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
	}).on('click', function(e) { 
		e.stopPropagation();

		// Hide all popovers except the one being clicked $('[data-toggle="popover"]').not(this).popover('hide');
		$('[data-toggle="popover"]').not(this).popover('hide');
	});
}

window.getMyAccountPaymentScheduleWidget = function() {

	var get_current_tab = $('#reports-tab .nav-link.active').attr('href');

	var user_id = user.user_details.user_id;
	var filters = getFilterData();

	var post_data = '';
	if(get_current_tab == '#r-current-upcoming-payment') {
		openLoadingDialog($('#current_upcoming_payment_container'));
		post_data = 'user_id='+user_id+'&user_service_name='+user_service_name+'&filters='+filters+'&open_from=current_upcoming_payment';
	} else if(get_current_tab == '#r-overdue-summary') {
		openLoadingDialog($('#overdue_rent_summary_container'));
		post_data = 'user_id='+user_id+'&user_service_name='+user_service_name+'&filters='+filters+'&open_from=overdue_rent_summary';
	} else {
		openLoadingDialog($('#payment_schedule_container'));
		post_data = 'user_id='+user_id+'&user_service_name='+user_service_name+'&filters='+filters;
	}

	setTimeout(function(){
		$.ajax({
			url: api_url+"payment-schedules",
			type: 'POST',
			data: post_data,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				var html = '';
				if(response.response_data.success == true) {
					if(response.response_data.type == 'combine_data') {
						getCombinedPaymentData(response);						
					} else if(response.response_data.type == 'separate') {
						getSeparatePaymentData(response);											
					} else if(response.response_data.type == 'current_upcoming_payment') {
						getCurrentAndUpcomingPaymentData(response);											
					} else if(response.response_data.type == 'overdue_rent_summary') {
						getOverdueSummary(response);
					}
				} else {
					//display_error_message(response);
					var html = '';
					html += '<div class="text-center h-100">';
						html += '<lottie-player src="img/lottie-files/no_data_found_2.json" background="transparent" speed="1" style="width: 200px;height: 200px;" loop="" autoplay="" direction="1" mode="bounce" class="m-auto"></lottie-player>';
						html += '<h3 class="mt-4 text-center fs-22 bold-black">'+response.response_data.message+'</h3>';
					html += '<div>';
					setTimeout(function() {
						$('#payment_schedule_container').html(html);
						$('#current_upcoming_payment_container').html(html);
						$('#overdue_rent_summary_container').html(html);
					}, 500);
				}
			}
		});
	}, 500);
}

window.loadAssociatePayments = function(payment_schedule_id, type, totalAmount){
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
		method: 'POST',
		url: api_url+'payment-schedules/payments',
		data: 'payment_schedule_id='+payment_schedule_id+'&type='+type+'&user_service_name='+user_service_name+'&user_timezone='+timezone,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(response.response_data.success == true){
				if(type == 'combine_data') {
					$('#sidebar_heading').html('Detail - '+totalAmount);
					var html = '';
					html += '<div class="transction_detail">';
						html += '<div class="accordion mt-3" id="paymentAccordion">';
							var cnt = 1;
							for(var key in response.data){
								html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';
									html += '<div class="border border-radius flex-fill">';
										html += '<div class="p-2 bg-light" id="headingOne'+cnt+'" data-toggle="collapse" data-target="#collapseOne'+cnt+'" aria-expanded="true" aria-controls="collapseOne'+cnt+'" style="cursor:pointer;">';

											html += '<div class="row mt-1 mr-1 mb-1 ml-0">';
												html += '<div class="col-1 col-md-1 mt-1 mr-2">';
													html += '<img src="icon/property.svg" class="filter-8" style="width: 24px;"/>';
												html += '</div>';
												html += '<div class="col-8 col-md-8 mt-1">';
													html += '<h4 class="card-title fs-16">'+response.data[key].property.name+'</h4>';
												html += '</div>';
												html += '<div class="col-2 col-md-2 text-right fs-18 bold-black ml-3">'+response.data[key].total+'</div>';
											html += '</div>';
										html += '</div>';
										html += '<div id="collapseOne'+cnt+'" class="collapse" aria-labelledby="headingOne'+cnt+'" data-parent="#paymentAccordion">';
											html += '<div class="mt-2 justify-content-center">';
												html += getPaymentTransactionDetail(response.data[key].payment, 'combine_data', cnt);
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
								cnt++;
							}
						html += '</div>';
					html += '</div>';
					$('#sidebar_content_area').html(html);
				} else {
					var html = '';
					var cnt = 1;
					for(var key in response.data){
						html += getPaymentTransactionDetail(response.data[key].payment, '', cnt);
						cnt++;
					}
					$('#sidebar_content_area').html(html);
				}
			}
		}
	});
}

window.getPaymentTransactionDetail = function(response, type, cnt) {
	if(type != 'combine_data') {
		$('#sidebar_heading').html('Activity - '+response.paymentSchedule.payment_type.name);
	}
	var html = '';

	var margin_class = 'm-3';
	if(response.paymentSchedule.is_partial_payment == 1){
		margin_class = 'mt-1 mb-3 ml-3 mr-3';
		html += '<div class="col-12 col-md-12 col-xl-12">';
			html += '<div class="row m-1">';
				html += '<div class="alert alert-primary alert-outline alert-dismissible width-100" role="alert" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">';
					html += '<div class="alert-icon">';
						html += '<i class="align-middle far fa-fw fa-bell fs-18"></i>';
					html += '</div>';
					html += '<div class="alert-message" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">';
						html += '<strong class="fs-14 bold-black">This payment is partial!</strong>';
					html += '</div>';
				html += '</div>';
			html += '</div>';
		html += '</div>';
	}

	html += '<div class="transction_detail">';
		//html += '<div class="fs-18 bold-black pl-3">'+response.paymentSchedule.payment_type.name+' - '+response.paymentSchedule.total+'</div>';
		html += '<div class="card flex-fill border '+margin_class+'">';
			html += '<div class="padding075">';
				html += '<div class="mb-1">';
					html += '<span class="float-left text-left">';
						html += '<h3 id="current_month_rent" class="mb-0 pt-2">Sender <span class="red">('+response.paymentSchedule.total+')</span></h3>';
					html += '</span>';
					html += '<span class="float-right text-right mt-2">';
						html += '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather-arrow-up-circle text-danger"><circle cx="12" cy="12" r="10"></circle><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg>';
					html += '</span>';
					html += '</div>';
				html += '<div class="mb-1 mt-6">';
					html += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">'+response.sender_name+' <span class="tile-view sender_receiver_detail" data-type="sender" data-id="'+cnt+'"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				html += '</div>';
				html += '<div id="sender_detail_container_'+cnt+'" class="mb-1 mt-3 d-none">';
					html += '<div class="card mb-0">';
						html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
							html += '<tbody>';
								html += '<tr>';
									html += '<td class="grey">Bank Name</td>';
								html += '</tr>';
								html += '<tr>';
									html += '<td class="bold-black">'+response.sender_account_name+'</th>';
								html += '</tr>';
								// html += '<tr>';
								// 	html += '<td class="grey">Funding Source ID</td>';
								// html += '</tr>';
								// html += '<tr>';
								// 	html += '<td class="bold-black">'+response.source_funding+'</th>';
								// html += '</tr>';
							html += '</tbody>';
						html += '</table>';
					html += '</div>';
				html += '</div>';
			html += '</div>';
		html += '</div>';

		html += '<div class="card flex-fill border m-3">';
			html += '<div class="padding075">';
				html += '<div class="mb-1">';
					html += '<span class="float-left text-left">';
						html += '<h3 id="current_month_rent" class="mb-0 pt-2">Receiver <span class="green">('+response.paymentSchedule.total+')</span></h3>';
					html += '</span>';
					html += '<span class="float-right text-right mt-2">';
						html += '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather-arrow-down-circle text-success"><circle cx="12" cy="12" r="10"></circle><polyline points="8 12 12 16 16 12"></polyline><line x1="12" y1="8" x2="12" y2="16"></line></svg>';
					html += '</span>';
					html += '</div>';
				html += '<div class="mb-1 mt-6">';
					html += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">'+response.receiver_name+' <span class="tile-view sender_receiver_detail" data-type="receiver" data-id="'+cnt+'"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				html += '</div>';
				html += '<div id="receiver_detail_container_'+cnt+'" class="mb-1 mt-3 d-none">';
					html += '<div class="card mb-0">';
						html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
							html += '<tbody>';
								html += '<tr>';
									html += '<td class="grey">Bank Name</td>';
								html += '</tr>';
								html += '<tr>';
									html += '<td class="bold-black">'+response.receiver_account_name+'</th>';
								html += '</tr>';
								// html += '<tr>';
								// 	html += '<td class="grey">Funding Source ID</td>';
								// html += '</tr>';
								// html += '<tr>';
								// 	html += '<td class="bold-black">'+response.receiver_funding+'</th>';
								// html += '</tr>';
							html += '</tbody>';
						html += '</table>';
					html += '</div>';
				html += '</div>';
			html += '</div>';
		html += '</div>';

		html += '<div class="transction_detail">';
			if(Object.keys(response.paymentActivities).length > 0){
				html += '<div class="accordion mt-3" id="activityAccordion">';
					html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';
						html += '<div class="p-2 badge-grey" id="activityHeadingOne1" data-toggle="collapse" data-target="#activityCollapseOne1" aria-expanded="true" aria-controls="activityCollapseOne1" style="cursor:pointer;">';
							html += '<div class="row mt-1 mr-1 mb-1 ml-0">';
								html += '<div class="col-6 col-md-6">';
									html += '<span class="mr-2 mt-2"><img src="icon/activity/history.svg" class="filter-15 mb-1" style="width: 23px;"/></span>';
									html += '<h4 class="card-title my-2 fs-18 bold-black">Activity Log</h4>';
								html += '</div>';
								// html += '<div class="col-6 col-md-6 text-right">';
								// 	html += '<i class="ml-2 mr-2 mt-2 align-middle fas fa-fw fa-angle-down fs-20"></i>';
								// html += '</div>';
							html += '</div>';
						html += '</div>';
						html += '<div id="activityCollapseOne1" class="collapse" aria-labelledby="activityHeadingOne1" data-parent="#activityAccordion">';
							html += '<div class="mt-2 justify-content-center">';
								html += '<div class="mb-0">';
									html += '<div class="pl-4 pr-4">';
										html += '<table class="table-sm fs-13" style="width:100%;">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td colspan="2">';
														html += '<ol class="custom_timeline">';
															for(var pakey in response.paymentActivities){
																var background = '';
																var image_icon = '';
																if(response.paymentActivities[pakey].activity_name == 'creating_payment_schedule'){
																	background = 'background-grey';
																	image_icon = 'icon/activity/dollar_activity.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_bank_activity'){
																	background = 'background-blue';
																	image_icon = 'icon/activity/bank.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_status'){
																	background = 'background-green';
																	image_icon = 'icon/activity/status.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'autopay_enabled_disabled'){
																	background = 'background-black';
																	image_icon = 'icon/activity/autopay.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_coupon_applied'){
																	background = 'background-green';
																	image_icon = 'icon/activity/coupon.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_fee_applied'){
																	background = 'background-red';
																	image_icon = 'icon/activity/payment.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_errors'){
																	background = 'background-red';
																	image_icon = 'icon/activity/error.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'transaction_activity'){
																	background = 'background-blue';
																	image_icon = 'icon/activity/payment.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'send_payment_schedule_mail'){
																	background = 'background-black';
																	image_icon = 'icon/activity/email.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_webhook'){
																	background = 'background-yellow';
																	image_icon = 'icon/activity/webhook.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'processing_payment_schedule'){
																	background = 'background-grey';
																	image_icon = 'icon/activity/history.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'calculating_late_fee'){
																	background = 'background-blue';
																	image_icon = 'icon/activity/history.svg';
																} else if(response.paymentActivities[pakey].activity_name == 'payment_schedule_attachments'){
																	background = 'background-blue';
																	image_icon = 'icon/template.png';
																}

																html += '<li class="custom-timeline-item">';
																	html += '<span class="timeline-item-icon | faded-icon '+background+'">';
																		html += '<img src="'+image_icon+'" class="filter-white">';
																	html += '</span>';
																	html += '<div class="timeline-item-description">';
																		html += '<span><strong class="bold-black">'+response.paymentActivities[pakey].description+'</strong> <br>on <time datetime="21-01-2021">'+response.paymentActivities[pakey].created_usertimezone+'</time>';
																			if(Object.keys(response.paymentActivities[pakey].user).length > 0){
																				html += '&nbsp;<small>by '+response.paymentActivities[pakey].user.first_name+' '+response.paymentActivities[pakey].user.last_name+'</small>';
																			}
																		html += '</span>';
																	html += '</div>';
																html += '</li>';
															}
														html += '</ol>';

													html += '</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
				html += '</div>';
			}
		html += '</div>';

		html += '<div class="transction_detail">';

			if(Object.keys(response.couponData).length > 0){
				html += '<div class="accordion mt-3" id="couponAccordion">';
					html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';
						html += '<div class="p-2 badge-grey" id="couponHeadingOne1" data-toggle="collapse" data-target="#couponCollapseOne1" aria-expanded="true" aria-controls="couponCollapseOne1" style="cursor:pointer;">';
							html += '<div class="row m-1">';
								html += '<span class="mr-2 ml-2 mt-2"><img src="icon/activity/coupon.svg" class="filter-15" style="width: 24px;"/></span>';
								html += '<h4 class="card-title my-2 fs-18 bold-black">Coupon Detail</h4>';
							html += '</div>';
						html += '</div>';
						html += '<div id="couponCollapseOne1" class="collapse" aria-labelledby="couponHeadingOne1" data-parent="#couponAccordion">';
							html += '<div class="mt-2 justify-content-center">';
								html += '<div class="mb-0">';
									html += '<div class="pl-4 pr-4">';
										html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
											html += '<tbody>';
												for(var ckey in response.couponData){
													html += '<tr>';
														html += '<td class="bold-black fs-14">Coupon Code</td>';
														html += '<td class="bold-black">'+response.couponData[ckey].coupon_code+'<br><small>applied on '+response.couponData[ckey].created+'</small></td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="bold-black fs-14">Discount Amount</td>';
														html += '<td class="bold-black">'+response.couponData[ckey].coupon_amount+'</td>';
													html += '</tr>';
													if(response.couponData[ckey].coupon.discount_type == 'P') {
														html += '<tr>';
															html += '<td class="bold-black fs-14">Coupon Percentage</td>';
															html += '<td class="bold-black">'+response.couponData[ckey].coupon_percentage+' %</td>';
														html += '</tr>';
													}
												}
											html += '</tbody>';
										html += '</table>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
				html += '</div>';
			}

			if(Object.keys(response.feesData).length > 0){
				html += '<div class="accordion mt-3" id="feeAccordion">';
					html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';
						html += '<div class="p-2 badge-grey" id="feeHeadingOne1" data-toggle="collapse" data-target="#feeCollapseOne1" aria-expanded="true" aria-controls="feeCollapseOne1" style="cursor:pointer;">';
							html += '<div class="row m-1">';
								html += '<span class="mr-2 ml-2 mt-2"><img src="icon/activity/fee.svg" class="filter-15" style="width: 24px;"/></span>';
								html += '<h4 class="card-title my-2 fs-18 bold-black">Fees Detail</h4>';
							html += '</div>';
						html += '</div>';
						html += '<div id="feeCollapseOne1" class="collapse" aria-labelledby="feeHeadingOne1" data-parent="#feeAccordion">';
							html += '<div class="mt-2 pl-3 pr-3 justify-content-center">';
								for(var fkey in response.feesData){
									var exclamation = '<span><i data-id="'+response.feesData[fkey].id+'" data-waived-amount="'+response.feesData[fkey].waived_amount+'" data-fee-type-id="'+response.feesData[fkey].fee_type.id+'" data-fee-type="'+response.feesData[fkey].fee_type.label+'" data-total-amount="'+response.feesData[fkey].fee_amount+'" data-remaining-amount="'+response.feesData[fkey].total_amount+'" class="align-middle mr-2 fas fa-fw fa-info-circle get_related_waived_fees_data_sidebar pointer"></i></span>';
									html += '<div class="row mb-4">';
										html += '<div class="col-6 col-md-6 col-xl-6">';
											html += '<div class="mt-2">';
												html += '<div class="bold-black fs-14">'+response.feesData[fkey].fee_type.label+' '+exclamation+'<br><small>applied on '+response.feesData[fkey].created+'</small></div>';
											html += '</div>';
										html += '</div>';
										html += '<div class="col-6 col-md-6 col-xl-6 text-right">';
											html += '<div class="mt-3 text-right bold-black">'+response.feesData[fkey].total_amount+'</div>';
										html += '</div>';
									html += '</div>';

									html += '<div id="fee_child_'+response.feesData[fkey].id+'" class="row fee_child_'+response.feesData[fkey].id+'">';
										html += '<div class="col-12 col-md-6 col-xl-6"></div>';
									html += '</div>';
								}
								if(response.fee_applied == 1 && user_service_name == sa_role && (response.paymentSchedule.status_constant_name == 'pending_authorization')) {
									html += '<div class="row">';
										html += '<div class="col-12 col-md-6 col-xl-12"><button type="button" data-id="'+response.paymentSchedule.id+'" class="btn btn-orange width-100 waive_payment_schedule_late_fee">Waive Fee</button></div>';
									html += '</div>';
								}
							html += '</div>';
						html += '</div>';
					html += '</div>';
				html += '</div>';
			}

			html += '<div class="accordion mt-3" id="transactionAccordion">';
				var cnt = 1;
				for(var key in response.data){
					var status = '';
					if(response.data[key].status_constant_name == 'failed_transfer' || response.data[key].status_constant_name == 'cancelled_authorized_transfer' || response.data[key].status_constant_name == 'cancelled_transfer' || response.data[key].status_constant_name == 'deleted'){
						status = '<span class="badge badge-red">'+response.data[key].status+'</span>';
					} else if(response.data[key].status_constant_name == 'transfer_in_progress' || response.data[key].status_constant_name == 'processing') {
						status = '<span class="badge badge-yellow">'+response.data[key].status+'</span>';
					} else if(response.data[key].status_constant_name == 'processed') {
						status = '<span class="badge badge-green">'+response.data[key].status+'</span>';
					} else if(response.data[key].status_constant_name == 'transfer_authorized') {
						status = '<span class="badge badge-blue">'+response.data[key].status+'</span>';
					} else {
						status = '<span class="badge badge-grey">'+response.data[key].status+'</span>';
					}

					var show_first_accordion = '';
					if(cnt == 1) {
						show_first_accordion = 'show';
					}

					html += '<div class="col-12 col-md-12 col-xl-12 mb-3">';
						html += '<div class="p-2 badge-grey" id="headingOne1'+cnt+'" data-toggle="collapse" data-target="#collapseOne1'+cnt+'" aria-expanded="true" aria-controls="collapseOne1'+cnt+'" style="cursor:pointer;">';
							html += '<div class="row m-1">';
								html += '<span class="mr-2 ml-2 mt-2"><img src="icon/activity/payment.svg" class="filter-15" style="width: 20px;"/></span>';
								html += '<h4 class="card-title my-2 fs-18 bold-black">Transaction</h4>';
								html += '<span class="accordion_arrow bold-black mr-4 mt-1">'+status+'</span>';
							html += '</div>';
						html += '</div>';
						html += '<div id="collapseOne1'+cnt+'" class="collapse '+show_first_accordion+'" aria-labelledby="headingOne1'+cnt+'" data-parent="#transactionAccordion">';
							html += '<div class="mt-2 justify-content-center">';
								html += '<div class="mb-0">';
									html += '<div class="pl-4 pr-4">';
										html += '<table class="table-sm my-2 fs-13" style="width:100%;">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td class="grey">Amount</td>';
													html += '<td class="text-right bold-black">'+response.data[key].amount+'</td>';
													html += '</tr>';
												html += '<tr>';
													html += '<td class="grey">Created on</td>';
													html += '<td class="text-right bold-black">'+response.data[key].created_usertimezone+'</td>';
												html += '</tr>';
												if(response.data[key].mark_as_paid == 0){
													html += '<tr>';
														html += '<td class="grey">Transfer ID</td>';
														html += '<td class="text-right bold-black">'+response.data[key].dwolla_transfer_id+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="grey">Sender IndividualAch ID</td>';
														html += '<td class="text-right bold-black">'+response.data[key].source_ach_id+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="grey">Receiver IndividualAch ID</td>';
														html += '<td class="text-right bold-black">'+response.data[key].destination_ach_id+'</td>';
													html += '</tr>';
												} else {
													html += '<tr>';
														html += '<td class="grey">Collected Outside</td>';
														html += '<td class="text-right bold-black">Yes</td>';
													html += '</tr>';
													html += '<tr>';
														if(response.data[key].status_constant_name == 'deleted'){
															html += '<td class="grey">Deleted On</td>';
														} else {
															html += '<td class="grey">Paid On</td>';
														}
														html += '<td class="text-right bold-black">'+response.data[key].paid_on+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="grey">Remark</td>';
														html += '<td class="text-right bold-black">'+response.data[key].remark+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="grey">Attachment</td>';
														html += '<td class="text-right bold-black">'+response.data[key].attachment+'</td>';
													html += '</tr>';
												}

												if(response.data[key].status_constant_name == 'failed_transfer' || response.data[key].status_constant_name == 'cancelled_authorized_transfer' || response.data[key].status_constant_name == 'cancelled_transfer'){
													html += '<tr>';
														html += '<td class="grey">Failure Code</td>';
														html += '<td class="text-right bold-black">'+response.data[key].failure_code+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="grey">Failure Reason</td>';
														html += '<td class="text-right bold-black">'+response.data[key].failure_reason+'</td>';
													html += '</tr>';
												}

												html += '<tr>';
													html += '<td colspan="2">';
														html += '<ol class="custom_timeline">';
														if(Object.keys(response.data[key].payment_status).length > 0){
															for(var pkey in response.data[key].payment_status){
																var background = '';
																if(response.data[key].payment_status[pkey].constant_name == 'processed') {
																	background = 'background-green';
																} else if(response.data[key].payment_status[pkey].constant_name == 'transfer_authorized') {
																	background = 'background-blue';
																} else if(response.data[key].payment_status[pkey].constant_name == 'transfer_in_progress' || response.data[key].payment_status[pkey].constant_name == 'processing') {
																	background = 'background-yellow';
																} else if(response.data[key].payment_status[pkey].constant_name == 'failed_transfer' || response.data[key].payment_status[pkey].constant_name == 'cancelled_authorized_transfer' || response.data[key].payment_status[pkey].constant_name == 'cancelled_transfer' || response.data[key].payment_status[pkey].constant_name == 'deleted') {
																	background = 'background-red';
																} else {
																	background = 'background-grey';
																}

																html += '<li class="custom-timeline-item">';
																	html += '<span class="timeline-item-icon | faded-icon '+background+'">';
																		html += '<img src="icon/activity/payment.svg" class="filter-white">';
																	html += '</span>';
																	html += '<div class="timeline-item-description">';
																		html += '<span><strong class="bold-black">'+response.data[key].payment_status[pkey].status_label+'</strong> on <time datetime="21-01-2021">'+response.data[key].payment_status[pkey].status_date+'</time></span>';
																	html += '</div>';
																html += '</li>';
															}
														}
														html += '</ol>';
													html += '</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
					cnt++;
				}
			html += '</div>';
		html += '</div>';

	html += '</div>';

	return html;
}

window.regeneratePaymentSchedule = function(payment_schedule_id, payment_type){
	$.ajax({
		method: 'POST',
		url: api_url+'payment-schedules/regenerate-transaction',
		data: 'payment_schedule_id='+payment_schedule_id+'&payment_type_id='+payment_type+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true){
				//getMyAccountPaymentScheduleWidget();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
			}
		}
	});
}

window.savePSRentCollectionData = function(payment_schedule_id){
	var from = $('#ps_data_from').val();
	if(user_service_name == sa_role){
		var sender_payment_detail_id = 0;
	} else {
		var sender_payment_detail_id = $('#ps_payment_detail').val();
	}
	$.ajax({
		method: 'POST',
		url: api_url+'payment-schedules/authorize-transaction',
		data: 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id+'&sender_payment_detail_id='+sender_payment_detail_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.ps_save_rent_collection_data').attr('disabled', false);
			display_error_message(response);
			if(response.response_data.success == true){
				// $('#authorizePaymentModal').modal('hide');
				//getMyAccountPaymentScheduleWidget();
				if(from == 'notification'){
					getNotificationData();
				} else {
					closeRightSidebar();
				}

				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
			}
		}
	});
}

window.getPaymentScheduleDetails = function(payment_schedule_id, from) {
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/get-payment-schedule-details",
        data: 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	// var bank_html = "<option value=''>Select Payment Method</option>";
            	var bank_html = "";
                for (var key in response.paymentDetails) {
					var selected = '';
			        if (response.paymentSchedule.sender_payment_detail_id == response.paymentDetails[key].id) {
						selected = 'selected';
					}

					bank_html += '<option value="'+response.paymentDetails[key].id+'" '+selected+'>'+response.paymentDetails[key].bank_name+'</option>';
				}

            	var html = '';
            	html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.paymentSchedule != null) {
		            		var line = '(One time payment)';
		            		if(response.paymentSchedule.payment_type.constant == 'rent' || response.paymentSchedule.payment_type.constant == 'subscription_fee') {
		            			line = 'for the month of '+response.paymentSchedule.month;
		            		}

		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

		            		var formatted_payment_due_date = response.paymentSchedule.due_date;

		            		html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_id" class="payment_schedule_hidden_input" value="'+response.paymentSchedule.id+'">';
		            		html += '<input type="hidden" name="ps_data_from" id="ps_data_from" class="payment_schedule_hidden_input" value="'+from+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white border-bottom">';
					            html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.paymentSchedule.payment_type.name+'</span> '+line+'</h5>';
					            html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';
					                	// check if due date is less then or equal to current date, then add class add_opacity
					                	// var d1 = new Date(formatted_payment_due_date);
					                	// var d2 = new Date(current_date);

					                	var add_opacity = '';
					            		// if(d1.getTime() <= d2.getTime()) {
					            			add_opacity = 'add_opacity';
		    							// }

										html += '<div class="form-group col-md-12 mb-0 pt-3">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6 col-xl-6">';
													html += '<div class="black mb-2 fs-18"><strong>Due Date</strong></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
													html += '<div class="input-group date mb-2 other_month_due_date '+add_opacity+'" data-target-input="nearest">';
														html += '<input type="text" name="payment_due_date[]" id="payment_due_date_0" class="payment_due_date form-control readonly_white" autocomplete="off" readonly="" value="'+formatted_payment_due_date+'" />';
														html += '<div class="input-group-append">';
															html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
														html += '</div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										if(response.paymentSchedule.status_constant_name != 'pending_collection' && user_service_name != sa_role){
											html += '<div class="form-group col-md-12 mb-0">';
												html += '<div class="form-row">';
													html += '<div class="form-group col-md-6 col-xl-6">';
														html += '<div class="black mb-2 fs-18"><strong>Payment Method</strong></div>';
													html += '</div>';
													html += '<div class="form-group col-md-6 col-xl-6">';
														html += '<select name="payment_detail_id[]" id="ps_payment_detail" class="form-control mb-2" data-toggle="select2">'+bank_html+'</select>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										}

										if(Object.keys(response.feesData).length > 0){
											html += '<div class="form-group col-md-12 mb-0">';
												html += '<div class="form-row">';
													html += '<div class="form-group col-6 col-md-8 col-xl-8">';
														html += '<div class="mb-2"><strong>'+response.paymentSchedule.payment_type.name+'</strong> <br><small>Due by '+formatted_payment_due_date+'</time></small></div>';
													html += '</div>';
													html += '<div class="form-group col-6 col-md-4 col-xl-4 text-right">';
														html += '<div class="black mb-2"><strong>$'+response.paymentSchedule.amount+'</strong></div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';

											for(var key in response.feesData){
												var exclamation = '<span><i data-id="'+response.feesData[key].id+'" data-waived-amount="'+response.feesData[key].waived_amount+'" data-fee-type-id="'+response.feesData[key].fee_type.id+'" data-fee-type="'+response.feesData[key].fee_type.label+'" data-total-amount="'+response.feesData[key].fee_amount+'" data-remaining-amount="'+response.feesData[key].total_amount+'" class="align-middle mr-2 fas fa-fw fa-info-circle get_related_waived_fees_data_sidebar"></i></span>';

												html += '<div class="form-group col-md-12 mb-0">';
													html += '<div class="form-row">';
														html += '<div class="form-group col-6 col-md-8 col-xl-8">';
															if(response.feesData[key].fee_type.constant == 'late_fee'){
																html += '<div class="mb-2"><strong>Late Fee '+exclamation+'</strong><br><small>Applicable for '+response.feesData[key].no_of_days+' days</small></div>';
															} else {
																html += '<div class="mb-2"><strong>'+response.feesData[key].fee_type.label+'</strong> '+exclamation+'<br><small>applied on <time datetime="21-01-2021">'+response.feesData[key].created+'</time></small></div>';
															}
														html += '</div>';
														html += '<div class="form-group col-6 col-md-4 col-xl-4 text-right red">';
															html += '<div class="mb-2">'+response.feesData[key].total_amount+'</div>';
														html += '</div>';
													html += '</div>';
												html += '</div>';

												html += '<div class="form-group col-md-12 mb-0">';
													html += '<div id="fee_child_'+response.feesData[key].id+'" class="form-row fee_child_'+response.feesData[key].id+'">';
														html += '<div class="form-group col-md-12 col-xl-12"></div>';
													html += '</div>';
												html += '</div>';
											}
										}

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-8 col-xl-8">';
													html += '<div class="black mb-2 fs-22"><strong>Total</strong></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-4 col-xl-4 text-right">';
													html += '<div class="black mb-2 fs-22"><strong>$'+response.paymentSchedule.total+'</strong></div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

					                html += '</div>';

					            html += '</div>';
					        html += '</div>';
						}

						if(response.paymentSchedule.status_constant_name != 'pending_collection'){
							html += '<div class="col-auto d-sm-block mb-2 mt-4">';
								html += '<div class="ps_pay_now_checkbox">';
									html += '<label class="custom-control custom-checkbox m-0">';
									html += '<input name="ps_pay_now_checkbox" id="ps_payment_authorize_checkbox" type="checkbox" class="custom-control-input">';
									html += '<span class="custom-control-label">'+authorize_text+'</span>';
									html += '</label>';
								html += '</div>';
							html += '</div>';

							if(from == 'notification'){
								html += '<div class="text-center mt-4 form-row p-3">';
									html += '<div class="col-6 col-md-6 col-xl-6 form-group">';
										html += '<button type="button" class="btn btn-orange ps_save_rent_collection_data width-100" disabled="">Agree & Authorize</button>';
									html += '</div>';
									html += '<div class="col-6 col-md-6 col-xl-6 form-group">';
										html += '<button type="button" class="btn btn-orange open_notification_section width-100">Back</button>';
									html += '</div>';
								html += '</div>';
							} else {
								html += '<div class="text-center mt-4">';
									html += '<div class="col-auto ml-auto">';
										html += '<button type="button" class="btn btn-orange ps_save_rent_collection_data width-100" disabled="">Agree & Authorize</button>';
									html += '</div>';
								html += '</div>';
							}
						}
				    html += '</div>';
				html += '</form>';

				if(response.paymentSchedule.status_constant_name == 'pending_collection'){
					$('#authorizePaymentModal .border-top').remove();
					$('.payment_due_date').attr('disabled', true);
					$('.review_title').html('Payment Review');
				}

				// $('#ps_rent_collection_container').html(html);
        		$('#sidebar_content_area').html(html);

				$("#ps_payment_detail").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "Select Payment Method",
							dropdownParent: $(this).parent()
						});
				})

				ps_tenant_authorize_datepicker("other_month_due_date", formatted_payment_due_date);

				addRentCollectionValidation();
            } else {
				$('#sidebar_content_area').html('<h3 class="text-center">'+response.response_data.message+'</h3>');
			}
        }
    });
}

window.getPSDetailsForUndoDelete = function(payment_schedule_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/get-payment-schedule-details",
        data: 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id+'&type=undo_delete',
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var html = '';
            	html += '<form id="undo_delete_ps_form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.paymentSchedule != null) {
							var formatted_payment_due_date = response.paymentSchedule.due_date;
		            		var line = '(One time payment)';
		            		if(response.paymentSchedule.payment_type.constant == 'rent' || response.paymentSchedule.payment_type.constant == 'subscription_fee') {
		            			line = 'Due by '+formatted_payment_due_date;
		            		}

		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

		            		html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_id" class="payment_schedule_hidden_input" value="'+response.paymentSchedule.id+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            // html += '<div class="border-bottom">';
					            //     html += '<h5 class="card-title"><span class="fs-22">'+response.paymentSchedule.payment_type.name+'</span> '+line+'</h5>';
					            // html += '</div>';
								html += '<div class="row border-bottom pb-3">';
									html += '<div class="col-8 col-md-8 col-xl-8">';
										html += '<h5 class="card-title"><span class="fs-22">'+response.paymentSchedule.payment_type.name+'</span><br> '+line+'</h5>';
									html += '</div>';
									html += '<div class="col-4 col-md-4 col-xl-4">';
										html += '<input type="hidden" id="ps_main_total_amount" name="ps_main_total_amount" value="'+response.paymentSchedule.total_without_format+'">';
										html += '<div class="fs-22 blalotck text-right ps_main_total_amount"><strong>$'+response.paymentSchedule.total+'</strong></div>';
									html += '</div>';
								html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-12 col-md-12 col-xl-12">';
													html += '<strong>Remarks <span class="text-danger">*</span></strong>';
												html += '</div>';
												html += '<div class="form-group col-12 col-md-12 col-xl-12 text-right">';
													html += '<textarea name="inquiry_textarea" id="inquiry_textarea" class="max_char_count minHeight100" maxlength="500" placeholder="Type Any Remark ..." autofocus></textarea>';
													html += '<div id="the-count" class="float-right">';
														html += '<span id="current">0</span>';
														html += '<span id="maximum">/ 500</span>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';;

					                html += '</div>';
					            html += '</div>';
					        html += '</div>';
						}

						html += '<div class="text-center mt-3">';
							html += '<div class="col-auto ml-auto">';
							var button_class = '';
								html += '<button type="button" class="btn btn-orange save_undo_delete_ps width-100">Save</button>';
							html += '</div>';
						html += '</div>';
				    html += '</div>';
				html += '</form>';

        		$('#sidebar_content_area').html(html);

				addMarkAsPaidValidation();
            } else {
				$('#sidebar_content_area').html('<h3 class="text-center">'+response.response_data.message+'</h3>');
			}
        }
    });
}

window.undoDeletePS = function(payment_schedule_id){
	$.ajax({
		method: 'POST',
		url: api_url+'payment-schedules/undo-delete',
		data: $('#undo_delete_ps_form').serialize()+'&user_id='+user_id+'&user_service_name='+user_service_name+'&payment_schedule_id='+payment_schedule_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				closeRightSidebar();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
				}
			}
		}
	});
}

window.getUploadEvictionDocumentData = function(payment_schedule_id) {
	var html = '';
	html += '<form id="upload-eviction-contract" class="wizard-primary create-account-form" action="javascript:void(0)">';
		html += '<div id="payNowCollapse">'
				html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_id" class="payment_schedule_hidden_input" value="'+payment_schedule_id+'">';

				html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					html += '<div class="rent_collection_data mt-2">';
						html += '<div class="row">';
							html += '<div class="form-group col-md-12 mb-0">';
								html += '<div class="form-row">';
									html += '<div class="form-group col-12 col-md-12 col-xl-12">';
										html += '<strong>Remarks</strong>';
									html += '</div>';
									html += '<div class="form-group col-12 col-md-12 col-xl-12 text-right">';
										html += '<textarea name="inquiry_textarea" id="inquiry_textarea" class="max_char_count" maxlength="500" placeholder="Type Any Remark ..." autofocus></textarea>';
										html += '<div id="the-count" class="float-right">';
											html += '<span id="current">0</span>';
											html += '<span id="maximum">/ 500</span>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';

							html += '<div class="form-group col-md-12 mb-0">';
								html += '<div class="form-row">';
									html += '<div class="form-group col-12 col-md-12 col-xl-12">';
										html += '<strong>Attach your contract</strong>';
									html += '</div>';
									html += '<div class="form-group col-12 col-md-12 col-xl-12 text-right">';
										html += '<div class="center drag_drop_expense border-dashed-grey">';
											html += '<img src="icon/document.svg" class="filter-8" width="50"><br>';
											html += '<div class="image-upload">';
												html += '<label for="inputFile" class="inputFile"><small>Drag and drop</small><br><strong>OR</strong><br><small>Click to add file <span class="blue pointer">Add contract</span><input name="inputFile" id="inputFile" type="file" style="display: none" accept="image/jpg, image/jpeg, image/png, application/pdf" /></small><br><small>Allowed extension : .jpg , .jpeg , .png, .pdf</small></label>';
												// html += '<label for="inputFile" class="inputFile"><small>Drag and drop your reciept image here</small><br><strong>OR</strong><br><small>Add your receipt image here by clicking <span class="blue pointer">Add receipt</span><input name="inputFile[]" id="inputFile" multiple type="file" style="display: none"/></small></label>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';

							html += '<div class="form-group col-md-12 mb-0">';
								html += '<div id="scannedFileList"></div>';
								html += '<div id="inputFileList"></div>';
								html += '<div id="existing_doc"></div>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
				html += '</div>';

			html += '<div class="text-center mt-3">';
				html += '<div class="col-auto ml-auto">';
					html += '<button type="button" class="btn btn-orange save_eviction_contract width-100">Save</button>';
				html += '</div>';
			html += '</div>';
		html += '</div>';
	html += '</form>';

	$('#sidebar_content_area').html(html);
	$('#inquiry_textarea').css('min-height', '100px');

	// uploadEvictionDocumentValidation();
}

window.savePaymentScheduleAttachment = function(){
	var form = $('#upload-eviction-contract')[0];
	var formData = new FormData(form);
	formData.append('user_id', user_id);

	$.ajax({
		method: 'POST',
		url: api_url+'payment-schedule-attachments/add',
		data: formData,
		processData: false,
        contentType: false,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				closeRightSidebar();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
			}
		}
	});
}

window.getPSDetails = function(payment_schedule_id, from) {
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/get-payment-schedule-details",
        data: 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id+'&type=mark_as_paid',
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var html = '';
            	html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.paymentSchedule != null) {
							var formatted_payment_due_date = response.paymentSchedule.due_date;
		            		var line = '(One time payment)';
		            		if(response.paymentSchedule.payment_type.constant == 'rent' || response.paymentSchedule.payment_type.constant == 'subscription_fee') {
		            			line = 'Due by '+formatted_payment_due_date;
		            		}

		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

		            		html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_id" class="payment_schedule_hidden_input" value="'+response.paymentSchedule.id+'">';
		            		html += '<input type="hidden" name="ps_data_from" id="ps_data_from" class="payment_schedule_hidden_input" value="'+from+'">';
		            		html += '<input type="hidden" name="mark_as_paid_opened" id="mark_as_paid_opened" class="payment_schedule_hidden_input" value="1">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            // html += '<div class="border-bottom">';
					            //     html += '<h5 class="card-title"><span class="fs-22">'+response.paymentSchedule.payment_type.name+'</span> '+line+'</h5>';
					            // html += '</div>';
								html += '<div class="row border-bottom pb-3">';
									html += '<div class="col-8 col-md-8 col-xl-8">';
										html += '<h5 class="card-title"><span class="fs-22">Total Amount</span><br> '+line+'</h5>';
									html += '</div>';
									html += '<div class="col-4 col-md-4 col-xl-4">';
										html += '<input type="hidden" id="ps_main_total_amount" name="ps_main_total_amount" value="'+response.paymentSchedule.total_without_format+'">';
										html += '<div class="fs-22 blalotck text-right ps_main_total_amount"><strong>$'+response.paymentSchedule.total+'</strong></div>';
									html += '</div>';
								html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';

										var pt = '';

										html += '<div class="form-group col-md-12 mb-0 pt-3">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6 col-xl-6">';
													html += '<div class="mb-2"><strong>'+response.paymentSchedule.payment_type.name+'</strong> <br><small>Due by '+formatted_payment_due_date+'</time></small></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
													html += '<div class="black mb-2"><strong>$'+currencyFormat(Number(response.paymentSchedule.amount))+'</strong></div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6 col-xl-6">';
													html += '<div class="mb-2"><strong>Additional Fees</strong></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
													html += '<div class="black mb-2"><strong>$'+currencyFormat(Number(response.paymentSchedule.additional_fees))+'</strong></div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										if(response.paymentSchedule.coupon_data){
											html += '<div class="form-group col-md-12 mb-0">';
												html += '<div class="form-row">';
													html += '<div class="form-group col-6 col-md-6 col-xl-6">';
														html += '<div class="mb-2"><strong>Coupon Discount</strong> <br><small>Code : '+response.paymentSchedule.coupon_data.coupon_code+'</small></div>';
													html += '</div>';
													html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
														html += '<div class="green mb-2"><strong>- $'+currencyFormat(Number(response.paymentSchedule.coupon_data.coupon_amount))+'</strong></div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										}

										if(Number(response.paymentSchedule.fees_applied) == 1 && from != 'mark_as_deleted'){
											html += '<div class="form-group col-md-12 mb-0">';
												html += '<div class="form-row">';
													html += '<div class="form-group col-6 col-md-6 col-xl-6">';
														html += '<div class="mb-2">';
														if(Number(response.paymentSchedule.additional_fees) > 0){
															html += '<strong>Do you want to waive fees?</strong>';
														} else {
															html += '<strong>To see waived fees</strong>';
														}
														html += '</div>';
													html += '</div>';
													html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
														html += '<div class="black mb-2"><a href="javascript:void(0)" data-id="'+response.paymentSchedule.id+'" data-open-from="mark_as_paid" class="waive_payment_schedule_late_fee">Click Here</a></div>';
														// html += '</div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';

											html += '<div id="waive_fee_data_container" class="minHeight200 d-none form-group col-md-12 pb-5 bg-light">';
											html += '</div>';
										}

										// if(Object.keys(response.feesData).length > 0){
										// 	for(var key in response.feesData){
										// 		var exclamation = '<span><i data-id="'+response.feesData[key].id+'" data-waived-amount="'+response.feesData[key].waived_amount+'" data-fee-type-id="'+response.feesData[key].fee_type.id+'" data-fee-type="'+response.feesData[key].fee_type.label+'" data-total-amount="'+response.feesData[key].fee_amount+'" data-remaining-amount="'+response.feesData[key].total_amount+'" class="align-middle mr-2 fas fa-fw fa-info-circle get_related_waived_fees_data_sidebar"></i></span>';

										// 		html += '<div class="form-group col-md-12 mb-0">';
										// 			html += '<div class="form-row">';
										// 				html += '<div class="form-group col-6 col-md-6 col-xl-6">';
										// 					if(response.feesData[key].fee_type.constant == 'late_fee'){
										// 						html += '<div class="mb-2"><strong>Late Fee '+exclamation+'</strong><br><small>For '+response.feesData[key].no_of_days+' days</small></div>';
										// 					} else {
										// 						html += '<div class="mb-2"><strong>'+response.feesData[key].fee_type.label+'</strong> '+exclamation+'<br><small>applied on <time datetime="21-01-2021">'+response.feesData[key].created+'</time></small></div>';
										// 					}
										// 				html += '</div>';
										// 				html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right red">';
										// 					html += '<div class="mb-2">'+response.feesData[key].total_amount+'</div>';
										// 				html += '</div>';
										// 			html += '</div>';
										// 		html += '</div>';

										// 		html += '<div class="form-group col-md-12 mb-0">';
										// 			html += '<div id="fee_child_'+response.feesData[key].id+'" class="form-row fee_child_'+response.feesData[key].id+'">';
										// 			html += '</div>';
										// 		html += '</div>';
										// 	}
										// } else {
										// 	pt = 'pt-3';
										// }

										// html += '<div class="form-group col-md-12 mb-0 '+pt+'">';
										// 	html += '<div class="form-row">';
										// 		html += '<div class="form-group col-6 col-md-6 col-xl-6">';
										// 			html += '<div class="black mb-2 fs-20"><strong>Total</strong></div>';
										// 		html += '</div>';
										// 		html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
										// 			html += '<div class="black mb-2 fs-20"><strong>$'+response.paymentSchedule.total+'</strong></div>';
										// 		html += '</div>';
										// 	html += '</div>';
										// html += '</div>';

					                	// check if due date is less then or equal to current date, then add class add_opacity
					                	// var d1 = new Date(formatted_payment_due_date);
					                	var d2 = new Date(current_date).toLocaleDateString("en-US");

					                	// var add_opacity = '';
					            		// if(d1.getTime() <= d2.getTime()) {
					            			// add_opacity = 'add_opacity';
		    							// }

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6 col-xl-6">';
													html += '<div class="mb-2">';
														var add_opacity = '';
														if(from == 'mark_as_deleted'){
															html += '<strong>Deleted On <span class="text-danger">*</span></strong>';
															add_opacity = 'add_opacity_7';
														} else {
															html += '<strong>Paid On <span class="text-danger">*</span></strong>';
														}
													html += '</div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 col-xl-6 text-right">';
													html += '<div class="input-group date mb-2 paid_on_date '+add_opacity+'" data-target-input="nearest">';
														html += '<input type="text" name="paid_on_date" id="paid_on_date" class="form-control readonly_white" autocomplete="off" readonly="" value="'+d2+'" />';
														html += '<div class="input-group-append">';
															html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
														html += '</div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-12 col-md-12 col-xl-12">';
													html += '<strong>Remarks <span class="text-danger">*</span></strong>';
												html += '</div>';
												html += '<div class="form-group col-12 col-md-12 col-xl-12 text-right">';
													html += '<textarea name="inquiry_textarea" id="inquiry_textarea" class="max_char_count" maxlength="500" placeholder="Type Any Remark ..." autofocus></textarea>';
													html += '<div id="the-count" class="float-right">';
														html += '<span id="current">0</span>';
														html += '<span id="maximum">/ 500</span>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-12 col-md-12 col-xl-12">';
													html += '<strong>Attach your receipt here</strong>';
												html += '</div>';
												html += '<div class="form-group col-12 col-md-12 col-xl-12 text-right">';
													html += '<div class="center drag_drop_expense border-dashed-grey">';
														html += '<img src="icon/document.svg" class="filter-8" width="50"><br>';
														html += '<div class="image-upload">';
															html += '<label for="inputFile" class="inputFile"><small>Drag and drop</small><br><strong>OR</strong><br><small>Click to add file <span class="blue pointer">Add receipt</span><input name="inputFile" id="inputFile" type="file" style="display: none" accept="image/jpg, image/jpeg, image/png, application/pdf" /></small><br><small>Allowed extension : .jpg , .jpeg , .png, .pdf</small></label>';
															// html += '<label for="inputFile" class="inputFile"><small>Drag and drop your reciept image here</small><br><strong>OR</strong><br><small>Add your receipt image here by clicking <span class="blue pointer">Add receipt</span><input name="inputFile[]" id="inputFile" multiple type="file" style="display: none"/></small></label>';
														html += '</div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										html += '<div class="form-group col-md-12 mb-0">';
											html += '<div id="scannedFileList"></div>';
											html += '<div id="inputFileList"></div>';
											html += '<div id="existing_doc"></div>';
										html += '</div>';

					                html += '</div>';
					            html += '</div>';
					        html += '</div>';
						}

						html += '<div class="text-center mt-3">';
							html += '<div class="col-auto ml-auto">';
							var button_class = '';
							if(from == 'mark_as_deleted'){
								button_class = 'save_ps_as_deleted';
							} else {
								button_class = 'save_ps_as_paid';
							}
								html += '<button type="button" class="btn btn-orange '+button_class+' width-100">Save</button>';
							html += '</div>';
						html += '</div>';
				    html += '</div>';
				html += '</form>';

				if(response.paymentSchedule.status_constant_name == 'pending_collection'){
					$('#authorizePaymentModal .border-top').remove();
					$('#paid_on_date').attr('disabled', true);
					$('.review_title').html('Payment Review');
				}

        		$('#sidebar_content_area').html(html);
				$('#inquiry_textarea').css('min-height', '100px');

				ps_tenant_authorize_datepicker("paid_on_date", formatted_payment_due_date);

				addMarkAsPaidValidation();
            } else {
				$('#sidebar_content_area').html('<h3 class="text-center">'+response.response_data.message+'</h3>');
			}
        }
    });
}

window.savePSAsPaid = function(payment_schedule_id, from = ''){

	var form = $('#rent-collection-wizard')[0];
	var formData = new FormData(form);
	formData.append('user_id', user_id);

	var url = api_url+'payment-schedules/mark-as-paid';
	if(from == 'mark_as_deleted'){
		url = api_url+'payment-schedules/mark-as-deleted';
	}

	$.ajax({
		method: 'POST',
		url: url,
		data: formData,
		processData: false,
        contentType: false,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				closeRightSidebar();
				if($('#selected_property_id').val()){
					getPropertiesPaymentScheduleWidgetInfo();
				} else {
					getMyAccountPaymentScheduleWidget();
					// $('.ps_filter_property_name').trigger('change');
				}
			}
		}
	});
}

window.updateAutoPay = function(payment_schedule_id){
	$.ajax({
		method: 'POST',
		url: api_url+'payment-schedules/update-auto-pay',
		data: 'payment_schedule_id='+payment_schedule_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if($('#selected_property_id').val()){
				getPropertiesPaymentScheduleWidgetInfo();
			} else {
				getMyAccountPaymentScheduleWidget();
				// $('.ps_filter_property_name').trigger('change');
			}

			if(response.response_data.success != true){
				$('#auto_pay_'+payment_schedule_id).attr('disabled', false);
				$('#auto_pay_'+payment_schedule_id).parent('label').removeClass('add_opacity');
			}
		}
	});
}

window.getPaymentFeeDetails = function(payment_schedule_id, open_from = '') {
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/get-payment-fee-details",
        data: 'user_id='+user_id+'&payment_schedule_id='+payment_schedule_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
			var html = '';
        	if(response.response_data.success == true) {
				var line = '(One time payment)';
				if(response.paymentScheduleData.payment_type.constant == 'rent' || response.paymentScheduleData.payment_type_id == 'subscription_fee') {
					line = 'Due by '+response.paymentScheduleData.month;
				}

            	html += '<form id="waive-fees-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
						html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_'+response.paymentScheduleData.id
						+'" class="payment_schedule_hidden_input" value="'+response.paymentScheduleData.id+'">';

						var payment_due_date = response.paymentScheduleData.due_date;
						var formatted_payment_due_date = getFormattedDate(payment_due_date);
						var bg_white = '';
						if(open_from != 'mark_as_paid'){
							bg_white = 'bg-white';
						}
						html += '<div class="rent-detail-card p-3 mb-1 '+bg_white+'">';
							if(open_from == 'mark_as_paid'){
								html += '<div class="card-actions float-right">';
									html += '<i class="align-middle mr-4 mt-2 fas fa-fw fa-window-close close_waive_fee_container" aria-label="Close"></i>';
								html += '</div>';
							} else {
								html += '<div class="row border-bottom pb-3">';
									html += '<div class="col-8 col-md-8 col-xl-8">';
										html += '<h5 class="card-title"><span class="fs-22">'+response.paymentScheduleData.payment_type.name+'</span><br> '+line+'</h5>';
									html += '</div>';
									html += '<div class="col-4 col-md-4 col-xl-4">';
										html += '<div class="fs-22 black text-right"><strong>$'+currencyFormat(Number(response.paymentScheduleData.amount))+'</strong></div>';
									html += '</div>';
								html += '</div>';
							}
							html += '<div class="rent_collection_data mt-5">';
								var length = Object.keys(response.data).length;
								for(var key in response.data){
									var amount = response.data[key].fee_amount - response.data[key].waived_amount;

									if(amount != response.data[key].fee_amount){
										var exclamation = '<span><i data-id="'+response.data[key].id+'" data-waived-amount="$ '+currencyFormat(Number(response.data[key].waived_amount))+'" data-fee-type-id="'+response.data[key].fee_type.id+'" data-fee-type="'+response.data[key].fee_type.label+'" data-total-amount="$ '+currencyFormat(Number(response.data[key].fee_amount))+'" data-remaining-amount="$ '+currencyFormat(Number(amount))+'" class="align-middle mr-2 fas fa-fw fa-info-circle get_related_waived_fees_data_sidebar"></i></span>';
									} else {
										var exclamation = '';
									}

									if(response.data[key].fee_type.constant == 'late_fee'){
										var label = '<strong>Late Fee '+exclamation+'</strong><br><small>For '+response.data[key].no_of_days+' days</small>';
									} else {
										var label = '<strong>'+response.data[key].fee_type.label+'</strong> '+exclamation+'<br><small>applied on <time datetime="21-01-2021">'+response.data[key].created+'</time></small>';
									}

									html += '<div class="row mb-4">';
										html += '<div class="col-6 col-md-6 col-xl-6">';
											html += '<div>'+label+'</div>';
										html += '</div>';
										html += '<div class="col-6 col-md-6 col-xl-6 text-right">';
											html += '<div class="iBox" style="margin:0;">';

												var add_opacity = '';
												if(amount == 0){
													add_opacity = 'add_opacity';
												}

												html += '<input type="text" name="payment_fee_details['+response.data[key].id+']" id="ps_waive_late_fee_amount" data-id="'+response.paymentScheduleData.id+'" data-max-amount="'+currencyFormat(Number(amount))+'" class="text-right payment_schedule_applied_fees border allow_numeric_with_decimal format_amount '+add_opacity+'" placeholder="Enter Amount to Waive" maxlength="5">';
												html += '<label for="ps_waive_late_fee_amount" class="dark red">$'+currencyFormat(Number(amount))+'</label>';
											html += '</div>';
										html += '</div>';
									html += '</div>';

									html += '<div id="fee_child_'+response.data[key].id+'" class="row fee_child_'+response.data[key].id+'">';
										html += '<div class="col-12 col-md-6 col-xl-6"></div>';
									html += '</div>';
								}
							html += '</div>';
						html += '</div>';

						if(Number(response.paymentScheduleData.additional_fees) > 0){
							html += '<div id="totalCardContainer" class="mb-4">';
								html += '<div class="overview_tiles">';
								if(open_from != 'mark_as_paid'){
									html += '<div class="col-12 col-md-12 col-xl-12">';
										html += '<div class="card light-border mb-0">';
											html += '<div class="card-header">';
												html += '<span class="fs-22 float-right bold-black">$'+currencyFormat(Number(response.paymentScheduleData.total))+'</span>';
												html += '<h5 class="card-title mb-0"><img src="icon/activity/payment.svg" class="filter-15 mr-2" style="width:24px;"><strong class="fs-18 bold-black">Total</strong></h5>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
								}
									html += '<div class="col-12 col-md-12 col-xl-12">';
										html += '<div class="card light-border mb-0">';
											html += '<div class="card-header">';
												html += '<h3 class="green ps_total_waive_fee_amount float-right">$0.00</h3>';
												html += '<h5 class="card-title mb-0"><img src="icon/activity/payment.svg" class="filter-10 mr-2" style="width:24px;"><strong class="fs-18 bold-black green">Waived amount</strong></h5>';
												html += '<input type="hidden" name="ps_total_waive_fee_amount" id="ps_total_waive_fee_amount" data-id="'+response.paymentScheduleData.id+'" value="" data-total="'+response.paymentScheduleData.total+'">';
											html += '</div>';
										html += '</div>';
									html += '</div>';
									html += '<div class="col-12 col-md-12 col-xl-12">';
										html += '<div class="card light-border mb-0">';
											html += '<div class="card-header">';
												html += '<h3 class="ps_total_after_waive_fee float-right">$0.00</h3>';
												html += '<h5 class="card-title mb-0"><img src="icon/activity/payment.svg" class="filter-15 mr-2" style="width:24px;"><strong class="fs-18 bold-black">Total after waive fees</strong></h5>';
												html += '<input type="hidden" name="ps_total_after_waive_fee" id="ps_total_after_waive_fee" data-id="'+response.paymentScheduleData.id+'" value="0">';
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';

							html += '<div class="text-center mt-3">';
								html += '<div class="col-auto ml-auto">';
									html += '<button type="button" class="btn btn-orange waive_ps_late_fee width-100" data-open-from="'+open_from+'">Waive Fees</button>';
								html += '</div>';
							html += '</div>';
						}
					html += '</div>';
				html += '</form>';
            } else {
				html += '<h3 class="text-center"> No Data Available </h3>';
			}

			if(open_from == 'mark_as_paid'){
				$('#waive_fee_data_container').html(html);
			} else {
				//$('#payment_schedule_waive_late_fee_container').html(html);
				$('#sidebar_heading').html('Waive Fee');
				$('#sidebar_content_area').html(html);
			}

			$('.overview_tiles .card').css('min-height', 'auto');
        }
    });
}

window.getWaivedLateFeeDetails = function(payment_fee_details_id, total_fee, remaining_fee, fee_type_id = 0){
	var html = '';
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/get-waived-fee-details",
        data: 'payment_fee_details_id='+payment_fee_details_id+'&user_id='+user_id+'&fee_type_id='+fee_type_id,
        async: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			var bg_light = '';
			if($('#mark_as_paid_opened').val() == 1){
				bg_light = 'bg-light';
			}
			html += '<div class="card flex-fill mb-0 '+bg_light+'">';
				html += '<div class="pl-3 pr-3">';
					if(response.data.fee_type){
						html += '<div class="alert alert-primary alert-outline alert-dismissible width-100" role="alert" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">';
							html += '<div class="alert-icon p-2">';
								html += '<i class="fs-12 mt-1"></i>';
							html += '</div>';
							html += '<div class="alert-message p-2" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">';
								html += '<strong class="fs-12 bold-black">'+response.data.fee_type.description+'</strong>';
							html += '</div>';
						html += '</div>';
					}
					html += '<table id="transaction_table" class="table" style="width:100%">';
						html += '<thead>';
							html += '<tr class="bg-light">';
								html += '<th class="black fs-14">Total Fee Amount</th>';
								html += '<th class="text-right bold-black fs-16">'+total_fee+'</th>';
							html += '</tr>';
						html += '</thead>';
						html += '<tbody>';
							if(response.response_data.success == true && Object.keys(response.data).length > 0) {
								for(var key in response.data){
									if(key != 'fee_type'){
										html += '<tr class="bg-light">';
											var name = 'RS Team';
											if(response.data[key].user){
												name = response.data[key].user.first_name+' '+response.data[key].user.last_name;
											}
											html += '<td class="fs-14">'+name+' waived on <time datetime="21-01-2021">'+response.data[key].created+'</time></td>';
											html += '<td class="fs-14 text-right green""> - '+response.data[key].amount+'</td>';
										html += '</tr>';
									}
								}
							}
							html += '<tr class="bg-light bold-black fs-14">';
								html += '<td class="text-left">Remaining Fee Amount</td>';
								html += '<td class="text-right red"><strong>'+remaining_fee+'</strong></td>';
							html += '</tr>';
						html += '</tbody>';
					html += '</table>';
				html += '</div>';
			html += '</div>';
        }
    });
	return html;
}

window.savePSWaivedLateFeeData = function(open_from = ''){
	if(open_from == 'mark_as_paid'){
		var waived_fees_data = $('#rent-collection-wizard').serialize();
	} else {
		var waived_fees_data = $('#waive-fees-wizard').serialize();
	}
	$.ajax({
		url: api_url+"payment-schedules/waive-fee",
		type: 'POST',
		data: 'user_id='+user_id+'&'+waived_fees_data,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.waive_ps_late_fee').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
				if(open_from == 'mark_as_paid'){
					var payment_schedule_id = $('#payment_schedule_id').val();
					openLoadingDialog($('#sidebar_content_area'));
					$('#sidebar_heading').html('Mark As Paid');
					setTimeout(function(){
						getPSDetails(payment_schedule_id);
						getMyAccountPaymentScheduleWidget();
					}, 500);
				} else {
					// $('#paymentScheduleWaiveModal').modal('hide');
					if($('#selected_property_id').val()){
						getPropertiesPaymentScheduleWidgetInfo();
					} else {
						getMyAccountPaymentScheduleWidget();
						// $('.ps_filter_property_name').trigger('change');
					}
					closeRightSidebar();
				}
            }
		}
	});
}

window.getPartialPaymentData = function(payment_schedule_id){
	$.ajax({
        method: 'POST',
        url: api_url+"payment-schedules/get-payment-schedule-details",
        data: 'user_id='+user_id+'&payment_schedule_id='+payment_schedule_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<input type="hidden" id="selected_payment_schedule_id" value="'+payment_schedule_id+'">';
            	html += '<form id="partial-transaction-form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.paymentSchedule != null) {
		            		var line = '(One time payment)';
		            		if(response.paymentSchedule.payment_type.constant == 'rent' || response.paymentSchedule.payment_type.constant == 'subscription_fee') {
		            			line = 'for the month of '+response.paymentSchedule.month;
		            		}

		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

							var formatted_payment_due_date = response.paymentSchedule.due_date;

		            		html += '<input type="hidden" name="payment_schedule_id" id="payment_schedule_id" class="payment_schedule_hidden_input" value="'+response.paymentSchedule.id+'">';
		            		html += '<input type="hidden" name="payment_schedule_sender_id" id="payment_schedule_sender_id" class="payment_schedule_hidden_input" value="'+response.paymentSchedule.sender_user_id+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.paymentSchedule.payment_type.name+'</span> '+line+'</h5>';
					            html += '</div>';
					            html += '<div class="rent_collection_data">';
					                html += '<div class="row">';
										html += '<div class="form-group col-md-12 border-top pt-3 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="black fs-18"><strong>Total</strong><br><small>Due as of '+response.paymentSchedule.current_date+'</small></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 text-right">';
													html += '<input type="hidden" id="pp_monthly_rent" name="pp_monthly_rent">';
													html += '<div class="black fs-18 pp_monthly_rent" data-value="'+response.paymentSchedule.total+'"><strong>$'+response.paymentSchedule.total+'</strong></div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';
										html += '<div class="form-group col-md-12 border-top pt-4 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="fs-14">Partial Payment</div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<input type="hidden" id="pp_partial_amount" name="pp_partial_amount">';
													html += '<input type="text" name="partial_pay_amount" id="partial_pay_amount" data-partial-fee-amount="'+response.paymentSchedule.lease.partial_payment_value+'" data-partial-method="'+response.paymentSchedule.lease.partial_payment_method+'" lease-id="'+response.paymentSchedule.lease_id+'" class="allow_numeric_with_decimal form-control text-right format_amount" autocomplete="off" value="" placeholder="Enter Partial Amount"/>';
												html += '</div>';
											html += '</div>';
										html += '</div>';

										// check if due date is less then or equal to current date, then add class add_opacity
					                	var d1 = new Date(formatted_payment_due_date);
					                	var d2 = new Date(current_date);

					                	var add_opacity = '';
					            		if(d1.getTime() <= d2.getTime()) {
					            			add_opacity = 'add_opacity';
		    							}

										html += '<div class="form-group col-md-12 pt-2 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="fs-14">Partial Payment Due Date</div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="input-group date mb-2 other_month_due_date '+add_opacity+'" data-target-input="nearest">';
														html += '<input type="text" name="pp_due_date" id="pp_due_date_0" class="pp_due_date form-control readonly_white height-100" readonly autocomplete="off" value="'+formatted_payment_due_date+'" />';
														html += '<div class="input-group-append">';
															html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
														html += '</div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';
										html += '<div class="form-group col-md-12 border-top pt-4 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="black mb-2 fs-18"><strong>Balance</strong></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 text-right">';
													html += '<input type="hidden" id="pp_balance" name="pp_balance">';
													html += '<div class="black fs-16 pp_balance"><strong>$ 0.00</strong></div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';
										html += '<div class="form-group col-md-12 pt-2 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="fs-14">Balance Due Date</div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="input-group date mb-2 balance_due_date" data-target-input="nearest">';
														html += '<input type="text" name="payment_due_date" id="payment_due_date_0" class="payment_due_date form-control readonly_white height-100" readonly autocomplete="off" value="'+formatted_payment_due_date+'" />';
														html += '<div class="input-group-append">';
															html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
														html += '</div>';
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';
										html += '<div class="form-group col-md-12 pt-2 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="fs-14">Partial Payment Fee<br>';
														if(response.paymentSchedule.lease.partial_payment_method == 'P'){
															html += '<small id="pp_payment_fee_label">('+response.paymentSchedule.lease.partial_payment_value+'% of Balance)</small>';
														} else{
															html += '<small id="pp_payment_fee_label">(Fixed Amount)</small>';
														}
														html += '<small id="pp_payment_fee_label"></small>';
													html += '</div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 text-right red">';
													html += '<input type="hidden" id="pp_payment_fee" name="pp_payment_fee">';
													html += '<div class="fs-14 pp_payment_fee">';
														if(response.paymentSchedule.lease.partial_payment_method == 'P'){
															html += '<strong>$ 0.00</strong>';
														} else {
															html += '<strong>$'+currencyFormat(Number(response.paymentSchedule.lease.partial_payment_value))+'</strong>';
														}
													html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';
										html += '<div class="form-group col-md-12 border-top pt-4 mb-0">';
											html += '<div class="form-row">';
												html += '<div class="form-group col-6 col-md-6">';
													html += '<div class="black mb-2 fs-18"><strong>Remaining Amount</strong><br><small>(Balance + Partial Payment Fee)</small></div>';
												html += '</div>';
												html += '<div class="form-group col-6 col-md-6 text-right">';
													html += '<input type="hidden" id="pp_total_balance_amount" name="pp_total_balance_amount">';
													html += '<div class="black fs-18 pp_total_balance_amount"><strong>$ 0.00</strong></div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';
					                html += '</div>';
					            html += '</div>';
					        html += '</div>';
						}

				    html += '</div>';
					html += '<div class="text-center">';
						html += '<div class="col-auto ml-auto">';
							html += '<button type="button" class="btn btn-orange save_ps_partial_payment_data width-100">Save</button>';
						html += '</div>';
					html += '</div>';
				html += '</form>';

				// $('#get_partial_payment_data_container').html(html);
        		$('#sidebar_content_area').html(html);

				ps_tenant_authorize_datepicker("balance_due_date", formatted_payment_due_date);
				ps_tenant_authorize_datepicker("other_month_due_date", formatted_payment_due_date);

				partialTransactionValidation();
            } else {
				$('#sidebar_content_area').html('<h3 class="text-center">'+response.response_data.message+'</h3>');
			}
        }
    });
}

window.savePartialPaymentData = function(){
	var pp_data = $('#partial-transaction-form').serialize();
	$.ajax({
		method: 'POST',
		url: api_url+"payment-schedules/create-partial-payment",
		data: pp_data+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response){
			// $('#getPartialPaymentModal').modal('hide');
			closeRightSidebar();
			display_error_message(response);
			//getMyAccountPaymentScheduleWidget();
			if($('#selected_property_id').val()){
				getPropertiesPaymentScheduleWidgetInfo();
			} else {
				getMyAccountPaymentScheduleWidget();
				// $('.ps_filter_property_name').trigger('change');
			}
		}
	})
}

// New Payment Workflow : End

window.getMyAccountAutopayWidget = function() {
	openLoadingDialog($('#myaccount_autopay_widget'));
	setTimeout(function(){
		if(user_service_name == po_role){
			$('#myaccount_autopay_widget').addClass('d-none');
		}
    	$('#myaccount_autopay_widget').load('components/myaccount/myaccount_autopay_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        url: api_url+"autopays",
			    type: 'POST',
			    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	var html = '';
		        	if(response.response_data.success == true) {
		        		for (var key in response.leases) {
			                if (response.leases.hasOwnProperty(key)) {
			                	var property_name = response.leases[key].property.name
			                	var start_date = new Date(response.leases[key].start_date).toDateString();
			                	var end_date = new Date(response.leases[key].end_date).toDateString();
			                	var status = '-';
			                	var payment_data = '-';
			                	var date = '-';
			                    if(response.leases[key].autopay != null && response.leases[key].autopay != 0) {
			                		if(response.leases[key].autopay.autopay == 0) {
				                    	status = '<span class="badge badge-danger">Disabled</span>';
				                    } else {
				                        status = '<span class="badge badge-success">Enabled</span>';
				                    }
				                    if(response.leases[key].autopay.payment_detail_id > 0) {
										if(response.leases[key].autopay.payment_detail.account_number){
											payment_data = response.leases[key].autopay.payment_detail.bank_name+'('+response.leases[key].autopay.payment_detail.account_number+')';
										} else {
											payment_data = response.leases[key].autopay.payment_detail.bank_name;
										}
				                    }
				                    if(response.leases[key].autopay.day > 0) {
				                    	date = ordinal_suffix_of(response.leases[key].autopay.day)+' of every month';
				                    }
			                	}

								var kebab_item = '';
								// if(response.leases[key].autopay_enabled == 0 && (localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1)) {
								// 	kebab_item = '<a href="javascript:void(0)" data-type="enable" data-id="'+response.leases[key].id+'" data-property-id="'+response.leases[key].property_id+'" class="dropdown-item enable_disable_autopay_btn disable_action">Enable Autopay</a>';
								// } else if(localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1){
								// 	kebab_item = '<a href="javascript:void(0)" data-type="disable" data-id="'+response.leases[key].id+'" data-property-id="'+response.leases[key].property_id+'" class="dropdown-item enable_disable_autopay_btn disable_action">Disable Autopay</a>';
								// }

								if(localStorage.getItem("primary") == null || localStorage.getItem("allow_ai_access") == 1){
									kebab_item = '<a href="javascript:void(0)" data-id="'+response.leases[key].id+'" data-property-id="'+response.leases[key].property_id+'" class="dropdown-item enable_disable_autopay_btn disable_action">Edit</a>';
								}

								var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';

			                	html += '<tr>';
				                    html += '<td>'+property_name+'</td>';
				                    html += '<td>'+start_date.substring(4)+'</td>';
				                    html += '<td>'+end_date.substring(4)+'</td>';
				                    html += '<td>'+payment_data+'</td>';
				                    html += '<td>'+date+'</td>';
				                    html += '<td class="text-right">$'+response.leases[key].monthly_rent_amount+'</td>';
				                    html += '<td>'+status+'</td>';
									html += '<td>'+kebab+'</td>';
			                    html += '</tr>';
			                }
			            }

				        $('#myaccount-autopay-data-container').html(html);

				        var autopay_table = $("#myaccount-autopay-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '200px',
						    scrollX: false,
							columnDefs: [
								{
									"targets": [1, 2],
									"type": 'date'
								},
							]
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.saveAutopayData = function(type, lease_id, property_id, payment_detail_id, day, autopay) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	// if(autopay == 1) {
		var data = 'user_id='+user_id+'&user_timezone='+timezone+'&type='+type+'&lease_id='+lease_id+'&property_id='+property_id+'&payment_detail_id='+payment_detail_id+'&day='+day+'&autopay='+autopay;
	// } else {
		// var data = 'user_id='+user_id+'&user_timezone='+timezone+'&type='+type+'&lease_id='+lease_id+'&property_id='+property_id+'&autopay='+autopay;
	// }
	$.ajax({
        method: 'POST',
        url: api_url+"autopays/save-autopay-data",
        data:data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			$('.save_autopay_data').text('Update');
			$('.save_autopay_data').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#enableAutopayModal').modal('hide');
            	getMyAccountAutopayWidget();
            }
        }
    });
}

window.getMyAccountLinkedAccountDetailWidget = function() {
	openLoadingDialog($('#myaccount_linked_account_detail_widget'));
	setTimeout(function(){
    	$('#myaccount_linked_account_detail_widget').load('components/myaccount/myaccount_linked_account_detail_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"linked-accounts",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	var html = '';
		        	if(response.response_data.success == true) {
		        		$('#myaccount_link_account_add_btn').html('<span class="btn btn-orange float-right add_new_account">Add</span>');
		    			for (var key in response.linkedAccounts) {
			                if (response.linkedAccounts.hasOwnProperty(key)) {
			                	var from_user = response.linkedAccounts[key].from_user.first_name+' '+response.linkedAccounts[key].from_user.last_name+'<br><small>'+response.linkedAccounts[key].from_user.email+'</small>';
			                	var to_user = response.linkedAccounts[key].to_user.first_name+' '+response.linkedAccounts[key].to_user.last_name+'<br><small>'+response.linkedAccounts[key].to_user.email+'</small>';
			                	html += '<tr>';
				                    //html += '<td>'+response.linkedAccounts[key].id+'</td>';
				                    html += '<td>'+from_user+'</td>';
				                    html += '<td>'+to_user+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].services+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].status_label+'</td>';
				                    html += '<td><a href="javascript:void(0)" data-id="'+response.linkedAccounts[key].id+'" class="unlink_account">Unlink</a></td>';
			                    html += '</tr>';
			                }
			            }

				        $('#myaccount-linked_account-data-container').html(html);

				        var linked_account_table = $("#myaccount-linked-account-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.getMyAccountDocumentWidget = function() {
	openLoadingDialog($('#myaccount_document_widget'));
	setTimeout(function(){
    	$('#myaccount_document_widget').load('components/myaccount/myaccount_document_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"dwolla-documents",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
					if(response.ButtonEnable == 1){
						$('#myaccount_document_add_btn').html('<span class="btn btn-orange float-right upload_dwolla_document">Upload KYC Document</span>');
					}
		        	var html = '';
		        	if(response.response_data.success == true) {
		        		for (var key in response.documents) {
			                if (response.documents.hasOwnProperty(key)) {
			                	html += '<tr>';
				                    html += '<td>'+response.documents[key].display_name+'</td>';
				                    html += '<td>'+response.documents[key].status_label+'</td>';
				                    html += '<td>'+response.documents[key].failure_reason+'</td>';
				                    html += '<td>'+response.documents[key].message+'</td>';
				                    html += '<td>'+response.documents[key].created+'</td>';
			                    html += '</tr>';
			                }
			            }

				        $('#myaccount-document-data-container').html(html);

				        var document_table = $("#myaccount-document-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.getMyAccountActivityDetailWidget = function() {
	openLoadingDialog($('#myaccount_activity_log_widget'));
	setTimeout(function(){
		$('#myaccount_activity_log_widget').load('components/myaccount/myaccount_activity_log_widget.html', function(){
			var user_id = user.user_details.user_id;
	    	var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"user-activities/list",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	display_error_message(response);
		        	if(response.response_data.success == true) {
		        		var html = '';
		            	for (var key in response.userActivities) {
			                if (response.userActivities.hasOwnProperty(key)) {
			                	html += '<div class="fs-16 bold-black mt-3">'+key+'</div>';
			                	for (var jkey in response.userActivities[key]) {
			                		var username = '';
			                		if(response.userActivities[key][jkey].user != null) {
			                			username = response.userActivities[key][jkey].user.first_name+' '+response.userActivities[key][jkey].user.last_name
			                		}
				                	var message = response.userActivities[key][jkey].message;
				                	var created1 = response.userActivities[key][jkey].created1;
				                	html += '<div class="m-3">';
					                    html += '<div class="media">';
					                        html += '<img src="icon/avatar.jpg" width="36" height="36" class="rounded-circle mr-2" alt="Ashley Briggs">';
					                        html += '<div class="media-body mt-2">';
					                            html += '<small class="float-right text-navy">'+created1+'</small>';
					                            html += '<strong>'+username+'</strong> '+message;
					                        html += '</div>';
					                    html += '</div>';
						            html += '</div>';
							    }
			                }
			            }

			            if(user_id > 0) {
			            	$('#myaccount-audit-log-data-container').html(html);
			            } else {
			            	$('#activities-container').html(html);
			            }
		            }
		        }
		    });
		});
	}, 1000);
}

// window.getMyAccountInvoiceDetailWidget = function() {
// 	openLoadingDialog($('#myaccount_invoice_detail_widget'));
// 	setTimeout(function(){
//     	$('#myaccount_invoice_detail_widget').load('components/myaccount/myaccount_invoice_detail_widget.html', function(){
// 			var user_id = user.user_details.user_id;
// 			var tz = jstz.determine();
// 			var timezone = tz.name();
// 			$.ajax({
// 		        method: 'POST',
// 		        url: api_url+"invoices",
// 		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
// 		        beforeSend: function(xhr) {
// 			        xhr.setRequestHeader('Authorization', accessToken);
// 			    },
// 		        success: function(response) {
// 		        	var html = '';
// 		        	if(response.response_data.success == true) {
// 		        		for (var key in response.invoices) {
// 			                if (response.invoices.hasOwnProperty(key)) {

// 			                	var bg_light = '';
// 			                	if(key % 2 == 1) {
// 			                		bg_light = 'bg-light';
// 			                	}

// 			                	var kebab_item = '';
// 			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item view_invoice" href="javascript:void(0)">View</a>';
// 			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" data-from="invoice_list" class="dropdown-item download_invoice" href="javascript:void(0)">Download</a>';
// 			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item email_invoice" href="javascript:void(0)">Email</a>';

// 			                	var status = '';
// 			                    if(response.invoices[key].status == 1) {
// 						        	status += '<div class="badge badge-danger">'+response.invoices[key].status_label+'</div>';
// 						        } else if(response.invoices[key].status == 3) {
// 						        	status += '<div class="badge badge-warning">'+response.invoices[key].status_label+'</div>';
// 						        } else if(response.invoices[key].status == 4) {
// 						        	status += '<div class="badge badge-success">'+response.invoices[key].status_label+'</div>';
// 						        }

// 			                    var kebab = kebab_item;

// 			                	var coupon_code = response.invoices[key].coupon_code;
// 			                	var coupon_amount = '$'+response.invoices[key].coupon_discounted_amount;
// 			                	if(response.invoices[key].coupon_id == 0) {
// 			                		coupon_code = '-';
// 			                		coupon_amount = '-';
// 			                	}

// 			                	// Table based UI
// 			                	html += '<tr data-id="'+response.invoices[key].id+'">';
// 			                		html += '<td>'+response.invoices[key].id+'</td>';
// 			                		html += '<td>'+response.invoices[key].property.name+'</td>';
// 			                		html += '<td>'+response.invoices[key].bill_from_date+'<br>to<br>'+response.invoices[key].bill_to_date+'</td>';
// 			                		html += '<td>'+response.invoices[key].next_bill_from_date+'</td>';
// 			                		html += '<td>';
// 			                			html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
// 						                html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
// 			                		html += '</td>';
// 			                		html += '<td class="green fs-16">$'+response.invoices[key].total+'</td>';
// 			                		html += '<td>'+status+'</td>';
// 			                		html += '<td>';
// 			                			html += '<small>';
// 	                                        html += '<a href="javascript:void(0)" data-invoice-id="'+response.invoices[key].id+'" class="view_invoice">View</a><br>';
// 	                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' data-from="invoice_list" class="download_invoice">Download</a><br>';
// 	                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' class="email_invoice">Email</a><br>';
// 	                                    html += '</small>';
// 			                		html += '</td>';
// 			                	html += '</tr>';
// 			                }
// 			            }

// 				        $('#myaccount-invoice-data-container').html(html);

// 				        var myaccount_invoice_table = $("#myaccount-invoice-datatable").DataTable({
// 			            	responsive: true,
// 			            	pageLength: 6,
// 							lengthChange: false,
// 							dom: 'frtip',
// 			    			autoWidth: false,
// 			    			searching: false,
// 			            	paging: false,
// 			            	info: false,
// 						    scrollY: '400px',
// 						    scrollX: false
// 						});
// 		            } else {
// 		            	display_error_message(response);
// 		            }
// 		        }
// 		    });
// 		});
// 	}, 1000);
// }

window.getMyAccountInvoiceDetailWidget = function() {
	openLoadingDialog($('#myaccount_invoice_detail_widget'));
	setTimeout(function(){
    	$('#myaccount_invoice_detail_widget').load('components/myaccount/myaccount_invoice_detail_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"invoices",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	var html = '';
		        	if(response.response_data.success == true) {
		        		for (var key in response.invoices) {
			                if (response.invoices.hasOwnProperty(key)) {

			                	var bg_light = '';
			                	if(key % 2 == 1) {
			                		bg_light = 'bg-light';
			                	}

			                	var kebab_item = '';
			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item view_invoice" href="javascript:void(0)">View</a>';
			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" data-from="invoice_list" class="dropdown-item download_invoice" href="javascript:void(0)">Download</a>';
			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item email_invoice" href="javascript:void(0)">Email</a>';

			                	var status = '';
			                    if(response.invoices[key].status == 1 || response.invoices[key].status == 2) {
						        	status += '<div class="badge badge-danger">'+response.invoices[key].status_label+'</div>';
						        } else if(response.invoices[key].status == 3) {
						        	status += '<div class="badge badge-warning">'+response.invoices[key].status_label+'</div>';
						        } else if(response.invoices[key].status == 4) {
						        	status += '<div class="badge badge-success">'+response.invoices[key].status_label+'</div>';
						        }

			                    var kebab = kebab_item;

			                	// Table based UI
			                	html += '<tr data-id="'+response.invoices[key].id+'">';
			                		html += '<td>'+response.invoices[key].id+'</td>';
									if(response.invoices[key].property){
										html += '<td>'+response.invoices[key].property.name+'</td>';
									} else {
										html += '<td>-</td>';
									}
			                		html += '<td>'+response.invoices[key].invoice_month+'</td>';
			                		html += '<td>'+response.invoices[key].next_bill_from_date+'</td>';
			                		html += '<td class="green fs-16">$'+response.invoices[key].total+'</td>';
			                		html += '<td>'+status+'</td>';
			                		html += '<td>';
			                			html += '<small>';
	                                        html += '<a href="javascript:void(0)" data-invoice-id="'+response.invoices[key].id+'" class="view_invoice">View</a><br>';
	                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' data-from="invoice_list" class="download_invoice">Download</a><br>';
	                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' class="email_invoice">Email</a><br>';
	                                    html += '</small>';
			                		html += '</td>';
			                	html += '</tr>';
			                }
			            }

				        $('#myaccount-invoice-data-container').html(html);

				        var myaccount_invoice_table = $("#myaccount-invoice-datatable").DataTable({
			            	responsive: true,
							order: [0, 'desc'],
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.loadTablePaymentDetail = function() {

	// Below confition will check if applications page or my account page open
	var page_name = localStorage.getItem('page_name');
	if(page_name == 'users') {
		var user_id = $('#selected_user_id').val();
		var data = 'user_id='+user_id;
	} else {
		var data = 'user_id='+user.user_details.user_id;
	}

	$.ajax({
	    method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.totalBanks == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.userBanks) {
	                if (response.userBanks.hasOwnProperty(key)) {

	                	var bg_light = '';
	                	if(key % 2 == 1) {
	                		bg_light = 'bg-light';
	                	}

	                	var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;

	                	var kebab_item = '';
	                	var deleted_menu = '';
	                	if(user_service_name == po_role && response.userBanks[key].status == 2) {
	                		kebab_item += '<a class="dropdown-item link_bank_and_property" data-module="Bank" data-id="'+response.userBanks[key].id+'" data-payment-method="'+payment_method+'" href="javascript:void(0)">Link Property</a>';
	                	}

	                	var status = '';
	                    if(response.userBanks[key].status == 0) { // Unverified
	                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
	                    } else if(response.userBanks[key].status == 1) { // Awaiting
	                        status += '<span class="badge badge-warning">'+response.userBanks[key].status_label+'</span>';
	                        kebab_item += '<a href="javascript:void(0)" data-id='+response.userBanks[key].id+' class="dropdown-item verify_bank_detail" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false">Verify Bank Detail</a>';
	                    } else if(response.userBanks[key].status == 2) { // Verified
	                        status += '<span class="badge badge-success">'+response.userBanks[key].status_label+'</span>';
	                    } else if(response.userBanks[key].status == 3) { // Deleted
	                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
	                        //deleted_menu += '<a class="dropdown-item restore_bank_detail" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Restore</a>';
	                    } else if(response.userBanks[key].status == 10) { // Processing
	                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
	                        if(user_service_name == sa_role) {
	                        	kebab_item += '<a class="dropdown-item initiate_micro_deposit" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Initiate Micro Deposit</a>';
	                        }
	                    }

	                    if(user.user_details.user_id == response.userBanks[key].user_id) {
	                    	kebab_item += '<a class="dropdown-item delete_bank_detail" data-id="'+response.userBanks[key].id+'" data-message="'+response.userBanks[key].message+'" href="javascript:void(0)">Delete</a>';
	                    }

	                    var kebab = '';
	                    if(response.userBanks[key].status != 3 && kebab_item != '') {
	                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	                    }

	                	// Card based UI
	                	html += '<div data-id="'+response.userBanks[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
							html += '<div class="card">';
								html += '<div class="card-header bg-light"><span class="accordion_arrow">'+kebab+'</span><h5 class="card-title capitalize mb-0 mr-3">'+response.userBanks[key].bank_name+'</h5></div>';
								html += '<div class="text-center"><img src="img/sample/bank.jpg" height="100" width="100" alt="Unsplash"></div>';
								html += '<div class="card-body">';
									html += '<table class="table mb-0 card-table word-break">';
										html += '<tbody>';
											html += '<tr>';
												html += '<td class="nowrap">Account Number</td>';
												html += '<td class="text-right">'+response.userBanks[key].account_number+'</td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Account Type</td>';
												html += '<td class="text-right">'+response.userBanks[key].account_type_label+'</td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Linked to</td>';
												html += '<td class="text-right">'+response.userBanks[key].linked_to+' Properties</td>';
											html += '</tr>';
										html += '</tbody>';
									html += '</table>';
									html += '<div class="text-right mt-4">'+status+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
	                }
	            }
	        }
            $('#paymentDataContainer').html(html);
            // Get Floating Action Button
            if(page_name != 'users') {
				getFloatingActionButton('Bank', '#paymentDataContainer');
			}
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.deleteBank = function(payment_detail_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'GET',
        url: api_url+"payment-details/delete/"+payment_detail_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadTablePaymentDetail();
            }
        }
    });
}

window.initiateMicroDeposit = function(payment_detail_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/initiate-micro-deposit",
        data:'payment_details_id='+payment_detail_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadTablePaymentDetail();
            }
        }
    });
}

window.refreshAccountDetail = function(payment_detail_id) {
	$('#myaccount_payment_detail_widget').addClass('add_opacity');
	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/refresh-account-detail",
        data:'payment_detail_id='+payment_detail_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
			$('#myaccount_payment_detail_widget').removeClass('add_opacity');
            if(response.response_data.success == true) {
                getMyAccountPaymentDetailWidget();
            }
        }
    });
}

window.loadTableInvoiceDetail = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	var data = 'user_id='+user.user_details.user_id+'&user_timezone='+timezone;
	$.ajax({
	    method: 'POST',
	    url: api_url+"invoices",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.invoices == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.invoices) {
	                if (response.invoices.hasOwnProperty(key)) {

	                	var bg_light = '';
	                	if(key % 2 == 1) {
	                		bg_light = 'bg-light';
	                	}

	                	var kebab_item = '';
	                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item view_invoice" href="javascript:void(0)">View</a>';
	                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" data-from="invoice_list" class="dropdown-item download_invoice" href="javascript:void(0)">Download</a>';
	                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item email_invoice" href="javascript:void(0)">Email</a>';

	                	var status = '';
	                    if(response.invoices[key].status == 1) {
				        	status += '<div class="badge badge-danger">'+response.invoices[key].status_label+'</div>';
				        } else if(response.invoices[key].status == 3) {
				        	status += '<div class="badge badge-warning">'+response.invoices[key].status_label+'</div>';
				        } else if(response.invoices[key].status == 4) {
				        	status += '<div class="badge badge-success">'+response.invoices[key].status_label+'</div>';
				        }

	                    var kebab = kebab_item;

	                	// Card based UI

	                	var coupon_code = response.invoices[key].coupon_code;
	                	var coupon_amount = '$'+response.invoices[key].coupon_discounted_amount;
	                	if(response.invoices[key].coupon_id == 0) {
	                		coupon_code = '-';
	                		coupon_amount = '-';
	                	}

	                	html += '<div class="card tab-card '+bg_light+'">';
				            html += '<div class="accordion">';
				                html += '<div class="border-bottom">';
				                    html += '<h5 class="card-title my-2 fs-16">Invoice: #'+response.invoices[key].id+' &nbsp;'+status+'</h5>';
				                    html += '<span class="accordion_arrow">';
				                        html += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div>';
				                    html += '</span>';
				                html += '</div>';
				                html += '<div class="tab_inner_data" data-id="'+response.invoices[key].id+'" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">';
				                    html += '<div class="row">';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                	html += '<div class="black"><strong>Property:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].property.name+'</strong></div>';
				                                    html += '<div class="black"><strong>Plan:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].plan_name+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Invoice Month:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_from_date+'<br>to<br>'+response.invoices[key].bill_to_date+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Next Billing Date:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].next_bill_from_date+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Coupon:</strong></div>';
				                                    html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
				                                    html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Bill From:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_from_name+'</strong></div>';
				                                    html += '<div class="black"><strong>Billed To:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_to_name+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-1 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                	html += '<div class="black"><strong>Amount:</strong></div>';
				                                    html += '<div class="green fs-18"><strong> $'+response.invoices[key].total+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-1 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<small>';
				                                        html += '<a href="javascript:void(0)" data-invoice-id="'+response.invoices[key].id+'" class="view_invoice">View</a><br>';
				                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' data-from="invoice_list" class="download_invoice">Download</a><br>';
				                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' class="email_invoice">Email</a><br>';
				                                    html += '</small>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
	                }
	            }
	        }
            $('#invoiceDataContainer').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

// window.getInvoiceData = function(invoice_id) {
// 	var tz = jstz.determine();
// 	var timezone = tz.name();
// 	var data = 'invoice_id='+invoice_id+'&user_timezone='+timezone+'&user_id='+user_id;
// 	$.ajax({
// 	    method: 'POST',
// 	    url: api_url+"invoices/view",
// 	    data:data,
// 	    beforeSend: function(xhr) {
// 	        xhr.setRequestHeader('Authorization', accessToken);
// 	    },
// 	}).then(function(response) {
// 		if(response.response_data.success == true) {
// 			var html = '';
// 			if(response.invoice == 0) {
// 	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
// 		    } else {

// 		    	var status = '';
//                 if(response.invoice.status == 1) {
// 		        	status += '<div class="badge badge-danger">'+response.invoice.status_label+'</div>';
// 		        } else if(response.invoice.status == 3) {
// 		        	status += '<div class="badge badge-warning">'+response.invoice.status_label+'</div>';
// 		        } else if(response.invoice.status == 4) {
// 		        	status += '<div class="badge badge-success">'+response.invoice.status_label+'</div>';
// 		        }

// 		        html += '<input type="hidden" name="selected_invoice_id" id="selected_invoice_id" value="'+invoice_id+'"/>';

// 		    	html += '<div class="col-12 col-md-12 col-xl-12">';
// 		            html += '<div class="card-body m-sm-3">';
// 		                html += '<div class="mb-4">';
// 							html += '<h3 class="float-right mt-2">INVOICE</h3>';
// 							html += '<img src="img/logo/Rent_Preview_File_v2.png" height="35px" />';
// 		                html += '</div>';
// 		                html += '<hr class="my-4">';
// 		                html += '<div class="row mb-4">';
// 		                    html += '<div class="col-md-6">';
// 		                        html += '<span class="bold-black">'+response.invoice.bill_from_name+'</span>';
// 		                        html += '<p>';
// 		                            html += ''+response.invoice.bill_from_name+'<br>'+response.invoice.bill_from_address_line1+', '+response.invoice.bill_from_address_line2+' <br> '+response.invoice.bill_from_city+', '+response.invoice.bill_from_state+' '+response.invoice.bill_from_zipcode+' <br> '+response.invoice.bill_from_country+' <br>';
// 		                            html += '<a href="#">'+response.invoice.bill_from_email+'</a>';
// 		                        html += '</p>';
// 		                    html += '</div>';
// 		                    html += '<div class="col-md-6 text-md-left">';
// 		                        html += 'Customer Account: '+response.invoice.customer_account_number+'<br>';
// 		                        html += 'Invoice: #'+response.invoice.id+'<br>';
// 		                        html += 'Invoice Date: '+response.invoice.invoice_date1+'<br>';
// 		                        html += 'Invoice Amount: <span class="bold-black">$'+response.invoice.total+' (USD)</span><br>';
// 		                        html += '<h4 class="mt-2">'+status+'</h4>';
// 		                    html += '</div>';
// 		                html += '</div>';
// 		                html += '<div class="row mb-4">';
// 		                    html += '<div class="col-md-6">';
// 		                        html += '<span class="bold-black">BILLED TO</span>';
// 		                        html += '<p>';
// 		                            html += ''+response.invoice.bill_to_name+'<br>'+response.invoice.bill_to_address_line1+', '+response.invoice.bill_to_address_line2+' <br> '+response.invoice.bill_to_city+', '+response.invoice.bill_to_state+' '+response.invoice.bill_to_zipcode+' <br> '+response.invoice.bill_to_country+' <br>';
// 		                            html += '<a href="#">'+response.invoice.bill_to_email+'</a>';
// 		                        html += '</p>';
// 		                    html += '</div>';
// 		                    html += '<div class="col-md-6 text-md-left">';
// 		                        html += '<span class="bold-black">'+response.invoice.sp_type_label+'</span><br>';
// 		                        if(response.invoice.sp_typeID == 1) {
// 		                        	html += 'Plan Name: <span class="bold-black">Basic</span><br>';
// 		                        }
// 		                        html += 'Property: '+response.invoice.property.name+'<br>';
// 		                        html += 'Billing Period: '+response.invoice.bill_from_date+' to '+response.invoice.bill_to_date+'<br>';
// 		                        html += 'Next Billing Date: '+response.invoice.next_bill_from_date+'<br>';
// 		                        html +=  response.invoice.source_ach_id;
// 		                    html += '</div>';
// 		                html += '</div>';
// 		                html += '<table class="table table-sm">';
// 		                    html += '<thead>';
// 		                        html += '<tr class="bg-light">';
// 		                            html += '<th><span class="bold-black">Description</span></th>';
// 		                            html += '<th><span class="bold-black">Unit</span></th>';
// 		                            html += '<th class="text-right"><span class="bold-black">Unit Price</span></th>';
// 		                            html += '<th class="text-right"><span class="bold-black">Amount(USD)</span></th>';
// 		                        html += '</tr>';
// 		                    html += '</thead>';
// 		                    html += '<tbody>';
// 		                        html += '<tr>';
// 		                            html += '<td><span class="bold-black">Rent Synergy Monthly Subscription</span></td>';
// 		                            html += '<td>1</td>';
// 		                            html += '<td class="text-right">$'+response.invoice.invoice_amount+'</td>';
// 		                            html += '<td class="text-right">$'+response.invoice.invoice_amount+'</td>';
// 		                        html += '</tr>';
// 		                        if(response.invoice.coupon_id > 0) {
// 		                        	html += '<tr>';
// 			                            html += '<td><span class="green ">Coupon Applied ('+response.invoice.coupon_code+')</span></td>';
// 			                            html += '<td>&nbsp;</td>';
// 			                            html += '<td class="text-right">&nbsp</td>';
// 			                            html += '<td class="green text-right">$'+response.invoice.coupon_discounted_amount+'</td>';
// 			                        html += '</tr>';
// 		                        }
// 		                        html += '<tr>';
// 		                            html += '<th>&nbsp;</th>';
// 		                            html += '<th>&nbsp;</th>';
// 		                            html += '<th class="text-right">Total </th>';
// 		                            html += '<td class="text-right">$'+response.invoice.total+'</td>';
// 		                        html += '</tr>';
// 		                        html += '<tr>';
// 		                            html += '<th>&nbsp;</th>';
// 		                            html += '<th>&nbsp;</th>';
// 		                            html += '<th class="text-right">Payments </th>';
// 		                            html += '<th class="text-right">($'+response.invoice.total+')</th>';
// 		                        html += '</tr>';
// 		                        html += '<tr>';
// 		                            html += '<th>&nbsp;</th>';
// 		                            html += '<th>&nbsp;</th>';
// 		                            html += '<th class="text-right">Amount Due(USD) </th>';
// 		                            html += '<th class="text-right">$0.00</th>';
// 		                        html += '</tr>';
// 		                    html += '</tbody>';
// 		                html += '</table>';
// 		                html += '<div class="text-center">';
// 		                    html += '<a href="javascript:void(0)" data-invoice-id='+response.invoice.id+' data-from="invoice_preview" class="btn btn-orange download_invoice hide_download_invoice">Download Invoice</a>';
// 		                html += '</div>';
// 		            html += '</div>';
// 			    html += '</div>';
// 	        }
//             $('#invoice_content_container').html(html);
//         } else {
//             display_error_message(response);
//         }
//     }, function() {
//         // hang on this step if the error occur
//     });
// }

window.getInvoiceData = function(invoice_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	var data = 'invoice_id='+invoice_id+'&user_timezone='+timezone;
	$.ajax({
	    method: 'POST',
	    url: api_url+"invoices/view",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.invoice == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {

		    	var status = '';
                if(response.invoice.status == 1) {
		        	status += '<div class="badge badge-danger">'+response.invoice.status_label+'</div>';
		        } else if(response.invoice.status == 3) {
		        	status += '<div class="badge badge-warning">'+response.invoice.status_label+'</div>';
		        } else if(response.invoice.status == 4) {
		        	status += '<div class="badge badge-success">'+response.invoice.status_label+'</div>';
		        }

		        html += '<input type="hidden" name="selected_invoice_id" id="selected_invoice_id" value="'+invoice_id+'"/>';

		    	html += '<div class="col-12 col-md-12 col-xl-12">';
		            html += '<div class="card-body m-sm-3">';
		                html += '<div class="mb-4">';
							html += '<h3 class="float-right mt-2">INVOICE</h3>';
							html += '<img src="img/logo/Rent_Preview_File_v2.png" height="35px" />';
		                html += '</div>';
		                html += '<hr class="my-4">';
		                html += '<div class="row mb-4">';
		                    html += '<div class="col-md-6">';
		                        html += '<span class="bold-black">'+response.invoice.bill_from_name+'</span>';
		                        html += '<p>';
		                            html += ''+response.invoice.bill_from_name+'<br>'+response.invoice.bill_from_address_line1+', '+response.invoice.bill_from_address_line2+' <br> '+response.invoice.bill_from_city+', '+response.invoice.bill_from_state+' '+response.invoice.bill_from_zipcode+' <br> '+response.invoice.bill_from_country+' <br>';
		                            html += '<a href="#">'+response.invoice.bill_from_email+'</a>';
		                        html += '</p>';
		                    html += '</div>';
		                    html += '<div class="col-md-6 text-md-left">';
		                        html += 'Customer Account: '+response.invoice.customer_account_number+'<br>';
		                        html += 'Invoice: #'+response.invoice.id+'<br>';
		                        html += 'Invoice Date: '+response.invoice.invoice_date1+'<br>';
		                        html += 'Invoice Amount: <span class="bold-black">$'+response.invoice.total+' (USD)</span><br>';
		                        html += '<h4 class="mt-2">'+status+'</h4>';
		                    html += '</div>';
		                html += '</div>';
		                html += '<div class="row mb-4">';
		                    html += '<div class="col-md-6">';
		                        html += '<span class="bold-black">BILLED TO</span>';
		                        html += '<p>';
		                            html += ''+response.invoice.bill_to_name+'<br>'+response.invoice.bill_to_address_line1+', '+response.invoice.bill_to_address_line2+' <br> '+response.invoice.bill_to_city+', '+response.invoice.bill_to_state+' '+response.invoice.bill_to_zipcode+' <br> '+response.invoice.bill_to_country+' <br>';
		                            html += '<a href="#">'+response.invoice.bill_to_email+'</a>';
		                        html += '</p>';
		                    html += '</div>';
		                    html += '<div class="col-md-6 text-md-left">';
		                        html += '<span class="bold-black">'+response.invoice.sp_type_label+'</span><br>';
		                        if(response.invoice.sp_typeID == 1) {
		                        	html += 'Plan Name: <span class="bold-black">Basic</span><br>';
		                        }
		                        html += 'Billing Period: '+response.invoice.bill_from_date+' to '+response.invoice.bill_to_date+'<br>';
		                        html += 'Next Billing Date: '+response.invoice.next_bill_from_date+'<br>';
		                    html += '</div>';
		                html += '</div>';
		                html += '<table class="table table-sm black fs-12">';
		                    html += '<thead>';
		                        html += '<tr class="bg-light">';
									// html += '<th>&nbsp</th>';
									html += '<th width="27%">Property</th>';
									html += '<th width="10%">ACH Ref</th>';
									html += '<th width="10%">Collected Outside</th>';
									html += '<th width="10%">Status</th>';
									html += '<th width="15%">Coupon</th>';
									html += '<th class="text-right" width="14%">Unit Price</th>';
									html += '<th class="text-right" width="14%">Amount(USD)</th>';
		                        html += '</tr>';
		                    html += '</thead>';
		                    html += '<tbody>';
								for(var key in response.invoiceLineItems){
									var coupon_code = '-';
									var coupon_discounted_amount = '0.00';
									if(response.invoiceLineItems[key].coupon_code){
										coupon_code = response.invoiceLineItems[key].coupon_code;
										coupon_discounted_amount = response.invoiceLineItems[key].coupon_discounted_amount;
									}

									html += '<tr>';
										// html += '<td>'+(Number(key)+1)+'</td>';
										html += '<td>'+response.invoiceLineItems[key].property.name+'</td>';
										html += '<td>'+response.invoiceLineItems[key].source_ach_id+'</td>';
										if(response.invoiceLineItems[key].mark_as_paid){
											html += '<td>Yes</td>';
										} else {
											html += '<td>No</td>';
										}
										// html += '<td>'+status+'</td>';
										if(response.invoiceLineItems[key].status == 1 || response.invoiceLineItems[key].status == 2) {
											html += '<td class="red">'+response.invoiceLineItems[key].status_label+'</td>';
										} else if(response.invoiceLineItems[key].status == 3) {
											html += '<td class="black">'+response.invoiceLineItems[key].status_label+'</td>';
										} else if(response.invoiceLineItems[key].status == 4) {
											html += '<td class="green">'+response.invoiceLineItems[key].status_label+'</td>';
										}
										html += '<td>';
											html  += 'Code: <strong>'+coupon_code+'</strong><br>';
											html  += 'Amount: <strong>$'+coupon_discounted_amount+'</strong>';
										html += '</td>';
										html += '<td class="text-right">$'+response.invoiceLineItems[key].invoice_amount+'</td>';
										html += '<td class="text-right">$'+response.invoiceLineItems[key].total+'</td>';
									html += '</tr>';
								}
								html += '<tr>';
									// html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th class="text-right">Total </th>';
									html += '<th class="text-right">$'+currencyFormat(Number(response.invoice.total_amount))+'</th>';
								html += '</tr>';
								html += '<tr>';
									// html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th class="text-right">Payments </th>';
									html += '<th class="text-right">($'+currencyFormat(Number(response.invoice.paid_amount))+')</th>';
								html += '</tr>';
								html += '<tr>';
									// html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th>&nbsp;</th>';
									html += '<th class="text-right">Amount Due(USD) </th>';
									html += '<th class="text-right">$'+currencyFormat(Number(response.invoice.due_amount))+'</th>';
								html += '</tr>';
		                    html += '</tbody>';
		                html += '</table>';
		                html += '<div class="text-center">';
		                    html += '<a href="javascript:void(0)" data-invoice-id='+response.invoice.id+' data-from="invoice_preview" class="btn btn-orange download_invoice hide_download_invoice">Download Invoice</a>';
		                html += '</div>';
		            html += '</div>';
			    html += '</div>';
	        }
            $('#invoice_content_container').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.sendInvoiceEmail = function(pdfAsString) {
	var tz = jstz.determine();
	var timezone = tz.name();
	var invoice_id = $('#selected_invoice_id').val();
	var data = 'user_id='+user.user_details.user_id+'&invoice_id='+invoice_id+'&user_timezone='+timezone+'&pdfAsString='+pdfAsString;
	$.ajax({
        method: 'POST',
        url: api_url+"invoices/send-email",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
        }
    });
}

/* End : Myaccount */

window.processPendingPayment = function(property_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/process-scheduled-payments",
        //url: api_url+"scheduled-payments/process-scheduled-payments-in-single-transfer",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == false) {
            	$('.process_pending_payment').attr('disabled',false);
            }
        }
    });
}

window.payToPropertyOwner = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/process-property-owner-payments",
        data: 'property_id='+property_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == false) {
            	$('.pay_to_property_owner').attr('disabled',false);
            }
        }
    });
}

/* System email data */

window.getSystemEmailDatas = function(type = '') {
	var tz = jstz.determine();
	var timezone = tz.name();
	var user_id = user.user_details.user_id;

	if(type == 'inbox') {
		var container = 'inbox_data_container';
		var db_table = 'inbox-data-datatable';
	} else if(type == 'sent-email') {
		var container = 'sent_email_data_container';
		var db_table = 'sent-email-data-datatable';
	}

	openLoadingDialog($('#'+container));

	if ($.fn.DataTable.isDataTable("#"+db_table)) {
		$("#"+db_table).DataTable().destroy();
	}

	$("#"+db_table).DataTable({
		responsive: true,
		order: [[0,'desc']],
		searching: true,
		serverSide: true,
		processing: true,
		pageLength: 10,
		ajax: {
			method: 'POST',
			url: api_url+"system-email-datas",
			data: function(d){
				d.user_timezone = timezone;
				d.user_id = user_id;
				d.user_service_name = user_service_name;
				d.email_type = type;
			},
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
		},
		createdRow: function(row, data, dataIndex){
			$( row ).find('td:eq(6)').addClass('text-center');
		}
	});
}

window.getSentEmailPreview = function(id) {
    $.ajax({
        method: 'POST',
        url: api_url + "system-email-datas/email-preview",
        data: 'id=' + id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            if (response.response_data.success == true) {
                $('#emailPreviewModal').modal('show');
                openLoadingDialog($('#email_preview_container'));
                var email_subject = response.system_email_data.email_subject;
                var email_content = atob(response.file_data);

                html += '<div class="row h-100">';
                html += '<div class="accordion" id="formAccordion">';
                html += '<div class="card border">';
                html += '<div class="card-header border-bottom">';
                html += '<div class="card-actions float-right">';
                html += '<i class="align-middle mr-4 mt-2 fas fa-fw fa-window-close" class="close" data-dismiss="modal" aria-label="Close"></i>';
                html += '</div>';
                html += '<h5 class="card-title my-2">Email Subject : ' + email_subject + '</h5>';
                html += '</div>';
                html += '<form id="email_preview_form" class="wizard-primary create-account-form" action="javascript:void(0)">';
                html += '<div id="email_preview_content">';
                html += '<style>table, td, div, h1, p{font-family: "Segoe UI"; color: dimgray;}p{line-height: 1.5;}h1{padding-bottom: 5% !important;}@media screen and (max-width: 530px){.unsub{display: block; padding: 8px; margin-top: 14px; border-radius: 6px; background-color: #555555; text-decoration: none !important; font-weight: bold;}.col-lge{max-width: 100% !important;}}@media screen and (min-width: 531px){.col-sml{max-width: 27% !important;}.col-lge{max-width: 73% !important;}}</style>';
                html += '<body style="margin:0;padding:0;word-spacing:normal;"> <div role="article" aria-roledescription="email" lang="en" style="padding: 10px; text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;background-color:#f6f9fc;"> <table role="presentation" style="width:100%;border:none;border-spacing:0;font-family: Segoe UI";> <tr> <td align="center" style="padding:0;"> <table role="presentation" style="width:94%;max-width:600px;border:none;border-spacing:0;text-align:left;font-family:Segoe UI;font-size:16px;line-height:22px;color:dimgray;"> <tr> <td style="padding:30px 25px 0px 25px;text-align:center;font-size:30px;font-weight:bold;background-color: #ffffff;"> <img src="https://static.rentsynergy.com/images/rslogo22087tbg.png" alt="Logo"> </td></tr><tr> <td style="padding:30px 60px 30px 60px;background-color:#ffffff;min-height: 100px;font-family: Segoe UI;"> ' + email_content + ' </td></tr><tr> <td style="padding:30px 60px 30px 60px;text-align:center;font-family: Segoe UI; font-size:12px;background-color:white;border-top: 1px solid #ebeef1;"> <p>You\'re receiving this email because you have a Rent Synergy account. Please note, you may not be able to opt out of these emails, as they\'re non-promotional in nature and contain important information regarding your account.<br>Please do not reply to this email. Instead, contact us at <a href="mailto:support@rentsynergy.com">support@rentsynergy.com</a></p><p>&#169;' + new Date().getFullYear() + ' L3 Software Services LLC DBA rentsynergy.com. All rights reserved.<br>14200 Midway Road #120, Dallas, TX 75244 USA</p></td></tr></table> </td></tr></table> </div></body>';
                html += '</div>';
                html += '</form>';
                html += '<div class="border-top">';
                html += '<div class="row">';
                html += '<div class="col-auto ml-auto text-right mt-2 mb-2 mr-2">';
                html += '<button type="button" data-id="' + id + '" class="btn btn-orange resend_email">Resend</button>';
                html += '<button type="button" class="btn btn-primary ml-2" data-dismiss="modal" aria-label="Close">Close</button>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '</div>';

                $('#email_preview_container').html(html);
				$('#email_preview_content tbody tr').eq(2).find('img').css('width', '100%');
            } else {
                display_error_message(response);
            }
        }
    });
}

window.resendEmail = function(id) {
	$.ajax({
        method: 'POST',
        url: api_url+"system-email-datas/resend-email",
        data: 'id='+id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	$('#emailPreviewModal').modal('hide');
        }
    });
}

var $emailTemplateForm;
window.emailTemplateValidation = function() {
	$emailTemplateForm = $("#email_template_form");
	$emailTemplateForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "email_name": { required: true },
	        "email_identifier": { required: true },
	        "email_subject": { required: true },
	    },
	    messages: {
	        "email_name": { required: '' },
	        "email_identifier": { required: '' },
	        "email_subject": { required: '' },
	    }
	});
}

window.getEmailTemplates = function() {
	$.ajax({
        method: 'GET',
        url: api_url+"email-templates",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.emailTemplates) {
	                if (response.emailTemplates.hasOwnProperty(key)) {
	                	var kebab = '';
	                	kebab += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_email_template" href="javascript:void(0)" data-id="'+response.emailTemplates[key].id+'">Edit</a></div></div></div>';

	                	html += '<tr>';
                            html += '<td>'+response.emailTemplates[key].id+'</td>';
                            html += '<td>'+response.emailTemplates[key].email_name+'</td>';
                            html += '<td>'+response.emailTemplates[key].email_identifier+'</td>';
                            html += '<td>'+response.emailTemplates[key].email_subject+'</td>';
                            html += '<td>'+response.emailTemplates[key].status_label+'</td>';
                            html += '<td class="text-center"><i class="align-middle mr-2 fas fa-fw fa-eye email_preview" data-id="'+response.emailTemplates[key].id+'" data-type="main" class="email_preview"></i></td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }

	            if ($.fn.DataTable.isDataTable("#email-template-data-datatable")) {
		            $("#email-template-data-datatable").DataTable().destroy();
		        }

	            $('#email_template_data_container').html(html);

				var email_template_table = $("#email-template-data-datatable").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '425px',
				    scrollX: true,
			        scrollCollapse: true,
			        order: [[0,'desc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
			           {
			              extend: 'colvis',
			              text: "Columns Visibility ",
			              collectionLayout: 'two-column',
			              postfixButtons: ['colvisRestore'],
			           }
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
					initComplete: function () {
			          	//console.log("The Table has been initialized!")
			        }
				});

				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
            }
        }
    });
}

window.getEmailIdentifier = function(identifier = null) {
	$.ajax({
        method: 'GET',
        url: api_url+"email-templates/identifiers",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		var html = '';
        		html += "<option value=''>Select Email Identifire</option>";
                $.each( response.emailIdentifiers, function( key, value ) {
                	var selected = '';
                	if(key == identifier) {
                		selected = 'selected';
                	}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#email_identifier').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getEmailTemplateDetail = function(email_template_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"email-templates/view/"+email_template_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#email_name').val(response.emailTemplate.email_name);
				$('#email_identifier').val(response.emailTemplate.email_identifier);
				$('#email_subject').val(response.emailTemplate.email_subject);
				$('#email_template_status_dd').val(response.emailTemplate.status);
				getEmailIdentifier(response.emailTemplate.email_identifier);
				setTimeout(function() {
					CKEDITOR.instances['email_body'].setData(response.emailTemplate.email_body);
				}, 1000);
            }
        }
    });
}

window.saveEmailTemplateData = function(type) {
	if($emailTemplateForm.valid()){
		$('.save_email_template_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var email_name = $('#email_name').val();
		var email_identifier = $('#email_identifier').val();
		var email_subject = $('#email_subject').val();
		var status = $('#email_template_status_dd').val();
		var email_body = CKEDITOR.instances['email_body'].getData();

		if(email_identifier == null) {
			$('.save_email_template_data').attr('disabled',false);
			var response = {
                "response_data": {
                    "success": false,
                    "message": 'Please select email identifier'
                }
            };
            display_error_message(response);
            return false;
		}

		if(type == 'add') {
			var url = api_url+"email-templates/add";
		} else if(type == 'edit') {
			var email_template_id = $('#selected_email_template_id').val();
			var url = api_url+"email-templates/edit/"+email_template_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+"&email_name="+email_name+'&email_identifier='+email_identifier+'&email_subject='+email_subject+'&status='+status+'&email_body='+encodeURIComponent(email_body),
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_email_template_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#addEmailTemplateModal').modal('hide');
                	$('#editEmailTemplateModal').modal('hide');
                	getEmailTemplates();
                }
            }
        });
    }
}

window.getMainEmailPreview = function(id) {
	$.ajax({
        method: 'POST',
        url: api_url+"email-templates/email-preview",
        data: 'id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	var html = '';
        	if(response.response_data.success == true) {
        		$('#emailPreviewModal').modal('show');
        		openLoadingDialog($('#email_preview_container'));
        		var email_subject = response.emailTemplate.email_subject;
        		var email_content = response.emailTemplate.email_body+'<div style="padding: 2% 10% 2% 0%; font-size: 16px; font-family: system-ui; color: dimgray;"><p>Thank you,<br>The Rent Synergy Team</p></div>';

				html += '<div class="row h-100">';
				        html += '<div class="card border">';
				            html += '<div class="card-header border-bottom">';
				                html += '<div class="card-actions float-right">';
				                    html += '<i class="align-middle mr-4 mt-2 fas fa-fw fa-window-close" class="close" data-dismiss="modal" aria-label="Close"></i>';
				                html += '</div>';
				                html += '<h5 class="card-title my-2">Email Subject : '+email_subject+'</h5>';
				            html += '</div>';
				            html += '<form id="email_preview_form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				                html += '<div id="email_preview_content">';
				                	html += '<style>table, td, div, h1, p{font-family: "Segoe UI"; color: dimgray;}p{line-height: 1.5;}h1{padding-bottom: 5% !important;}@media screen and (max-width: 530px){.unsub{display: block; padding: 8px; margin-top: 14px; border-radius: 6px; background-color: #555555; text-decoration: none !important; font-weight: bold;}.col-lge{max-width: 100% !important;}}@media screen and (min-width: 531px){.col-sml{max-width: 27% !important;}.col-lge{max-width: 73% !important;}}</style>';
				                	html += '<body style="margin:0;padding:0;word-spacing:normal;"> <div role="article" aria-roledescription="email" lang="en" style="padding: 10px; text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;background-color:#f6f9fc;"> <table role="presentation" style="width:100%;border:none;border-spacing:0;font-family: Segoe UI";> <tr> <td align="center" style="padding:0;"> <table role="presentation" style="width:94%;max-width:600px;border:none;border-spacing:0;text-align:left;font-family:Segoe UI;font-size:16px;line-height:22px;color:dimgray;"> <tr> <td style="padding:30px 25px 0px 25px;text-align:center;font-size:30px;font-weight:bold;background-color: #ffffff;"> <img src="https://static.rentsynergy.com/images/rslogo22087tbg.png" alt="Logo"> </td></tr><tr> <td style="padding:30px 60px 30px 60px;background-color:#ffffff;min-height: 100px;font-family: Segoe UI;"><h1 style="line-height:initial;text-align:center;margin-bottom:36px;">'+email_subject+'</h1> '+email_content+' </td></tr><tr> <td style="padding:30px 60px 30px 60px;text-align:center;font-family: Segoe UI; font-size:12px;background-color:white;border-top: 1px solid #ebeef1;"> <p>You\'re receiving this email because you have a Rent Synergy account. Please note, you may not be able to opt out of these emails, as they\'re non-promotional in nature and contain important information regarding your account.<br>Please do not reply to this email. Instead, contact us at <a href="mailto:support@rentsynergy.com">support@rentsynergy.com</a></p><p>&#169;'+new Date().getFullYear()+' L3 Software Services LLC DBA rentsynergy.com. All rights reserved.<br>14200 Midway Road #120, Dallas, TX 75244 USA</p></td></tr></table> </td></tr></table> </div></body>';
				                html += '</div>';
				                html += '<div class="border-top">';
				                    html += '<div class="row">';
				                        html += '<div class="col-auto ml-auto text-right mt-2 mb-2 mr-2">';
				                            html += '<button type="button" class="btn btn-primary ml-2" data-dismiss="modal" aria-label="Close">Close</button>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</form>';
				        html += '</div>';
				html += '</div>';

				$('#email_preview_container').html(html);
            } else {
				display_error_message(response);
            }
        }
    });
}

/* Dwolla webhook datas */

window.getDwollaWebhooks = function() {
	if(user.permissionData['DWOLLA-WEBHOOK']["feature_items['list']"]) {
		$.ajax({
	        method: 'POST',
	        url: api_url+"webhook-events",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		var html = '';
	            	for (var key in response.webhookEvents) {
		                if (response.webhookEvents.hasOwnProperty(key)) {
		                	html += '<tr>';
	                            html += '<td>'+response.webhookEvents[key].id+'</td>';
	                            html += '<td>'+response.webhookEvents[key].username+'</td>';
	                            html += '<td>'+response.webhookEvents[key].topic+'</td>';
	                            html += '<td>'+response.webhookEvents[key].timestamp+'</td>';
	                            html += '<td>'+response.webhookEvents[key].resourceID+'</td>';
	                            html += '<td>'+response.webhookEvents[key].transferID+'</td>';
	                            html += '<td>'+response.webhookEvents[key].webhookID+'</td>';
	                            html += '<td>'+response.webhookEvents[key].self+'</td>';
	                            html += '<td>'+response.webhookEvents[key].account+'</td>';
	                            html += '<td>'+response.webhookEvents[key].resource+'</td>';
	                            html += '<td>'+response.webhookEvents[key].customer+'</td>';
	                            html += '<td>'+response.webhookEvents[key].created+'</td>';
	                        html += '</tr>';
		                }
		            }
		            $('#dwolla_data_container').html(html);

		            $('#dwolla-webhook-datatable thead tr').clone(true).addClass('filters').appendTo('#dwolla-webhook-datatable thead');

		            var otable = $("#dwolla-webhook-datatable").DataTable({
					    //responsive: true,
					    order: [[0,'desc']],
					    scrollX: true,
					    orderCellsTop: true,
        				fixedHeader: true,
					    initComplete: function () {
					    	$('.webooh_footer').removeClass('d-none');
					        var api = this.api();

				            // For each column
				            api
			                .columns()
			                .eq(0)
			                .each(function (colIdx) {
			                    // Set the header cell to contain the input element
			                    var cell = $('.filters th').eq(
			                        $(api.column(colIdx).header()).index()
			                    );
			                    var title = $(cell).text();
			                    $(cell).html('<input type="text" class="form-control" placeholder="' + title + '" />');

			                    // On every keypress in this input
			                    $(
			                        'input',
			                        $('.filters th').eq($(api.column(colIdx).header()).index())
			                    )
			                        .off('keyup change')
			                        .on('change', function (e) {
			                            // Get the search value
			                            $(this).attr('title', $(this).val());
			                            var regexr = '({search})'; //$(this).parents('th').find('select').val();

			                            var cursorPosition = this.selectionStart;
			                            // Search the column for that value
			                            api
			                                .column(colIdx)
			                                .search(
			                                    this.value != ''
			                                        ? regexr.replace('{search}', '(((' + this.value + ')))')
			                                        : '',
			                                    this.value != '',
			                                    this.value == ''
			                                )
			                                .draw();
			                        })
			                        .on('keyup', function (e) {
			                            e.stopPropagation();

			                            $(this).trigger('change');
			                            $(this)
			                                .focus()[0]
			                                .setSelectionRange(cursorPosition, cursorPosition);
			                        });
			                });
					    }
					});
	            }
	        }
	    });
	}
}

window.getRelatedTransactionDetail = function(id,transferID) {
	var html = '';
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-related-transaction-detail",
        data: 'id='+id+'&transferID='+transferID,
        async: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		html += '<div class="fixTableHead">';
        		html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
        			html += '<thead>';
	        			html += '<tr>';
	        			html += '<th class="black fs-14">Status</th>';
	        			html += '<th class="black fs-14">Progress Detail</th>';
	        			html += '<th class="black fs-14">Source</th>';
	        			html += '<th class="black fs-14">Destination</th>';
	        			html += '<th class="black fs-14">Who Changed</th>';
	        			html += '<th class="black fs-14">Which Date</th>';
	        			html += '</tr>';
	        		html += '</thead>';
        			html += '<tbody>';
        			if(response.TotalapplicationStatus == 0) {
        				html += '<tr>';
		                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
	                    html += '</tr>';
        			} else {
		    			for (var key in response.transactionStatusArray) {
			                if (response.transactionStatusArray.hasOwnProperty(key)) {
			                	var d1 = response.transactionStatusArray[key].status_date;
			                	d1 = d1.replace('T',' ');
			                	d1 = d1.replace('+00:00','');
		                		html += '<tr>';
				                    html += '<td><strong>'+response.transactionStatusArray[key].status+'</strong></td>';
				                    html += '<td>&nbsp;</td>';
				                    html += '<td>&nbsp;</td>';
				                    html += '<td>&nbsp;</td>';
				                    html += '<td><strong>'+response.transactionStatusArray[key].who_changed+'</strong></td>';
				                    html += '<td><strong>'+d1+'</strong></td>';
			                    html += '</tr>';

			                    if(response.transactionStatusArray[key].webhook_data) {
									for (var wkey in response.transactionStatusArray[key].webhook_data) {
										if (response.transactionStatusArray[key].webhook_data.hasOwnProperty(wkey)) {
											var d2 = response.transactionStatusArray[key].webhook_data[wkey].timestamp;
						                	d2 = d2.replace('T',' ');
						                	d2 = d2.replace('+00:00','');
						                	var topic = response.transactionStatusArray[key].webhook_data[wkey].topic;
						                	topic = topic.replaceAll("_", " ");
						                	topic = eachWordFirstLetterCapital(topic);
						                	var customer = response.transactionStatusArray[key].webhook_data[wkey].user;
											html += '<tr>';
												html += '<td>&nbsp;</td>';
							                    html += '<td>'+topic+'</td>';
							                    html += '<td>'+customer+'</td>';
							                    html += '<td>&nbsp;</td>';
							                    html += '<td>&nbsp;</td>';
							                    html += '<td><strong>'+d2+'</strong></td>';
						                    html += '</tr>';
										}
									}
								}
			                }
			            }
			        }
		        html += '</tbody>';
		        html += '</table>';
		        if(response.failureData != '') {
		        	html += '<h3>Transaction failed reason</h3>';
		           	html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
		    			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Code</th>';
		        			html += '<th class="black fs-14">Description</th>';
		        			html += '<th class="black fs-14">Explanation</th>';
		        			html += '</tr>';
		        		html += '</thead>';
		    			html += '<tbody>';
		    				html += '<tr>';
			        			html += '<td>'+response.failureData.failure_code+'</td>';
			        			html += '<td>'+response.failureData.failure_description+'</td>';
			        			html += '<td>'+response.failureData.failure_explanation+'</td>';
		        			html += '</tr>';
		    			html += '</tbody>';
		    		html += '</table>';
			    }
		        html += '</div>';
            } else {
            	display_error_message(response);
            }
        }
    });

	return html;
}

/* Dwolla transfer to bank and add to balance */

var $transferToBankForm;
window.transferToBankValidation = function() {
	$transferToBankForm = $("#transfer_to_bank_form");
	$transferToBankForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "amount_to_withdraw": { required: true, notEqual: '0.00' },
	        "transfer_to": { required: true },
			"transfer_to_description": { required: true },
	    },
	    messages: {
	        "amount_to_withdraw": { required: '', notEqual:"" },
	        "transfer_to": { required: '' },
			"transfer_to_description": { required: '' },
	    }
	});
}

var $addToBalanceForm;
window.addToBalanceValidation = function() {
	$addToBalanceForm = $("#add_to_balance_form");
	$addToBalanceForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "amount_to_transfer": { required: true, notEqual: '0.00' },
	        "transfer_from": { required: true },
			"transfer_from_description": { required: true },
	    },
	    messages: {
	        "amount_to_transfer": { required: '', notEqual:"" },
	        "transfer_from": { required: '' },
			"transfer_from_description": { required: '' },
	    }
	});
}

window.getBalanceAmount = function(uid, dwolla_uid) {
	openLoadingDialog($('#dwolla_balance'));
	openLoadingDialog($('#dwolla_balance_id'));
	$.ajax({
        method: 'POST',
        url: api_url+"dwollas/get-balance",
		data: 'user_id='+uid+'&dwolla_uid='+dwolla_uid,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#dwolla_balance').html('<h1 class="fs-36">$'+response.fs_balance+'</h1>');
        		$('#dwolla_balance_id').html('<small class="fs-12">ID:</small> <small class="fs-12 balance_0">'+response.dwollaFSBalanceID+'</small> <img src="icon/copy.svg" width="24px" height="24px" data-type="balance" data-id="0" class="copy_button" alt="Click to copy" title="Click to copy" />');
            }
        }
    });
}

window.getFundingSource = function(uid, dwolla_uid, open_from = '') {
	if(open_from == 'main') {
		$('#funding-source-container').addClass('dwolla_bank_container');
		openLoadingDialog($('#funding-source-container'));
	}
	$.ajax({
        method: 'POST',
        url: api_url+"dwollas/get-banks",
		data: 'user_id='+uid+'&dwolla_uid='+dwolla_uid,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = "";
				var to_html = "";
				var from_html = "";
        		html += '<option value="">Select Transfer To</option>';
            	for (var key in response.fundingSource) {
	                if (response.fundingSource.hasOwnProperty(key)) {
	                	var name = response.fundingSource[key].name;
						var type = response.fundingSource[key].type;
						var bank_status = response.fundingSource[key].status;
						var removed = response.fundingSource[key].removed;
						var user_email = response.fundingSource[key].user_email;
	                	// for dropdown
	                	if(type == 'bank' && bank_status == 'verified' && removed == 0) {
                			html += '<option data-name="'+user_email+'" data-type="'+type+'" value="'+response.fundingSource[key].id+'">'+name+'</option>';
                		}
					}
				}

				to_html += html;
				if(uid > 0) {
					getRsFundingSource(to_html); // Other than admin , add Rent synergy balance and bank for selection in fund transfer
				} else {
					$('#transfer_to').html(to_html);
					$('#transfer_to').attr('disabled',false);
				}

				from_html = html;
				$('#transfer_from').html(from_html);
				$('#transfer_from').attr('disabled',false);

				if(open_from == 'main') {
					var card_html = "";
					for (var key in response.fundingSource) {
						if (response.fundingSource.hasOwnProperty(key)) {
							var id = response.fundingSource[key].id;
							var name = response.fundingSource[key].name;
							var type = response.fundingSource[key].type;
							var status = response.fundingSource[key].status;
							var removed = response.fundingSource[key].removed;
							var bankAccountType = response.fundingSource[key].bankAccountType;
							var created = response.fundingSource[key].created;
							var time = response.fundingSource[key].time;
							var status_class = 'badge-danger';
							if(status == 'verified') {
								status_class = 'badge-success';
							}

							if(removed == 1) {
								status = 'Removed'
								status_class = 'badge-danger';
							}
							// for display bank card
							if(type == 'bank') {
								card_html += '<div class="col-xl-6">';
									card_html += '<div class="card">';
										card_html += '<div class="card-body">';
											card_html += '<div class="row no-gutters">';
												card_html += '<div class="col-sm-2 col-xl-2 col-xxl-2 text-center">';
													card_html += '<img src="img/sample/bank.jpg" width="64" height="64" alt="'+name+'">';
												card_html += '</div>';
												card_html += '<div class="col-sm-10 col-xl-10 col-xxl-10 mt-2 pl-3">';
													card_html += '<h3 class="mt-2">'+name+'</h3>';
													card_html += '<span class="absolute badge '+status_class+'">'+humanize(status)+'</span>';
												card_html += '</div>';
												card_html += '<div class="col-sm-9 col-xl-9 col-xxl-9 pt-3 pl-3">';
													if(type == 'bank') {
														card_html += '<small class="fs-12">'+type+' | '+bankAccountType+' | Added: '+created+' at '+time+'</small><br>';
													} else {
														card_html += '<small class="fs-12">'+type+' | Added: '+created+' at '+time+'</small><br>';
													}
													card_html += '<small class="fs-12">ID:</small> <small class="fs-12 bank_'+key+'">'+id+'</small> <img src="icon/copy.svg" width="24px" height="24px" data-type="bank" data-id="'+key+'" class="copy_button" alt="Click to copy" title="Click to copy" />';
												card_html += '</div>';
												if(response.fundingSource[key].check_balance == 1){
													card_html += '<div class="col-sm-3 col-xl-3 col-xxl-3 pt-4 pr-1 text-right">';
														card_html += '<a href="javascript:void(0)" class="get_bank_balance mt-2" data-id="'+id+'" data-fs-id="'+id+'">Check Balance</a>';
														card_html += '<span class="d-none bold-black fs-18 get_bank_balance_container_'+id+' mt-2"></a>';
													card_html += '</div>';	
												}
											card_html += '</div>';
										card_html += '</div>';
									card_html += '</div>';
								card_html += '</div>';
							}
						}
					}
					$('#funding-source-container').html(card_html);
					$('#funding-source-container').removeClass('dwolla_bank_container');
				}
            }
        }
    });
}

window.getRsFundingSource = function(to_html) {
	$.ajax({
        method: 'POST',
        url: api_url+"dwollas/get-rs-banks",
		data: 'user_id='+uid+'&dwolla_uid='+dwolla_uid,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		for (var key in response.fundingSource) {
	                if (response.fundingSource.hasOwnProperty(key)) {
	                	var name = response.fundingSource[key].name;
						var type = response.fundingSource[key].type;
						var bank_status = response.fundingSource[key].status;
						var removed = response.fundingSource[key].removed;
						var user_email = response.fundingSource[key].user_email;
	                	// for dropdown
	                	if(bank_status == 'verified' && removed == 0) {
                			to_html += '<option data-name="'+user_email+'" data-type="'+type+'" value="'+response.fundingSource[key].id+'">Rent Synergy - '+name+'</option>';
                		}
					}
				}
				$('#transfer_to').html(to_html);
				$('#transfer_to').attr('disabled',false);
            }
        }
    });
}

window.transferToBank = function(uid, dwolla_uid) {
	if($transferToBankForm.valid()) {
		var transfer_to_name = $('#transfer_to :selected').html();
		var transfer_to_username = $('#transfer_to :selected').attr('data-name');
		var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"dwollas/transfer-to-bank",
	        data: $('#transfer_to_bank_form').serialize()+'&transfer_to_username='+transfer_to_username+'&transfer_to_name='+transfer_to_name+'&user_id='+uid+'&dwolla_uid='+dwolla_uid+'&logged_user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		getBalanceAmount(uid, dwolla_uid);
	        		$('#transferFundModal').modal('hide');
	            } else {
	            	$('.dwolla_transfer_btn').attr('disabled', false);
	            }
	        }
	    });
	} else {
		$('.dwolla_transfer_btn').attr('disabled', false);
	}
}

window.addToBalance = function(uid, dwolla_uid) {
	if($addToBalanceForm.valid()) {
		var transfer_from_name = $('#transfer_from :selected').html();
		var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"dwollas/add-to-balance",
	        data: $('#add_to_balance_form').serialize()+'&transfer_from_name='+transfer_from_name+'&user_id='+uid+'&dwolla_uid='+dwolla_uid+'&logged_user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		getBalanceAmount(uid, dwolla_uid);
	        		$('#transferFundModal').modal('hide');
	            } else {
	            	$('.dwolla_transfer_btn').attr('disabled', false);
	            }
	        }
	    });
	} else {
		$('.dwolla_transfer_btn').attr('disabled', false);
	}
}

window.getBankBalance = function(fs_id) {
    var user_id = user.user_details.user_id;
    $.post({
        url: api_url+'dwollas/get-bank-balance',
        data:"user_id="+user_id+"&fs_id="+fs_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
				$('.get_bank_balance[data-fs-id='+fs_id+']').addClass('d-none');
				$('.get_bank_balance_container_'+fs_id).removeClass('d-none');
                $('.get_bank_balance_container_'+fs_id).html(response.available_amount);
            } else {
				$('.get_bank_balance[data-fs-id='+fs_id+']').html('Check Balance');
			}
        }
    });
}

/* UI for Manage plans & Subscriptions */

var $couponForm;
window.addCouponValidation = function() {
	$couponForm = $("#coupon_add_form, #coupon_edit_form");
	$couponForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "coupon_code": { required: true },
	        "coupon_plan": { required: true },
	        "offer_name": { required: true },
	        "discount_percentage": { required: true },
	        "offer_start_date": { required: true },
	        "offer_end_date": { required: true }
	    },
	    messages: {
	        "coupon_code": { required: '' },
	        "coupon_plan": { required: '' },
	        "offer_name": { required: '' },
	        "discount_percentage": { required: '' },
	        "offer_start_date": { required: '' },
	        "offer_end_date": { required: '' }
	    }
	});
}

window.loadCouponType = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"coupons/get-types",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var html = '';
                //var html = "<option value=''>Select Coupon Type</option>";
                $.each( response.types, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.loadPlanType = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"plans",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var html = '';
				for(var key in response.plans){
					html += '<option value="'+response.plans[key].id+'">'+response.plans[key].name+'</option>';
				}
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getCouponDetailById = function(coupon_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"coupons/view/"+coupon_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
				$('#coupon_code').val(response.coupon.code);
				$('#coupon_plan').val(response.coupon.plan_id).trigger('change');
				$('#offer_name').val(response.coupon.offer_name);
				$('#coupon_description').val(response.coupon.description);
				$('#offer_start_date > input').val(response.coupon.offer_start_date2);
				$('#offer_end_date > input').val(response.coupon.offer_end_date2);
				$('#duration_month').val(response.coupon.duration_months);

				// if(response.coupon.discount_type == 'P'){
				// 	$('#discount_percentage').val(response.coupon.discount_percentage);
				// } else{
				// 	$('#discount_percentage').val(response.coupon.discount_amount);
				// }

				var status = response.coupon.status;
				var html = '';
				$.each( response.status, function( key, value ) {
					var selected = '';
					if(key == status) {
						selected = 'selected';
					}
						html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
				$('#coupon_status').html(html);

				// var coupon_type = response.coupon.type;
				// var html = '';
				// $.each( response.types, function( key, value ) {
				// 	var selected = '';
				// 	if(key == coupon_type) {
				// 		selected = 'selected';
				// 	}
				// 		html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				// });
				// $('#coupon_type').html(html);

				// var discount_types = {
				// 	'P': 'Percentage',
				// 	'F': 'Fix Amount'
				// }
				// var discount_type = response.coupon.discount_type;
				// var html = '';
				// $.each( discount_types, function( key, value ) {
				// 	var selected = '';
				// 	if(key == discount_type) {
				// 		selected = 'selected';
				// 	}
				// 		html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				// });
				// $('#discount_type').html(html);

				if(response.coupon.coupon_used == 1) {
					// $('#coupon_code, #coupon_type, #offer_name, #offer_start_date > input, #offer_end_date > input, #discount_type, #discount_percentage, #duration_month').attr('disabled', true);
					$('#coupon_code, #coupon_plan, #offer_name, #offer_start_date > input, #offer_end_date > input').attr('disabled', true);
				}
            }
        }
    });
}

window.loadCouponData = function() {
	if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['coupon_add']"]) {
		$('.add_new_coupon').remove();
	}
	//getFloatingActionButton('Coupon', '#coupon_data_section');

	if ($.fn.DataTable.isDataTable("#coupon-datatable")) {
		$("#coupon-datatable").DataTable().destroy();
	}

	var tz = jstz.determine();
	var timezone = tz.name();
	var user_id = user.user_details.user_id;

	$("#coupon-datatable").DataTable({
		responsive: true,
		order: [[1,'desc']],
		searching: true,
		serverSide: true,
		processing: true,
		pageLength: 10,
		ajax: {
			method: 'POST',
			url: api_url+"coupons",
			data: function(d){
				d.user_timezone = timezone;
				d.user_id = user_id;
			},
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
		},
	});
}

window.saveCouponData = function(type) {
	if($couponForm.valid()){
		$('.save_coupon_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var coupon_code = $('#coupon_code').val();
		var plan_id = $('#coupon_plan').val();
		var offer_name = $('#offer_name').val();
		var description = $('#coupon_description').val();
		var offer_start_date = $('#offer_start_date > input').val();
		var offer_end_date = $('#offer_end_date > input').val();

		// var discount_type = $('#discount_type').val();
		// if(discount_type == 'P'){
		// 	var discount_amount = '0.00';
		// 	var discount_percentage = $('#discount_percentage').val();
		// } else{
		// 	var discount_amount = $('#discount_percentage').val();
		// 	var discount_percentage = '0';
		// }

		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"coupons/add";
		} else if(type == 'edit') {
			var coupon_id = $('#selected_coupon_id').val();
			var status = $('#coupon_status').val();
			var url = api_url+"coupons/edit/"+coupon_id;
		}

		// var data = 'user_id='+user_id+'&code='+coupon_code+'&type='+coupon_type+'&offer_name='+offer_name+'&offer_start_date='+offer_start_date+"&offer_end_date="+offer_end_date+"&description="+description+"&discount_type="+discount_type+"&discount_amount="+discount_amount+"&discount_percentage="+discount_percentage+'&duration_months='+duration_months+'&duration_end_date='+offer_end_date+"&status="+status;
		var data = 'user_id='+user_id+'&plan_id='+plan_id+'&code='+coupon_code+'&offer_name='+offer_name+'&offer_start_date='+offer_start_date+"&offer_end_date="+offer_end_date+"&description="+description+'&duration_end_date='+offer_end_date+"&status="+status;

		$.ajax({
            method: 'POST',
            url: url,
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_coupon_data').attr('disabled',false);
                if(response.response_data.success == true) {
					$('#coupon_form_container_edit').html('');
					$('#coupon_form_container_add').html('');
                	$('#couponAddModal').modal('hide');
                	$('#couponEditModal').modal('hide');
                	loadCouponData();
                }
            }
        });
    }
}

/* Plan Data */

var $planForm;
window.addPlanValidation = function() {
	$planForm = $("#plan_add_form, #plan_edit_form");
	$planForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "plan_name": { required: true },
	    },
	    messages: {
	        "plan_name": { required: '' },
	    }
	});
}

window.getPlanDetailById = function(plan_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"plans/view",
        data: 'user_timezone='+timezone+'&plan_id='+plan_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#plan_name').val(response.plan.name);
        		$('#plan_description').val(response.plan.description);

				if(response.plan.is_default == 1){
					$('#plan_is_default').attr('checked', true);
				} else {
					$('#plan_is_default').attr('checked', false);
				}

				if(response.plan.enable_payment_routing == 1){
					$('#plan_routing_enabled').attr('checked', true);
				} else {
					$('#plan_routing_enabled').attr('checked', false);
				}

        		var status = response.plan.status;
        		var html = '';
        		$.each( response.status, function( key, value ) {
        			var selected = '';
        			if(key == status) {
        				selected = 'selected';
        			}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#plan_status').html(html);
            }
        }
    });
}

window.loadPlanData = function() {
	if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['plan_add']"]) {
		$('.add_new_plan').remove();
	}
	$.ajax({
        method: 'GET',
        url: api_url+"plans",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.plans) {
	                if (response.plans.hasOwnProperty(key)) {

	                	var radio = '<label class="custom-control custom-radio"><input name="plan-radio" type="radio" class="custom-control-input get_pricing_data" data-id="'+response.plans[key].id+'"><span class="custom-control-label">&nbsp;</span></label>';

	                	var kebab = '';
	                	if(user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['plan_edit']"]) {
	                		kebab += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_plan" href="javascript:void(0)" data-id="'+response.plans[key].id+'">Edit</a></div></div></div>';
	                	}

	                	html += '<tr>';
                            html += '<td>'+radio+'</td>';
                            html += '<td>'+response.plans[key].id+'</td>';
                            html += '<td>'+response.plans[key].name+'</td>';
							html += '<td>'+response.plans[key].description+'</td>';
							if(response.plans[key].enable_payment_routing == 1){
								html += '<td>Yes</td>';
							} else {
								html += '<td>No</td>';
							}
							if(response.plans[key].is_default == 1){
								html += '<td>Yes</td>';
							} else {
								html += '<td>No</td>';
							}
                            html += '<td>'+response.plans[key].status_label+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#plan_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#plan-datatable")) {
					$("#plan-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.savePlanData = function(type) {
	if($planForm.valid()){
		$('.save_plan_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var name = $('#plan_name').val();
		var description = $('#plan_description').val();

		var is_default = 0;
		if($('#plan_is_default').is(":checked")){
			is_default = 1;
		}

		var enable_payment_routing = 0;
		if($('#plan_routing_enabled').is(":checked")){
			enable_payment_routing = 1;
		}

		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"plans/add";
		} else if(type == 'edit') {
			var plan_id = $('#selected_plan_id').val();
			var status = $('#plan_status').val();
			var url = api_url+"plans/edit/"+plan_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&name='+name+'&description='+description+"&status="+status+'&is_default='+is_default+'&enable_payment_routing='+enable_payment_routing,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_plan_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#planAddModal').modal('hide');
                	$('#planEditModal').modal('hide');
                	$('#plan_pricing_data_section').addClass('d-none');
                	loadPlanData();
                }
            }
        });
    }
}

/* Coupon Details Data */

var $couponDetailsForm;
window.addCouponDetailsValidation = function() {
	$couponDetailsForm = $("#coupon_details_add_form, #coupon_details_edit_form");
	$couponDetailsForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "coupon_id": { required: true },
	        "coupon_fee_type": { required: true },
	        "coupon_discount_type": { required: true },
			"coupon_discount_amount": { required: true }
	    },
	    messages: {
	        "coupon_id": { required: '' },
	        "coupon_fee_type": { required: '' },
	        "coupon_discount_type": { required: '' },
			"coupon_discount_amount": { required: '' }
	    }
	});
}

/* Plan Pricing Data */

var $planPricingForm;
window.addPlanPricingValidation = function() {
	$planPricingForm = $("#plan_pricing_add_form, #plan_pricing_edit_form");
	$planPricingForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "property_setup_fee": { required: true },
	        "price_per_month": { required: true },
			"property_management_fee_type": { required: true },
			"property_management_fee": { required: true },
	        "valid_from_date": { required: true },
	        "valid_to_date": { required: true }
	    },
	    messages: {
	        "property_setup_fee": { required: '' },
	        "price_per_month": { required: '' },
			"property_management_fee_type": { required: '' },
			"property_management_fee": { required: '' },
	        "valid_from_date": { required: '' },
	        "valid_to_date": { required: '' }
	    }
	});
}

window.getPlanPricingDetailById = function(plan_pricing_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"plan-pricings/view/"+plan_pricing_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#plan_name_div').html(response.plan_pricing.plan_name);
				$('#property_setup_fee').val(response.plan_pricing.property_setup_fee);
        		$('#price_per_month').val(response.plan_pricing.price_per_month);
				$('#property_management_fee').val(response.plan_pricing.property_management_fee);
        		$('#valid_from_date > input').val(response.plan_pricing.valid_from_date2);
        		$('#valid_to_date > input').val(response.plan_pricing.valid_to_date2);

        		var status = response.plan_pricing.status;
        		var html = '';
        		$.each( response.status, function( key, value ) {
        			var selected = '';
        			if(key == status) {
        				selected = 'selected';
        			}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#plan_pricing_status').html(html);

				var property_management_fee_types = {
					'P': 'Percentage',
					'F': 'Fix Amount'
				}
				var property_management_fee_type = response.plan_pricing.property_management_fee_type;
				var html = '';
				$.each( property_management_fee_types, function( key, value ) {
					var selected = '';
					if(key == property_management_fee_type) {
						selected = 'selected';
					}
						html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
				$('#property_management_fee_type').html(html);
            }
        }
    });
}

window.loadCouponDetailsData = function(coupon_id){
	if ($.fn.DataTable.isDataTable("#coupon-details-datatable")) {
		$("#coupon-details-datatable").DataTable().destroy();
	}
	$('#coupon_details_data_section').removeClass('d-none');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"coupons/list-coupon-details",
        data: "coupon_id="+coupon_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
			var html = '';
			if(response.allow_add == 0){
				$('.add_new_coupon_detail').attr('disabled', true);
			} else {
				$('.add_new_coupon_detail').attr('disabled', false);
			}
        	if(response.response_data.success == true) {
    	    	for (var key in response.data) {
					var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_coupon_discount_details" href="javascript:void(0)" data-id="'+response.data[key].id+'">Edit</a></div></div></div>';

					html += '<tr>';
						html += '<td>'+response.data[key].id+'</td>';
						html += '<td>'+response.data[key].fee_type+'</td>';
						html += '<td>'+response.data[key].discount_type+'</td>';
						html += '<td class="text-right">'+response.data[key].discount_amount+'</td>';
						html += '<td>'+response.data[key].status+'</td>';
						html += '<td>'+kebab+'</td>';
					html += '</tr>';
                }
            }
			$('#coupon_details_data_container').html(html);
			$("#coupon-details-datatable").DataTable({
				responsive: true
			});
        }
    });
}

window.getCouponDetailsData = function(type){
	var coupon_id = $('input[name="coupon-radio"]:checked').attr('data-id');
	var tz = jstz.determine();
	var timezone = tz.name();
	var selected_coupon_details_id = $('#selected_coupon_details_id').val();

	$.ajax({
        method: 'POST',
        url: api_url+"coupons/get-coupon-discount-details",
        data: "coupon_id="+coupon_id+"&user_timezone="+timezone+'&type='+type+'&selected_coupon_details_id='+selected_coupon_details_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
			$('#coupon_code_of_fee_type').val(response.couponCode);
			if(response.response_data.success == true) {
				var fee_type_html = '';
				for(var key in response.types){
					if(response.data && response.data.fee_type == key){
						fee_type_html += '<option value="'+key+'" selected>'+response.types[key]+'</option>';
					} else {
						fee_type_html += '<option value="'+key+'">'+response.types[key]+'</option>';
					}
				}
				$('#coupon_fee_type').html(fee_type_html);

				var status_html = '';
				for(var key in response.status_label){
					if(response.data && response.data.status == key){
						status_html += '<option value="'+key+'" selected>'+response.status_label[key]+'</option>';
					} else {
						status_html += '<option value="'+key+'">'+response.status_label[key]+'</option>';
					}
				}
				$('#coupon_details_status').html(status_html);

				if(Object.keys(response.data).length > 0){
					$('#coupon_discount_amount').val(response.data.discount_amount);
					$('#coupon_discount_type').val(response.data.discount_type).trigger('change');
				}
			}
		}
	});
}

window.loadPlanPricingData = function(plan_id) {
	$('#plan_pricing_data_section').removeClass('d-none');
	if ($.fn.DataTable.isDataTable("#plan-pricing-datatable")) {
		$("#plan-pricing-datatable").DataTable().destroy();
	}
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"plans/view",
        data: "plan_id="+plan_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
    	    	for (var key in response.plan.plan_pricings) {
            		if (response.plan.plan_pricings.hasOwnProperty(key)) {
	                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_plan_pricing" href="javascript:void(0)" data-id="'+response.plan.plan_pricings[key].id+'">Edit</a></div></div></div>';

	                	html += '<tr>';
                            html += '<td>'+response.plan.plan_pricings[key].id+'</td>';
                            html += '<td>'+response.plan.name+'</td>';
                            html += '<td class="text-right">$'+response.plan.plan_pricings[key].property_setup_fee+'</td>';
                           	html += '<td class="text-right">$'+response.plan.plan_pricings[key].price_per_month+'</td>';
                            html += '<td class="text-right">'+response.plan.plan_pricings[key].property_management_fee+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].valid_from_date+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].valid_to_date+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].status_label+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
                    }
                }
	            $('#plan_pricing_data_container').html(html);

				$("#plan-pricing-datatable").DataTable({
					responsive: true
				});
            }
        }
    });
}

window.saveCouponDetailsData = function(type){
	if($couponDetailsForm.valid()){
		$('.save_coupon_details_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var coupon_id = $('input[name="coupon-radio"]:checked').attr('data-id');
		var fee_type = $('#coupon_fee_type').val();
		var discount_type = $('#coupon_discount_type').val();
		var discount_amount = $('#coupon_discount_amount').val().replace(/,/g, '');
		var selected_coupon_details_id = $('#selected_coupon_details_id').val();

		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"coupons/add-coupon-discount-details";
		} else if(type == 'edit') {
			var status = $('#coupon_details_status').val();
			var url = api_url+"coupons/edit-coupon-discount-details";
		}

		var data = 'user_id='+user_id+'&coupon_id='+coupon_id+'&fee_type='+fee_type+"&discount_type="+discount_type+'&discount_amount='+discount_amount+'&selected_coupon_details_id='+selected_coupon_details_id+'&status='+status;

		$.ajax({
            method: 'POST',
            url: url,
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_coupon_details_data').attr('disabled',false);
                if(response.response_data.success == true) {
					$('#coupon_details_container_add').html('');
					$('#coupon_details_container_edit').html('');
                	$('#couponDetailsAddModal').modal('hide');
                	$('#couponDetailsEditModal').modal('hide');
                	loadCouponDetailsData(coupon_id);
                }
            }
        });
    }
}

window.savePlanPricingData = function(type) {
	if($planPricingForm.valid()){
		$('.save_plan_pricing_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var plan_id = $('input[name="plan-radio"]:checked').attr('data-id');
		var property_setup_fee = $('#property_setup_fee').val().replace(/,/g, '');
		var price_per_month = $('#price_per_month').val().replace(/,/g, '');
		var property_management_fee_type = $('#property_management_fee_type').val();
		var property_management_fee = $('#property_management_fee').val().replace(/,/g, '');
		var valid_from_date = $('#valid_from_date > input').val();
		var valid_to_date = $('#valid_to_date > input').val();

		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"plan-pricings/add";
		} else if(type == 'edit') {
			var plan_pricing_id = $('#selected_plan_pricing_id').val();
			var status = $('#plan_pricing_status').val();
			var url = api_url+"plan-pricings/edit/"+plan_pricing_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&plan_id='+plan_id+'&property_setup_fee='+property_setup_fee+"&price_per_month="+price_per_month+'&property_management_fee_type='+property_management_fee_type+'&property_management_fee='+property_management_fee+'&valid_from_date='+valid_from_date+'&valid_to_date='+valid_to_date+'&status='+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_plan_pricing_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#planPricingAddModal').modal('hide');
                	$('#planPricingEditModal').modal('hide');
                	loadPlanPricingData(plan_id);
                }
            }
        });
    }
}

/* Subscriptions Data */

window.loadSubscriptionData = function() {
	if ($.fn.DataTable.isDataTable("#subscription-plan-datatable")) {
		$("#subscription-plan-datatable").DataTable().destroy();
	}
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"subscription-plans",
        data:'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.subscriptionPlans) {
	                if (response.subscriptionPlans.hasOwnProperty(key)) {

	                	var kebab = '';

	                	var username = '-';
	                	if(response.subscriptionPlans[key].user != null) {
	                		username = response.subscriptionPlans[key].user.first_name+' '+response.subscriptionPlans[key].user.last_name;
	                	}

	                	var property = '-';
	                	if(response.subscriptionPlans[key].property != null) {
	                		property = response.subscriptionPlans[key].property.name;
	                	}

	                	var plan = '-';
	                	if(response.subscriptionPlans[key].plan != null) {
	                		plan = response.subscriptionPlans[key].plan.name;
	                	}

	                	var coupon = '-';
	                	if(response.subscriptionPlans[key].coupon != null) {
	                		coupon = response.subscriptionPlans[key].coupon_type_label+'<br>'+response.subscriptionPlans[key].coupon.code;
	                	}

	                	var date_cancelled = '-';
	                	if(response.subscriptionPlans[key].date_cancelled != null) {
	                		date_cancelled = response.subscriptionPlans[key].date_cancelled;
	                	}

	                	html += '<tr>';
                            html += '<td>'+response.subscriptionPlans[key].id+'</td>';
                            html += '<td>'+username+'</td>';
                            html += '<td>'+property+'</td>';
                            html += '<td>'+plan+'</td>';
                            html += '<td>'+coupon+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].start_date+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].end_date+'</td>';
                            // html += '<td>'+response.subscriptionPlans[key].subscription_duration_label+'</td>';
                            // html += '<td>'+response.subscriptionPlans[key].date_subscribed+'</td>';
                            // html += '<td>'+date_cancelled+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].status_label+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#subscription_plan_data_container').html(html);

				$("#subscription-plan-datatable").DataTable({
					responsive: true
				});
            }
        }
    });
}

/* Load Group Data */

var groupForm;
window.addGroupValidation = function() {
	groupForm = $("#group_add_form, #group_edit_form");
	groupForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "group_name": { required: true },
	        "group_owner": { required: true }
	    },
	    messages: {
	        "group_name": { required: '' },
	        "group_owner": { required: '' }
	    }
	});
}

window.getGroupDetailById = function(group_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"rsgroups/view/"+group_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#group_name').val(response.group.name);
        		$('#group_description').val(response.group.description);
        		setTimeout(function () {
		            $('#group_owner').val(response.group.user_id);
		            $(".select2").each(function() {
					    $(this).select2();
					});
		        }, 1000);
            }
        }
    });
}

window.loadGroupData = function() {
	//getFloatingActionButton('Coupon', '#coupon_data_section');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"rsgroups",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.groups) {
	                if (response.groups.hasOwnProperty(key)) {

	                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_group" href="javascript:void(0)" data-id="'+response.groups[key].id+'">Edit</a><a data-id="'+response.groups[key].id+'" data-module="Group" class="dropdown-item delete_group" href="javascript:void(0)">Delete</a></div></div></div>';

	                	var owner = response.groups[key].user.first_name+' '+response.groups[key].user.last_name;

	                	html += '<tr>';
                            html += '<td>'+response.groups[key].id+'</td>';
                            html += '<td>'+response.groups[key].name+'</td>';
                            html += '<td>'+response.groups[key].description+'</td>';
                            html += '<td>'+owner+'</td>';
                            html += '<td>'+response.groups[key].status_label+'</td>';
                            //html += '<td>'+response.groups[key].created+'</td>';
                            //html += '<td>'+response.groups[key].modified+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#group_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#group-datatable")) {
					$("#group-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.saveGroupData = function(type) {
	if(groupForm.valid()){
		$('.save_group_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var group_name = $('#group_name').val();
		var group_owner = $('#group_owner').val();
		var group_description = $('#group_description').val();

		var url;
		if(type == 'add') {
			var group_id = 0;
			var url = api_url+"rsgroups/add";
		} else if(type == 'edit') {
			var group_id = $('#selected_group_id').val();
			var url = api_url+"rsgroups/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+group_id+'&group_name='+group_name+'&group_owner='+group_owner+'&group_description='+group_description,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_group_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#groupAddModal').modal('hide');
                	$('#groupEditModal').modal('hide');
                	loadGroupData();
                }
            }
        });
    }
}

window.deleteGroup = function(group_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"rsgroups/delete",
        data:'id='+group_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadGroupData();
            }
        }
    });
}

/* Features code start */

var $featureForm;
window.addFeatureValidation = function() {
	$featureForm = $("#feature_add_form, #feature_edit_form");
	$featureForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "feature_name": { required: true },
	        "feature_action": { required: true }
	    },
	    messages: {
	        "feature_name": { required: '' },
	        "feature_action": { required: '' }
	    }
	});
}

window.loadFeaturesData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"features",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.features) {
	                if (response.features.hasOwnProperty(key)) {

	                	var radio = '<label class="custom-control custom-radio"><input name="feature-radio" type="radio" class="custom-control-input get_feature_item" data-id="'+response.features[key].id+'"><span class="custom-control-label">&nbsp;</span></label>';

	                	var kebab = '<a class="dropdown-item edit_feature" href="javascript:void(0)" data-id="'+response.features[key].id+'">Edit</a>';
	                	/*if(response.features[key].status != 1) {
	                		kebab += '<a data-id="'+response.features[key].id+'" data-module="Service" class="dropdown-item delete_service" href="javascript:void(0)">Delete</a>';
	                	}*/

	                	html += '<tr>';
                            html += '<td>'+radio+'</td>';
                            html += '<td>'+response.features[key].id+'</td>';
                            html += '<td>'+response.features[key].name+'</td>';
                            html += '<td>'+response.features[key].action+'</td>';
                            html += '<td>'+response.features[key].icon+'</td>';
                            html += '<td>'+response.features[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
	                }
	            }
	            $('#feature_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#feature-datatable")) {
					$("#feature-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.getFeatureDetailById = function(feature_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"features/view/"+feature_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#feature_name').val(response.feature.name);
        		$('#feature_action').val(response.feature.action);
        		$('#feature_icon').val(response.feature.icon);
        		$('#feature_status_dd').val(response.feature.status);
            }
        }
    });
}

window.saveFeatureData = function(type) {
	if($featureForm.valid()){
		$('.save_feature_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var name = $('#feature_name').val();
		var action = $('#feature_action').val();
		var icon = $('#feature_icon').val();
		var status = $('#feature_status_dd').val();

		var url;
		if(type == 'add') {
			var feature_id = 0;
			var url = api_url+"features/add";
		} else if(type == 'edit') {
			var feature_id = $('#selected_feature_id').val();
			var url = api_url+"features/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+feature_id+'&name='+name+'&action='+action+'&icon='+icon+'&status='+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_feature_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#featureAddModal').modal('hide');
                	$('#featureEditModal').modal('hide');
                	$('#feature_item_data_container').html('');
                	loadFeaturesData();
                }
            }
        });
    }
}

/* Feature items code start here */

var $featureItemForm;
window.addFeatureItemValidation = function() {
	$featureItemForm = $("#feature_item_add_form, #feature_item_edit_form");
	$featureItemForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "feature_item_display_name": { required: true },
	        "feature_item_action": { required: true }
	    },
	    messages: {
	        "feature_item_display_name": { required: '' },
	        "feature_item_action": { required: '' }
	    }
	});
}

window.loadFeatureItemData = function(feature_id) {
	$('#feature_item_data_section').removeClass('d-none');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"feature-items/get-feature-items",
        data: "feature_id="+feature_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
    	    	for (var key in response.feature_items) {
            		if (response.feature_items.hasOwnProperty(key)) {
            			var kebab = '<a data-id="'+response.feature_items[key].id+'" data-module="FeatureItems" class="dropdown-item edit_feature_item" href="javascript:void(0)">Edit</a>';
	                	html += '<tr>';
                            html += '<td>'+response.feature_items[key].id+'</td>';
                            html += '<td>'+response.feature_items[key].display_name+'</td>';
                            html += '<td>'+response.feature_items[key].action+'</td>';
                            html += '<td>'+response.feature_items[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
                    }
                }
	            $('#feature_item_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#feature-item-datatable")) {
					$("#feature-item-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.getFeatureItemDetailById = function(feature_item_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"feature-items/view/"+feature_item_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#feature_item_display_name').val(response.feature_item.display_name);
        		$('#feature_item_action').val(response.feature_item.action);
        		$('#feature_item_status_dd').val(response.feature_item.status);
            }
        }
    });
}

window.saveFeatureItemData = function(type) {
	if($featureItemForm.valid()){
		$('.save_feature_item_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var display_name = $('#feature_item_display_name').val();
		var action = $('#feature_item_action').val();
		var status = $('#feature_item_status_dd').val();

		var feature_id = $("input[name='feature-radio']:checked").attr('data-id');

		var url;
		if(type == 'add') {
			var feature_item_id = 0;
			var url = api_url+"feature-items/add";
		} else if(type == 'edit') {
			var feature_item_id = $('#selected_feature_item_id').val();
			var url = api_url+"feature-items/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+feature_item_id+'&feature_id='+feature_id+'&display_name='+display_name+'&action='+action+'&status='+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_feature_item_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#featureItemAddModal').modal('hide');
                	$('#featureItemEditModal').modal('hide');
                	loadFeatureItemData(feature_id);
                }
            }
        });
    }
}

/* Services code start */

var $serviceForm;
window.addServiceValidation = function() {
	$serviceForm = $("#service_add_form, #service_edit_form");
	$serviceForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "service_name": { required: true }
	    },
	    messages: {
	        "service_name": { required: '' }
	    }
	});
}

window.getServiceDetailById = function(service_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"services/view/"+service_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#service_name').val(response.service.service_name);
        		$('#service_description').val(response.service.description);
        		$('#service_status_dd').val(response.service.status);
        		var description = response.service.description;
        		if(description != null){
        			service_description_editor.value(description);
        		}
            }
        }
    });
}

window.loadServicesData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"services",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.services) {
	                if (response.services.hasOwnProperty(key)) {

	                	var radio = '<label class="custom-control custom-radio"><input name="service-radio" type="radio" class="custom-control-input get_service_member" data-id="'+response.services[key].id+'"><span class="custom-control-label">&nbsp;</span></label>';

	                	var kebab = '<a class="dropdown-item edit_service" href="javascript:void(0)" data-id="'+response.services[key].id+'">Edit</a>';
	                	/*if(response.services[key].status != 1) {
	                		kebab += '<a data-id="'+response.services[key].id+'" data-module="Service" class="dropdown-item delete_service" href="javascript:void(0)">Delete</a>';
	                	}*/
	                	var description = '';
	                	if(response.services[key].description != null) {
	                		description = response.services[key].description;
	                	}
	                	html += '<tr>';
                            html += '<td>'+radio+'</td>';
                            html += '<td>'+response.services[key].id+'</td>';
                            html += '<td>'+response.services[key].service_name+'</td>';
                            html += '<td>'+description+'</td>';
                            html += '<td>'+response.services[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
	                }
	            }
	            $('#service_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#service-datatable")) {
					$("#service-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.saveServiceData = function(type) {
	if($serviceForm.valid()){
		$('.save_service_data').attr('disabled',true);

		// Check validation for comment area
		if($.trim(service_description_editor.value()) == "") {
			$('.save_service_data').attr('disabled', false);
            var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Description should not be blank.'
                }
            };
            $('.save_service_data').attr('disabled', false);
            display_error_message(response);
            return false;
        }

        // Parse markdown to html
        var formatted_markdown_description = parse_markdown_to_html(service_description_editor.value());

		var user_id = user.user_details.user_id;
		var service_name = $('#service_name').val();
		var description = service_description_editor.value();
		var status = $('#service_status_dd').val();

		var url;
		if(type == 'add') {
			var service_id = 0;
			var url = api_url+"services/add";
		} else if(type == 'edit') {
			var service_id = $('#selected_service_id').val();
			var url = api_url+"services/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+service_id+'&service_name='+service_name+'&description='+btoa(unescape(encodeURIComponent(description)))+'&status='+status+'&formatted_markdown_description='+formatted_markdown_description,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_service_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#serviceAddModal').modal('hide');
                	$('#serviceEditModal').modal('hide');
                	$('#service_member_data_section').html('');
                	loadServicesData();
                }
            }
        });
    }
}

window.deleteService = function(service_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"services/delete",
        data:'id='+service_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadServicesData();
                $('#service_member_data_section').addClass('d-none');
                $('#service_member_data_container').html('');
            }
        }
    });
}

/* Get Service Member */

window.loadServiceMemberData = function(service_id) {
	$('#service_member_data_section').removeClass('d-none');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"user-services/get-service-members",
        data: "service_id="+service_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
    	    	for (var key in response.user_services) {
            		if (response.user_services.hasOwnProperty(key)) {
            			var kebab = '<a data-id="'+response.user_services[key].id+'" data-module="ServiceMember" class="dropdown-item delete_user_service" href="javascript:void(0)">Delete</a>';
	                	html += '<tr>';
                            html += '<td>'+response.user_services[key].id+'</td>';
                            html += '<td>'+response.user_services[key].user.first_name+'</td>';
                            html += '<td>'+response.user_services[key].user.last_name+'</td>';
                           	html += '<td>'+response.user_services[key].user.email+'</td>';
                            html += '<td>'+response.user_services[key].user.phone+'</td>';
                            html += '<td>'+response.user_services[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
                    }
                }
	            $('#service_member_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#service-member-datatable")) {
					$("#service-member-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.getVerifiedUserList = function() {
	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        html += '<select name="verified_user_dd" class="form-control verified_user_dd mt-3" data-toggle="select2">';
					html += '<option data-dwolla-id="0" data-user-id="0" value="0">Rent Synergy</option>';
	            	for (var key in response.users) {
		                if (response.users.hasOwnProperty(key)) {
							if(response.users[key].dwolla_customer_status == 1) {
								var name = response.users[key].first_name+' '+response.users[key].last_name;
								var email = response.users[key].email;
								var dwolla_uid = response.users[key].dwolla_uid;
								html += '<option data-dwolla-id="'+dwolla_uid+'" data-user-id="'+response.users[key].id+'" value="'+response.users[key].id+'">'+name+' ('+email+')</option>';
							}
						}
					}
				html += '</select>';
				$('#verified_user_container').html(html);

				$(".verified_user_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select User",
				            dropdownParent: $(this).parent()
				        });
				});
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.getUserList = function() {
	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        html += '<select name="service_members" class="form-control service_member_dd mt-3" data-toggle="select2" multiple>';
	            	for (var key in response.users) {
		                if (response.users.hasOwnProperty(key)) {
		                	var name = response.users[key].first_name+' '+response.users[key].last_name;
			                var email = response.users[key].email;
	                		html += '<option data-user-id="'+response.users[key].id+'" value="'+response.users[key].id+'">'+name+'</option>';
						}
					}
				html += '</select>';
				$('#service_member_dd_container').html(html);

				$(".service_member_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select User",
				            dropdownParent: $(this).parent()
				        });
				});
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.saveServiceMemberData = function() {

	var service_member_id_arr = $('.service_member_dd').val();
	var service_member_ids = $('.service_member_dd').find(':selected').map(function() {
	    return $(this).attr("data-user-id");
	}).get().join(',');

	$('.save_service_member_data').attr('disabled',true);

	// Check validation for comment area
	if($.trim(service_member_ids) == "") {
		$('.save_service_member_data').attr('disabled', false);
        var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select user.'
            }
        };
        $('.save_service_member_data').attr('disabled', false);
        display_error_message(response);
        return false;
    }

    var user_id = user.user_details.user_id;
    var service_id = $("input[name='service-radio']:checked").attr('data-id');

	var status = 1;
	var url = api_url+"user-services/add";

	$.ajax({
        method: 'POST',
        url: url,
        data: 'user_id='+user_id+'&service_id='+service_id+'&service_member_ids='+service_member_ids+'&status='+status,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            $('.save_service_member_data').attr('disabled',false);
            if(response.response_data.success == true) {
            	$('#serviceMemberAddModal').modal('hide');
            	loadServiceMemberData(service_id);
            }
        }
    });
}

window.deleteUserService = function(user_service_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"user-services/delete",
        data:'id='+user_service_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var service_id = $("input[name='service-radio']:checked").attr('data-id');
                loadServiceMemberData(service_id);
            }
        }
    });
}

/* Get Feature permissions data */

window.loadFeaturePermissionsData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();

	$.ajax({
	    method: 'POST',
	    url: api_url+"features-permissions/list-permissions",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		var html = '';
	    		html += '<div class="card">';
				    html += '<table class="table">';
				        html += '<thead>';
				            html += '<tr>';
				                html += '<th>&nbsp;</th>';
				                for (var i=1; i<=Object.keys(response.services).length; i++) {
				        			html += '<th class="fs-16 bold-black text-center p_service_name" data-service-id="'+i+'">'+response.services[i]+'</th>';
								}
								html += '</tr>';
						html += '</thead>';
						html += '<tbody class="service_permissions_table">';
		            	for (var fkey in response.feature_permissions) {
			                if (response.feature_permissions.hasOwnProperty(fkey)) {
				        		html += '<tr class="bg-light">';
					                html += '<td class="fs-16 bold-black p_feature_name" data-feature-id="'+fkey+'">'+response.feature_permissions[fkey].name+'</td>';
					                html += '<td>&nbsp;</td>';
					                html += '<td>&nbsp;</td>';
					                html += '<td>&nbsp;</td>';
					                html += '<td>&nbsp;</td>';
					            html += '</tr>';
						        for (var fikey in response.feature_permissions[fkey].feature_items) {
	                                if (response.feature_permissions[fkey].feature_items.hasOwnProperty(fikey)) {
	                                	html += '<tr>';
							                html += '<td>'+response.feature_permissions[fkey].feature_items[fikey].action+'</td>';
							                for (var skey in response.feature_permissions[fkey].feature_items[fikey].services) {
	                                			if (response.feature_permissions[fkey].feature_items[fikey].services.hasOwnProperty(skey)) {
	                                				if(response.feature_permissions[fkey].feature_items[fikey].services[skey] == 1) {
	                                					var checked= 'checked';
	                                				} else {
	                                					var checked= '';
	                                				}

	                                				if(skey == 1) {
	                                					var add_opacity = 'add_opacity';
	                                				} else {
	                                					var add_opacity = '';
	                                				}

							                		html += '<td class="text-center '+add_opacity+'">';
									                    html += '<label class="custom-control custom-checkbox">';
									                        html += '<input name="" type="checkbox" class="custom-control-input permission_checkbox" data-service-id="'+skey+'" data-feature-id="'+fkey+'" data-feature-item-id="'+fikey+'" '+checked+'>';
									                        html += '<span class="custom-control-label">&nbsp;</span>';
									                    html += '</label>';
									                html += '</td>';
												}
											}
							            html += '</tr>';
						        	}
						    	}
			                }
			            }
			            html += '</tbody>';
			        html += '</table>';
				html += '</div>';
				$('#feature_item_data').html(html);
	        }
	    }
	});
}

window.saveFeaturePermissionData = function(permissionArray) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"features-permissions/save-permission-data",
        data:'user_id='+user_id+'&permissionArray='+permissionArray,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		loadFeaturePermissionsData();
        	}
        }
    });
}

/* UI API for Manage Zoho Template */

window.getZohoTemplates = function() {
	var user_id = user.user_details.user_id;
	getFloatingActionButton('Template', '#zoho_template_container');
	if(user.permissionData['TEMPLATE']["feature_items['list']"]) {
		if ($.fn.DataTable.isDataTable("#zoho-template-datatable")) {
            $("#zoho-template-datatable").DataTable().destroy();
        }
		$.ajax({
	        method: 'POST',
	        url: api_url+"zoho-templates",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(data) {
	        	if(data.response_data.success == true) {
	        		var tempArray = data.tempArray;
		        	$.ajax({
				        method: 'POST',
				        url: api_url+"zoho-templates/list",
				        data: 'user_id='+user_id,
				        beforeSend: function(xhr) {
					        xhr.setRequestHeader('Authorization', accessToken);
					    },
				        success: function(response) {
				        	display_error_message(response);
				        	if(response.response_data.success == true) {
				        		var html = '';
				            	for (var key in response.templateList.templates) {
					                if (response.templateList.templates.hasOwnProperty(key)) {
					                	// Display only templates which is belongs to zoho_templates table
					                	if(tempArray.hasOwnProperty(response.templateList.templates[key].template_id)) {
						                	var owner = response.templateList.templates[key].owner_first_name+' '+response.templateList.templates[key].owner_last_name;
						                	var created_time = response.templateList.templates[key].created_time;
						                	created_time = new Date(created_time).toLocaleDateString("en-US");

						                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">';

						                	if(user.permissionData['TEMPLATE']["feature_items['edit']"]) {
						                		kebab += '<a class="dropdown-item edit_zoho_template" href="javascript:void(0)" data-template-id="'+response.templateList.templates[key].template_id+'">Edit</a>';
						                	}
						                	if(user.permissionData['TEMPLATE']["feature_items['delete']"]) {
						                		kebab += '<a class="dropdown-item delete_zoho_template" href="javascript:void(0)" data-template-id="'+response.templateList.templates[key].template_id+'">Delete</a>';
						                	}

						                	kebab += '</div></div></div>';


									        html += '<tr>';
					                            html += '<td>'+response.templateList.templates[key].template_name+'</td>';
					                            html += '<td>'+owner+'</td>';
					                            html += '<td>'+response.templateList.templates[key].is_sequential+'</td>';
					                            html += '<td>'+response.templateList.templates[key].expiration_days+'</td>';
					                            html += '<td>'+response.templateList.templates[key].reminder_period+'</td>';
					                            html += '<td><span style="display:none">'+response.templateList.templates[key].created_time+'</span>'+created_time+'</td>';
					                            html += '<td>'+kebab+'</td>';
					                        html += '</tr>';
					                    }
					                }
					            }
					            $('#zoho_data_container').html(html);

					            var column = [
						            { className: 'all', targets: [0], width:'40%', orderable: true},
						            { targets: [1], width:'15%', orderable: true},
						            { targets: [2], width:'10%', orderable: true},
						            { targets: [3], width:'10%', orderable: true},
						            { targets: [4], width:'10%', orderable: true},
						            { targets: [5], width:'10%', orderable: false},
						            { className: 'all', targets: [6], width:'5%', orderable: false}
						        ];

					            $("#zoho-template-datatable").DataTable({
								    responsive: true,
								    order: [[5,'desc']],
								    columnDefs: column,
								});
				            }
				        }
				    });
		        }
	        }
	    });
	}
}

window.downloadContract = function(contract_id, contract_name, type){
	var user_id = user.user_details.user_id;

	if(!type){
		type = 'combined';
	}
	$.ajax({
		method : 'POST',
		url : api_url+"docusigns/download-envelope-document",
		data : 'lease_agreement_id='+contract_id+'&user_id='+user_id+'&type='+type,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.download_signed_contract').attr('disabled', false);
			$('.download_signed_contract').text('Download');
			if(response.response_data.success == true) {
				const linkSource = `data:application/pdf;base64,${response.data}`;
				const downloadLink = document.createElement("a");
				const fileName = contract_name+".pdf";
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			} else {
				display_error_message(response);
			}
		}
	});
}

window.downloadZohoContract = function(contract_id, contract_name, type = '', status){
	var user_id = user.user_details.user_id;

	if(!type){
		type = 'pdf';
	}
	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/download-document",
		data : 'zoho_document_id='+contract_id+'&user_id='+user_id+'&type='+type,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.download_zoho_signed_contract').attr('disabled', false);
			$('.download_zoho_signed_contract').text('Download');
			display_error_message(response);
			if(response.response_data.success == true) {
				const linkSource = `data:application/pdf;base64,${response.data}`;
				const downloadLink = document.createElement("a");
				const fileName = contract_name+".pdf";
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			}
		}
	});
}

window.extendZohoDocument = function(contract_id, contract_name){
	var user_id = user.user_details.user_id;
	var extend_date = $('input[name^="zoho_extend_date"]').val();
	if(extend_date == ''){
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select new date'
            }
        };
        display_error_message(response);
		$('.extend_zoho_document').attr('disabled', false);
		$('.extend_zoho_document').text('Extend');
	} else{
		$.ajax({
			method : 'POST',
			url : api_url+"zoho-documents/extend-document",
			data : 'zoho_document_id='+contract_id+'&user_id='+user_id+'&extend_date='+extend_date,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				display_error_message(response);
				if(response.response_data.success == true) {
					$('#extendDocumentModal').modal('hide');
					var expiration_date = $('.extend_document_modal').attr('expiration-date', extend_date);
				} else {
					$('.extend_zoho_document').attr('disabled', false);
					$('.extend_zoho_document').text('Extend');
				}
			}
		});
	}
}

window.remindZohoDocument = function(contract_id, contract_name){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/remind-document",
		data : 'zoho_document_id='+contract_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			$('.remind_zoho_document').attr('disabled', false);
			$('.remind_zoho_document').text('Send Reminder');
		}
	});
}

window.voidContract = function(contract_id, contract_name, void_reason){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"docusigns/void-contract",
		data : 'lease_agreement_id='+contract_id+'&user_id='+user_id+'&void_reason='+void_reason,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			$('#VoidDocumentModal').modal('hide');
			$('#used_master_contract').val('');
			getPropertyDocumentWidgetInfo();
		}
	});
}

window.recallContract = function(contract_id, contract_name, void_reason){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/recall-document",
		data : 'zoho_document_id='+contract_id+'&user_id='+user_id+'&void_reason='+void_reason,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			$('#RecallDocumentModal').modal('hide');
			$('#used_master_contract').val('')
			getPropertyDocumentWidgetInfo();
		}
	});
}

window.getDocumentTypes = function(request_type_id = '') {
	$.ajax({
       	method: 'GET',
	    url: api_url+"zoho-templates/get-document-types",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<select name="request_type_id"  id="request_type_id" class="form-control">';
	            	for (var key in response.documentTypes) {
		                if (response.documentTypes.hasOwnProperty(key)) {
		                	var selected = '';
		                	if(request_type_id == response.documentTypes[key].folder_id) {
		                		selected = "selected";
		                	}
	                		html += '<option value="'+response.documentTypes[key].folder_id+'" '+selected+'>'+response.documentTypes[key].folder_name+'</option>';
						}
					}
				html += '</select>';
				$('#document_type_container').html(html);
            }
        }
    });
}

window.zohoCreateNewTemplate = function() {

	if(createTemplateDropzone.files.length == 0) {
		$('.submit_zoho_template_detail').attr('disabled', false);
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select pdf document'
            }
        };
        display_error_message(response);
        return false;
	}


	var formData = new FormData();

	// Upload Property files
    for (var x = 0; x < createTemplateDropzone.files.length; x++) {
    	formData.append("file[]", createTemplateDropzone.files[x]);
    }

    var is_sequential = false;

    var email_reminders = false;
    if($('#email_reminders').is(":checked")) {
    	email_reminders = true;
    }

    var user_id = user.user_details.user_id;
    formData.append('user_id', user_id);
    formData.append('template_name', $('#template_name').val());
    formData.append('request_type_id', $('#request_type_id').val());
    formData.append('expiration_days', $('#expiration_days').val());
    formData.append('is_sequential', is_sequential);
    formData.append('reminder_period', $('#reminder_period').val());
    formData.append('email_reminders', email_reminders);
    formData.append('description', $('#description').val());
    formData.append('notes', $('#notes').val());

    var recipientArray = [];
    $('input.sequenceNo').each(function(key, value) {
    	var no = key + 1;
	    var seq_no = $('#seq_no'+no).val();
	    var role = $('#role'+no).val();
	    //var recipient_email = $('#recipient_email'+no).val();
	    //var recipient_name = $('#recipient_name'+no).val();
	    recipientArray.push({
			'signing_order' : seq_no,
			'role' : role,
			'recipient_email' : '',
			'recipient_name' : '',
		});
	});

	formData.append('actions', JSON.stringify(recipientArray));

	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/create",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var template_id = response.templateDetail.templates.template_id;
        		var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var tid = rand_num1+template_id+rand_num2;
        		window.history.pushState("", "", '?action=template&tid='+tid);
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
					$('#main_container').load('components/template/viewer.html', template_id, function(){
						$('#pdf_template_id').val(template_id);
						$('#t_user_id').val(user_id);
						//getZohoTemplateRecipients(template_id);
						getZohoFieldTypes();
			    		//getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
            } else {
            	$('.submit_zoho_template_detail').attr('disabled', false);
            }
        }
    });
}

window.zohoUpdateTemplate = function() {
	var formData = new FormData();
	// Upload Property files
    for (var x = 0; x < updateTemplateDropzone.files.length; x++) {
    	formData.append("file[]", updateTemplateDropzone.files[x]);
    }
    var is_sequential = false;
    var email_reminders = false;
    if($('#email_reminders').is(":checked")) {
    	email_reminders = true;
    }

    var user_id = user.user_details.user_id;
    var template_id = $('#template_id').val();
    formData.append('user_id', user_id);
    formData.append('template_id', template_id);
    formData.append('template_name', $('#template_name').val());
    formData.append('request_type_id', $('#request_type_id').val());
    formData.append('expiration_days', $('#expiration_days').val());
    formData.append('is_sequential', is_sequential);
    formData.append('reminder_period', $('#reminder_period').val());
    formData.append('email_reminders', email_reminders);
    formData.append('description', $('#description').val());
    formData.append('notes', $('#notes').val());

    var deleted_actions = $('#deletedZohoActionId').val();
    deleted_actions =  deleted_actions.split(',');
    formData.append('deleted_actions', deleted_actions);

    var recipientArray = [];
    $('input.sequenceNo').each(function(key, value) {
    	var no = $(this).val();
	    var seq_no = $('#seq_no'+no).val();
	    var role = $('#role'+no).val();
	    var recipient_email = $('#recipient_email'+no).val();
	    var recipient_name = $('#recipient_name'+no).val();
	    var action_id = $('#action_id'+no).val();
	    recipientArray.push({
			'signing_order' : seq_no,
			'role' : role,
			'recipient_email' : '',
			'recipient_name' : '',
			'action_id' : action_id,
		});
	});

	//console.log(recipientArray);return false;

	formData.append('actions', JSON.stringify(recipientArray));

	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/update",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var tid = rand_num1+template_id+rand_num2;
        		window.history.pushState("", "", '?action=template&tid='+tid);
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
					$('#main_container').load('components/template/viewer.html', template_id, function(){
						$('#pdf_template_id').val(template_id);
						$('#t_user_id').val(user_id);
						//getZohoTemplateRecipients(template_id);
						getZohoFieldTypes();
			    		//getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
            } else {
            	$(".update_zoho_template_detail").attr('disabled', false);
            }
        }
    });
}

window.getZohoTemplateDetailById = function(template_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"zoho-templates/detail/"+template_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var template_id = response.templateDetail.templates.template_id;
        		var template_name = response.templateDetail.templates.template_name;
        		var request_type_id = response.templateDetail.templates.request_type_id;
        		var is_sequential = response.templateDetail.templates.is_sequential;
        		var expiration_days = response.templateDetail.templates.expiration_days;
        		var email_reminders = response.templateDetail.templates.email_reminders;
        		var reminder_period = response.templateDetail.templates.reminder_period;
        		var description = response.templateDetail.templates.description;
        		var notes = response.templateDetail.templates.notes;

        		setTimeout(function () {
		            getDocumentTypes(request_type_id);
		        }, 300);

        		var html = '';
        		for (var key in response.templateDetail.templates.document_ids) {
	                if (response.templateDetail.templates.document_ids.hasOwnProperty(key)) {
        				html += '<div id="document'+response.templateDetail.templates.document_ids[key].document_id+'" class="form-group col-md-4 col-xl-2 ml-3 text-center border preview_zoho_document"><i data-template-id="'+template_id+'" data-document-id="'+response.templateDetail.templates.document_ids[key].document_id+'" class="align-middle mr-2 fas fa-fw fa-trash delete_zoho_document d-none" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img src="data:image/png;base64,'+response.templateDetail.templates.document_ids[key].image_string+'" alt="'+response.templateDetail.templates.document_ids[key].document_name+'" /></div>';
        			}
        		}

        		$('#pdf_section').append(html);

        		$('#document_dropzone_container').removeClass('d-none');

        		$('#email_reminders').attr('checked', false);
        		if(email_reminders == true) {
        			$('#email_reminders').attr('checked', true);
        		}

        		$('#template_id').val(template_id);
        		$('#template_name').val(template_name);
        		$('#expiration_days').val(expiration_days);
        		$('#reminder_period').val(reminder_period);
        		$('#description').val(description);
        		$('#notes').val(notes);

        		var html = '';

        		var display = 'd-none';
        		var total = response.templateDetail.templates.actions.length;
        		if(total > 1) {
        			display = '';
        		}

        		for (var key in response.templateDetail.templates.actions) {
	                if (response.templateDetail.templates.actions.hasOwnProperty(key)) {
	                	var sigining_order = response.templateDetail.templates.actions[key].signing_order;
	                	var action_id = response.templateDetail.templates.actions[key].action_id;
	                	var role = response.templateDetail.templates.actions[key].role;
	                	var recipient_email = response.templateDetail.templates.actions[key].recipient_email;
	                	var recipient_name = response.templateDetail.templates.actions[key].recipient_name;

	                	var add_opacity = '';
	                	if(key == 0) {
	                		add_opacity = 'add_opacity bold-black';
	                	}

	                	html += '<div id="recipients_'+sigining_order+'" class="form-row recipients_data">';
				            html += '<div class="form-group col-md-4 col-xl-4">';
				            	html += '<div class="input-group mb-2 mr-sm-2">';
									html += '<div class="input-group-prepend">';
										html += '<input type="hidden" name="action_id[]" id="action_id'+sigining_order+'" class="action_input" value="'+action_id+'">';
										html += '<input type="hidden" name="seq_no[]" id="seq_no'+sigining_order+'" class="sequenceNo" value="'+sigining_order+'">';
										html += '<div class="input-group-text sequenceNo">'+sigining_order+'</div>';
									html += '</div>';
									//html += '<input type="text" name="role[]" id="role'+sigining_order+'" class="form-control role" placeholder="Role" value="'+role+'">';
									html += '<select name="role[]" id="role'+sigining_order+'" class="form-control role '+add_opacity+'">';
										$.each(role_dd, function( key, value ) {
											var selected = '';
											if(value == role) {
												selected = 'selected';
											}
										  	html += '<option value="'+value+'" '+selected+'>'+value+'</option>';
										});
									html += '</select>';
								html += '</div>';
				            html += '</div>';
					        html += '<div class="action_btn_group form-group col-md-1 col-xl-1">';
				            	html += '<a href="javascript:void(0)" data-row-id="'+sigining_order+'" class="removeRowButton btn btn-default mr-2 '+display+'"><i class="fas fa-minus"></i></a>';
				            	if((total - 1) == key) {
				            		html += '<a href="javascript:void(0)" data-row-id="'+sigining_order+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>';
				            	}
					        html += '</div>';
				        html += '</div>';
	                }
	            }

	            $('#recipients_container').html(html);

	            if($('.recipients_data').length > 1) {
		        	$('.recipients_data:first').find('.removeRowButton').remove();
		        }
            }
        }
    });
}

window.saveZohoDocumentData = function(template_id, actions) {
	//console.log(actions);
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/update-document",
        data: 'template_id='+template_id+'&actions='+actions+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('.save_document_data').attr('disabled',false);
    			openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html');
            } else {
            	$('.save_document_data').attr('disabled',false);
            }
        }
    });
}

window.deleteZohoDocument = function(id) {
	var user_id = user.user_details.user_id;
	var arr = id.split('&&');
	var template_id = arr[0];
	var document_id = arr[1];
	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/delete-document",
        data: 'template_id='+template_id+'&document_id='+document_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#document'+document_id).remove();
            }
        }
    });
}

window.deleteDocumentField = function(id, field_id, action_id) {
	$('.field'+id).remove();
	// Add delete field_id in hidden input
	if(field_id > 0) {
		var delimeter = '';
		if($('#deletedFieldIds').val() != "") { delimeter = ','; }
		var deletedActionIds = $('#deletedFieldIds').val($('#deletedFieldIds').val()+delimeter+field_id);
	}
}

window.getZohoTemplateRecipients = function(response) {
	var template_id = response.templateDetail.templates.template_id;
	var template_name = response.templateDetail.templates.template_name;
	$('#navbar_role_name').html(humanize(current_page)+' <small class="fs-12">('+template_name+')</small>');

	var html = '';
	var field_input = '';
	var bg_color = '';
	for (var key in response.templateDetail.templates.actions) {
        if (response.templateDetail.templates.actions.hasOwnProperty(key)) {
        	var sigining_order = response.templateDetail.templates.actions[key].signing_order;
        	var action_id = response.templateDetail.templates.actions[key].action_id;
        	var role = response.templateDetail.templates.actions[key].role;
        	var recipient_email = response.templateDetail.templates.actions[key].recipient_email;
        	var recipient_name = response.templateDetail.templates.actions[key].recipient_name;

        	var action_type = response.templateDetail.templates.actions[key].action_type;
        	var signing_order = response.templateDetail.templates.actions[key].signing_order;
        	var in_person_name = recipient_name;
        	var verify_recipient = response.templateDetail.templates.actions[key].verify_recipient;
        	var verification_type = response.templateDetail.templates.actions[key].verification_type;

        	var bg_color = color_array[key];
        	var border_color = border_color_array[key];

        	var bg_class = '';
        	if(key == 0) {
            	$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+" } </style>").appendTo("head");
				bg_class = 'recipient_row_bg';
			}

        	html += '<div data-border-color='+border_color+' data-bg-color='+bg_color+' data-template-id='+template_id+' data-action-id='+action_id+' data-role="'+role+'" data-name="'+recipient_name+'" data-action-type='+action_type+' data-signing-order='+signing_order+' data-in-person_name="'+recipient_name+'" data-verify-recipient='+verify_recipient+' data-verification-type='+verification_type+' class="recipient_row media border-bottom p-2 '+bg_class+'">';
				html += '<div class="po_bg mt-1 ml-2 mr-3"><div class="circle"><p class="text-center">'+role.charAt(0)+'</p></div></div>';
				html += '<div class="media-body mt-1 pt-2"><strong>'+role+'</strong></div>';
			html += '</div>';

			field_input += '<input type="hidden" name="'+action_id+'" id="'+action_id+'">';
        }
    }

    $('#recipients_data_container').html(html);
    $('#recipients_data_container').append(field_input);
}

window.getZohoDocumentFields = function(response) {
	display_error_message(response);
	if(response.response_data.success == true) {
		var template_id = response.templateDetail.templates.template_id;

		var bg_color = '';
		for (var key in response.templateDetail.templates.actions) {
            if (response.templateDetail.templates.actions.hasOwnProperty(key)) {
            	var sigining_order = response.templateDetail.templates.actions[key].signing_order;
            	var action_id = response.templateDetail.templates.actions[key].action_id;
            	var role = response.templateDetail.templates.actions[key].role;
            	var recipient_email = response.templateDetail.templates.actions[key].recipient_email;
            	var recipient_name = response.templateDetail.templates.actions[key].recipient_name;

            	var action_type = response.templateDetail.templates.actions[key].action_type;
            	var signing_order = response.templateDetail.templates.actions[key].signing_order;
            	var in_person_name = recipient_name;
            	var verify_recipient = response.templateDetail.templates.actions[key].verify_recipient;
            	var verification_type = response.templateDetail.templates.actions[key].verification_type;

            	var bg_color = color_array[key];
            	var border_color = border_color_array[key];

            	var bg_class = '';
            	if(key == 0) {
                	$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+"; border-left: 3px solid "+border_color+" !important; border-right: 3px solid "+border_color+" !important; } </style>").appendTo("head");
					bg_class = 'recipient_row_bg';
				}

            	// Set Field data in document
				for (var fkey in response.templateDetail.templates.actions[key].fields) {
            		if (response.templateDetail.templates.actions[key].fields.hasOwnProperty(fkey)) {
            			var field_id = response.templateDetail.templates.actions[key].fields[fkey].field_id;
            			var field_type_id = response.templateDetail.templates.actions[key].fields[fkey].field_type_id;
            			var field_label = response.templateDetail.templates.actions[key].fields[fkey].field_label;
            			var field_category = response.templateDetail.templates.actions[key].fields[fkey].field_category;
            			var document_id = response.templateDetail.templates.actions[key].fields[fkey].document_id;
            			var page_no = response.templateDetail.templates.actions[key].fields[fkey].page_no;
            			var x_value = response.templateDetail.templates.actions[key].fields[fkey].x_value;
            			var y_value = response.templateDetail.templates.actions[key].fields[fkey].y_value;

            			var randomStr = Math.floor(Math.random() * 1000000000);

            			// Left and Top position calculation
				  		// Percentage with 817 * 1057

				  		var leftPixel = (Math.round(x_value) * canvas_width) / 100 ;
				  		var topPixel = (Math.round(y_value) * canvas_height) / 100;

				  		leftPixel = leftPixel+'px';
				  		topPixel = topPixel+'px';

				  		var field_html = '';
				  		field_html += '<div id='+field_label+' field-id='+field_id+' field-type-id='+field_type_id+' field-category='+field_category+' name='+field_label+' class="drag ui-draggable ui-draggable-handle item_element field'+randomStr+'" page-no='+page_no+' data-id='+randomStr+' data-template-id='+template_id+' data-action-id='+action_id+' data-role="'+role+'" data-name="'+recipient_name+'" data-action-type='+action_type+' data-signing-order='+signing_order+' data-verify-recipient='+verify_recipient+' data-verification-type='+verification_type+' data-bg-color='+bg_color+' style="position:absolute;left:'+leftPixel+';top:'+topPixel+';border:1px dashed '+border_color+'; background-color: '+bg_color+'">';
            				field_html += '<div class="field_label" title='+field_label+'>'+field_label+'</div>';
            			field_html += '</div>';

            			$('div[page-no="'+page_no+'"][document-id="'+document_id+'"]').find('.text-layer').append(field_html);
			            $('div[page-no="'+page_no+'"][document-id="'+document_id+'"]').find('.item_element').draggable({
			                containment: 'parent'
			            });
            		}
            	}
            }
        }
    }
}

window.getZohoFieldTypes = function() {
	openLoadingDialog($('#field_types_container'));
	$.ajax({
        method: 'GET',
        url: api_url+"zoho-templates/fieldtypes",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
        		html += '<div id="fields-list" class="fields-list">';
        		for (var key in response.response.field_types) {
	                if (response.response.field_types.hasOwnProperty(key)) {
	                	var display_name = response.response.field_types[key].field_type_name;
	                	var field_type_id = response.response.field_types[key].field_type_id;
	                	var field_type_name = response.response.field_types[key].field_type_name;
	                	var field_category = response.response.field_types[key].field_category;

	                	var icon = '';
	                	if(field_type_name == 'Signature') {
	                		icon = '<i class="far fa-edit field-icon"></i>';
	                	} else if(field_type_name == 'Dropdown') {
	                		icon = '<i class="far fa-caret-square-down field-icon"></i>';
	                	} else if(field_type_name == 'Attachment') {
	                		icon = '<i class="fas fa-paperclip field-icon"></i>';
	                	} else if(field_type_name == 'Email') {
	                		icon = '<i class="far fa-envelope field-icon"></i>';
	                	} else if(field_type_name == 'Company') {
	                		icon = '<i class="far fa-building field-icon"></i>';
	                	} else if(field_type_name == 'Date') {
	                		icon = '<i class="far fa-calendar-alt field-icon"></i>';
	                	} else if(field_type_name == 'Textfield') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Text';
	                	} else if(field_type_name == 'Jobtitle') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Job Title';
	                	} else if(field_type_name == 'Initial') {
	                		icon = '<i class="fas fa-signature field-icon"></i>';
	                	} else if(field_type_name == 'Name') {
	                		icon = '<i class="fas fa-male field-icon"></i>';
	                		display_name = 'Full Name';
	                	} else if(field_type_name == 'CustomDate') {
	                		icon = '<i class="far fa-calendar-alt field-icon"></i>';
	                		display_name = 'Sign Date';
	                	} else if(field_type_name == 'Stamp') {
	                		icon = '<i class="fas fa-stamp field-icon"></i>';
	                	} else if(field_type_name == 'Checkbox') {
	                		icon = '<i class="far fa-check-square field-icon"></i>';
	                	} else if(field_type_name == 'Radiogroup') {
	                		icon = '<i class="far fa-dot-circle field-icon"></i>';
	                		display_name = 'Radio';
	                	}

						html += '<div class="field-li">';
							html += '<div id="'+field_type_name+'" field-type-id="'+field_type_id+'" field-category="'+field_category+'" name="'+field_type_name+'" class="drag field-li-view">';
									html += '<i class="fas fa-grip-vertical icon-move drag-icon"></i>';
									html += '<div class="field_label" title="'+field_type_name+'">'+display_name+'</div>';
									html += icon;
							html += '</div>';
						html += '</div>';
					}
				}
				html += '</div>';

				$('#field_types_container').html(html);

				$(".field-li-view").draggable({
			  		helper: "clone",
			  		cursor: 'move',
				    tolerance: 'fit',
				    revert: true,
				    start: function( event, ui ) {
				    	var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
				    	$(ui.helper).css({'border': '1px dashed '+border_color});
				    	$(ui.helper).height(35);
				    	$(ui.helper).css('line-height','35px !important');
				    	$(ui.helper).find('div.field_label').css({'position' : 'relative','top': '20%'});

				    	$(ui.helper).find('.drag-icon').remove();
						$(ui.helper).find('.field-icon').remove();
				    }
			    });
            }
        }
    });
}

window.deleteZohoTemplateById = function(template_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/remove",
        data: 'template_id='+template_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html');
            }
        }
    });
}

/* Manage Master Contracts  : Start */

window.getMasterContracts = function(){
	var user_id = user.user_details.user_id;
	if ($.fn.DataTable.isDataTable("#master-contract-datatable")) {
        $("#master-contract-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'GET',
        url: api_url+"master-contracts",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			var html = '';
        	if(response.response_data.success == true && Object.keys(response.master_contracts).length > 0) {
				for (var key in response.master_contracts) {
					var kebab = '<a class="dropdown-item edit_master_contract" href="javascript:void(0)" data-id="'+response.master_contracts[key].id+'">Edit</a>';
					kebab += '<a class="dropdown-item delete_master_contract" href="javascript:void(0)" data-id="'+response.master_contracts[key].id+'">Delete</a>';
					html += '<tr>';
						html += '<td>'+response.master_contracts[key].id+'</td>';
						if(response.master_contracts[key].template_name != null){
							html += '<td>'+response.master_contracts[key].template_name+'</td>';
						} else {
							html += '<td>-</td>';
						}

						if(response.master_contracts[key].user != null){
							html += '<td>'+response.master_contracts[key].user.first_name+' '+response.master_contracts[key].user.last_name+'</td>';
						} else {
							html += '<td>-</td>';
						}

						// html += '<td>'+response.master_contracts[key].status_label+'</td>';
						html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
					html+= '</tr>';
				}
	        } else {
				html += '<h3 class="text-center"> No Data Available </h3>';
	        }

			$('#master_contracts_data_container').html(html);

			$("#master-contract-datatable").DataTable({
				responsive: true
			});

			getFloatingActionButton('MasterContracts','#masterContractContainer');
		}
	});
}

var $masterContractForm;
window.validateMasterContract = function(){
	$masterContractForm = $("#master_contract_add_form");
	$masterContractForm.validate({
		errorPlacement: function errorPlacement(error, element) {
			$(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
		},
		highlight: function(element) {
			$(element).addClass("is-invalid");
		},
		unhighlight: function(element) {
			$(element).removeClass("is-invalid");
		},
		rules: {
			"master_contract_name": { required: true },
			"contract_identifier": { required: true },
			"master_contract_description": { required: true },
		},
		messages: {
			"master_contract_name": { required: '' },
			"contract_identifier": { required: '' },
			"master_contract_description": { required: '' }
		}
	});
}

window.saveMasterContract = function(){
	var template_name = $('#master_contract_name').val();
	var template_description = $('#master_contract_description').val();
	var content = CKEDITOR.instances['master_contract_content'].getData();

	var formData = new FormData();

	formData.append('user_service_name', user_service_name);
    formData.append('created_by', user_id);
    formData.append('template_name', template_name);
	formData.append('description', template_description);
    formData.append('identifier', $('#contract_identifier').val());
    formData.append('content', content);

	$.ajax({
        method: 'POST',
        url: api_url+"master-contracts/add",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(response) {
			display_error_message(response);
			$('.save_master_contract').attr('disabled', false);
			$('.save_master_contract').text('Submit');
			if(response.response_data.success == true) {
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/master_contracts.html');
				}, 500);
			}
		}
	});
}

window.getMasterContractDetail = function(id, type = ''){
	var user_id = user.user_details.user_id;
	if ($.fn.DataTable.isDataTable("#master-contract-datatable")) {
        $("#master-contract-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'GET',
        url: api_url+"master-contracts/detail/"+id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			if(type == ''){
				display_error_message(response);
			}

			var contract_variable_html = '';
			if(Object.keys(response.contract_variables).length > 0){
				for(var i in response.contract_variables){
					if(i <= 5){
						contract_variable_html += '<span class="badge badge-black text-left mr-2 mt-2 variable'+i+'">{{'+response.contract_variables[i].variable_name+'}}</span>';
					}
				}
				contract_variable_html += '<a href="javascript:void(0)" class="view_contract_variables">View More</a>';
				$('.contract_variables').html(contract_variable_html);
			}

        	if(response.response_data.success == true && response.master_contract != null) {
				$('#master_contract_name').val(response.master_contract.template_name);
				$('#master_contract_description').val(response.master_contract.description);
				$('#contract_identifier').val(response.master_contract.identifier);
				setTimeout(function(){
					CKEDITOR.instances['master_contract_content'].setData(response.master_contract.content);
				}, 500);
			}
		}
	});
}

window.getContractVariables = function(id, type = ''){
	openLoadingDialog($('#sidebar_content_area'));
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'GET',
        url: api_url+"master-contracts/detail/"+id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			if(type == ''){ display_error_message(response); }
			var contract_variable_html = '';
			var cnt = 0;
			if(Object.keys(response.contract_variables).length > 0){
				for(var i in response.contract_variables){
					cnt++;
					contract_variable_html += '<div class="col-md-12 col-lg-12">';
		                contract_variable_html += '<div class="badge badge-black text-left mr-2 mt-2">'+cnt+'. {{'+response.contract_variables[i].variable_name+'}}</div>';
		                contract_variable_html += '<div class="pt-3 pb-3">'+response.contract_variables[i].description+'</div>';
		            contract_variable_html += '</div>';
				}
				$('#sidebar_heading').html('Allowed Variables');
				$('#sidebar_content_area').html(contract_variable_html);
			}
		}
	});
}

window.updateMasterContract = function(id){
	var template_name = $('#master_contract_name').val();
	var template_description = $('#master_contract_description').val();
	var content = CKEDITOR.instances['master_contract_content'].getData();

	var formData = new FormData();

	formData.append('user_service_name', user_service_name);
    formData.append('master_contract_id', id);
    formData.append('modified_by', user_id);
    formData.append('template_name', template_name);
    formData.append('description', template_description);
	formData.append('identifier', $('#contract_identifier').val());
    formData.append('content', content);

	$.ajax({
        method: 'POST',
        url: api_url+"master-contracts/edit",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(response) {
			display_error_message(response);
			$('.update_master_contract').attr('disabled', false);
			$('.update_master_contract').text('Submit');
			if(response.response_data.success == true) {
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/master_contracts.html');
				}, 500);
			}
		}
	});
}

window.deleteMasterContract = function(id){
	$.ajax({
        method: 'POST',
        url: api_url+"master-contracts/delete",
        data: 'user_service_name='+user_service_name+'&master_contract_id='+id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/master_contracts.html');
				}, 500);
			}
		}
	});
}

window.getMasterContractsDD = function(){
	var user_id = user.user_details.user_id;
	if ($.fn.DataTable.isDataTable("#master-contract-datatable")) {
        $("#master-contract-datatable").DataTable().destroy();
    }
	$.ajax({
		method : 'GET',
		url : api_url+"master-contracts",
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			var html = '';
			if(response.response_data.success == true && Object.keys(response.master_contracts).length > 0) {
				for (var key in response.master_contracts) {
					var opacity = '';
					if($('#add_contract_open_from').val() == 'add_document'){
						var x = $('#used_master_contract').val().split(',')
						if(x.includes(response.master_contracts[key].id.toString())){
							var opacity = 'add_opacity';
						}
					}

					html += '<div class="input-group mb-3 '+opacity+'">';
						html += '<div class="input-group-prepend">';
							html += '<span class="input-group-text"><input type="radio" name="add_new_contract_radio" id="add_new_contract_radio_'+response.master_contracts[key].id+'" class="big_radio" data-type="'+response.master_contracts[key].identifier+'" value="'+response.master_contracts[key].id+'"></span>';
						html += '</div>';
						html += '<input type="text" class="form-control null_pointer" value="'+response.master_contracts[key].template_name+'" readonly_white>';
					html += '</div>';
				}
			} else {
				html += '<h3 class="text-center"> No Data Available </h3>';
				// display_error_message(response);
			}
			$('#master_contracts_div').html(html);
		}
	});
}

window.getAddNewContractDetails = function(master_contract_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method: 'GET',
		url: api_url+"master-contracts/detail/"+master_contract_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			var contract_variables = {};
			response.contract_variables.forEach(element => {
				contract_variables[element.variable_name] = element.description;
			});
			var content = response.master_contract.content;

			var variables = content.match(/{{([\w]*)}}/g);
			var content_variables = [];
			var used_variables = {}
			if(variables){
				variables.forEach(element => {
					content_variables.push(element.replace(/[^\w]/g, ''))
				});

				content_variables.forEach(element => {
					if(element in contract_variables){
						used_variables[element] = contract_variables[element];
					}
				});
			}

			openRightSidebar();
			openLoadingDialog($('#sidebar_content_area'));
			var contract_variable_html = '';
			var cnt = 0;
			contract_variable_html += '<div class="pl-3 pt-3 pr-3 fs-18 bold-black">Document For</div>';
			contract_variable_html += '<div class="col-md-12 col-lg-12">';
				if(response.master_contract.identifier == 'p_doc'){
					var document_for = 'Property Owner';
				} else if(response.master_contract.identifier == 't_doc'){
					var document_for = 'Tenant';
				} else{
					var document_for = 'Both';
				}
				contract_variable_html += '<div class="pt-3 pb-3">'+document_for+'</div>';
			contract_variable_html += '</div>';
			if(response.master_contract.description !== '' && response.master_contract.description !== null) {
				// contract_variable_html += '<div class="">'+response.master_contract.description+'</div>';
				contract_variable_html += '<div class="pl-3 pt-3 pr-3 fs-18 bold-black">Description</div>';
				contract_variable_html += '<div class="col-md-12 col-lg-12">';
					contract_variable_html += '<div class="pt-3 pb-3">'+response.master_contract.description+'</div>';
				contract_variable_html += '</div>';
			}
			if(Object.keys(used_variables).length > 0){
				contract_variable_html += '<div class="pl-3 pt-3 pr-3 fs-18 bold-black">Used Variables</div>';
				for(var key in used_variables){
					cnt++;
					contract_variable_html += '<div class="col-md-12 col-lg-12">';
						contract_variable_html += '<div class="badge badge-black text-left mr-2 mt-2">'+cnt+'. {{'+key+'}}</div>';
						contract_variable_html += '<div class="pt-3 pb-3">'+used_variables[key]+'</div>';
					contract_variable_html += '</div>';
				}
			} else{
				contract_variable_html += '<div class="pl-3 pt-3 pr-3 fs-18 bold-black">Used Variables</div>';
				contract_variable_html += '<div class="col-md-12 col-lg-12">';
					contract_variable_html += '<div class="pt-3 pb-3">No variables used.</div>';
				contract_variable_html += '</div>';
			}
			$('#sidebar_heading').html('Contract Detail');
			$('#sidebar_content_area').html(contract_variable_html);
		}
	})
}

/* Manage Master Contracts  : End */

// Docusign e-sign apis #Start

window.createContracts = function(property_id, master_contract_id, property_name, lease_id = '0'){
	var contract_name = property_name;
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&master_contract_id='+master_contract_id+'&contractName='+contract_name+'&lease_id='+lease_id;

	$.ajax({
        method: 'POST',
        url: api_url+"lease-agreements/create-contract",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			$('.add_new_lease_agreements').attr('disabled', false);
			$('.add_new_lease_agreements').text('Add');
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#addNewContractModal').modal('hide');
				getPropertyDocumentWidgetInfo();
			}
		}
	});
}

window.listContracts = function(property_id){
	var first_time_signup = $('#first_time_signup').val();

	$.ajax({
		method: 'POST',
		url: api_url+"lease-agreements/get-contracts",
		data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&first_time_signup='+first_time_signup,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			var checklist_list = '';
			if(Object.keys(response.data).length > 0) {
				for(var key in response.data){
					var kebab = '';
					var kebab_item = '';

					if(response.data[key]['status_constant_name'] == 'ds_document_draft' && user_service_name == sa_role){
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item preview_contract_in_container" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Preview Contract</a>';
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item edit_contract_in_container" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Edit Contract</a>';
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item delete_contract" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Delete Contract</a>';
					}else if(response.data[key]['status_constant_name'] == 'ds_document_completed') {
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item download_sign_contract_modal" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Download Contract</a>';
					}else if(response.data[key]['status_constant_name'] == 'ds_document_delivered' || response.data[key]['status_constant_name'] == 'ds_document_sent'){
						if(response.data[key]['lease_agreement_recipient_id'] != 0){
							if(response.data[key]['signed'] == 1){
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item sign_contract" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'" data-type="property_detail">View Signed Contract</a>';
							} else {
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item sign_contract" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'" data-type="property_detail">Sign Contract</a>';
							}
						}

						if(user_service_name == sa_role){
							kebab_item += '<a href="javascript:void(0)" class="dropdown-item void_contract_modal" data-id="'+response.data[key]["lease_agreement_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Void Contract</a>';
						}
					}

					if($.trim(kebab_item) != '') {
						kebab = '<div class="text-center document_widget_action"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					}

					var status_label = '';
					if(response.data[key]['status_constant_name'] == 'ds_document_completed') { //completed
						status_label += '<span class="badge badge-success">'+response.data[key]['status_label']+'</span>';
					} else if(response.data[key]['status_constant_name'] == 'ds_document_draft' || response.data[key]['status_constant_name'] == 'ds_document_deleted' || response.data[key]['status_constant_name'] == 'ds_document_declined' || response.data[key]['status_constant_name'] == 'ds_document_voided') { //pending for reivew
						status_label += '<span class="badge badge-danger">'+response.data[key]['status_label']+'</span>';
					} else if(response.data[key]['status_constant_name'] == 'ds_document_sent' || response.data[key]['status_constant_name'] == 'ds_document_delivered'){
						status_label += '<span class="badge badge-info">'+response.data[key]['status_label']+'</span>';
					} else {
						status_label += '<span class="badge badge-primary">'+response.data[key]['status_label']+'</span>';
					}

					if(response.data[key]['status_constant_name'] == 'ds_document_draft' || response.data[key]['status_constant_name'] == 'ds_document_sent' || response.data[key]['status_constant_name'] == 'ds_document_delivered' || response.data[key]['status_constant_name'] == 'ds_document_completed'){
						var used_master_contract = $('#used_master_contract').val();
						if(used_master_contract){
							var x = used_master_contract.split(',')
							x.push(response.data[key]['master_contract_id'])
							$('#used_master_contract').val(x.join())
						} else{
							$('#used_master_contract').val(response.data[key]['master_contract_id'])
						}
					}

					if(response.data[key]['identifier'] == 'p_doc'){
						var doc_type = 'Property Owner';
					} else if(response.data[key]['identifier'] == 't_doc'){
						var doc_type = 'Tenant';
					} else {
						var doc_type = 'Both';
					}

					var user_html = '';
					for (var akey in response.data[key].recipients) {
						if (response.data[key].recipients.hasOwnProperty(akey)) {

							var status_color = '';
							if(response.data[key].recipients[akey].application_status.statusConstantName == 'ds_recipient_completed') {
								status_color = 'green';
							}
							if(response.data[key]['status_constant_name'] != 'ds_document_draft'){
								user_html += '<div class="fs-14 mb-2">'+response.data[key].recipients[akey].recipient_email+' <br> <strong class="fs-16 '+status_color+'">'+response.data[key].recipients[akey].application_status.statusLabel+'</strong> <br>on '+response.data[key].recipients[akey].application_status.formatted_date+'</div>';
							} else{
								user_html += '<div class="fs-14 mb-2">'+response.data[key].recipients[akey].recipient_email+'</div>';
							}
						}
					}


					checklist_list += '<tr id="'+response.data[key]['lease_agreement_id']+'">';
						if(response.data[key]['status_constant_name'] == 'ds_document_draft' && signature_enabled == 'docusign'){
							checklist_list += '<td>';
								checklist_list += '<label class="custom-control custom-checkbox m-0">';
					                checklist_list += '<input type="checkbox" name="contracts_list[]" class="send_for_sign_checkbox custom-control-input" data-payment="'+response.data[key]['payment_status_label']+'" value="'+response.data[key]['lease_agreement_id']+'">';
					                checklist_list += '<span class="custom-control-label">&nbsp;</span>';
					            checklist_list += '</label>';
				            checklist_list += '</td>';
						} else {
							checklist_list += '<td>&nbsp;</td>';
						}
						checklist_list += '<td>'+response.data[key]['contract_name']+'</td>';
						checklist_list += '<td>'+doc_type+'</td>';
						checklist_list += '<td>'+user_html+'</td>';
						checklist_list += '<td>'+status_label+'</td>';
						checklist_list += '<td>&nbsp;</td>';
						checklist_list += '<td>&nbsp;</td>';
						checklist_list += '<td>'+kebab+'</td>';
					checklist_list += '</tr>';
				}
			}

			$('#property-document-data-container').append(checklist_list);
		}
	});
}

window.getContracts = function(property_id){
	var first_time_signup = $('#first_time_signup').val();

	$.ajax({
		method: 'POST',
		url: api_url+"lease-agreements/get-contracts",
		data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&first_time_signup='+first_time_signup,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(!response.data || Object.keys(response.data).length <= 0){
				if($('#existing_zoho_template').val() == 0){
					$('#contracts_accordion').removeClass('d-none');
				}
			} else {
				$('#contracts_accordion').parent().remove();

				var html = '';
				var add_opacity = '';
				var status_html = '';
				var count = 0;

				for(var key in response.data){
					if(response.data[key].lease_agreement_recipient_id){

						var name = response.data[key].contract_name;
						if (name.length > 50) {
							var shortname = name.substring(0, 50) + " ...";
							name = shortname;
						}

						html += '<div class="col-12 col-md-12 col-xl-12 d-flex mb-3">';
							html += '<div id="'+response.data[key].lease_agreement_id+'" class="border border-radius bg-white flex-fill" data-col="1">';
								html += '<div class="p-3" id="headingLease'+response.data[key].lease_agreement_id+'" data-toggle="collapse" data-target="#collapseLease'+response.data[key].lease_agreement_id+'" aria-expanded="true" aria-controls="collapseLease'+response.data[key].lease_agreement_id+'">';
									html += '<div class="row">';
										html += '<span class="mr-2 ml-2 mt-2"><img src="icon/leases.png" class="filter-5" style="width: 24px;"/></span>';
										html += '<h4 class="card-title my-2 fs-18"><a href="javascript:void(0)" class="no_underline" title="'+response.data[key].contract_name+'">'+name+'</a></h4>';
										html += '<span class="accordion_edit ml-2 mt-3"></span>';

										if(response.data[key].signed == 1 && response.data[key].status_constant_name != 'ds_document_completed'){
											html += '<span class="accordion_verfiy mr-2 mt-1">';
												html += '<img src="icon/in_progress.svg" class="align-middle mr-2 filter-14 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="In Progress"/>';
											html += '</span>';
										} else {
											html += '<span class="accordion_arrow">';
												var contract_class = '';
												var button_label = '';
												if(response.data[key].signed == 0){
													contract_class = 'sign_contract';
													button_label = 'Sign Contract';
												}

												if(response.data[key].status_constant_name == 'ds_document_completed'){
													contract_class = 'download_sign_contract_modal';
													button_label = 'Download Contract';
												}

												html += '<button type="button" class="btn btn-orange '+contract_class+' '+add_opacity+'" data-id="'+response.data[key].lease_agreement_id+'" data-type="signup" data-name="'+response.data[key].contract_name+'">'+button_label+'</button>';

												if(response.data[key].signed == 0){
													add_opacity = 'add_opacity';
												}

											html += '</span>'
										}
									html += '</div>';
								html += '</div>';
								html += '<div id="collapseLease'+response.data[key].lease_agreement_id+'" class="collapse" aria-labelledby="headingLease'+response.data[key].lease_agreement_id+'" data-parent="#accordionExample">';
								html += '</div>';
							html += '</div>';
						html += '</div>';

						count++;
						status_html += '<label class="fs-16 bold-black"> '+count+'. '+response.data[key].contract_name+'</label>';
						status_html += '<table class="table table-striped border">';
							status_html += '<tbody>';
								for(var rkey in response.data[key]['recipients']){

									if(response.data[key]['recipients'][rkey]['role'] == 'Admin'){
										response.data[key]['recipients'][rkey]['recipient_name'] = 'Rent Synergy';
									}

									var status_class = '';
									if(response.data[key]['recipients'][rkey]['application_status']['statusConstantName'] == 'ds_recipient_completed') {
										status_class = 'badge-success';
									} else {
										status_class = 'badge-warning';
									}

									status_html += '<tr>';
										status_html += '<td class="text-left bold-black fs-14">'+response.data[key]['recipients'][rkey]['recipient_name']+'</td>';
										status_html += '<td class="text-right bold-black"><span class="badge '+status_class+'">'+response.data[key]['recipients'][rkey]['application_status']['statusLabel']+'</span></td>';
									status_html += '</tr>';
								}
							status_html += '</tbody>';
						status_html += '</table>';

					}
				}

				$('#signature_pending_accordion').removeClass('d-none');
				$('.onboarding_task_to_complete').append(html);
				$('#signature_content').html(status_html);
			}
		}
	});
}

window.getUserContent = function(lease_agreement_id, type, content = '', open_from = ''){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"lease-agreements/get-user-contents",
		data : 'lease_agreement_id='+lease_agreement_id+'&user_id='+user_id+'&user_service_name='+user_service_name+'&type='+type+'&content='+content+'&open_from='+open_from,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				if(type == 'edit'){
					setTimeout(function(){
						CKEDITOR.instances['user_contract_content'].setData(response.data.content);
					}, 500);

					$('.update_user_content').attr('data-id', response.data.id);
					$('.update_user_content').attr('data-property-id', response.data.property_id);
				} else {
					if(open_from == 'edit'){
						$('.user_contract_ck_editor_div').addClass('d-none');
						$('#user_contents_container').html(response.data.content);
						$('#user_contents_container').removeClass('d-none');
						$('#preview_edited_contract').addClass('d-none');
						$('.view_contract_variables').addClass('d-none');
						$('#back_to_edit').removeClass('d-none');
						$('.card-title').html('Preview Contract Contents');
					} else{
						$('#user_contents_container').html(response.data.content);
					}
				}
			}
		}
	});
}

window.updateUserContent = function(property_id, lease_agreement_id){
	var content = CKEDITOR.instances['user_contract_content'].getData();
	$.ajax({
		method : 'POST',
		url : api_url+"lease-agreements/update-user-content",
		data : 'lease_agreement_id='+lease_agreement_id+'&user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&content='+encodeURIComponent(content),
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				var pid = getUrlParameter('pid');
				if(typeof pid !== "undefined") {
					var pid = pid.substring(11, pid.length-20);
					loadPropertyDetailLayout(pid, 'vertical', '');
				}
			}
		}
	});
}

window.deleteContract = function(id){
	$.ajax({
		method : 'POST',
		url : api_url+"lease-agreements/delete-contract",
		data : 'lease_agreement_id='+id+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				getPropertyDocumentWidgetInfo();
				$('#used_master_contract').val('');
			}
		}
	});
}

window.sendForSignContract = function(lease_agreement_ids){
	openLoadingDialog($('#property_document_widget'));
	$.ajax({
		method : 'POST',
		url : api_url+"docusigns/send-for-signature",
		data : 'lease_agreement_ids='+lease_agreement_ids+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			getPropertyDocumentWidgetInfo();
			// if(response.response_data.success == true) {
			// 	getPropertyDocumentWidgetInfo();
			// }
		}
	});
}

window.signContract = function(lease_agreement_id, return_url){
	$.ajax({
		method : 'POST',
		url : api_url+"docusigns/sign-contract",
		data : 'lease_agreement_id='+lease_agreement_id+'&user_id='+user_id+'&user_service_name='+user_service_name+'&return_url='+return_url,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.sign_contract').attr('disabled', false);
			$('.sign_contract').text('Sign Contract');
			if(response.response_data.success == true) {
				const signLink = document.createElement("a");
				signLink.href = response.sign_url;
				signLink.target = '_blank';
				signLink.click();
			} else {
				display_error_message(response);
				if(response.data == 1){
					$('#notification_container').attr('disabled', true);
					$('.sign_contract').attr('disabled', true);
					$('.sign_contract').text('Please Wait...');
					setTimeout(function() {
						loadDashboardPage();
					}, 300);
				}
			}
		}
	});
}

window.getFieldTypes = function() {
	openLoadingDialog($('#field_types_container'));
	$.ajax({
        method: 'GET',
        url: api_url+"fieldtypes",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
        		html += '<div id="fields-list" class="fields-list">';
        		for (var key in response.field_types) {
	                if (response.field_types.hasOwnProperty(key)) {
	                	var display_name = response.field_types[key].field_type_name;
	                	var field_type_id = response.field_types[key].id;
	                	var field_type_name = response.field_types[key].field_type_name;
	                	var field_category = response.field_types[key].field_category;

	                	var icon = '';
	                	var add_opacity = 'add_opacity';
	                	if(field_type_name == 'Signature') {
	                		icon = '<i class="far fa-edit field-icon"></i>';
	                		add_opacity = '';
	                	} else if(field_type_name == 'Dropdown') {
	                		icon = '<i class="far fa-caret-square-down field-icon"></i>';
	                	} else if(field_type_name == 'Attachment') {
	                		icon = '<i class="fas fa-paperclip field-icon"></i>';
	                	} else if(field_type_name == 'Email') {
	                		icon = '<i class="far fa-envelope field-icon"></i>';
	                	} else if(field_type_name == 'Company') {
	                		icon = '<i class="far fa-building field-icon"></i>';
	                	} else if(field_type_name == 'Date') {
	                		icon = '<i class="far fa-calendar-alt field-icon"></i>';
	                		add_opacity = '';
	                	} else if(field_type_name == 'Textfield') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Text';
	                	} else if(field_type_name == 'Jobtitle') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Job Title';
	                	} else if(field_type_name == 'Initial') {
	                		icon = '<i class="fas fa-signature field-icon"></i>';
	                	} else if(field_type_name == 'Name') {
	                		icon = '<i class="fas fa-male field-icon"></i>';
	                		display_name = 'Name';
	                		add_opacity = '';
	                	} else if(field_type_name == 'Stamp') {
	                		icon = '<i class="fas fa-stamp field-icon"></i>';
	                	} else if(field_type_name == 'Checkbox') {
	                		icon = '<i class="far fa-check-square field-icon"></i>';
	                	} else if(field_type_name == 'Radiogroup') {
	                		icon = '<i class="far fa-dot-circle field-icon"></i>';
	                		display_name = 'Radio';
	                	}

						html += '<div class="field-li '+add_opacity+'">';
							html += '<div id="'+field_type_name+'" field-type-id="'+field_type_id+'" field-category="'+field_category+'" name="'+field_type_name+'" class="drag field-li-view">';
									html += '<i class="fas fa-grip-vertical icon-move drag-icon"></i>';
									html += '<div class="field_label" title="'+field_type_name+'">'+display_name+'</div>';
									html += icon;
							html += '</div>';
						html += '</div>';
					}
				}
				html += '</div>';

				$('#field_types_container').html(html);

				$(".field-li-view").draggable({
			  		helper: "clone",
			  		cursor: 'move',
				    tolerance: 'fit',
				    revert: true,
				    start: function( event, ui ) {
				    	var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
				    	$(ui.helper).css({'border': '1px dashed '+border_color});
				    	$(ui.helper).height(35);
				    	$(ui.helper).css('line-height','35px !important');
				    	$(ui.helper).find('div.field_label').css({'position' : 'relative','top': '20%'});

				    	$(ui.helper).find('.drag-icon').remove();
						$(ui.helper).find('.field-icon').remove();
				    }
			    });

				// Change color of fields section
			    /*setTimeout(function(){
					var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
					$('#field_header').css({'background': border_color, 'color': 'white'});
					$('.field-li-view').css({'border': '1px solid '+border_color});
					$('.field-li-view .drag-icon').css({'color': border_color});
					$('.field-li-view .field-icon').css({'background-color': border_color});
				}, 500);*/
            }
        }
    });
}

window.getPendingSignatureContractDetails = function(pendingDocusignContracts){
	var notification_html = "";
	if(Object.keys(pendingDocusignContracts.data).length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/master_contracts.svg" class="filter-15" style="width: 23px;"/></span> Contracts</div>';
			notification_html += '</div>';
		notification_html += '</div>';

		for (var key in pendingDocusignContracts.data) {
			var name = '';
			var address = '';
			if(pendingDocusignContracts.data[key].property != null && pendingDocusignContracts.data[key].property.address != null) {
				name = stripHtml(pendingDocusignContracts.data[key].property.name);
				address = pendingDocusignContracts.data[key].property.address.address_line1+', '+pendingDocusignContracts.data[key].property.address.address_line2+'<br>'+pendingDocusignContracts.data[key].property.address.city+', '+pendingDocusignContracts.data[key].property.address.state+' '+pendingDocusignContracts.data[key].property.address.zip_code;
			}

			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="lease_agreement_'+pendingDocusignContracts.data[key].lease_agreement_id+'" class="notifcation-group-item list-group-item document_review_card no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						//notification_html += '<div class="col-2"> <img src="icon/notification/bank.svg" class="filter-7"> </div>';
						notification_html += '<div class="col-12">';
							notification_html += '<div class="text-dark">'+pendingDocusignContracts.data[key].lease_agreement.contract_name+'</div>';
							notification_html += '<div class="text-muted small mt-1">'+address+'</div>';
							notification_html += '<div class="mt-2 mb-2">Document <strong>'+pendingDocusignContracts.data[key].lease_agreement.contract_name+'</strong> available for your signature. Click link below to sign document</div>';
							notification_html += '<button data-contract-name="'+pendingDocusignContracts.data[key].lease_agreement.contract_name+'" data-id="'+pendingDocusignContracts.data[key].lease_agreement_id+'" data-type="properties" type="button" class="btn btn-orange sign_contract w-100">Sign Contract</button>';
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

// window.getPendingSignatureContractDetails = function(){
// 	$.ajax({
// 		method: 'POST',
// 		url: api_url+"lease-agreements/get-pending-signature-contract",
// 		data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
// 		beforeSend: function(xhr) {
// 			xhr.setRequestHeader('Authorization', accessToken);
// 		},
// 		success: function(response) {
// 			if(response.status == "SUCCESS") {
// 				var notification_html = "";
// 				if(Object.keys(response.data).length > 0){
// 					for (var key in response.data) {
// 						var name = '';
// 						var address = '';
// 						if(response.data[key].property != null && response.data[key].property.address != null) {
// 							name = stripHtml(response.data[key].property.name);
// 							address = response.data[key].property.address.address_line1+', '+response.data[key].property.address.address_line2+'<br>'+response.data[key].property.address.city+', '+response.data[key].property.address.state+' '+response.data[key].property.address.zip_code;
// 						}

// 						notification_html += '<div id="lease_agreement_'+response.data[key].lease_agreement_id+'" class="notifcation-group-item list-group-item document_review_card">';
// 							notification_html += '<div class="row no-gutters align-items-center">';
// 								//notification_html += '<div class="col-2"> <img src="icon/notification/bank.svg" class="filter-7"> </div>';
// 								notification_html += '<div class="col-12">';
// 									notification_html += '<div class="text-dark">'+response.data[key].lease_agreement.contract_name+'</div>';
// 									notification_html += '<div class="text-muted small mt-1">'+address+'</div>';
// 									notification_html += '<div class="mt-2 mb-2">Document <strong>'+response.data[key].lease_agreement.contract_name+'</strong> available for your signature. Click link below to sign document</div>';
// 									notification_html += '<button data-contract-name="'+response.data[key].lease_agreement.contract_name+'" data-id="'+response.data[key].lease_agreement_id+'" data-type="properties" type="button" class="btn btn-orange sign_contract">Sign Contract</button>';
// 								notification_html += '</div>';
// 							notification_html += '</div>';
// 						notification_html += '</div>';
// 					}
// 					$('#sidebar_content_area').append(notification_html);
// 				}
// 			}
// 		}
// 	});
// }

// Docusign e-sign apis #End

// Zoho Document e-sign apis #Start

window.createZohoDocument = function(property_id, master_contract_id, property_name, lease_id = 0){
	var contract_name = property_name;
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&master_contract_id='+master_contract_id+'&contractName='+contract_name+'&lease_id='+lease_id;

	$.ajax({
        method: 'POST',
        url: api_url+"zoho-documents/create",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			$('.add_new_lease_agreements').attr('disabled', false);
			$('.add_new_lease_agreements').text('Add');
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#addNewContractModal').modal('hide');
				getPropertyDocumentWidgetInfo();
			}
		}
	});
}

window.sendZohoDocumentForSignature = function(document_ids){
	openLoadingDialog($('#property_document_widget'));
	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/send-for-signature",
		data : 'zoho_document_ids='+document_ids+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			getPropertyDocumentWidgetInfo();
			// if(response.response_data.success == true) {
			// 	getPropertyDocumentWidgetInfo();
			// }
		}
	});
}


window.getZohoDocuments = function(property_id){
	var first_time_signup = $('#first_time_signup').val();

	$.ajax({
		method: 'POST',
		url: api_url+"zoho-documents",
		data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&first_time_signup='+first_time_signup,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(!response.data || Object.keys(response.data).length <= 0){
				if($('#existing_zoho_template').val() == 0){
					$('#contracts_accordion').removeClass('d-none');
				}
			} else {
				$('#contracts_accordion').parent().remove();

				var html = '';
				var add_opacity = '';
				var status_html = '';
				var count = 0;

				for(var key in response.data){
					if(response.data[key].zoho_document_recipient_id){

						var name = response.data[key].contract_name;
						if (name.length > 50) {
							var shortname = name.substring(0, 50) + " ...";
							name = shortname;
						}

						html += '<div class="col-12 col-md-12 col-xl-12 d-flex mb-3">';
							html += '<div id="'+response.data[key].zoho_document_id+'" class="border border-radius bg-white flex-fill" data-col="1">';
								html += '<div class="p-3" id="headingLease'+response.data[key].zoho_document_id+'" data-toggle="collapse" data-target="#collapseLease'+response.data[key].zoho_document_id+'" aria-expanded="true" aria-controls="collapseLease'+response.data[key].zoho_document_id+'">';
									html += '<div class="row">';
										html += '<span class="mr-2 ml-2 mt-2"><img src="icon/leases.png" class="filter-5" style="width: 24px;"/></span>';
										html += '<h4 class="card-title my-2 fs-18"><a href="javascript:void(0)" class="no_underline" title="'+response.data[key].contract_name+'">'+name+'</a></h4>';
										html += '<span class="accordion_edit ml-2 mt-3"></span>';

										if(response.data[key].zoho_document_recipient_signed == 1 && response.data[key].status_constant_name != 'zd_document_completed'){
											html += '<span class="accordion_verfiy mr-2 mt-1">';
												html += '<img src="icon/in_progress.svg" class="align-middle mr-2 filter-14 pt-1" style="width: 20px;" data-toggle="tooltip" data-placement="bottom" data-original-title="In Progress"/>';
											html += '</span>';
										} else {
											html += '<span class="accordion_arrow">';
												var contract_class = '';
												var button_label = '';
												if(response.data[key].zoho_document_recipient_signed == 0){
													contract_class = 'sign_zoho_document';
													button_label = 'Sign Contract';
												}

												if(response.data[key].status_constant_name == 'zd_document_completed'){
													contract_class = 'download_zoho_contract_modal';
													button_label = 'Download Contract';
												}

												html += '<button type="button" class="btn btn-orange '+contract_class+' '+add_opacity+'" data-id="'+response.data[key].zoho_document_id+'" data-type="signup" data-name="'+response.data[key].contract_name+'" data-status="'+response.data[key].status_constant_name+'">'+button_label+'</button>';

												if(response.data[key].signed == 0){
													add_opacity = 'add_opacity';
												}

											html += '</span>'
										}
									html += '</div>';
								html += '</div>';
								html += '<div id="collapseLease'+response.data[key].zoho_document_id+'" class="collapse" aria-labelledby="headingLease'+response.data[key].zoho_document_id+'" data-parent="#accordionExample">';
								html += '</div>';
							html += '</div>';
						html += '</div>';

						count++;
						status_html += '<label class="fs-16 bold-black"> '+count+'. '+response.data[key].contract_name+'</label>';
						status_html += '<table class="table table-striped border">';
							status_html += '<tbody>';
								for(var rkey in response.data[key]['recipients']){

									if(response.data[key]['recipients'][rkey]['role'] == 'Admin'){
										response.data[key]['recipients'][rkey]['recipient_name'] = 'Rent Synergy';
									}

									var status_class = '';
									if(response.data[key]['recipients'][rkey]['application_status']['statusConstantName'] == 'zd_recipient_signed') {
										status_class = 'badge-success';
									} else {
										status_class = 'badge-warning';
									}

									status_html += '<tr>';
										status_html += '<td class="text-left bold-black fs-14">'+response.data[key]['recipients'][rkey]['recipient_name']+'</td>';
										status_html += '<td class="text-right bold-black"><span class="badge '+status_class+'">'+response.data[key]['recipients'][rkey]['application_status']['statusLabel']+'</span></td>';
									status_html += '</tr>';
								}
							status_html += '</tbody>';
						status_html += '</table>';

					}
				}

				$('#signature_pending_accordion').removeClass('d-none');
				$('.onboarding_task_to_complete').append(html);
				$('#signature_content').html(status_html);
			}
		}
	});
}

window.listZohoDocuments = function(property_id){
	var first_time_signup = $('#first_time_signup').val();

	$.ajax({
		method: 'POST',
		url: api_url+"zoho-documents",
		data: 'user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&first_time_signup='+first_time_signup,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			var checklist_list = '';
			if(Object.keys(response.data).length > 0) {
				for(var key in response.data){
					var kebab = '';
					var kebab_item = '';

					if(response.data[key]['status_constant_name'] == 'zd_document_draft' && user_service_name == sa_role){
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item preview_contract_in_container" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Preview Contract</a>';
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item edit_contract_in_container" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Edit Contract</a>';
						kebab_item += '<a href="javascript:void(0)" class="dropdown-item delete_contract" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Delete Contract</a>';
					} else if(response.data[key]['status_constant_name'] == 'zd_document_sent' || response.data[key]['status_constant_name'] == 'zd_document_inprogress'){
						if(response.data[key]['zoho_document_recipient_id'] != 0){
							if(response.data[key]['zoho_document_recipient_signed'] == 1){
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item sign_zoho_document" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'" data-type="property_detail">View Signed Contract</a>';
							} else {
								kebab_item += '<a href="javascript:void(0)" class="dropdown-item sign_zoho_document" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'" data-type="property_detail">Sign Contract</a>';
							}
						}

						if(user_service_name == sa_role){
							kebab_item += '<a href="javascript:void(0)" class="dropdown-item recall_contract_modal" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Void Contract</a>';
						}
					}
					// else if(response.data[key]['status_constant_name'] == 'zd_document_completed') {
					// 	kebab_item += '<a href="javascript:void(0)" class="dropdown-item download_zoho_contract_modal" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Download Contract</a>';
					// }

					kebab_item += '<a href="javascript:void(0)" class="dropdown-item download_zoho_contract_modal" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'" data-status="'+response.data[key]['status_constant_name']+'">Download Contract</a>';

					if(response.data[key]['status_constant_name'] == 'zd_document_inprogress' || response.data[key]['status_constant_name'] == 'zd_document_expired'){
						if(user_service_name == sa_role){
							kebab_item += '<a href="javascript:void(0)" class="dropdown-item extend_document_modal" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'" expiration-date="'+response.data[key]["expiration_date"]+'">Extend Contract</a>';
						}
					}

					if(response.data[key]['status_constant_name'] == 'zd_document_inprogress'){
						if(user_service_name == sa_role){
							kebab_item += '<a href="javascript:void(0)" class="dropdown-item remind_zoho_document" data-id="'+response.data[key]["zoho_document_id"]+'" data-name="'+response.data[key]["contract_name"]+'">Send Reminder</a>';
						}
					}

					if($.trim(kebab_item) != '') {
						kebab = '<div class="text-center document_widget_action"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					}

					var status_label = '';
					if(response.data[key]['status_constant_name'] == 'zd_document_completed') { //completed
						status_label += '<span class="badge badge-success">'+response.data[key]['status_label']+'</span>';
					} else if(response.data[key]['status_constant_name'] == 'zd_document_draft' || response.data[key]['status_constant_name'] == 'zd_document_expired' || response.data[key]['status_constant_name'] == 'zd_document_recalled' || response.data[key]['status_constant_name'] == 'zd_document_declined' || response.data[key]['status_constant_name'] == 'zd_document_deleted') { //pending for reivew
						status_label += '<span class="badge badge-danger">'+response.data[key]['status_label']+'</span>';
					} else if(response.data[key]['status_constant_name'] == 'zd_document_sent' || response.data[key]['status_constant_name'] == 'zd_document_inprogress'){
						status_label += '<span class="badge badge-info">'+response.data[key]['status_label']+'</span>';
					} else {
						status_label += '<span class="badge badge-primary">'+response.data[key]['status_label']+'</span>';
					}

					if(response.data[key]['status_constant_name'] == 'zd_document_draft' || response.data[key]['status_constant_name'] == 'zd_document_sent' || response.data[key]['status_constant_name'] == 'zd_document_inprogress' || response.data[key]['status_constant_name'] == 'zd_document_completed'){
						var used_master_contract = $('#used_master_contract').val();
						if(used_master_contract){
							var x = used_master_contract.split(',')
							x.push(response.data[key]['master_contract_id'])
							$('#used_master_contract').val(x.join());
						} else{
							$('#used_master_contract').val(response.data[key]['master_contract_id']);
						}
					}

					if(response.data[key]['identifier'] == 'p_doc'){
						var doc_type = 'Property Owner';
					} else if(response.data[key]['identifier'] == 't_doc'){
						var doc_type = 'Tenant';
					} else {
						var doc_type = 'Both';
					}

					var user_html = '';
					for (var akey in response.data[key].recipients) {
						if (response.data[key].recipients.hasOwnProperty(akey)) {

							var status_color = '';
							if(response.data[key].recipients[akey].application_status.statusConstantName == 'zd_recipient_signed') {
								status_color = 'green';
							}
							if(response.data[key]['status_constant_name'] != 'zd_document_draft'){
								user_html += '<div class="fs-14 mb-2">'+response.data[key].recipients[akey].recipient_email+' <br> <strong class="fs-16 '+status_color+'">'+response.data[key].recipients[akey].application_status.statusLabel+'</strong> <br>on '+response.data[key].recipients[akey].application_status.formatted_date+'</div>';
							} else{
								user_html += '<div class="fs-14 mb-2">'+response.data[key].recipients[akey].recipient_email+'</div>';
							}
						}
					}


					checklist_list += '<tr id="'+response.data[key]['zoho_document_id']+'">';
						if(response.data[key]['status_constant_name'] == 'zd_document_draft' && signature_enabled == 'zoho_document'){
							checklist_list += '<td>';
								checklist_list += '<label class="custom-control custom-checkbox m-0">';
					                checklist_list += '<input type="checkbox" name="contracts_list[]" class="send_for_sign_checkbox custom-control-input" data-payment="'+response.data[key]['payment_status_label']+'" value="'+response.data[key]['zoho_document_id']+'">';
					                checklist_list += '<span class="custom-control-label">&nbsp;</span>';
					            checklist_list += '</label>';
				            checklist_list += '</td>';
						} else {
							checklist_list += '<td>&nbsp;</td>';
						}
						checklist_list += '<td>'+response.data[key]['contract_name']+'</td>';
						checklist_list += '<td>'+doc_type+'</td>';
						checklist_list += '<td>'+user_html+'</td>';
						checklist_list += '<td>'+status_label+'</td>';
						checklist_list += '<td>&nbsp;</td>';
						checklist_list += '<td>&nbsp;</td>';
						checklist_list += '<td>'+kebab+'</td>';
					checklist_list += '</tr>';
				}
			}

			$('#property-document-data-container').append(checklist_list);
		}
	});
}

window.previewMasterContractContent = function(content = '', master_contract_id = 0, payment_schedule_id = 0, container = '', open_from = ''){
	var user_id = user.user_details.user_id;
	var document_name = $('#master_contract_name').val();
	$.ajax({
		method : 'POST',
		url : api_url+"master-contracts/preview-master-contract-content",
		data : 'user_id='+user_id+'&user_service_name='+user_service_name+'&document_name='+document_name+'&master_contract_id='+master_contract_id+'&payment_schedule_id='+payment_schedule_id+'&open_from='+open_from+'&content='+content,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				setTimeout(function(){
					var document_name = response.data.document_name;
					$('.preview_title').html(document_name);
					var container_id = document.getElementById(container);
					var document_base64 = response.data.file_base64;
					if(open_from == 'reports'){
						$('.download_eviction_document').attr('href', document_base64);
						$('.download_eviction_document').attr('download', document_name+'.pdf');
					}
					
					// Remove data URI scheme and convert Base64 to Uint8Array
					var base64String = document_base64;
					var pdfData = base64String.split(',')[1];
					var uint8Array = base64ToUint8Array(pdfData);

					loadPDFPreview(uint8Array, document_name, container_id);

				}, 3000);
			}
		}
	});
}

window.getZohoDocumentContent = function(zoho_document_id, type, content = '', open_from = ''){
	var user_id = user.user_details.user_id;

	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/get-zoho-document-contents",
		data : 'zoho_document_id='+zoho_document_id+'&user_id='+user_id+'&user_service_name='+user_service_name+'&type='+type+'&content='+content+'&open_from='+open_from,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				if(type == 'edit'){
					$('.update_user_content').attr('data-id', response.data.id);
					$('.update_user_content').attr('data-property-id', response.data.property_id);
					$('.user_contents_container').addClass('d-none');

					setTimeout(function(){
						CKEDITOR.instances['user_contract_content'].setData(response.data.content);
					}, 500);
				} else {
					if(open_from == 'edit'){
						$('.user_contract_ck_editor_div').addClass('d-none');
						//$('#user_contents_container').html(response.data.content);
						$('.user_contents_container').removeClass('d-none');
						$('#preview_edited_contract').addClass('d-none');
						$('.view_contract_variables').addClass('d-none');
						$('#back_to_edit').removeClass('d-none');
						$('.card-title').html('Preview Contract Contents');
					} else{
						// $('#user_contents_container').html(html);
						// $('#user_contents_container').html(response.data.content);
					}

					openLoadingDialog($('#user_contents_container'));
					setTimeout(function(){
						var container = document.getElementById("user_contents_container");
						var document_base64 = response.data.file_base64;
						
						// Remove data URI scheme and convert Base64 to Uint8Array
						var base64String = document_base64;
						var pdfData = base64String.split(',')[1];
						var uint8Array = base64ToUint8Array(pdfData);

						loadPDFPreview(uint8Array, response.data.contract_name, container);

					}, 3000);
				}
			}
		}
	});
}

// Function to convert date format
window.convertDate = function(inputDate) {
    var date = new Date(inputDate);
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero and get month in mm format
    var day = ("0" + date.getDate()).slice(-2); // Add leading zero and get day in dd format
    var year = date.getFullYear(); // Get year in yyyy format
    return month + "/" + day + "/" + year; // Combine to mm/dd/yyyy format
}

// Function to convert Base64 to Uint8Array
window.base64ToUint8Array = function(base64){
	var raw = atob(base64);
	var uint8Array = new Uint8Array(raw.length);
	for (var i = 0; i < raw.length; i++) {
		uint8Array[i] = raw.charCodeAt(i);
	}
	return uint8Array;
}

window.loadPDFPreview = function(uint8Array, document_name, container) {
	var scale = 3;
	var thePdf = '';
    pdfjsLib.getDocument({ data: uint8Array }).promise.then(function(pdf) {
        thePdf = pdf;
        for(var page = 1; page <= pdf.numPages; page++) {
            // Create div element in container
            var div = document.createElement("div");
            div.classList.add("image-header");
            container.appendChild(div);
            
            // Create footer element and append to main div
            var footer = document.createElement("div");
            footer.classList.add("pdf_footer");
            footer.innerHTML = "<div><div style='float:left;'>"+document_name+"</div><div class='text-right'>"+page+"/"+pdf.numPages+"</div></div>";
            div.after(footer);

                // Create canvas for pdf file
            var canvas = document.createElement('canvas');
            div.appendChild(canvas);
            renderPage(page, canvas);
        }
    });

    function renderPage(pageNumber, canvas) {
        thePdf.getPage(pageNumber).then(function(page) {
            var viewport = page.getViewport({
                scale: scale
            });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.classList.add("canvas-page");
            canvas.style.width = '100%';
            canvas.style.height = canvas_height+'px';
            // Render PDF page into canvas context
            var renderContext = {
                canvasContext: canvas.getContext('2d'),
                viewport: viewport
            };
            var renderTask = page.render(renderContext);
        });
    }

	Scrollbar.initAll({damping: .1,thumbMinSize: 20,renderByPixels: true,continuousScrolling: 'auto',alwaysShowTracks: false,wheelEventTarget: null,plugins: {}});
    setTimeout(function(){
        $('.spinner-border').remove();
    }, 2000);
}

window.updateZohoDocumentContent = function(property_id, zoho_document_id){
	var content = CKEDITOR.instances['user_contract_content'].getData();
	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/update-zoho-document-content",
		data : 'zoho_document_id='+zoho_document_id+'&user_id='+user_id+'&user_service_name='+user_service_name+'&property_id='+property_id+'&content='+encodeURIComponent(content),
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				var pid = getUrlParameter('pid');
				if(typeof pid !== "undefined") {
					var pid = pid.substring(11, pid.length-20);
					loadPropertyDetailLayout(pid, 'vertical', '');
				}
			}
		}
	});
}

window.deleteZohoContract = function(id){
	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/delete",
		data : 'zoho_document_id='+id+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
        	if(response.response_data.success == true) {
				getPropertyDocumentWidgetInfo();
				$('#used_master_contract').val('');
			}
		}
	});
}

window.signZohoDocument = function(zoho_document_id, return_url){
	$.ajax({
		method : 'POST',
		url : api_url+"zoho-documents/sign-zoho-document",
		data : 'zoho_document_id='+zoho_document_id+'&user_id='+user_id+'&user_service_name='+user_service_name+'&return_url='+return_url,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			$('.sign_zoho_document').attr('disabled', false);
			$('.sign_zoho_document').text('Sign Contract');
			if(response.response_data.success == true) {
				const signLink = document.createElement("a");
				signLink.href = response.sign_url;
				signLink.target = '_blank';
				signLink.click();
			} else {
				display_error_message(response);
				if(response.data == 1){
					$('#notification_container').attr('disabled', true);
					$('.sign_zoho_document').attr('disabled', true);
					$('.sign_zoho_document').text('Please Wait...');
					setTimeout(function() {
						loadDashboardPage();
					}, 300);
				}
			}
		}
	});
}

window.getDocumentSignUrl = function(sign_request_id, sign_action_id){
	var url = api_url+"app/get-document-sign-url";
	$.ajax({
		method : 'POST',
		url : url,
		data : 'sign_request_id='+sign_request_id+'&sign_action_id='+sign_action_id,
		success: function(response) {
			if(response.sign_url.status == 'failure') {
				$('.lottie_container').html('<div style="font-size:26px;color:red;font-weight:bold">'+response.sign_url.message+'</div>');
				return false;
			} else {
				const signLink = document.createElement("a");
				signLink.href = response.sign_url.sign_url;
				signLink.click();
			}
		}
	});
}

window.getPendingZohoDocuments = function(pendingZohoDocuments){
	var notification_html = "";
	if(Object.keys(pendingZohoDocuments.data).length > 0){
		notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
			notification_html += '<div class="col-12 col-md-12">';
				notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/master_contracts.svg" class="filter-15" style="width: 23px;"/></span> Contracts</div>';
			notification_html += '</div>';
		notification_html += '</div>';

		for (var key in pendingZohoDocuments.data) {
			var name = '';
			var address = '';
			if(pendingZohoDocuments.data[key].property != null && pendingZohoDocuments.data[key].property.address != null) {
				name = stripHtml(pendingZohoDocuments.data[key].property.name);
				address = pendingZohoDocuments.data[key].property.address.address_line1+', '+pendingZohoDocuments.data[key].property.address.address_line2+'<br>'+pendingZohoDocuments.data[key].property.address.city+', '+pendingZohoDocuments.data[key].property.address.state+' '+pendingZohoDocuments.data[key].property.address.zip_code;
			}
			notification_html += '<div class="card light-border m-3">';
				notification_html += '<div id="zoho_document_'+pendingZohoDocuments.data[key].zoho_document_id+'" class="notifcation-group-item list-group-item document_review_card no_border">';
					notification_html += '<div class="row no-gutters align-items-center">';
						notification_html += '<div class="col-12">';
							notification_html += '<div class="mt-2 mb-3">Document <strong>'+pendingZohoDocuments.data[key].zoho_document.contract_name+'</strong> available for your signature. Click link below to sign document</div>';
							notification_html += '<button data-contract-name="'+pendingZohoDocuments.data[key].zoho_document.contract_name+'" data-id="'+pendingZohoDocuments.data[key].zoho_document_id+'" data-type="properties" type="button" class="btn btn-orange w-100 sign_zoho_document">Sign Contract</button>';
						notification_html += '</div>';
					notification_html += '</div>';
				notification_html += '</div>';
			notification_html += '</div>';
		}
	}
	return notification_html;
}

// window.getPendingZohoDocuments = function(){
// 	$.ajax({
// 		method: 'POST',
// 		url: api_url+"zoho-documents/get-pending-zoho-documents",
// 		data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
// 		beforeSend: function(xhr) {
// 			xhr.setRequestHeader('Authorization', accessToken);
// 		},
// 		success: function(response) {
// 			if(response.response_data.success == true) {
// 				var notification_html = "";
// 				if(Object.keys(response.data).length > 0){

// 					notification_html += '<div class="row mt-1 mr-1 mb-1 ml-0 bg-light height-60">';
// 						notification_html += '<div class="col-12 col-md-12">';
// 							notification_html += '<div class="card-title fs-18 bold-black"><span class="mr-2 mt-2"><img src="icon/master_contracts.svg" class="filter-15" style="width: 23px;"/></span> Contracts</div>';
// 						notification_html += '</div>';
// 					notification_html += '</div>';

// 					for (var key in response.data) {
// 						var name = '';
// 						var address = '';
// 						if(response.data[key].property != null && response.data[key].property.address != null) {
// 							name = stripHtml(response.data[key].property.name);
// 							address = response.data[key].property.address.address_line1+', '+response.data[key].property.address.address_line2+'<br>'+response.data[key].property.address.city+', '+response.data[key].property.address.state+' '+response.data[key].property.address.zip_code;
// 						}
// 						notification_html += '<div class="card light-border m-3">';
// 							notification_html += '<div id="zoho_document_'+response.data[key].zoho_document_id+'" class="notifcation-group-item list-group-item document_review_card no_border">';
// 								notification_html += '<div class="row no-gutters align-items-center">';
// 									notification_html += '<div class="col-12">';
// 										notification_html += '<div class="mt-2 mb-3">Document <strong>'+response.data[key].zoho_document.contract_name+'</strong> available for your signature. Click link below to sign document</div>';
// 										notification_html += '<button data-contract-name="'+response.data[key].zoho_document.contract_name+'" data-id="'+response.data[key].zoho_document_id+'" data-type="properties" type="button" class="btn btn-orange w-100 sign_zoho_document">Sign Contract</button>';
// 									notification_html += '</div>';
// 								notification_html += '</div>';
// 							notification_html += '</div>';
// 						notification_html += '</div>';
// 					}
// 					$('#sidebar_content_area').append(notification_html);
// 				}
// 			}
// 		}
// 	});
// }

// Zoho Document e-sign apis #End

window.getSignupZohoTemplateList = function(property_id) {
	var user_id = user.user_details.user_id;
	var first_time_signup = $('#first_time_signup').val();

	$.ajax({
	    method: 'POST',
	    url: api_url+"user-templates",
	    data:'property_id='+property_id+'&tenant_ids='+user_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTemplates == 0) {
	        	$('#zoho_lease_accordion').parent().remove();
				$('#existing_zoho_template').val(0);
		    } else {
				$('#headingZohoLease span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i>');
				$('#zoho_lease_accordion').removeClass('d-none');
				$('#existing_zoho_template').val(1);
	        }

			if(signature_enabled == 'docusign'){
				getContracts(property_id);
			} else if(signature_enabled == 'zoho_document'){
				getZohoDocuments(property_id);
			}
        }
    }, function() {
        // hang on this step if the error occur
    });
}

/* Status Master code start */

var $statusMasterForm;
window.addStatusMasterValidation = function() {
	$statusMasterForm = $("#status_master_add_form, #status_master_edit_form");
	$statusMasterForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "constant_name": { required: true},
	        "label": { required: true},
	        "message": { required: true, email: true},
	        "description": { required: true},
	        "transaction_type_id": { required: true}
	    },
	    messages: {
	        "constant_name": { required: ''},
	        "label": { required: ''},
	        "message": { required: ''},
	        "description": { required: ''},
	        "transaction_type_id": { required: ''}
	    }
	});
}

window.saveStatusMasterData = function() {
	if($statusMasterForm.valid()){
		$('.save_status_master_data').attr('disabled',true);

		var constant_name = $('#constant_name').val();
		var label = $('#label').val();
		var message = $('#message').val();
		var description = $('#description').val();
		var transaction_type_id = $('#transaction_type_id').val();

		var data = 'constant_name='+constant_name+'&label='+label+'&message='+message+'&description='+description+'&transaction_type_id='+transaction_type_id;

        $.ajax({
            method: 'POST',
            url: api_url+"status-masters/add",
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_status_master_data').attr('disabled',false);
                if(response.response_data.success == true) {

                }
            }
        });
    }
}

window.getUserActivities = function(user_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"user-activities/list",
        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone+'&user_service_name='+user_service_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
			if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.userActivities) {
	                if (response.userActivities.hasOwnProperty(key)) {
						html += '<tr><td colspan="3">';
	                		html += '<div class="fs-16 bold-black">'+key+'</div>';
						html += '</td></tr>';
	                	for (var jkey in response.userActivities[key]) {
	                		var username = '';
	                		if(response.userActivities[key][jkey].user != null) {
	                			username = response.userActivities[key][jkey].user.first_name+' '+response.userActivities[key][jkey].user.last_name
	                		}
		                	var module_name = response.userActivities[key][jkey].module_name;
							var message = response.userActivities[key][jkey].message;
		                	var created1 = response.userActivities[key][jkey].created1;
							var created_usertimezone = response.userActivities[key][jkey].created_usertimezone;
							// html += '<tr><td>';
							// 	html += '<div calss="card">';
							// 			html += '<div class="media">';
							// 				html += '<div><img src="icon/avatar.jpg" width="24" height="24" class="rounded-circle mr-2" alt=""></div>';
							// 				html += '<div class="media-body">';
							// 					html += '<strong>'+username+'</strong> '+wordWrap(message,100);
							// 					html += '<small class="float-right text-navy">'+created1+'</small>';
							// 				html += '</div>';
							// 			html += '</div>';
							// 	html += '</div>';
							// html += '</td></tr>';
							html += '<tr>'
								html += '<td>'+username+'</td>';
								html += '<td>'+module_name+'</td>';
								html += '<td>'+wordWrap(message,100)+'</td>';
								html += '<td>'+created_usertimezone+'</td>';
							html += '</tr>';
					    }
	                }
	            }
	            if(user_id > 0) {
	            	$('#audit_data_container').html(html);
	            }
            }
        }
    });
}

const wordWrap = (str, max, br = '<br>') => str.replace(
	new RegExp(`(?![^\\n]{1,${max}}$)([^\\n]{1,${max}})\\s`, 'g'), '$1' + br
);

window.getLinkedAccount = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"linked-accounts",
        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	var head_html = '';
        	var html = '';
        	if(response.response_data.success == true) {
        		html += '<table id="linked_account_table" class="table table-striped" style="width:100%">';
        			html += '<thead>';
	        			html += '<tr>';
	        			html += '<th>Id</th>';
	        			html += '<th>From</th>';
	        			html += '<th>To</th>';
	        			html += '<th>Services</th>';
	        			html += '<th>Created</th>';
	        			html += '<th>Status</th>';
	        			html += '<th>Action</th>';
	        			html += '</tr>';
	        		html += '</thead>';
        			html += '<tbody>';
        			if(response.TotalLinkedAccounts == 0) {
        				html += '<tr>';
		                    html += '<td class="text-center" colspan="7"><strong>'+response.response_data.message+'</strong></td>';
	                    html += '</tr>';
        			} else {
        				head_html += '<div class="dropdown-divider"></div>';
        				head_html += '<div class="fs-16 bold-grey pl-4">Linked Accounts</div>';
        				head_html += '<div class="dropdown-divider"></div>';
		    			for (var key in response.linkedAccounts) {
			                if (response.linkedAccounts.hasOwnProperty(key)) {
			                	var from_user = response.linkedAccounts[key].from_user.first_name+' '+response.linkedAccounts[key].from_user.last_name+'<br><small>'+response.linkedAccounts[key].from_user.email+'</small>';
			                	var to_user = response.linkedAccounts[key].to_user.first_name+' '+response.linkedAccounts[key].to_user.last_name+'<br><small>'+response.linkedAccounts[key].to_user.email+'</small>';
			                	html += '<tr>';
				                    html += '<td>'+response.linkedAccounts[key].id+'</td>';
				                    html += '<td>'+from_user+'</td>';
				                    html += '<td>'+to_user+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].services+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].created+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].status_label+'</td>';
				                    html += '<td><a href="javascript:void(0)" data-id="'+response.linkedAccounts[key].id+'" class="unlink_account">Unlink</a></td>';
			                    html += '</tr>';

			                    var linked_user = response.linkedAccounts[key].to_user.first_name+' '+response.linkedAccounts[key].to_user.last_name+' ('+response.linkedAccounts[key].services+')<br><small>'+response.linkedAccounts[key].to_user.email+'</small>';
			                    head_html += '<div data-id="'+response.linkedAccounts[key].id+'" data-email="'+response.linkedAccounts[key].to_user.email+'" class="dropdown-item bold-grey switch_user">';
			                    	head_html += linked_user;
			                    	head_html += '<span><i data-type="down" class="float-right fas fa-fw fa-angle-down"></i></span>';
			                    head_html += '</div>';
			                }
			            }
			        }
		        	html += '</tbody>';
		        html += '</table>';

		        $('#linked_account_data_container').html(html);
		        $('#header_linked_account_container').html(head_html);

		        var linked_account_table = $("#linked_account_table").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '650px',
				    scrollX: true,
			        scrollCollapse: true,
			        order: [[0,'desc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
			           {
			              extend: 'colvis',
			              text: "Columns Visibility ",
			              collectionLayout: 'two-column',
			              postfixButtons: ['colvisRestore'],
			           }
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
					initComplete: function () {
			          	//console.log("The Table has been initialized!")
			        }
				});

				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
            } else {
            	display_error_message(response);
            }
        }
    });

    getFloatingActionButton('LinkedAccount', '#a-linked-account');
}

window.linkAccount = function() {
	$('.link_account_btn').attr('disabled', true);
	var user_id = user.user_details.user_id;
	var challenge = $('#login_challenge').val();
	var email = $('#login_email').val();
	var session = $('#login_session').val();
	var username = $('#login_username').val();
	var phone_number = $('#login_phone_number').val();

	var phone_digit_1 = $('#login-phone-digit-1').val();
	var phone_digit_2 = $('#login-phone-digit-2').val();
	var phone_digit_3 = $('#login-phone-digit-3').val();
	var phone_digit_4 = $('#login-phone-digit-4').val();
	var answer = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4;

	var data = 'challenge='+challenge+'&email='+email+'&session='+session+'&username='+username+'&phone_number='+phone_number+'&answer='+answer+'&user_id='+user_id;
	var url = api_url+"linked-accounts/add";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
	    		$('#linkAccountModal').modal('hide');
	    		$('#link_form_container').html('');
	    		getMyAccountLinkedAccountDetailWidget();
	    	} else {
	    		$('.link_account_btn').attr('disabled', false);
	    	}
        }
    });
}

window.unlinkAccount = function(id, user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"linked-accounts/delete",
        data:'user_id='+user.user_details.user_id+'&id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		getMyAccountLinkedAccountDetailWidget();
            }
        }
    });
}

window.getUserSubscriptionData = function() {
	if(user_service_name == po_role) {
		$('#myaccount_po_tiles').removeClass('d-none');
		openLoadingDialog($('#total_properties_container'));
		openLoadingDialog($('#current_monthly_bill_container'));
		openLoadingDialog($('#next_payment_due_container'));
		openLoadingDialog($('#billing_cycle_container'));
	} else if(user_service_name == t_role) {
		$('#myaccount_t_tiles').removeClass('d-none');
		openLoadingDialog($('#tenant_total_properties_container'));
		openLoadingDialog($('#current_monthe_rent_container'));
		openLoadingDialog($('#total_pending_amount_container'));
	} else {
		return false;
	}

	var user_id = user.user_details.user_id;
	var type = '';
	$.ajax({
        method: 'POST',
        url: api_url+"subscription-plans/get-user-subscription-data",
        data:"user_id="+user_id+'&user_service_name='+user_service_name+'&type='+type,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		if(user_service_name == po_role) {
		    		var html = '';
		    		setTimeout(function(){
			    		html += '<div class="card-header">';
				            html += '<span class="float-right text-right"><h3 id="total_property" class="mb-0 pt-2 font-weight-light">'+response.subscriptionPlans.length+'</h3></span>';
				            html += '<img src="icon/property.svg" class="filter-1">';
				            html += '<div class="mb-1 mt-4">';
				                html += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Total Property <span data-id="po_total_property" data-title="Total Property" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html += '</div>';
				            html += '<div id="total_property_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html += '</div>';
						$('#total_properties_container').html(html);
					}, 1000);

					var html1 = '';
					setTimeout(function(){
	    		        html1 += '<div class="card-header">';
				            html1 += '<span class="float-right text-right"><h3 id="total_amount" class="mb-0 pt-2 font-weight-light">$'+response.total+'</h3></span>';
				            html1 += '<img src="icon/tiles/expense.svg" class="filter-5">';
				            html1 += '<div class="mb-1 mt-4">';
				                html1 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Current monthly bill <span data-id="po_current_monthly_bill" data-title="Current Monthly Bill" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html1 += '</div>';
				            html1 += '<div id="current_monthly_bill_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html1 += '</div>';
						$('#current_monthly_bill_container').html(html1);
					}, 1000);

					var html2 = '';
					setTimeout(function(){
	    		        html2 += '<div class="card-header">';
				            html2 += '<span class="float-right text-right"><h3 id="payment_due_date" class="mb-0 pt-2 font-weight-light">'+response.next_payment_due_date+'</h3></span>';
				            html2 += '<img src="icon/detail/year.svg" class="filter-3">';
				            html2 += '<div class="mb-1 mt-4">';
				                html2 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Next payment due</h3>';
				            html2 += '</div>';
				            html2 += '<div id="next_payment_due_data_container" class="mb-1 mt-3"><!-- next payment due data --></div>';
				        html2 += '</div>';
						$('#next_payment_due_container').html(html2);
					}, 1000);

					var html3 = '';
					setTimeout(function(){
	    		        html3 += '<div class="card-header">';
				            html3 += '<span class="float-right text-right"><h3 id="billing_cycle" class="mb-0 pt-2 font-weight-light">'+response.plan_name+'<br>'+response.billing_cycle+'</h3></span>';
				            html3 += '<img src="icon/tiles/cycle.svg" class="filter-4">';
				            html3 += '<div class="mb-1 mt-4">';
				                html3 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Plan name & Billing cycle</h3>';
				            html3 += '</div>';
				            html3 += '<div id="billing_cycle_data_container" class="mb-1 mt-3"><!-- billing cycle data --></div>';
				        html3 += '</div>';
						$('#billing_cycle_container').html(html3);
					}, 1000);

		    	} else if(user_service_name == t_role) {
		    		var html = '';
		    		setTimeout(function(){
			    		html += '<div class="card-header">';
				            html += '<span class="float-right text-right"><h3 id="total_property" class="mb-0 pt-2 font-weight-light">'+response.totalProperty+'</h3></span>';
				            html += '<img src="icon/tent.svg" class="filter-5">';
				            html += '<div class="mb-1 mt-4">';
				                html += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Total Property <span data-id="t_total_property" data-title="Total Property" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html += '</div>';
				            html += '<div id="total_property_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html += '</div>';
						$('#tenant_total_properties_container').html(html);
					}, 1000);

					var html1 = '';
					setTimeout(function(){
	    		        html1 += '<div class="card-header">';
				            html1 += '<span class="float-right text-right"><h3 id="current_month_rent" class="mb-0 pt-2 font-weight-light">$'+response.currentMonthAmount+'</h3></span>';
				            html1 += '<img src="icon/vacant.svg" class="filter-6">';
				            html1 += '<div class="mb-1 mt-4">';
				                html1 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Current month rent <span data-id="t_current_month_rent" data-title="Current Month Rent" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html1 += '</div>';
				            html1 += '<div id="current_month_rent_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html1 += '</div>';
						$('#current_monthe_rent_container').html(html1);
					}, 1000);

					var html2 = '';
					setTimeout(function(){
	    		        html2 += '<div class="card-header">';
				            html2 += '<span class="float-right text-right"><h3 id="total_pending_amount" class="mb-0 pt-2 font-weight-light">$'+response.totalPendingAmount+'</h3></span>';
				            html2 += '<img src="icon/rent.svg" class="filter-7">';
				            html2 += '<div class="mb-1 mt-4">';
				                html2 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Total pending amount <span data-id="t_total_pending_amount" data-title="Total Pending Amount" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html2 += '</div>';
				            html2 += '<div id="total_pending_amount_data_container" class="mb-1 mt-3"><!-- next payment due data --></div>';
				        html2 += '</div>';
						$('#total_pending_amount_container').html(html2);
					}, 1000);
		    	}
	    	}
        }
    });
}

window.getMyAccountOverviewDetail = function(type, container) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"subscription-plans/get-user-subscription-data",
        data:"user_id="+user_id+'&user_service_name='+user_service_name+'&type='+type,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		var html = '';

        		if(type == 'po_current_monthly_bill') {
        			html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Price Per Month</th>';
		        			html += '<th class="black fs-14">Coupon</th>';
		        			html += '<th class="black fs-14 text-right">Amount</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.subscriptionPlans.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
	        				for (var key in response.subscriptionPlans) {
				                if (response.subscriptionPlans.hasOwnProperty(key)) {
				                	var msg_class = response.subscriptionPlans[key].coupon_message_color;
				                	html += '<tr class="bg-light">';
					                    html += '<td colspan="3" class="fs-14"><strong>'+response.subscriptionPlans[key].property_name+'</strong></td>';
				                    html += '</tr>';

	                    			html += '<tr>';
	                    				html += '<td><small class="bold-black text-right">$'+response.subscriptionPlans[key].price_per_month+'</small></td>';
					                    html += '<td class="text-center"><small class="bold-black text-right">'+response.subscriptionPlans[key].coupon_code+'<br><span class="'+msg_class+'">'+response.subscriptionPlans[key].coupon_message+'</span></small></td>';
					                    html += '<td class="text-right"><small class="bold-black text-right">$'+response.subscriptionPlans[key].finalAmont+'</small></td>';
				                    html += '</tr>';
				                }
				            }

				            html += '<tr class="bg-light bold-black fs-14">';
				            	html += '<td colspan="2" class="text-left">Total</td>';
			                    html += '<td class="text-right"><strong>$'+response.total+'</strong></td>';
		                    html += '</tr>';
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
        		} else if(type == 'po_total_property') {
		    		html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Property Name</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.subscriptionPlans.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
			    			for (var key in response.subscriptionPlans) {
				                if (response.subscriptionPlans.hasOwnProperty(key)) {
				                	html += '<tr>';
					                    html += '<td><strong>'+response.subscriptionPlans[key].property_name+'</strong></td>';
				                    html += '</tr>';
				                    html += '<tr>';
					                    html += '<td class="text-right"><small class="bold-black">'+response.subscriptionPlans[key].tenant_name+'</small></td>';
				                    html += '</tr>';
				                }
				            }
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
		    	} else if(type == 't_current_month_rent' || type == 't_total_pending_amount') {
		    		html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Name</th>';
		        			html += '<th class="black fs-14">Due Date</th>';
		        			html += '<th class="black fs-14 text-right">Amount</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.arrSP.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
			    			for (var key in response.propertyAray) {
				                if (response.propertyAray.hasOwnProperty(key)) {
				                	html += '<tr class="bg-light">';
					                    html += '<td colspan="3" class="fs-14"><strong>'+key+'</strong></td>';
				                    html += '</tr>';

				                    if(response.propertyAray[key]) {
										for (var wkey in response.propertyAray[key]) {
											if (response.propertyAray[key].hasOwnProperty(wkey)) {
												html += '<tr>';
								                    html += '<td class="text-center">-</td>';
								                    html += '<td><small class="bold-black">'+response.propertyAray[key][wkey].payment_due_date+'</small></td>';
								                    html += '<td class="text-right"><small class="bold-black">$'+response.propertyAray[key][wkey].formated_total+'</small></td>';
							                    html += '</tr>';
											}
										}
									}
				                }
				            }

				            var total = 0;
				            if(type == 'current_month_rent') {
				            	total = response.currentMonthAmount;
				            } else {
				            	total = response.totalPendingAmount;
				            }

				            html += '<tr class="bg-light bold-black fs-14">';
				            	html += '<td colspan="2" class="text-left">Total</td>';
			                    html += '<td class="text-right"><strong>$'+total+'</strong></td>';
		                    html += '</tr>';
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
		    	} else if(type == 't_total_property') {
		    		html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Property Name</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.arrSP.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
			    			for (var key in response.propertyAray) {
				                if (response.propertyAray.hasOwnProperty(key)) {
				                	html += '<tr>';
					                    html += '<td><strong>'+key+'</strong></td>';
				                    html += '</tr>';

				                    if(response.propertyAray[key]) {
										for (var wkey in response.propertyAray[key]) {
											if (response.propertyAray[key].hasOwnProperty(wkey)) {
												if(wkey == 0) {
													html += '<tr>';
									                    html += '<td><small class="bold-black">'+response.propertyAray[key][wkey].property_address+'</small></td>';
								                    html += '</tr>';
								                }
											}
										}
									}
				                }
				            }
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
		    	}

		    	$(container).html(html);
	    	}
        }
    });
}

window.getPropertyList = function() {
	$.ajax({
	    url: api_url+"properties/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            for (var key in response.propertyData) {
	                if (response.propertyData.hasOwnProperty(key)) {
	                    var name = response.propertyData[key].name;
	                    html += '<option value="'+response.propertyData[key].id+'">'+name+'</option>';
	                }
	            }
	        	$('#agreement_property_id').html(html);
	        	$('#transaction_property').append(html);
	        	$('#transaction_report_property_dd').append(html);

				$('#transaction_property').select2({
					placeholder: "Select Property"
				});

	        	$(".transaction_report_property_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "BY PROPERTY",
				            //dropdownParent: $(this).parent()
				        });
				})
	        }
	    }
	});
}

window.getTenantListForTransaction = function(){
	var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#transaction_property').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;

	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true){
			var html = "";
			html += '<option value="">Select tenant</option>';
			for (var key in response.tenants) {
				if (response.tenants.hasOwnProperty(key)) {
					var name = response.tenants[key].user.first_name + ' ' + response.tenants[key].user.last_name;
					html += '<option value="'+response.tenants[key].id+'" selected>'+name+'</option>';
				}
			}

			$('#transaction_tenant').html(html);
			$('#transaction_tenant').select2({
				placeholder: "Select Tenant"
			});
		}
	})
}

window.listTransactions = function(){
	var user_id = user.user_details.user_id;
	var property_id = $('#transaction_property').val();
	var tenant_id = $('#transaction_tenant').val();
	$.ajax({
		url: api_url+"scheduled-payments/list-transactions-for-tenants",
		type: 'POST',
		data: 'property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(response.response_data.success == true){
				for(var key in response.payment_data){
					if(response['payment_data'][key]['payment_type'] == 3 && response['payment_data'][key]['status_constant'] == 'processed'){
						$("#transaction_payment option[value='3']").attr('disabled', true);
					}
					if(response['payment_data'][key]['payment_type'] == 4 && response['payment_data'][key]['status_constant'] == 'processed'){
						$("#transaction_payment option[value='4']").attr('disabled', true);
					}
					if(response['payment_data'][key]['lease']['status'] == 1){
						$("#transaction_payment option[value='9']").attr('disabled', true);
					}
				}
			}
		}
	})
}

window.getNextMonthRentDetail = function(){
	var user_id = user.user_details.user_id;
	var property_id = $('#transaction_property').val();

	var data = '';
	data = 'user_id='+user_id+'&property_id='+property_id+'&user_service_name='+user_service_name;

	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/get-next-month-rent-details",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.error_code == 0){
			var next_month = getFormattedDate(response.data.next_month);
			$('#transaction_rent_month').val(next_month);

			var amount = response.data.lease.monthly_rent_amount;
			var amt = amount.replace(/,/g, '');
			$('#manual_transaction_amount').val(currencyFormat(Number(amt)));
		}

		$('#manual_transaction_amount').attr('readonly', false);
		$('input[name^="transaction_rent_month"]').datepicker("destroy");
		$('input[name^="transaction_rent_month"]').datepicker({
			format: 'mm/dd/yyyy',
			minViewMode: 1,
			autoclose: true,
			useCurrent: true,
			forceParse: false,
			// todayHighlight: true,
			startDate: new Date(next_month),
			endDate: new Date(next_month),
		});

	})
}

window.postManualTransaction = function(){
	var user_id = user.user_details.user_id;
	var is_partial_payment = $('#is_partial_payment').val();
	if(is_partial_payment == 1){
		if($('#payment_due_date').val() == ''){
			var response = {
				"response_data": {
					"success": false,
					"message": 'Please select Payment Due Date'
				}
			};
			display_error_message(response);
			return false;
		}
	}
	var form = $('#manual_transaction_form')[0];
	var formData = new FormData(form);
	formData.append("file", manualTransDropzone.files[0]);
	formData.append("user_id", user_id);
	$.ajax({
	    url: api_url+"scheduled-payments/add-manual-transaction",
	    method: 'POST',
	    data: formData,
		processData: false,
		contentType: false,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			display_error_message(response);
			$('#addManualTransaction').modal('hide');
			var date_macro = '';
			getPaymentReceiptCardReport(date_macro);
	    }
	});
}

window.showPartialPaymentData = function(){
	var user_id = user.user_details.user_id;
	var rent_month = $('#transaction_rent_month').val();
	var payment_type = $('#transaction_payment').val();
	var property_id = $('#transaction_property').val();
	var tenant_id = $('#transaction_tenant').val();
	$.ajax({
		url: api_url+"scheduled-payments/get-payment-details",
		type: 'POST',
		data: 'rent_month='+rent_month+'&payment_type='+payment_type+'&property_id='+property_id+'&tenant_id='+tenant_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(response.response_data.error_code == 0){
				var amount = $('.manual_transaction_amount').val();
				var amt = amount.replace(/,/g, '');
				if(parseFloat(amt) < parseFloat(response.payment_data.total_with_late_fee)){
					$('.partial_payment_card').removeClass('d-none');
					$('.pp_due_date_div').removeClass('d-none');
					$('.save_transaction').attr('disabled',false);
					if(payment_type != 7){
						if(payment_type == 3){
							$('.pp_monthly_rent_label strong').html('Security Deposit');
							$('.pp_auth_rent_label strong').html('Authorized Security Deposit');
						}
						if(payment_type == 4){
							$('.pp_monthly_rent_label strong').html('Pet Deposit');
							$('.pp_auth_rent_label strong').html('Authorized Pet Deposit');
						}
					}
					var partial_amount = amt;
					var balance = response.payment_data.total - partial_amount;
					var partial_payment_fee = response.payment_data.lease.partial_payment_value;
					// partial_payment_fee = partial_payment_fee.replace(/,/g, '');
					if(response.payment_data.lease.partial_payment_method == 'P'){
						var pp_fee = (balance * (partial_payment_fee)/100);
						$('#pp_payment_fee_label').html('('+partial_payment_fee+'% of Balance)');
					} else{
						var pp_fee = partial_payment_fee;
						$('#pp_payment_fee_label').html('(Fixed Amount)');
					}
					var total = amt;
					var balance_due = parseFloat(balance) + parseFloat(pp_fee);
					$('.pp_monthly_rent').html('<strong>$ '+currencyFormat(Number(response.payment_data.total))+'</strong></div>');
					$('#pp_monthly_rent').val(response.payment_data.total);

					$('.pp_partial_amount').html('<strong>$ '+currencyFormat(Number(partial_amount))+'</strong></div>');
					$('#pp_partial_amount').val(partial_amount);

					$('.pp_balance').html('<strong>$ '+currencyFormat(Number(balance))+'</strong></div>');
					$('#pp_balance').val(balance);

					$('.pp_payment_fee').html('<strong>$ '+currencyFormat(Number(pp_fee))+'</strong></div>');
					$('#pp_payment_fee').val(pp_fee);

					$('.pp_authorized_rent').html('<strong>$ '+currencyFormat(Number(total))+'</strong></div>');
					$('#pp_authorized_rent').val(total);

					$('.pp_balance_due').html('<strong>$ '+currencyFormat(Number(balance_due))+'</strong></div>');
					$('#pp_balance_due').val(balance_due);
					$('#is_partial_payment').val(1);
				} else if(parseFloat(amt) == parseFloat(response.payment_data.total)){
					$('.partial_payment_card').addClass('d-none');
					$('.pp_due_date_div').addClass('d-none');
					$('#is_partial_payment').val(0);
				} else{
					var response = {
						"response_data": {
							"success": false,
							"message": 'The amount cannot be greater than rent amount'
						}
					};
					display_error_message(response);
					$('.save_transaction').attr('disabled',true);
				}
			} else{
				if(payment_type == 7){
					var data = 'user_id='+user_id+'&property_id='+property_id+'&user_service_name='+user_service_name;
					$.ajax({
						method: 'POST',
						url: api_url+"scheduled-payments/get-next-month-rent-details",
						data:data,
						beforeSend: function(xhr) {
							xhr.setRequestHeader('Authorization', accessToken);
						},
					}).then(function(response) {
						if(response.response_data.error_code == 0){
							var r_amount = response.data.lease.monthly_rent_amount;
							var rent_amount = r_amount.replace(/,/g, '');
							var amount = $('.manual_transaction_amount').val();
							var amt = amount.replace(/,/g, '');
							if(parseFloat(amt) < parseFloat(rent_amount)){
								$('.partial_payment_card').removeClass('d-none');
								$('.pp_due_date_div').removeClass('d-none');
								$('.save_transaction').attr('disabled',false);
								var partial_amount = amt;
								var balance = rent_amount - partial_amount;
								var partial_payment_fee = response.data.lease.partial_payment_value;
								// partial_payment_fee = partial_payment_fee.replace(/,/g, '');
								if(response.data.lease.partial_payment_method == 'P'){
									var pp_fee = (balance * (partial_payment_fee)/100);
									$('#pp_payment_fee_label').html('('+partial_payment_fee+'% of Balance)');
								} else{
									var pp_fee = partial_payment_fee;
									$('#pp_payment_fee_label').html('(Fixed Amount)');
								}
								var total = amt;
								var balance_due = parseFloat(balance) + parseFloat(pp_fee);
								$('.pp_monthly_rent').html('<strong>$ '+currencyFormat(Number(rent_amount))+'</strong></div>');
								$('#pp_monthly_rent').val(rent_amount);

								$('.pp_partial_amount').html('<strong>$ '+currencyFormat(Number(partial_amount))+'</strong></div>');
								$('#pp_partial_amount').val(partial_amount);

								$('.pp_balance').html('<strong>$ '+currencyFormat(Number(balance))+'</strong></div>');
								$('#pp_balance').val(balance);

								$('.pp_payment_fee').html('<strong>$ '+currencyFormat(Number(pp_fee))+'</strong></div>');
								$('#pp_payment_fee').val(pp_fee);

								$('.pp_authorized_rent').html('<strong>$ '+currencyFormat(Number(total))+'</strong></div>');
								$('#pp_authorized_rent').val(total);

								$('.pp_balance_due').html('<strong>$ '+currencyFormat(Number(balance_due))+'</strong></div>');
								$('#pp_balance_due').val(balance_due);
								$('#is_partial_payment').val(1);
							} else if(parseFloat(amt) == parseFloat(rent_amount)){
								$('.partial_payment_card').addClass('d-none');
								$('.pp_due_date_div').addClass('d-none');
								$('#is_partial_payment').val(0);
							} else{
								var response = {
									"response_data": {
										"success": false,
										"message": 'The amount cannot be greater than rent amount'
									}
								};
								display_error_message(response);
								$('.save_transaction').attr('disabled',true);
							}
						}
					})
				}
			}
		}
	})
}

window.getPropertyTransactionList = function(type) {
	$.ajax({
	    url: api_url+"status-masters/list",
	    type: 'POST',
	    data: 'type='+type,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            for (var key in response.statusData) {
	                if (response.statusData.hasOwnProperty(key)) {
	                    var name = response.statusData[key].label;
	                    html += '<option value="'+response.statusData[key].id+'">'+name+'</option>';
	                }
	            }
	        	$('#transaction_report_tt_dd').append(html);

	        	$(".transaction_report_tt_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "BY TRANSACTION TYPE",
				            //dropdownParent: $(this).parent()
				        });
				})
	        }
	    }
	});
}

window.getPaymentTypes = function() {
	$.ajax({
	    url: api_url+"scheduled-payments/get-payment-types",
	    type: 'GET',
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            for (var key in response.paymentTypes) {
	                if (response.paymentTypes.hasOwnProperty(key)) {
	                	var name = response.paymentTypes[key];
	                    html += '<option value="'+key+'">'+name+'</option>';
	                }
	            }
	        	$('#transaction_report_pt_dd').append(html);

	        	$(".transaction_report_pt_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "BY PAYMENT TYPE",
				            //dropdownParent: $(this).parent()
				        });
				})
	        }
	    }
	});
}

window.getPaymentTypesForTransactions = function() {
	$.ajax({
	    url: api_url+"scheduled-payments/get-payment-types",
	    type: 'GET',
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            for (var key in response.paymentTypes) {
	                if (response.paymentTypes.hasOwnProperty(key) && (key == 3 || key == 4 || key == 7 || key == 9)) {
	                	var name = response.paymentTypes[key];
	                    html += '<option value="'+key+'">'+name+'</option>';
	                }
	            }
				$('#transaction_payment').append(html);
				$('#transaction_payment').select2({
					placeholder: "Select Payment Type"
				});
	        }
	    }
	});
}

window.getPropertyListForSearch = function(open_for) {
	$.ajax({
	    url: api_url+"properties/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            if(open_for == 'modal') {
	            	// Create aray of selected checkbox
		            var selectedProperty = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_property_data').each( function(){
				            selectedProperty.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.property_search_checkbox:checked').each( function(){
				            selectedProperty.push($.trim($(this).val()));
				        });
	            	}

	            	var propertyData = response.propertyData;
		            propertyData.sort(function(a, b) {
					  return compareStrings(a.name, b.name);
					})
					response.propertyData = propertyData;
		            html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.propertyData, 'searchPropertyModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								//html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedProperty.includes($.trim(groupData[key][jkey].id))) {
		                    				checked = 'checked';
		                    			}
		                    			var name = groupData[key][jkey].name;
		                    			var tenants = groupData[key][jkey].tenants;
				                    	html += '<li class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input property_search_checkbox search_modal_checkbox" name="property_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-tenants="'+tenants+'" data-modal="searchPropertyModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+name+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';

		            $('#search_property_container').html(html);
				} else {
	                for (var key in response.propertyData) {
	                    if (response.propertyData.hasOwnProperty(key)) {
	                        var name = response.propertyData[key].name;
	                        var tenants = response.propertyData[key].tenants;
	                        var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.propertyData.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchPropertyModal" data-container="search_property_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
                    		} else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input property_search_checkbox" name="property_search_checkbox" type="checkbox" value="'+response.propertyData[key].id+'" data-tenants="'+tenants+'" data-modal="searchPropertyModal" data-id="'+response.propertyData[key].id+'"><span class="form-check-label">'+name+'</span></label></li>';
	                    	}
	                    }
	                }
	            	$('#property-search').html(html);
	            }
	        }
	    }
	});
}

window.getTenantListForSearch = function(open_for) {
	$.ajax({
	    url: api_url+"tenants/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
            	if(open_for == 'modal') {

			        // Create aray of selected checkbox
		            var selectedTenant = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_tenant_data').each( function(){
				            selectedTenant.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.tenant_search_checkbox:checked').each( function(){
				            selectedTenant.push($.trim($(this).val()));
				        });
	            	}

	            	var tenants = response.tenants;
		            tenants.sort(function(a, b) {
					  return compareStrings(a.name, b.name);
					})
					response.tenants = tenants;
					html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.tenants, 'searchTenantModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedTenant.includes($.trim(groupData[key][jkey].id))) {
		                    				checked = 'checked';
		                    			}
		                    			var name = groupData[key][jkey].name;
				                        var email = groupData[key][jkey].email;
				                        var properties = groupData[key][jkey].properties;
				                        html += '<li class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input tenant_search_checkbox search_modal_checkbox" name="tenant_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-properties="'+properties+'" data-modal="searchTenantModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+name+'<br>'+email+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';
		            $('#search_tenant_container').html(html);
				} else {
	                for (var key in response.tenants) {
	                    if (response.tenants.hasOwnProperty(key)) {
	                        var name = response.tenants[key].name;
	                        var email = response.tenants[key].email;
	                        var properties = response.tenants[key].properties;
	                        var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.tenants.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchTenantModal" data-container="search_tenant_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
                    		} 	else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input tenant_search_checkbox" name="tenant_search_checkbox" type="checkbox" value="'+response.tenants[key].id+'" data-properties="'+properties+'" data-modal="searchTenantModal" data-id="'+response.tenants[key].id+'"><span class="form-check-label">'+name+'<br>'+email+'</span></label></li>';
	                    	}
	                    }
	                }
	                $('#tenant-search').html(html);

	                if(user_service_name == t_role) {
	                	$('#search-by-tenant').addClass('d-none add_opacity');
	                }
	            }
	        }
	    }
	});
}

window.getPSUsersListSeach = function() {
	var property_id = 0;
	if($('#selected_property_id').val()){
		property_id = $('#selected_property_id').val();
	} else {
		property_id = $('#ps_filter_property_name').val();
	}

	var lease_id = 0;
	if($('#ps_filter_lease_id').val()){
		lease_id = $('#ps_filter_lease_id').val();
	}
	$.ajax({
		method: 'POST',
		url: api_url+"properties/get-users",
		data:'property_id='+property_id+'&lease_id='+lease_id,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
	            var html = '';
				for(var key in response.userData){
					html += '<option value="'+response.userData[key].user_id+'">'+response.userData[key].name+' ('+response.userData[key].email+') - '+response.userData[key].type+'</option>';
				}
				$('#ps_filter_po_users').html(html);
				$(".ps_filter_po_users").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "All Property Users",
						dropdownParent: $(this).parent()
						});
				});
	        }
	    }
	});
}

window.getPropertyLeaseListForSearch = function(open_for) {
	$.ajax({
	    url: api_url+"leases/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            if(open_for == 'modal') {
	            	// Create aray of selected checkbox
		            var selectedLease = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_lease_data').each( function(){
				            selectedLease.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.lease_search_checkbox:checked').each( function(){
				            selectedLease.push($.trim($(this).val()));
				        });
	            	}

	            	var leaseData = response.leaseData;
		            leaseData.sort(function(a, b) {
					  return compareStrings(a.start_date, b.start_date);
					})
					response.leaseData = leaseData;
		            html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.leaseData, 'searchLeaseModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								//html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedLease.includes($.trim(groupData[key][jkey].name))) {
		                    				checked = 'checked';
		                    			}
		                    			var name = groupData[key][jkey].name;
		                    			var start_date = $.trim(groupData[key][jkey].start_date);
		                    			var end_date = $.trim(groupData[key][jkey].end_date);
				                    	html += '<li data-start="'+start_date+'" data-end="'+end_date+'" class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input lease_search_checkbox search_modal_checkbox" name="lease_search_checkbox" type="checkbox" value="'+name+'" data-modal="searchLeaseModal" data-id="'+name+'" '+checked+'><span class="form-check-label">'+name+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';

		            $('#search_lease_container').html(html);
				} else {
	                for (var key in response.leaseData) {
	                    if (response.leaseData.hasOwnProperty(key)) {
	                        var date = response.leaseData[key].start_date+' - '+response.leaseData[key].end_date;
	                        var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.leaseData.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchLeaseModal" data-container="search_lease_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
                    		} else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input lease_search_checkbox" name="lease_search_checkbox" type="checkbox" value="'+date+'" data-id="'+date+'"><span class="form-check-label">'+date+'</span></label></li>';
	                    	}
	                    }
	                }
	            	$('#lease-search').html(html);
	            }
	        }
	    }
	});
}

window.getPropertyTransactionListForSearch = function(type, open_for) {
	$.ajax({
	    url: api_url+"status-masters/list",
	    type: 'POST',
	    data: 'type='+type,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            if(open_for == 'modal') {

	            	// Create aray of selected checkbox
		            var selectedTransaction = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_transaction_data').each( function(){
				            selectedTransaction.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.transaction_search_checkbox:checked').each( function(){
				            selectedTransaction.push($.trim($(this).val()));
				        });
	            	}

	            	// Create UL LI structure for display in modal
	            	var statusData = response.statusData;
		            statusData.sort(function(a, b) {
					  return compareStrings(a.label, b.label);
					});
					response.statusData = statusData;
					html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.statusData, 'searchTransactionModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedTransaction.includes($.trim(groupData[key][jkey].id))) {
		                    				checked = 'checked';
		                    			}
				                    	html += '<li class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input transaction_search_checkbox search_modal_checkbox" name="transaction_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-modal="searchTransactionModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].label+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';
		            $('#search_transaction_status_container').html(html);
				} else {
					for (var key in response.statusData) {
	                    if (response.statusData.hasOwnProperty(key)) {
	                    	var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.statusData.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchTransactionModal" data-container="search_transaction_status_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
	                    	} else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input transaction_search_checkbox" name="transaction_search_checkbox" type="checkbox" value="'+response.statusData[key].id+'" data-id="'+response.statusData[key].id+'"><span class="form-check-label">'+response.statusData[key].label+'</span></label></li>';
	                    	}
	                    }
	                }
		            $('#transaction-search').html(html);
				}
	        }
	    }
	});
}

window.getPropertyFilterData = function(){
	$.ajax({
	    url: api_url+"properties/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			if(user_service_name != sa_role){
				$('#property_search_container').addClass('d-none');
			}
			var property_html = '';
			for(var pkey in response.propertyData){
				property_html += '<option value="'+response.propertyData[pkey].id+'">'+response.propertyData[pkey].name+'</option>';
			}
			$('#filter_property_name').html(property_html);
			$(".filter_property_name").each(function() {
				$(this)
					.wrap("<div class=\"position-relative\"></div>")
					.select2({
					placeholder: "BY PROPERTY",
					//dropdownParent: $(this).parent()
					});
			})
		}
	});
	$.ajax({
	    url: api_url+"tenants/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			var tenant_html = '';
			for(var tkey in response.tenants){
				tenant_html += '<option value="'+response.tenants[tkey].id+'">'+response.tenants[tkey].name+'</option>';
			}
			$('#filter_tenant_name').html(tenant_html);
			$(".filter_tenant_name").each(function() {
				$(this)
					.wrap("<div class=\"position-relative\"></div>")
					.select2({
					placeholder: "BY TENANT",
					//dropdownParent: $(this).parent()
					});
			})
		}
	});
	$.ajax({
	    url: api_url+"leases/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
			var lease_html = '';
			for(var lkey in response.leaseData){
				lease_html += '<option value="'+response.leaseData[lkey].start_date+' - '+response.leaseData[lkey].end_date+'">'+response.leaseData[lkey].start_date+' - '+response.leaseData[lkey].end_date+'</option>';
			}
			$('#filter_lease_detail').html(lease_html);
			$(".filter_lease_detail").each(function() {
				$(this)
					.wrap("<div class=\"position-relative\"></div>")
					.select2({
					placeholder: "BY LEASE",
					//dropdownParent: $(this).parent()
					});
			})
		}
	});

	if(new_payment_schedule_enabled == 'true'){
		$.ajax({
			url: api_url+"payment-schedules/get-payment-status-list",
			type: 'POST',
			data: 'user_service_name='+user_service_name+'&user_id='+user_id,
			beforeSend: function(xhr) {
				xhr.setRequestHeader('Authorization', accessToken);
			},
			success: function(response) {
				var transaction_status_html = '';
				for(var key in response.paymentStatusData){
					transaction_status_html += '<option value="'+response.paymentStatusData[key].id+'">'+response.paymentStatusData[key].label+'</option>';
				}
				$('#filter_transaction_status').html(transaction_status_html);
				$(".filter_transaction_status").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "BY TRANSACTION",
						//dropdownParent: $(this).parent()
						});
				})
			}
		});
	} else {
		$.ajax({
		    url: api_url+"status-masters/list",
		    type: 'POST',
		    data: 'type=scheduled_payments',
		    beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
				var transaction_status_html = '';
				for(var skey in response.statusData){
					transaction_status_html += '<option value="'+response.statusData[skey].id+'">'+response.statusData[skey].label+'</option>';
				}
				$('#filter_transaction_status').html(transaction_status_html);
				$(".filter_transaction_status").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "BY TRANSACTION",
						//dropdownParent: $(this).parent()
						});
				})
			}
		});
	}
}

window.getUserDataForSearch = function(open_for, field) {
	$.ajax({
	    url: api_url+"users/get-user-data",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&field='+field,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var name_html = "";
	            var email_html = "";
	            var phone_html = "";
	            var status_html = "";
	            if(open_for == 'modal') {
	            	if(field == 'name') {
		            	// Create aray of selected checkbox
			            var selectedUserName = [];
			            if(localStorage.getItem('filter_data') != null) {
			            	$('.selected_user_name_data').each( function(){
					            selectedUserName.push($.trim($(this).val()));
					        });
		            	} else {
		            		$('.user_name_search_checkbox:checked').each( function(){
					            selectedUserName.push($.trim($(this).val()));
					        });
		            	}

		            	var users = response.users;
			            users.sort(function(a, b) {
						  return compareStrings(a.first_name, b.first_name);
						});
						response.users = users;
			            name_html += '<ul class="ul-search-modal list-unstyled">';
							var groupData = alphabetGroupCollection(response.users, 'searchUserNameModal');
							for (var key in groupData) {
								if (groupData.hasOwnProperty(key)) {
									name_html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
			                    	for (var jkey in groupData[key]) {
			                    		if (groupData[key].hasOwnProperty(jkey)) {
			                    			var checked = '';
			                    			if(selectedUserName.includes($.trim(groupData[key][jkey].id))) {
			                    				checked = 'checked';
			                    			}
			                    			name_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_name_search_checkbox search_modal_checkbox" name="user_name_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-user-name="'+groupData[key][jkey].name+'" data-modal="searchUserNameModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].name+'</span></label></li>';
					                    }
					                }
			                    }
			                }
						name_html += '</ul>';
			            $('#search_user_name_container').html(name_html);
			        } else if(field == 'email') {
			        	// Create aray of selected checkbox
			            var selectedUserEmail = [];
			            if(localStorage.getItem('filter_data') != null) {
			            	$('.selected_user_email_data').each( function(){
					            selectedUserEmail.push($.trim($(this).val()));
					        });
		            	} else {
		            		$('.user_email_search_checkbox:checked').each( function(){
					            selectedUserEmail.push($.trim($(this).val()));
					        });
		            	}

		            	var users = response.users;
			            users.sort(function(a, b) {
						  return compareStrings(a.email, b.email);
						});
						response.users = users;
			            email_html += '<ul class="ul-search-modal list-unstyled">';
							var groupData = alphabetGroupCollection(response.users, 'searchUserEmailModal');
							for (var key in groupData) {
								if (groupData.hasOwnProperty(key)) {
									email_html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
			                    	for (var jkey in groupData[key]) {
			                    		if (groupData[key].hasOwnProperty(jkey)) {
			                    			var checked = '';
			                    			if(selectedUserEmail.includes($.trim(groupData[key][jkey].id))) {
			                    				checked = 'checked';
			                    			}
			                    			email_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_email_search_checkbox search_modal_checkbox" name="user_email_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-user-email="'+groupData[key][jkey].email+'" data-modal="searchUserEmailModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].email+'</span></label></li>';
					                    }
					                }
			                    }
			                }
						email_html += '</ul>';
			            $('#search_user_email_container').html(email_html);
			        } else if(field == 'phone') {
			        	// Create aray of selected checkbox
			            var selectedUserPhone = [];
			            if(localStorage.getItem('filter_data') != null) {
			            	$('.selected_user_phone_data').each( function(){
					            selectedUserPhone.push($.trim($(this).val()));
					        });
		            	} else {
		            		$('.user_phone_search_checkbox:checked').each( function(){
					            selectedUserPhone.push($.trim($(this).val()));
					        });
		            	}

		            	phone_html += '<ul class="ul-search-modal list-unstyled">';
							//var groupData = alphabetGroupCollection(response.users, 'searchUserPhoneModal');
							for (var key in response.users) {
								if (response.users.hasOwnProperty(key)) {
		                        	var phone = response.users[key].phone;
		                        	if(phone != null) {
		                        		phone = phone.split('+1');
		                        		phone = phone[1];
										var checked = '';
		                    			if(selectedUserPhone.includes($.trim(response.users[key].id))) {
		                    				checked = 'checked';
		                    			}
		                    			phone_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_phone_search_checkbox search_modal_checkbox" name="user_phone_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-phone="'+phone+'" data-modal="searchUserPhoneModal" data-id="'+response.users[key].id+'" '+checked+'><span class="form-check-label phone_number">'+phone+'</span></label></li>';
	                    			}
			                    }
			                }
						phone_html += '</ul>';
			            $('#search_user_phone_container').html(phone_html);
			            $('.phone_number').mask('(000) 000-0000');
			        }
				} else {
					if(field == 'name') {
						for (var key in response.users) {
		                    if (response.users.hasOwnProperty(key)) {
		                        var name = response.users[key].first_name+' '+response.users[key].last_name;
		                        var count = record_to_display - 1;

		                    	if(key > count && key == record_to_display) {
		                    		var record = response.users.length - record_to_display;
		                    		name_html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="user" data-type="searchUserNameModal" data-container="search_user_name_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
		                    		break;
	                    		} else {
	                    			name_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_name_search_checkbox" name="user_name_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-name="'+name+'" data-modal="searchUserNameModal" data-id="'+response.users[key].id+'"><span class="form-check-label">'+name+'</span></label></li>';
		                    	}
		                    }
		                }
		            	$('#user-name-search').html(name_html);
					} else if(field == 'email') {
						for (var key in response.users) {
		                    if (response.users.hasOwnProperty(key)) {
		                        var email = response.users[key].email;
		                        var count = record_to_display - 1;

		                    	if(key > count && key == record_to_display) {
		                    		var record = response.users.length - record_to_display;
		                    		email_html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="user" data-type="searchUserEmailModal" data-container="search_user_email_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
		                    		break;
	                    		} else {
	                    			email_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_email_search_checkbox" name="user_email_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-email="'+email+'" data-modal="searchUserEmailModal" data-id="'+response.users[key].id+'"><span class="form-check-label">'+email+'</span></label></li>';
		                    	}
		                    }
		                }
		                $('#user-email-search').html(email_html);
					} else if(field == 'phone') {
						for (var key in response.users) {
		                    if (response.users.hasOwnProperty(key)) {
		                        var phone = response.users[key].phone;
		                        phone = phone.split('+1');
		                        phone = phone[1];
		                        var count = record_to_display - 1;

		                    	if(key > count && key == record_to_display) {
		                    		var record = response.users.length - record_to_display;
		                    		phone_html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="user" data-type="searchUserPhoneModal" data-container="search_user_phone_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
		                    		break;
	                    		} else {
	                    			if(phone != null) {
	                    				phone_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_phone_search_checkbox" name="user_phone_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-phone="'+phone+'" data-modal="searchUserPhoneModal" data-id="'+response.users[key].id+'"><span class="form-check-label phone_number">'+phone+'</span></label></li>';
	                    			}
		                    	}
		                    }
		                }
		                $('#user-phone-search').html(phone_html);
		                $('.phone_number').mask('(000) 000-0000');
					} else if(field == 'status') {
						status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="active" data-user-status="Active" data-modal="searchUserStatusModal"><span class="form-check-label">Active</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="inactive" data-user-status="Inactive" data-modal="searchUserStatusModal"><span class="form-check-label">Inactive</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="verified" data-user-status="Verified" data-modal="searchUserStatusModal"><span class="form-check-label">Verified</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="unverified" data-user-status="Unverified" data-modal="searchUserStatusModal"><span class="form-check-label">Unverified</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="optin" data-user-status="Optin" data-modal="searchUserStatusModal"><span class="form-check-label">Optin</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="optout" data-user-status="Optout" data-modal="searchUserStatusModal"><span class="form-check-label">Optout</span></label></li>';

		            	$('#user-status-search').html(status_html);
					}
	            }
	        }
	    }
	});
}

window.getPSUserDataForSearch = function(open_for, field) {
	$.ajax({
	    url: api_url+"users/get-user-data",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&field='+field+'&user=po',
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var name_html = "";
	            var email_html = "";
	            var phone_html = "";
	            var status_html = "";
	            if(open_for == 'modal') {
					// Create aray of selected checkbox
					var selectedUserName = [];
					if(localStorage.getItem('ps_filter_data') != null) {
						$('.selected_user_name_data').each( function(){
							selectedUserName.push($.trim($(this).val()));
						});
					} else {
						$('.user_name_ps_filter_checkbox:checked').each( function(){
							selectedUserName.push($.trim($(this).val()));
						});
					}

					var users = response.users;
					users.sort(function(a, b) {
						return compareStrings(a.first_name, b.first_name);
					});
					response.users = users;
					name_html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.users, 'searchUserNameModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								name_html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
								for (var jkey in groupData[key]) {
									if (groupData[key].hasOwnProperty(jkey)) {
										var checked = '';
										if(selectedUserName.includes($.trim(groupData[key][jkey].id))) {
											checked = 'checked';
										}
										console.log(checked);
										name_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_name_ps_filter_checkbox ps_apply_filters" name="user_name_ps_filter_checkbox" type="radio" value="'+groupData[key][jkey].id+'" data-user-name="'+groupData[key][jkey].name+'" data-modal="searchUserNameModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].name+'<br>('+groupData[key][jkey].email+')</span></label></li>';
									}
								}
							}
						}
					name_html += '</ul>';
					$('#search_user_name_container').html(name_html);
				} else {
					for (var key in response.users) {
						if (response.users.hasOwnProperty(key)) {
							var email = response.users[key].email;
							var name = response.users[key].first_name+' '+response.users[key].last_name;
							var count = record_to_display - 1;

							if(key > count && key == record_to_display) {
								var record = response.users.length - record_to_display;
								name_html += '<li><label class="form-check"><span class="bold-black open_ps_filter_modal_box" data-field="user" data-type="searchUserNameModal" data-container="search_user_name_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
								break;
							} else {
								name_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_name_ps_filter_checkbox ps_apply_filters" name="user_name_ps_filter_checkbox" type="radio" value="'+response.users[key].id+'" data-user-name="'+name+'" data-modal="searchUserNameModal" data-id="'+response.users[key].id+'"><span class="form-check-label">'+name+'<br>('+email+')</span></label></li>';
							}
						}
					}
					$('#user-name-search').html(name_html);
	            }
	        }
	    }
	});
}

window.getPSPODataForSearch = function(open_for, field) {
	$.ajax({
	    url: api_url+"users/get-user-data",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&field='+field+'&user=po',
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
				var html = '';
				html += '<option value="0">All Property Owners</option>';
				for(var key in response.users){
					html += '<option value="'+response.users[key].id+'">'+response.users[key].first_name+' '+response.users[key].last_name+'('+response.users[key].email+')</option>';
				}
				$('#ps_filter_po_name').html(html);
				$(".ps_filter_po_name").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
						placeholder: "All Property Owners",
						//dropdownParent: $(this).parent()
						});
				})
	        }
	    }
	});
}

window.getPropertiesFilterData = function(data) {
	$.ajax({
        method: 'POST',
        url: api_url+"searchs/get-filter-data",
        data: data,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#navbar_role_name').html('Properties');
            	localStorage.setItem('filter_data', data);
                loadPropertyCard(response, 'search');
            } else {
                display_error_message(response);
            }
        }
    });
}

window.getUserDataForDD = function() {
	$.ajax({
	    url: api_url+"users/get-user-data",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var name_html = "";
	            var email_html = "";
	            var phone_html = "";
	            var status_html = "";

				for (var key in response.users) {
					if (response.users.hasOwnProperty(key)) {
						var name = response.users[key].first_name+' '+response.users[key].last_name;
						name_html += '<option value="'+response.users[key].id+'">'+name+'</option>';
						var email = response.users[key].email;
						email_html += '<option value="'+response.users[key].id+'">'+email+'</option>';
						if(response.users[key].phone != null){
							var phone = response.users[key].phone;
							phone = phone.split('+1');
							phone = phone[1];
							phone_html += '<option value="'+response.users[key].id+'" class="phone_number">'+phone+'</option>';
						}
					}
				}

				var status= ['Active','Inactive','Verified','Unverified','Optin','Optout']
				for(var skey in status){
					status_html += '<option value="'+status[skey]+'">'+status[skey]+'</option>';
				}

				$('#usersNameDD').html(name_html);
				$('#usersEmailDD').html(email_html);
				$('#usersPhoneDD').html(phone_html);
				$('#usersStatusDD').html(status_html);

				$('.phone_number').mask('(000) 000-0000');

				$(".usersNameDD").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "BY NAME",
							//dropdownParent: $(this).parent()
						});
				})

				$(".usersEmailDD").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "BY EMAIL",
							//dropdownParent: $(this).parent()
						});
				})

				$(".usersPhoneDD").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "BY PHONE",
							//dropdownParent: $(this).parent()
						});
				})

				$(".usersStatusDD").each(function() {
					$(this)
						.wrap("<div class=\"position-relative\"></div>")
						.select2({
							placeholder: "BY STATUS",
							//dropdownParent: $(this).parent()
						});
				})
	        }
	    }
	});
}

window.getUsersFilterData = function(data) {
	$.ajax({
        method: 'POST',
        url: api_url+"searchs/get-user-filter-data",
        data: data,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#navbar_role_name').html('Users');
            	localStorage.setItem('filter_data', data);
            	loadUserCard('card_layout', response);
            } else {
                display_error_message(response);
            }
        }
    });
}

window.openSearchMoreModalBox = function(search_modal) {
	var open_for = 'modal';
	if(search_modal == 'searchPropertyModal') {
		getPropertyListForSearch(open_for);
	} else if(search_modal == 'searchTenantModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getTenantListForSearch(open_for);
	} else if(search_modal == 'searchLeaseModal') {
		getPropertyLeaseListForSearch(open_for);
		var html = '';
		var html = '<option value="">&nbsp;</option>';
		$.each(date_dd, function( key, value ) {
			key = key + 1;
			html += '<option value="'+key+'">'+value+'</option>';
		});
		$('#search_date_macro').html(html);
	} else if(search_modal == 'searchTransactionModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getPropertyTransactionListForSearch('scheduled_payments', open_for);
	} else if(search_modal == 'searchUserNameModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getUserDataForSearch(open_for, 'name');
	} else if(search_modal == 'searchUserEmailModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getUserDataForSearch(open_for, 'email');
	} else if(search_modal == 'searchUserPhoneModal') {
		getUserDataForSearch(open_for, 'phone');
	} else if(search_modal == 'searchUserStatusModal') {
		getUserDataForSearch(open_for, 'status');
	}
}

window.openPSFilterModalBox = function(search_modal) {
	var open_for = 'modal';
	if(search_modal == 'searchLeaseModal') {
		getPropertyLeaseListForSearch(open_for);
		var html = '';
		var html = '<option value="">&nbsp;</option>';
		$.each(date_dd, function( key, value ) {
			key = key + 1;
			html += '<option value="'+key+'">'+value+'</option>';
		});
		$('#search_date_macro').html(html);
	} else if(search_modal == 'searchUserNameModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getPSUserDataForSearch(open_for, 'name');
	}
}

window.alphabetGroupCollection = function(data, type) {
	var groupedCollection = {};
	//var html = '';
	//html += '<ul class="list-unstyled text-left">';
	for (var key in data) {
        if (data.hasOwnProperty(key)) {
        	if(type == 'searchPropertyModal' || type == 'searchTenantModal') {
        		var firstLetter = data[key].name.charAt(0);
        	} else if(type == 'searchLeaseModal') {
        		var name = data[key].start_date+' - '+data[key].end_date;
        		var firstLetter = name.charAt(0);
        	} else if(type == 'searchTransactionModal') {
        		var firstLetter = data[key].label.charAt(0);
        	} else if(type == 'searchUserNameModal' || type == 'searchPONameModal') {
        		var firstLetter = data[key].first_name.charAt(0);
        	} else if(type == 'searchUserEmailModal') {
        		var firstLetter = data[key].email.charAt(0);
        	} else if(type == 'searchUserPhoneModal') {
        		var firstLetter = data[key].phone.charAt(0);
        	} else if(type == 'searchUserStatusModal') {
        		var firstLetter = data[key].status.charAt(0);
        	}

        	firstLetter = firstLetter.toUpperCase();

        	if(groupedCollection[firstLetter] == undefined){
		        groupedCollection[firstLetter] = [];
		        $('#alpha_'+firstLetter).removeClass('add_opacity');
		    }

		    if(type == 'searchPropertyModal') {
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':data[key].name,
		    		'tenants':data[key].tenants
		    	});
		    } else if(type == 'searchTenantModal') {
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':data[key].name,
		    		'email':data[key].email,
		    		'properties':data[key].properties
		    	});
		    } else if(type == 'searchLeaseModal') {
		    	var name = data[key].start_date+' - '+data[key].end_date;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':name,
		    		'start_date':data[key].start_date,
		    		'end_date':data[key].end_date,
		    	});
		    } else if(type == 'searchTransactionModal') {
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'label':data[key].label
		    	});
		    } else if(type == 'searchUserNameModal' || type == 'searchPONameModal') {
		    	var name = data[key].first_name+' '+data[key].last_name;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':name,
					'email': data[key].email
		    	});
		    } else if(type == 'searchUserEmailModal') {
		    	var email = data[key].email;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'email':email
		    	});
		    } else if(type == 'searchUserPhoneModal') {
		    	var phone = data[key].phone;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'email':phone
		    	});
		    } else if(type == 'searchUserStatusModal') {
		    	var status = data[key].status;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'status':status
		    	});
		    }
        }
    }

    ////html += '</ul>';
    //$('#'+type+' #alphabet_container').html(html);

    return groupedCollection;
}

window.printAlphabets = function() {
	var k;
	var i = 65;
	var j = 91;
	var html = '';
	html += '<ul class="list-unstyled text-right mr-2">';
	for(k = i; k < j; k++){
		var str =String.fromCharCode(k);
		html += '<li id="alpha_'+str+'" class="alphabet-li add_opacity" data-char="'+str+'">'+str+'</li>';
	}
	html += '</ul>';
	return html;
}

window.compareStrings = function(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();

  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

window.getAnnouncementDetail = function(announcement_id) {
	$.ajax({
	    url: api_url+"announcements/detail",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&announcement_id='+announcement_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var title = $('#announcement_title').val();
		        var description = response.announcement.description;
		        $('#readMore .modal-title').html(title);
		        $('#readMore .modal-body').html(description);
	        }
	    }
	});
}

window.load_selected_page = function(page_name,clicked_from='') {

	getUserServiceList();
	getLinkedAccount();
	var timeout = 1500;
	if(page_name == 'properties' || page_name == 'my_account'){
		timeout = 3000;
	}

	$('.username_container').text(username);
	if(user_service_name == po_role) {
		if(status_code == 1) {
			$('.sidebar-toggle').show();
			$('.hamburger').removeClass('d-none');
			$('#sidebar').removeClass('d-none');
		}
	} else {
		$('.sidebar-toggle').show();
		$('.hamburger').removeClass('d-none');
		$('#sidebar').removeClass('d-none');
	}

	// Start: Reset search form field and close searh navigation
	var search = localStorage.getItem('search');
	if(search == 'pin') {
		var open_for = 'search';
        $('#main_container').load('components/properties.html', open_for, function() {
        	$('#open_for').val(open_for);
        	getPropertiesFilterData(localStorage.getItem('filter_data'));
        });
	} else {

		$(window).on('load', function(){
			if(localStorage.getItem("filter_data") !== null) {
				$('.clear_search_property_data').trigger('click');
				$('#global_search_form .close').trigger('click');
			}
		});

		// End: Reset search form field and close searh navigation

		openLoadingDialog($('#main_container'));
		setTimeout(function(){
			var user_id = user.user_details.user_id;

			if(page_name == 'master-data' && (user_service_name == po_role || user_service_name == t_role)) {
				$('#navbar_role_name').html(humanize('Contact'));
				localStorage.setItem('page_name', page_name);
			} else {
				$('#navbar_role_name').html(humanize(page_name));
				localStorage.setItem('page_name', page_name);
			}

			if(clicked_from != 'left_menu'){
				var pid = getUrlParameter('pid');
				var tid = getUrlParameter('tid');
				var eaid = getUrlParameter('eaid');
				var aid = getUrlParameter('aid');
				var id = getUrlParameter('id');
			}

			if((page_name == 'reports' && $('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-payment-schedule')){
				$('#ps_filter_form_container').load('components/reports/payment_schedules_filters.html');
				$('#ps_filter_form_container').removeClass('d-none');
			} else {
				$('#ps_filter_form_container').addClass('d-none');
				$('#ps_filter_form_container').html('');
				$('#payment_schedule_form_toggle').addClass('d-none');
			}

			if(page_name == 'properties' && (typeof pid !== "undefined")) {
				var pid = pid.substring(11, pid.length-20);
				loadPropertyDetailLayout(pid, 'vertical', '');
				var property_name = localStorage.getItem('property_name');
				localStorage.removeItem('account_role');
				$('#navbar_role_name').html(property_name);
			} else if(page_name == 'template' && (typeof tid !== "undefined")) {
				var tid = tid.substring(11, tid.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/template/viewer.html', tid, function(){
						$('#pdf_template_id').val(tid);
						$('#t_user_id').val(user_id);
						getZohoFieldTypes();
			    		getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
			} else if(page_name == 'template' && (typeof eaid !== "undefined")) {
				var eaid = eaid.substring(11, eaid.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/agreement/update_agreement.html', eaid, function(){
				    	getAgreementDetailById(eaid);
				    });
				}, 2000);
			} else if(page_name == 'template' && (typeof aid !== "undefined")) {
				var aid = aid.substring(11, aid.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/agreement/viewer.html', aid, function(){
						$('#pdf_agreement_id').val(aid);
						getFieldTypes();
			    		//getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
			} else if(page_name == 'template' && (typeof id !== "undefined")) {
				var id = id.substring(11, id.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/template/update_template.html', id, function(){
						getZohoTemplateDetailById(id);
					});
				}, 2000);
			} else {

				if(page_name == 'dashboard') {
					window.history.replaceState("", "", domain+'dashboard.html');
				} else {
					window.history.pushState("", "", '?action='+page_name);
				}
				$('#main_container').load('components/'+page_name+'.html', function(response, status, xhr) {
					$('.sidebar-item[data-page-name="'+page_name+'"]').find('a.sidebar-link').addClass('bg-light');
					$('.sidebar-item[data-page-name="'+page_name+'"]').find('a.sidebar-link').addClass('orange');

					if(page_name == 'users') {
						//getPendingUserList('card_layout');
					}

					if (xhr.status == "403" || xhr.status == "404") {
						$('#main_container').load('components/404.html');
			        } else {
						if(page_name == "dashboard") {
							$('.username_container').text(username);
						}

						// Permission code for plan-subscription
						if(page_name == 'plan-subscriptions') {
							if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['coupon_list']"]) {
								$('a[href="#p-coupons"]').closest('li').remove();
								$('#p-coupons').remove();
							}

							if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['plan_list']"]) {
								$('a[href="#p-plans"]').closest('li').remove();
								$('#p-plans').remove();
							}

							if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['subscription_list']"]) {
								$('a[href="#p-subscriptions"]').closest('li').remove();
								$('#p-subscriptions').remove();
							}

							$('#plan-subscription-tab ul li:first a').addClass('active');
							$('#plan-subscription-tab .tab-content .tab-pane:first').addClass('active');
							if($('#plan-subscription-tab .tab-content .tab-pane:first').attr('id') == 'p-coupons') {
								loadCouponData();
							} else if($('#plan-subscription-tab .tab-content .tab-pane:first').attr('id') == 'p-plans') {
								loadPlanData();
							} else if($('#plan-subscription-tab .tab-content .tab-pane:first').attr('id') == 'p-subscriptions') {
								loadSubscriptionData();
							}
						}

						// Permission code for Permissions menu
						if(page_name == 'permissions') {
							if(!user.permissionData['PERMISSIONS']["feature_items['feature_list']"]) {
								$('a[href="#p-features"]').closest('li').remove();
								$('#p-features').remove();
							}

							if(!user.permissionData['PERMISSIONS']["feature_items['service_list']"]) {
								$('a[href="#p-services"]').closest('li').remove();
								$('#p-services').remove();
							}

							if(!user.permissionData['PERMISSIONS']["feature_items['service_permission_list']"]) {
								$('a[href="#p-service-permissions"]').closest('li').remove();
								$('#p-service-permissions').remove();
							}

							$('#permissions-tab ul li:first a').addClass('active');
							$('#permissions-tab .tab-content .tab-pane:first').addClass('active');
							if($('#permissions-tab .tab-content .tab-pane:first').attr('id') == 'p-features') {
								loadFeaturesData();
							} else if($('#permissions-tab .tab-content .tab-pane:first').attr('id') == 'p-services') {
								loadServicesData();
							} else if($('#permissions-tab .tab-content .tab-pane:first').attr('id') == 'p-service-permissions') {
								loadServicePermissionsData();
							}
						}

						// Permission code for Email menu
						if(page_name == 'email') {
							if(!user.permissionData['EMAIL']["feature_items['inbox_list']"]) {
								$('a[href="#p-inbox"]').closest('li').remove();
								$('#p-inbox').remove();
							}

							if(!user.permissionData['EMAIL']["feature_items['sent_email_list']"]) {
								$('a[href="#p-sent-emails"]').closest('li').remove();
								$('#p-sent-emails').remove();
							}

							if(!user.permissionData['EMAIL']["feature_items['email_template_list']"]) {
								$('a[href="#p-email-templates"]').closest('li').remove();
								$('#p-email-templates').remove();
							}

							$('#system-email-tab ul li:first a').addClass('active');
							$('#system-email-tab .tab-content .tab-pane:first').addClass('active');
							if($('#system-email-tab .tab-content .tab-pane:first').attr('id') == 'p-inbox') {
								getSystemEmailDatas('inbox');
							} else if($('#system-email-tab .tab-content .tab-pane:first').attr('id') == 'p-sent-emails') {
								getSystemEmailDatas('sent-email');
							} else if($('#system-email-tab .tab-content .tab-pane:first').attr('id') == 'p-email-templates') {
								getEmailTemplates();
							}
						}

						// Permission code for Report menu
						if(page_name == 'reports') {
							/*if(!user.permissionData['REPORTS']["feature_items['summary_list']"]) {
								$('a[href="#r-summary"]').closest('li').remove();
								$('#r-summary').remove();
							}*/

							// if(!user.permissionData['REPORTS']["feature_items['transaction_report']"]) {
							// 	$('a[href="#r-payment-receipt"]').closest('li').remove();
							// 	$('#r-payment-receipt').remove();
							// }

							if(!user.permissionData['REPORTS']["feature_items['current_upcoming_payment_report']"]) {
								$('a[href="#r-current-upcoming-payment"]').closest('li').remove();
								$('#r-current-upcoming-payment').remove();
							}

							if(!user.permissionData['REPORTS']["feature_items['overdue_summary']"]) {
								$('a[href="#r-overdue-summary"]').closest('li').remove();
								$('#r-overdue-summary').remove();
							}

							if(!user.permissionData['REPORTS']["feature_items['transaction_report']"]) {
								$('a[href="#r-payment-schedule"]').closest('li').remove();
								$('#r-payment-schedule').remove();
							}

							if(!user.permissionData['REPORTS']["feature_items['income_expense_report']"]) {
								$('a[href="#r-expenses"]').closest('li').remove();
								$('#r-expenses').remove();
							}

							$('#reports-tab ul li:first a').addClass('active');
							$('#reports-tab .tab-content .tab-pane:first').addClass('active');
							/*if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-summary') {
								getPaymentSummaryReport();
							} else */
							if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-payment-receipt') {
								var date_macro = '';
								//getPaymentReceiptReport(date_macro);
								getPaymentReceiptCardReport(date_macro);
							} else if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-overdue-summary' || $('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-current-upcoming-payment' || $('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-payment-schedule'){
								$('#ps_filter_form_container').load('components/reports/payment_schedules_filters.html');
								$('#ps_filter_form_container').removeClass('d-none');
							} else if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-expenses') {
								$('#ps_filter_form_container').addClass('d-none');
								openLoadingDialog($('#expense_report_data_container'));
						    	setTimeout(function(){
							    	getExpenseDetailedReport();
							    }, 100);
							}
						}
					}

					if(page_name == 'master-contracts'){
						getMasterContracts();
					}

					// Permission code for Master data menu
					if(page_name == 'master-data') {
						if(!user.permissionData['MASTER-DATA']["feature_items['pay_to_list']"]) {
							$('a[href="#r-vendor"]').closest('li').remove();
							$('#r-vendor').remove();
						}

						if(!user.permissionData['MASTER-DATA']["feature_items['account_list']"]) {
							$('a[href="#r-account"]').closest('li').remove();
							$('#r-account').remove();
						}

						$('#master-data-tab ul li:first a').addClass('active');
						$('#master-data-tab .tab-content .tab-pane:first').addClass('active');
						if($('#master-data-tab .tab-content .tab-pane:first').attr('id') == 'r-vendor') {
							loadVendorData();
						} else if($('#master-data-tab .tab-content .tab-pane:first').attr('id') == 'r-account') {
							loadAccountData();
						}
					}
					//createActivity(user_id, '', '', 'Clicked on <b>' + page_name + '</b>page');
				});
			}
		}, 500);
	}

	if(localStorage.getItem("primary") != null && localStorage.getItem("allow_ai_access") != 1) {
		setTimeout(function(){
			disableForAssumeIdentity();
		}, timeout);
	}
}

window.disableForAssumeIdentity = function() {
	$('.input-group-prepend').remove();
	$('.disable_action').remove();
	$('#myaccount_bank_add_btn').remove();
	$('.edit_profile').remove();
	$('.edit_address').remove();
	$('#myaccount_link_account_add_btn').remove();
	$('.add_new_property').remove();
	// $('.delete_property_data').remove();
	$('#property_detail_edit_btn').remove();
	$('#property_photo_add_btn').remove();
	$('.property_cover_image').remove();
	$('.delete_property_image').remove();
	$('.add_new_vendor').remove();
	// $('.edit_vendor').remove();
	// $('.delete_vendor').remove();
	$('.add_new_expense').remove();
	$('.delete_expense').remove();
	$('.send_expense').remove();
	$('.edit_expense').remove();
	$('.update_expense').remove();
	$('.add_expense_item').remove();
	$('.delete_line_item').remove();
	$('.camera-upload').remove();
	$('.attachment_expenses').remove();
	$('.add_new_ticket').remove();
	$('#add_new_comment_container').remove();
	$('#property_detail_footer').remove();
}

window.getSignupNextStep = function(current_step, next_step) {
	$('head').append('<style>#signup_checkilist_step_'+current_step+':before{background:green !important;}</style>');
	$('#signup_completed_steps').html(current_step);
	$('#signup_step_name').html($('#signup_checkilist_step_'+next_step).html());

	// Remove selected green color when close modal popup
    $('#signup_checkilist_step_'+current_step).removeClass('orange');
	$('#signup_checkilist_step_'+next_step).addClass('orange');
}

window.DownloadPdf = function(text) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1>"+text+"</h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
}

window.getXYCoordinate = function(width, height, viewer_width, viewer_height, leftPixel, topPixel) {

    const width_perc = width / viewer_width;
    const height_perc = height / viewer_height;

    //console.log(width); console.log(height);

    var leftPixel = ((Math.round(leftPixel) * viewer_width) / 100) * width_perc;
    var topPixel = (height - (((Math.round(topPixel) * viewer_height) / 100) * height_perc)); // here we are subtract height from calculation reason Y coordinate calculated from bottom and in viewer we are calculating from top

    //console.log(leftPixel); console.log(topPixel);

    return [leftPixel, topPixel];
}

window.getFloatingActionButton = function(module_name, container) {
	$('#floating-action-button-nav').remove();
	var html = '';
	html += '<nav id="floating-action-button-nav" class="float-action-button">';
		if(module_name == 'LandingPage') {
	    	html += '<a id="scroll_to_top" href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Back to top" style="display:none"><i class="fas fa-angle-up"></i></a>';
	    } else if(module_name == 'All-User') {
			var layout = $('#user_list_layout').val();
			if(layout == 'table_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="user" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
			} else if(layout == 'card_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="user" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
			}
			html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="User Menu"><i class="fa fa-ellipsis-v"></i></a>';
		} else if(module_name == 'Application') {
			html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="User Menu"><i class="fa fa-ellipsis-v"></i></a>';
		} else if(module_name == 'Property') {
			var layout = $('#selected_layout').val();
			if(layout == 'table_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="property" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
			} else if(layout == 'card_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="property" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
			}

			if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['property_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child_btn add_new_property" data-toggle="tooltip" data-placement="left" title="Add New Property"><i class="fas fa-plus"></i></a>';
	    	}

	    	html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Property Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    } else if(module_name == 'PropertyDetail') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button back_button" data-module="property" data-toggle="tooltip" data-placement="left" title="Back to property"><i class="align-middle ml-3 fas fa-fw fa-angle-left">&nbsp;</i></a>';
	    }  else if(module_name == 'TicketDetail') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button back_button" data-module="ticket" data-toggle="tooltip" data-placement="left" title="Back to ticket"><i class="align-middle ml-3 fas fa-fw fa-angle-left">&nbsp;</i></a>';
	    } else if(module_name == 'Lease') {
	    	if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['lease_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons main-button add_new_lease" data-toggle="tooltip" data-placement="left" title="Add New Lease"><i class="fas fa-plus"></i></a>';
	    	}
	    } else if(module_name == 'Document') {
	    	//if(user_service_name == sa_role) {
	    		var layout = $('#document_tab_layout').val();
	    		if(layout == 'table_layout') {
					html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="document" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
				} else if(layout == 'card_layout') {
					html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="document" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
				}

	    		if(user.permissionData['PROPERTIES']["feature_items['upload_document']"]) {
	    			html += '<a href="javascript:void(0)" class="buttons child_btn get_property_document_dropzone" data-toggle="tooltip" data-placement="left" title="Upload Document"><i class="fas fa-upload"></i></a>';
	    		}
	    		if(user.permissionData['PROPERTIES']["feature_items['add_signature_document']"]) {
	    			html += '<a href="javascript:void(0)" class="buttons child_btn add_lease_aggrement_signature_document" data-toggle="tooltip" data-placement="left" title="Add Signature Document"><i class="fas fa-file-signature"></i></a>';
	    		}
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Document Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	/*} else {
	    		html += '<a href="javascript:void(0)" class="buttons main-button get_property_document_dropzone"  title="Upload Document" data-toggle="tooltip" data-placement="left"><i class="fas fa-upload"></i></a>';
	    	}*/
	    } else if(module_name == 'Tenant') {
	    	if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['tenant_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons main-button add_new_tenant" data-toggle="tooltip" data-placement="left" title="Add New Tenant"><i class="fas fa-plus"></i></a>';
	    	}
	    } else if(module_name == 'Ticket') {
	    	if(user.permissionData['SUPPORT']["feature_items['add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child-button add_new_ticket" data-toggle="tooltip" data-placement="left" title="Add New Ticket"><i class="fas fa-plus mt-1"></i></a>';
	    		html += '<a href="javascript:void(0)" class="buttons main-button disable_action" data-toggle="tooltip" data-placement="left" title="Support Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'Bank') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button add_new_payment" data-toggle="tooltip" data-placement="left" title="Add New Payment"><i class="fas fa-plus"></i></a>';
	    } else if(module_name == 'Template') {
			if(user.permissionData['TEMPLATE']["feature_items['add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child_btn create_zoho_template" data-toggle="tooltip" data-placement="left" title="Create New Template"><i class="fas fa-plus"></i></a>';
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Template Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'LinkedAccount') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button add_new_account" data-toggle="tooltip" data-placement="left" title="Add new account"><i class="fas fa-plus"></i></a>';
	    } else if(module_name == 'Expenses') {
			html += '<a href="javascript:void(0)" class="buttons child_btn add_new_expense" id="" data-module="" data-toggle="tooltip" data-placement="left" title data-original-title="Add New Expense"><i class="fas fa-plus"></i></a>'
			//html += '<a href="javascript:void(0)" class="buttons child_btn get_expense_detailed_report" id="" data-module="" data-toggle="tooltip" data-placement="left" title data-original-title="Get expense detailed report"><i class="align-middle mr-2 fas fa-fw fa-file-alt"></i></a>'
			html += '<a href="javascript:void(0)" class="buttons main-button disable_action" data-toggle="tooltip" data-placement="left" title data-original-title="Expense Menu"><i class="fa fa-ellipsis-v"></i></a>'
		} else if(module_name == 'Transactions Report'){
			if(user_service_name == sa_role){
				html += '<a href="javascript:void(0)" class="buttons child_btn add_manual_transaction" data-toggle="tooltip" data-placement="left" title="Add Transaction"><i class="fas fa-plus"></i></a>';
				html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title data-original-title="Transaction Menu"><i class="fa fa-ellipsis-v"></i></a>'
			}
		} else if(module_name == 'MasterContracts'){
			html += '<a href="javascript:void(0)" class="buttons child_btn add_master_contract" data-toggle="tooltip" data-placement="left" title="Add Master Contract"><i class="fas fa-plus"></i></a>';
			html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title data-original-title="Master Contracts Menu"><i class="fa fa-ellipsis-v"></i></a>'
		}
	html += '</nav>';
	$(container).append(html);

	$('[data-toggle="tooltip"]').tooltip({
		trigger: 'hover'
	});

	$('[data-toggle="tooltip"]').on('click', function () {
    	$(this).tooltip('hide');
    	setTimeout(function(){
		    $('.tooltip').removeClass('show');
		}, 1000);
  	});
}

window.openPopoverModal = function(title, type) {
	var popOverSettings = {
		title: title+'<i class="mr-2 fas fa-fw fa-times-circle close_transaction_detail_modal" style="float:right;"></i>',
		trigger: 'manual',
	    html: true,
	    animation:false,
	    sanitize: false,
	    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
	    content: function () {
	    	var id = $(this).attr('data-id');
	    	var transferID = $(this).attr('data-transferID');
	    	var html = getRelatedTransactionDetail(id,transferID);
	    	return html;
	    }
	}

	$('[data-toggle="popover"]').popover(popOverSettings)
    .on("click", function (e) {
    	e.stopPropagation();
        var _this = this;
        $(this).popover("toggle");
    });
}

window.openLateFeePopoverModal = function(title, primary_percentage, secondary_percentage) {
	var popOverSettings = {
		title: title+'<i class="mr-2 fas fa-fw fa-times-circle close_transaction_detail_modal" style="float:right;"></i>',
		trigger: 'manual',
	    html: true,
	    animation:false,
	    sanitize: false,
		container: '#rent_collection_container',
	    placement: 'bottom',
	    content: function () {
			var html = 'This is calculated on rent that has remained unpaid two full days after the date the rent was originally due at a rate of '+primary_percentage+'% on day 1 and '+secondary_percentage+'% each subsequent day, up to a maximum of 10% of the amount of rent for the rental period.';
			return html;
	    }
	}

	$('#open_latefee_info').popover(popOverSettings)
    .on("click", function (e) {
    	e.stopPropagation();
        var _this = this;
        $(this).popover("toggle");
		$('.popover').css('max-width','60%');
    });
}

window.openOtherFeePopoverModal = function(title) {
	var popOverSettings = {
		title: title+'<i class="mr-2 fas fa-fw fa-times-circle close_transaction_detail_modal" style="float:right;"></i>',
		trigger: 'manual',
	    html: true,
	    animation:false,
	    sanitize: false,
		container: '#rent_collection_container',
	    placement: 'bottom',
	    content: function () {
			var html = 'This includes ACH Return fees and any other fees or penalties. For more details, contact the customer support team.';
			return html;
	    }
	}

	$('#open_otherfee_info').popover(popOverSettings)
    .on("click", function (e) {
    	e.stopPropagation();
        var _this = this;
        $(this).popover("toggle");
		$('.popover').css('max-width','60%');
    });
}

window.ConfirmFieldDelete = function(type,id, field_id, action_id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Delete " + type + " Field</h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm deletion, type <b><em class='fs-22'>"+delete_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+delete_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == delete_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != delete_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before deleting'
                }
            };
            display_error_message(response);
    	} else {
    		deleteDocumentField(id, field_id, action_id);
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.MissingFields = function(data){
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Missing Fields in Expense: </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p class='mt-4'>"+data+"</p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });
}

window.checkForVariables = function(ids){
	if(signature_enabled == 'docusign'){
		var url = api_url+"docusigns/check-for-variables";
	} else if(signature_enabled == 'zoho_document'){
		var url = api_url+"zoho-documents/check-for-variables";
	}

	$.ajax({
		method : 'POST',
		url : url,
		data : 'ids='+ids+'&user_id='+user_id+'&user_service_name='+user_service_name,
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', accessToken);
		},
		success: function(response) {
			if(response.response_data.success == true) {
				ConfirmSend('signature', ids);
			} else{
				display_error_message(response);
			}
		}
	});
}

window.ConfirmSend = function(type,id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Send for " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm send, "+type+" <b><em class='fs-22'>"+confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Send</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
		var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before deleting'
                }
            };
            display_error_message(response);
    	} else {
			if(type == 'signature') {
				if(signature_enabled == 'docusign'){
					sendForSignContract(id);
				} else if(signature_enabled == 'zoho_document'){
					sendZohoDocumentForSignature(id);
				}
	        }
			$(this).parents('.dialog-ovelay').remove();
		}
	});
}

window.ConfirmDelete = function(type,id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Delete " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm deletion, type <b><em class='fs-22'>"+delete_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+delete_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == delete_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != delete_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before deleting'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Property') {
	        	deleteProperty(id);
	        } else if(type == 'Property Image') {
	        	deletePropertyImage(id);
	        } else if(type == 'Property Document') {
	        	deleteUserTemplate(id);
	        } else if(type == 'Background Check Report' || type == 'Credit Report') {
	        	deleteUserBackCreditReportFile(id,type);
	        } else if(type == 'Lease') {
	        	deleteLease(id);
	        } else if(type == 'Tenant') {
	        	deleteTenant(id);
	        } else if(type == 'Ticket') {
	        	deleteDraftTicket(id);
	        } else if(type == 'Comment') {
	        	deleteTicketComment(id);
	        } else if(type == 'Bank') {
	        	deleteBank(id);
	        } else if(type == 'ZohoTemplate') {
	        	deleteZohoTemplateById(id);
	        } else if(type == 'Document') {
	        	deleteZohoDocument(id);
	        } else if(type == 'Agreement') {
	        	deleteAgreementDocument(id);
	        } else if(type == 'Group') {
	        	deleteGroup(id);
	        } else if(type == 'Service') {
	        	deleteService(id);
	        } else if(type == 'ServiceMember') {
	        	deleteUserService(id);
	        } else if(type == 'Expense Report') {
	        	deleteExpense(id);
	        } else if(type == 'Expense') {
	        	deleteExpenseLineItem(id);
	        } else if(type == 'User') {
	        	deleteUser(id);
	        } else if(type == 'Account'){
				deleteAccount(id);
			} else if(type == 'Sub Account'){
				deleteSubAccount(id);
			} else if(type == 'Pay To User') {
				deleteVendor(id);
			} else if(type == 'MasterContract'){
				deleteMasterContract(id);
			} else if(type == 'Contract'){
				if(signature_enabled == 'docusign'){
					deleteContract(id);
				} else if(signature_enabled == 'zoho_document'){
					deleteZohoContract(id);
				}
			} else if(type == 'Payment Schedules'){
				deletePaymentSchedules(id);
			}
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmLateFeeEnableDisable = function(title, id, type) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> "+eachWordFirstLetterCapital(type)+" " + title + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm "+type+", type <b><em class='fs-22'>"+type+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+type+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == type) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != type) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before '+type
                }
            };
            display_error_message(response);
    	} else {
    		enableDisableLateFee(id, type);
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmDisable = function(type,id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Disable " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm disable, type <b><em class='fs-22'>"+disable_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+disable_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == disable_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != disable_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before disable'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'MasterAgreement') {
	        	disableMasterAgreement(id);
	        }
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmPayment = function(type, data) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1>Confirm Payment Authorization</h1>" +
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>Please confirm that you will ensure sufficient funds are available in your account to cover the total payment. Failure to maintain sufficient funds may result in additional late fees and ACH return fees.</p>" +
	        	"<p>Type <b><em class='fs-22'>"+confirmation_text+"</em></b> and click on the Confirm button to complete the authorization process.</p>" +
	        	"<p class='mt-4'><input type='text' id='payment_authorization_confirm_text' class='form-control grey-border' placeholder='"+confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    // $('body').prepend($content);
	if(type == 'fmp') {
		$('#reviewFirstMonthPaymentModal').prepend($content);
	} else if(type == 'ps_rent') {
		// $('#authorizePaymentModal').prepend($content);
		$('#sidebar_content_area').prepend($content);
	} else {
		$('#payNowModal').prepend($content);
	}
	$('#payment_authorization_confirm_text').focus();
    $('#payment_authorization_confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
    	$('.authorize_first_month_payment, .save_rent_collection_data, .ps_save_rent_collection_data').attr('disabled', false);
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#payment_authorization_confirm_text').val();
    	text = text.toLowerCase();
    	if(text != confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before confirm'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'fmp') {
    			authorizeFirstMonthPaymentData(data);
    		} else if(type == 'rent') {
    			saveRentCollectionData(data);
    		} else if(type == 'ps_rent'){
				savePSRentCollectionData(data);
			}
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmCancel = function(type,id,cancel_type='') {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Cancel " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm cancel, type <b><em class='fs-22'>"+cancel_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='cancel_confirm_text' class='form-control grey-border' placeholder='"+cancel_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#cancel_confirm_text').focus();
    $('#cancel_confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == cancel_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#cancel_confirm_text').val();
    	text = text.toLowerCase();
    	if(text != cancel_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before canceling'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Transaction') {
    			cancelPayment(id, cancel_type);
	        } else if(type == 'Payment Schedule'){
				cancelPaymentSchedule(id,cancel_type);
			}
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmRegenerate = function(type,id,payment_type='') {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Regenerate " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm regenerate, type <b><em class='fs-22'>"+confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirm string before confirm'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Transaction') {
    			regenerateTransaction(id, payment_type);
	        } else if(type == 'Payment Schedule'){
				regeneratePaymentSchedule(id, payment_type);
			}
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmPaymentRoute = function(type,id = 0) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>Please confirm that you are aware of the changes in payment routes. If you add or remove a payment route, it will reflect on the selected payment schedules by adding or removing the payment schedule. Note that if you have created any partial entries for a payment route that you are removing, it will remove that payment schedule data.</p>" +
	        	"<p>Type <b><em class='fs-22'>"+confirmation_text+"</em></b> and click on the Confirm button to change payment route.</p>" +
	        	"<p class='mt-4'><input type='text' id='payment_route_confirm_text' class='form-control grey-border' placeholder='"+confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#payment_route_confirm_text').focus();
    $('#payment_route_confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
		$('.save_ps_payment_route').attr('disabled', false);
    });

    $('.doAction').click(function() {
    	var text = $('#payment_route_confirm_text').val();
    	text = text.toLowerCase();
    	if(text != confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirm string before confirm'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Change Payment Route') {
    			savePSPaymentRouteDetails(id);
	        }
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.openRightSidebar = function(width = '500px') {
	if($(window).width() < 767) {
		$('#rightSideMenu').css('width', '100%');
		$('#sidebar_overlay').css('display', 'block');
		$('body').css('overflow-y', 'hidden');
	} else {
		$('#rightSideMenu').css('width', width);
		$('#sidebar_overlay').css('display', 'block');
		$('body').css('overflow-y', 'hidden');
	}
}

window.closeRightSidebar = function() {
    $('#rightSideMenu').css('width', 0);
    $('#sidebar_overlay').css('display', 'none');
    $('body').css('overflow-y', 'auto');
    $('#sidebar_heading').html('');
	$('#sidebar_content_area').html('');

	// #661 this we implemented for apply back button in authorize payment schedule
	$('.back_button_right_sidebar').addClass('d-none').removeClass('open_notification_section');
}

window.DestroyDropzones = function() {
    $('.dropzone').each(function () {
        let dropzoneControl = $(this)[0].dropzone;
        if (dropzoneControl) {
            dropzoneControl.destroy();
        }
    });
}

window.sortResults = function(array, field, type) {
    array.sort(function(a, b) {
        if (type) {
            return (a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0);
        } else {
            return (b[field] > a[field]) ? 1 : ((b[field] < a[field]) ? -1 : 0);
        }
    });
    renderResults();
}

window.stripHtml = function (html){
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

window.getFormattedDate = function(date) {
	var date = new Date(date);
    var formated_date = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    return formated_date;
}

window.formatDateYmd = function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

window.tenant_authorize_datepicker = function(date_element, payment_due_date) {
	//allow current date to due date selection
	if(date_element == 'other_month_due_date'){
		var nowDate = new Date();
		var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: today,
			endDate:payment_due_date,
			autoclose: true,
			todayHighlight: true,
			forceParse: false
		});
	} else if(date_element == 'balance_due_date'){
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: payment_due_date,
			autoclose: true,
			todayHighlight: true,
			forceParse: false
		});
	}
}

window.ps_tenant_authorize_datepicker = function(date_element, payment_due_date) {
	//allow current date to due date selection
	var nowDate = new Date();
	var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
	if(date_element == 'other_month_due_date'){
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: today,
			endDate: payment_due_date,
			autoclose: true,
			todayHighlight: true,
			forceParse: false,
			container: "#rightSideMenu"
		});
	} else if(date_element == 'balance_due_date'){
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: payment_due_date,
			autoclose: true,
			todayHighlight: true,
			forceParse: false,
			container: "#rightSideMenu"
		});
	} else if(date_element == 'paid_on_date'){
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: '01/01/2020',
			endDate: today,
			autoclose: true,
			todayHighlight: true,
			forceParse: false,
			container: "#rightSideMenu"
		});
	} else if(date_element == 'ps_due_date'){
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: '01/01/2020',
			autoclose: true,
			todayHighlight: true,
			forceParse: false,
			container: "#rightSideMenu"
		});
	} else {
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			autoclose: true,
			todayHighlight: true,
			forceParse: false,
			container: "#rightSideMenu"
		});
	}
}

window.datepicker = function(date_element) {
	if(date_element == 'date_of_birth') {
		$("#"+date_element).datepicker({
			startDate: '-125y',
			endDate: '-18y',
		   	format: 'mm/dd/yyyy',
		   	autoclose: true,
		   	useCurrent: false,
		   	forceParse: false,
			container: "#addBankCollapse"
		});
	} else if(date_element == 'first_month_due_date') {
		//disabling past date from datepicker
		var nowDate = new Date();
		var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: today,
		   	autoclose: true,
		   	todayHighlight: true,
		   	forceParse: false
		});
	} else if (date_element == 'inputDate') {
		$('input[name^="inputDate"]').datepicker({
		   	format: 'mm/dd/yyyy',
		   	autoclose: true,
		   	useCurrent: false,
		   	forceParse: false,
			todayHighlight: true
		});
	} else if (date_element == 'transaction_rent_month') {
		$('input[name^="transaction_rent_month"]').datepicker({
		   	format: 'mm/dd/yyyy',
			minViewMode: 1,
		   	autoclose: true,
		   	useCurrent: false,
		   	forceParse: false,
			todayHighlight: true
		});
	} else if (date_element == 'payment_due_date') {
		$('input[name^="payment_due_date"]').datepicker({
		   	format: 'mm/dd/yyyy',
		   	autoclose: true,
		   	useCurrent: false,
		   	forceParse: false,
			todayHighlight: true
		});
	}
}

window.initialize_datepicker = function(start_date, end_date, sd_due_date, pd_due_date) {

	$('#start_date, #end_date').on('keyup', function() {
	   validateEnteredDate($(this).val(), $(this).attr('id'));
	});

	$("#"+start_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: '12/31/2070',
	   	todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	   	var minDate = new Date(selected.date.valueOf());
	   	minDate.setFullYear(minDate.getFullYear() + 1);
		minDate.setDate(minDate.getDate() - 1);
	   	$('#'+end_date).datepicker('setStartDate', minDate);

		minDate.setDate(minDate.getDate());
	   	$('#'+end_date).datepicker('setDate', minDate);

	   	// Check security due date should be less then start date
	   	var sdMaxDate = new Date(selected.date.valueOf());
	   	sdMaxDate.setDate(sdMaxDate.getDate() - 1);
	   	$('#'+sd_due_date).datepicker('setEndDate', sdMaxDate);

	   	// Check pet deposit due date should be less then start date
	   	var pdMaxDate = new Date(selected.date.valueOf());
	   	pdMaxDate.setDate(pdMaxDate.getDate() - 1);
	   	$('#'+pd_due_date).datepicker('setEndDate', pdMaxDate);

		if($('#prorate_enabled').prop('checked') == true){
			$('#prorate_enabled').trigger('change');
		}
	});

	$("#"+end_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: '12/31/2070',
	   	todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	    var minDate = new Date(selected.date.valueOf());
	    $('#'+start_date).datepicker('setEndDate', minDate);
	});

	$('#start_date').on('blur', function () {
		if($(this).val() == "") {
           $('#end_date').datepicker('setStartDate', '');
       	}
   	});

   	// Security and Pet Deposit Due Date
	$("#"+sd_due_date+",#"+pd_due_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: '12/31/2070',
	   	// todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	    var maxDate = new Date(selected.date.valueOf());
	    maxDate.setDate(maxDate.getDate() + 1);
	    $('#'+start_date).datepicker('setStartDate', maxDate);
	});
}

window.ps_datepicker = function(start_date, end_date) {

	$('#ps_filter_start_date, #ps_filter_end_date').on('keyup', function() {
	   validateEnteredDate($(this).val(), $(this).attr('id'));
	});

	var date = new Date();
	date.setMonth(date.getMonth() + 1);
	var startDateEndDate = new Date(date.getFullYear(), date.getMonth(), 1);
	var EndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	$("#"+start_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: startDateEndDate,
	   	todayHighlight: true,
		forceParse: false,
		showButtonPanel: true,
		closeText: 'Clear',
		onClose: function () {
			var event = arguments.callee.caller.caller.arguments[0];
			// If "Clear" gets clicked, then really clear it
			if ($(event.delegateTarget).hasClass('ui-datepicker-close')) {
				$(this).val('');
			}
		}
	}).on('changeDate', function (selected) {
	   	var minDate = new Date(selected.date.valueOf());
	   	// minDate.setFullYear(minDate.getFullYear() + 1);
		minDate.setMonth(minDate.getMonth() + 1);
	   	$('#'+end_date).datepicker('setStartDate', minDate);
	});

	$("#"+end_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: EndDate,
	   	todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	    var minDate = new Date(selected.date.valueOf());
	    $('#'+start_date).datepicker('setEndDate', minDate);
	});

	$('#ps_filter_start_date').on('blur', function () {
		if($(this).val() == "") {
           $('#ps_filter_end_date').datepicker('setStartDate', '');
       	}
   	});
}

window.prorate_datepicker = function(prorate_due_date) {

	$('#prorate_due_date').on('keyup', function() {
	   validateEnteredDate($(this).val(), $(this).attr('id'));
	});

	var date = new Date();
	date.setMonth(date.getMonth() + 1);
	var startDateEndDate = new Date(date.getFullYear(), date.getMonth(), 1);

	$("#"+prorate_due_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: startDateEndDate,
	   	todayHighlight: true,
		forceParse: false,
		showButtonPanel: true,
		closeText: 'Clear',
		onClose: function () {
			var event = arguments.callee.caller.caller.arguments[0];
			// If "Clear" gets clicked, then really clear it
			if ($(event.delegateTarget).hasClass('ui-datepicker-close')) {
				$(this).val('');
			}
		}
	});

	$('#prorate_due_date').on('blur', function () {
		if($(this).val() == "") {
           $('#prorate_due_date').datepicker('setStartDate', '');
       	}
   	});
}

window.validateEnteredDate = function(value, element_id)
{
   if(value.length == 10) {
       var arr_val = value.split('/');

       if(occurrences(value, '/') != 2) {
           $('#'+element_id).val('');
           return true;
       }

       var dtMonth = arr_val[0];
       var dtDay = arr_val[1];
       var dtYear = arr_val[2];

       if (dtMonth < 1 || dtMonth > 12)
          $('#'+element_id).val('');
       else if (dtDay < 1 || dtDay > 31)
          $('#'+element_id).val('');
       else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
          $('#'+element_id).val('');
       else if (dtMonth == 2) {
          var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
          if (dtDay > 29 || (dtDay == 29 && !isleap))
              $('#'+element_id).val('');
       } else if (dtYear.length > 4) {
           $('#'+element_id).val('');
       }
   } else if(value.length > 10) {
       $('#'+element_id).val('');
   }
}

window.populateSelect2 = function(container, label){
	$(container).each(function() {
		$(this)
			.wrap("<div class=\"position-relative\"></div>")
			.select2({
				placeholder: label,
				tags: true,
				dropdownParent: $(this).parent()
			});
	})
}

window.datatable_excel_common_formatting = function(xlsx,sheet) {
    var sSh = xlsx.xl['styles.xml'];
    var s1 = '<xf numFmtId="300" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyNumberFormat="1"/>';
    var s2 = '<xf numFmtId="0" fontId="2" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
        '<alignment horizontal="center"/></xf>';
    sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2;

    var row = $('row', sheet);
    var foot_num = row.length - 1;
    $('row:eq(0) c', sheet).attr('s', '42'); // Report name - Report Row : grey background bold and centered : datatable_excel_common_formatting()
    $('row:eq(1) c', sheet).attr('s', '42'); // Desclaimer Row : Bold, green background, thin black border
    $('row:eq(2) c', sheet).attr('s', '47'); // Header : Bold, green background, thin black border
    $('row:eq('+foot_num+') c', sheet).attr('s', '47'); // Footer Row : Bold, green background, thin black border

    /*$(row[0]).attr('ht', 20);
    $(row[1]).attr('ht', 20);*/
}

/**
*Function that count occurrences of a substring in a string;
*/
window.occurrences = function(string, subString, allowOverlapping) {

   string += "";
   subString += "";
   if (subString.length <= 0) return (string.length + 1);

   var n = 0,
       pos = 0,
       step = allowOverlapping ? 1 : subString.length;

   while (true) {
       pos = string.indexOf(subString, pos);
       if (pos >= 0) {
           ++n;
           pos += step;
       } else break;
   }
   return n;
}

window.getImageMimeType = function(file, callback) {

    //List of known mimes
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xFF, 0xD8, 0xFF],
            mask: [0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4E, 0x47],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];

    function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file

    var reader = new FileReader();
    reader.onloadend = function(e) {
        if (e.target.readyState === FileReader.DONE) {
            var bytes = new Uint8Array(e.target.result);

            for (var i=0, l = mimes.length; i<l; ++i) {
                if (check(bytes, mimes[i])) return callback(mimes[i].mime);
            }

            return callback("unknown");
        }
    };
    reader.readAsArrayBuffer(blob);
}

window.getUrlParameter = function(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

window.random_number = function(len) {
	var x='';
 	for(var i=0;i<len;i++){x+=Math.floor(Math.random() * 10);}
 	return x;
}

window.ordinal_suffix_of = function(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

window.censorEmail = function (email){
    var arr = email.split("@");
    return censorWord(arr[0]) + "@" + censorWord(arr[1]);
}

window.censorWord = function (str) {
   return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
}

window.currencyFormat = function(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

window.humanize = function(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

window.eachWordFirstLetterCapital = function(str) {
	return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

window.getMonthName = function(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}

window.addScript = function(url) {
    var script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = url;
    document.head.appendChild(script);
}

window.getBase64 = function(file, container) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
    	console.log(file.name);
		var image_string = reader.result.replace("data:", "").replace(/^.+,/, "");
		console.log(image_string);
		container.append('<div class="form-group col-md-4 col-xl-2 ml-3 text-center border"><img src="data:image/png;base64,'+image_string+'" alt="'+file.name+'" /></div>');
    };
    reader.onerror = function (error) {
		console.log('Error: ', error);
    };
}

window.openLoadingDialog = function(element) {
	element.html('<div class="spinner-border text-info mr-2" role="status"><span class="sr-only">Loading...</span></div>');
}

window.scrollToTop = function(element){
	var scroll_to_bottom = document.getElementById(element);
	// scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
	if(typeof scroll_to_bottom !== "undefined" && scroll_to_bottom != null) {
    	scroll_to_bottom.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}
}

window.display_expense_message = function(response) {
	if(response.message != "" && response.status == "ERROR") {
		Snackbar.show({
			text: response.message,
			textColor: '#FFFFFF',
			backgroundColor: 'red',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	} else if(response.message != "" && response.status == "SUCCESS") {
		Snackbar.show({
			text: response.message,
			textColor: '#FFFFFF',
			backgroundColor: 'green',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	}
}

window.display_error_message = function(response) {
	if(response.response_data.message != "" && response.response_data.success == false) {
		Snackbar.show({
			text: response.response_data.message,
			textColor: '#FFFFFF',
			backgroundColor: 'red',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 30000,
			pos: 'top-center'
		});
	} else if(response.response_data.message != "" && response.response_data.success == true) {
		Snackbar.show({
			text: response.response_data.message,
			textColor: '#FFFFFF',
			backgroundColor: 'green',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 30000,
			pos: 'top-center'
		});
	}
}

/*Snackbar.show({
	text: 'Your account created succefully.',
	textColor: '#FFFFFF',
	backgroundColor: 'green',
	actionTextColor:'#FFFFFF',
	actionText:'<i class="fas fa-times-circle"></i>',
	duration: 1500000,
	pos: 'top-center'
});*/